import React from 'react'

class ContactContent extends React.Component {
	render() {
		return (
			<dl className="dl-horizontal">
				<dt>Email:</dt>

				<dd>
					<a href={`mailto:${this.props.infoEmail}`}>{this.props.infoEmail}</a>
				</dd>

				<dt>Phone Number:</dt>

				<dd>
					{this.props.phoneNumber}
				</dd>

				<dt>Support Email:</dt>

				<dd>
					<a href={`mailto:${this.props.supportEmail}`}>{this.props.supportEmail}</a>
					<p>You can send direct support issues on this email. They will be converted automatically to tickets
						in our internal support system.</p>
				</dd>
			</dl>
		)
	}
}

export default ContactContent
