import { transformResponseToTableData } from '../../../components/Datatable/dataTableUtils';

// const formatSpecialties = (specialtyList) => {
//     let str = specialtyList.filter(negate(isNull)).slice(0, 3).join(', ');

//     str += specialtyList.length > 3 ? ', ...' : '';
//     return str.length > 60 ? str.substr(0, 60) + '...' : str;
// };

export const transformFacilities = transformResponseToTableData((data) => {
    // console.log('transformReviewers', data);
    return {
        data: data.map(({ code, active, name, facilityTypeCode, facilityCode }) => ({
            code,
            name,
            facilityTypeCode,
            facilityCode,
            status: active ? 'ACTIVE' : 'INACTIVE',
        })),
    };
});
