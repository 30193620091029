const defaultLayoutState = {
    loading: true,
    error: null,
    availableLinks: {
        'links': [],
        'parts': []
    },
    items: []
};
const defaultTranslationState = {
    loading: true,
    error: null,
    map: {}
};

const caseCount = {
    'CW_DRAFT': 0,
    'CW_DISPATCH': 10,
    'CW_REVIEW': 20,
    'CW_QA': 30,
    'CW_CLOSED': 40,
};

const defaultState = {
    loading: true,
    layout: defaultLayoutState,
    translation: defaultTranslationState,
    caseCount: caseCount,
    locale: 'en_GB',
    timezone: null,
    language: 'en',
    localeLoading: false
};

const countAllCases = (caseStageMap) => {
    if (!caseStageMap || typeof caseStageMap !== 'object') return;

    const all = Object.keys(caseStageMap).reduce((count, stage) => caseStageMap[stage] + count, 0);
    const inProgress = caseStageMap['CW_DISPATCH'] + caseStageMap['CW_REVIEW'] + caseStageMap['CW_QA'];

    return {
        ...caseStageMap,
        'CW_ALL': all,
        'CW_PROGRESS': inProgress
    }
};

const getAllLinks = (items) => {
    let links = [];
    for (let i = 0; i < items.length; i++) {
        let child = items[i];
        if (child.link != null) links.push(child.link);
        if (child.children.length > 0) links = links.concat(getAllLinks(child.children));
    }
    return links;
};

const getLinksParts = (links) => {
    let parts = [];
    for (let i = 0; i < links.length; i++) {
        parts.push(links[i] + '/');
        if (links[i].indexOf('facilities') !== -1) {
            parts.push('/facility/');
            parts.push('/facility_type/');
        }
        if (links[i].indexOf('medical_services') !== -1) {
            parts.push('/coding_system/');
            parts.push('/coding_system_code/');
            parts.push('/procedure/');
            parts.push('/diagnose/');
            parts.push('/coding_system_code/');
        }

    }
    parts.push('/case/');
    parts.push('/assign/');
    parts.push('/billing_tier/');
    parts.push('/billing_groups/');
    parts.push('/financial_report/');
    return parts;
};

export default (state = defaultState, action) => {
    let newState;
    const layoutItemIndex = state.layout.items.findIndex((g) => g['@id'] === action.itemId);

    switch (action.type) {

        // TRANSLATION LIST
        case 'CONTEXT_TRANSLATION_LIST_ERROR':
            newState = Object.assign({}, state, {
                translation: Object.assign({}, state.translation, {
                    loading: false,
                    error: action.error,
                })
            });
            newState.loading = isContextLoading(newState);
            return newState;
        case 'CONTEXT_TRANSLATION_LIST_LOADING':
            newState = Object.assign({}, state, {
                translation: Object.assign({}, state.translation, {
                    loading: action.loading,
                    error: null,
                })
            });
            newState.loading = isContextLoading(newState);
            return newState;
        case 'CONTEXT_TRANSLATION_LIST_SUCCESS':
            newState = Object.assign({}, state, {
                translation: Object.assign({}, state.translation, {
                    loading: false,
                    items: action.payload,
                })
            });
            newState.loading = isContextLoading(newState);
            return newState;
        case 'CONTEXT_TRANSLATION_LIST_RESET':
            return Object.assign({}, state, {
                translation: defaultTranslationState
            });

        // CASE COUNT
        case 'CONTEXT_CASE_COUNT_ERROR':
            newState = Object.assign({}, state, {
                loading: false,
                error: action.error,
            });
            return newState;
        case 'CONTEXT_CASE_COUNT_LOADING':
            newState = Object.assign({}, state, {
                loading: true,
                error: null,
            });
            return newState;
        case 'CONTEXT_CASE_COUNT_SUCCESS':
            newState = Object.assign({}, state, {
                loading: false,
                caseCount: countAllCases(action.payload),
            });
            return newState;

        // LAYOUT LIST
        case 'CONTEXT_LAYOUT_LIST_ERROR':
            newState = Object.assign({}, state, {
                layout: Object.assign({}, state.layout, {
                    loading: false,
                    error: action.error,
                })
            });
            newState.loading = isContextLoading(newState);
            return newState;
        case 'CONTEXT_LAYOUT_LIST_LOADING':
            newState = Object.assign({}, state, {
                layout: Object.assign({}, state.layout, {
                    loading: action.loading,
                    error: null,
                })
            });
            newState.loading = isContextLoading(newState);
            return newState;
        case 'CONTEXT_LAYOUT_LIST_SUCCESS':
            let itemsArray = getAllLinks(action.payload);
            let itemsArrayParts = getLinksParts(itemsArray);

            newState = Object.assign({}, state, {
                layout: Object.assign({}, state.layout, {
                    loading: false,
                    items: action.payload,
                    availableLinks: {
                        'links': itemsArray.concat(['/', '/profile', '/help_desk', '/billing_groups', '/financial_report']),
                        'parts': itemsArrayParts
                    }
                })
            });
            newState.loading = isContextLoading(newState);
            return newState;
        case 'CONTEXT_LAYOUT_LIST_RESET':
            return Object.assign({}, state, {
                layout: defaultLayoutState
            });
        case 'CONTEXT_LAYOUT_ITEM_COUNTER_LOADING':
            return Object.assign({}, state, {
                layout: {
                    ...state.layout,
                    items: [
                        ...state.layout.items.slice(0, layoutItemIndex),
                        Object.assign({}, state.layout.items[layoutItemIndex], {
                            countLoading: true
                        }),
                        ...state.layout.items.slice(layoutItemIndex + 1)
                    ]
                }
            });
        case 'CONTEXT_LAYOUT_ITEM_COUNTER_SUCCESS':
            return Object.assign({}, state, {
                layout: {
                    ...state.layout,
                    items: [
                        ...state.layout.items.slice(0, layoutItemIndex),
                        Object.assign({}, state.layout.items[layoutItemIndex], {
                            count: action.data['hydra:totalItems'],
                            countLoading: false
                        }),
                        ...state.layout.items.slice(layoutItemIndex + 1)
                    ]
                }
            });
        case 'CONTEXT_LAYOUT_ITEM_COUNTER_ERROR':
            return Object.assign({}, state, {
                layout: {
                    ...state.layout,
                    items: [
                        ...state.layout.items.slice(0, layoutItemIndex),
                        Object.assign({}, state.layout.items[layoutItemIndex], {
                            countLoading: false
                        }),
                        ...state.layout.items.slice(layoutItemIndex + 1)
                    ]
                }
            });
        case 'LANGUAGE_LOADING':
            newState = Object.assign({}, state, {
                localeLoading: true
            });
            newState.loading = isContextLoading(newState);
            return newState;
        case 'LANGUAGE_SUCCESS':
            newState = Object.assign({}, state, {
                language: action.data['isoCode'],
                localeLoading: false
            });
            newState.loading = isContextLoading(newState);
            return newState;
        case 'LANGUAGE_ERROR':
            newState = Object.assign({}, state, {
                localeLoading: false
            });

            newState.loading = isContextLoading(newState);
            return newState;

        case 'CONTEXT_LOCALE_LOADING':
            newState = Object.assign({}, state, {
                localeLoading: true
            });
            newState.loading = isContextLoading(newState);
            return newState;

        case 'CONTEXT_LOCALE_SUCCESS':
            newState = Object.assign({}, state, {
                locale: action.payload,
                localeLoading: false
            });
            newState.loading = isContextLoading(newState);
            return newState;

        case 'CONTEXT_LOCALE_ERROR':
            newState = Object.assign({}, state, {
                localeLoading: false
            });
            newState.loading = isContextLoading(newState);
            return newState;

        // GENERAL
        case 'CONTEXT_RESET':
            return defaultState;
        default:
            return state
    }
}

const isContextLoading = newState => {
    return newState.translation.loading || newState.layout.loading || newState.localeLoading
};

// const generateTranslationMap = (translations) => {
//
//     var result = {}
//     if (!translations || translations.length === 0) {
//         return result
//     }
//
//     translations.forEach(t => {
//         result[t.id] = t
//     })
//
//     return result
// }
