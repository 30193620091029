import React from 'react';
import {Link} from 'react-router-dom'
import shortId from 'shortid'
import LoadingIndicator from '../../../components/LoadingIndicator';
import FormField from '../../../components/FormField'
import {editableByRoles, fieldClasses, fieldsTypes} from '../../../utils/field-constants'
import UsageMatrix from '../../FieldComponents/UsageMatrix'
import ValidationBlock from '../../FieldComponents/ValidationBlock'
import EditScriptPanel from './EditScriptPanel'
import Box from '../../../components/Box'
import BoxHeader from '../../../components/BoxHeader'
import BoxBody from '../../../components/BoxBody'
import {
    makeSelectOptions,
    getSelectFieldValue,
    validateListOptions,
    toArray,
    getFirstValue,
    isFieldTypeSelect,
    isJSON
} from '../../../utils/helpers'
import SimpleReactValidator from "utils/simple-react-validator";
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import convertFieldData from '../../../utils/fields'
import {isDefaultValueValid} from '../../../utils/fields'
import SystemMessage from '../../../components/SystemMessage'
import history from "../../../redux/history";

export default class ManageFieldForm extends React.Component {

    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({
            custom_value: {
                message: 'The Value should be in the List Options.',
                rule: this.isDefaultValueValid
            },
            positive_num: {
                message: 'The :attribute must be greater than 0.',
                rule: function (val) {
                    return parseFloat(val) > 0 || val === ''
                }
            },
            list_options: {
                message: 'List options must be the same type as field type',
                rule: this.isListOptionsValid
            }
        });
        this.validator.showMessages();

        this.state = {
            displayNameLanguage: {value: 'EN', label: 'English'},
            helpTextLanguage: {value: 'EN', label: 'English'},
            firstLoad: true,
            errorMessages: Object.keys(this.validator.errorMessages).map((field) => ({
                id: field,
                field,
                message: this.validator.errorMessages[field],
            }))
        }

    }

    isDefaultValueValid = (val) => {
        isDefaultValueValid(val, this.props.field)
    };

    isListOptionsValid = () => {
        const {listOptions, fieldType} = this.props.field;
        if (fieldType) {
            return validateListOptions(listOptions, fieldType.value ? fieldType.value : fieldType)
        }
        return true
    };

    componentDidMount() {
        if (!this.props.templateCode) {
            history.goBack();
        } else {
            this.props.getInitData();
            setTimeout(() => {
                this.dispatchValidState()
            }, 2000)
        }
    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        if (this.state.firstLoad && JSON.stringify(this.props.field) !== JSON.stringify(nextProps.field) && nextProps.field.code) {

            const openedScripts = Object.keys(nextProps.field).reduce((openedPanels, field) => {
                if (field.match('Script')) {
                    openedPanels[field] = !!nextProps.field[field]
                }

                return openedPanels
            }, {});

            this.setState({
                firstLoad: false,
                ...openedScripts
            })
        }
    }

    dispatchValidState = () => {
        const {fields, errorMessages} = this.validator;
        let valid = true;
        let messages = [];

        for (let key in fields) {
            if (!fields.hasOwnProperty(key)) continue;
            if (getSelectFieldValue(this.props.field.fieldType) + '' === 'LABEL' && key === 'Display Name') {
                messages.push({
                    id: key,
                    field: key,
                    message: null
                });
                continue
            }

            valid = valid && fields[key];
            messages.push({
                id: key,
                field: key,
                message: errorMessages[key]
            })
        }
        this.props.isAllValid(valid);

        this.setState({
            errorMessages: messages
        })
    };

    openEditScriptForm = (e) => {
        console.log('openEditScriptForm', e.target.dataset.name + 'Script');
        let script = e.target.dataset.name + 'Script';
        this.setState({
            [script]: !this.state[script]
        });
        console.log(this.state[script]);
    };

    openEditListScriptForm = () => {
        this.setState({
            listOptionsScript: !this.state.listOptionsScript
        })
    };

    onChange = (e) => this.props.editField([e.target.name], e.target.value);

    onChangeValidation = (value) => {
        this.props.editField('fieldValidation', {
            ...this.props.field['fieldValidation'],
            ...value
        });
    };

    // fieldAddValidRule = (e) => {
    //     e.preventDefault();
    //     const key = Object.keys(this.props.field['fieldValidation']).length;
    //     this.onChangeValidation({
    //         [key]: {
    //             validationLogic: '',
    //             validationText: '',
    //             softValidation: false,
    //         }
    //     })
    // };

    onChangeCheckBox = (e) => this.props.editField([e.target.name], e.target.checked);

    onChangeAllowMultiSelect = (e) => {
        const {name, checked} = e.target;
        const {defaultValue, fieldType, listOptions} = this.props.field;
        let defaultVal;

        if (isFieldTypeSelect(getSelectFieldValue(fieldType)) || (listOptions && listOptions.length)) {
            defaultVal = checked ? toArray(defaultValue) : getFirstValue(defaultValue)
        }

        this.props.editField([name], checked);
        this.props.editField('defaultValue', defaultVal || defaultValue)
    };

    onChangeSkippName = (e) => {
        this.props.editField([e.target.name], e.target.checked)
    };

    onChangeDisplayNameLanguage = (displayNameLanguage) => this.setState({displayNameLanguage});

    onChangeHelpTextLanguage = (helpTextLanguage) => this.setState({helpTextLanguage});

    onChangeDataType = (fieldType) => {
        this.props.editField('fieldType', fieldType);
        this.props.editField('allowMultiSelect', false);
        this.props.editField('listOptions', []);

        if (fieldType && fieldType.value !== 'DATE_TIME'
            && fieldType.value !== 'DATE' && fieldType.value !== 'TIME'
            && fieldType.value !== 'YEAR')
            this.props.editField('defaultValue', '');
        else this.props.editField('defaultValue', null)
    };

    onChangeDefaultValue = (defaultValue) => {
        if (defaultValue && defaultValue.target) {
            const type = this.props.field.fieldType.value || this.props.field.fieldType;
            if (type === 'BOOLEAN') {
                return this.props.editField('defaultValue', defaultValue.target.checked);
            } else {
                this.onChange(defaultValue);
            }
        } else {
            this.props.editField('defaultValue', defaultValue)
        }
    };

    onChangeScript = (script) => this.setState({script});

    onChangeListOptionsScript = (listOptionsScript) => this.props.editField('listOptionsScript', listOptionsScript);

    onChangeReadOnlyMatrixScript = (readOnlyMatrixScrip) => this.props.editField('readOnlyMatrixScript', readOnlyMatrixScrip);

    onChangeRequiredMatrixScript = (requiredMatrixScript) => this.props.editField('requiredMatrixScript', requiredMatrixScript);

    onChangeVisibilityMatrixScript = (visibilityMatrixScript) => this.props.editField('visibilityMatrixScript', visibilityMatrixScript);

    onChangeCustomClasses = (customClasses) => this.props.editField('customClasses', customClasses);

    onChangeSection = (sectionCode) => this.props.editField('sectionCode', sectionCode);

    onChangeCaseLevel = (caseLevel) => this.props.editField('caseLevel', caseLevel);

    onChangeEditableAfterClose = (val) => this.props.editField('editableAfterClose', val);

    onChangeClientEditableAfterClose = (val) => this.props.editField('clientEditableAfterClose', val);

    onChangeDisplayName = (value) => {
        this.props.editField('name', value);
    };

    onChangeListOptions = (listOptions) => {
        const {fieldType, defaultValue, allowMultiSelect} = this.props.field;

        if (isFieldTypeSelect(fieldType) && !!defaultValue && !allowMultiSelect) {
            const isContains = !!listOptions.find((item) => item.value === getSelectFieldValue(defaultValue));
            !isContains && this.props.editField('defaultValue', null)
        }

        if (!listOptions || !listOptions.length) this.props.editField('defaultValue', null);

        if(!listOptions.find((item) => item.value === defaultValue)) {
            this.props.editField('defaultValue', null)
        }

        this.props.editField('listOptions', listOptions)
    };

    onChangeHelpTextStaff = (value) => {
        const helpTextStaff = Object.assign({}, this.props.field.helpText, {
            [this.state.helpTextLanguage.value]: value
        });

        this.props.editField('helpTextStaff', helpTextStaff);
    };

    onChangeHelpTextReviewer = (value) => {
        const helpTextReviewer = Object.assign({}, this.props.field.helpTextReviewer, {
            [this.state.helpTextLanguage.value]: value
        });

        this.props.editField('helpTextReviewer', helpTextReviewer);
    };

    onChangeHelpTextClient = (value) => {
        const helpTextClient = Object.assign({}, this.props.field.helpTextClient, {
            [this.state.helpTextLanguage.value]: value
        });

        this.props.editField('helpTextClient', helpTextClient);
    };

    payloadMatrix = (name, newMatrix) => {
      this.props.editField(name, newMatrix);
    };

    onChangeMatrix = (name, stageRole) => {
      this.props.editField(name, {
          ...this.props.field[name],
          [stageRole]: !this.props.field[name][stageRole]
      });
    };

    isFieldTypeSelect = (field) => {
        return /REF/g.test(field ? getSelectFieldValue(field) : null)
    };

    onChangeUseDefault = (e) => {
        const {name, checked} = e.target;
        const fieldName = name === 'fieldValidationsUseDefault' ? 'fieldValidationDtos' : name.replace('UseDefault', '');
        // console.log(name);
        // console.log(fieldName);

        this.props.editField([name], checked);
        checked && this.props.editField([fieldName], this.props.baseField[fieldName]);
    };

    onChangeValidationRule = (value) => {
        const {fieldValidationDtos} = this.props.field;
        this.props.editField('fieldValidationDtos', fieldValidationDtos.map((item) => {
            if (item.code === value.code) return value;
            return item;
        }))
    };

    removeFieldValidRule = (code) => {
        const {fieldValidationDtos} = this.props.field;

        this.props.editField('fieldValidationDtos', fieldValidationDtos.filter((item) => item.code !== code));
    };

    addFieldValidRule = (e) => {
        e.preventDefault();
        const {fieldValidationDtos} = this.props.field;
        const emptyRule = {
            active: true,
            code: shortId.generate(),
            hardValidation: true,
            logic: '',
            text: {'EN': ''}
        };

        this.props.editField('fieldValidationDtos', [...fieldValidationDtos, emptyRule])
    };

    onSave = () => {
        const data = convertFieldData(this.props.field);
        return this.props.mode === 'edit' ? this.props.updateFieldInstance(data)
            : this.props.createFieldInstance({...data, code: data.originalFieldCode + '_' + this.props.templateCode})
    };

    optimizeValue = () => {
        if (['DATE', 'TIME', 'DATE_TIME'].indexOf(this.props.field.fieldType) !== -1) {
            return new Date(this.props.field.defaultValue);
        }
        if (this.props.field.listOptions && this.props.field.listOptions.length === 0) {
            return this.props.field.defaultValue;
        }
        return (isJSON(this.props.field.defaultValue)) ? JSON.parse(this.props.field.defaultValue) : this.props.field.defaultValue;
    };

    render() {
        const fieldType = getSelectFieldValue(this.props.field.fieldType);
        const {fieldValidationDtos, fieldValidationsUseDefault} = this.props.field;
        const {errorMessages} = this.state;

        let defaultValueType;

        if (this.props.field.listOptions && this.props.field.listOptions.length) {
            defaultValueType = 'select'
        } else {
            defaultValueType = fieldType + '' === 'LABEL' ? 'RICH_TEXT' : fieldType;
        }

        const validationSection = fieldValidationDtos.map((validRule, i) => (
            <ValidationBlock
                key={validRule.code}
                validRule={validRule}
                onChange={this.onChangeValidationRule}
                onDelete={this.removeFieldValidRule}
                isLast={fieldValidationDtos.length === i + 1}
            />
        ));

        const isListOptionsDisabled = fieldType + '' === 'LABEL' ||
            fieldType + '' === 'BOOLEAN' ||
            fieldType + '' === 'DATE' ||
            fieldType + '' === 'DATE_TIME' ||
            fieldType + '' === 'TIME' ||
            fieldType + '' === 'TEXT' ||
            !!this.props.field['listOptionsUseDefault'];

        return (
            // stick_to_section
            <div>
                <SystemMessage shown={this.props.editedSuccess && this.props.isFormValid} type='success'
                               message='Field Instance successfully updated' top='65px'
                               style={{marginLeft: '35px', marginRight: '35px'}}/>
                <SystemMessage shown={!this.props.isFormValid} type='error' message={this.props.error}
                               validationMessages={errorMessages} top='65px'
                               style={{marginLeft: '35px', marginRight: '35px'}}/>

                <section className="content user-profile-content " style={{paddingLeft: '50px', paddingRight: '50px'}}>
                    {this.props.loading ? <LoadingIndicator height={50}/> : null}


                    <PreventTransitionPrompt
                        when={!!this.props.isFieldChanged}
                        message='MyCustomDialogComponent'
                        isFormValid={this.props.fieldValid}
                        saveChanges={this.onSave}
                        entity='field_instance'
                        onValiudationFail={() => this.props.dispatchFIValidStatus(false)}
                        cancelAndDiscard={() => {
                            this.props.getInitData()
                        }}
                    />
                    <div className="row">

                        {/*<PageHeader style={{color:'#08437a'}}>{this.props.label}</PageHeader>*/}
                        <Box color="blue" classes="box-profile-first-row-height-show">
                            <BoxHeader title={this.props.label} color="blue" classes="box-profile-first-row-height-show">
                                <div className='col-md-2 pull-right text-center'>
                                    <Link style={{fontSize: '18px', textDecoration: 'underline'}}
                                          to={`/fields/update/${this.props.field.originalFieldCode}`}>
                                        Edit Library Field
                                    </Link>
                                </div>
                            </BoxHeader>
                            <BoxBody>
                                <form onKeyDown={(e) => {
                                    if (e.target['tagName'] === 'INPUT' && e.keyCode === 13) e.preventDefault()
                                }}>

                                    <div className="form-group fixed_row bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type='label_field'
                                                label="Attribute"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em'}}
                                            />
                                            <FormField
                                                md="2"
                                                type='label_field'
                                                aligment='center'
                                                label="Link To Default"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em'}}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                label="Field Key"
                                                labelLeft
                                                className="form-control"
                                                required={this.props.mode === 'create'}
                                                name="code"
                                                disabled={this.props.mode !== 'create'}
                                                value={this.props.field.originalFieldCode}
                                                onChange={this.onChange}
                                                valids="required"
                                                validator={this.validator}
                                                onBlur={this.dispatchValidState}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group bordered" style={{paddingBottom: '13px'}}>
                                        <div className="row">
                                            {getSelectFieldValue(this.props.field.fieldType) + '' === 'LABEL' ?
                                                <FormField
                                                    md="10"
                                                    type='RICH_TEXT'
                                                    labelLeft
                                                    actioned
                                                    icon='code'
                                                    onActionClick={this.openEditScriptForm}
                                                    label="Display Name"
                                                    id="Display Name"
                                                    className="form-control required-field"
                                                    readOnly={true}
                                                    name="name"
                                                    value={null}
                                                    onChange={this.onChangeDisplayName}
                                                />
                                                :
                                                <FormField
                                                    md="10"
                                                    type='RICH_TEXT'
                                                    labelLeft
                                                    actioned
                                                    icon='code'
                                                    onActionClick={this.openEditScriptForm}
                                                    label="Display Name"
                                                    id="Display Name"
                                                    className="form-control required-field"
                                                    name="name"
                                                    value={this.props.field.name}
                                                    onChange={this.onChangeDisplayName}
                                                    valids='required'
                                                    validator={this.validator}
                                                    onBlur={this.dispatchValidState}
                                                    readOnly={this.props.field['nameUseDefault']}
                                                />
                                            }
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='nameUseDefault'
                                                value={this.props.field['nameUseDefault']}
                                                checked={!!this.props.field['nameUseDefault']}
                                                onBlur={this.dispatchValidState}

                                            />
                                            <EditScriptPanel
                                                opened={this.state.nameScript}
                                                md='10'
                                                name='nameScript'
                                                label='Display Name Script'
                                                readOnly={this.props.field['nameScriptUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='nameScriptUseDefault'
                                                value={!!this.props.field['nameScriptUseDefault']}
                                                checked={!!this.props.field['nameScriptUseDefault']}
                                                style={{display: this.state.nameScript ? 'block' : 'none'}}
                                            />
                                        </div>
                                    </div>

                                    {/*                    <div className="form-group bordered">
                      <div className="row">
                      <FormField
                      md="2"
                      clearable={false}
                      type="REF_LANGUAGE"
                      label="Language"
                      className="text-bg  margin-top-15"
                      name="displayNameLanguage"
                      value={this.state.displayNameLanguage}
                      onChange={this.onChangeDisplayNameLanguage}
                      style={{marginBottom:'10px'}}
                      />
                      <FormField
                      md="6"
                      type="checkbox"
                      alignment='left'
                      label="Is Not Displayed"
                      name='skippDisplayName'
                      value={this.props.field.skippDisplayName}
                      checked={!!this.props.field.skippDisplayName}
                      onChange={this.onChangeCheckBox}
                      className="checkbox"
                      onBlur={this.dispatchValidState}
                      style={{marginTop: '42px'}}
                      />
                    </div>
                  </div> */}

                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="select"
                                                label="Field Type"
                                                labelLeft
                                                className={this.props.mode !== 'create' ? 'background-grey text-bg' : "required-field text-bg"}
                                                disabled={this.props.mode !== 'create'}
                                                options={makeSelectOptions(fieldsTypes)}
                                                name="fieldType"
                                                value={this.props.field.fieldType}
                                                onChange={this.onChangeDataType}
                                                valids="required"
                                                validator={this.validator}
                                                onBlur={this.dispatchValidState}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group border-bold">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="select"
                                                label="Style"
                                                labelLeft
                                                className="text-bg "
                                                searchable={true}
                                                multi={true}
                                                options={fieldClasses}
                                                name="customClasses"
                                                actioned
                                                icon='code'
                                                onActionClick={this.openEditScriptForm}
                                                value={this.props.field.customClasses}
                                                onChange={this.onChangeCustomClasses}
                                                disabled={this.props.field['customClassesUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='customClassesUseDefault'
                                                value={this.props.field['customClassesUseDefault']}
                                                checked={!!this.props.field['customClassesUseDefault']}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.customClassesScript}
                                                md='10'
                                                name='customClassesScript'
                                                label='Style Script'
                                                readOnly={this.props.field['customClassesScriptUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='customClassesScriptUseDefault'
                                                value={this.props.field['customClassesScriptUseDefault']}
                                                checked={!!this.props.field['customClassesScriptUseDefault']}
                                                style={{display: this.state.customClassesScript ? 'block' : 'none'}}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group border-bold">
                                        <div className="form-group ">
                                            <div className="row">
                                                <FormField
                                                    md="12"
                                                    type='label_field'
                                                    label="Placement"
                                                    className='margin-bottom-15'
                                                    style={{fontSize: '1.2em', marginBottom: '20px'}}
                                                />

                                                <FormField
                                                    md="10"
                                                    label="Section"
                                                    id="Section"
                                                    labelLeft
                                                    className="text-bg required-field"
                                                    type="select"
                                                    name="sectionCode"
                                                    searchable={true}
                                                    sorted
                                                    options={this.props.sections}
                                                    value={this.props.field.sectionCode}
                                                    onChange={this.onChangeSection}
                                                    valids='required'
                                                    validator={this.validator}
                                                    onBlur={this.dispatchValidState}
                                                    actioned
                                                    icon='code'
                                                    onActionClick={this.openEditScriptForm}
                                                />

                                                <EditScriptPanel
                                                    opened={this.state.sectionCodeScript}
                                                    md='10'
                                                    name='sectionCodeScript'
                                                    label='Section Script'
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <div className="row">
                                                <FormField
                                                    md="10"
                                                    type="number"
                                                    label="Order"
                                                    id="Order"
                                                    labelLeft
                                                    className="text-bg "
                                                    name="position"
                                                    disabled={!!this.props.field['positionUseDefault']}
                                                    required
                                                    value={this.props.field.position}
                                                    onChange={this.onChange}
                                                    valids='required|positive_num'
                                                    validator={this.validator}
                                                    onBlur={this.dispatchValidState}
                                                    actioned
                                                    icon='code'
                                                    onActionClick={this.openEditScriptForm}
                                                />

                                                <FormField
                                                    md="2"
                                                    type='checkbox'
                                                    label=''
                                                    aligment='center'
                                                    onChange={this.onChangeUseDefault}
                                                    name='positionUseDefault'
                                                    value={this.props.field['positionUseDefault']}
                                                    checked={!!this.props.field['positionUseDefault']}
                                                />

                                                <EditScriptPanel
                                                    opened={this.state.positionScript}
                                                    md='10'
                                                    name='positionScript'
                                                    label='Order Script'
                                                />
                                                <FormField
                                                    md="2"
                                                    type='checkbox'
                                                    label=''
                                                    aligment='center'
                                                    onChange={this.onChangeUseDefault}
                                                    name='positionScriptUseDefault'
                                                    value={this.props.field['positionScriptUseDefault']}
                                                    checked={!!this.props.field['positionScriptUseDefault']}
                                                    style={{display: this.state.positionScript ? 'block' : 'none'}}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group bordered margin-top-15">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type={isFieldTypeSelect(fieldType) ? fieldType : 'creatable'}
                                                multi
                                                actioned
                                                icon='code'
                                                onActionClick={this.openEditScriptForm}
                                                creatable={true}
                                                label="List Options"
                                                id="List Options"
                                                labelLeft
                                                valuesSorted
                                                name="listOptions"
                                                validator={this.validator}
                                                valids='list_options'
                                                value={this.props.field.listOptions}
                                                onChange={this.onChangeListOptions}
                                                disabled={isListOptionsDisabled}
                                                onBlur={this.dispatchValidState}

                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='listOptionsUseDefault'
                                                value={this.props.field['listOptionsUseDefault']}
                                                checked={!!this.props.field['listOptionsUseDefault']}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.listOptionsScript}
                                                md='10'
                                                name='listOptionsScript'
                                                label='List Options Script'
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='listOptionsScriptUseDefault'
                                                value={this.props.field['listOptionsScriptUseDefault']}
                                                checked={!!this.props.field['listOptionsScriptUseDefault']}
                                                style={{display: this.state.listOptionsScript ? 'block' : 'none'}}
                                            />

                                        </div>
                                    </div>

                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="checkbox"
                                                label="Include Other Option"
                                                labelLeft
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                name='includeOther'
                                                disabled={this.props.field['includeOtherUseDefault']}
                                                value={this.props.field['includeOther']}
                                                checked={!!this.props.field['includeOther']}
                                                onChange={this.onChangeCheckBox}
                                                className="checkbox"
                                                onBlur={this.dispatchValidState}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='includeOtherUseDefault'
                                                value={this.props.field['includeOtherUseDefault']}
                                                checked={!!this.props.field['includeOtherUseDefault']}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="checkbox"
                                                label="Allow Multi-select"
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                labelLeft
                                                aligment='left'
                                                title="Only for reference fields"
                                                name='allowMultiSelect'
                                                disabled={this.props.field['allowMultiSelectUseDefault']}
                                                value={this.props.field.allowMultiSelect}
                                                checked={!!this.props.field.allowMultiSelect}
                                                onChange={this.onChangeAllowMultiSelect}
                                                className="checkbox"
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                onChange={this.onChangeUseDefault}
                                                name='allowMultiSelectUseDefault'
                                                value={this.props.field['allowMultiSelectUseDefault']}
                                                checked={!!this.props.field['allowMultiSelectUseDefault']}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group border-bold">
                                        <div className="row">
                                            <FormField
                                                md='10'
                                                type={defaultValueType}
                                                className={this.props.field['defaultValueUseDefault'] ? 'background-grey text-bg' : "text-bg"}
                                                label='Value'
                                                id='Value'
                                                labelLeft
                                                name='defaultValue'
                                                actioned
                                                icon='code'
                                                valids="custom_value"
                                                validator={this.validator}
                                                onActionClick={this.openEditScriptForm}
                                                value={this.optimizeValue()}
                                                checked={!!this.props.field.defaultValue}
                                                onChange={this.onChangeDefaultValue}
                                                options={this.props.field.listOptions}
                                                sorted={this.props.field.listOptions && this.props.field.listOptions.length}
                                                multi={this.props.field.allowMultiSelect}
                                                disabled={!this.props.field.fieldType || !!this.props.field['defaultValueUseDefault']}
                                                onBlur={this.dispatchValidState}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='defaultValueUseDefault'
                                                value={this.props.field['defaultValueUseDefault']}
                                                checked={!!this.props.field['defaultValueUseDefault']}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.defaultValueScript}
                                                md='10'
                                                name='defaultValueScript'
                                                label='Value Script'
                                                readOnly={this.props.field['defaultValueScriptUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='defaultValueScriptUseDefault'
                                                value={this.props.field['defaultValueScriptUseDefault']}
                                                checked={!!this.props.field['defaultValueScriptUseDefault']}
                                                style={{display: this.state.defaultValueScript ? 'block' : 'none'}}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group  border-bold">
                                        <div className="row">
                                            <FormField
                                                md="2"
                                                type='label_field'
                                                label="Visibility"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em'}}
                                            />
                                            <UsageMatrix
                                                matrix={this.props.field.visibilityMatrix}
                                                label=''
                                                languageCode={'EN'}
                                                onChange={this.onChangeMatrix}
                                                payloadMatrix={this.payloadMatrix}
                                                onActionClick={this.openEditScriptForm}
                                                name='visibilityMatrix'
                                                disabled={this.props.field['visibilityMatrixUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='visibilityMatrixUseDefault'
                                                value={this.props.field['visibilityMatrixUseDefault']}
                                                checked={!!this.props.field['visibilityMatrixUseDefault']}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.visibilityMatrixScript}
                                                md='10'
                                                name='visibilityMatrixScript'
                                                label='Visibility Script'
                                                readOnly={this.props.field['visibilityMatrixScriptUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='visibilityMatrixScriptUseDefault'
                                                value={this.props.field['visibilityMatrixScriptUseDefault']}
                                                checked={!!this.props.field['visibilityMatrixScriptUseDefault']}
                                                style={{display: this.state.visibilityMatrixScript ? 'block' : 'none'}}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group  border-bold">
                                        <div className="row">
                                            <FormField
                                                md="2"
                                                type='label_field'
                                                label="Read Only"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em'}}
                                            />
                                            <UsageMatrix
                                                label=""
                                                matrix={this.props.field.readOnlyMatrix}
                                                languageCode={'EN'}
                                                onChange={this.onChangeMatrix}
                                                payloadMatrix={this.payloadMatrix}
                                                onActionClick={this.openEditScriptForm}
                                                name='readOnlyMatrix'
                                                disabled={this.props.field['readOnlyMatrixUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='readOnlyMatrixUseDefault'
                                                value={this.props.field['readOnlyMatrixUseDefault']}
                                                checked={!!this.props.field['readOnlyMatrixUseDefault']}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.readOnlyMatrixScript}
                                                md='10'
                                                name='readOnlyMatrixScript'
                                                label='Read Only Script'
                                                readOnly={this.props.field['readOnlyMatrixScriptUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='readOnlyMatrixScriptUseDefault'
                                                value={this.props.field['readOnlyMatrixScriptUseDefault']}
                                                checked={!!this.props.field['readOnlyMatrixScriptUseDefault']}
                                                style={{display: this.state.readOnlyMatrixScript ? 'block' : 'none'}}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group  border-bold">
                                        <div className="row">
                                            <FormField
                                                md="2"
                                                type='label_field'
                                                label="Required"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em'}}
                                            />
                                            <UsageMatrix
                                                label=""
                                                matrix={this.props.field.requiredMatrix}
                                                languageCode={'EN'}
                                                onChange={this.onChangeMatrix}
                                                payloadMatrix={this.payloadMatrix}
                                                onActionClick={this.openEditScriptForm}
                                                name='requiredMatrix'
                                                disabled={this.props.field['requiredMatrixUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='requiredMatrixUseDefault'
                                                value={this.props.field['requiredMatrixUseDefault']}
                                                checked={!!this.props.field['requiredMatrixUseDefault']}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.requiredMatrixScript}
                                                md='10'
                                                name='requiredMatrixScript'
                                                label='Required Script'
                                                readOnly={this.props.field['requiredMatrixScriptUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='requiredMatrixScriptUseDefault'
                                                value={this.props.field['requiredMatrixScriptUseDefault']}
                                                checked={!!this.props.field['requiredMatrixScriptUseDefault']}
                                                style={{display: this.state.requiredMatrixScript ? 'block' : 'none'}}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group  border-bold">

                                        <div className="row">
                                            <FormField
                                                md="12"
                                                type='label_field'
                                                label="Help Text"
                                                style={{fontSize: '1.2em', marginBottom: '20px'}}
                                            />
                                            {/*     <FormField
                        md="10"
                        type='RICH_TEXT'
                        language={this.state.helpTextLanguage.value}
                        actioned
                        icon='code'
                        onActionClick={this.openEditScriptForm}
                        label=""
                        className="form-control"
                        name="helpText"
                        value={this.props.field.helpText && this.props.field.helpText[this.state.helpTextLanguage.value]}
                        onChange={this.onChangeHelpText}

                        />
                        <FormField
                        md="2"
                        clearable={false}
                        type="REF_LANGUAGE"
                        label="Language"
                        className="text-bg "
                        name="helpTextLanguage"
                        value={this.state.helpTextLanguage}
                        onChange={this.onChangeHelpTextLanguage}
                        />
                        */}

                                            <FormField
                                                md="10"
                                                type='RICH_TEXT'
                                                actioned
                                                icon='code'
                                                onActionClick={this.openEditScriptForm}
                                                label="Staff"
                                                labelLeft
                                                className="form-control"
                                                name="helpTextStaff"
                                                value={this.props.field.helpTextStaff && this.props.field.helpTextStaff[this.state.helpTextLanguage.value]}
                                                onChange={this.onChangeHelpTextStaff}
                                                readOnly={this.props.field['helpTextStaffUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='helpTextStaffUseDefault'
                                                value={this.props.field['helpTextStaffUseDefault']}
                                                checked={!!this.props.field['helpTextStaffUseDefault']}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.helpTextStaffScript}
                                                md='10'
                                                name='helpTextStaffScript'
                                                label='Staff Help Text Script'
                                                readOnly={this.props.field['helpTextStaffScriptUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='helpTextStaffScriptUseDefault'
                                                value={this.props.field['helpTextStaffScriptUseDefault']}
                                                checked={!!this.props.field['helpTextStaffScriptUseDefault']}
                                                style={{display: this.state.helpTextStaffScript ? 'block' : 'none'}}
                                            />
                                            <div className='col-md-12'>
                                            </div>
                                            <FormField
                                                md="10"
                                                type='RICH_TEXT'
                                                labelLeft
                                                actioned
                                                icon='code'
                                                onActionClick={this.openEditScriptForm}
                                                label="Reviewer"
                                                className="form-control"
                                                name="helpTextReviewer"
                                                value={this.props.field.helpTextReviewer && this.props.field.helpTextReviewer[this.state.helpTextLanguage.value]}
                                                onChange={this.onChangeHelpTextReviewer}
                                                readOnly={this.props.field['helpTextReviewerUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='helpTextReviewerUseDefault'
                                                value={this.props.field['helpTextReviewerUseDefault']}
                                                checked={!!this.props.field['helpTextReviewerUseDefault']}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.helpTextReviewerScript}
                                                md='10'
                                                name='helpTextReviewerScript'
                                                label='Reviewer Text Script'
                                                readOnly={this.props.field['helpTextReviewerScriptUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='helpTextReviewerScriptUseDefault'
                                                value={this.props.field['helpTextReviewerScriptUseDefault']}
                                                checked={!!this.props.field['helpTextReviewerScriptUseDefault']}
                                                style={{display: this.state.helpTextReviewerScript ? 'block' : 'none'}}
                                            />
                                            <div className='col-md-12'>
                                            </div>

                                            <FormField
                                                md="10"
                                                type='RICH_TEXT'
                                                labelLeft
                                                actioned
                                                icon='code'
                                                onActionClick={this.openEditScriptForm}
                                                label="Client"
                                                className="form-control"
                                                name="helpTextClient"
                                                value={this.props.field.helpTextClient && this.props.field.helpTextClient[this.state.helpTextLanguage.value]}
                                                onChange={this.onChangeHelpTextClient}
                                                readOnly={this.props.field['helpTextClientUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='helpTextClientUseDefault'
                                                value={this.props.field['helpTextClientUseDefault']}
                                                checked={!!this.props.field['helpTextClientUseDefault']}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.helpTextClientScript}
                                                md='10'
                                                name='helpTextClientScript'
                                                label='Client Help Text Script'
                                                readOnly={this.props.field['helpTextClientScriptUseDefault']}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='helpTextClientScriptUseDefault'
                                                value={this.props.field['helpTextClientScriptUseDefault']}
                                                checked={!!this.props.field['helpTextClientScriptUseDefault']}
                                                style={{display: this.state.helpTextClientScript ? 'block' : 'none'}}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group border-bold">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type='label_field'
                                                label="Validation"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em', marginBottom: '20px'}}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                onChange={this.onChangeUseDefault}
                                                name='fieldValidationsUseDefault'
                                                value={this.props.field.fieldValidationsUseDefault}
                                                checked={!!this.props.field.fieldValidationsUseDefault}
                                            />
                                        </div>
                                        {validationSection}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <button
                                                    disabled={fieldValidationsUseDefault}
                                                    className='btn-link cursor-pointer'
                                                    onClick={this.addFieldValidRule}>
                                                    +Add new Validation Rule
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <FormField
                                                md="12"
                                                type='label_field'
                                                label="Additional Attributes"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em', marginBottom: '20px'}}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="select"
                                                label="Editable After Close"
                                                labelLeft
                                                className="text-bg "
                                                options={editableByRoles}
                                                disabled={!!this.props.field['editableAfterCloseUseDefault']}
                                                name="editableAfterClose"
                                                value={this.props.field.editableAfterClose}
                                                onChange={this.onChangeEditableAfterClose}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                onChange={this.onChangeUseDefault}
                                                name='editableAfterCloseUseDefault'
                                                value={this.props.field['editableAfterCloseUseDefault']}
                                                checked={!!this.props.field['editableAfterCloseUseDefault']}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="select"
                                                label="Client Editable After Close"
                                                labelLeft
                                                className="text-bg "
                                                options={editableByRoles}
                                                name="clientEditableAfterClose"
                                                disabled={!!this.props.field['clientEditableAfterCloseUseDefault']}
                                                value={this.props.field.clientEditableAfterClose}
                                                onChange={this.onChangeClientEditableAfterClose}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                onChange={this.onChangeUseDefault}
                                                name='clientEditableAfterCloseUseDefault'
                                                value={this.props.field['clientEditableAfterCloseUseDefault']}
                                                checked={!!this.props.field['clientEditableAfterCloseUseDefault']}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                type='checkbox'
                                                className='form-control'
                                                name='copyOption'
                                                labelLeft
                                                label='Copy Option'
                                                disabled={this.props.field['copyOptionUseDefault']}
                                                value={this.props.field['copyOption']}
                                                checked={!!this.props.field['copyOption']}
                                                onChange={this.onChangeCheckBox}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                onChange={this.onChangeUseDefault}
                                                name='copyOptionUseDefault'
                                                value={this.props.field['copyOptionUseDefault']}
                                                checked={!!this.props.field['copyOptionUseDefault']}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                type='checkbox'
                                                className='form-control'
                                                name='phi'
                                                labelLeft
                                                label='PHI Field'
                                                disabled={this.props.field['phiUseDefault']}
                                                value={this.props.field.phi}
                                                checked={!!this.props.field.phi}
                                                onChange={this.onChangeCheckBox}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                onChange={this.onChangeUseDefault}
                                                name='phiUseDefault'
                                                value={this.props.field['phiUseDefault']}
                                                checked={!!this.props.field['phiUseDefault']}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group border-bold">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                type='checkbox'
                                                className='form-control'
                                                name='question'
                                                labelLeft
                                                label='Question'
                                                disabled={this.props.field['questionUseDefault']}
                                                value={this.props.field.question}
                                                checked={!!this.props.field.question}
                                                onChange={this.onChangeCheckBox}
                                            />
                                            <FormField
                                                md="2"
                                                type='checkbox'
                                                label=''
                                                aligment='center'
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                onChange={this.onChangeUseDefault}
                                                name='questionUseDefault'
                                                value={this.props.field['questionUseDefault']}
                                                checked={!!this.props.field['questionUseDefault']}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <FormField
                                                md='12'
                                                type="checkbox"
                                                title="Status"
                                                label="Active"
                                                labelLeft
                                                className='form-control'
                                                name='active'
                                                value={this.props.field.active}
                                                checked={!!this.props.field.active}
                                                onChange={this.onChangeCheckBox}
                                            />
                                        </div>
                                    </div>

                                    {/*<div className="form-group bordered">
                  <div className="row">
                  <div className='col-md-12 col-xs-6 fields'>
                  <label htmlFor="to_do_note"  className="required">Question</label>
                  <textarea name="question" cols="30" rows="3" value={this.state.question} onChange={this.onChange}/>
                </div>
              </div>
            </div>
            <div className="form-group bordered">
            <div className="row">
            <FormField
            md="12"
            type="REF_CASE_LEVEL"
            label="Case Level"
            className="text-bg "
            name="caseLevel"
            value={this.state.caseLevel}
            onChange={this.onChangeCaseLevel}
            />
          </div>
        </div>

        <div className="form-group bordered">
        <div className="row">
        <FormField
        md="12"
        type="CODE"
        label="Scripts"
        value={this.state.script}
        onChange={this.onChangeScript}
        />
      </div>
    </div>
    <div className="row no-padding">
    <div className="col-xs-4 pull-right">
    <button
    disabled={this.props.isLoading}
    className="btn btn-primary btn-block btn-flat">
    Save
  </button>
</div>
</div> */}
                                </form>
                            </BoxBody>
                        </Box>
                    </div>
                    {this.props.loading ?
                        <LoadingIndicator height={50}/>
                        : null}
                </section>
            </div>
        )
    }
}
