import React, { Component } from 'react';
// ----- HARDCODING! we are changing this component to the new one -> package.json -----
// import CreatableSelect from 'react-select/lib/Creatable';
import CreatableSelect from '../deprecated/react-select/lib/Creatable';
// -------------------------------------------------------------------------------------
import InputGroupWrapper from './InputGroupWrapper';

// const moveInput = (e) => {
//   if (e.target.getAttribute('class') === "Select-multi-value-wrapper") {
//     Array.prototype.forEach.call(e.target.childNodes, (elem, i) => {
//       console.log(elem);
//     })
//     // console.log(e.target);
//     // console.log(e.clientX);
//   }
// }
//
// const exchangeElements = (element1, element2) => {
//     if (!element1 || !element2) return
//
//     var clonedElement1 = element1.cloneNode(true);
//     var clonedElement2 = element2.cloneNode(true);
//
//     element2.parentNode.replaceChild(clonedElement1, element2);
//     element1.parentNode.replaceChild(clonedElement2, element1);
//
//     return clonedElement1;
// }


class CreatableMulti extends Component {

    render() {
        let classes = ""
        classes += `col-md-${this.props.labelLeft ? this.props.md - 2 : this.props.md}`
        classes += " col-xs-" + (this.props.xs || "12")

        const disabledStyle = this.props.disabled ? ' background-grey' : ''

        let i = <CreatableSelect
            multi
            id={this.props.id}
            onChange={this.props.onChange}
            value={this.props.value}
            options={this.props.options}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            className={this.props.className + disabledStyle}
            onBlur={this.props.onBlur}
        >
        </CreatableSelect>


        if (this.props.name === "reviewerSelect") {
            return i
        } else {
            return (
                <React.Fragment>
                    {this.props.labelLeft && 
                    <div className='col-md-2'>
                        <label>
                            {this.props.label}
                            {this.props.tickMark}
                        </label>
                    </div>}
                    <div className={classes}>
                        {!this.props.labelLeft && 
                        <label htmlFor={this.props.id} id={'displayName-'+ this.props.name} className="required">
                            {this.props.label}
                            {this.props.tickMark}
                        </label>}
                        <span style={{ display: 'none' }}>{this.props.validator && this.props.valids
                            ? this.props.validator.message(this.props.validLabel || this.props.label,
                                this.props.value, this.props.valids, 'text-danger') : ''}</span>
                        {this.props.actioned ? <InputGroupWrapper name={this.props.name} icon='code'
                                                                  onActionClick={this.props.onActionClick}>{i}</InputGroupWrapper> : i}
                    </div>
                </React.Fragment>

            )
        }
    }
}

export default CreatableMulti
