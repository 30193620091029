import React from 'react';
import {connect} from 'react-redux';
import {createNewSCSystem} from '../../../redux/actions/scsystemsActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../../components/LoadingIndicator";
import FormField from "../../../components/FormField";
import SimpleReactValidator from "utils/simple-react-validator";
import shortId from "shortid";
import SystemMessage from '../../../components/SystemMessage';

class CodingSystemCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            language: {value: 'EN', label: 'English'},
            description: {},
            service: this.props.mcSystem.service,
            diagnosis: this.props.mcSystem.diagnosis,
            medicalCodingSystemCode: this.props.mcSystem.code,
            name: ''
        };

        let match = this.props;
        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This Code already exists in the system, the new record won\'t be created.',
                rule: function (val) {
                    return !match.code_names.split(',').includes(val);
                }
            }
        });
        this.validator.showMessages();

        this.Submit = this.Submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);

    }

    onChangeCheckBox(e) {
        // const newValue = e.target.value === 'true' ? false : true
        this.setState({[e.target.name]: e.target.checked});
    }

    Submit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            const data = {
                ...this.state,
                code: shortId.generate(),
                medicalCodingSystemCode: this.props.mcSystem.code
            }
            this.props.signUp(data);
        } else {
            this.setState({messagesShown: true})

        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onChangeDescription = (e) => {
        const {value} = e.target

        const description = Object.assign({}, this.state.description, {
            [this.state.language.value]: value
        })
        this.setState({description})
    }

    onChangeLanguage = (language) => this.setState({language})

    render() {
        // const {match} = this.props;
        const {name, description, language} = this.state;
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
        }));

        return (
            <div>
                <SystemMessage shown={this.state.messagesShown} type='error' message={this.props.error} validationMessages={errorMessages} top='0'/>

                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <form noValidate autoComplete="off"
                      className='modal-content custom-modal-content'
                      onSubmit={this.Submit}>
                    <div className="form-group">
                        <div className='row'>
                            <FormField
                                md="12"
                                label={this.context.translate('common.form.code_name')}
                                id={this.context.translate('common.form.code_name')}
                                required
                                valids="required|unique"
                                validator={this.validator}
                                value={name}
                                onChange={this.onChange}
                                name='name'
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className='row'>
                            <FormField
                                md="12"
                                label={this.context.translate('common.form.description')}
                                value={description[language.value] ? description[language.value] : ''}
                                onChange={this.onChangeDescription}
                                name='description'
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className='row'>
                            <FormField
                                md="4"
                                type="checkbox"
                                title={this.context.translate('common.form.status')}
                                label={this.context.translate('common.form.active')}
                                name='active'
                                value={this.state.active}
                                checked={this.state.active}
                                onChange={this.onChangeCheckBox}
                                className="checkbox"
                            />
                            <FormField
                                md="4"
                                type="checkbox"
                                title={!this.props.mcSystem.service ? this.context.translate('common.message.only_for_diagnoses') : this.context.translate('common.form.services')}
                                label={this.context.translate('common.form.services')}
                                name='service'
                                disabled={!this.props.mcSystem.service}
                                value={this.state.service}
                                checked={this.state.service}
                                onChange={this.onChangeCheckBox}
                                className="checkbox"
                            />
                            <FormField
                                md="4"
                                type="checkbox"
                                title={!this.props.mcSystem.diagnosis ? this.context.translate('common.message.only_for_services') : this.context.translate('common.form.diagnoses')}
                                label={this.context.translate('common.form.diagnoses')}
                                name='diagnosis'
                                disabled={!this.props.mcSystem.diagnosis}
                                value={this.state.diagnosis}
                                checked={this.state.diagnosis}
                                onChange={this.onChangeCheckBox}
                                className="checkbox"
                            />
                        </div>
                    </div>
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right">
                            <button
                                className='btn btn-block btn-flat btn-success'
                                disabled={this.props.isLoading}
                            >
                                {this.context.translate('common.client.add_code')}
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        );
    }
}

CodingSystemCreateForm.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => ({
    editCodingSystemSuccess: state.app.scSystem.editCodingSystemSuccess,
    isLoading: state.app.scSystem.isLoading,
    mcSystem: state.app.mcSystem.editedMCSystem,
    error: state.app.scSystem.error,
    code_names: state.app.scSystem.data
        .map((item) => {
            return item.name;
        }).join(',')
})

const mapDispatchToProps = dispatch => ({
    signUp: (item) => {
        dispatch(createNewSCSystem(item))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CodingSystemCreateForm)
