import React from 'react';
import {connect} from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'
import PropTypes from "prop-types";
import {createEntity, showValidation} from '../../../redux/actions/BasicEntityActions'
import {getMultiSelectValue, getSelectFieldValue} from '../../../utils/helpers'
import history from '../../../redux/history'

class CreateTierButton extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave() {
        const {isValid, tier, showValidation, group} = this.props;
        const tierNumber = group.billingTiers ? group.billingTiers.length + 1 : 1;

        if (isValid) {
            const data = {
                ...tier,
                tierNumber: tierNumber,
                name: 'Tier ' + tierNumber,
                caseTypes: getMultiSelectValue(tier.caseTypes),
                caseLevel: getSelectFieldValue(tier.caseLevel),
                reviewerTypes: getMultiSelectValue(tier.reviewerTypes),
                rateType: getSelectFieldValue(tier.rateType),
                refTier: getSelectFieldValue(tier.refTier),
            };

            this.props.onSave(data).then((value) => value && history.goBack())
        } else showValidation(false)
    }

    render() {
        return (
            <React.Fragment>
                <SubmitButton color='blue' disabled={!this.props.isProfileChanged}
                              onSubmit={(e) => this.onSave(e, true)} title='Add Tier'
                              showTooltip={!this.props.isTemplateValid}/>
            </React.Fragment>
        )
    }

    static propTypes = {
        isValid: PropTypes.bool,
        isProfileChanged: PropTypes.bool,
        isTemplateValid: PropTypes.bool,
        tier: PropTypes.object,
        group: PropTypes.object,
        showValidation: PropTypes.func,
        onSave: PropTypes.func,
    };

    static contextTypes = {
        translate: PropTypes.func
    };
}

const mapStateToProps = (state) => ({
    isValid: state.app.billing_tier.isValid,
    isProfileChanged: state.app.billing_tier.isProfileChanged,
    tier: state.app.billing_tier.editedBillingTier,
    group: state.app.billing_group.editedBillingGroup,
});

const mapDispatchToProps = dispatch => ({
    onSave: (data, closeAfterSave) => dispatch(createEntity('billing_tier', data, closeAfterSave)),
    showValidation: () => dispatch(showValidation('billing_tier'))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTierButton)
