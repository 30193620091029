import React from 'react'
import {connect} from 'react-redux';
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import {getSelectFieldValue, isClient, isUser} from '../../utils/helpers'
import {userEditField} from '../../redux/action-creators/users'
import FormField from "../../components/FormField";
import ContactInfoSection from "../../components/Sections/ContactInfoSection";
import AddressInfoSection from "../../components/Sections/AddressInfoSection";
import PropTypes from "prop-types";
import { getAllCompanies } from "../../redux/actions/companiesActions";

class UserEditGeneralInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.onChange = this.onChange.bind(this);
        this.changeRole = this.changeRole.bind(this);
    }

    componentDidMount() {
        if (this.props.companies && this.props.companies.length === 0) {
            this.props.getAllCompanies();
        }
    }

    changeRole(roleCode) {
        this.props.userEditField('roleCode', roleCode)
    }

    onChange(e) {
        if(e.target.name.toLowerCase().includes('email'))
            this.props.userEditField(e.target.name, e.target.value.replace(/\s+/g, ''));
        else
            this.props.userEditField(e.target.name, e.target.value );
    }

    onChangeState = (stateCode) => {
        this.props.userEditField('stateCode', stateCode)
    };

    onChangeCountry = (countryCode) => {
        this.props.userEditField('countryCode', countryCode);
        if (!countryCode || getSelectFieldValue(countryCode) + '' !== 'US') {
            this.props.userEditField('stateCode', null);
        }
    };

    render() {
        const { currentSessionUserAccountCode, user: {code}, user, companies, roles, validator, dispatchValidState, isEditable } = this.props;

        const isRoleFieldDisabledWhileEditMyself = !code || currentSessionUserAccountCode === code;
        return (
            <div className="content">
                <Box color="orange" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`Submitter - ${user.firstName} ${user.lastName}`}
                               color="orange" icon="fa-user" classes="box-profile-first-row-height-show"/>
                    <BoxBody>
                        <form onSubmit={this.submitUpdateUser}>
                            <div className="bordered form-group">
                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="12"
                                            type='label_field'
                                            label="Personal Information"
                                            className='margin-bottom-15 fontWeight800'
                                            style={{fontSize: '1.1em'}}
                                        />
                                        <FormField
                                            md="4"
                                            label={this.context.translate('common.users.first_name')}
                                            id={this.context.translate('common.users.first_name')}
                                            className="form-control"
                                            required
                                            name="firstName"
                                            valids="required|alpha_dash_space"
                                            validator={validator}
                                            value={user.firstName}
                                            onChange={this.onChange}
                                            onBlur={dispatchValidState}
                                            disabled={!isEditable}
                                        />
                                        <FormField
                                            md="4"
                                            label={this.context.translate('common.users.middle_name')}
                                            id={this.context.translate('common.users.middle_name')}
                                            className="form-control"
                                            name="middleName"
                                            valids="alpha_dash_space"
                                            validator={validator}
                                            value={user.middleName}
                                            onChange={this.onChange}
                                            onBlur={dispatchValidState}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="4"
                                            label={this.context.translate('common.users.last_name')}
                                            id={this.context.translate('common.users.last_name')}
                                            className="form-control"
                                            required
                                            name="lastName"
                                            valids="required|alpha_dash_space"
                                            validator={validator}
                                            value={user.lastName}
                                            onChange={this.onChange}
                                            onBlur={dispatchValidState}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group bordered">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        type='label_field'
                                        label="Security Information"
                                        className='margin-bottom-15 fontWeight800'
                                        style={{fontSize: '1.1em'}}
                                    />
                                    <FormField
                                        md="4"
                                        label={this.context.translate('common.users.company')}
                                        id={this.context.translate('common.users.company')}
                                        disabled={true}
                                        value={companies.find((item) => item.code === user.companyCode) ? companies.find((item) => item.code === user.companyCode).fullName : ''}
                                        onChange={this.onChange}
                                    />
                                    {
                                        isRoleFieldDisabledWhileEditMyself || roles.length === 0 ?
                                            <FormField
                                                md={"4"}
                                                type={"select"}
                                                label={this.context.translate('common.users.role')}
                                                id={this.context.translate('common.users.role')}
                                                className={"text-bg"}
                                                searchable
                                                disabled={true}
                                                multi={false}
                                                options={roles}
                                                name={"roleCode"}
                                                value={user.roleCode}
                                            />
                                            :
                                            <FormField
                                                md={"4"}
                                                type={"select"}
                                                label={this.context.translate('common.users.role')}
                                                id={this.context.translate('common.users.role')}
                                                className={"text-bg required-field"}
                                                required
                                                searchable
                                                disabled={false}
                                                multi={false}
                                                options={roles}
                                                name={"roleCode"}
                                                valids={"required"}
                                                validator={validator}
                                                value={user.roleCode}
                                                onChange={this.changeRole}
                                                onBlur={dispatchValidState}
                                            />
                                    }
                                </div>
                            </div>
                            <ContactInfoSection
                                validator={validator}
                                dispatchValidState={dispatchValidState}
                                onChange={this.onChange}
                                email={user.email}
                                notificationEmail={user.notificationEmail}
                                phoneNumber={user.phoneNumber}
                                alternativePhoneNumber={user.alternativePhoneNumber}
                                isEditable={isEditable}
                            />
                            <AddressInfoSection
                                onChange={this.onChange}
                                onChangeCountry={this.onChangeCountry}
                                onChangeState={this.onChangeState}
                                address1={user.address1}
                                address2={user.address2}
                                city={user.city}
                                countryCode={user.countryCode}
                                stateCode={user.stateCode}
                                postalCode={user.postalCode}
                                isEditable={isEditable}
                            />
                            {isClient(this.props.my_role) ?
                                '' :
                                <div className="form-group">
                                    <div className="row">
                                        <div className='col-md-8 col-xs-12 fields'>
                                            <label
                                                className="required">{this.context.translate('common.form.notes')}</label>
                                            <textarea name="notes" id="" cols="30" rows="7"
                                                      value={user.notes}
                                                      onChange={this.onChange}
                                                      disabled={!isEditable}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </form>
                    </BoxBody>
                    <BoxFooter>
                    </BoxFooter>
                </Box>
            </div>
        );
    }

    static propTypes = {
        isEditable: PropTypes.bool,
        roles: PropTypes.array,
        companies: PropTypes.array,
        user: PropTypes.object,
        validator: PropTypes.object,
        dispatchValidState: PropTypes.func,
    }

    static contextTypes = {
        translate: PropTypes.func
    };
}

const mapStateToProps = (state) => ({
    currentSessionUserAccountCode: state.app.auth.user.code,
    user: state.app.users.editedUser,
    isProfileValid: state.app.users.userValid,
    loading: state.app.users.profileLoading,
    editUserSuccess: state.app.users.editUserSuccess,
    isProfileChanged: state.app.users.isProfileChanged,
    error: state.app.users.editUserError,
    roles: state.app.users.roles
        .filter((item) => isUser(item.code) && item.active)
        .map(role => ({
            value: role.code,
            label: role.name
        })),
    companies: state.app.companies.data,
    my_role: state.app.auth.user.roleCode
});

const mapDispatchToProps = dispatch => ({
    userEditField: (field, value) => dispatch(userEditField(field, value)),
    getAllCompanies: () => dispatch(getAllCompanies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEditGeneralInfo)
