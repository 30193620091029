import React from 'react';
import {connect} from 'react-redux';
import {createNewMCSystem} from '../../../redux/actions/mcsystemsActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../../components/LoadingIndicator";
import FormField from "../../../components/FormField";
import SimpleReactValidator from "utils/simple-react-validator";
import shortId from "shortid";
import SystemMessage from '../../../components/SystemMessage';

class CodingSystemCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            service: false,
            diagnosis: false,
            name: ''
        };

        let match = this.props;
        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This Coding System already exists in the system, the new record won\'t be created.',
                rule: function (val) {
                    return !match.mc_names.split(',').includes(val);
                }
            }
        });
        this.validator.showMessages();

        this.Submit = this.Submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);

    }

    onChangeCheckBox(e) {
        // const newValue = e.target.value === 'true' ? false : true
        this.setState({[e.target.name]: e.target.checked});
    }

    Submit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            const data = {
                ...this.state,
                code: shortId.generate(),
            }
            this.props.signUp(data);
        } else {
            this.setState({messagesShown: true})

        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        // const {match} = this.props;
        // let showSuccess = this.props.editCodingSystemSuccess ? 'block' : 'none';
        // let showError = this.props.error ? 'block' : 'none';
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
        }))

        return (
            <div>
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <SystemMessage shown={this.state.messagesShown} type='error' message={this.props.error} validationMessages={errorMessages} top='0'/>

                <form noValidate autoComplete="off"
                      className='modal-content custom-modal-content'
                      onSubmit={this.Submit}>
                    <div className="form-group">
                        <div className='row'>
                            <FormField
                                md="12"
                                id={this.context.translate('common.form.coding_system')}
                                label={this.context.translate('common.form.coding_system')}
                                required
                                valids="required|unique"
                                validator={this.validator}
                                value={this.state.name}
                                onChange={this.onChange}
                                name='name'
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className='row'>
                            <FormField
                                md="4"
                                type="checkbox"
                                title={this.context.translate('common.form.status')}
                                label={this.context.translate('common.form.active')}
                                name='active'
                                value={this.state.active}
                                checked={this.state.active}
                                onChange={this.onChangeCheckBox}
                                className="checkbox"
                            />
                            <FormField
                                md="4"
                                type="checkbox"
                                title={this.context.translate('common.form.services')}
                                label={this.context.translate('common.form.services')}
                                name='service'
                                value={this.state.service}
                                checked={this.state.service}
                                onChange={this.onChangeCheckBox}
                                className="checkbox"
                            />
                            <FormField
                                md="4"
                                type="checkbox"
                                title={this.context.translate('common.form.diagnoses')}
                                label={this.context.translate('common.form.diagnoses')}
                                name='diagnosis'
                                value={this.state.diagnosis}
                                checked={this.state.diagnosis}
                                onChange={this.onChangeCheckBox}
                                className="checkbox"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row no-padding">
                            <div className="col-xs-4 pull-right">
                                <button
                                    className='btn btn-block btn-flat btn-success'
                                    disabled={this.props.isLoading}
                                >
                                    {this.context.translate('common.client.add_coding_system')}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

CodingSystemCreateForm.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => ({
    editCodingSystemSuccess: state.app.mcSystem.editCodingSystemSuccess,
    isLoading: state.app.mcSystem.isLoading,

    error: state.app.mcSystem.error,
    mc_names: state.app.mcSystem.data
        .map((item) => {
            return item.name;
        }).join(',')
})

const mapDispatchToProps = dispatch => ({
    signUp: (procedure) => {
        dispatch(createNewMCSystem(procedure))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CodingSystemCreateForm)
