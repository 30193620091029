const defaultState = {
    loading: false,
    data: [],
    error: '',
    loadedCount: 0,
    lastLoadedTime: 0,
}

const timezone = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_TIME_ZONE_LOADING':
            return { ...state, loading: true }
        case 'FETCH_TIME_ZONE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            })
        //
        case 'FETCH_TIME_ZONE_LOADED_COUNT':
            return { ...state, loadedCount: action.payload }

        case 'FETCH_TIME_ZONE_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            }

        case 'FETCH_TIME_ZONE_ERROR':
            return { ...state, error: action.payload, loading: false }
        case 'FETCH_COUNTRY_LOADED_COUNT':
            return { ...state, loadedCount: action.payload }

        default:
    }

    return state;
}

export default timezone;
