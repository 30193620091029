export function sessionExpired(lastUserActivityTimeStamp, inactionTimeLimitSeconds){
    return ((getCurrentTimeStamp() - lastUserActivityTimeStamp) > inactionTimeLimitSeconds);
}

export function triggerLogout(){
    localStorage.removeItem('LAST_USER_ACTIVITY_TIMESTAMP');
    localStorage.setItem('MEDREV_SESSION_STATUS', 'finished');
}

export function resetLastUserActivityTimeStamp(){
    localStorage.removeItem('LAST_USER_ACTIVITY_TIMESTAMP');
    localStorage.setItem('LAST_USER_ACTIVITY_TIMESTAMP', getCurrentTimeStamp());
}

export function getCurrentTimeStamp(){
    return Math.floor(Date.now() / 1000);
}