import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';
import { patientCaseStages } from '../patientCaseStages';

const reviewSlice = createDatatableTemplateSlice({
    name: patientCaseStages.REVIEW,
    initialSettings: {},
});

export const reviewReducer = reviewSlice.reducer;

export const { loadData, dataLoaded, dataLoadError } = reviewSlice.actions;

export default reviewSlice;
