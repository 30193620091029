import React from 'react'
import ProfileUserDataClients from './UserDataClients'
import ProfileUserDataReviewer from './UserDataReviewer'
import ProfileUserDataStaff from './UserDataStaff'
import {getMultiSelectValue, getSelectFieldValue, isAdmin, isClient, isReviewer, isStaff} from '../../utils/helpers'
import {getMyProfile, saveNewPassword, saveProfile, saveSignature, setSuccess} from "../../redux/actions/profileActions";

import {connect} from "react-redux";

import {getAllLocales} from '../../redux/actions/localeActions';
import {getLanguages} from "../../redux/actions/languageActions";
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';

class ProfilePage extends React.Component {
    componentDidMount() {
        this.props.getAllLocales();
        this.props.getLanguages();
    }

    constructor(props) {
        super(props);
        this.state = {
            role: ''
        }
    }

    onSave = () => {
        if (this.props.isProfileValid) {
            const {newPassword, oldPassword, repeatNewPassword} = this.props.profile;

            if (!!newPassword && !!oldPassword && !!repeatNewPassword && this.props.isPasswordValid) {
                this.props.saveNewPassword({oldPassword, newPassword, repeatNewPassword})
            }

            return this.props.saveProfile({
                ...this.props.profile,
                active: true,
                timeZoneCode: getSelectFieldValue(this.props.profile.timeZoneCode),
                countryCode: getSelectFieldValue(this.props.profile.countryCode),
                stateCode: getSelectFieldValue(this.props.profile.stateCode),
                specialtyCodes: getMultiSelectValue(this.props.profile.specialtyCodes),
                languageCodes: getMultiSelectValue(this.props.profile.languageCodes),
                defaultLanguageCode: getSelectFieldValue(this.props.profile.defaultLanguageCode),
                defaultLocaleCode: getSelectFieldValue(this.props.profile.defaultLocaleCode),
                // roleCode: getSelectFieldValue(this.props.profile.profile.roleCode),
                // defaultLocaleCode: getSelectFieldValue(this.props.profile.defaultLocaleCode)
            })

            // if (isReviewer(this.props.profile.roleCode) && this.props.profile.savedSignature[0]) {
            //     this.props.saveSignature({
            //         code: this.props.profile.code,
            //         file: this.props.profile.savedSignature[0]
            //     })
            // }
        }
    }

    render() {
        if (this.props.showSuccess) {
            setTimeout(() => {
                this.props.hideSuccessMessage();
            }, 3000);
        }
        let user = this.props.currentUser;
        let role;
        if (isAdmin(user.roleCode)) role = 'ADMIN';
        if (isStaff(user.roleCode)) role = 'STAFF';
        if (isReviewer(user.roleCode)) role = 'REVIEWER';
        if (isClient(user.roleCode)) role = 'CLIENT';
        return (
            <section className="content user-profile-content">
                <PreventTransitionPrompt
                    when={this.props.isProfileChanged}
                    message='MyCustomDialogComponent'
                    entity='profile'
                    isFormValid={this.props.isProfileValid}
                    saveChanges={this.onSave}
                    cancelAndDiscard={() => this.props.getProfile()}
                />

                <div className="row">
                    {(role === 'CLIENT') ?
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <ProfileUserDataClients/>
                        </div> : ''
                    }
                    {(role === 'REVIEWER') ?
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <ProfileUserDataReviewer/>
                        </div> : ''
                    }
                    {(role === 'STAFF') ?
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <ProfileUserDataStaff/>
                        </div> : ''
                    }
                    {(role === 'ADMIN') ?
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <ProfileUserDataStaff/>
                        </div> : ''
                    }
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.app.auth.user,
    showSuccess: state.app.profile.showSuccess,
    profile: state.app.profile.data,
    isProfileChanged: state.app.profile.isProfileChanged,
    isPasswordValid: state.app.profile.isPasswordValid,
    isProfileValid: state.app.profile.profileValid,

});

const mapDispatchToProps = dispatch => {
    return {
        getLanguages: () => dispatch(getLanguages()),
        getAllLocales: () => dispatch(getAllLocales()),
        hideSuccessMessage: () => dispatch(setSuccess(false)),
        saveProfile: data => dispatch(saveProfile(data)),
        saveSignature: data => dispatch(saveSignature(data)),
        getProfile: () => dispatch(getMyProfile()),
        saveNewPassword: ChangePassword => dispatch(saveNewPassword(ChangePassword))

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
