/* eslint-disable object-shorthand */
/* eslint-disable comma-dangle */
import { createApiParams } from 'components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import { fetchEntityList$ } from "../../epicUtils/commonEpics";
import facilitySlice from "./facilitySlice";
import { transformFacilities } from "./utils";

const loadFacilitiesEpic = fetchEntityList$({
    ...facilitySlice.actions,
    resolveUrl: () => 'facility_minimal/page',
    resolveParams: (params) => [{ params: createApiParams(params) }],
    dataTransformer: transformFacilities,
});

export default combineEpics(loadFacilitiesEpic);
