import React from 'react';

export const DataTableNoDataWarning = () => (
  <tbody>
    <tr>
      <td colSpan="100%" className="react-bs-table-no-data">
        There is no data to display
      </td>
    </tr>
  </tbody>
);

export default DataTableNoDataWarning;
