import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { getPatientCaseStateKey } from 'redux/pages/patient-case/patientCaseUtils';

const useGridVisibility = (sliceName) => {
    const { totalRecords } = useSelector((state) => get(state.app, getPatientCaseStateKey(sliceName), { totalRecords: 0 }));

    return totalRecords > 0;
};

export default useGridVisibility;
