import React from 'react'
import LoadingIndicator from "../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import {getAll, setCurrentTab} from '../../redux/actions/BasicEntityActions'
import PropTypes from "prop-types";
import {Tab, Tabs} from "react-bootstrap";
import CodingSystemList from "./CodingSystems/CodingSystemList";
import ProceduresList from "./Procedures/ProceduresList";
import DiagnosisList from "./Diagnoses/DiagnosisList";
import { getAllSpecialties } from "../../redux/actions/specialtyActions";

class MCSystemLists extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mcSystem: '',
            key: props.match.params.type ? props.match.params.type : "codes",
            params: {
                'page': 0,
                'size': 10,
                'sort': '',
                'dir': 'asc',
                'search': ''
            }
        };
        // this.onRowClick = this.onRowClick.bind(this);
        // this.onRowClick2 = this.onRowClick2.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        this.props.getAllMc();
        this.props.getAllSc();
        this.props.getAllProcedures();
        this.props.getAllDiagnoses();
        this.props.getAllBodyParts();
        this.props.getAllSpecialties();
    }

//
    // onRowClick(item) {
    //     this.setState({ item })
    //     this.props.history.push(`/facility/${item}`)
    // }
    //
    // onRowClick2(item) {
    //     this.setState({ item })
    //     this.props.history.push(`/facility_type/${item}`)
    // }
    //
    // enumFormatter(cell, row, enumObject) {
    //     return enumObject[cell];
    // }

    handleSelect(key) {
        this.props.setCurrentTab(key)
    }

    render() {
        const {isLoading} = this.props;
        return (
            <div className='container-fluid'>
                {isLoading && <LoadingIndicator type="tabs"/>}
                <Tabs activeKey={this.props.currentTab} animation={true} id="noanim-tab-example" onSelect={this.handleSelect}>
                    <Tab eventKey="codes" title={this.context.translate('common.form.coding_systems')}>
                        <CodingSystemList/>
                    </Tab>
                    <Tab eventKey="procedures" title={this.context.translate('common.form.services')}>
                        <ProceduresList/>
                    </Tab>
                    <Tab eventKey="diagnoses" title={this.context.translate('common.form.diagnoses')}>
                        <DiagnosisList/>
                    </Tab>
                </Tabs>
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
    }
    static defaultProps = {
        isLoading: false
    };
}

MCSystemLists.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.app.mcSystem.loading,
        mc_systems: state.app.mcSystem.data,
        currentTab: state.app.mcSystem.currentTab,
        procedures: state.app.procedures.data
            .map(item => {
                return {
                    ...item,
                    bodyPartCodes: item.bodyPartCodes ? item.bodyPartCodes.join(', ') : ''
                }
            }),
        diagnoses: state.app.diagnoses.data
            .map(item => {
                return {
                    ...item,
                    specialtyCodes: item.specialtyCodes ? item.specialtyCodes.join(', ') : '',
                    bodyPartCodes: item.bodyPartCodes ? item.bodyPartCodes.join(', ') : ''
                }
            }),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllMc: () => dispatch(getAll('mc_system')),
        getAllSc: () => dispatch(getAll('sc_system')),
        getAllProcedures: () => dispatch(getAll('med_service')),
        getAllDiagnoses: () => dispatch(getAll('med_diagnosis')),
        getAllBodyParts: () => dispatch(getAll('body_part')),
        setCurrentTab: (tab) => dispatch(setCurrentTab(tab, 'mc_system')),
        getAllSpecialties: () => dispatch(getAllSpecialties()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MCSystemLists);
