import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';

const ButtonLoader = forwardRef(({ text, className, onClick }, ref) => {
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        setLoading: (loading) => setIsLoading(loading),
      };
    },
    [],
  );

  return (
    <div className={'button-loader'}>
      <button className={className} onClick={onClick} disabled={isLoading}>
        <span>{text}</span>&nbsp;{isLoading && <Spinner />}
      </button>
    </div>
  );
});

ButtonLoader.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonLoader.defaultProps = {
  className: 'btn btn-default',
};

export default ButtonLoader;
