import React from 'react';
import {connect} from 'react-redux';
import SubmitButton from '../../components/SubmitButton'
import PropTypes from "prop-types";
import {createTemplate, dispatchTemplateValidStatus, updateTemplate, updateFieldForReport} from '../../redux/actions/templateActions'

class UpdateTemplateButton extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        if (this.props.isTemplateValid) {
            const {template} = this.props;
            const fieldInstanceCodes = template.fieldInstancesDto.map((item) => item.code);
            const names = {'EN': template.name};
            if(this.props.currentTab === 'report') {
              this.props.updateFieldForReport({...template, names, fieldInstanceCodes}, closeAfterSave)
            } else {
              this.props.updateTemplate({...template, names, fieldInstanceCodes}, closeAfterSave)
            }
        } else this.props.dispatchTemplateValidStatus(false)
    }

    render() {
        return (
            <React.Fragment>
                <SubmitButton color='blue' disabled={!this.props.isTemplateChanged} onSubmit={this.onSave}
                              showTooltip={!this.props.isTemplateValid}/>
                <SubmitButton color='blue' disabled={!this.props.isTemplateChanged}
                              onSubmit={(e) => this.onSave(e, true)} title='Save And Close'
                              showTooltip={!this.props.isTemplateValid}/>
            </React.Fragment>
        )
    }
}

UpdateTemplateButton.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    isTemplateValid: state.app.templates.isTemplateValid,
    isTemplateChanged: state.app.templates.isTemplateChanged,
    template: state.app.templates,
    isTemplateExist: state.app.templates.allTemplates.find((item) => item.code === state.app.templates.code),
    currentTab: state.app.templates.currentTab
});

const mapDispatchToProps = dispatch => ({
    createTemplate: data => dispatch(createTemplate(data)),
    updateTemplate: (data, closeAfterSave) => dispatch(updateTemplate(data, closeAfterSave)),
    updateFieldForReport: (data, closeAfterSave) => dispatch(updateFieldForReport(data, closeAfterSave)),
    dispatchTemplateValidStatus: data => dispatch(dispatchTemplateValidStatus(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTemplateButton)
