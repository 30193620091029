import React, { useContext } from 'react';
import DataTablecontext from './DataTableContext';

const DataTableColumnDefs = () => {
  const { selectable, columns, columnKeys, hasDelete, hasActions } = useContext(
    DataTablecontext,
  );
  return (
    <colgroup>
      {selectable && <col style={{ width: 50 }} />}

      {columnKeys.map((col) => (
        <col key={col} data-column-key={col} style={columns[col].colStyle} />
      ))}

      {hasActions && <col className={'datatable__col-actions'} /*style={{ width: 80 }}*/ />}
      {hasDelete && <col style={{ width: 70 }} />}
    </colgroup>
  );
};
export default DataTableColumnDefs;
