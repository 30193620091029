import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createEntityLoading as createCaseFileTemplate, resetEntity } from '../../redux/pages/case-file-template/caseFileTemplateSlice';
import CaseFileTemplateForm, { FORM_TYPES } from './CaseFileTemplateForm';
import SubmitButtonGroup from 'components/Form/SubmitButton';

const CaseFileTemplateCreateForm = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetEntity());
  }, [dispatch]);

  const onSubmit = (entity) => {
    dispatch(
      createCaseFileTemplate({
        ...entity,
        clients: entity.clients.map(({ value }) => ({ code: value })),
      }),
    );
  };

  return (
    <CaseFileTemplateForm
      className="modal-content custom-modal-content"
      onSubmit={onSubmit}
      type={FORM_TYPES.new}
    >
      <SubmitButtonGroup title="Add Case File Template"/>
    </CaseFileTemplateForm>
  );
};

export default CaseFileTemplateCreateForm;
