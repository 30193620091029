import React, {Component} from "react";
import {connect} from 'react-redux'
import FormField from '../../components/FormField'
import {editPhysician, editField, createNewPhysician} from '../../redux/actions/physicianActions'
import {getMultiSelectValue, getSelectFieldValue} from '../../utils/helpers'
import PropTypes from "prop-types";
import shortId from "shortid";
import SystemMessage from '../../components/SystemMessage';

class PhysicianCommonInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.onChange = this.onChange.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeSpecialty = this.onChangeSpecialty.bind(this);
        this.onChangeLanguagesCode = this.onChangeLanguagesCode.bind(this);
        this.onChangeBodyParts = this.onChangeBodyParts.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.Submit = this.Submit.bind(this);
    }

    componentDidMount() {
        setTimeout(this.props.dispatchValidState, 500)
    }

    Submit(e) {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            this.props.newPhysician({
                ...this.props.physician,
                code: shortId.generate(),
                countryCode: getSelectFieldValue(this.props.physician.countryCode),
                stateCode: getSelectFieldValue(this.props.physician.stateCode),
                specialtyCodes: getMultiSelectValue(this.props.physician.specialtyCodes),
                bodyPartCodes: getMultiSelectValue(this.props.physician.bodyPartCodes),
                languageCodes: getMultiSelectValue(this.props.physician.languageCodes)
            });
        } else {
            this.props.showMessages()
        }
    }

    onChange(e) {
        this.setState({showSuccess: false});
        this.props.editField(e.target.name, e.target.value);


    }

    changeCountry(countryCode) {
        this.setState({showSuccess: false});
        this.props.editField('countryCode', countryCode);
        if (countryCode) {
            if (countryCode.value !== 'US') {
                this.props.editField('stateCode', null);
            }
        } else {
            this.props.editField('stateCode', null)
        }
    }

    onChangeState(stateCode) {
        this.setState({showSuccess: false});
        this.props.editField('stateCode', stateCode)

    }

    onChangeLanguagesCode(languageCodes) {
        this.setState({showSuccess: false});
        this.props.editField('languageCodes', languageCodes)


    }

    onChangeSpecialty(specialtyCodes) {
        this.setState({showSuccess: false});
        this.props.editField('specialtyCodes', specialtyCodes)

    }

    onChangeBodyParts(bodyPartCodes) {
        this.setState({showSuccess: false});
        this.props.editField('bodyPartCodes', bodyPartCodes)

    }

    onChangeCheckBox(e) {
        this.setState({[e.target.name]: e.target.checked});
        this.props.editField(e.target.name, e.target.checked);
    }

    render() {
        const {showSuccess} = this.state;

        return (
            <div>
                <SystemMessage
                    shown={showSuccess}
                    type='success'
                    message='Profile successfully updated'
                />
                <form onSubmit={this.Submit} noValidate autoComplete="off">
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="6"
                                label={this.context.translate('common.form.first_name')}
                                id={this.context.translate('common.form.first_name')}
                                className="form-control"
                                required
                                valids="required|alpha_dash_space"
                                validator={this.props.validator}
                                name="firstName"
                                value={this.props.physician.firstName}
                                onChange={this.onChange}
                                onBlur={this.props.dispatchValidState}
                            />
                            <FormField
                                md="6"
                                label={this.context.translate('common.form.last_name')}
                                id={this.context.translate('common.form.last_name')}
                                className="form-control"
                                required
                                valids="required|alpha_dash_space"
                                validator={this.props.validator}
                                name="lastName"
                                value={this.props.physician.lastName}
                                onChange={this.onChange}
                                onBlur={this.props.dispatchValidState}

                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="6"
                                label={this.context.translate('common.form.title')}
                                className="form-control"
                                name="title"
                                value={this.props.physician.title}
                                onChange={this.onChange}
                            />
                            <FormField
                                md="6"
                                label={this.context.translate('common.form.code')}
                                className="form-control"
                                name="physicianCode"
                                value={this.props.physician.physicianCode}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="6"
                                type="REF_SPECIALTY"
                                label={this.context.translate('common.form.specialties')}
                                id={this.context.translate('common.form.specialties')}
                                className="required-field"
                                required
                                value={this.props.physician.specialtyCodes}
                                onChange={this.onChangeSpecialty}
                                onBlur={this.props.dispatchValidState}
                                validator={this.props.validator}
                                name="specialtyCodes"
                                valids="required"
                                multi
                            />
                            <FormField
                                md="6"
                                type="REF_BODY_PART"
                                label={this.context.translate('common.form.body_parts')}
                                id={this.context.translate('common.form.body_parts')}
                                className="required-field"
                                name="bodyPartCodes"
                                required
                                value={this.props.physician.bodyPartCodes}
                                onChange={this.onChangeBodyParts}
                                onBlur={this.props.dispatchValidState}
                                validator={this.props.validator}
                                valids="required"
                                multi
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="6"
                                type="select"
                                label={this.context.translate('common.form.tables.languages')}
                                multi={true}
                                options={this.props.languages}
                                id="user_edit_language"
                                name="languageCodes"
                                value={this.props.physician.languageCodes}
                                onChange={this.onChangeLanguagesCode}
                            />
                            <FormField
                                md="6"
                                label={this.context.translate('common.form.email')}
                                id={this.context.translate('common.form.email')}
                                className="form-control"
                                valids="custom_email"
                                validator={this.props.validator}
                                name="email"
                                value={this.props.physician.email}
                                onChange={this.onChange}
                                onBlur={this.props.dispatchValidState}

                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="6"
                                label={this.context.translate('common.form.phone_number')}
                                className="form-control"
                                name="phoneNumber"
                                value={this.props.physician.phoneNumber}
                                onChange={this.onChange}
                            />
                            <FormField
                                md="6"
                                label={this.context.translate('common.form.address_1')}
                                className="form-control"
                                id="user_edit_address1"
                                name="address1"
                                value={this.props.physician.address1}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="6"
                                label={this.context.translate('common.form.address_2')}
                                className="form-control"
                                id="user_edit_address2"
                                name="address2"
                                value={this.props.physician.address2}
                                onChange={this.onChange}
                            />
                            <FormField
                                md="6"
                                label={this.context.translate('common.form.city')}
                                className="form-control"
                                name="city"
                                value={this.props.physician.city}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="6"
                                type='REF_STATE'
                                label={this.context.translate('common.form.state')}
                                name='stateCode'
                                className="text-bg "
                                disabled={getSelectFieldValue(this.props.physician.countryCode) + '' !== 'US'}
                                value={this.props.physician.stateCode}
                                onChange={this.onChangeState}
                            />
                            <FormField
                                md="6"
                                label={this.context.translate('common.form.postal_code')}
                                className="form-control"
                                name="postalCode"
                                value={this.props.physician.postalCode}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="6"
                                type="checkbox"
                                title={this.context.translate('common.form.private_facility')}
                                label={this.context.translate('common.form.private_facility')}
                                name='private_facility'
                                value={this.props.physician.private_facility}
                                checked={this.props.physician.private_facility}
                                onChange={this.onChangeCheckBox}
                                className="checkbox"
                                classBox="checkbox-padding"
                            />
                            <FormField
                                md="6"
                                type="select"
                                label={this.context.translate('common.form.country')}
                                className="text-bg "
                                disabled={false}
                                options={this.props.countries}
                                id="user_edit_country"
                                name="countryCode"
                                value={this.props.physician.countryCode}
                                onChange={this.changeCountry}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className='row'>
                            <FormField
                                md="6"
                                type="checkbox"
                                title={this.context.translate('common.form.status')}
                                label={this.context.translate('common.form.active')}
                                name='active'
                                value={this.props.physician.active}
                                checked={this.props.physician.active}
                                onChange={this.onChangeCheckBox}
                                className="checkbox"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className='col-md-12 col-xs-6 fields'>
                                <label className="">{this.context.translate('common.form.notes')}</label>
                                <textarea name="notes" id="" cols="50" rows="8" value={this.props.physician.notes}
                                          onChange={this.onChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row no-padding" style={{display: this.props.physician.code ? 'none' : 'block'}}>
                            <div className="col-xs-4 pull-right">
                                <button
                                    className='btn btn-block btn-flat btn-success'
                                    disabled={this.props.isLoading}
                                >
                                    {this.context.translate('common.client.add_physician')}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

PhysicianCommonInfo.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        physician: state.app.physician.editedPhysician,
        error: state.app.physician.editGeneralError,
        isLoading: state.app.physician.loading,
        countries: state.app.countries.data
            .map(country => ({
                value: country.code,
                label: country.name
            })),
        languages: state.app.languages.data
            .filter((item) => item.active)
            .map(lang => ({
                value: lang.code,
                label: lang.name
            }))

    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveProfile: data => dispatch(editPhysician(data, 'admin')),
        newPhysician: data => dispatch(createNewPhysician(data)),
        editField: (field, value) => dispatch(editField(field, value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianCommonInfo);
