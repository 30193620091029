import api from "../../utils/api";
import history from "../history";
import { Statuses } from "./statuses";

export const createNewPatient = (patient) => (dispatch) => {
    dispatch({
        type: 'FETCH_PATIENT_LOADING',
    })

    api.post('patient/create', patient)
        .then(() => {
            dispatch({
                type: 'FETCH_CREATE_PATIENT_SUCCESS',
                payload: patient
            })
            dispatch({
                type: 'CLOSE_CREATE_MODAL'
            })
            history.push(`/patients/profile/${patient.code}`)
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PATIENT_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_PATIENT_ERROR',
                    payload: Statuses[ error.request.status ]
                });
            }
        })
}

export const getAllPatientMinimal = (caseId) => (dispatch) => {
    dispatch({
        type: 'FETCH_PATIENT_MINIMAL_LOADING',
    });

    api.get('patient/patients-by-case?caseCode=' + caseId)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_PATIENT_MINIMAL_SUCCESS',
                payload: data
            });
            dispatch({
                type: 'FILL_PATIENT_LOCAL_STORAGE'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PATIENT_MINIMAL_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.error(error.request);
            } else {
                console.error('Error ', error.message);
                dispatch({
                    type: 'FETCH_PATIENT_MINIMAL_ERROR',
                    payload: error.message
                });
            }
        })
}

export const fillSelectedPatientData = (patientData) => (dispatch) => {
  dispatch({
      type: 'FILL_SELECTED_PATIENT_DATA',
      payload: patientData,
  });
};
