import React from 'react';
import {connect} from 'react-redux'
import {Tab, Tabs} from 'react-bootstrap';
import SimpleReactValidator from "utils/simple-react-validator";
import LoadingIndicator from '../../components/LoadingIndicator';
import EditTemplatePage from './EditTemplatePage';
import Report from './Report';
import FieldsList from './Fields/FieldsList';
import SectionsList from './Sections/SectionsList';
import ClientList from './Clients/ClientList';
import {getFullTemplate, isTemplateValid, setCurrentTab} from "../../redux/actions/templateActions";
import {getAllFields} from "../../redux/actions/fieldsActions";
import {closeCreateModal} from '../../redux/action-creators/users'
import SystemMessage from '../../components/SystemMessage'
import PropTypes from "prop-types";

class TemplateManagePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.validator = new SimpleReactValidator({
            alpha_num_dash_space: {
                message: 'The :attribute may only contain letters, numbers, dashes, and spaces.',
                rule: (val) => val.toString().match(/^[A-Z0-9 _-]*$/i) !== null
            },
            custom_value: {
                message: 'The list options does not contain this value.',
                rule: this.isDefaultValueValid
            },
            unique_template: {
                message: 'This Template already exists in the system.',
                rule: (val) => !this.props.templateNames.includes(val)
            },
        });
        this.validator.showMessages()
    }

    componentDidMount() {
        this.props.closeCreateModal();
        this.props.getFullTemplate(this.props.match.params.id)
            .then(this.dispatchValidState);
        this.props.getAllFields();
        this.props.setCurrentTab('field_instances')
    }

    componentWillUnmount() {
        // console.log('un');
        this.props.setCurrentTab('field_instances')
    }

    handleSelect = (key) => {
        this.props.setCurrentTab(key)
    };

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        this.forceUpdate();
    };

    render() {
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
            tab: 'template'
        }));
        const {isLoading} = this.props;
        return (
            <section className="content user-profile-content">
                <div className='col-md-12'>
                    {isLoading && <LoadingIndicator type="tabs"/>}
                    <Tabs activeKey={this.props.currentTab}
                          defaultActiveKey={this.props.currentTab ? this.props.currentTab : 'field_instances'}
                          animation={true} id="noanim-tab-example" onSelect={this.handleSelect}>
                        <SystemMessage shown={this.props.templateEditSuccess && this.props.isTemplateFormValid}
                                       type='success' message='Template successfully updated' top='7em'/>
                        <SystemMessage shown={!this.props.isTemplateFormValid} type='error' message={this.props.error}
                                       validationMessages={errorMessages} goToTab={this.handleSelect} top='7em'/>

                        <Tab eventKey='template' title="Template">
                            <div className='row'>
                                <EditTemplatePage history={this.props.history} validator={this.validator}
                                                  dispatchValidState={this.dispatchValidState}/>
                            </div>
                        </Tab>

                        <Tab eventKey='field_instances' title="Fields" disabled={!this.props.isTemplateExist}>
                            <div className='row'>
                                <FieldsList history={this.props.history}/>
                            </div>
                        </Tab>

                        <Tab eventKey='sections' title="Sections" disabled={!this.props.isTemplateExist}>
                            <div className='row'>
                                <SectionsList history={this.props.history}/>
                            </div>
                        </Tab>

                        <Tab eventKey='clients' title="Clients" disabled={!this.props.isTemplateExist}>
                            <div className='row'>
                                <ClientList history={this.props.history}/>
                            </div>
                        </Tab>

                        <Tab eventKey='report' title="Report">
                            <div className='row'>
                                <Report history={this.props.history} validator={this.validator}
                                                  dispatchValidState={this.dispatchValidState}/>
                            </div>
                        </Tab>

                    </Tabs>
                </div>
            </section>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
    }
    static defaultProps = {
        isLoading: false
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.app.templates.loading,
    error: state.app.templates.error,
    currentTab: state.app.templates.currentTab,
    templateEditSuccess: state.app.templates.templateEditSuccess,
    isTemplateFormValid: state.app.templates.isTemplateFormValid,
    currentPage: state.router.location.pathname,
    templateNames: state.app.templates.allTemplates
        .filter((item) => item.code !== state.app.templates.code)
        .map((item) => item.name),
    // isTemplateExist: state.app.templates.allTemplates.find((item) => item.code === state.app.templates.code)
    isTemplateExist: true
});

const mapDispatchToProps = dispatch => {
    return {
        getFullTemplate: (code) => dispatch(getFullTemplate(code)),
        getAllFields: () => dispatch(getAllFields()),
        setCurrentTab: (tab) => dispatch(setCurrentTab(tab)),
        closeCreateModal: () => {
            dispatch(closeCreateModal())
        },
        isAllValid: (valid) => dispatch(isTemplateValid(valid)),


    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateManagePage);
