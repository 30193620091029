import {connect} from 'react-redux'
import ManageFieldForm from './ManageFieldForm'
import {dispatchValidStatus, editField, getField, isAllValid, updateField} from '../../../redux/actions/fieldsActions'
import {getLanguages} from '../../../redux/actions/languageActions'
import {getAllStages} from '../../../redux/actions/stages'
import {getAllRoles, openEditModal} from '../../../redux/action-creators/users'

const mapStateToProps = (state) => ({
    mode: 'edit',
    field: state.app.fields.editedField,
    languageCodes: state.app.languages.data,
    label: 'Edit Field',
    error: state.app.fields.error,
    loading: state.app.fields.loading,
    editedSuccess: state.app.fields.editedSuccess,
    isFormValid: state.app.fields.isFormValid,
    isFieldValid: state.app.fields.fieldValid,
    isProfileChanged: state.app.fields.isProfileChanged,

});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: field => dispatch(updateField(field)),
        handleOpenModal: () => {
            dispatch(openEditModal())
        },
        getInitData: () => {
            dispatch(getLanguages());
            dispatch(getAllStages());
            dispatch(getAllRoles());
            dispatch(dispatchValidStatus(true));
            dispatch(getField(ownProps.match.params.id))

        },
        editField: (field, value) => dispatch(editField(field, value)),
        isAllValid: (valid) => dispatch(isAllValid(valid)),
        updateField: data => dispatch(updateField(data)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageFieldForm)
