const defaultState = {
    loading: false,
    loadedCount: 0,
    data: [],
    error: ''
};

const procedures = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_BILLING_TIER_LOADING':
            return {...state, loading: true};
        case 'FETCH_BILLING_TIER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null
            });

        case 'FETCH_BILLING_TIER_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            };
        case 'FILL_BILLING_TIER_LOCAL_STORAGE':
            let items = {};
            for (let i = 0; i < state.data.length; i++) {
                items[state.data[i].code] = state.data[i]['tierName'];
            }
            localStorage.setItem('list_billing_tier', JSON.stringify(items));
            return state;
        case 'FETCH_BILLING_TIER_LOADED_COUNT':
            return {...state, loadedCount: action.payload};

        case 'FETCH_BILLING_TIER_ERROR':
            return {...state, error: action.payload, loading: false};
        default:
            return state
    }
};

export default procedures
