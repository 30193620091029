import React from 'react';
import { getCaseTemplate } from "../../../redux/actions/templateActions";
import { hideSearchPanel } from "../../../redux/actions/gridActions";
import { getCase, getCaseFiles, getCaseValues, setCaseMessage, showNotValidMessage } from "../../../redux/actions/cases/caseActions";
import connect from "react-redux/es/connect/connect";
import TemplatesInner from "./TemplatesInner";
import { getActiveCountries, getActiveStates } from "../../../redux/actions/countryActions";
import LoadingIndicator from '../../../components/LoadingIndicator';
import CaseAccept from "../Buttons/CaseAccept";
import CaseReject from "../Buttons/CaseReject";
import CaseDecideLater from "../Buttons/CaseDecideLater";
import CaseDownloadMergeDoc from "../Buttons/CaseDownloadMergeDoc";
import ColoredBox from "../../../components/ColoredBox/ColoredBox";
import PropTypes from "prop-types";
import CaseReopenModal from 'pages/CaseReopenModal';

class Details extends React.Component {

    componentDidMount() {
        this.props.hideSearchPanel();
    }

    reviewerAcceptance() {
        // only for Reviewers
        let detailAction = '';
        if (this.props.currentCase) {
            let aDetails = this.props.assignmentDetails;
            for (let i = 0; i < aDetails.length; i = i + 1) {
                if (aDetails[ i ].reviewerCode === this.props.currentUser.code) {
                    detailAction = aDetails[ i ].action;
                }
            }
        }
        if (detailAction === '' || detailAction === 'ACCEPTED' || this.props.loading) return <></>;
        return (
            <ColoredBox title="Important notice" variant="warning" className="">
                <div className={'legal-statement-wrapper'}>
                    <p>
                        <strong>By accepting this case, you declare the following statements to be true and accurate:</strong>
                    </p>
                    <ol className={'legal-statement'} style={{listStyle: 'lower-alpha'}}>
                        <li>Reviewer compensation is not dependent on case determination/outcome;</li>
                        <li>Reviewer was not involved with the episode of care under review;</li>
                        <li>
                            Reviewer does not have a material professional, familial, or financial conflict of interest
                            regarding any of the following:
                            <ol style={{listStyle: 'upper-roman'}}>
                                <li>The patient; OR</li>
                                <li>The referring healthcare provider; OR</li>
                                <li>The facility at which the service under review would be provided; OR</li>
                                <li>The payer; OR</li>
                                <li>The developer or manufacturer of the medical service under review.</li>
                            </ol>
                        </li>
                        <li>Reviewer has the appropriate licensure or certification that typically manages the medical service under review;</li>
                        <li>Reviewer has current, relevant experience and/or knowledge to render a determination for the case under review.</li>
                    </ol>
                    <p className={'text-center mg-20'}>
                        <CaseAccept/>
                        <CaseReject/>
                        <CaseDecideLater/>
                        <CaseDownloadMergeDoc/>
                    </p>
                </div>
            </ColoredBox>
        )
    }

    render() {
        return (
            <>
                <section className="content case-details-content">
                    {this.reviewerAcceptance()}
                    {this.props.loading ? <LoadingIndicator height={50}/> : null}
                    <CaseReopenModal></CaseReopenModal>
                    <TemplatesInner caseid={this.props.match.params.id} history={this.props.history}/>
                </section>
            </>
        )
    }
}

Details.propTypes = {
    loading: PropTypes.bool,
    match: PropTypes.object,
    history: PropTypes.object,
    hideSearchPanel: PropTypes.func,
    currentCase: PropTypes.object,
    currentUser: PropTypes.object,
    assignmentDetails: PropTypes.array,
};
const mapState = (state) => {
    return {
        loading: state.app.cases.loading,
        currentCase: state.app.cases.editedCase,
        currentUser: state.app.auth.user,
        assignmentDetails: state.app.case_assign.appliedReviewers,
    }
};

const mapActions = dispatch => {
    return {
        setCaseMessage: (data) => dispatch(setCaseMessage(data)),
        showNotValidMessage: (data) => dispatch(showNotValidMessage(data)),
        getCaseTemplate: (code) => dispatch(getCaseTemplate(code)),
        getCase: (code) => dispatch(getCase(code)),
        getCaseValues: (code) => dispatch(getCaseValues(code)),
        getCaseFiles: (code) => dispatch(getCaseFiles(code)),
        getActiveCountries: () => dispatch(getActiveCountries()),
        getActiveStates: () => dispatch(getActiveStates()),
        hideSearchPanel: () => dispatch(hideSearchPanel()),
    }
};

export default connect(mapState, mapActions)(Details);
