import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SidebarToggle = () => {
    const dispatch = useDispatch();
    const { sidebarCollapsed } = useSelector((state) => state.app.menu);

    const toggleSidebar = () => {
        dispatch({ type: 'TOGGLE_SIDEBAR' });
    };

    useLayoutEffect(() => {
        if (sidebarCollapsed) {
            document.body.classList.add('sidebar-collapse');
        } else {
            document.body.classList.remove('sidebar-collapse');
        }
    }, [sidebarCollapsed]);

    return <span className="sidebar-toggle" to="/" data-toggle="offcanvas" role="button" onClick={toggleSidebar}></span>;
};

export default SidebarToggle;
