import React from 'react'

class UserPanel extends React.Component {
	render() {
		return (
			<div className="user-panel">

			</div>
		)
	}
}

export default UserPanel

// <div className="pull-left image">
// 	{/*{% if app.user.image is not empty %}*/}
// 	{/*<img src="{{ path('med_rev_core_get_profile_image', { 'imageHash' : app.user.image.hash} ) }}" class="img-circle" alt="{{ 'admin.menu_sidebar.user_image'|trans }}" />*/}
// 	{/*{% else %}*/}
// 	{/*<img src="{{ asset('bundles/medrevcore/images/usr_img/default-user.png') }}" class="img-circle" alt="{{ 'admin.menu_sidebar.user_image'|trans }}" />*/}
// 	{/*{% endif %}*/}
// </div>
// <div className="pull-left info">
// 	{/*<p>{{ app.user.firstName ~ ' ' ~ app.user.lastName }}</p>*/}
// </div>
