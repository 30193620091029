import { uniqBy } from 'lodash';
import { isObjectsEqual } from '../../utils/helpers';

const DEBUG = false;
const debug = (...message) => { DEBUG && console.debug(...message) };

const defaultState = {
    loading: false,
    loadedCount: 0,
    data: [],
    error: '',
    editedPatient: {},
    originalPatient: {},
    editedPatientSuccess: false,
    isProfileChanged: false,
    showValidMessages: false,
};

const patients = (state = defaultState, action) => {
    switch (action.type) {

        case 'FETCH_PATIENT_LOADING': {
            debug(action.type);
            return {
                ...state, loading: true, editedPatientSuccess: false, isProfileChanged: false, showValidMessages: false,
            };
        }

        case 'FETCH_PATIENT_SUCCESS': {
            debug(action.type);
            return Object.assign({}, state, { loading: false, data: action.payload, error: null });
        }

        case 'FETCH_PATIENT_ERROR':
        case 'FETCH_PATIENT_PROFILE_ERROR':
        case 'FETCH_UPDATE_PATIENT_ERROR': {
            debug(action.type);
            return { ...state, error: action.payload, loading: false };
        }

        case 'FETCH_CREATE_PATIENT_SUCCESS': {
            debug(action.type);
            return Object.assign({}, state, { loading: false, createError: null, data: [...state.data, action.payload] });
        }

        case 'FETCH_PATIENT_PROFILE_SUCCESS': {
            debug(action.type);
            return {
                ...state, error: null, editedPatient: action.payload, originalPatient: action.payload, loading: false };
        }

        case 'FETCH_UPDATE_PATIENT_SUCCESS': {
            debug(action.type);
            return {
                ...state, error: null, originalPatient: action.payload, editedPatient: action.payload, editedPatientSuccess: true, loading: false };
        }

        case 'PATIENT_EDIT_FIELD': {
            debug(action.type);
            return Object.assign({}, state, {
                editedPatient: Object.assign({}, state.editedPatient, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedPatient, action.payload), state.originalPatient)
            });
        }

        case 'VALIDATE_PATIENT_FIELD': {
            debug(action.type);
            return Object.assign({}, state, {
                patientValid: action.payload
            });
        }

        case 'SHOW_PATIENT_VALIDATION': {
            debug(action.type);
            return Object.assign({}, state, {
                showValidMessages: true
            });
        }

        case 'FETCH_PATIENT_4SELECT_SUCCESS': {
            debug(action.type);
            debug(action.payload);
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            };
        }

        case 'FILL_PATIENT_LOCAL_STORAGE': {
            debug(action.type, state.data);
            let items = {};
            for (let i = 0; i < state.data.length; i++) {
                items[ state.data[ i ].code ] =
                    (state.data[ i ].firstName ? state.data[ i ].firstName + ' ' : '') +
                    (state.data[ i ].lastName ? state.data[ i ].lastName : '');
            }
            localStorage.setItem('list_patient', JSON.stringify(items));
            return state;
        }

        case 'FETCH_PATIENT_LOADED_COUNT': {
            debug(action.type);
            return { ...state, loadedCount: action.payload };
        }

        case 'FETCH_PATIENT_MINIMAL_LOADING': {
            debug(action.type);
            return { ...state, loading: true };
        }

        case 'FETCH_PATIENT_MINIMAL_SUCCESS': {
            debug(action.type);
            debug(action.payload);
            return { ...state, loading: false, data: action.payload, error: null };
        }

        case 'FETCH_PATIENT_MINIMAL_ERROR': {
            debug(action.type);
            return { ...state, error: action.payload, loading: false };
        }

        case 'FILL_SELECTED_PATIENT_DATA': {
          const indexToExtend = state.data.findIndex(({code}) => code === action.payload.code);
          let newData = [...state.data];
          newData[indexToExtend] = action.payload;
            
          return {...state, data: uniqBy(newData, 'code')}
        }


        default:
            debug(action.type);
            return state;
    }
};

export default patients;
