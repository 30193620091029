const defaultState = {
    counterOpen: false,
    adminOpen: false,
    submitToOpen: false,
    caseReportOpen: false,
    data: [],
    loading: false,
    error: null,
    sidebarCollapsed: true,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'LOAD_USERS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.items,
                error: null,
            });
        case "TOGGLE_COUNTER_MENU":
            return Object.assign({}, state, {
                counterOpen: !state.counterOpen
            });
        case "CLOSE_COUNTER_MENU":
            return Object.assign({}, state, {
                counterOpen: false
            });
        case "TOGGLE_SUBMIT_TO_MENU":
            return Object.assign({}, state, {
                submitToOpen: !state.submitToOpen
            });
        case "TOGGLE_CASE_REPORT_MENU":
            return Object.assign({}, state, {
                caseReportOpen: !state.caseReportOpen
            });
        case "CLOSE_CASE_REPORT_MENU":
            return Object.assign({}, state, {
                caseReportOpen: false
            });
        case "CLOSE_SUBMIT_TO_MENU":
            return Object.assign({}, state, {
                submitToOpen: false
            });
        case "TOGGLE_ADMIN_MENU":
            return Object.assign({}, state, {
                adminOpen: !state.adminOpen
            });
        case "TOGGLE_SIDEBAR":
            return Object.assign({}, state, {
                sidebarCollapsed: !state.sidebarCollapsed
            });
        case "CLOSE_ADMIN_MENU":
            return Object.assign({}, state, {
                adminOpen: false
            });
        default:
            return state
    }
}
