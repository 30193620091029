import React from 'react';
import PropTypes from 'prop-types';
// ----- HARDCODING! we are changing this component to the new one -> package.json -----
// import CreatableSelect from 'react-select/lib/Creatable';
import CreatableSelect from '../deprecated/react-select/lib/Creatable';
// import Select from 'react-select';
import Select from '../deprecated/react-select';
// -------------------------------------------------------------------------------------
import { alphabetSorter, getMultiSelectValue } from '../../utils/helpers';

const MySelect = props => {

    const disabledClass = props.disabled ? 'background-grey ' : '';
    const requiredClass = props.required || props[ 'valids' ] === 'required' ? 'required-field ' : '';

    const classes = disabledClass ? disabledClass : requiredClass;

    const removeDuplicates = (originalArray, prop) => {
        //const uniqueValues = [...new Set(originalArray.map(getObjKey(prop)))]
        return originalArray.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t[ prop ] === item[ prop ]
            ))
        )
        //return originalArray.filter((item) => uniqueValues.includes(item.value));
    };

    const optionsConverter = () => {
        let options = (props.options != null && props[ 'creatableOptions' ] && props[ 'creatableOptions' ].length > 0)
            ? props.options.concat(props[ 'creatableOptions' ])
            : props.options;
        options = removeDuplicates(options, 'value');

        return props.sorted ? options : options.sort(alphabetSorter)
    };

    const valueSorter = () => {
        const { value } = props;
        let options = optionsConverter();
        if (!Array.isArray(value)) return null;

        const result = getMultiSelectValue(value)
            .map((item) => {
                return options.find((option) => option.value === item) || { value: item, label: item }
            });

        return props[ 'valuesSorted' ] ? result : result.sort(alphabetSorter)
    };

    if (props.allowSelectAll) {
        let optionsConverted = optionsConverter();
        if (props.value && props.value.length >= optionsConverted.length) {
            if (props.creatable) {
                return (
                    <CreatableSelect
                        {...props}
                        label={props.tickMark}
                        className={props.className}
                        options={optionsConverted}
                        closeOnSelect={false}
                    />
                );
            } else {
                return (
                    <Select
                        {...props}
                        label={props.tickMark}
                        className={props.className}
                        options={optionsConverted}
                        value={valueSorter()}
                        closeOnSelect={false}
                    />
                );
            }
        }

        if (props.creatable) {
            return (
                <CreatableSelect
                    {...props}
                    label={props.tickMark}
                    className={classes}
                    options={optionsConverted.length ? [props.allOption, ...optionsConverted] : []}
                    onChange={selected => {
                        if (
                            selected.length > 0 &&
                            selected[ selected.length - 1 ].value === props.allOption.value
                        ) {
                            // let all = props.value.concat(optionsConverted);
                            let all;
                            if (typeof props.value === 'string') {
                                all = optionsConverted;
                            } else {
                                all = props.value.concat(optionsConverted);
                            }
                            all = removeDuplicates(all, 'value');
                            return props.onChange(all);
                        }
                        return props.onChange(selected);
                    }}
                    closeOnSelect={false}
                    value={props.multi ? valueSorter() : props.value}
                />
            );
        } else {
            return (
                <Select
                    {...props}
                    label={props.tickMark}
                    className={classes}
                    options={optionsConverted.length ? [props.allOption, ...optionsConverted] : []}
                    onChange={selected => {
                        if (
                            selected.length > 0 &&
                            selected[ selected.length - 1 ].value === props.allOption.value
                        ) {
                            return props.onChange(optionsConverted);
                        }
                        return props.onChange(selected);
                    }}
                    closeOnSelect={false}
                    value={props.multi ? valueSorter() : props.value}
                />
            );
        }
    }

    if (props.creatable) {
        let value = (Array.isArray(props.value) ? props.value[ 0 ] : props.value);
        if (typeof value === 'string' && value !== '' && !props.options.find((item) => item.value === value)) value = {
            label: value,
            value: value
        };

        return <CreatableSelect {...props}
                                label={props.tickMark}
                                options={optionsConverter()}
                                value={value}
                                className={classes}
        />;
    } else {
        return <Select {...props}
                       label={props.tickMark}
                       options={optionsConverter()}
                       value={props.multi ? valueSorter() : ((props.value != null && Array.isArray(props.value)) ? props.value[ 0 ] : props.value)}
                       className={props.className}/>;
    }
};

MySelect.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};

MySelect.defaultProps = {
    allOption: {
        label: "Select all",
        value: "*"
    }
};

export default MySelect;

// document.getElementsByClassName('Select').onwheel = (e) => {
//     //if (e.target.tagName != 'TEXTAREA') return;
//     console.log(e);
//     console.log(e.target);
//     var area = e.target;
//
//     var delta = e.deltaY || e.detail || e.wheelDelta;
//
//     if (delta < 0 && area.scrollTop === 0) {
//         e.preventDefault();
//     }
//
//     if (delta > 0 && area.scrollHeight - area.clientHeight - area.scrollTop <= 1) {
//         e.preventDefault();
//     }
// };
