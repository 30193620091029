import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';
import { patientCaseStages } from '../patientCaseStages';

const draftSlice = createDatatableTemplateSlice({
    name: patientCaseStages.DRAFT,
    initialSettings: {},
});

export const draftReducer = draftSlice.reducer;

export const { loadData, dataLoaded, dataLoadError } = draftSlice.actions;

export default draftSlice;
