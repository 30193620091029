import React from 'react';
import AddPatient from './AddPatient'

export default class Case extends React.Component {

    render() {
        return (
            <section className="content user-profile-content">
                <AddPatient> </AddPatient>
            </section>
        )
    }
}
