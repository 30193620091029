import React from 'react'

import SelectField from './SelectField'
import InputField from "./InputField";
import DateTimeField from "./DateTimeField";
import Reference from "./Reference"
import CreatableField from "./CreatableField"
import WysiwygEditor from "./WysiwygEditor"
import CodeEditor from "./CodeEditor"
import TextReadField from "./TextReadField"
import RefSpecialty from "./RefSpecialty"
import RefBillingTiers from "./RefBillingTiers"
import RefBillingGroup from "./RefBillingGroup"
import RefCurrency from "./RefCurrency"
import RefCaseDetermination from "./RefCaseDetermination"
import RefState from "./RefState"
import RefCaseLevel from "./RefCaseLevel"
import RefCaseType from "./RefCaseType"
import RefCompany from "./RefCompany"
import RefCountry from "./RefCountry"
import RefDiagnoses from "./RefDiagnoses"
import RefBodyPart from "./RefBodyPart"
import RefSide from "./RefSide"
import RefSections from "./RefSections"
import RefFacilityType from "./RefFacilityType"
import RefTemplateField from "./RefTemplateField"
import RefPhysician from "./RefPhysician"
import RefPhysicianCompany from "./RefPhysicianCompany"
import RefProcedure from "./RefProcedure"
import RefPatient from "./RefPatient"
import RefGender from "./RefGender"
import RefServiceType from "./RefServiceType"
import RefReviewer from "./RefReviewer"
import RefSubmitter from "./RefSubmitter"
import RefMcSystem from "./RefMcSystem"
import RefScSystem from "./RefScSystem"
import RefTimeZone from "./RefTimeZone"
import RefLanguage from "./RefLanguage"
import RefLocale from "./RefLocale"
import RefQuestionsCount from "./RefQuestionsCount"
import RefFacility from "./RefFacility";

const FieldMap = {
    select: SelectField,
    SELECT: SelectField,
    timezone: RefTimeZone,
    datetime: DateTimeField,
    DATE_TIME: DateTimeField,
    DATE: DateTimeField,
    TIME: DateTimeField,
    // YEAR: DateTimeField,
    reference: Reference,
    creatable: CreatableField,
    CASE_QUESTIONS_COUNT: RefQuestionsCount,
    REF_BODY_PART: RefBodyPart,
    REF_BILLING_TIER: RefBillingTiers,
    REF_BILLING_GROUP: RefBillingGroup,
    REF_CASE_TYPE: RefCaseType,
    REF_CASE_LEVEL: RefCaseLevel,
    REF_CASE_DETERMINATION: RefCaseDetermination,
    REF_CURRENCY: RefCurrency,
    REF_COMPANY: RefCompany,
    REF_COUNTRY: RefCountry,
    REF_MEDICAL_DIAGNOSIS: RefDiagnoses,
    REF_FACILITY: RefFacility,
    REF_FIELD: RefTemplateField,
    REF_FACILITY_TYPE: RefFacilityType,
    REF_GENDER: RefGender,
    REF_PHYSICIAN: RefPhysician,
    REF_PHYSICIAN_COMPANY: RefPhysicianCompany,
    REF_LANGUAGE: RefLanguage,
    REF_LOCALE: RefLocale,
    REF_MEDICAL_SERVICE: RefProcedure,
    REF_PATIENT: RefPatient,
    REF_REVIEWER: RefReviewer,
    REF_SERVICE_TYPE: RefServiceType,
    REF_SECTION: RefSections,
    REF_SIDE: RefSide,
    REF_SPECIALTY: RefSpecialty,
    REF_MEDICAL_CODE: RefScSystem,
    REF_MEDICAL_CODING_SYSTEM: RefMcSystem,
    REF_STATE: RefState,
    REF_SUBMITTER: RefSubmitter,
    RICH_TEXT: WysiwygEditor,
    CODE: CodeEditor,
    TEXT_READ: TextReadField,
    LABEL: WysiwygEditor
};

class FormField extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          isHovered: false
      };
  }

  handleMouseEnter = () => {
      this.setState({ isHovered: true });
  }

  handleMouseLeave = () => {
      this.setState({ isHovered: false });
  }

  render() {
      const { props } = this;
      const Component = FieldMap[props.type] || InputField;

      if (Component !== InputField) {
          return (
              <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                  <Component
                      allowDate={props.type === 'DATE_TIME' || props.type === 'DATE'}
                      allowTime={props.type === 'DATE_TIME' || props.type === 'TIME'}
                      {...props}
                  />
              </div>
          );
      }

      return (
          <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
              <Component
                  {...props}
              />
          </div>
      );
  }
}

export default FormField;
