import { transformResponseToTableData } from "../../../components/Datatable/dataTableUtils";

export const transformPatients = transformResponseToTableData((data) => {
    return {
        data: data.map((
            {
                code, firstName, lastName, physicianCode, specialtyNames, bodyPartNames, languageNames, privateFacility, active
            }) => ({
            code, firstName, lastName, physicianCode,
            specialtyNames: specialtyNames,
            bodyPartNames: bodyPartNames,
            languageNames: languageNames,
            privateFacility: privateFacility,
            status: active ? 'ACTIVE' : 'INACTIVE',
        })),
    };
});
