import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  DropdownButton,
  MenuItem,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { range } from 'lodash';
import { toolTipId } from '../../utils/helpers';
import DataTablecontext from './DataTableContext';
import classnames from 'classnames';

const PREDEFINED_PAGE_SIZES = [5, 10, 20, 50];

const DataTablePagination = ({
  maxPageShown,
  pageCount,
  onPageSizeChange,
  onPageChange,
  pageSize,
  currentPage,
  dataLength,
}) => {
  const { variant } = useContext(DataTablecontext);

  const handlePageSizeChange = useCallback(
    (pageSize) => {
      onPageSizeChange(pageSize);
    },
    [onPageSizeChange],
  );

  const handlePaginationButtonClick = useCallback(
    (page) => {
      if (page !== currentPage) {
        onPageChange(page);
      }
    },
    [currentPage, onPageChange],
  );

  const pageOffset = Math.floor((maxPageShown - 1) / 2);

  const pages = range(
    Math.max(1, currentPage - pageOffset),
    Math.min(currentPage + pageOffset + 1, pageCount + 1),
  );

  return (
    <div className="datatable__pagination">
      {dataLength > Math.min(...PREDEFINED_PAGE_SIZES) && (
        <OverlayTrigger placement="top" overlay={<Tooltip id={toolTipId('dt-pagination')}>Page size</Tooltip>}>
          <DropdownButton
            title={pageSize}
            id="dropdown-pagination"
            onSelect={handlePageSizeChange}
          >
            {PREDEFINED_PAGE_SIZES.map((pageSize) => (
              <MenuItem eventKey={pageSize} key={pageSize}>
                {pageSize}
              </MenuItem>
            ))}
          </DropdownButton>
        </OverlayTrigger>
      )}
      <ButtonGroup
        className={classnames(['mr-2', { hidden: pageCount === 1 }])}
        aria-label="First group"
      >
        {currentPage > 1 && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={toolTipId('dt-previous-page')}>Previous page</Tooltip>}
          >
            <Button
              bsStyle="default"
              onClick={() => handlePaginationButtonClick(currentPage - 1)}
            >
              {'<'}
            </Button>
          </OverlayTrigger>
        )}

        {!pages.find((p) => p === 1) && (
          <Button
            bsStyle={currentPage === 1 ? variant : 'default'}
            onClick={() => handlePaginationButtonClick(1)}
          >
            {1}
          </Button>
        )}

        {currentPage - pageOffset > 2 && (
          <Button bsStyle="default" disabled>
            {'...'}
          </Button>
        )}

        {pages.map((page) => (
          <Button
            key={page}
            bsStyle={page === currentPage ? variant : 'default'}
            onClick={() => handlePaginationButtonClick(page)}
          >
            {page}
          </Button>
        ))}
        {currentPage + pageOffset < pageCount && pageCount > pageOffset + 2 && (
          <Button bsStyle="default" disabled>
            {'...'}
          </Button>
        )}

        {!pages.find((p) => p === pageCount) && (
          <Button
            bsStyle={currentPage === pageCount ? variant : 'default'}
            onClick={() => handlePaginationButtonClick(pageCount)}
          >
            {pageCount}
          </Button>
        )}

        {currentPage < pageCount && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={toolTipId('dt-next-page')}>Next page</Tooltip>}
          >
            <Button
              bsStyle="default"
              onClick={() => handlePaginationButtonClick(currentPage + 1)}
            >
              {'>'}
            </Button>
          </OverlayTrigger>
        )}
      </ButtonGroup>
    </div>
  );
};

DataTablePagination.propTypes = {
  maxPageShown: PropTypes.number,
  onPageSizeChange: PropTypes.func,
  onPageChange: PropTypes.func,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  dataLength: PropTypes.number.isRequired,
};

DataTablePagination.defaultProps = {
  maxPageShown: 5,
  onPageSizeChange: () => {},
  onPageChange: () => {},
};

export default DataTablePagination;
