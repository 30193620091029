/* eslint-enable react-hooks/exhaustive-deps */
/* eslint "react-hooks/exhaustive-deps": "error" */

import { useState, useCallback } from 'react';

const useInnerPagination = ({ defaultSettings, onSettingsChange }) => {
    const [currentPage, setCurrentPage] = useState(defaultSettings.page);
    const [pageSize, setPageSize] = useState(defaultSettings.pageSize);

    const handlePageSizeChange = useCallback(
        (pageSizeToChange) => {
            setPageSize(pageSizeToChange);
            setCurrentPage(1);
            onSettingsChange({ pageSize: pageSizeToChange, page: 1 });
        },
        [onSettingsChange],
    );

    const handlePageChange = useCallback(
        (page) => {
            setCurrentPage(page);
            onSettingsChange({ page });
        },
        [onSettingsChange, setCurrentPage],
    );

    return { handlePageSizeChange, currentPage, pageSize, setCurrentPage, handlePageChange };
};

export default useInnerPagination;
