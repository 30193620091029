import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { mapValues } from 'lodash';
import DataTableHeader from './DataTableHeader';
import DataTableRow from './DataTableRow';
import DataTableControlButtons from './DataTableControlButtons';
import DataTableFilterRow from './DataTableFilterRow';
import DataTablePagination from './DataTablePagination';
import DataTableContext from './DataTableContext';
import DataTableLoadingPlaceholder from './DataTableLoadingPlaceholder';
import DataTableNoDataWarning from './DataTableNoDataWarning';
import DataTableLoadingIndicator from './DataTableLoadingIndicator';
import DataTableInner from './DataTableInner';
import DataTableWrapper from './DataTableWrapper';
import DataTableContainer from './DataTableContainer';

import { defaultSettingsGlobal } from './dataTableUtils';
import useRowSelect from './hooks/useRowSelect';
import useInnerPagination from './hooks/useInnerPagination';
import useInnerSort from './hooks/useInnerSort';
import useInnerFilter from './hooks/useInnerFilter';

import './DataTable.css';
import DataTableColumnDefs from './DataTableColumnDefs';

const mergeDefaultColumnProps = (columns) =>
  mapValues(columns, (c) => ({
    filterable: true,
    sortable: true,
    format: (value) => value,
    colStyle: {},
    tdStyle: {},
    ...c,
  }));

const DataTable = forwardRef((
  {
    data,
    columns: columnsProp,
    style,
    onRowClick,
    onDelete,
    deleteDisabled,
    deleteLoading,
    keyExtractor,
    totalRecordsFiltered,
    onSettingsChange,
    defaultSettings,
    initialSettings,
    variant,
    loading,
    customActions,
    serverside,
    filterOptions,
    isDeleting,
    actionsTitle,
    loadingPlaceholderCount,
    onRowSelect,
    onRowSelectAll,
    onRowDeselect,
    onRowDeselectAll,
    onSelectedRowsChange,
    selectable,
    singleSelect,
    selectedKeys,
    className,
    connectedCurrentPage,
  }, ref) => {

  const columns = mergeDefaultColumnProps(columnsProp);
  const columnKeys = Object.keys(columns);

  const [containerWidth, _setContainerWidth] = useState('auto');

  const setContainerWidth = useCallback(
    (width) => {
      const controlButtosWidth = 50;
      _setContainerWidth(width - controlButtosWidth);
    },
    [_setContainerWidth],
  );

  const {
    handlePageChange,
    handlePageSizeChange,
    currentPage,
    pageSize,
    setCurrentPage,
  } = useInnerPagination({ defaultSettings, onSettingsChange });

  const {
    handleResetSort,
    handleSort,
    sortedColumn,
    sortDirection,
  } = useInnerSort({
    setCurrentPage,
    onSettingsChange,
    defaultSettings,
    initialSettings,
  });

  const {
    handleFilterChange,
    handleToggleFilter,
    isFilterRowVisible,
    filters,
  } = useInnerFilter({
    setCurrentPage,
    onSettingsChange,
    defaultSettings,
    columnKeys,
  });

  let { selectedRows, handleRowSelect, handleRowSelectAll, selectAllValue, setSelectAllValue, resetSelectedRows } = useRowSelect({
    data,
    onRowSelect,
    onRowDeselect,
    onRowDeselectAll,
    onRowSelectAll,
    onSelectedRowsChange,
    keyExtractor,
    singleSelect,
    selectedKeys,
  });

  const pageCount = Math.ceil(totalRecordsFiltered / pageSize);

  if (columnKeys.length === 0) {
    return null;
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        resetSelectedRows: () => resetSelectedRows(),
      };
    },
    [resetSelectedRows],
  );

  const contextValues = {
    variant,
    filters,
    serverside,
    filterOptions,
    onRowSelect: handleRowSelect,
    onRowSelectAll: handleRowSelectAll,
    selectAllValue,
    setSelectAllValue,
    selectable,
    singleSelect,
    columns,
    columnKeys,
    hasActions: !!customActions,
    hasDelete: !!onDelete,
  };

  return (
    <DataTableContext.Provider value={contextValues}>
      <DataTableContainer
        className={className}
        setContainerWidth={setContainerWidth}
      >
        {loading && <DataTableLoadingIndicator />}
        <DataTableControlButtons
          onResetSort={handleResetSort}
          onToggleFilter={handleToggleFilter}
        />
        <DataTableInner style={{ width: containerWidth }}>
          <DataTableWrapper
            wrapperStyle={{ width: containerWidth }}
            style={{
              ...style,
              width: containerWidth,
              tableLayout: data.length === 0 ? 'auto' : 'fixed',
            }}
          >
            <DataTableColumnDefs />
            <DataTableHeader
              onSort={handleSort}
              sortBy={sortedColumn}
              sortDirection={sortDirection}
              actionsTitle={actionsTitle}
            >
              <DataTableFilterRow
                visible={isFilterRowVisible}
                onFilterChange={handleFilterChange}
                filters={filters}
              />
            </DataTableHeader>
            {(() => {
              if (loading && data.length < 1) {
                return (
                  <DataTableLoadingPlaceholder rows={loadingPlaceholderCount} />
                );
              } else if (!data || !data.length) {
                return <DataTableNoDataWarning />;
              }

              return (
                <tbody>
                {data.map((rowData, index) => {
                  const id = keyExtractor(rowData, index);
                  // console.log('SELECTED selectedRows', selectedRows);
                  return (
                    <DataTableRow
                      key={id}
                      id={id}
                      selected={selectedRows.includes(id)}
                      rowData={rowData}
                      customActions={customActions}
                      onDelete={onDelete}
                      formatters={mapValues(columns, 'format')}
                      deleteDisabled={
                        !!deleteDisabled(rowData, index) || isDeleting
                      }
                      deleteLoading={!!deleteLoading(rowData, index)}
                      index={index}
                      onClick={
                        onRowClick
                          ? () => onRowClick(rowData, index)
                          : undefined
                      }
                    />
                  );
                })}
                </tbody>
              );
            })()}
          </DataTableWrapper>
          {pageCount > 0 && (
            <DataTablePagination
              pageCount={pageCount}
              onPageSizeChange={handlePageSizeChange}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              currentPage={connectedCurrentPage ? connectedCurrentPage : currentPage}
              dataLength={totalRecordsFiltered}
            />
          )}
        </DataTableInner>
      </DataTableContainer>
    </DataTableContext.Provider>
  );
});

DataTable.propTypes = {
  data: PropTypes.any,
  columns: PropTypes.object.isRequired,
  style: PropTypes.object,
  onRowClick: PropTypes.func,
  keyExtractor: PropTypes.func,
  defaultSettings: PropTypes.object,
  initialSettings: PropTypes.object,
  totalRecords: PropTypes.number,
  totalRecordsFiltered: PropTypes.number.isRequired,
  onSettingsChange: PropTypes.func,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'info', 'danger', 'warning', 'success']),
  customActions: PropTypes.func,
  actionsTitle: PropTypes.string,
  serverside: PropTypes.bool.isRequired,
  deleteDisabled: PropTypes.func,
  deleteLoading: PropTypes.func,
  filterOptions: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  loadingPlaceholderCount: PropTypes.number,
  selectable: PropTypes.bool,
  singleSelect: PropTypes.bool,
  selectedKeys: PropTypes.arrayOf(PropTypes.array),
  className: PropTypes.string,
  connectedCurrentPage: PropTypes.number,
  onRowSelect: PropTypes.func,
  onRowSelectAll: PropTypes.func,
  onRowDeselectAll: PropTypes.func,
  onRowDeselect: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
};

DataTable.defaultProps = {
  data: [],
  style: {},
  defaultSettings: defaultSettingsGlobal,
  initialSettings: defaultSettingsGlobal,
  variant: 'primary',
  customActions: null,
  totalRecords: 1,
  loadingPlaceholderCount: 10,
  actionsTitle: 'Actions',
  loading: false,
  serverside: false,
  isDeleting: false,
  selectable: false,
  singleSelect: false,
  selectedKeys: null,
  className: '',
  connectedCurrentPage: null,
  keyExtractor: (_, index) => index,
  deleteDisabled: () => false,
  deleteLoading: () => false,
  onSettingsChange: () => {
  },
  onRowSelect: () => {
  },
  onRowDeselect: () => {
  },
  onRowSelectAll: () => {
  },
  onRowDeselectAll: () => {
  },
  onSelectedRowsChange: () => {
  },
};

export default DataTable;
