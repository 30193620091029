import React from 'react'
import Box from '../components/Box'
import BoxHeader from '../components/BoxHeader'
import BoxBody from '../components/BoxBody'
import ContactContent from '../components/ContactContent'

export default class ContactUs extends React.Component {
	render() {
		return (
			<section className="content contact-us-page">
				<Box borderColor="box-primary">
					<BoxHeader border="with-border" icon="fa-phone" heading="Contact Us"/>
					<BoxBody>
						<ContactContent infoEmail="info@medrev.com" phoneNumber="+353 1 901 4298"
						                supportEmail="helpdesk@medrev.com"/>
					</BoxBody>
				</Box>
			</section>
		)
	}
}
