import FormField from 'components/FormField';
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { closeCaseReopenModal } from 'redux/action-creators/users';
import { updateCaseValues, prepareCaseAttributes } from 'redux/actions/cases/caseActions';
import { saveCaseLocalStorageData } from 'utils/case-helpers';
import { reopenCase } from 'redux/actions/cases/caseListActions';
import { isJson } from 'utils/helpers';

const CaseReopenModal = () => {
  const dispatch = useDispatch();
  const [reasonValue, setReasonValue] = useState('');
  const [notesValue, setNotesValue] = useState('');

  const { isOpen, fieldData, resubmitReasonField, resubmitNotesField, editedCase } = useSelector((state) => {
    return {
      isOpen: state.app.users.caseReopenModalOpen,
      fieldData: state.app.cases.values.fieldData,
      resubmitReasonField: state.app.cases.fields.find((field) => field.originalFieldCode === 'resubmitReason'),
      resubmitNotesField: state.app.cases.fields.find((field) => field.originalFieldCode === 'resubmitNotes'),
      editedCase: state.app.cases.editedCase,
    }
  });

  useEffect(() => {
    if (fieldData && resubmitReasonField && resubmitNotesField) {
      setReasonValue(fieldData[resubmitReasonField.code] || '');
      setNotesValue(fieldData[resubmitNotesField.code] || '');
    }
  }, [fieldData, resubmitReasonField, resubmitNotesField])

  function handleClose() {
    dispatch(closeCaseReopenModal());
  }

  function handleSubmit() {
    if (!reasonValue || reasonValue === '') {
      return;
    }
    fieldData[resubmitNotesField.code] = notesValue;
    fieldData[resubmitReasonField.code] = reasonValue;

    dispatch(prepareCaseAttributes([
      { data: fieldData[resubmitNotesField.code] || '', code: resubmitNotesField.code },
      { data: fieldData[resubmitReasonField.code] || '', code: resubmitReasonField.code },
    ]));
    saveCaseLocalStorageData(editedCase.code, 'fields', fieldData);
    saveCaseLocalStorageData(editedCase.code, 'values', fieldData);
    updateAndReopenCase(dispatch, editedCase.code);
    // dispatch({
    //   type: 'CASE_IS_CREATED',
    //   payload: true
    // });
    dispatch(closeCaseReopenModal());
  }

  return (
    isOpen && resubmitReasonField && resubmitNotesField && (
      <Modal
        isOpen={isOpen}
        onRequestClose={handleClose}
        className="custom-modal modal-dialog"
        overlayClassName="modal-overlay">
        <h3 className='modal-title text-center'>Resubmit Closed Case</h3>
        <div className='modal-content custom-modal-content'>
          <div className='modal-body'>
            <FormField
              md="12"
              type="select"
              required
              label={isJson(resubmitReasonField.name)
                ? JSON.parse(resubmitReasonField.name).blocks[0].text
                : resubmitReasonField.name}
              className="text-bg required-field"
              searchable
              disabled={false}
              multi={false}
              options={JSON.parse(resubmitReasonField.listOptions)}
              id="resubmit_reason"
              name="reason"
              value={reasonValue}
              onChange={(e) => setReasonValue(e?.value || '')}
            />
            <FormField
              md="12"
              type="TEXT_READ"
              required
              label={isJson(resubmitNotesField.name)
                ? JSON.parse(resubmitNotesField.name).blocks[0].text
                : resubmitNotesField.name}
              className="text-bg"
              searchable
              disabled={false}
              id="resubmit_notes"
              name="notes"
              value={notesValue}
              onChange={(e) => setNotesValue(e.target.value)}
            />
          </div>
          <div className='modal-footer' style={{ border: 'none' }}>
            <button
              className='btn btn-danger margin-left-5 margin-top-5'
              onClick={handleClose}>
              Cancel
            </button>
            <button
              className='btn btn-success margin-right-5 margin-top-5'
              onClick={handleSubmit}
              disabled={!reasonValue || reasonValue === '' || !notesValue || notesValue.trim() === ''}>
              Submit
            </button>
          </div>
        </div>
      </Modal>
    )
  )
}

async function updateAndReopenCase(dispatch, editedCaseCode) {
  await dispatch(updateCaseValues(editedCaseCode));
  dispatch(reopenCase());
}

export default CaseReopenModal
