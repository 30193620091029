import React from 'react';
import PropTypes from 'prop-types';

const DataTableInner = ({ children, ...props }) => (
  <div className="datatable__inner" {...props}>
    {children}
  </div>
);

DataTableInner.propTypes = {
  children: PropTypes.node,
};

export default DataTableInner;
