/* eslint-disable object-shorthand */
/* eslint-enable react-hooks/exhaustive-deps */
/* eslint "react-hooks/exhaustive-deps": "error" */

import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLanguage } from '../../utils/hooks/language';
import useFormState from './useFormState';
import useValidator from './useValidator';

const useForm = (actions, formKey, options = {}) => {
    const dispatch = useDispatch();
    const language = useLanguage();
    const { customValidatorRules = {} } = options;
    const { editField, setInputStates, submitFields } = actions;
    const fieldValidationRules = useRef({});
    const formState = useFormState(formKey);

    const setFieldValidity = useCallback(
        (field, validity) => {
            dispatch(actions.setFieldValidity({ field, validity }));
        },
        [actions, dispatch],
    );

    const setFieldValidities = useCallback(
        (fieldValidities) => {
            dispatch(actions.setFieldValidities(fieldValidities));
        },
        [actions, dispatch],
    );

    const { errorMessages, validate: validateField, allValid, hasError, setErrorMessages } = useValidator({
        setFieldValidity,
        formKey,
        customValidatorRules,
    });

    const setFieldValidationRule = (field, rule) => {
        fieldValidationRules.current = { ...fieldValidationRules.current, [field]: rule };
    };

    const setFieldStates = useCallback(
        (name, stateObject) => {
            dispatch(setInputStates({ name, stateObject }));
        },
        [dispatch, setInputStates],
    );

    const getValue = useCallback(
        (field, translated) => {
            const { [field]: value = '' } = formState.entity;

            if (!translated) {
                return value;
            }

            const { [language]: translatedValue = '' } = value;
            return translatedValue;
        },
        [formState.entity, language],
    );

    const validateAll = useCallback(() => {
        let validationsMessages = {};
        let validities = {};
        Object.keys(fieldValidationRules.current).forEach((field) => {
            const { translated, rules, label } = fieldValidationRules.current[field];
            const result = validateField(field, getValue(field, translated), rules, { label });
            validities[field] = !result;
            if (result) {
                validationsMessages[field] = result;
            }
        });
        setErrorMessages(validationsMessages);
        setFieldValidities(validities);

        return Object.values(validities).every((v) => v === true);
    }, [getValue, setErrorMessages, setFieldValidities, validateField]);

    const submitForm = useCallback(() => {
        dispatch(submitFields());
        return validateAll();
    }, [dispatch, submitFields, validateAll]);

    const dispatchChange = useCallback(
        (fields) => {
            dispatch(editField(fields));
        },
        [dispatch, editField],
    );

    const onChangeField = useCallback(
        (fieldObject) => {
            dispatchChange(fieldObject);
        },
        [dispatchChange],
    );

    const onChangeTranslatedField = useCallback(
        (name, value) => {
            dispatchChange({ [name]: { [language]: value } });
        },
        [dispatchChange, language],
    );

    const mappedErrorMessages = useMemo(() => {
        return Object.keys(errorMessages).map((field) => ({
            id: field,
            field: errorMessages[field].label,
            message: errorMessages[field].message,
        }));
    }, [errorMessages]);

    return {
        setFieldValidationRule,
        getValue,
        hasError,
        allValid,
        errorMessages,
        validateField,
        onChangeField,
        onChangeTranslatedField,
        dispatchChange,
        mappedErrorMessages,
        setInputStates,
        submitForm,
        setFieldStates,
        ...formState,
    };
};

export default useForm;
