import { connect } from 'react-redux'
import { caseQuestionCount } from '../../utils/Constants'
import RefField from './RefField'

const mapStateToProps = (state, ownProps) => ({
  isLoading:  false,
  options: caseQuestionCount,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getOptions: () => {},
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefField)
