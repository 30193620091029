import api from '../../../../utils/api';
import clientEditMedicalServicesDiagnosesSlice, {
    linkingEntities,
    linkingEntitiesError,
    linkingEntitiesSuccess,
    unlinkingEntities
} from './clientEditMedicalServicesDiagnosesSlice';

export const linkClientEditDiagnoses = (selectedDiagnoseCodes, storedSettings, search, getPageCode, medicalCodingSystemCodes) => (dispatch) => {
    if (selectedDiagnoseCodes && selectedDiagnoseCodes.length === 0) {
        return;
    }
    dispatch(linkingEntities());
    const entity = {
        diagnoseCodes: selectedDiagnoseCodes,
    };

    return api
        .post('client_medical_service/diagnosis/link?code=' + getPageCode, entity)
        .then((res) => {
            // console.log('res', res);
            dispatch(linkingEntitiesSuccess());
            dispatch(clientEditMedicalServicesDiagnosesSlice.actions.loadData(
                { ...storedSettings, globalSearch: search, code: getPageCode, medicalCodingSystemCodes: medicalCodingSystemCodes }
            ));
        })
        .catch((error) => {
            // console.log('catch', error.response);
            dispatch(linkingEntitiesError());
            if (error.response) {
                // dispatch(fetchThreadListError(fieldsErrors[ error.response.status ]));
            } else if (error.request) {
                console.error(error.request);
                // dispatch(fetchThreadListError(error));
            } else {
                console.error('Error ', error.message);
            }
        });
}

export const unlinkClientEditDiagnoses = (selectedDiagnoseCodes, storedSettings, search, getPageCode, medicalCodingSystemCodes) => (dispatch) => {
    if (selectedDiagnoseCodes && selectedDiagnoseCodes.length === 0) {
        return;
    }
    dispatch(unlinkingEntities());
    const entity = {
        diagnoseCodes: selectedDiagnoseCodes,
    };
    return api
        .post('client_medical_service/diagnosis/unlink?code=' + getPageCode, entity)
        .then((res) => {
            // console.log('res', res);
            dispatch(linkingEntitiesSuccess());
            dispatch(clientEditMedicalServicesDiagnosesSlice.actions.loadData(
                { ...storedSettings, globalSearch: search, code: getPageCode, medicalCodingSystemCodes: medicalCodingSystemCodes }
            ));
        })
        .catch((error) => {
            // console.log('catch', error.response);
            dispatch(linkingEntitiesError());
            if (error.response) {
                // dispatch(fetchThreadListError(fieldsErrors[ error.response.status ]));
            } else if (error.request) {
                console.error(error.request);
                // dispatch(fetchThreadListError(error));
            } else {
                console.error('Error ', error.message);
            }
        });
}
