import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';
import { patientCaseStages } from '../patientCaseStages';

const flaggedSlice = createDatatableTemplateSlice({
    name: patientCaseStages.FLAGGED,
    initialSettings: {},
});

export const flaggedReducer = flaggedSlice.reducer;

export const { loadData, dataLoaded, dataLoadError } = flaggedSlice.actions;

export default flaggedSlice;
