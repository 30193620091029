const defaultState = {
    loading: false,
    data: [],
    error: ''
}

const stages = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_STAGES_LOADING':
            return { ...state, loading: true }
        case 'FETCH_STAGES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null
            })
        case 'FETCH_STAGES_ERROR':
            return {...state, error: action.payload, loading: false}
        default:
    }

    return state
}

export default stages
