import { isObjectsEqual } from '../../../utils/helpers'

const defaultCountriesState = {
    loading: false,
    editCountrySuccess: false,
    data: [],
    all: [],
    states: [],
    error: '',
    editedCountry: {
        name: '',
        code: null,
        active: true
    },
    originalCountry: {
        name: '',
        code: null,
        active: true
    },
    isProfileChanged: false,
    lastLoadedTime: 0,
}

const countries = (state = defaultCountriesState, action) => {
    switch (action.type) {
        case 'FETCH_STATE_LOADING':
        case 'FETCH_COUNTRY_LOADING':
            return { ...state, loading: true, editCountrySuccess: false, isProfileChanged: false, }
        case 'FETCH_STATES_SUCCESS': {
            let items2 = {};
            let active_items2 = action.payload.filter((item) => item.active);
            for (let i = 0; i < active_items2.length; i++) {
                items2[ active_items2[ i ].code ] = active_items2[ i ].name;
            }
            localStorage.setItem('list_state', JSON.stringify(items2));
            return Object.assign({}, state, {
                loading: false,
                states: action.payload,
                error: null
            });
        }
        case 'FETCH_COUNTRY_SUCCESS': {
            let items = {};
            let active_items = action.payload.filter((item) => item.active);
            for (let i = 0; i < active_items.length; i++) {
                items[ active_items[ i ].code ] = active_items[ i ].name;
            }
            localStorage.setItem('list_country', JSON.stringify(items));
            return Object.assign({}, state, {
                loading: false,
                all: action.payload,
                data: Array.isArray(action.payload) ? action.payload.filter((item) => item.active) : [],
                error: null,
                lastLoadedTime: Date.now(),
            });
        }
        case 'FETCH_COUNTRY_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            }

        case 'FETCH_CREATE_COUNTRY_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                all: [...state.all, action.payload]
            })

        case 'FETCH_GET_COUNTRY_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                editedCountry: action.payload,
                originalCountry: action.payload,
                isProfileChanged: false,

            }

        case 'FETCH_EDIT_COUNTRY_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editCountrySuccess: true,
                originalCountry: action.payload,
                all: state.data.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            })
        case 'COUNTRY_EDIT_FIELD':
            return Object.assign({}, state, {
                editedCountry: Object.assign({}, state.editedCountry, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedCountry, action.payload), state.originalCountry)
            })

        case 'FETCH_COUNTRY_LOADED_COUNT':
            return { ...state, loadedCount: action.payload }

        case 'FETCH_STATE_ERROR':
        case 'FETCH_COUNTRY_ERROR':
            return { ...state, error: action.payload, loading: false }

        default:
            return state
    }

}

export default countries;
