import { createSlice } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";
import { initialInputState } from "../../utils/createEntityMutationTemplateSlice";

const importTemplateSlice = createSlice({
    name: 'importTemplate',
    initialState: {
        entity: {
            templateName: ''
        },
        entityOriginal: {},
        inputStates: {},
        serverErrors: []
    },
    reducers: {
        setInputStates: (state, { payload: { name, stateObject } }) => {
            const inputState = state.inputStates[name];
            state.inputStates[name] = { ...inputState, ...stateObject };
        },
        editField: (state, { payload: changedEntity }) => {
            const newEntity = { ...state.entity, ...changedEntity };
            state.entity = newEntity;
            state.isFormChanged = !isEqual(newEntity, state.entityOriginal);
        },
        setFieldValidity: (state, { payload: { field, validity } }) => {
            // console.log('setFieldValidity', field, ':::', validity);
            const newState = { ...state.inputStates[field], isValid: validity };
            const inputStates = { ...state.inputStates, [field]: newState };
            const isFormValid = Object.values(inputStates).every(({ isValid }) => isValid);

            state.inputStates[field] = newState;
            state.isFormValid = isFormValid;
        },
        setFieldValidities: (state, { payload: fieldValidities }) => {
            Object.keys(fieldValidities).forEach((field) => {
                state.inputStates[field] = { ...state.inputStates[field], isValid: fieldValidities[field] };
            });

            state.isFormValid = Object.values(fieldValidities).every((isValid) => isValid);
        },
        submitFields: (state) => {
            state.serverErrors = [];
            Object.keys(state.inputStates).forEach((field) => {
                state.inputStates[field] = { ...initialInputState, isSubmitted: true };
            });
        },
    }
});

export const importTemplateReducer = importTemplateSlice.reducer;

export default importTemplateSlice;
