import React from 'react';
import { qa } from 'redux/pages/patient-case/patientCaseColumns';
import qaSlice from 'redux/pages/patient-case/slices/qaSlice';
import PatientCaseGrid from '../Components/PatientCaseGrid';
import { useSelector } from 'react-redux';
import { isReviewer } from '../../../utils/helpers';

const columns = qa;

const Qa = (props) => {
  const { roleCode } = useSelector((state) => {
    return state.app.auth.user;
  });

  return <PatientCaseGrid variant="warning" title="QA" columns={columns} slice={qaSlice} clickable={!isReviewer(roleCode)} {...props} />;
};

export default Qa;
