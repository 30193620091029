import fetch from '../../utils/fetch'

export default (dispatch, page, actionName, extraData = {}) => {
	dispatch({
		type: actionName + '_LOADING',
		loading: true,
		...extraData
	})

	return fetch(page)

		.then(response => response.json())
		.then(data => {
			dispatch({
				type: actionName + '_SUCCESS',
				data,
				...extraData
			})
			return data
		})
		.catch(e => {
			dispatch({
				type: actionName + '_ERROR',
				error: e.message,
				...extraData
			})
		})
}
