import React from 'react';
import { connect } from 'react-redux';
import { editProcedure, getProcedure, isAllValid } from '../../../redux/actions/proceduresActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Box from "../../../components/Box";
import BoxHeader from "../../../components/BoxHeader";
import BoxBody from "../../../components/BoxBody";
import ProcedureForm from "./ProcedureForm";
import { getAll } from "../../../redux/actions/BasicEntityActions";
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import { getMultiSelectValue } from '../../../utils/helpers'
import SystemMessage from '../../../components/SystemMessage';
import SimpleReactValidator from "utils/simple-react-validator";

class ProcedureEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This Service already exists in the system.',
                rule: (val, opt) => !this.props.procedureNames.includes(val)
            }
        });
        this.validator.showMessages();

    }

    componentDidMount() {
        this.props.getProcedure(this.props.match.params.id);
        this.props.getAllMc();
        this.props.getAllSc();
    }

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        this.forceUpdate();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
        }
    }

    onSave = () => {
        let { systemToCode, names, name } = this.props.item

        const medicalCodeCodes = Object.keys(systemToCode).reduce((p, c) => {
            return [...p, ...systemToCode[ c ]]
        }, [])

        if (names) {
            names[ 'EN' ] = names[ 'EN' ] ? names[ 'EN' ] : name
            name = names[ 'EN' ] ? names[ 'EN' ] : name
        } else names = { 'EN': name }

        return this.props.saveProcedure({
            ...this.props.item,
            names: names,
            name: name,
            active: this.props.item.active,
            code: this.props.item.code,
            medicalCodingSystemCodes: medicalCodeCodes,
            specialtyCodes: getMultiSelectValue(this.props.item.specialtyCodes),
            bodyPartCodes: getMultiSelectValue(this.props.item.bodyPartCodes),
        })
    }

    render() {
        const { match, editProcedureSuccess, showValidMessages, isValid } = this.props;

        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[ field ],
        }))

        return (
            <div className="content" style={{ marginTop: '10px' }}>
                <PreventTransitionPrompt
                    when={this.props.isProfileChanged}
                    message='MyCustomDialogComponent'
                    isFormValid={isValid}
                    entity='procedure'
                    saveChanges={this.onSave}
                    cancelAndDiscard={() => this.props.getProcedure(this.props.item.code)}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <SystemMessage shown={editProcedureSuccess && isValid} type='success'
                               message={this.context.translate('common.message.service_success')}/>

                <SystemMessage shown={showValidMessages} type='error' message={this.props.error} validationMessages={errorMessages}/>

                <Box color="green" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`${this.context.translate('common.form.service')} - ${this.props.item.name}`} color="green"
                               icon="fa-list-alt" classes="box-profile-first-row-height-show"/>
                    <BoxBody>
                        <ProcedureForm match={match} params_id="" procedure={this.props.item} validator={this.validator}
                                       dispatchValidState={this.dispatchValidState}/>
                    </BoxBody>
                </Box>
            </div>
        );
    }
}

ProcedureEditPage.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    item: state.app.procedures.editedProcedure,
    loading: state.app.procedures.loading,
    isProfileChanged: state.app.procedures.isProfileChanged,
    showValidMessages: state.app.procedures.showValidMessages,
    isValid: state.app.procedures.procedureValid,
    error: state.app.procedures.error,
    editProcedureSuccess: state.app.procedures.editProcedureSuccess,
    procedureNames: state.app.procedures.data
        .filter((item) => item.code !== state.app.procedures.editedProcedure.code)
        .map((item) => item.name),
})

const mapDispatchToProps = dispatch => ({
    getAllMc: () => dispatch(getAll('mc_system')),
    getAllSc: () => dispatch(getAll('sc_system')),
    getProcedure: code => dispatch(getProcedure(code)),
    saveProcedure: data => dispatch(editProcedure(data)),
    isAllValid: (valid) => dispatch(isAllValid(valid))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureEditPage)
