import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../components/SubmitButton'
import PropTypes from "prop-types";
import { showValidation, updateEntity } from '../../redux/actions/BasicEntityActions'
import { getSelectFieldValue } from '../../utils/helpers'

class SavePatientProfile extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        e.preventDefault();
        if (this.props.isProfileValid) {
            this.setState({ showTooltip: false });
            this.props.saveProfile({
                ...this.props.profile,
                email: this.props.profile.email.trim(),
                gender: getSelectFieldValue(this.props.profile.gender),
                company: getSelectFieldValue(this.props.profile.company),
                state: getSelectFieldValue(this.props.profile.state),
                country: getSelectFieldValue(this.props.profile.country),
                birthDate: this.props.profile.birthDate ? new Date(this.props.profile.birthDate).getTime() : null,
            }, closeAfterSave)
        } else {
            this.props.showValidation()
        }
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton color='blue' onSubmit={this.onSave} showTooltip={!this.props.isProfileValid}
                              disabled={!this.props.isProfileChanged}/>
                <SubmitButton color='blue' onSubmit={(e) => this.onSave(e, true)} title='Save And Close'
                              showTooltip={!this.props.isProfileValid}
                              disabled={!this.props.isProfileChanged}/>
            </React.Fragment>
        )
    }
}

SavePatientProfile.contextTypes = {
    translate: PropTypes.func
};

const allowToEdit = (role) => (role === 'ROLE_ADMIN' || role === 'ROLE_CLIENT_ADMIN'
    || role === 'ROLE_CLIENT_USER' || role === 'ROLE_CLIENT_SUPERVISOR');

const mapStateToProps = (state) => ({
    isEditable: allowToEdit(state.app.auth.user.roleCode) || state.app.users.my_permissions.includes('MODIFY_PATIENTS'),
    profile: state.app.patients.editedPatient,
    isProfileValid: state.app.patients.patientValid,
    isProfileChanged: state.app.patients.isProfileChanged,
});

const mapDispatchToProps = dispatch => ({
    saveProfile: (data, closeAfterSave) => dispatch(updateEntity('patient', data, closeAfterSave)),
    showValidation: () => dispatch(showValidation('patient')),

});

export default connect(mapStateToProps, mapDispatchToProps)(SavePatientProfile)
