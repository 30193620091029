import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'

import PropTypes from "prop-types";
import { editMCSystem } from "../../../redux/actions/mcsystemsActions";
import { showValidation } from '../../../redux/actions/BasicEntityActions'


class SaveMCSystem extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        e.preventDefault();
        if (this.props.isValid) {
            this.props.editMCSystem({ ...this.props.mcSystem }, closeAfterSave)
        } else {
            this.props.showValidation()
        }
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={this.onSave}
                />
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={(e) => this.onSave(e, true)}
                    title={'Save And Close'}
                />
            </React.Fragment>
        )
    }
}

SaveMCSystem.contextTypes = {
    translate: PropTypes.func
};

const allowToEdit = (role) => (role === 'ROLE_ADMIN' || role === 'ROLE_CLIENT_ADMIN');

const mapStateToProps = (state) => ({
    isEditable: allowToEdit(state.app.auth.user.roleCode) || state.app.users.my_permissions.includes('MANAGE_SUR'),
    mcSystem: state.app.mcSystem.editedMCSystem,
    isValid: state.app.mcSystem.mcSystemValid,
    isProfileChanged: state.app.mcSystem.isProfileChanged,
});

const mapDispatchToProps = dispatch => ({
    showValidation: () => dispatch(showValidation('mc_system')),
    editMCSystem: (mcSystem, closeAfterSave) => {
        dispatch(editMCSystem(mcSystem, closeAfterSave))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveMCSystem)
