export const Statuses = {
    204: 'User not found',
    302: 'The record with the same data is already exist',
    400: 'Request contains invalid data',
    401: 'Unauthorized',
    403: 'Forbidden, Unauthorized',
    404: 'Not Found',
    452: 'Your login or password is incorrect',
    453: 'Number of attempts has expired',
    454: 'Username not found',
    455: 'Your profile is not active',
    456: 'You try to login without Email verification. You need to go to the email and click confirmation link',
    457: 'Google verification exception',
    458: "Can't send email",
    459: 'Not all required fields are specified',
    461: 'Password expired',
    500: 'Unknown error',
    0: 'Request timeout',

    old_password_not_correct: 'Old password is not correct',

    recovery_link_sent:
        'If an account associated with the provided email address exists, ' +
        'an email containing instructions on how to reset the password would be sent.',
    success_change_password: 'Password was successfully changed',
};

export const userErrors = {
    ...Statuses,
    302: 'User with the same email is already exist',
};
export const fieldsErrors = {
    ...Statuses,
    302: 'Field with the same key is already exist',
};

export const userStatuses = {
    LOGGED_IN: 'LOGGED_IN',
    AUTHENTICATED: 'AUTHENTICATED',
    LOGGING_IN: 'LOGGING_IN',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGGED_OUT: 'LOGGED_OUT',
};

// export const Statuses = {
//     '400': 'Server Exception',
//     '401': 'Unauthorized',
//     '403': 'Forbidden',
//     '404': 'Not Found',
//     '452': 'Bad credentials',
//     '453': 'Number of attempts has expired',
//     '454': 'Username not found',
//     '456': 'Credentials expired',
//     '457': 'Verification exception'
// };
