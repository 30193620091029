import React from 'react'
import MainLogo from './MainLogo'
import CollapsedLogo from './CollapsedLogo'
import SidebarToggle from './SidebarToggle'
//import CreateButton from './CreateButton'
import EntityManageButtons from './EntityManageButtons'
import NavBarNav from './NavBarNav';

const Header = () => (
    <header className="main-header">
        <MainLogo />
        <CollapsedLogo />
        <nav className="navbar navbar-fixed-top">
            <SidebarToggle />
            <div className="v-align-mid-50">
            </div>
            {/*<CreateButton/>*/}
            <div className="navbar-custom-menu">
                <NavBarNav />
            </div>
            <EntityManageButtons />
        </nav>
    </header>
);

export default Header
