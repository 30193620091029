import React from 'react';
import { connect } from 'react-redux';
import shortId from 'shortid'
import "../../components/ModalWin.css"
import FormField from '../../components/FormField'
import { createNewCompany } from '../../redux/actions/companiesActions'
import { getAllCurrencies } from '../../redux/actions/currencyActions'
import { getTemplates } from '../../redux/actions/templateActions'
import { getAllBillingCodes } from '../../redux/actions/billingActions'
import { getAllCaseFileTemplates } from '../../redux/actions/cases/caseFileTemplateActions'
import { getMultiSelectValue, getSelectFieldValue, idFromString } from '../../utils/helpers'
import FormValidateWrapper from '../../containers/FormValidateWrapper';
import { getLanguages } from "../../redux/actions/languageActions";

class ClientCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            allowReopens: false,
            businessCalendarDays: 'calendar',
            medicalCodingSystemCodes: null,
            caseLevels: ["INITIAL"]
        };

        this.createClient = this.createClient.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCurrencyCode = this.onChangeCurrencyCode.bind(this);
        this.onChangeDefaultTemplateCode = this.onChangeDefaultTemplateCode.bind(this);
        this.onChangeFileCaseTemplateCode = this.onChangeFileCaseTemplateCode.bind(this);
        this.onChangeBillingGroupCodes = this.onChangeBillingGroupCodes.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.onChangeCaseLevels = this.onChangeCaseLevels.bind(this);
        this.onChangeDefaultLanguageCode = this.onChangeDefaultLanguageCode.bind(this);
    }

    componentDidMount() {
        this.props.getAllBillingCodes(this.props.billing);
        this.props.getAllCurrencies(this.props.currencies);
        this.props.getTemplates(this.props.templates);
        this.props.getAllCaseFileTemplates(this.props.caseFileTemplates);
        this.props.getLanguages(this.props.languages);
        this.props.dispatchValidState();
    }

    createClient(e) {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            this.props.signUp({
                ...this.state,
                code: idFromString(shortId.generate()),
                currencyCode: getSelectFieldValue(this.state.currencyCode),
                defaultTemplateCode: getSelectFieldValue(this.state.defaultTemplateCode),
                caseFileTemplateCode: getSelectFieldValue(this.state.caseFileTemplateCode),
                billingGroup: getSelectFieldValue(this.state.billingGroup),
                medicalCodingSystemCodes: getMultiSelectValue(this.state.medicalCodingSystemCodes),
                defaultLanguageCode: getSelectFieldValue(this.state.defaultLanguageCode),
                businessCalendarDays: !(this.state.businessCalendarDays === 'calendar' ||
                    this.state.businessCalendarDays === false ||
                    this.state.businessCalendarDays === 'false'),
                caseLevels: getMultiSelectValue(this.state.caseLevels),

            });
        } else {
            this.props.dispatchValidState();
            this.props.showMessages()
        }
    }

    onChange(e) {
        this.setState({ [ e.target.name ]: e.target.value });
    }

    onChangeRadio = (e) => {
        const { value, name } = e.target;
        this.setState({ [ name ]: value === 'business' });
    };

    onChangeDefaultTemplateCode(defaultTemplateCode) {
        this.setState({ showSuccess: false });
        this.setState({ defaultTemplateCode });
    }

    onChangeFileCaseTemplateCode(caseFileTemplateCode) {
        this.setState({ showSuccess: false });
        this.setState({ caseFileTemplateCode });
    }

    onChangeCurrencyCode(currencyCode) {
        this.setState({ currencyCode });
    }

    onChangeBillingGroupCodes(billingGroup) {
        this.setState({ showSuccess: false });
        this.setState({ billingGroup });
    }

    onChangeCheckBox(e) {
        this.setState({ showSuccess: false });
        this.setState({ [ e.target.name ]: e.target.checked });
    }

    onChangeMedicalCodingSystemCodes = (medicalCodingSystemCodes) => this.setState({ medicalCodingSystemCodes });

    onChangeDefaultLanguageCode(defaultLanguageCode) {
        this.setState({ showSuccess: false });
        this.setState({ defaultLanguageCode })
    }

    onChangeCaseLevels(caseLevels) {
        let found = false;
        caseLevels.forEach((item) => {
            if (item.value === 'INITIAL') found = true;
        });
        if (!found) {
            caseLevels.push({
                value: 'INITIAL',
                label: 'Initial'
            })
        }
        this.setState({ caseLevels });
    }

    render() {
        const error = this.props.error;
        return (
            <form noValidate
                  className='modal-content custom-modal-content'
                  onSubmit={this.createClient}>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="6"
                            label="Full Name"
                            id="Full Name"
                            required
                            valids="required|unique"
                            validator={this.props.validator}
                            className="form-control"
                            name="fullName"
                            value={this.state.fullName}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            label="Short Name"
                            id="Short Name"
                            required
                            className="form-control"
                            name="shortName"
                            valids="max:5|required"
                            validator={this.props.validator}
                            value={this.state.shortName}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            type="select"
                            label="Default Template"
                            id="Default Template"
                            className="text-bg required-field"
                            searchable
                            valids="required"
                            validator={this.props.validator}
                            options={this.props.templatesAll}
                            name="countryCode"
                            value={this.state.defaultTemplateCode}
                            onChange={this.onChangeDefaultTemplateCode}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            type="select"
                            label="Case File Template"
                            id="Case File Template"
                            className="required-field text-bg"
                            valids="required"
                            validator={this.props.validator}
                            options={this.props.case_file_templates}
                            name="caseFileTemplateCode"
                            value={this.state.caseFileTemplateCode}
                            onBlur={this.props.dispatchValidStatus}
                            onChange={this.onChangeFileCaseTemplateCode}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="6"
                            type="select"
                            label="Billing Group"
                            id="Billing Group"
                            className="required-field"
                            searchable
                            valids="required"
                            required
                            validator={this.props.validator}
                            options={this.props.billingGroupCodes}
                            value={this.state.billingGroup}
                            onChange={this.onChangeBillingGroupCodes}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            xs="6"
                            required
                            type='input-icon'
                            icon='percent'
                            label="Hourly Margin"
                            id="Hourly Margin"
                            className="form-control"
                            divClasses="show"
                            name="margin"
                            valids="required|integer"
                            validator={this.props.validator}
                            value={this.state.margin}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="6"
                            className="text-bg required-field"
                            type="select"
                            label="Currency"
                            id="Currency"
                            searchable
                            valids="required"
                            validator={this.props.validator}
                            options={this.props.currenciesData}
                            value={this.state.currencyCode}
                            onChange={this.onChangeCurrencyCode}
                            multi={false}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            type="REF_MEDICAL_CODING_SYSTEM"
                            label="Coding Systems"
                            id="Coding Systems"
                            value={this.state.medicalCodingSystemCodes}
                            onChange={this.onChangeMedicalCodingSystemCodes}
                            valids="required"
                            validator={this.props.validator}
                            multi
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="6"
                            type="select"
                            label="Default Case Language"
                            id="Default Case Language"
                            searchable
                            valids="required"
                            validator={this.props.validator}
                            options={this.props.languageKeyValuePairs}
                            value={this.state.defaultLanguageCode}
                            onChange={this.onChangeDefaultLanguageCode}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="6"
                            type="checkbox"
                            title="Status"
                            label="Active"
                            name='active'
                            value={this.state.active}
                            checked={this.state.active}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                        />
                        <div className='col-md-6'>
                            <label className="required" style={{ marginBottom: '10px' }}>Business vs Calendar Days: </label>
                            <br/>
                            <FormField
                                type='radio'
                                label='Business'
                                name="businessCalendarDays"
                                onClick={this.onChangeRadio}
                                checked={this.state.businessCalendarDays === "business" || this.state.businessCalendarDays === true}
                                value='business'
                            />
                            <FormField
                                type='radio'
                                label='Calendar'
                                name="businessCalendarDays"
                                onClick={this.onChangeRadio}
                                checked={this.state.businessCalendarDays === "calendar" || this.state.businessCalendarDays === false}
                                value='calendar'
                            />
                        </div>
                    </div>
                </div>
                <p className="login-box-msg">
                    {(error) ? <span className="text-danger">{error}</span> : null}
                </p>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right text-right">
                            <button
                                className="btn btn-flat btn-success "
                                disabled={this.props.isLoading}
                            >
                                Add Client
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    const client_names = state.app.companies.data
        .map((item) => {
            return item.fullName;
        })
        .join(',');

    return {
        isLoading: state.app.companies.loading,
        error: state.app.companies.error,
        currenciesData: state.app.currencies.data
            .filter((item) => item.active)
            .map(currency => ({
                value: currency.code,
                label: currency.fullName
            })),
        templatesAll: state.app.templates.allTemplates
            .filter((item) => item.active)
            .map(template => ({
                value: template.code,
                label: template.name
            })),
        case_file_templates: state.app.case_file_templates.data
            .filter((item) => item.active)
            .map(template => ({
                value: template.code,
                label: template.name
            })),
        billingGroupCodes: state.app.billing.codes
            .filter((item) => item.active)
            .map((billing) => ({
                value: billing.code,
                label: billing.name
            })),
        languageKeyValuePairs: state.app.languages.data
            .map(lang => ({
                value: lang.code,
                label: lang.name
            })),

        //VALIDATOR PROPS
        top: 0,
        customRules: {
            unique: {
                message: 'This value already exists in the system, the new record won\'t be created.',
                rule: function (val) {
                    return !client_names.split(',').includes(val);
                }
            }
        },
        billing: state.app.billing,
        currencies: state.app.currencies,
        templates: state.app.templates,
        caseFileTemplates: state.app.case_file_templates,
        languages: state.app.languages
    }
};

const mapDispatchToProps = dispatch => ({
    signUp: (user) => {
        dispatch(createNewCompany(user))
    },
    getAllBillingCodes: (billing) => dispatch(getAllBillingCodes(billing)),
    getAllCurrencies: (currencies) => dispatch(getAllCurrencies(currencies)),
    getTemplates: (templates) => dispatch(getTemplates(templates)),
    getAllCaseFileTemplates: (caseFileTemplates) => dispatch(getAllCaseFileTemplates(caseFileTemplates)),
    getLanguages: (languages) => dispatch(getLanguages(languages))
});

export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(ClientCreateForm))
