import { createApiParams } from '../../../components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import { fetchEntityList$ } from 'redux/epicUtils/commonEpics';
import filteredReviewersSlice from "./filteredReviewersSlice";
import { transformFilteredReviewers } from "./utils";

const loadFilteredReviewersEpic = fetchEntityList$({
    ...filteredReviewersSlice.actions,
    resolveUrl: ({ patientCaseCode }) => {
        return `assignment/page?patientCaseCode=${patientCaseCode}`;
    },
    resolveParams: (params) => [{ params: createApiParams(params) }],
    dataTransformer: transformFilteredReviewers,
});

export default combineEpics(loadFilteredReviewersEpic);
