import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Modal from 'react-modal';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { closeCreateModal, openCreateModal } from '../../redux/action-creators/users'
import TemplateCreateForm from "./TemplateCreateForm";
import TemplateCopyForm from "./TemplateCopyForm";
import TemplateImportForm from "./TemplateImportForm";
import PropTypes from "prop-types";

class AddFieldButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeLink: 1 }
    }

    handleSelect = (selectedKey) => {
        this.setState({ activeLink: selectedKey });
        this.props.modalOpen();
    };

    render() {
        return (
            <span className={'margin-left-5'}>
                <DropdownButton
                    bsStyle='primary'
                    title='Add Template'
                    key={1}
                    id={`dropdown-basic-1`}
                >
                    <MenuItem eventKey="Add" onSelect={this.handleSelect}>Add New</MenuItem>
                    <MenuItem eventKey="Copy" onSelect={this.handleSelect}>Copy Template</MenuItem>
                    <MenuItem eventKey="Import" onSelect={this.handleSelect}>Import Template</MenuItem>
                </DropdownButton>

                <Modal
                    isOpen={this.props.isOpen}
                    contentLabel="onRequestClose Example"
                    className="custom-modal modal-dialog"
                    overlayClassName="modal-overlay"
                >
                    <h3 className="modal-title text-center"> {this.state.activeLink} Template
                        <i className='fa fa-times close' onClick={this.props.modalClose} />
                    </h3>
                    <div className='white-bg'>
                        {this.state.activeLink === 'Add' && <TemplateCreateForm history={this.props.history} />}
                        {this.state.activeLink === 'Copy' && <TemplateCopyForm />}
                        {this.state.activeLink === 'Import' && <TemplateImportForm />}
                    </div>
                </Modal>
           </span>
        );
    }
}

AddFieldButton.propTypes = {
    modalOpen: PropTypes.func.isRequired,
    modalClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    history: PropTypes.any
};

const mapStateToProps = (state) => ({
    isOpen: state.app.users.createModalOpen,
    currentPage: state.router.location.pathname,
    currentHash: state.router.location.hash,
    templateCurrentTab: state.app.templates.currentTab
});

const mapDispatchToProps = dispatch => ({
    modalOpen: () => dispatch(openCreateModal()),
    modalClose: () => dispatch(closeCreateModal())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFieldButton))
