import React from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import SubmitButton from '../../../../components/SubmitButton'
import {removeFieldInstance} from '../../../../redux/actions/templateActions'

class CreateFieldButton extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e) {
        e.preventDefault();
        const {field} = this.props;

        this.props.removeFieldInstance(field)
    }

    render() {
        return (<SubmitButton
            color='red'
            title='Remove'
            disabled={this.props.loading}
            onSubmit={this.onSave}
            showTooltip={false}
        />)
    }
}

CreateFieldButton.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    field: state.app.templates.editedField,
    loading: state.app.templates.loading
});

const mapDispatchToProps = dispatch => ({
    removeFieldInstance: data => dispatch(removeFieldInstance(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFieldButton)
