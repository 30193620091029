// import React from 'react';
import connect from "react-redux/es/connect/connect";
import CaseButton from './CaseButton'
import {reopenCase, } from '../../../redux/actions/cases/caseListActions'
import { openCaseReopenModal } from "redux/action-creators/users";


const mapState = (state) => {
    return {
        className: 'btn-default margin-left-5',
        isVisible: state.app.cases.editedCase.currentStage.code === 'CW_CLOSED' && state.app.auth.user.roleCode !== 'ROLE_REVIEWER',
        icon: 'fa-reply',
        title: 'Reopen',
        needConfirmation: true
    }
};

const mapDispatchToProps = dispatch => {
    const response =  {
        // onClick: () => dispatch(reopenCase()),
        onClick: () => dispatch(openCaseReopenModal()),
    }

    dispatch({
      type: 'CASE_IS_CREATED',
      payload: true
    });

  return response;
};

export default connect(mapState, mapDispatchToProps)(CaseButton);
