import api from '../../utils/api';
import { Statuses } from './statuses';
import history from '../history';
import { isEntityLoaded } from '../../utils/helpers';

const isAvailableInCache = (countries) =>
    isEntityLoaded(countries) && countries.all && countries.all.length > 0;

export const getActiveCountries = (countries) => (dispatch) => {
    if (isAvailableInCache(countries)) {
        return;
    }
    dispatch({
        type: 'FETCH_COUNTRY_LOADING',
    })
    api.get('country/all/active')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_COUNTRY_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_COUNTRY_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_COUNTRY_ERROR',
                    payload: error.message
                });
            }
        })

}

export const getActiveStates = () => (dispatch) => {
    dispatch({
        type: 'FETCH_STATE_LOADING',
    })

    api.get('state/all/active')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_STATES_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_STATES_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_STATES_ERROR',
                    payload: error.message
                });
            }
        })

}

export const getCountry = (code) => dispatch => {
    dispatch({
        type: 'FETCH_COUNTRY_LOADING',
    })

    return api.get(`country/get?code=${code}`)
        .then(({ data }) => dispatch({
            type: 'FETCH_GET_COUNTRY_SUCCESS',
            payload: data
        }))
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_COUNTRY_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_COUNTRY_ERROR',
                    payload: error.message
                });
            }
        })
}

export const createNewCountry = (country) => (dispatch) => {
    dispatch({
        type: 'FETCH_COUNTRY_LOADING',
    })

    api.post('country/create', country)
        .then(() => {
            dispatch({
                type: 'FETCH_CREATE_COUNTRY_SUCCESS',
                payload: country
            })
            dispatch({
                type: 'CLOSE_CREATE_MODAL'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_COUNTRY_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_COUNTRY_ERROR',
                    payload: Statuses[ error.request.status ]
                });
            }
        })

}


export const editCountry = (data, closeAfterSave) => dispatch => {
    dispatch({
        type: 'FETCH_COUNTRY_LOADING',
    })

    return api.post('country/update', data)
        .then(({ config }) => {
            dispatch({
                type: 'FETCH_EDIT_COUNTRY_SUCCESS',
                payload: JSON.parse(config.data)
            })

            closeAfterSave && history.goBack()

        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_EDIT_COUNTRY_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ]
            })
        })
}

export const countryEditField = (field, value) => ({
    type: 'COUNTRY_EDIT_FIELD',
    payload: {
        [ field ]: value
    }
})
