import React from 'react'

class ResponsiveTable extends React.Component {
    render() {
        let tableStyle = {
            // width: '35%',
            padding: '3px'
        };
        const {headerStyle} = this.props;
        return (
            <div className="table-responsive" style={{marginTop: '10px', marginBottom: '10px'}}>
                <table className="table" style={{background: 'inherit'}}>
                    <tbody>
                    {this.props.fields.map((m, key) => {
                        return (
                            <tr key={m.id} className={this.props.rowClassName}>
                                <th title={m.title} style={{...tableStyle, ...headerStyle}}>{m.title}</th>
                                <td title={m.value} style={tableStyle}>{m.value ? m.value : <i>None</i>}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ResponsiveTable
