import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { isClient, isReviewer } from '../helpers';

const useUserRole = () => {
    const roleCode = useSelector((state) => get(state, 'app.auth.user.roleCode'));

    return isClient(roleCode) ? 'CLIENT' : isReviewer(roleCode) ? 'REVIEWER' : 'STAFF';
};
export default useUserRole;
