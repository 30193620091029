import React from 'react'
import FooterRight from './FooterRight'
import FooterLeft from './FooterLeft'

const Footer = () => (
	<footer className="main-footer">
		<FooterRight/>
		<FooterLeft/>
	</footer>
)

export default Footer
