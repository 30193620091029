import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';

const createLinkingConnectedDataTableSlice = (name, initialSortByName = 'firstName') => createDatatableTemplateSlice({
    name: name,
    initialSettings: {
        sortBy: initialSortByName,
    },
    initialState: {
        selectedEntities: [],
        loadingLink: false,
        loadingUnlink: false,
    },
    reducers: {
        resetState: (state) => {
            state.selectedEntities = [];
            state.loadingLink = false;
            state.loadingUnlink = false;
        },
        addSelectedEntities: (state, { payload }) => {
            state.selectedEntities = state.selectedEntities.concat(payload);
        },
        removeSelectedEntities: (state, { payload }) => {
            state.selectedEntities = state.selectedEntities.filter((item) => !payload.map(payloadItem => payloadItem.code).includes(item.code));
        },
        resetEntities: (state) => {
            state.selectedEntities = [];
        },
        linkingEntities: (state) => {
            state.loadingLink = true;
        },
        unlinkingEntities: (state) => {
            state.loadingUnlink = true;
        },
        linkingEntitiesSuccess: (state) => {
            state.loadingLink = false;
            state.loadingUnlink = false;
        },
        linkingEntitiesError: (state) => {
            state.loadingLink = false;
            state.loadingUnlink = false;
        },
    }
});

// export const {
//     loadData, dataLoaded, dataLoadError, changeSettings,
//     resetState,
//     addSelectedEntities, removeSelectedEntities, resetEntities,
//     linkingEntities, unlinkingEntities, linkingEntitiesSuccess, linkingEntitiesError,
// } = createLinkingConnectedDataTableSlice.actions;

export default createLinkingConnectedDataTableSlice;
