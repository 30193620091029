import React from 'react';
import { connect } from 'react-redux';
import { getProcedure, isAllValid} from '../../../redux/actions/proceduresActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ProcedureForm from "./ProcedureForm";
import SimpleReactValidator from "utils/simple-react-validator";
import SystemMessage from '../../../components/SystemMessage';

class ProcedureCreateForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = {};
        let this_props = this.props;
        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This Service already exists in the system.',
                rule: function(val, options) {
                    let procedures_names = [];
                    this_props.procedures.forEach((item) => {
                        if (item.code !== this_props.params_id) {
                            procedures_names.push(item.name);
                        }
                    });
                    return !procedures_names.includes(val);
                }
            }
        });
        this.validator.showMessages();

    }

    componentDidMount() {
        this.props.getProcedure('');
        this.setState(this.props.procedure);
    }

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        this.forceUpdate();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
        }
    }

    render () {
        const {match, showValidMessages} = this.props;
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
                          id:field,
                          field,
                          message: this.validator.errorMessages[field],
                        }))
        return (
            <div>
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <SystemMessage shown={showValidMessages} type='error' message={this.props.error} validationMessages={errorMessages} top='0'/>

                <ProcedureForm match={match} params_id="" procedure={this.props.procedure} validator={this.validator} dispatchValidState={this.dispatchValidState}/>

            </div>
        );
    }
}

ProcedureCreateForm.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    procedure: state.app.procedures.editedProcedure,
    editProcedureSuccess: state.app.procedures.editProcedureSuccess,
    showValidMessages: state.app.procedures.showValidMessages,
    isLoading: state.app.procedures.loading,
    mc_systems: state.app.mcSystem.data
        .map(item => {
            return {
                ...item,
                sCSC: item.medicalCodeCodes.map(sc => {
                    return {
                        value: sc,
                        label: sc
                    }
                })
            }
        }),
    procedures: state.app.procedures.data,

    error: state.app.procedures.error,
})

const mapDispatchToProps = dispatch => ({
    getProcedure: code => dispatch(getProcedure('')),
    isAllValid: ( valid ) => dispatch(isAllValid( valid ))

})

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureCreateForm)
