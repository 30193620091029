import React from 'react';
import { complete } from 'redux/pages/patient-case/patientCaseColumns';
import completeSlice from 'redux/pages/patient-case/slices/completeSlice';
import PatientCaseGrid from '../Components/PatientCaseGrid';

const columns = complete;

const Complete = (props) => (
  <PatientCaseGrid columns={columns} slice={completeSlice} {...props} />
);

export default Complete;
