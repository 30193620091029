import React from 'react';
import { closedCasesThisMonth } from 'redux/pages/patient-case/patientCaseColumns';
import closedThisMonthSlice from 'redux/pages/patient-case/slices/closedThisMonthSlice';
import PatientCaseGrid from '../Components/PatientCaseGrid';
import { useSelector } from 'react-redux';
import { isReviewer } from '../../../utils/helpers';

const columns = closedCasesThisMonth;

const ClosedThisMonth = (props) => {
  const { roleCode } = useSelector((state) => {
    return state.app.auth.user;
  });

  return <PatientCaseGrid columns={columns} slice={closedThisMonthSlice} clickable={!isReviewer(roleCode)} {...props} />;
};

export default ClosedThisMonth;
