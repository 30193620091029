import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DataTableContext from './DataTableContext';
import DataTableFilterField from './DataTableFilterField';

const DataTableFilterRow = ({ visible, onFilterChange, filters }) => {
  const {
    filterOptions: filterOptionsFromProps,
    selectable,
    columns,
    columnKeys,
    serverside,
  } = useContext(DataTableContext);

  if (!visible) return null;

  return (
    <tr className="datatable__filter-header">
      <>
        {selectable && <th className="datatable__filter-cell" />}
        {columnKeys.map((columnKey) => {
          const {
            filterOptions: filterOptionsFromColumn,
            label,
            multiSelect,
            filterable,
          } = columns[columnKey];

          const filterOptions =
            filterOptionsFromColumn || serverside
              ? filterOptionsFromColumn
              : filterOptionsFromProps[columnKey];

          return (
            <DataTableFilterField
              visible={visible}
              key={columnKey}
              columnKey={columnKey}
              label={label}
              onChange={onFilterChange}
              defaultValue={filters[columnKey] || ''}
              options={filterOptions}
              multiSelect={multiSelect}
              filterable={filterable}
            />
          );
        })}
      </>
    </tr>
  );
};

DataTableFilterRow.propTypes = {
  visible: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

DataTableFilterRow.defaultProps = {
  visible: false,
};

export default DataTableFilterRow;
