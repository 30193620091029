import api from '../../utils/api';
import { userErrors } from '../actions/statuses'
import history from '../history'
import { GLOBAL_ASYNC_CACHE_TIME } from "../../utils/Constants";

export const openCreateModal = () => ({
    type: 'OPEN_CREATE_MODAL',
});

export const closeCreateModal = () => ({
    type: 'CLOSE_CREATE_MODAL',
});

export const openEditModal = () => ({
    type: 'OPEN_EDIT_MODAL',
});

export const closeEditModal = () => ({
    type: 'CLOSE_EDIT_MODAL',
});

export const openSubmitModal = () => ({
    type: 'OPEN_SUBMIT_MODAL',
});

export const closeSubmitModal = () => ({
    type: 'CLOSE_SUBMIT_MODAL',
});

export const openDeleteModal = () => ({
    type: 'OPEN_DELETE_MODAL',
});

export const closeDeleteModal = () => ({
    type: 'CLOSE_DELETE_MODAL',
});

export const openFilesModal = () => ({
    type: 'OPEN_FILES_MODAL',
});

export const closeFilesModal = () => ({
    type: 'CLOSE_FILES_MODAL',
});

export const openCaseReopenModal = () => ({
    type: 'OPEN_CASE_REOPEN_MODAL',
});

export const closeCaseReopenModal = () => ({
    type: 'CLOSE_CASE_REOPEN_MODAL',
});

export const saveDeletedCode = (code, type) => ({
    type: 'SAVE_DELETED_CODE',
    code: code,
    deletedType: type
});

export const getAllUsers = (url = 'auth/user/all', isWithProfile = true) => (dispatch) => { // url parameter for Hibernate problem !
    dispatch({
        type: 'FETCH_USER_LOADING',
    });

    let pathURL = url;

    if (isWithProfile === true) {
        pathURL = `${pathURL}?isWithProfile=${isWithProfile}`;
    }

    // api.get('auth/user/all')
    return api.get(pathURL)
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_ALL_USERS_SUCCESS',
                payload: data
            });
            dispatch({
                type: 'FILL_USER_LOCAL_STORAGE'
            });
            
            return;
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_USER_ERROR',
                payload: userErrors[ err.response ? err.response.status : '0' ]
            });

            return Promise.reject(err);
        });
};

export const getAllUsersWithProfiles = (url = 'auth/user/all_with_profiles', role = '') => (dispatch) => { // url parameter for Hibernate problem !
    dispatch({
        type: 'FETCH_USER_LOADING',
    });

    let pathURL = url;

    if (role) {
        pathURL = `${pathURL}?role=${role}`;
    }

    // api.get('auth/user/all')
    return api.get(pathURL)
            .then(({ data }) => {
                console.log("DATA", data);
                dispatch({
                    type: 'FETCH_ALL_USERS_SUCCESS',
                    payload: data
                });
                dispatch({
                    type: 'FILL_USER_LOCAL_STORAGE'
                });
                return;
            })
            .catch((err) => {
                dispatch({
                    type: 'FETCH_USER_ERROR',
                    payload: userErrors[ err.response ? err.response.status : '0' ]
                });

                return Promise.reject(err);
            });
};

export const getAllUsersIfEmpty = () => async (dispatch, getState) => {
    let users = getState().app.users.users;
    if (users.length === 0) {
        await dispatch(getAllUsers());
    }
};

const isRolesAvailableInCache = (users) =>
    users && users.roles && users.roles.length > 0 && users.lastLoadedRolesTime && (Date.now() - users.lastLoadedRolesTime) < GLOBAL_ASYNC_CACHE_TIME;

export const getAllRoles = (users) => (dispatch) => {
    if (isRolesAvailableInCache(users)) {
        return;
    }
    dispatch({
        type: 'FETCH_USER_ROLES_LOADING',
    });
    api.get('auth/role/all')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_USER_ROLES_SUCCESS',
                payload: data
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_USER_ERROR',
                payload: userErrors[ err.response ? err.response.status : '0' ]
            })
        })
};

export const getAllPermissions = () => (dispatch) => {
    dispatch({
        type: 'FETCH_USER_PERMISSIONS_LOADING',
    });

    api.get('auth/permission/all')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_USER_PERMISSIONS_SUCCESS',
                payload: data
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_USER_ERROR',
                payload: userErrors[ err.response ? err.response.status : '0' ]
            })
        })
};

export const getMyPermissions = () => (dispatch) => {
    dispatch({
        type: 'FETCH_USER_LOADING',
    });

    api.get('auth/user/get/authorities')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_MY_PERMISSIONS_SUCCESS',
                payload: data
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_USER_ERROR',
                payload: userErrors[ err.response ? err.response.status : '0' ]
            })
        })
};

export const getUserProfile = (userCode, url = 'auth/user/get?code=') => dispatch => { // url parameter for Hibernate problem !
    dispatch({
        type: 'FETCH_USER_PROFILE_LOADING',
    });

    // return api.get(`auth/user/get?code=${userCode}`)
    return api.get(url + userCode)
        .then(({ data }) => dispatch({
            type: 'FETCH_USER_PROFILE_SUCCESS',
            payload: data
        }))
        .catch((err) => {
            dispatch({
                type: 'FETCH_USER_PROFILE_ERROR',
                payload: userErrors[ err.response ? err.response.status : '0' ]
            })
        })
};

export const getUserStatistics = (code) => (dispatch) => {
    dispatch({
        type: 'FETCH_USER_LOADING',
    });

    api.get(`reviewer/statistics?code=${code}`)
        .then((res) => {
            dispatch({
                type: 'FETCH_USER_STATISTICS',
                payload: res.data
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_USER_ERROR',
                payload: userErrors[ err.response ? err.response.status : '0' ]
            })
        })
};

export const createNewUser = (user, userType, url = 'auth/user/create') => (dispatch) => { // url parameter for Hibernate problem !
    dispatch({
        type: 'FETCH_USER_LOADING',
    });

    // return api.post('auth/user/create', user)
    return api.post(url, user)
        .then(({ config }) => {
            dispatch({
                type: 'FETCH_CREATE_USER_SUCCESS',
                payload: JSON.parse(config.data)
            });
            dispatch(closeCreateModal());
            history.push(`${userType}/profile/${user.code}`)
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_USER_ERROR',
                payload: userErrors[ err.response ? err.response.status : '0' ]
            })
        })
};

export const editUser = (data, closeAfterSave, url = 'auth/user/update') => (dispatch) => { // url parameter for Hibernate problem !
    dispatch({
        type: 'FETCH_USER_LOADING',
    });

    // return api.post('auth/user/update', data)
    return api.post(url, data)
        .then(({ config }) => {
            dispatch({
                type: 'FETCH_EDIT_USER_SUCCESS',
                payload: JSON.parse(config.data)
            });
            closeAfterSave && history.goBack()
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'FETCH_EDIT_USER_ERROR',
                payload: userErrors[ err.response ? err.response.status : '0' ]
            })
        })
};

export const editRole = (data) => (dispatch) => {
    dispatch({
        type: 'FETCH_USER_ROLES_LOADING',
    });

    return api.post('auth/role/update', data)
        .then(({ config }) => {
            console.log(config);
            dispatch({
                type: 'FETCH_UPDATE_ROLE_SUCCESS',
                payload: JSON.parse(config.data)
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_USER_ERROR',
                payload: userErrors[ err.response ? err.response.status : '0' ]
            })
        })
};

export const userEditField = (field, value) => ({
    type: 'USER_EDIT_FIELD',
    payload: {
        [ field ]: value
    }
});

export const isAllValid = valid => {
    return {
        type: 'VALIDATE_USER_FIELD',
        payload: valid
    }
};

export const showValidation = () => {
    return {
        type: 'SHOW_USER_VALIDATION',
    }
};

export const emitValidationStatus = (status) => {
    return {
        type: 'REVIEWER_VALID_STATUS',
        payload: status
    }
};

export const setCurrentTab = (tab) => {
    return {
        type: 'SET_USER_CURRENT_TAB',
        payload: tab
    }
};

export const userLoaded = () => {
    return {
        type: 'USER_LOADED',
    }
};
