import React, {Component} from "react";
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import ProfileFormWrapper from '../../containers/ProfileFormWrapper'

import FormField from '../../components/FormField'
import ContactInfoSection from "../../components/Sections/ContactInfoSection";
import AddressInfoSection from "../../components/Sections/AddressInfoSection";

class ProfileUserDataStaff extends Component {
    state = {};

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        if (nextProps.profile && (this.state.firstLoad || JSON.stringify(nextProps.profile) !== JSON.stringify(this.props.profile))) {
            // let myLanguage = '';
            // let myLocale = '';
            if (nextProps.profile) {
                // myLanguage = this.props.languages.find((item) => {
                //     if (item.value == nextProps.profile.defaultLanguageCode)
                //         return item;
                // });
                // myLocale = this.props.locales.find((item) => {
                //     if (item.value == nextProps.profile.defaultLocaleCode)
                //         return item;
                // });
            }
            this.setState({firstLoad: false});
        }
    }

    render() {
        return (
            <Box color="blue" classes="box-profile-first-row-height-show">
                <BoxHeader title="Profile" color="blue" icon="fa-user"/>
                <BoxBody>
                    <form noValidate>
                        <div className="form-group bordered">
                            <div className="row">
                                <FormField
                                    md="12"
                                    type='label_field'
                                    label="Personal Information"
                                    className='margin-bottom-15'
                                    style={{fontSize: '1.1em'}}
                                />
                                <FormField
                                    md="4"
                                    label="First Name"
                                    id="First Name"
                                    className="form-control"
                                    required
                                    name="firstName"
                                    valids="required|alpha_dash_space"
                                    validator={this.props.validator}
                                    value={this.props.profile.firstName}
                                    onChange={this.props.onChange}
                                    onBlur={this.props.dispatchValidState}
                                />
                                <FormField
                                    md="4"
                                    label="Last Name"
                                    id="Last Name"
                                    className="form-control"
                                    required
                                    name="lastName"
                                    valids="required|alpha_dash_space"
                                    validator={this.props.validator}
                                    value={this.props.profile.lastName}
                                    onChange={this.props.onChange}
                                    onBlur={this.props.dispatchValidState}
                                />
                            </div>
                        </div>
                        <ContactInfoSection
                            validator={this.props.validator}
                            dispatchValidState={this.props.dispatchValidState}
                            onChange={this.props.onChange}
                            email={this.props.profile.email}
                            notificationEmail={this.props.profile.notificationEmail}
                            phoneNumber={this.props.profile.phoneNumber}
                            alternativePhoneNumber={this.props.profile.alternativePhoneNumber}
                        />
                        <AddressInfoSection
                            onChange={this.props.onChange}
                            onChangeCountry={this.props.onChangeCountry}
                            onChangeState={this.props.onChangeState}
                            address1={this.props.profile.address1}
                            address2={this.props.profile.address2}
                            city={this.props.profile.city}
                            countryCode={this.props.profile.countryCode}
                            stateCode={this.props.profile.stateCode}
                            postalCode={this.props.profile.postalCode}
                        />

                        <div className="form-group">
                            <div className="row">
                                <FormField
                                    md="4"
                                    type="select"
                                    label="Locale"
                                    className="text-bg "
                                    searchable
                                    multi={false}
                                    disabled={false}
                                    options={this.props.locales}
                                    name="defaultLocaleCode"
                                    value={this.props.profile.defaultLocaleCode}
                                    onChange={this.props.onChangeDefaultLocale}
                                />
                                <FormField
                                    md="4"
                                    type="select"
                                    label="Time Zone"
                                    className="text-bg "
                                    searchable={true}
                                    multi={false}
                                    disabled={false}
                                    options={this.props.timezones}
                                    name="timeZoneCode"
                                    value={this.props.profile.timeZoneCode}
                                    onChange={this.props.onChangeTimeZones}
                                />
                            </div>
                        </div>
                    </form>
                </BoxBody>
                <BoxFooter>

                </BoxFooter>
            </Box>
        );
    }
}

export default ProfileFormWrapper(ProfileUserDataStaff);
