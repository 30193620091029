import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import MenuItem from './MenuItem'
import {sortBy} from 'utils/helpers'

const sortByPosition = sortBy('position', false)

class MenuItemGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
        this.url = null;
        const {children} = props.menuItem;

        if (children && children.length) {
            this.url = children.sort(sortByPosition)[0].link
        }

    }

    componentDidMount() {
        const {menuItem, history} = this.props;
        const groupUrls = menuItem.children.reduce((urls, item) => [...urls, item.link], [])

        this.setState({
            open: groupUrls.indexOf(history.location.pathname) !== -1
        })
    }

    toggleOpen = (e) => {
        e.preventDefault();
        this.setState({
            open: !this.state.open
        })

    }

    render() {
        const {menuItem} = this.props;
        let display = this.state.open ? 'block' : 'none'
        // let activeClassName = this.state.open ? 'active' : ''

        return (
            [<MenuItem
                key={menuItem.code}
                counterEndPoint={menuItem.counter_endpoint}
                name={menuItem.name['EN']}
                itemName={menuItem.code}
                url={this.url}
                icon={menuItem.icon}
                tooltip={menuItem.tooltip}
                onClick={this.toggleOpen}
                isGroupItem
            >
                {menuItem.children}
            </MenuItem>,
                <ul className="treeview-menu" style={{display: display}} key={menuItem.code + '_group'}>
                    {
                        menuItem.children
                            .filter((item) => {
                                if (menuItem.code === 'cases_group' && this.props.role === 'ROLE_REVIEWER') {
                                    return item.code === 'flagged' || item.code === 'review'
                                } else return true
                            })
                            .sort(sortByPosition)
                            .map((item) => {
                                return <MenuItem
                                    key={item.code}
                                    counterEndPoint={item.counter_endpoint}
                                    name={item.name['EN']}
                                    itemName={item.code}
                                    url={item.link}
                                    icon={item.icon}
                                    tooltip={item.tooltip}
                                >
                                    {item.children}
                                </MenuItem>
                            })
                    }
                </ul>]

        )
    }
}

export default withRouter(MenuItemGroup);
// {
//   "code": "cases_main",
//   "active": true,
//   "link": "/cases",
//   "icon": "fa-file-text-o",
//   "position": 3,
//   "parentMenuItemCode": null,
//   "adminMenu": false,
//   "name": {
//     "EN": "Cases"
//   },
//   "permissionCodes": [],
//   "childrenCodes": [],
//   "children": null,
//   "tooltip": null
// },
