import { isClient, isJSON, isReviewer } from './helpers';
import { TAT_MAX, TAT_MIN } from './Constants';
import { set } from 'lodash';

// let moment = require('moment');
let moment = require('moment-business-days');

export function isAssignSection(section) {
    return section.code.endsWith('_ReviewerAssignment') // unified by importer
        || section.code.substr(0, 11) === 'assignment_'
        || section.code.indexOf('_assignment') > 0;
}

export function isAttachSection(section) {
    return section.code.endsWith('_Attachments') // unified by importer synonym of MedicalRecords
        || section.code.substr(0, 12) === 'attachments_' // old templates?
        || section.code.indexOf('_attachments') > 0;
}

export function isMedicalRecordsSection(section) {
    return section.code.endsWith('_MedicalRecords') // unified by importer
        || section.code.substr(0, 16) === 'medical_records_' // old templates?
        || section.code.indexOf('_medical_records') > 0;
}

export function isFinanceSection(section) {
    return section.code.endsWith('_PricingManagement') // unified by importer
        || section.code.endsWith('_finance') // old templates?
        || section.code.endsWith('_pricing_management');
}

export function isCaseCommentsSection(section) {
    //console.log(section);
    return section.code.endsWith('_Comments') // unified by importer
        || section.code.substr(0, 9) === 'comments_' // old templates?
        || section.code.endsWith('_comments');
}

export function isReportSection(section) {
    return section.code.endsWith('_Report') // unified by importer
        || section.code.substr(0, 7) === 'report_' // old templates?
        || section.code.indexOf('_report') > 0;
}

export function isInternalSection(section) {
    return section.code.endsWith('_InternalCaseFiles') // unified by importer
        || section.code.substr(0, 19) === 'internal_case_file_' // old templates?
        || section.code.indexOf('_internal_case_file') > 0;
}

export function isOwnerSection(section) {
    return section.code.endsWith('_Owners') // unified by importer
        || section.code.substr(0, 7) === 'owners_' // old templates?
        || section.code.indexOf('_owners') > 0;
}

export function isSystemSection(section) {
    return (
        isOwnerSection(section) ||
        isAssignSection(section) ||
        isAttachSection(section) ||
        isMedicalRecordsSection(section) ||
        isReportSection(section) ||
        isInternalSection(section) ||
        isFinanceSection(section) ||
        isCaseCommentsSection(section)
    );
}

export function isEmptySection(section, fields, matrixCode) {
    if (isSystemSection(section)) return false;
    if (!section.fieldInstances || section.fieldInstances.length === 0) return true;
    let fieldCount = 0;
    for (let j = 0; j < fields.length; j += 1) {
        let field = fields[j];
        if (section.fieldInstances.includes(field.code)) {
            if (field.visibilityMatrix[matrixCode]) fieldCount += 1;
        }
    }
    return fieldCount === 0;
}

export function checkForContinueSection(section, fields, matrixCode, reports_length, my_permissions) {
    if (
        isAssignSection(section) &&
        !(
            matrixCode.indexOf('DISPATCH#ROLE_ADMIN') !== -1 ||
            matrixCode.indexOf('DISPATCH#ROLE_STAFF') !== -1 ||
            matrixCode.indexOf('REVIEW#ROLE_ADMIN') !== -1 ||
            matrixCode.indexOf('REVIEW#ROLE_STAFF') !== -1
        )
    ) {
        return true;
    }
    if (isCaseCommentsSection(section) && !my_permissions.includes('COMMENT_CASES')) {
        return true;
    }
    if (
        (isOwnerSection(section) || isInternalSection(section)) &&
        !(matrixCode.indexOf('#ROLE_ADMIN') !== -1 || matrixCode.indexOf('#ROLE_STAFF') !== -1)
    ) {
        return true;
    }
    if (isInternalSection(section) && !my_permissions.includes('DOWNLOAD_INTERNAL_FILE')) return true;
    if (isReportSection(section) && reports_length === 0) return true;
    return isEmptySection(section, fields, matrixCode);
}

export function isReadOnlyField(field, user, editedCase, matrixCode, assignmentDetails, isFinanceLoading) {
    let readonly = isFinanceLoading || field.readOnlyMatrix[matrixCode] || matrixCode.indexOf('_CLOSED') > 0;
    if (editedCase && !readonly) {
        if (isReviewer(user.roleCode)) {
            let detailAction = '';
            let aDetails = assignmentDetails;
            for (let i = 0; i < aDetails.length; i += 1) {
                if (aDetails[i].reviewerCode === user.code) {
                    detailAction = aDetails[i].action;
                }
            }
            if (detailAction !== 'ACCEPTED' || matrixCode.indexOf('_REVIEW') === -1) {
                readonly = true;
            }
        }
        if (isClient(user.roleCode)) {
            readonly = !(editedCase.currentStage.code === 'CW_DRAFT');
        }
    }
    return readonly;
}

/////////////    BEGIN CASE LOCAL STORAGE     /////////////
export function getLocalStorageKey(code) {
    return 'c__' + code;
}

export function saveCaseLocalStorageData(code, arrayKey, data) {
    if (typeof code === 'string') {
        let key = getLocalStorageKey(code);
        let saved = isJSON(localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : {};
        set(saved, arrayKey, data);
        localStorage.setItem(key, JSON.stringify(saved));
    }
}

export function loadCaseLocalStorageData(code, arrayKey) {
    let key = getLocalStorageKey(code);
    let result = {};
    if (isJSON(localStorage.getItem(key))) {
        let saved = JSON.parse(localStorage.getItem(key));
        if (typeof saved[arrayKey] !== 'undefined') {
            result = saved[arrayKey];
        }
    }
    return result;
}

export function checkIfRefDataInCaseLocalStorage(caseCode, fieldCode) {
    let Case = loadCaseLocalStorageData(caseCode, 'case');
    return typeof Case[fieldCode] !== 'undefined';
}

export function saveToLocalStorageToCaseKey(caseCode, refArray, field, fieldValue) {
    let Case = loadCaseLocalStorageData(caseCode, 'case');
    Case[field.code] = refArray.find((entity) => entity.code === fieldValue);
    saveCaseLocalStorageData(caseCode, 'case', Case);
}

export function clearCaseLocalStorageData(code) {
    localStorage.removeItem(getLocalStorageKey(code));
}

/////////////   END CASE LOCAL STORAGE     /////////////

function correctTime(tempDate, corrTime) {
    return moment(tempDate).format('L') + ' ' + corrTime;
}

// TODO: need deep testing
export function addWeekdays(date, days, corrTime, businessCalendarDays) {
    return correctTime(businessCalendarDays ? moment(date).businessAdd(days) : moment(date).add(days, 'days'), corrTime);
}

export function addWeekdaysAutistic(date, days, corrTime, businessCalendarDays) {
    date = moment(date);
    if (businessCalendarDays) {
        while (days !== 0) {
            date = days > 0 ? date.add(1, 'days') : date.subtract(1, 'days');
            // decrease "days" only if it a weekday.
            if (date.isoWeekday() < 6) {
                days = days > 0 ? days - 1 : days + 1;
            }
        }
    } else {
        date = days > 0 ? date.add(days, 'days') : date.subtract(days, 'days');
    }
    return correctTime(date, corrTime);
}

function addWeekdaysHours(date, hours, corrTime, businessCalendarDays) {
    date = moment(correctTime(date, corrTime));
    date = date.add(hours, 'hours');
    if (businessCalendarDays && date.isoWeekday() === 6 && date.isoWeekday() === 7) {
        date = hours > 0 ? date.add(2, 'days') : date.subtract(2, 'days');
    }
    return date;
}

function diffDates(d1, d2, businessCalendarDays) {
    let diff = Math.abs(Math.round(moment(d1).diff(moment(d2), 'days', true)));
    if (businessCalendarDays) {
        let begin = d1 < d2 ? d1 : d2;
        let day = moment(begin).isoWeekday();
        let countWeeks = Math.floor(diff / 7);
        let checkDays = day + diff - countWeeks * 7;
        if (checkDays > 6) {
            diff -= 2;
        } else if (checkDays === 6) {
            diff -= 2;
        }
        diff -= countWeeks * 2;
    }
    return diff;
}

function getDefTime(oldD, newD) {
    let oldCdd = new Date(oldD);
    let newCdd = new Date(newD);
    if (moment(oldCdd).format('L') !== moment(newCdd).format('L')) {
        return moment(oldCdd).format('H:mm:ss');
    } else {
        return moment(newCdd).format('H:mm:ss');
    }
}

function checkTimestamp(timestamp) {
    return new Date(timestamp).getTime() > 0 && ('' + timestamp).length > 10;
}

export function onChangeTat(tat, currentCase, businessCalendarDays) {
    let cdd, atat, rdd;
    if (tat < TAT_MIN) {
        tat = TAT_MIN;
    }
    if (tat > TAT_MAX) {
        tat = TAT_MAX;
    }

    let defTime = moment(currentCase.clientDueDate).utc(false).format('H:mm');

    if (currentCase.currentStage.code.indexOf('DRAFT') !== -1 || !checkTimestamp(currentCase.submitDate)) {
        cdd = addWeekdays(new Date(), tat, defTime, businessCalendarDays);
    } else {
        cdd = addWeekdays(currentCase.submitDate, tat, defTime, businessCalendarDays);
    }

    if (checkTimestamp(currentCase.dispatchDate)) {
        atat = diffDates(cdd, currentCase.dispatchDate, businessCalendarDays);
    } else {
        atat = diffDates(cdd, new Date(), businessCalendarDays);
    }

    if (atat <= 1) {
        rdd = addWeekdaysHours(cdd, -4, defTime, businessCalendarDays);
    } else if (atat < 3) {
        rdd = addWeekdaysHours(cdd, -6, defTime, businessCalendarDays);
    } else if (atat >= 3) {
        rdd = addWeekdays(cdd, -1, defTime, businessCalendarDays);
    }

    return { cdd: cdd, rdd: rdd };
}

export function onChangeCDD(cdd, currentCase, businessCalendarDays) {
    let tat;
    let atat;
    let rdd;
    let defTime = getDefTime(currentCase.clientDueDate, cdd);
    if (currentCase.currentStage.code.indexOf('DRAFT') !== -1 || !checkTimestamp(currentCase.submitDate)) {
        tat = diffDates(cdd, new Date(), businessCalendarDays);
    } else {
        tat = diffDates(cdd, currentCase.submitDate, businessCalendarDays);
    }
    if (tat < TAT_MIN) {
        tat = TAT_MIN;
    }
    if (tat > TAT_MAX) {
        tat = TAT_MAX;
    }
    if (checkTimestamp(currentCase.dispatchDate)) {
        atat = diffDates(cdd, currentCase.dispatchDate, businessCalendarDays);
    } else {
        atat = diffDates(cdd, new Date(), businessCalendarDays);
    }
    if (atat < 1) {
        rdd = addWeekdaysHours(cdd, -4, defTime, businessCalendarDays);
    } else if (atat < 3) {
        rdd = addWeekdaysHours(cdd, -6, defTime, businessCalendarDays);
    } else if (atat >= 3) {
        rdd = addWeekdays(cdd, -1, defTime, businessCalendarDays);
    }

    return { tat: tat, rdd: rdd };
}

export function onChangeRDD(rdd, tat, currentCase, businessCalendarDays) {
    let atat;
    let rtat;
    let cdd;
    let defTime = getDefTime(currentCase.reviewerDueDate, rdd);
    if (checkTimestamp(currentCase.dispatchDate)) {
        rtat = diffDates(rdd, currentCase.dispatchDate, businessCalendarDays);
    } else {
        rtat = diffDates(rdd, new Date(), businessCalendarDays);
    }
    if (tat < TAT_MIN) {
        tat = TAT_MIN;
    }
    if (tat > TAT_MAX) {
        tat = TAT_MAX;
    }

    let beginTime = moment();
    let endTime = moment();
    if (checkTimestamp(currentCase.submitDate)) {
        beginTime = moment(currentCase.submitDate);
    }
    if (checkTimestamp(currentCase.dispatchDate)) {
        endTime = moment(currentCase.dispatchDate);
    }
    atat = tat - (beginTime.get('days') - endTime.get('days'));
    let diff = atat - rtat;
    if (diff <= 0 || atat < 1) {
        cdd = addWeekdaysHours(rdd, 4, defTime, businessCalendarDays);
    } else if (atat < 3) {
        cdd = addWeekdaysHours(rdd, 6, defTime, businessCalendarDays);
    } else {
        cdd = addWeekdays(rdd, 1, defTime, businessCalendarDays);
    }

    if (currentCase.currentStage.code.indexOf('DRAFT') !== -1 || !checkTimestamp(currentCase.submitDate)) {
        tat = diffDates(cdd, new Date(), businessCalendarDays);
    } else {
        tat = diffDates(cdd, currentCase.submitDate, businessCalendarDays);
    }
    if (tat < TAT_MIN) {
        tat = TAT_MIN;
    }
    if (tat > TAT_MAX) {
        tat = TAT_MAX;
    }

    atat = tat - (beginTime.get('days') - endTime.get('days'));
    diff = atat - rtat;
    if (diff <= 0 || atat < 1) {
        cdd = addWeekdaysHours(rdd, 4, defTime, businessCalendarDays);
    } else if (atat < 3) {
        cdd = addWeekdaysHours(rdd, 6, defTime, businessCalendarDays);
    } else {
        cdd = addWeekdays(rdd, 1, defTime, businessCalendarDays);
    }
    return { tat: tat, cdd: cdd, rdd: rdd };
}
