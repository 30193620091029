import React, {Component} from 'react'
import {connect} from 'react-redux'
import {BootstrapTable, TableHeaderColumn, SearchField} from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Box from "../Box";
import BoxBody from "../BoxBody";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {
    onChangeSortConfiguration,
    resetSortConfiguration,
    onChangePage,
    saveTotal
} from '../../redux/actions/gridActions'
import {addVisibleCodes} from '../../redux/actions/cases/caseListActions'
import RemoteGridSearchPanel from "./RemoteGridSearchPanel";

const preventedTags = ['BUTTON', 'A', 'I'];

class RemoteGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: typeof props.showFilters !== "undefined" ? props.showFilters : false,
            page: 0,
            sizePerPage: props.sizePerPage ? props.sizePerPage : 10,
            sort: false,
            dir: 'asc',
            search: '',
            filters: [],
            visibleCodes: [],
            caseKeys: {}
        };
        this.fetchData = this.fetchData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSizePerPageChange = this.handleSizePerPageChange.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.actionFormatter = this.actionFormatter.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.createCustomSearchField = this.createCustomSearchField.bind(this);
        this.filtered = this.filtered.bind(this);
        this.changeFilters = this.changeFilters.bind(this);
        //this.renderShowsTotal = this.renderShowsTotal.bind(this);
    }

    componentDidMount() {
        this.props.showSearch();
        if (this.props.memoryData) {
            let entity = this.props.entity[0] + this.props.entity.replace(/_/g, ' ').replace(/\b\w/g, function (l) {
                return l.toUpperCase()
            }).replace(/ /g, '').substr(1);
            if (typeof this.props.totalMemory[entity] !== "undefined" && this.props.totalMemory[entity].total !== this.props.data.length)
                this.props.saveTotal(this.props.data.length, this.props.entity);
        }
    }

    componentWillUnmount() {
        this.props.hideSearch()
    }

    fetchData(page = this.state.page,
              sizePerPage = this.state.sizePerPage,
              sort = this.state.sort,
              dir = this.state.dir,
              search = this.state.search) {
        let data = {
            'page': page,
            'size': sizePerPage,
            'sort': sort,
            'dir': dir,
            'search': search
        };
        if (this.props.fetchData) {
            this.props.fetchData(data);
        }
    }

    handleFilterChange(filters) {
        this.props.onChangePage(1, this.props.sizePerPage, this.props.entity);
        this.setState({filters: filters});
    }

    getMemoryData(entity) {
        //console.log()
        let searchValue = '';

        function isFoundSearch(item, searchValue) {
            for (let attr in item) {
                if (!item.hasOwnProperty(attr)) continue;
                if (item[attr] == null) continue;
                if (typeof item[attr] === "undefined") continue;
                if (typeof item[attr] === "boolean") item[attr] += '';
                if (typeof item[attr] !== "string") continue;
                if (item[attr].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) return true;
            }
            return false;
        }

        function isFilteredData(item, attr, value) {
            if (!item.hasOwnProperty(attr)) return true;
            if (typeof item[attr] === "undefined") return false;
            if (typeof item[attr] === "boolean") item[attr] += '';
            if (typeof item[attr] !== "string") return false;
            return (item[attr].toLowerCase().indexOf(value.toLowerCase()) !== -1);
        }

        let data = [...this.props.data];
        if (document.getElementById('search-everywhere') != null) {
            searchValue = document.getElementById('search-everywhere').value;
            if (searchValue !== "undefined" && searchValue !== '' && searchValue != null) {
                let searchData = data.filter((item) => isFoundSearch(item, searchValue));
                data = [...searchData];
            }
        }
        let keyFilter = '';
        for (let colFiltered in this.state.filters) {
            if (!this.state.filters.hasOwnProperty(colFiltered)) continue;
            keyFilter += this.state.filters[colFiltered].value;
            let searchData = data.filter((item) => isFilteredData(item, colFiltered, this.state.filters[colFiltered].value));
            data = [...searchData];
        }

        function dynamicSort(field, order) {
            let sortOrder = order === 'asc' ? -1 : 1;
            let a_field, b_field;
            return function (a, b) {
                if (typeof a[field] === "undefined" || a[field] === null) { a_field = ''; }
                else {
                    a_field = typeof a[field] === "string" ?
                        (a[field] != null ? a[field].toUpperCase() : ' ')
                        : a[field] + '';
                }
                if (typeof b[field] === "undefined" || b[field] === null) { b_field = ''; }
                else {
                    b_field = typeof b[field] === "string" ?
                        (b[field] != null ? b[field].toUpperCase() : ' ')
                        : b[field] + '';
                }
                if (a_field === ('' + parseInt(a_field, 10)) && b_field === ('' + parseInt(b_field, 10))) {
                    a_field = parseInt(a_field, 10);
                    b_field = parseInt(b_field, 10);
                }
                if (a_field < b_field)
                    return sortOrder;
                if (a_field > b_field)
                    return -sortOrder;
                return 0;
            }
        }

        // let sName =this.props.sortName;
        // if (this.props.sortOrder === 'asc')
        //     sort(data).asc(item => item[sName] == parseInt(item[sName]) ? parseInt(item[sName]) : item[sName]);
        // else sort(data).desc(item => item[sName] == parseInt(item[sName]) ? parseInt(item[sName]) : item[sName]);
        data.sort(dynamicSort(this.props.sortName, this.props.sortOrder));
        let data_length = data.length;
        if (typeof this.props.totalMemory[entity] !== "undefined" && this.props.totalMemory[entity].total !== data_length) {
            this.props.saveTotal(data_length, this.props.entity);
        }
        let from = (this.setPageSavely() - 1) * this.props.sizePerPage;
        if (from > data_length) from = 0;
        let to = from + this.props.sizePerPage;
        let returnData = data.slice(from, to);
        if (this.props['isCases']) {
            let key = this.props.sortName + this.props.sortOrder + searchValue + from + to + keyFilter;
            if (typeof this.state.caseKeys[key] === "undefined") {
                let caseKeys = this.state.caseKeys;
                caseKeys[key] = 1;
                this.setState({caseKeys: caseKeys});

                let visibleCodes = [];
                let returnCodes = [];
                for (let i = 0, returnLength = returnData.length; i < returnLength; i++) {
                    visibleCodes.push(returnData[i].code);
                    if (this.props.visibleCodes.indexOf(returnData[i].code) === -1)
                        returnCodes.push(returnData[i].code);
                }
                this.setState({visibleCodes: visibleCodes});
                if (returnCodes.length > 0) this.props.addVisibleCodes(returnCodes);
            }
        }
        return returnData;
    }

    handlePageChange(page, sizePerPage) {
        this.setState({page: page - 1, sizePerPage: sizePerPage});
        this.fetchData(page, sizePerPage, this.state.sort, this.state.dir, this.state.search);
    }

    handleSizePerPageChange(sizePerPage) {
        this.setState({page: 0, sizePerPage: sizePerPage});
        this.fetchData(0, sizePerPage, this.state.sort, this.state.dir, this.state.search);
    }

    handleSortChange(sort) {
        this.setState({sort: sort, dir: this.state.dir === 'asc' ? 'desc' : 'asc'});
        this.fetchData(this.state.page, this.state.sizePerPage, this.state.sort,
            this.state.dir, this.state.search);
    }

    handleSearchChange(search) {
        this.setState({search: search});
        this.fetchData(this.state.page, this.state.sizePerPage, this.state.sort,
            this.state.dir, this.state.search);
    }

    onRowClick(row) {
        if (!this.props.onRowClick) return;

        const {tagName} = arguments[3].target;
        if (preventedTags.includes(tagName)) {
            return false;
        } else {
            if (tagName === 'INPUT') {
                this.props.onRowClick(arguments, row);
            } else {
                this.props.onRowClick(row.code, row);
            }
        }
    }

    actionFormatter(cell, row) {
        // switch (this.props.actionType) {
        //     case 'link':
        if (this.props['actionType'] === 'link')
            return (<button className='btn btn-grey btn-sm' onClick={() => this.props['onAction'](row)}>
                {row.linked ? 'Deactivate' : 'Activate'}
            </button>)
        //}
    }

    createCustomSearchField = () => {
        return (
            <SearchField placeholder={this.context.translate('common.form.search')} defaultValue='ci'/>
        );
    };

    filtered = (item) => {
        if (this.state.showFilters) {
            if (item.filter && item.filter.type === 'TextFilter') {
                item.filter.placeholder = this.context.translate('common.form.enter') + ' ' + item.title;
            }
            return item.filter;
        }
        if (item.filter && typeof item.filter.defaultValue !== "undefined") {
            return item.filter;
        }
        return null;
    };

    changeFilters = () => {
        if (this.state.showFilters) {
            this.setState({'showFilters': false});
        } else {
            this.setState({'showFilters': true});
        }
    };

    changePage = () => {
        this.props.history.push(this.props['titleLink']);
    };

    setPageSavely = () => {
        const {total, sizePerPage, page} = this.props;

        if (!page) return 1;

        const maxPages = Math.ceil(total / sizePerPage);
        return maxPages >= page ? page : 1;
    };

    onMultiSortChange = (name, order) => {
        const sortName = [];
        const sortOrder = [];
        const sorting = this.props;

        for (let i = 0; i < sorting.sortName.length; i++) {
            if (sorting.sortName[i] !== name) {
                sortName.push(sorting.sortName[i]);
                sortOrder.push(sorting.sortOrder[i]);
            }
        }

        sortName.push(name);
        sortOrder.push(order);

        this.props.onSortChange(sortName, sortOrder, this.props.entity)
    };

    scrollTop = () => {
        document.querySelectorAll('.react-bs-container-body').forEach(item => item.scrollTop = 0);
    };

    render() {
        let color = this.props.color ? this.props.color : 'blue';
        let classShowFilters = this.state.showFilters ? '' : ' hide-filters';

        const customSortOptions = this.props['defaultSort'] && {
            onSortChange: this.props.multiColumnSort ? this.onMultiSortChange
                                                     : (name, order) => this.props.onSortChange(name, order, this.props.entity),
            defaultSortName: this.props.sortName,
            defaultSortOrder: this.props.sortOrder,
            sortName: this.props.sortName,
            sortOrder: this.props.sortOrder,
        };

        const needLoadingMessage = this.props.isLoading && this.props.data.length === 0;

        const options = {
            onRowClick: this.onRowClick,
            onCellEdit: this.props.onCellEdit,
            onDeleteRow: this.props.onDeleteRow,
            onAddRow: this.props.onAddRow,

            //onSortChange: this.handleSortChange,
            //onSearchChange: this.handleSearchChange,
            //onPageChange: this.handlePageChange,
            //onSizePerPageList: this.handleSizePerPageChange,
            onFilterChange: this.handleFilterChange,
            clearSearch: true,
            searchPanel: (props) => (<RemoteGridSearchPanel {...props}/>),

            page: this.setPageSavely(),
            sizePerPage: this.props.sizePerPage || 50,
            sizePerPageList: this.props.sizePerPageList,
            onPageChange: (page, sizePerPage) => {
                this.props.onChangePage(page, sizePerPage, this.props.entity);
                this.scrollTop();
            },
            noDataText: needLoadingMessage ? 'Loading... Please wait' : 'There is no data to display',
            ...customSortOptions
        };
        const options2 = {
            onRowClick: this.onRowClick,
            onCellEdit: this.props.onCellEdit,
            onDeleteRow: this.props.onDeleteRow,
            onAddRow: this.props.onAddRow,

            clearSearch: true,
            searchPanel: (props) => (<RemoteGridSearchPanel {...props}/>),

            page: this.setPageSavely(),
            sizePerPage: this.props.sizePerPage || 50,
            sizePerPageList: this.props.sizePerPageList,
            onPageChange: (page, sizePerPage) => {
                this.props.onChangePage(page, sizePerPage, this.props.entity);
                this.scrollTop();
            },
            noDataText: needLoadingMessage ? 'Loading... Please wait' : 'There is no data to display',
            ...customSortOptions
        };
        // const selectRow = {
        //     mode: 'radio' //radio or checkbox
        // }
        const buttonClass = {
            'orange': 'btn-warning',
            'green': 'btn-success',
            'blue': 'btn-primary'
        };
        const filterShowStyle = this.props['noFilter'] ? {} : {float: 'left', width: '94%'};
        const noBorder = this.props['noBorder'] ? ' boxNoBorder' : '';
        const memoryData = this.props['memoryData'] || false;
        let entity = '';
        let total = this.props.total;
        if (memoryData) {
            entity = this.props.entity[0] + this.props.entity.replace(/_/g, ' ').replace(/\b\w/g, function (l) {
                return l.toUpperCase()
            }).replace(/ /g, '').substr(1);
            total = typeof this.props.totalMemory[entity] !== "undefined" ? this.props.totalMemory[entity].total : this.props.total;
        }
        // console.log('RemoteGrid render:', this.props.fetchData, memoryData, this.props.data);
        return (
            <Box
                color={color}
                classes={'box-profile-first-row-height-show ' + noBorder + classShowFilters}>
                {this.props.title ?
                    <div className="box-header with-border">
                        {this.props.titleLink ?
                            <h3
                                className={"box-title text-" + color}
                                style={{cursor: 'pointer'}}
                                onClick={this.changePage}>
                                {this.props.title}
                            </h3>
                            :
                            <h3 className={"box-title text-" + color}>
                                {this.props.title}
                            </h3>
                        }
                    </div>
                    : ''
                }
                <BoxBody>
                    <div>
                        {this.props.children}
                    </div>

                    <div style={{display: 'block', position: 'relative'}}>
                        {this.props.noFilter ? '' :
                            <div style={{float: 'left', marginTop: '28px'}}>
                                <button
                                    className={`btn btn-sm resizable-button tooltip-button ` + buttonClass[color]}
                                    style={{marginRight: '10px'}}
                                    onClick={this.changeFilters}>
                                    <i className={`fa fa-filter`}/>
                                </button>
                                <button
                                    className={`btn btn-sm resizable-button tooltip-button ` + buttonClass[color]}
                                    style={{marginRight: '10px', display: 'block', marginTop: '10px'}}
                                    onClick={() => this.props.resetSorting(this.props.entity)}>
                                    <i className={`fa fa-sort`} style={{padding: '1.5px'}}/>
                                </button>
                            </div>
                        }
                        <div className={'client-edit-facilities'} style={filterShowStyle}>
                            <BootstrapTable
                                striped={typeof this.props.striped === 'undefined'}
                                hover
                                bodyStyle={this.props.bodyStyle}
                                headerContainerClass={this.props.headerContainerClass}
                                bordered={this.props.bordered}
                                data={memoryData ? this.getMemoryData(entity) : this.props.data}
                                ref={ref => (this.table = ref)}
                                remote={(this.props.fetchData || memoryData)}
                                insertRow={this.props.insertRow}
                                deleteRow={this.props.deleteRow}
                                search={this.props.search}
                                pagination={this.props.pagination}
                                fetchInfo={{dataTotalSize: total}}
                                options={this.props.fetchData || memoryData ? options : options2}
                                cellEdit={this.props.cellEdit}
                                selectRow={this.props.selectRow}
                                trClassName={this.props.trClassName || 'remotegrid-row'}
                                multiColumnSort={this.props.multiColumnSort}
                            >
                                {this.props.columns.map((item, i) => (
                                    <TableHeaderColumn
                                        {...item}
                                        key={i}
                                        sort={item.sort?'asc':undefined}
                                        dataField={item.name}
                                        dataSort={!!item.sort}
                                        width={String(item.width)}
                                        columnTitle={typeof item.columnTitle === "undefined" ? true : item.columnTitle}
                                        filter={this.filtered(item)}
                                    >
                                        {item.title}
                                    </TableHeaderColumn>
                                ))}
                            </BootstrapTable>
                        </div>
                    </div>
                </BoxBody>
            </Box>
        );
    }

    static propTypes = {
        data: PropTypes.array,
        isLoading: PropTypes.bool,
    }
}

RemoteGrid.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        totalMemory: state.app.grid,
        visibleCodes: state.app.case_list.visibleCodes,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showSearch: () => dispatch({type: 'SHOW_SEARCH_PANEL'}),
        hideSearch: () => dispatch({type: 'HIDE_SEARCH_PANEL'}),
        onSortChange: (name, order, entity) => dispatch(onChangeSortConfiguration(name, order, entity)),
        resetSorting: (entity) => dispatch(resetSortConfiguration(entity)),
        onChangePage: (page, sizePerPage, entity) => dispatch(onChangePage(page, sizePerPage, entity)),
        saveTotal: (total, entity) => dispatch(saveTotal(total, entity)),
        addVisibleCodes: (data) => dispatch(addVisibleCodes(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RemoteGrid));
