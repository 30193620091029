import React, {Component} from "react";
import {connect} from 'react-redux'
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import FormField from '../../components/FormField'
import {editUser, userEditField, isAllValid} from '../../redux/action-creators/users'

class ReviewerEditProfessionalInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstLoad: true,
        };

        this.onChange = this.onChange.bind(this);
        this.ChangeReviewerTypeCode = this.ChangeReviewerTypeCode.bind(this);
        this.onChangeSpecialties = this.onChangeSpecialties.bind(this);
        this.onChangeCompanyExcludedCodes = this.onChangeCompanyExcludedCodes.bind(this);
        this.onChangeCompanyIncludedCodes = this.onChangeCompanyIncludedCodes.bind(this);
        this.onChangeCaseTypeCodes = this.onChangeCaseTypeCodes.bind(this);
        this.onChangeTrainDate = this.onChangeTrainDate.bind(this);
        this.onChangeBackUp = this.onChangeBackUp.bind(this);
        this.onChangeLanguagesCode = this.onChangeLanguagesCode.bind(this);
    }

    onChange(e) {
        this.props.userEditField(e.target.name, e.target.value)
    }

    onChangeBackUp(e) {
        this.props.userEditField([e.target.name], e.target.checked)
    }

    ChangeReviewerTypeCode(reviewerTypeCodes) {
        this.props.userEditField("reviewerTypeCodes", reviewerTypeCodes)
    }

    onChangeSpecialties(specialtyCodes) {
        this.props.userEditField("specialtyCodes", specialtyCodes)
    }

    onChangeCompanyExcludedCodes(companyExcludedCodes) {
        this.props.userEditField("companyExcludedCodes", companyExcludedCodes)
    }

    onChangeCompanyIncludedCodes(companyIncludedCodes) {
        this.props.userEditField("companyIncludedCodes", companyIncludedCodes)
    }

    onChangeCaseTypeCodes(caseTypeCodesExcluded) {
        this.props.userEditField("caseTypeCodesExcluded", caseTypeCodesExcluded)
    }

    onChangeTrainDate(trainDate) {
        this.props.userEditField("trainDate", trainDate)
    }

    onChangeLanguagesCode(languageCodes) {
        this.props.userEditField("languageCodes", languageCodes)
    }

    render() {
        let companiesForInclude = this.props.companiesForInclude.filter(
            (item) => typeof item.value === "undefined"
                || typeof this.props.profile.companyExcludedCodes.find((i) => i.value === item.value) === "undefined"
        );
        let companiesForExclude = this.props.companiesForExclude.filter(
            (item) => typeof item.value === "undefined"
                || typeof this.props.profile.companyIncludedCodes.find((i) => i.value === item.value) === "undefined"
        );
        return (
            <Box color="blue" classes="box-profile-first-row-height-show">
                <BoxHeader title={`Reviewer - ${this.props.profile.firstName} ${this.props.profile.lastName}`}
                           color="blue" icon="fa-user-md"/>
                <BoxBody>
                    <form onSubmit={this.onSubmit}>
                        <div className="bordered form-group">
                            <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="8"
                                        type="select"
                                        label="Reviewer Type"
                                        id="Reviewer Type"
                                        className="text-bg required-field"
                                        valids="required"
                                        required
                                        validator={this.props.validator}
                                        options={this.props.reviewerTypeCodes}
                                        multi={true}
                                        name="reviewerTypeCodes"
                                        value={this.props.profile.reviewerTypeCodes}
                                        onChange={this.ChangeReviewerTypeCode}
                                        onBlur={this.props.dispatchValidStatus}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="8"
                                        type="select"
                                        className="required-field"
                                        label="Specialties"
                                        id="Specialties"
                                        required
                                        valids="required"
                                        validator={this.props.validator}
                                        multi={true}
                                        options={this.props.specialties}
                                        value={this.props.profile.specialtyCodes}
                                        onChange={this.onChangeSpecialties}
                                        onBlur={this.props.dispatchValidStatus}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className='row'>
                                <FormField
                                    md="8"
                                    type="select"
                                    label="Case Types Excluded"
                                    id="Case Types Excluded"
                                    className="text-bg"
                                    searchable
                                    multi
                                    options={this.props.caseTypes}
                                    name="caseTypeCodesExcluded"
                                    value={this.props.profile.caseTypeCodesExcluded}
                                    onChange={this.onChangeCaseTypeCodes}
                                />
                            </div>
                        </div>

                        <div className="form-group bordered">
                            <div className='row'>
                                <FormField
                                    md="8"
                                    type="select"
                                    label="Languages"
                                    id="Languages"
                                    searchable
                                    disabled={false}
                                    multi={true}
                                    options={this.props.languages}
                                    name="specialtyCodes"
                                    value={this.props.profile.languageCodes}
                                    onChange={this.onChangeLanguagesCode}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className='row'>
                                <FormField
                                    md="8"
                                    type="select"
                                    label="Clients Included"
                                    id="Clients Excluded"
                                    searchable={true}
                                    multi={true}
                                    options={companiesForInclude}
                                    value={this.props.profile.companyIncludedCodes}
                                    onChange={this.onChangeCompanyIncludedCodes}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className='row'>
                                <FormField
                                    md="8"
                                    type="select"
                                    label="Clients Excluded"
                                    id="Clients Excluded"
                                    searchable={true}
                                    multi={true}
                                    options={companiesForExclude}
                                    value={this.props.profile.companyExcludedCodes}
                                    onChange={this.onChangeCompanyExcludedCodes}
                                />
                            </div>
                        </div>

                        <div className="form-group bordered">
                            <div className='row'>
                                <FormField
                                    type='DATE'
                                    md="4"
                                    label="Train Date"
                                    className="text-bg  "
                                    value={this.props.profile.trainDate}
                                    onChange={this.onChangeTrainDate}
                                />
                                <FormField
                                    md="4"
                                    type="checkbox"
                                    style={{paddingTop: '20px'}}
                                    label="Backup"
                                    id="Backup"
                                    name='backUp'
                                    value={this.props.profile.backUp}
                                    checked={this.props.profile.backUp}
                                    onChange={this.onChangeBackUp}
                                    className="checkbox"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className='col-md-8 col-xs-10 fields'>
                                    <label className="required">Dispatch Notes</label>
                                    <textarea name="dispatchNotes" cols="30" rows="7"
                                              value={this.props.profile.dispatchNotes} onChange={this.onChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className='col-md-8 col-xs-10 fields'>
                                    <label htmlFor="notes_note" className="required">Notes</label>
                                    <textarea name="notes" id="notes_note" cols="30" rows="7"
                                              value={this.props.profile.notes} onChange={this.onChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className='col-md-8 col-xs-10 fields'>
                                    <label htmlFor="to_do_note" className="required">To Do</label>
                                    <textarea name="toDo" id="to_do_note" cols="30" rows="7"
                                              value={this.props.profile.toDo} onChange={this.onChange}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </BoxBody>
                <BoxFooter>

                </BoxFooter>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    let profile = state.app.users.editedUser;
    if (!profile.companyExcludedCodes) profile.companyExcludedCodes = [];
    if (!profile.companyIncludedCodes) profile.companyIncludedCodes = [];
    return {
        profile: profile,
        isLoading: state.app.users.loading,
        error: state.app.users.editUserError,
        specialties: state.app.specialties.data
            .filter(item => item.active)
            .map(spec => ({
                value: spec.code,
                label: spec.name
            })),

        reviewerTypeCodes: state.app.reviewerTypes.data
            .filter(item => item.active)
            .map(type => ({
                value: type.code,
                label: type.name
            })),

        companiesForInclude: state.app.companies.data
            .filter(item => item.active
                && !profile.companyExcludedCodes.includes(item.code))
            .map(type => ({
                value: type.code,
                label: type.fullName
            })),
        companiesForExclude: state.app.companies.data
            .filter(item => item.active
                && !profile.companyIncludedCodes.includes(item.code))
            .map(type => ({
                value: type.code,
                label: type.fullName
            })),

        caseTypes: state.app.case_types.types
            .filter(item => item.active)
            .map(type => ({
                value: type.code,
                label: type.name
            })),
        languages: state.app.languages.data
            .filter(item => item.active)
            .map(lang => ({
                value: lang.code,
                label: lang.name
            }))
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveProfile: data => dispatch(editUser(data, 'profile')),
        userEditField: (field, value) => dispatch(userEditField(field, value)),
        isAllValid: (valid) => dispatch(isAllValid(valid)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ReviewerEditProfessionalInfo);
