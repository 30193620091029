import api from "../../utils/api";
import { Statuses } from "./statuses";
import history from '../history'
import { isEntityLoaded } from "../../utils/helpers";
import { GLOBAL_ASYNC_CACHE_TIME } from "../../utils/Constants";
import { FETCH_UPDATE_CASE_VALUES_SUCCESS } from "../reducers/cases/case";

const isAvailableInCache = (facilities) =>
    isEntityLoaded(facilities) && facilities.data && facilities.data.length > 0;

export const getAllCompanyFacilityMinimal = (companyCode) => (dispatch) => {
    dispatch({
        type: 'FETCH_FACILITY_MINIMAL_LOADING',
    });

    api.get('facility/getByCompanyCode/' + companyCode)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_FACILITY_MINIMAL_SUCCESS',
                payload: data
            });
            dispatch({
                type: 'FILL_FACILITY_LOCAL_STORAGE'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_FACILITY_MINIMAL_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.error(error.request);
            } else {
                console.error('Error ', error.message);
                dispatch({
                    type: 'FETCH_FACILITY_MINIMAL_ERROR',
                    payload: error.message
                });
            }
        })
}

export const fillSelectedFacilityData = (facilityData) => (dispatch) => {
    dispatch({
        type: 'FILL_SELECTED_FACILITY_DATA',
        payload: facilityData,
    });
    dispatch({
        type: FETCH_UPDATE_CASE_VALUES_SUCCESS,
        payload: facilityData,
    });
};

export const getAllFacilities = (facilities) => (dispatch) => {
    if (isAvailableInCache(facilities)) {
        return;
    }
    dispatch({
        type: 'FETCH_FACILITY_LOADING',
    })
    api.get('facility/all')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_FACILITY_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'FILL_FACILITY_LOCAL_STORAGE'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_FACILITY_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_FACILITY_ERROR',
                    payload: error.message
                });
            }
        })
}

const isTypesAvailableInCache = (facilities) =>
    facilities && facilities.types && facilities.types.length > 0 && facilities.lastLoadedTypeTime &&
    (Date.now() - facilities.lastLoadedTypeTime) < GLOBAL_ASYNC_CACHE_TIME;

export const getAllFacilityTypes = (facilities) => (dispatch) => {
    if (isTypesAvailableInCache(facilities)) {
        return;
    }
    dispatch({
        type: 'FETCH_FACILITY_TYPE_LOADING',
    })
    api.get('facility/type/all')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_FACILITY_TYPE_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'FILL_FACILITY_TYPE_LOCAL_STORAGE'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_FACILITY_TYPE_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_FACILITY_TYPE_ERROR',
                    payload: error.message
                });
            }
        })
}

export const getFacility = (code) => dispatch => {
    dispatch({
        type: 'FETCH_FACILITY_LOADING',
    })
    dispatch({
        type: 'FETCH_FACILITY_PROFILE_LOADING',
    })

    return api.get(`facility/get?code=${code}`)
        .then(({ data }) => dispatch({
            type: 'FETCH_GET_FACILITY_SUCCESS',
            payload: data
        }))
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_FACILITY_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_FACILITY_ERROR',
                    payload: error.message
                });
            }
        })
}

export const createNewFacility = (facility) => (dispatch) => {
    dispatch({
        type: 'FETCH_FACILITY_LOADING',
    })

    api.post('facility/create', facility)
        .then(() => {
            dispatch({
                type: 'FETCH_CREATE_FACILITY_SUCCESS',
                payload: facility
            })
            dispatch({
                type: 'CLOSE_CREATE_MODAL'
            })
            history.push(`/facility/${facility.code}`)
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_FACILITY_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_FACILITY_ERROR',
                    payload: Statuses[ error.request.status ]
                });
            }
        })

}

export const editFacility = (data, closeAfterSave) => dispatch => {
    dispatch({
        type: 'FETCH_FACILITY_LOADING',
    })

    return api.post('facility/update', data)
        .then(({ config }) => {
            dispatch({
                type: 'FETCH_EDIT_FACILITY_SUCCESS',
                payload: JSON.parse(config.data)
            })

            closeAfterSave && history.goBack()

        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_EDIT_FACILITY_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ]
            })
        })
}

export const facilityEditField = (field, value) => ({
    type: 'FACILITY_EDIT_FIELD',
    payload: {
        [ field ]: value
    }
})

export const getFacilityType = (code) => dispatch => {
    dispatch({
        type: 'FETCH_FACILITY_TYPE_LOADING',
    })

    return api.get(`facility/type/get?code=${code}`)
        .then(({ data }) => dispatch({
            type: 'FETCH_GET_FACILITY_TYPE_SUCCESS',
            payload: data
        }))
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_FACILITY_TYPE_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_FACILITY_TYPE_ERROR',
                    payload: error.message
                });
            }
        })
}

export const createNewFacilityType = (facilityType) => (dispatch) => {
    dispatch({
        type: 'FETCH_FACILITY_TYPE_LOADING',
    })

    api.post('facility/type/create', facilityType)
        .then(() => {
            dispatch({
                type: 'FETCH_CREATE_FACILITY_TYPE_SUCCESS',
                payload: facilityType
            })
            dispatch({
                type: 'CLOSE_CREATE_MODAL'
            })
            history.push(`/facility_type/${facilityType.code}`)
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_FACILITY_TYPE_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_FACILITY_TYPE_ERROR',
                    payload: Statuses[ error.request.status ]
                });
            }
        })

}


export const editFacilityType = (data, closeAfterSave) => dispatch => {
    dispatch({
        type: 'FETCH_FACILITY_TYPE_LOADING',
    })

    return api.post('facility/type/update', data)
        .then(({ config }) => {
            dispatch({
                type: 'FETCH_EDIT_FACILITY_TYPE_SUCCESS',
                payload: JSON.parse(config.data)
            })
            closeAfterSave && history.goBack()
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_EDIT_FACILITY_TYPE_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ]
            })
        })
}

export const facilityTypeEditField = (field, value) => ({
    type: 'FACILITY_TYPE_EDIT_FIELD',
    payload: {
        [ field ]: value
    }
})

export const setCurrentTab = (tab) => {
    return {
        type: 'SET_FACILITY_CURRENT_TAB',
        payload: tab
    }
}

export const isAllValid = valid => {
    return {
        type: 'VALIDATE_FACILITY_FIELD',
        payload: valid
    }
}
