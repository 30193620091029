import React from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import FormField from "../../../components/FormField";
import {isStaff} from "../../../utils/helpers";
import {changeCaseOwner} from "../../../redux/actions/cases/caseActions";
import {closeCreateModal} from "../../../redux/action-creators/users";

class ChangeOwnerForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            owner: '',
            '--owners--state': false
        };

        this.saveOwner = this.saveOwner.bind(this);
    }

    onChangeOwner = (owner) => {
        console.log(this.props.stage);
        this.setState({owner});
    };

    saveOwner(e) {
        e.preventDefault();
        const {stage} = this.props;
        const {entity} = this.state.owner;
        this.props.changeCaseOwner(stage, entity)
    }

    render() {
        return (
            <form className='modal-content custom-modal-content' onSubmit={this.saveOwner} noValidate
                  autoComplete="off">
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type='select'
                            required
                            label={this.context.translate('common.form.owner')}
                            name='owner'
                            options={this.props.staffs}
                            value={this.state.owner}
                            onChange={this.onChangeOwner}
                        />
                    </div>
                </div>
                <div className="row no-padding">
                    <div className="col-xs-6 pull-right text-right">
                        <button
                            className='btn btn-flat btn-primary'
                            disabled={this.props.isLoading}
                        >
                            {this.context.translate('common.form.select_owner')}
                        </button>
                        &nbsp;&nbsp;
                        <button className='btn btn-flat btn-default' onClick={this.props.modalClose}
                                style={{minWidth: '100px'}}>
                            {this.context.translate('common.form.cancel')}
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}

ChangeOwnerForm.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state, ownProps) => {
    return {
        user: state.app.auth.user,
        staffs: state.app.users.users
            .filter((item) => {
                const needClient = ownProps.stage === 'ownerDraft';
                return (isStaff(item.roleCode) || item.roleCode === 'ROLE_ADMIN') ||
                    (needClient && item.companyCode === state.app.cases.editedCase.company)
            })
            .map(item => ({
                value: item.code,
                label: item.firstName + ' ' + item.lastName,
                entity: item
            })),
        owner: state.app.users.users.find((item) => item.code === state.app.cases.editedCase.executor),
        case: state.app.cases.editedCase,
    }
};

const mapActions = dispatch => {
    return {
        changeCaseOwner: (stage, owner) => dispatch(changeCaseOwner(stage, owner)),
        modalClose: () => dispatch(closeCreateModal()),
    }
};

ChangeOwnerForm = withRouter(ChangeOwnerForm);
export default connect(mapState, mapActions)(ChangeOwnerForm);
