import React from 'react';
import shortId from 'shortid';
import {connect} from 'react-redux';

import FormField from '../../components/FormField';
import FormValidateWrapper from '../../containers/FormValidateWrapper';
import {createEntity} from '../../redux/actions/BasicEntityActions';
import {idFromString} from '../../utils/helpers';

class BillingGroupCreateForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: true,
            billingTiers: [],
            code: idFromString(shortId.generate()),
            companies: [],
            name: '',
            tierCount: 0,
            currency: ''
        };
    }

    componentDidMount() {
        this.props.dispatchValidState();
    }

    onChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        this.setState({[event.target.name]: value});
        setTimeout(() => {
            this.props.dispatchValidState();
        }, 600);
    };

    onSubmit = (event) => {
        event.preventDefault();

        const {createBillingGroup, showMessages, validator} = this.props;

        if (validator.allValid()) {
            createBillingGroup(this.state);
        } else {
            showMessages();
        }
    };

    render() {
        const {dispatchValidState, isLoading, validator} = this.props;

        const {active, name} = this.state;

        return (
            <form
                className={'modal-content custom-modal-content'}
                noValidate={true}
                onSubmit={this.onSubmit}
            >
                <div className={'form-group'}>
                    <div className={'row'}>
                        <FormField
                            className={'form-control'}
                            id={'Name'}
                            label={'Name'}
                            md={'12'}
                            name={'name'}
                            onBlur={dispatchValidState}
                            onChange={this.onChange}
                            required={true}
                            validator={validator}
                            valids={'required|unique'}
                            value={name}
                        />
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className={'row'}>
                        <FormField
                            checked={active}
                            className={'checkbox'}
                            label={'Active'}
                            md={'12'}
                            name={'active'}
                            onChange={this.onChange}
                            title={'Status'}
                            type={'checkbox'}
                            value={active}
                        />
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className={'row no-padding'}>
                        <div className={'col-xs-4 pull-right text-right'}>
                            <button className={'btn btn-flat btn-primary'} disabled={isLoading}>
                                Add Billing Group
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    let BGNames = state.app.billing_group.data
        .map((item) => item.name);
    return {
        error: state.app.templates.error,
        loading: state.app.templates.loading,
        top: 0,
        customRules: {
            unique: {
                message: 'This Billing Group already exists in the system.',
                rule: function(val) {
                    return !BGNames.includes(val);
                }
            }
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createBillingGroup: (data) => dispatch(createEntity('billing_group', data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FormValidateWrapper(BillingGroupCreateForm));
