import React from 'react';
import { connect } from 'react-redux'
import Box from '../../../components/Box'
import BoxHeader from '../../../components/BoxHeader'
import BoxBody from '../../../components/BoxBody'
import BoxFooter from '../../../components/BoxFooter'
import TierRender from './TierRender'
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";

class AssignReviewers extends React.Component {

    state = { closed: false };

    static propTypes = {
        name: PropTypes.string,
        caseId: PropTypes.string,
        showEdit: PropTypes.bool,
    }

    toggleSection = (e) => {
        if (e.target.tagName === 'A') return;
        this.setState({ closed: !this.state.closed })
    };

    render() {
        const display = this.state.closed ? 'none' : 'block';

        return (
            <Box
                color="blue"
                classes="box-profile-first-row-height-show">
                <BoxHeader
                    title={this.props.name}
                    color="blue"
                    onClick={this.toggleSection}
                    icon="fa-user-md">
              <span>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link style={{
                    display: 'flat',
                    visibility: this.props.showEdit ? 'visible' : 'hidden',
                    marginLeft: '15px'
                }}
                      className="btn btn-default margin-left-5"
                      to={`/assign/${this.props.caseId}`}
                >
                  Edit
                </Link>
              <i className={`btn-box-tool fa toggle-fa-icon-reviewer-info ${this.state.closed ? 'fa-plus' : 'fa-minus'}`}
                 style={{ right: '20px' }}> </i>
            </span>
                </BoxHeader>
                <BoxBody style={{ display }}>
                    <div style={{ background: '#fff' }}>
                        <TierRender tier='TIER_1' title='Tier I' />
                        <TierRender tier='TIER_2' title='Tier II' />
                        <TierRender tier='TIER_3' title='Tier III' />
                    </div>
                </BoxBody>
                <BoxFooter>

                </BoxFooter>
            </Box>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log('mapStateToProps', state.app.case_assign);
    return {
        caseId: state.app.cases.editedCase.code,
    };
}

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AssignReviewers)
