import api from "../../utils/api";
import { Statuses } from "./statuses";
import history from '../history'
import { isEntityLoaded } from "../../utils/helpers";

const isAvailableInCache = (specialties) =>
    isEntityLoaded(specialties) && specialties.data && specialties.data.length > 0;

export const getAllSpecialties = (specialties) => (dispatch) => {
    if (isAvailableInCache(specialties)) {
        return;
    }
    dispatch({
        type: 'FETCH_SPECIALTY_LOADING',
    })
    api.get('speciality/list')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_SPECIALTY_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'FILL_SPECIALTY_LOCAL_STORAGE'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_SPECIALTY_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_SPECIALTY_ERROR',
                    payload: error.message
                });
            }
        })

}

export const getSpecialty = (code) => dispatch => {
    dispatch({
        type: 'FETCH_SPECIALTY_LOADING',
    })

    return api.get(`speciality/get?code=${code}`)
        .then(({ data }) => dispatch({
            type: 'FETCH_GET_SPECIALTY_SUCCESS',
            payload: data
        }))
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_SPECIALTY_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_SPECIALTY_ERROR',
                    payload: error.message
                });
            }
        })
}

export const createNewSpecialty = (specialty) => (dispatch) => {
    dispatch({
        type: 'FETCH_SPECIALTY_LOADING',
    })

    api.post('speciality/create', specialty)
        .then((res) => {
            dispatch({
                type: 'FETCH_CREATE_SPECIALTY_SUCCESS',
                payload: specialty
            })
            dispatch({
                type: 'CLOSE_CREATE_MODAL'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_SPECIALTY_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_SPECIALTY_ERROR',
                    payload: Statuses[ error.request.status ]
                });
            }
        })

}


export const editSpecialty = (data, closeAfterSave) => dispatch => {
    dispatch({
        type: 'FETCH_SPECIALTY_LOADING',
    })

    return api.post('speciality/update', data)
        .then(({ config }) => {
            dispatch({
                type: 'FETCH_EDIT_SPECIALTY_SUCCESS',
                payload: JSON.parse(config.data)
            })

            closeAfterSave && history.goBack()
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_EDIT_SPECIALTY_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ]
            })
        })
}

export const specialtyEditField = (field, value) => ({
    type: 'SPECIALTY_EDIT_FIELD',
    payload: {
        [ field ]: value
    }
})

export const isAllValid = valid => {
    return {
        type: 'VALIDATE_SPECIALTY_FIELD',
        payload: valid
    }
}
