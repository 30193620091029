import api from "../../utils/api";
import { Statuses } from "./statuses";
import history from '../history'

export const createNewMCSystem = (mcSystem) => (dispatch) => {
    dispatch({
        type: 'FETCH_MC_SYSTEM_LOADING',
    })

    api.post('mc_system/create', mcSystem)
        .then((res) => {
            dispatch({
                type: 'FETCH_CREATE_MC_SYSTEM_SUCCESS',
                payload: mcSystem
            })
            dispatch({
                type: 'CLOSE_CREATE_MODAL'
            })
            history.push(`coding_system/${mcSystem.code}`)
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_MC_SYSTEM_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_MC_SYSTEM_ERROR',
                    payload: Statuses[ error.request.status ]
                });
            }
        })
}

export const editMCSystem = (data, closeAfterSave) => dispatch => {
    dispatch({
        type: 'FETCH_MC_SYSTEM_LOADING',
    })

    return api.post('mc_system/update', data)
        .then(({ config }) => {
            dispatch({
                type: 'FETCH_EDIT_MC_SYSTEM_SUCCESS',
                payload: JSON.parse(config.data)
            })
            closeAfterSave && history.goBack()
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_EDIT_MC_SYSTEM_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ]
            })
        })
}

export const getMCSystem = (code) => dispatch => {
    dispatch({
        type: 'FETCH_MC_SYSTEM_LOADING',
    })

    return api.get(`mc_system/get?code=${code}`)
        .then(({ data }) => dispatch({
            type: 'FETCH_GET_MC_SYSTEM_SUCCESS',
            payload: data
        }))
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_MC_SYSTEM_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_MC_SYSTEM_ERROR',
                    payload: error.message
                });
            }
        })
}

export const editField = (field, value) => ({
    type: 'MC_SYSTEM_EDIT_FIELD',
    payload: {
        [ field ]: value
    }
})

export const isAllValid = valid => {
    return {
        type: 'VALIDATE_MC_SYSTEM_FIELD',
        payload: valid
    }
}

export const getProcedureNotes = (code) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_PROCEDURE_LOADING',
    })

    const companyCode = getState().app.companies.editedCompany.code;

    return api.get(`company/ms_notes?companyCode=${companyCode}&medicalServiceCode=${code}`)
        .then(({ data }) => {
            console.log('ms_notes data', data);
            dispatch({
                type: 'FETCH_GET_PROCEDURE_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PROCEDURE_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_PROCEDURE_ERROR',
                    payload: error.message
                });
            }
        })
}

export const getDiagnoseNotes = (code) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_DIAGNOSIS_LOADING',
    })

    const companyCode = getState().app.companies.editedCompany.code;

    return api.get(`company/md_notes?companyCode=${companyCode}&medicalDiagnosisCode=${code}`)
        .then(({ data }) => dispatch({
            type: 'FETCH_GET_DIAGNOSE_SUCCESS',
            payload: data
        }))
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_DIAGNOSIS_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_DIAGNOSIS_ERROR',
                    payload: error.message
                });
            }
        })
}
