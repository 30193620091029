export const fieldClasses = [
    {
        value: 'XTallMemoField',
        label: 'X Tall Memo Field'
    }, {
        value: 'XXTallMemoField',
        label: 'XX Tall Memo Field'
    }, {
        value: 'XWideField',
        label: 'X Wide Field'
    }, {
        value: 'XXWideField',
        label: 'XX Wide Field'
    }, {
        value: 'SWideField',
        label: 'S Wide Field'
    }, {
        value: 'NewRow',
        label: 'New Row'
    }, {
        value: 'EndOfRow',
        label: 'End of Row'
    }
];

export const fieldsTypes = [
    'STRING',
    'TEXT',
    'INTEGER',
    'NUMBER',
    'DATE',
    'TIME',
    'DATE_TIME',
    'YEAR',
    'BOOLEAN',
    'PHONE_NUMBER',
    'REF_SPECIALTY',
    'REF_CURRENCY',
    'REF_CASE_TYPE',
    'REF_CASE_LEVEL',
    'REF_CASE_DETERMINATION',
    'REF_BILLING_TIER',
    'REF_GENDER',
    'REF_COMPANY',
    'REF_SUBMITTER',
    'REF_PATIENT',
    'REF_REVIEWER',
    'REF_SERVICE_TYPE',
    'REF_MEDICAL_SERVICE',
    'REF_MEDICAL_DIAGNOSIS',
    'REF_MEDICAL_CODE',
    'REF_MEDICAL_CODING_SYSTEM',
    'REF_BODY_PART',
    'REF_SIDE',
    'REF_FACILITY_TYPE',
    'REF_FACILITY',
    'REF_PHYSICIAN',
    'REF_LANGUAGE',
    'SCORING',
    'LABEL'
];

export const editableByRoles = [
    {
        label: 'Nobody',
        value: 'NOBODY'
    }, {
        label: 'Admin only',
        value: 'ADMIN_ONLY'
    }, {
        label: 'Supervisor and Admin',
        value: 'SUPERVISOR_AND_ADMIN'
    }, {
        label: 'All Submitter',
        value: 'ALL_SUBMITTERS'
    }
];
