import {isObjectsEqual} from '../../../utils/helpers'

const baseBillTier = {
    code: '',
    name: '',
    billingGroup: '',
    callsMax: '',
    callsMin: '',
    caseLevel: '',
    caseTypeEquality: 'CONTAINS',
    caseTypes: [],
    companies: [],
    reviewerTypes: [],
    chargeMin: '',
    flatRate: '',
    hourlyRate: '',
    adjust: '',
    pagesMax: '',
    pagesMin: '',
    questionsMax: '',
    questionsMin: '',
    rateType: 'FLAT',
    refTier: '',
    reviewerTypeEquality: 'CONTAINS',
    tatMax: '',
    tatMin: '',
    tierNumber: '',
    timeMin: '',
    active: true
};

const defaultState = {
    data: [],
    editedBillingTier: baseBillTier,
    originalBillingTier: baseBillTier,
    error: '',
    editSuccess: false,
    loading: false,
    loadedCount: 0,
    isValid: false,
    isProfileChanged: false,
    showValidMessages: false,
};

const billing_group = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_BILLING_TIER_LOADING':
            return {...state, loading: true, editSuccess: false, isProfileChanged: false, showValidMessages: false};

        case 'SET_CURRENT_TIER':
            return Object.assign({}, state, {
                loading: false,
                editedBillingTier: {...baseBillTier, ...action.payload},
                originalBillingTier: {...baseBillTier, ...action.payload},
                isProfileChanged: false,
                showValidMessages: false,
                isValid: true,
                error: null,
            });

        case 'TIER_DISCARD_CHANGES':
            return Object.assign({}, state, {
                editedBillingTier: state.originalBillingTier,
                isProfileChanged: false,
                showValidMessages: false,
                isValid: true,
            });

        case 'FETCH_BILLING_TIER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null
            });

        case 'FETCH_UPDATE_BILLING_TIER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editSuccess: true,
                originalBillingTier: state.editedBillingTier
            });

        case 'FETCH_CREATE_BILLING_TIER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                data: [...state.data, action.payload]
            });

        case 'FETCH_BILLING_TIER_PROFILE_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                mcSystemValid: true,
                isValid: true,
                editedBillingTier: action.payload,
                originalBillingTier: action.payload,
            };

        case 'FETCH_EDIT_BILLING_TIER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editSuccess: true,
                originalBillingTier: action.payload,
                data: state.data.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            });

        case 'SET_BILLING_TIER_CURRENT_TAB':
            return Object.assign({}, state, {
                currentTab: action.payload
            });

        case 'BILLING_TIER_EDIT_FIELD':
            return Object.assign({}, state, {
                editedBillingTier: Object.assign({}, state.editedBillingTier, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedBillingTier, action.payload), state.originalBillingTier)
            });
        case 'VALIDATE_BILLING_TIER_FIELD':
            return Object.assign({}, state, {
                isValid: action.payload
            });
        case 'FETCH_BILLING_TIER_ERROR':
            return {...state, error: action.payload, loading: false};

        case 'SHOW_BILLING_TIER_VALIDATION':
            return Object.assign({}, state, {
                showValidMessages: true
            });

        case 'FETCH_BILLING_TIER_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            };

        case 'FETCH_BILLING_TIER_LOADED_COUNT':
            return {...state, loadedCount: action.payload};

        default:
            return state
    }
};

export default billing_group
