import React from 'react';
import { accepted } from 'redux/pages/patient-case/patientCaseColumns';
import acceptedSlice from 'redux/pages/patient-case/slices/acceptedSlice';
import PatientCaseGrid from '../Components/PatientCaseGrid';

const columns = accepted;

const Accepted = (props) => (
  <PatientCaseGrid columns={columns} slice={acceptedSlice} {...props} />
);

export default Accepted;
