import api from '../../../utils/api';
import { Statuses } from '../statuses';
import { isEntityLoaded } from "../../../utils/helpers";

const isAvailableInCache = (caseFileTemplates) =>
    isEntityLoaded(caseFileTemplates) && caseFileTemplates.data && caseFileTemplates.data.length > 0;

export const getAllCaseFileTemplates = (caseFileTemplates) => (dispatch) => {
    if (isAvailableInCache(caseFileTemplates)) {
        return;
    }
    dispatch({
        type: 'FETCH_CASE_FILE_TEMPLATES_LOADING',
    });
    api.get('case_file_template/all')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_CASE_FILE_TEMPLATES_SUCCESS',
                payload: data,
            });
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_CASE_FILE_TEMPLATES_ERROR',
                    payload: Statuses[error.response.status],
                });
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_CASE_FILE_TEMPLATES_ERROR',
                    payload: Statuses[0],
                });
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_CASE_FILE_TEMPLATES_ERROR',
                    payload: error.message,
                });
            }
        });
};
