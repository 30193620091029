let tmpId = 1
export default {
	load: () => {
		return dispatch => {
			dispatch({
				type: 'LOAD_USERS_START',
			})
			let randErr = Math.floor(Math.random() * 10)
			if (randErr !== 0) {
				setTimeout(() => {
					dispatch({
						type: 'LOAD_USERS_SUCCESS',
						users: [
							// {id: "6", name: "User 1", mail: "u1@gm.com", role: "admin", saving: true},
							// {id: "7", name: "User 2", mail: "u2@gm.com", role: "staff", deleting: true},
							{id: '8', name: 'Polko Tester', mail: 'uto@gm.com', role: 'staff'},
							{id: '9', name: 'Zeta Adste', mail: 'abc@gm.com', role: 'admin'},
							{id: '10', name: 'Ago Daster', mail: 'dadab@gm.com', role: 'staff'},
							{id: '3', name: 'Ergo Exter', mail: 'fud@gm.com', role: 'admin'},
							{id: '1', name: 'Cember Factsi', mail: 'xaazaz@gm.com', role: 'staff'},
							{id: '15', name: 'Octi Nisto', mail: 'rdsssds@gm.com', role: 'admin'},
							{id: '108', name: 'Pefo Namo', mail: 'sasssf@gm.com', role: 'staff'},
							{id: '4', name: 'Alto Mempo', mail: 'turetg@gm.com', role: 'admin'},
						],
					})
				}, 200)
			} else {
				setTimeout(() => {
					dispatch({
						type: 'LOAD_USERS_ERROR',
						error: 'Connection timeout',
					})
				}, 1000)
			}
		}
	},
	create: (user) => {
		let id = tmpId++
		return dispatch => {
			dispatch({
				type: 'CREATE_USER_START',
				user: {
					...user,
					id: 'l-' + id,
					saving: true,
				}
			})
			setTimeout(() => {
				dispatch({
					type: 'CREATE_USER_SUCCESS',
					tmpId: 'l-' + id,
					user: {
						...user,
						id: 's-' + id,
					}
				})
			}, Math.floor(Math.random() * 3000))
		}
	},
	delete: (id) => {
		return dispatch => {
			dispatch({
				type: 'DELETE_USER_START',
				id,
			})
			setTimeout(() => {
				dispatch({
					type: 'DELETE_USER_SUCCESS',
					id,
				})
			}, 200)
		}
	}
}
