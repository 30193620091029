import React from 'react';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux';
import Modal from 'react-modal';
import LoadingIndicator from '../../components/LoadingIndicator';
import RemoteGrid from "../../components/Grid/RemoteGrid";
import { API_HOST } from '../../config';
import PropTypes from "prop-types";

class ControlTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showActivateModal: false,
            showEditModal: false,
            clientId: ''
        };

        this.handleOpenActivate = this.handleOpenActivate.bind(this);
        this.handleOpenEdit = this.handleOpenEdit.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
        this.logoFormatter = this.logoFormatter.bind(this);
    }

    componentDidMount() {
        Modal['setAppElement']('body');
    }

    handleOpenActivate(clientId) {
        if (clientId.code) clientId = clientId.code;
        this.setState({clientId})
    }

    handleOpenEdit(clientId) {
        this.props.history.push(`/clients/profile/${clientId}`)
    }

    enumFormatter = (cell, row, enumObject) => {
        return enumObject[cell];
    };

    logoFormatter = (cell) => {
        if (cell !== '')
            return (<img style={{width: 100}} src={cell} alt=""/>);
        else return '';
    };

    render() {
        const {companies} = this.props;

        let currencies = {};
        for (let i = 0; i < this.props.currencies.length; i++) {
            currencies[this.props.currencies[i]] = this.props.currencies[i];
        }

        let companiesData = [];
        for (let i = 0; i < companies.length; i++) {
            let company = companies[i];
            company.address = '';
            if (company.city) company.address = company.address + company.city;
            if (company.street1) company.address = company.address + ' ' + company.street1;
            if (company.logo != null) {
                company.logoFile = API_HOST + '/company/logo?code=' + company.code;
            } else {
                company.logoFile = '';
            }
            company.ipWhiteList = (Array.isArray(company.ipWhiteList)) ? company.ipWhiteList.join(', ') : company.ipWhiteList;
            companiesData.push(company);
        }

        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true
            }, {
                'name': 'fullName',
                'title': 'Full Name',
                'sort': true,
                'width': '200',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'shortName',
                'title': 'Short Name',
                'sort': true,
                'width': '100',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'address',
                'title': 'Address',
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'vatId',
                'title': 'VAT ID',
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'currencyCode',
                'title': 'Currency',
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'SelectFilter', options: currencies}
            }, {
                'name': 'ipWhiteList',
                'title': 'IP White List',
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            },
            // {
            //     'name': 'logoFile',
            //     'title': 'Logo',
            //     'dataFormat': this.logoFormatter,
            //     'width': '150'
            // },
            {
                'name': 'active',
                'title': 'Status',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': 'Inactive',
                    'true': 'Active'
                },
                'width': '100',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': 'Inactive',
                        'true': 'Active'
                    }
                }
            }
        ];
        const {isLoading} = this.props;
        const {pagination, sorting} = this.props.gridConfiguration;
        return (
            <div className="table-responsive" style={{marginTop: '15px', "padding": '10px'}}>
                {isLoading && <LoadingIndicator type="lister"/>}
                <RemoteGrid
                    color="green"
                    title="Clients"
                    entity="client"
                    data={companiesData}
                    total={companiesData.length}
                    columns={columns}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.handleOpenEdit}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                    isLoading={isLoading}
                />
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        gridConfiguration: PropTypes.object,
    }
    static defaultProps = {
        isLoading: false
    };
}


const mapStateToProps = (state) => ({
    companies: state.app.companies.data,
    currencies: state.app.currencies.data
        .map(currency => (
            [currency.code]
        )),
    isLoading: state.app.companies.loading,
    gridConfiguration: state.app.grid.client,
});

const mapDispatchToProps = () => ({});

// ControlTable = withRouter(ControlTable);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ControlTable))
