import React from 'react';
import { useSelector } from 'react-redux';
import CaseCommentsThread from './CaseCommentsThread';

const CaseCommentsThreadList = () => {
  const { list } = useSelector((state) => {
    return state.app.caseCommentThreads;
  });

  return (
    <div className="mg-top-20">
      {list.map((thread) => (
        <CaseCommentsThread key={thread.code} {...thread}/>
      ))}
    </div>
  );
};

CaseCommentsThreadList.propTypes = {};

CaseCommentsThreadList.defaultProps = {};

export default CaseCommentsThreadList;
