import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'
import PropTypes from "prop-types";
import { createNewDiagnose, editDiagnose } from '../../../redux/actions/diagnosesActions'
import { getMultiSelectValue } from '../../../utils/helpers'
import { showValidation } from '../../../redux/actions/BasicEntityActions'

class SaveDiagnose extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        e.preventDefault();
        if (this.props.isValid) {
            let { systemToCode, names, name } = this.props.item;
            const medicalCodeCodes = Object.keys(systemToCode).reduce((p, c) => {
                return [...p, ...systemToCode[ c ]]
            }, []);

            if (names) {
                names[ 'EN' ] = names[ 'EN' ] ? names[ 'EN' ] : name;
                name = names[ 'EN' ] ? names[ 'EN' ] : name
            } else names = { 'EN': name };

            this.props.saveData({
                ...this.props.item,
                names: names,
                name: name,
                active: this.props.item.active,
                code: this.props.item.code,
                medicalCodingSystemCodes: medicalCodeCodes,
                specialtyCodes: getMultiSelectValue(this.props.item.specialtyCodes),
                bodyPartCodes: getMultiSelectValue(this.props.item.bodyPartCodes),
            }, closeAfterSave)
        } else {
            this.props.showValidation()
        }
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={this.onSave}
                />
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={(e) => this.onSave(e, true)}
                    title={'Save And Close'}
                />
            </React.Fragment>
        )
    }
}

SaveDiagnose.contextTypes = {
    translate: PropTypes.func
};

const allowToEdit = (role) => (role === 'ROLE_ADMIN' || role === 'ROLE_CLIENT_ADMIN');

const mapStateToProps = (state) => ({
    item: state.app.diagnoses.editedDiagnose,
    isProfileChanged: state.app.diagnoses.isProfileChanged,
    isValid: state.app.diagnoses.diagnoseValid,
    isEditable: allowToEdit(state.app.auth.user.roleCode) || state.app.users.my_permissions.includes('MANAGE_SUR'),
});

const mapDispatchToProps = dispatch => ({
    saveData: (data, closeAfterSave) => dispatch(editDiagnose(data, closeAfterSave)),
    newData: data => dispatch(createNewDiagnose(data)),
    showValidation: () => dispatch(showValidation('diagnose'))
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveDiagnose)
