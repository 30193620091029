import React from 'react';
import PropTypes from "prop-types";
import SubmitButton from '../../../../components/SubmitButton'
import history from '../../../../redux/history'

class CreateFieldButton extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave = (e) => {
        e.preventDefault();
        history.goBack();
    };

    render() {
        return <SubmitButton color='gray' title='Cancel' onSubmit={this.onSave}/>
    }
}

CreateFieldButton.contextTypes = {
    translate: PropTypes.func
};

export default CreateFieldButton
