import React, { useRef } from 'react';
// import ButtonLoader from "../Develop/ButtonLoader";
import { useDispatch } from "react-redux";
import { exportTemplate } from "../../redux/actions/templateActions";
// import {connect} from 'react-redux';
// import SubmitButton from '../../components/SubmitButton'
// import PropTypes from "prop-types";
// import {createTemplate, dispatchTemplateValidStatus, updateTemplate} from '../../redux/actions/templateActions'


const ExportTemplateButton = ({ templateCode } ) => {

    const buttonRef = useRef();
    const dispatch = useDispatch();
    // const templateCode = 'Sweden';
    // console.warn('&&&&', templateCode);

    const expTemplate = (e, ref) => {
        e.preventDefault();
        console.log('ref', e, ref);
        // console.warn('t', props);
        dispatch(exportTemplate(templateCode))
    }

    return (
        <button
            ref={buttonRef}
            className={`btn resizable-button tooltip-button btn-primary margin-left-5`}
            onClick={(e) => expTemplate(e) }>
            {'Export Template'}
        </button>
        // <div
        //     style={{ marginTop: 3, marginLeft: 5 }}
        // >
        //     <ButtonLoader
        //         ref={buttonRef}
        //         key={'ExportTemplate'}
        //         text={'TODO'}
        //         className={'btn btn-primary'}
        //         onClick={(e) => {
        //             exportTemplate(e, buttonRef);
        //         }}
        //     />
        // </div>
    );
};

export default ExportTemplateButton;



// class UpdateTemplateButton extends React.Component {
//     constructor(props) {
//         super(props);
//         this.onSave = this.onSave.bind(this)
//     }
//
//     onSave(e, closeAfterSave) {
//         if (this.props.isTemplateValid) {
//             const {template} = this.props;
//             const fieldInstanceCodes = template.fieldInstancesDto.map((item) => item.code);
//             const names = {'EN': template.name};
//
//             this.props.updateTemplate({...template, names, fieldInstanceCodes}, closeAfterSave)
//         } else this.props.dispatchTemplateValidStatus(false)
//     }
//
//     render() {
//         return (
//             <>
//                 <SubmitButton color='blue' disabled={!this.props.isTemplateChanged} onSubmit={this.onSave}
//                               showTooltip={!this.props.isTemplateValid}/>
//                 <SubmitButton color='blue' disabled={!this.props.isTemplateChanged}
//                               onSubmit={(e) => this.onSave(e, true)} title='Save And Close'
//                               showTooltip={!this.props.isTemplateValid}/>
//             </>
//         )
//     }
// }
//
// UpdateTemplateButton.contextTypes = {
//     translate: PropTypes.func
// };
//
// const mapStateToProps = (state) => ({
//     isTemplateValid: state.app.templates.isTemplateValid,
//     isTemplateChanged: state.app.templates.isTemplateChanged,
//     template: state.app.templates,
//     isTemplateExist: state.app.templates.allTemplates.find((item) => item.code === state.app.templates.code)
// });
//
// const mapDispatchToProps = dispatch => ({
//     createTemplate: data => dispatch(createTemplate(data)),
//     updateTemplate: (data, closeAfterSave) => dispatch(updateTemplate(data, closeAfterSave)),
//     dispatchTemplateValidStatus: data => dispatch(dispatchTemplateValidStatus(data)),
// });
//
// export default connect(mapStateToProps, mapDispatchToProps)(UpdateTemplateButton)
