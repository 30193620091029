import React from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import FormField from "../../components/FormField/index";
import shortId from "shortid";
import { createNewCase } from '../../redux/actions/cases/caseListActions'
import { withRouter } from "react-router-dom";
import FormValidateWrapper from '../../containers/FormValidateWrapper';
import { closeCreateModal, openCreateModal } from "../../redux/action-creators/users";
import { setCaseIsCreated } from "../../redux/actions/cases/caseActions";
import { fetchCaseCount } from 'redux/action-creators/init';
import LoadingIndicator from "../../components/LoadingIndicator";

class CreateCaseForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company: '',
            isLoading: false
        };
    }

    componentDidMount() {
        this.props.dispatchValidState();
    }

    onChangeCompany = (company) => {
        this.setState({
            company: company
        });
    };

    saveCase = () => {
        let caseCode = shortId.generate();
        this.props.createNewCase({
            code: caseCode,
            company: this.state.company.value,
            owner: this.props.currentUser.code,
            templateCode: this.state.company.templateCode,
        }).then(
            () => {
                this.props.setCaseIsCreated(true);
                this.props.modalClose();
                this.props.history.push(`/case/` + caseCode)
                this.props.fetchCaseCount();
            },
            () => {
                this.props.modalClose();
                this.props.history.push(`/home`)
            }
        );
    };

    createCase = (e) => {
        this.setState({ isLoading: true });
        e.preventDefault();
        if (this.props.validator.allValid()) {
            this.saveCase();
        } else {
            this.props.showMessages()
        }
    };

    render() {
        return (
            <form className='modal-content custom-modal-content' onSubmit={this.createCase} noValidate
                  autoComplete="off">
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type='REF_COMPANY'
                            label={this.context.translate('common.form.company')}
                            id={this.context.translate('common.form.company')}
                            name='companyCode'
                            required
                            valids="required"
                            validator={this.props.validator}
                            value={this.state.company}
                            onChange={this.onChangeCompany}
                            onBlur={this.props.dispatchValidState}
                            disabled={this.state.isLoading}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right text-right">
                            <button
                                className='btn btn-flat btn-primary'
                                disabled={this.state.isLoading}
                            >
                                {this.context.translate('common.form.add_case')}
                            </button>
                        </div>
                    </div>
                </div>

                {this.state.isLoading && (
                    <LoadingIndicator height={50} />
                )}
            </form>
        )
    }
}

CreateCaseForm.propTypes = {
    dispatchValidState: PropTypes.func,
    createNewCase: PropTypes.func,
    isLoading: PropTypes.bool,
    currentUser: PropTypes.object,
    setCaseIsCreated: PropTypes.func,
    modalClose: PropTypes.func,
    history: PropTypes.object,
    validator: PropTypes.object,
    showMessages: PropTypes.func
};


CreateCaseForm.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.app.auth.user,
        lastVersion: state.app.template,
        isOpen: state.app.users.createModalOpen,
        companies: state.app.companies.data,
        top: '0em'
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createNewCase: (data) => dispatch(createNewCase(data)),
        modalOpen: () => dispatch(openCreateModal()),
        modalClose: () => dispatch(closeCreateModal()),
        setCaseIsCreated: (data) => dispatch(setCaseIsCreated(data)),
        fetchCaseCount: () => dispatch(fetchCaseCount()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(withRouter(CreateCaseForm)));
