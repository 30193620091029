import React, { useCallback, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import FormContext from './FormContext';

const Form = React.forwardRef(
  (
    {
      formKey,
      onSubmit,
      children,
      className,
      inlineErrors,
      submitForm,
      ...contextProps
    },
    ref,
  ) => {
    const handleSubmit = useCallback(
      (event, params) => {
        if (event) {
          event.preventDefault();
        }
        const validationResult = submitForm();
        if (validationResult) {
          onSubmit(event, { validationResult, ...params });
        }

        return validationResult;
      },
      [onSubmit, submitForm],
    );

    useImperativeHandle(
      ref,
      () => ({
        submit: (params) => handleSubmit(null, params),
      }),
      [handleSubmit],
    );

    return (
      <FormContext.Provider value={{ formKey, inlineErrors, ...contextProps }}>
        <form ref={ref} className={className} onSubmit={handleSubmit} noValidate={true}>
          {children}
        </form>
      </FormContext.Provider>
    );
  },
);

Form.propTypes = {
  formKey: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  preventRenderForm: PropTypes.bool,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  inlineErrors: PropTypes.bool,
  submitForm: PropTypes.func.isRequired,
};

Form.defaultProps = {
  onSubmit: (event) => {
    event.preventDefault();
  },
  preventRenderForm: false,
  inlineErrors: false,
  className: '',
};

export default Form;
