/* eslint-disable react/display-name */
import React from 'react';
import debounce from 'lodash/debounce';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

const DataTableFilterInput = React.forwardRef(
  (
    { onChange, onClear, label, defaultValue, disabled, clearVisibility },
    ref,
  ) => {
    return (
      <div style={{ position: 'relative' }}>
        <input
          ref={ref}
          className="filter text-filter form-control"
          type="text"
          title={`Enter ${label}`}
          placeholder={`Enter ${label}`}
          onChange={debounce(onChange, 500)}
          defaultValue={defaultValue}
          disabled={disabled}
          autoComplete="off"
          name={`filter-input-${uniqueId()}`}
        />

        <div
          className={classnames(['filter-clear', { hidden: !clearVisibility }])}
          onClick={onClear}
        >
          <i className="fa fa-remove" />
        </div>
      </div>
    );
  },
);

DataTableFilterInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  clearVisibility: PropTypes.bool.isRequired,
};

export default DataTableFilterInput;
