import React from 'react';
import SimpleReactValidator from 'utils/simple-react-validator';
import {Tab, Tabs} from 'react-bootstrap';
import {connect} from 'react-redux';

import FormField from '../../components/FormField';
import LoadingIndicator from '../../components/LoadingIndicator';
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';
import StaffGeneralInfo from './StaffGeneralInfo';
import SystemMessage from '../../components/SystemMessage';
import UserSettings from '../UserSettings';
import {editUser, getAllRoles, getUserProfile, isAllValid, setCurrentTab, userEditField} from '../../redux/action-creators/users';
import {getAllLocales} from '../../redux/actions/localeActions';
import {getMultiSelectValue, getSelectFieldValue, isStaff, testIp} from '../../utils/helpers';
import PropTypes from "prop-types";

const tabToField = {
    'First Name': 'general',
    'IP White List': 'settings',
    'Last Name': 'general',
    'Notification Email': 'general',
    'Phone Number': 'general',
    Role: 'settings',
};

class StaffEditPage extends React.Component {
    constructor() {
        super();

        this.validator = new SimpleReactValidator({
            custom_email: {
                message: 'The Notification email must be a valid email address',
                rule: (value) => {
                    return (
                        value === '' ||
                        // eslint-disable-next-line no-control-regex,max-len
                        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gim.test(
                            value
                        )
                    );
                },
            },
            ip_list: {
                message: 'Invalid ip address.',
                rule: (value) => {
                    const ipList = getMultiSelectValue(value);

                    return testIp(ipList);
                },
            },
        });

        this.validator.showMessages();

        this.state = {
            errorMessages: Object.keys(this.validator.errorMessages).map((field) => {
                return {
                    field,
                    id: field,
                    message: this.validator.errorMessages[field],
                    tab: tabToField[field],
                };
            }),
        };
    }

    componentDidMount() {
        this.props.getAllRoles();
        this.props.getUserProfile(this.props.match.params.id).then(() => this.dispatchValidState());
    }

    handleSelect = (key) => {
        this.props.setCurrentTab(key);
    };

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        this.setState({
            errorMessages: Object.keys(this.validator.errorMessages).map((field) => {
                return {
                    field,
                    id: field,
                    message: this.validator.errorMessages[field],
                    tab: tabToField[field],
                };
            }),
        });
    };

    changeRole = (roleCode) => {
        this.setState({roleCode: roleCode});
        this.props.userEditField('roleCode', roleCode);
    };

    onChangeIpWhiteList = (staffIpWhitelist) => {
        this.props.userEditField('staffIpWhitelist', staffIpWhitelist);
    };

    onSave = () => {
        return this.props.saveProfile({
            ...this.props.user,
            active: this.props.user.active,
            code: this.props.user.code,
            defaultLanguageCode: getSelectFieldValue(this.props.user.defaultLanguageCode),
            defaultLocaleCode: getSelectFieldValue(this.props.user.defaultLocaleCode),
            roleCode: getSelectFieldValue(this.props.user.roleCode),
            staffIpWhitelist: getMultiSelectValue(this.props.user.staffIpWhitelist),
        });
    };

    render() {
        const {
            currentSessionUserAccountCode,
            editUserSuccess,
            error,
            isProfileChanged,
            isProfileValid,
            showValidMessages,
            user: {code},
            isLoading
        } = this.props;

        const {errorMessages} = this.state;

        const cancelAndDiscardCallback = () => {
            return this.props.getUserProfile(this.props.match.params.id);
        };
        const isRoleFieldDisabledWhileEditMyself = currentSessionUserAccountCode === code;

        return (
            <section className={'content user-profile-content row'}>
                <div className={'col-md-12'}>
                    {isLoading && <LoadingIndicator type="tabs"/>}
                    <Tabs
                        activeKey={this.props.currentTab}
                        animation={true}
                        defaultActiveKey={'general'}
                        id={'noanim-tab-example'}
                        onSelect={this.handleSelect}
                    >
                        <SystemMessage
                            message={'Profile successfully updated'}
                            shown={editUserSuccess}
                            top={'4em'}
                            type={'success'}
                        />
                        <SystemMessage
                            goToTab={this.handleSelect}
                            message={error}
                            shown={showValidMessages}
                            top={'7em'}
                            type={'error'}
                            validationMessages={errorMessages}
                        />
                        {/*{isLoading && <LoadingIndicator height={50}/>}*/}

                        <PreventTransitionPrompt
                            cancelAndDiscard={cancelAndDiscardCallback}
                            entity={'user'}
                            isFormValid={isProfileValid}
                            message={'MyCustomDialogComponent'}
                            saveChanges={this.onSave}
                            when={isProfileChanged}
                        />
                        <Tab eventKey={'general'} title={'General'}>
                            <div className={'row'}>
                                <StaffGeneralInfo
                                    dispatchValidState={this.dispatchValidState}
                                    validator={this.validator}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey={'settings'} title={'Settings'}>
                            <div className={'row'}>
                                <UserSettings
                                    color={'blue'}
                                    title={`Staff - ${this.props.user.firstName} ${
                                        this.props.user.lastName
                                    }`}
                                >
                                    <div className={'form-group bordered'}>
                                        <div className={'row'}>
                                            <FormField
                                                className={'margin-bottom-15'}
                                                label={'Security'}
                                                md={'12'}
                                                style={{fontSize: '1.1em'}}
                                                type={'label_field'}
                                            />
                                            <FormField
                                                className={'text-bg required-field'}
                                                disabled={isRoleFieldDisabledWhileEditMyself}
                                                id={'Role'}
                                                label={'Role'}
                                                md={'4'}
                                                multi={false}
                                                name={'roleCode'}
                                                onBlur={this.dispatchValidState}
                                                onChange={this.changeRole}
                                                options={this.props.roles}
                                                required={true}
                                                searchable={true}
                                                type={'select'}
                                                validator={this.validator}
                                                valids={'required'}
                                                value={this.props.user.roleCode}
                                            />
                                            <FormField
                                                creatable={true}
                                                id={'IP White List'}
                                                label={'IP White List'}
                                                md={'4'}
                                                multi={true}
                                                onBlur={this.dispatchValidState}
                                                onChange={this.onChangeIpWhiteList}
                                                placeholder={
                                                    'Input ip address or range of ip addresses, for example 192.168.0.1 or 192.168.0.2 - 192.168.0.100'
                                                }
                                                type={'creatable'}
                                                validator={this.validator}
                                                valids={'ip_list'}
                                                value={this.props.user.staffIpWhitelist}
                                            />
                                        </div>
                                    </div>
                                </UserSettings>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </section>
        );
    }

    static propTypes = {
        isLoading: PropTypes.bool,
    }
    static defaultProps = {
        isLoading: false
    };
}

const mapStateToProps = (state) => ({
    currentSessionUserAccountCode: state.app.auth.user.code,
    currentTab: state.app.users.currentTab,
    editUserSuccess: state.app.users.editUserSuccess,
    error: state.app.users.editUserError,
    isProfileChanged: state.app.users.isProfileChanged,
    isProfileValid: state.app.users.userValid,
    isLoading: state.app.users.profileLoading,
    roles: state.app.users.roles
        .filter((item) => {
            return isStaff(item.code) && item.active;
        })
        .map((role) => {
            return {value: role.code, label: role.name};
        }),
    showValidMessages: state.app.users.showValidMessages,
    user: state.app.users.editedUser,
});

const mapDispatchToProps = (dispatch) => ({
    getAllRoles: () => {
        dispatch(getAllRoles())
    },
    getAllLocales: () => {
        return dispatch(getAllLocales());
    },
    getUserProfile: (code) => {
        return dispatch(getUserProfile(code));
    },
    isAllValid: (valid) => {
        return dispatch(isAllValid(valid));
    },
    saveProfile: (data) => {
        return dispatch(editUser(data));
    },
    setCurrentTab: (tab) => {
        return dispatch(setCurrentTab(tab));
    },
    userEditField: (field, value) => {
        return dispatch(userEditField(field, value));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffEditPage);
