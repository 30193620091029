import React from 'react'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import { getAll } from '../../redux/actions/BasicEntityActions'
import { openDeleteModal } from '../../redux/action-creators/users'
import { editField } from '../../redux/actions/physicianActions'
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getNameFromCode } from "../../utils/helpers";

class PhysicianEditFacilities extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            size: 10,
            page: 1
        };
        this.applyParams = this.applyParams.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onActionClick = this.onActionClick.bind(this);
        this.facilityTypeFormatter = this.facilityTypeFormatter.bind(this);
    }

    componentDidMount() {
        this.props.getAll();
    }

    fetchData(data) {
        this.setState(data);
    }

    applyParams(data) {
        data.splice(this.state.page * this.state.size)
            .splice(0, this.state.size);

        return data;
    }

    onRowClick(item) {
        this.props.history.push(`/facility/${item}`)
    }

    enumFormatter = (cell, row, enumObject) => {
        return enumObject[ cell ];
    };

    onActionClick(physician) {
        const { code } = physician;
        const { physicianCodes } = this.props.facility;
        const { editField } = this.props;

        if (!Array.isArray(physicianCodes)) {
            return editField('physicianCodes', [code])
        }

        if (physicianCodes.indexOf(code) !== -1) {
            editField('physicianCodes', physicianCodes.filter((item) => item !== code))
        } else {
            editField('physicianCodes', [...physicianCodes, code])
        }
    }

    onRowSelect = (row, isSelected) => {
        const { code } = row;
        const { selectedFacilityCodes } = this.props.physician;
        const { editField } = this.props;

        if (!Array.isArray(selectedFacilityCodes)) {
            return editField('selectedFacilityCodes', [code])
        }

        if (isSelected) {
            editField('selectedFacilityCodes', [...selectedFacilityCodes, code])
        } else {
            editField('selectedFacilityCodes', selectedFacilityCodes.filter((item) => item !== code))
        }
    };

    onSelectAll = (isSelected) => {
        const { editField } = this.props;

        if (isSelected) {
            const data = this.props.data.map((item) => item.code);
            editField('selectedFacilityCodes', data)
        } else editField('selectedFacilityCodes', [])
    };

    facilityTypeFormatter(cell, row) {
        let typeName = '';
        if (row) {
            this.props.facilities_types.forEach((item) => {
                if (item.code === cell) {
                    typeName = item.name;
                }
            });
        }
        return typeName;
    }

    render() {
        let fTypes = {};
        for (let i = 0; i < this.props.facilities_types.length; i++) {
            fTypes[ this.props.facilities_types[ i ][ 'name' ] ] = this.props.facilities_types[ i ][ 'name' ];
        }
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'title': this.context.translate('common.form.code'),
                'sort': false,
                'hidden': true
            }, {
                'name': 'name',
                'title': this.context.translate('common.form.name'),
                'columnTitle': true,
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'facilityType',
                'title': this.context.translate('common.form.type'),
                'columnTitle': true,
                'sort': true,
                'filter': { type: 'SelectFilter', options: fTypes }
            }, {
                'name': 'facilityCode',
                'title': this.context.translate('common.form.facility_code'),
                'columnTitle': true,
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'linked',
                'title': 'Linking',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': 'Unlinked',
                    'true': 'Linked'
                },
                'width': '150',
                'filter': {
                    type: 'SelectFilter',
                    options: {
                        'false': 'Unlinked',
                        'true': 'Linked',
                    },
                    defaultValue: 'true'
                }
            }
        ];

        const selectRow = {
            mode: 'checkbox',
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll
        };
        const { gridConfiguration, data, loading, physician, isEditable } = this.props;
        const { sorting, pagination } = gridConfiguration;

        return (
            <div>
                <RemoteGrid
                    color="green"
                    entity="physician_facilities"
                    title={`Physician - ${physician.firstName} ${physician.lastName}`}
                    data={data}
                    total={data.length}
                    columns={columns}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.onRowClick}
                    selectRow={isEditable && selectRow}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                />
                {loading ? <LoadingIndicator height={50}/> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        facilities_types: state.app.facilities.types,
        data: state.app.facilities.data
            .filter((item) => item.active)
            .map(item => {
                let linked;
                const { facilityCodes } = state.app.physician.editedPhysician;
                if (facilityCodes) linked = facilityCodes.indexOf(item.code) !== -1;
                else linked = false;
                return {
                    ...item,
                    facilityType: getNameFromCode(item.facilityTypeCode, state.app.facilities.types),
                    linked
                }
            }),
        gridConfiguration: state.app.grid.physicianFacilities,
        physician: state.app.physician.editedPhysician
    }
};

PhysicianEditFacilities.contextTypes = {
    translate: PropTypes.func
};

const mapDispatchToProps = dispatch => {
    return {
        getAll: () => dispatch(getAll('facility')),
        handleOpenDeleteModal: () => {
            dispatch(openDeleteModal())
        },
        editField: (field, value) => dispatch(editField(field, value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PhysicianEditFacilities));
