const initialState = {
    loading: false,
    data: [],
    list: [],
    error: '',
    lastLoadedTime: 0,
}

const reviewerTypes = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_REVIEWER_TYPES_LOADING':
            return { ...state, loading: true }
        case 'FETCH_REVIEWER_TYPES_SUCCESS':
        case 'FETCH_REVIEWER_TYPE_SUCCESS': {
            let items = [];
            for (let i = 0; i < action.payload.length; i++) {
                items[ action.payload[ i ].code ] = action.payload[ i ].name;
            }
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                list: items,
                error: null,
                lastLoadedTime: Date.now(),
            })
        }
        case 'FETCH_REVIEWER_TYPES_ERROR':
            return { ...state, error: action.payload, loading: false }
        default:
    }

    return state
}

export default reviewerTypes
