import React from 'react';
import { allCases } from 'redux/pages/patient-case/patientCaseColumns';
import allSlice from 'redux/pages/patient-case/slices/allSlice';
import usePermissions from 'utils/hooks/usePermissions';
import PatientCaseGrid from '../Components/PatientCaseGrid';
import { reportFormatter } from '../Components/ReportFormatter';

const columns = allCases;

const All = (props) => {
  const { can } = usePermissions();

  return (
    <PatientCaseGrid
      // title="All"
      columns={columns}
      slice={allSlice}
      customActions={(rowData) =>
        can('MODIFY_CASES') ? reportFormatter(null, rowData) : null
      }
      actionsTitle="Report"
      {...props}
    />
  );
};

export default All;
