import React, { Component } from 'react';
import Box from '../../components/Box';
import BoxHeader from '../../components/BoxHeader';
import BoxBody from '../../components/BoxBody';
import BoxFooter from '../../components/BoxFooter';
import FormField from '../../components/FormField';

// import ReCAPTCHA from 'react-google-recaptcha'
// import {
//     reCaptchaKey as RECAPTCHA_KEY,
// } from '../../config';

export default class ProfileChangePassword extends Component {

    render() {
        return (
            <Box color="blue" classes="box-profile-first-row-height">
                <BoxHeader title="Change Password" color="blue" icon="fa-laptop"/>
                <BoxBody>
                    <form onSubmit={this.onSubmit} noValidate>
                        <div className="form-group ">
                            <div className="row">
                                <FormField
                                    md="8"
                                    type="password"
                                    component="text"
                                    required
                                    label="Current Password (for confirmation)"
                                    id="Current Password (for confirmation)"
                                    className="form-control"
                                    name="oldPassword"
                                    valids="required"
                                    validator={this.props.validator}
                                    value={this.props.profile.oldPassword}
                                    onChange={this.props.onChange}
                                    onBlur={this.props.dispatchValidState}
                                />
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="row">
                                <FormField
                                    md="8"
                                    type="password"
                                    required
                                    label="New Password"
                                    id="New Password"
                                    className="form-control"
                                    name="newPassword"
                                    valids="required|min:8|password"
                                    validator={this.props.validator}
                                    value={this.props.profile.newPassword}
                                    onChange={this.props.onChange}
                                    onBlur={this.props.dispatchValidState}
                                />
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="row">
                                <FormField
                                    md="8"
                                    type="password"
                                    required
                                    label="Repeat Password"
                                    id="Repeat Password"
                                    className="form-control"
                                    name="repeatNewPassword"
                                    valids="required|min:8|password|equal"
                                    validator={this.props.validator}
                                    value={this.props.profile.repeatNewPassword}
                                    onChange={this.props.onChange}
                                    onBlur={this.props.dispatchValidState}
                                />
                            </div>
                        </div>
                    </form>
                </BoxBody>
                <BoxFooter>
                    Password must contain at least a capital letter, a digit and a special character and be at least 8&nbsp;characters long.
                </BoxFooter>
            </Box>
        );
    }
}
