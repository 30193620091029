import api from '../../utils/api';
import { fieldsErrors } from './statuses';
import history from '../history';
import {
    setServerErrors,
    updateEntityError,
    updateEntityLoading,
    updateEntitySuccess,
    createEntityError,
    createEntitySuccess,
    createEntityLoading,
    getAvailableTriggersSuccess,
} from '../pages/notification-template/notificationTemplateSlice';
import { capitalize, omit, values } from 'lodash';
import { CC_ASSIGNEE_TYPES, TO_ASSIGNEE_TYPES, transformAssigneeTypesValuesToSelect } from '../pages/notification-template/utils';

const transformNotificationTemplate = (data) => ({
    ...omit(data, [...values(CC_ASSIGNEE_TYPES), ...values(TO_ASSIGNEE_TYPES)]),
    ccAssignee: transformAssigneeTypesValuesToSelect(CC_ASSIGNEE_TYPES, data),
    toAssignee: transformAssigneeTypesValuesToSelect(TO_ASSIGNEE_TYPES, data),
});

const mapUnprocessableEntityErrorMessages = (errors) =>
    errors.map(({ fieldName, errorMessage }) => ({
        id: fieldName,
        field: capitalize(fieldName),
        message: errorMessage,
    }));

export const createNotificationTemplate = (notificationTemplate) => (dispatch) => {
    dispatch(createEntityLoading());
    return api
        .post('notification_template/create', notificationTemplate)
        .then((response) => {
            dispatch(createEntitySuccess(transformNotificationTemplate(notificationTemplate)));
            dispatch({
                type: 'CLOSE_CREATE_MODAL',
            });
            history.push(`notification_templates/update/${response.data.id}`);
        })
        .catch((error) => {
            const {
                response: {
                    status = 500,
                    message = 'Unknown error',
                    data: { errors = [] },
                },
            } = error || {};
            dispatch(createEntityError(fieldsErrors[status]));
            if (status === 422) {
                dispatch(setServerErrors(mapUnprocessableEntityErrorMessages(errors)));
            }
            console.error('Error: ', message);
        });
};

export const getNotificationsAvailableTriggers = () => (dispatch) => {
    return api
        .get('notification_trigger/available_triggers')
        .then((response) => {
            // console.log('getNotificationsAvailableTriggers', response);
            dispatch(getAvailableTriggersSuccess(response));
        })
        .catch((error) => {
            const {
                response: {
                    status = 500,
                    message = 'Unknown error',
                    data: { errors = [] },
                },
            } = error || {};
            dispatch(createEntityError(fieldsErrors[status]));
            if (status === 422) {
                dispatch(setServerErrors(mapUnprocessableEntityErrorMessages(errors)));
            }
            console.error('Error: ', message);
        });
};

export const updateNotificationTemplate = (notificationTemplate, closeAfterSave) => (dispatch) => {
    dispatch(updateEntityLoading());

    return api
        .post('notification_template/update', omit(notificationTemplate, 'code'))
        .then(() => {
            dispatch(updateEntitySuccess(transformNotificationTemplate(notificationTemplate)));
            closeAfterSave && history.goBack();
        })
        .catch((error) => {
            const {
                response: {
                    status = 500,
                    message = 'Unknown error',
                    data: { errors = [] },
                },
            } = error || {};
            dispatch(updateEntityError(fieldsErrors[status]));
            if (status === 422) {
                dispatch(setServerErrors(mapUnprocessableEntityErrorMessages(errors)));
            }
            console.error('Error: ', message, error);
        });
};
