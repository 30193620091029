import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetFilter } from '../../../../redux/actions/cases/caseAssignActions';
import Spinner from "../../../Develop/Spinner";

class ResetFilterToDefaultButton extends React.Component {

    static propTypes = {
        loading: PropTypes.bool,
        patientCaseCode: PropTypes.string,
        resetFilter: PropTypes.func,
    }

    render() {
        const { loading, patientCaseCode, resetFilter } = this.props;
        return <button
            className="btn btn-default margin-left-5"
            style={{ width: '160px', display: 'inline-flex', justifyContent: 'center' }}
            disabled={loading}
            onClick={
                (e) => {
                    e.preventDefault();
                    resetFilter(patientCaseCode);
                }
            }
        >
            <span>{'Reset Filter to Default'}</span>&nbsp;{loading && <Spinner />}
        </button>
    }
}

ResetFilterToDefaultButton.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    // console.log('mapStateToProps', state.app);
    return {
        loading: state.app.case_assign.loading,
        patientCaseCode: state.app.cases.editedCase.code,
    }
};

const mapDispatchToProps = dispatch => ({
    resetFilter: (code) => dispatch(resetFilter(code))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetFilterToDefaultButton)
