import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LinkingConnectedDataTable from './common/LinkingConnectedDataTable';
import { linkClientEditPhysician, unlinkClientEditPhysician } from '../../redux/pages/client-edit-physician/clientEditPhysicianActions';
import clientEditPhysicianSlice from '../../redux/pages/client-edit-physician/clientEditPhysicianSlice';
import ColoredConnectedDataTable from './common/ColoredConnectedDataTable';

const columns = {
  firstName: { label: 'First Name', colStyle: { width: '15%' } },
  lastName: { label: 'Last Name', colStyle: { width: '15%' } },
  physicianCode: { label: 'Code', colStyle: { width: '12%' } },
  specialtyNames: { label: 'Specialty', colStyle: { width: 'auto' } },
  bodyPartNames: { label: 'Body Parts', colStyle: { width: '15%' } },
  languageNames: { label: 'Languages', colStyle: { width: '15%' } },
  linked: {
    label: 'Linking',
    colStyle: { width: '12%' },
    defaultValue: true,
    sortable: false,
    filterOptions: [
      { value: true, label: 'Linked' },
      { value: false, label: 'Unlinked' },
    ],
  },
};

const ClientEditPhysiciansNew = ({ isEditable }) => {
  const { code, fullName } = useSelector((state) => {
    return state.app.companies.editedCompany;
  });

  const { selectedEntities: selectedPhysicians, settings: datatableSettings } = useSelector((state) => {
    // console.log('STATE APP', state.app);
    return state.app.clientEditPhysician;
  });

  const { search } = useSelector((state) => {
    // console.log('APP:', state.app);
    return state.app.globalSearch;
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const handleRowClick = ({ code }) => {
    // console.log('handleRowClick', history, code);
    history.push(`/physicians/${code}`);
  };

  const handleLinkSubmit = () => {
    // console.log('handleLinkSubmit');
    const selectedPhysicianCodes = selectedPhysicians.map(({ code }) => code);
    dispatch(linkClientEditPhysician(selectedPhysicianCodes, datatableSettings, search, code));
  };

  const handleUnlinkSubmit = () => {
    // console.log('handleUnlinkSubmit', selectedPhysicians);
    const selectedPhysicianCodes = selectedPhysicians.map(({ code }) => code);
    dispatch(unlinkClientEditPhysician(selectedPhysicianCodes, datatableSettings, search, code));
  };

  return (
    isEditable ?
      <LinkingConnectedDataTable
        title={'Client - ' + fullName}
        columns={columns}
        stateKey="clientEditPhysician"
        actions={clientEditPhysicianSlice.actions}
        onRowClick={handleRowClick}
        onLinkClick={handleLinkSubmit}
        onUnlinkClick={handleUnlinkSubmit}
      />
      :
      <ColoredConnectedDataTable
        title={'Client - ' + fullName}
        columns={columns}
        stateKey="clientEditPhysician"
        actions={clientEditPhysicianSlice.actions}
        onRowClick={handleRowClick}
      />
  );
};

ClientEditPhysiciansNew.propTypes = {
  isEditable: PropTypes.bool,
  history: PropTypes.object,
};

export default ClientEditPhysiciansNew;
