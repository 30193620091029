import React from 'react';

const boxClass = {
    orange: 'box-warning',
    green: 'box-success',
    blue: 'box-primary',
};

const Box = ({ color, classes, className, children, ...rest }) => (
    <div {...rest} className={`box ${boxClass[color] || color} ${classes} ${className}`}>
        {children}
    </div>
);

export default Box;
