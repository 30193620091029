import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import FormContext from './FormContext';
import { useLanguage } from 'utils/hooks/language';
import ControlContextProvider from './ControlContextProvider';
import { initialInputState } from 'redux/utils/createEntityMutationTemplateSlice';
import { useSelector } from 'react-redux';
import { appendRequiredRuleIfNeccessary } from './utils';

const Control = ({
  component: Field,
  name,
  rules,
  required,
  translated,
  md,
  label,
  onBlur,
  onFocus,
  readOnly,
  inlineLabel,
  hideLabel,
  formatReadonlyValue,
  showRequiredLabelPostfix,
  onChange: onChangeProp,
  container: Container,
  ...rest
}) => {
  const language = useLanguage();
  const {
    formKey,
    onChangeField,
    inlineErrors,
    inputStates,
    setFieldStates,
    validateField,
    errorMessages,
    setFieldValidationRule,
  } = useContext(FormContext);

  const [inputStateInitialized, setInputStateInitialized] = useState(false);
  const { [name]: inputState = initialInputState } = inputStates;

  const showInvalidState = inputState.isVisited || inputState.isSubmitted;
  const isInvalid = errorMessages[name] && showInvalidState;

  const setInputState = useCallback(
    (stateObject) => {
      setFieldStates(name, { ...inputState, ...stateObject });
    },
    [inputState, name, setFieldStates],
  );

  const validatorRules = useMemo(() => {
    if (required) {
      return appendRequiredRuleIfNeccessary(rules);
    }
    return rules;
  }, [required, rules]);

  useEffect(() => {
    if (!inputStateInitialized) {
      setFieldValidationRule(name, {
        field: name,
        rules: validatorRules,
        translated,
        label,
      });
      setInputStateInitialized(true);
    }
  }, [
    inputState,
    inputStateInitialized,
    label,
    name,
    setFieldStates,
    setFieldValidationRule,
    translated,
    validatorRules,
  ]);

  const value = useSelector((state) => {
    const { [name]: value = '' } = state.app[formKey].entity;

    if (!translated) {
      return value;
    }

    const { [language]: translatedValue = '' } = value;
    return translatedValue;
  });

  const handleChange = useCallback(
    ({ target: { value: nextValue } }) => {
      if (!inputState.isTouched) {
        setInputState({ isTouched: true });
      }

      if (translated) {
        onChangeField({ [name]: { [language]: nextValue } });
        onChangeProp(name, { [language]: nextValue });
      } else {
        onChangeField({ [name]: nextValue });
        onChangeProp(name, nextValue);

        // console.log('change', nextValue);
      }

      validateField(name, nextValue, validatorRules, { label });
    },
    [
      inputState.isTouched,
      label,
      language,
      name,
      onChangeField,
      onChangeProp,
      setInputState,
      translated,
      validateField,
      validatorRules,
    ],
  );

  const isRequired = required || validatorRules.includes('empty');

  const showInlineErrors =
    showInvalidState && inlineErrors && errorMessages[name];

  const containerProps = Container === Col ? { md } : {};

  return (
    <Container {...containerProps}>
      <FormGroup controlId={name} validationState={isInvalid ? 'error' : null}>
        <ControlContextProvider>
          {label && (
            <Col md={inlineLabel ? 6 : 12} className="control__label-container">
              <ControlLabel>
                {!hideLabel && label}
                {!hideLabel && isRequired && showRequiredLabelPostfix && '*'}
              </ControlLabel>
            </Col>
          )}
          <Col md={inlineLabel ? 6 : 12} className="control__input-container">
            {readOnly && formatReadonlyValue ? (
              formatReadonlyValue(value)
            ) : (
              <Field
                {...rest}
                value={value}
                onChange={handleChange}
                name={name}
                readOnly={readOnly}
                required={required}
                onBlur={(...args) => {
                  validateField(name, value, validatorRules, { label });
                  onBlur(...args);
                  setInputState({ isFocused: false, isVisited: true });
                }}
                onFocus={(...args) => {
                  onFocus(...args);
                  setInputState({
                    isFocused: true,
                    isTouched: true,
                    isSubmitted: false,
                  });
                }}
              />
            )}
            <span className="text-danger">
              {showInlineErrors && errorMessages[name].message}
            </span>
          </Col>
        </ControlContextProvider>
      </FormGroup>
    </Container>
  );
};

Control.propTypes = {
  name: PropTypes.string.isRequired,
  component: PropTypes.any,
  label: PropTypes.string,
  className: PropTypes.string,
  rules: PropTypes.string,
  translated: PropTypes.bool,
  required: PropTypes.bool,
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  showRequiredLabelPostfix: PropTypes.bool,
  hideLabel: PropTypes.bool,
  readOnly: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  formatReadonlyValue: PropTypes.func,
  container: PropTypes.oneOfType([PropTypes.element, PropTypes.symbol]),
};

Control.defaultProps = {
  component: FormControl,
  className: 'form-control',
  rules: '',
  translated: false,
  required: false,
  md: 12,
  container: Col,
  label: '',
  showRequiredLabelPostfix: false,
  hideLabel: false,
  readOnly: false,
  inlineLabel: false,
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  formatReadonlyValue: null,
};

export default Control;
