import { connect } from 'react-redux'
import {getOne4Select, setLoadedCount} from '../../redux/actions/BasicEntityActions'
import {getCompanyPhysicians} from '../../redux/actions/physicianActions'
import RefField from './RefField'

const mapStateToProps = (state) => ({
    isLoading:  state.app.physician.loading,
    loadedCount: state.app.physician.loadedCount,
    options: state.app.physician.data
        .map(({ firstName, lastName, code }) => ({
            label: firstName + ' ' + lastName,
            value: code,
        })),
});

const mapDispatchToProps = (dispatch) => ({
    getOptions: () => dispatch(getCompanyPhysicians()),
    getOne4Select: (code) => dispatch(getOne4Select(code, 'physician')),
    setLoadedCount: (count) => dispatch(setLoadedCount(count, 'physician'))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefField)
