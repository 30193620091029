import React from 'react';
import FormField from '../FormField'
import PropTypes from "prop-types";

export default class MfaSection extends React.Component {

    onChangeCheckBox = (e) => {
        this.props.onChange([e.target.name], e.target.checked);
    };

    render() {
        const { isEditable, mfaEmail, mfaSms, mfaAuthenticator } = this.props;

        return (
            <>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type='label_field'
                            label="MFA"
                            className='margin-bottom-15 fontWeight800'
                            style={{ fontSize: '1.1em' }}
                        />
                        <FormField
                            md="2"
                            type="checkbox"
                            title="Authenticator MFA"
                            label="Authenticator"
                            name='mfaAuthenticator'
                            value={mfaAuthenticator}
                            checked={mfaAuthenticator}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                            disabled={!isEditable}
                        />
                        <FormField
                            md="2"
                            type="checkbox"
                            title="Email MFA"
                            label="Email"
                            name='mfaEmail'
                            value={mfaEmail}
                            checked={mfaEmail}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                            disabled={!isEditable}
                        />
                        <FormField
                            md="2"
                            type="checkbox"
                            title="SMS MFA"
                            label="SMS"
                            name='mfaSms'
                            value={mfaSms}
                            checked={mfaSms}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                            disabled={!isEditable}
                        />
                        </div>
                    </div>
                </>
        )
    }

    static propTypes = {
        mfaEmail: PropTypes.bool,
        mfaSms: PropTypes.bool,
        mfaAuthenticator: PropTypes.bool,
        isEditable: PropTypes.bool,
        onChange: PropTypes.func,
    }
    static defaultProps = {
        isEditable: true,
    }
}
