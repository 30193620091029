const { transformResponseToTableData } = require('components/Datatable/dataTableUtils');

export const transformCaseFileTemplates = transformResponseToTableData((data) => {
    return {
        data: data.map(({ name, clients, active, code, templateName }) => ({
            name,
            client: clients.map(({ shortName }) => shortName).join(', '),
            active: active ? 'ACTIVE' : 'INACTIVE',
            code,
            templateName,
        })),
    };
});
