export default function changeLocationMiddleware({dispatch}) {

    return next => action => {
        if (action && action.type === '@@router/LOCATION_CHANGE') {
            dispatch({
                type: 'CLEAR_VISIBLE_CODES'
            });

            return next(action)
        }

        return next(action)
    }
}