export const patientCaseStages = {
    DRAFT: 'DRAFT',
    COMPLETE: 'COMPLETE',
    FLAGGED: 'FLAGGED',
    DISPATCH: 'DISPATCH',
    REVIEW: 'REVIEW',
    QA: 'QA',
    CLOSED: 'CLOSED',
    IN_PROCESS: 'IN_PROCESS',
    AVAILABLE: 'AVAILABLE',
    ACCEPTED: 'ACCEPTED',
    CLOSED_THIS_MONTH: 'CLOSED_THIS_MONTH',
    CLOSED_LAST_MONTH: 'CLOSED_LAST_MONTH',
    ALL: 'ALL',
};
