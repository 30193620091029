/* eslint-enable react-hooks/exhaustive-deps */
/* eslint "react-hooks/exhaustive-deps": "error" */

import { isArray, zipObject } from 'lodash';
import { useState, useCallback } from 'react';
import { sortDirections } from '../dataTableUtils';

const useInnerSort = ({ setCurrentPage, onSettingsChange, defaultSettings, initialSettings }) => {
    const [sortedColumn, setSortedColumn] = useState(defaultSettings.sortBy);
    const [sortDirection, setSortDirection] = useState(defaultSettings.sortDirection);

    const getNextSortDirection = useCallback((sortDirection) => {
        const nextSortDirection = sortDirection === sortDirections.DESC ? sortDirections.ASC : sortDirections.DESC;

        return nextSortDirection;
    }, []);

    const handleSort = useCallback(
        (sortBy) => {
            setCurrentPage(1);

            if (isArray(sortedColumn)) {
                const sortDirectionObject = zipObject(sortedColumn, sortDirection);
                const nextSortDirection = sortDirectionObject[sortBy] ? getNextSortDirection(sortDirectionObject[sortBy]) : sortDirections.ASC;

                setSortDirection(nextSortDirection);
                setSortedColumn(sortBy);
                onSettingsChange({ sortDirection: nextSortDirection, sortBy, page: 1 });
            } else if (sortBy !== sortedColumn) {
                setSortedColumn(sortBy);
                onSettingsChange({ sortBy, page: 1 });
            } else {
                const nextSortDirection = getNextSortDirection(sortDirection);

                setSortDirection(nextSortDirection);
                onSettingsChange({ sortDirection: nextSortDirection, page: 1 });
            }
        },
        [setCurrentPage, sortedColumn, sortDirection, onSettingsChange, getNextSortDirection],
    );

    const handleResetSort = useCallback(() => {
        setCurrentPage(1);
        setSortedColumn(initialSettings.sortBy);
        setSortDirection(initialSettings.sortDirection);

        onSettingsChange({
            sortDirection: initialSettings.sortDirection,
            sortBy: initialSettings.sortBy,
            page: 1,
        });
    }, [initialSettings.sortBy, initialSettings.sortDirection, onSettingsChange, setCurrentPage]);

    return { handleResetSort, handleSort, sortedColumn, sortDirection };
};

export default useInnerSort;
