import React, { Component } from "react";

import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'

import { connect } from 'react-redux'
import { editField, saveLogo } from "../../redux/actions/companiesActions";

import { API_HOST } from '../../config';
import Dropzone from "../../components/deprecated/react-dropzone/src";

class ClientEditLogo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstLoad: true,
            logo: null,
            savedLogo: []
        };
        this.delImage = this.delImage.bind(this);
    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        if (nextProps.user && (this.state.firstLoad || JSON.stringify(nextProps.user) !== JSON.stringify(this.props.user))) {
            if (nextProps.user) {
                this.setState({
                    logo: nextProps.user.logo
                });
            }
        }
        this.setState({ firstLoad: false });
    }

    onDrop(savedLogo) {
        this.props.editField('savedLogo', savedLogo)
    }

    delImage() {
        this.props.editField('logo', null);
        this.props.editField('savedLogo', [])
    }

    render() {
        const { isEditable } = this.props;
        const { savedLogo, logo } = this.props.user;

        const pointerEvents = isEditable ? 'auto' : 'none';

        return (
            <Box color="green" classes="box-profile-first-row-height">
                <BoxHeader title="Client - Client Logo" color="green" icon="fa-camera"
                />
                <BoxBody>
                    <div className="form-group has-feedback">
                        <div className="form-group has-feedback">
                            <Dropzone
                                id="user-profile-image"
                                className={`dz-clickable ${logo != null || savedLogo.length !== 0 ? 'hidden' : null}`}
                                style={{ pointerEvents: pointerEvents }}
                                onDrop={this.onDrop.bind(this)}
                                multiple={false}
                            />
                            <div id="image-div" className={savedLogo && savedLogo.length === 0 ? 'hidden' : 'show'}>
                                {
                                    savedLogo && savedLogo.map(f =>
                                        <img key={f.name} className="" alt="Client logo" src={f.preview}/>
                                    )
                                }
                            </div>
                            <div id="image-div"
                                 className={logo === null || (savedLogo && savedLogo.length !== 0) ? 'hidden' : 'show'}>
                                <img className="" alt="" title="staff.edit_content.user_image"
                                     src={API_HOST + '/company/logo?code=' + this.props.user.code}/>
                            </div>
                            {/* eslint-disable-next-line */}
                            {isEditable && <a className="btn-lg remove-profile-image" onClick={this.delImage}><i
                                className="fa fa-times pull-right"> </i>
                            </a>}
                        </div>
                    </div>
                </BoxBody>
                <BoxFooter>
                    {isEditable && 'Click inside the grey rectangle or drag & drop a file'}
                </BoxFooter>
            </Box>
        );
    }

    static defaultProps = {
        isEditable: true,
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.app.companies.editedCompany,
        error: state.app.companies.error
    }
};

const mapActions = dispatch => {
    return {
        editField: (field, value) => dispatch(editField(field, value)),
        saveLogo: data => dispatch(saveLogo(data))
    }
};

export default connect(mapStateToProps, mapActions)(ClientEditLogo);
