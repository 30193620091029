const fieldsDefaultState = {
	loading: true,
	reloading: false,
	fields: []
}
const entityDefaultState = {
	loading: true,
	data: {}
}
const defaultState = {
	open: false,
	error: null,
	loading: true,
	entityName: '',
	fieldSections: [],
	fields: fieldsDefaultState,
	entity: entityDefaultState,
	tmpEntityValues: null,

	submitURL: '',
	fieldsURL: '',

	submitLoading: false,
	submitErrorMap: null
}

const loadError = "Could not load form for entity: "

export default (state = defaultState, action) => {
	var newState = null;
	var formStruct = null;
	// if (action.type.indexOf('ENTITY_FORM') === 0) {
	// 	console.log(action)
	// }
	switch (action.type) {
		// FORM FIELDS
		case "ENTITY_FORM_FIELDS_LIST_INITIALIZE":
			formStruct = buildFormStructure(action.fields);
			newState = Object.assign({}, state, {
				fieldSections: formStruct.sections,
				fields: Object.assign({}, state.fields, {
					loading: false,
					reloading: false,
					fields: formStruct.fields
				})
			});
			newState.loading = isEntityFormLoading(newState);
			return newState;
		case "ENTITY_FORM_FIELDS_LIST_SUCCESS":
			formStruct = buildFormStructure(action.data["hydra:member"]);
			newState = Object.assign({}, state, {
				fieldSections: formStruct.sections,
				fields: Object.assign({}, state.fields, {
					loading: false,
					reloading: false,
					fields: formStruct.fields
				})
			});
			newState.loading = isEntityFormLoading(newState);
			return newState;
		case "ENTITY_FORM_FIELDS_LIST_ERROR":
			newState = Object.assign({}, state, {
				error: loadError + state.entityName,
				fields: Object.assign({}, state.fields, {loading: false})
			})
			newState.loading = isEntityFormLoading(newState);
			return newState;
		case "ENTITY_FORM_FIELDS_LIST_RELOADING":
			return Object.assign({}, state, {
				fields: {
					...state.fields,
					reloading: true
				}
			})
		case "ENTITY_FORM_FIELDS_LIST_LOADING":
			newState = Object.assign({}, state, {
				fieldSections: [],
				fields: fieldsDefaultState
			})
			newState.loading = isEntityFormLoading(newState);
			return newState;

		// ENTITY_LOAD
		case "ENTITY_FORM_ENTITY_LOAD_SUCCESS":
			newState = Object.assign({}, state, {
				entity: Object.assign({}, state.entity, {
					loading: false,
					data: action.data
				})
			});
			newState.loading = isEntityFormLoading(newState);
			return newState;
		case "ENTITY_FORM_ENTITY_LOAD_ERROR":
			newState = Object.assign({}, state, {
				error: loadError + state.entityName,
				entity: Object.assign({}, state.entity, {loading: false})
			})
			newState.loading = isEntityFormLoading(newState);
			return newState;
		case "ENTITY_FORM_ENTITY_LOAD_LOADING":
			newState = Object.assign({}, state, {
				entity: entityDefaultState
			})
			newState.loading = isEntityFormLoading(newState);
			return newState;
		case "ENTITY_FORM_ENTITY_LOAD_EMPTY":
			newState = Object.assign({}, state, {
				entity: Object.assign({}, entityDefaultState, {
					loading: false,
				})
			})
			newState.loading = isEntityFormLoading(newState);
			return newState;

		// TEMPORARY FORM FIELDS DATA
		case "ENTITY_FORM_ENTITY_FIELD_VALUE_UPDATE":
			return Object.assign({}, state, {
				tmpEntityValues: Object.assign({}, state.entity.data, state.tmpEntityValues, {
					[action.key]: action.value,
				})
			})

		// SUBMIT ENTITY
		case "ENTITY_FORM_SUBMIT_SUCCESS":
			return Object.assign({}, state, {
				entity: {
					loading: false,
					data: action.data,
				},
				submitURL: action.data["@id"],
				submitLoading: false,
				tmpEntityValues: null,
			})
		case "ENTITY_FORM_SUBMIT_ERROR":
			return Object.assign({}, state, {
				submitLoading: false,
				submitErrorMap: action.error
			})
		case "ENTITY_FORM_SUBMIT_LOADING":
			return Object.assign({}, state, {
				submitLoading: action.loading,
				submitErrorMap: action.loading ? null : state.submitErrorMap
			})

		// GENERAL
		case "ENTITY_FORM_OPEN":
			return Object.assign({}, defaultState, {
				open: action.open,
				entityName: action.open ? action.entityName : '',
				submitURL: action.open ? action.submitURL : '',
				fieldsURL: action.open ? action.fieldsURL : '',
			})
		case "ENTITY_FORM_RESET":
			return Object.assign({}, state, {
				tmpEntityValues: null
			})
		case "ENTITY_FORM_ERROR":
			return Object.assign({}, state, {
				loading: false,
				submitLoading: false,
				error: action.error,
			})
		default:
			return state
	}
}

const buildFormStructure = fields => {
	var result = {
		fields: [],
		sections: [],
	}

	if (fields.length === 0) {
		return result;
	}

	if (fields[0].fields) {
		var sectionFields;
		for (var sect of fields) {
			sectionFields = sect.fields.map(f => f.key);
			result.fields.push(...sect.fields);
			result.sections.push({
				...sect,
				fields: sectionFields
			});
		}
	} else {
		result.fields = fields;
	}

	return result;
}

const isEntityFormLoading = newState => {
	return newState.fields.loading || newState.entity.loading
}
