import React from 'react';
import PageContainer from '../../components/PageContainer/PageContainer';
import ColoredBox from '../../components/ColoredBox/ColoredBox';
import ConnectedDataTable from '../../components/Datatable/ConnectedDataTable';
import caseFileTemplateSlice, { deleteEntity, downloadCaseFileTemplate } from 'redux/pages/case-file-template/caseFileTemplateSlice';

import { useDispatch, useSelector } from 'react-redux';
import SystemMessage from 'components/SystemMessage';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toolTipId } from '../../utils/helpers';

const columns = {
  name: {
    label: 'Template Name',
    colStyle: { width: '35%' },
  },
  client: {
    label: 'Clients',
    colStyle: { width: 'auto' },
    sortable: false,
  },
  active: {
    label: 'Status',
    colStyle: { width: '80px' },
    filterOptions: [
      { value: true, label: 'ACTIVE' },
      { value: false, label: 'INACTIVE' },
    ],
  },
};

// eslint-disable-next-line react/prop-types
const CaseFileTemplatePage = ({ history }) => {
  const dispatch = useDispatch();

  const { error } = useSelector((state) => state.app.caseFileTemplates);

  const handleRowClick = ({ code }) => {
    history.push(`case_file_templates/update/${code}`);
  };

  const handleRemove = ({ code }) => {
    dispatch(deleteEntity(code));
  };

  const handleDownload = (params, event) => {
    dispatch(downloadCaseFileTemplate(params));
    event.stopPropagation();
  };

  return (
    <PageContainer>
      <ColoredBox variant="primary" title="Templates">
        <SystemMessage type="error" shown={error} message={error}/>
        <ConnectedDataTable
          columns={columns}
          onRowClick={handleRowClick}
          onDelete={handleRemove}
          stateKey="caseFileTemplates"
          actions={caseFileTemplateSlice.actions}
          variant="primary"
          keyExtractor={({ code }) => code}
          deleteDisabled={({ client }) => client && client.length > 0}
          actionsTitle="Download"
          customActions={(entity) => {
            const { code, templateName } = entity;
            return (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={toolTipId('case-file-download-template')}>Download template</Tooltip>}
              >
                <Button
                  id={entity.code}
                  onClick={(event) =>
                    handleDownload({ code, templateName }, event)
                  }
                >
                  <i className="fa fa-download"/>
                </Button>
              </OverlayTrigger>
            );
          }}
        />
      </ColoredBox>
    </PageContainer>
  );
};

CaseFileTemplatePage.propTypes = {
  history: PropTypes.object,
};

export default CaseFileTemplatePage;
