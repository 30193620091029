import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

export const ControlCheckbox = ({
  className,
  name,
  id,
  disabled,
  checked,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(checked);

  const handleChange = useCallback(() => {
    const valueNegated = !checked;
    setValue(valueNegated);
    onChange({ target: { value: valueNegated } });
  }, [onChange, checked]);

  return (
    <input
      {...props}
      type="checkbox"
      className={className + ' checkbox'}
      name={name}
      id={id}
      value={value}
      disabled={disabled}
      checked={checked}
      onChange={handleChange}
    />
  );
};

ControlCheckbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ControlCheckbox;
