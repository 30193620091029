import { connect } from 'react-redux'
import { getAllSpecialties } from '../../redux/actions/specialtyActions'
import { getOne4Select, setLoadedCount } from "../../redux/actions/BasicEntityActions";
import RefField from './RefField'

const mapStateToProps = (state) => ({
    isLoading:  state.app.specialties.loading,
    loadedCount: state.app.specialties.loadedCount,
    options: state.app.specialties.data
        .filter((item) => item.active)
        .map(spec => ({
            value: spec.code,
            label: spec.name
        }))
})

const mapDispatchToProps = (dispatch) => ({
    getOptions: () => dispatch(getAllSpecialties()),
    getOne4Select: (code) => dispatch(getOne4Select(code, 'specialty')),
    setLoadedCount: (count) => dispatch(setLoadedCount(count, 'specialty'))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefField)
