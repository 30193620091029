import React, {Component} from "react";

import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'

import FormField from '../../components/FormField'

import {getSelectFieldValue} from "../../utils/helpers";
import ProfileFormWrapper from '../../containers/ProfileFormWrapper'
import ContactInfoSection from "../../components/Sections/ContactInfoSection";
import AddressInfoSection from "../../components/Sections/AddressInfoSection";
import { API_HOST } from '../../config';
import Dropzone from "../../components/deprecated/react-dropzone/src";

class ProfileUserDataReviewer extends Component {

    state = {}

    render() {
        // const {isLoading, showSuccess, error} = this.props;
        const {savedSignature, signature} = this.props.profile;

        return (
            <Box color="blue" classes="box-profile-first-row-height-show">
                <BoxHeader title="Profile" color="blue" icon="fa-user"/>
                <BoxBody>
                    <form onSubmit={this.onSubmit} noValidate autoComplete="off">
                        <div className="form-group bordered">
                            <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="12"
                                        type='label_field'
                                        label="Personal Information"
                                        className='margin-bottom-15'
                                        style={{fontSize: '1.1em'}}
                                    />
                                    <FormField
                                        md="4"
                                        label="First Name"
                                        id="First Name"
                                        className="form-control"
                                        required
                                        name="firstName"
                                        valids="required|alpha_dash_space"
                                        validator={this.props.validator}
                                        value={this.props.profile.firstName}
                                        onChange={this.props.onChange}
                                        onBlur={this.props.dispatchValidState}
                                    />
                                    <FormField
                                        md="4"
                                        label="Middle Name"
                                        id="Middle Name"
                                        className="form-control"
                                        name="middleName"
                                        valids="alpha_dash_space"
                                        validator={this.props.validator}
                                        value={this.props.profile.middleName}
                                        onChange={this.props.onChange}
                                        onBlur={this.props.dispatchValidState}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="4"
                                        label="Last Name"
                                        id="Last Name"
                                        className="form-control"
                                        required
                                        name="lastName"
                                        valids="required|alpha_dash_space"
                                        validator={this.props.validator}
                                        value={this.props.profile.lastName}
                                        onChange={this.props.onChange}
                                        onBlur={this.props.dispatchValidState}
                                    />
                                    <FormField
                                        md="4"
                                        label="Title"
                                        className="form-control"
                                        id="user_edit_title"
                                        name="title"
                                        value={this.props.profile.title}
                                        onChange={this.props.onChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <ContactInfoSection
                            validator={this.props.validator}
                            dispatchValidState={this.props.dispatchValidState}
                            onChange={this.props.onChange}
                            email={this.props.profile.email}
                            notificationEmail={this.props.profile.notificationEmail}
                            phoneNumber={this.props.profile.phoneNumber}
                            alternativePhoneNumber={this.props.profile.alternativePhoneNumber}
                        />
                        <AddressInfoSection
                            onChange={this.props.onChange}
                            onChangeCountry={this.props.onChangeCountry}
                            onChangeState={this.props.onChangeState}
                            address1={this.props.profile.address1}
                            address2={this.props.profile.address2}
                            city={this.props.profile.city}
                            countryCode={this.props.profile.countryCode}
                            stateCode={this.props.profile.stateCode}
                            postalCode={this.props.profile.postalCode}
                        />

                        <div className="form-group bordered">
                            <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="12"
                                        type='label_field'
                                        label="Professional Information"
                                        className='margin-bottom-15'
                                        style={{fontSize: '1.1em'}}
                                    />
                                    <FormField
                                        md="4"
                                        type="select"
                                        label="Specialties"
                                        id="Specialties"
                                        className="text-bg required-field"
                                        required
                                        searchable
                                        disabled={false}
                                        multi={true}
                                        options={this.props.specialties}
                                        name="specialtyCodes"
                                        valids="required"
                                        validator={this.props.validator}
                                        value={this.props.profile.specialtyCodes}
                                        onChange={this.props.onChangeSpecialties}
                                        onBlur={this.props.dispatchValidState}
                                    />
                                    <FormField
                                        md="4"
                                        type="select"
                                        label="Languages"
                                        className="text-bg "
                                        searchable
                                        multi={true}
                                        disabled={false}
                                        options={this.props.languages}
                                        id="user_edit_language"
                                        name="languageCodes"
                                        value={this.props.profile.languageCodes}
                                        onChange={this.props.onChangeLanguageCodes}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="4"
                                        label="IBAN"
                                        className="form-control"
                                        id="user_edit_reviewer_iban"
                                        divClasses="show"
                                        name="iban"
                                        value={this.props.profile.iban}
                                        onChange={this.props.onChange}
                                    />
                                    <FormField
                                        md="4"
                                        label="BIC Number"
                                        className="form-control"
                                        id="user_edit_reviewer_bicNumber"
                                        divClasses="show"
                                        name="bicnum"
                                        value={this.props.profile.bicnum}
                                        onChange={this.props.onChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="4"
                                        label="Routing Number"
                                        className="form-control"
                                        id="user_edit_reviewer_routingNumber"
                                        disabled={getSelectFieldValue(this.props.profile.countryCode) !== 'US'}
                                        divClasses="hide"
                                        name="routingNum"
                                        value={this.props.profile.routingNum}
                                        onChange={this.props.onChange}
                                    />
                                    <FormField
                                        md="4"
                                        label="Account Number"
                                        className="form-control h"
                                        id="user_edit_reviewer_accountNumber"
                                        disabled={getSelectFieldValue(this.props.profile.countryCode) !== 'US'}
                                        divClasses="hide"
                                        name="accountNum"
                                        value={this.props.profile.accountNum}
                                        onChange={this.props.onChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-3">
                                        <Dropzone
                                            id="user-profile-image"
                                            style={{margin: 0}}
                                            className={`dz-clickable ${signature != null || savedSignature.length !== 0 ? 'hidden' : null}`}
                                            onDrop={this.props.onDrop}
                                            multiple={false}
                                        />
                                        <div id="image-div" className={savedSignature && savedSignature.length === 0 ? 'hidden' : 'show'}>
                                            {
                                                savedSignature.map(f =>
                                                    <img key={f.name} className="" alt="client logo" src={f.preview}/>
                                                )
                                            }
                                        </div>
                                        <div id="image-div"
                                             className={signature === null || (savedSignature && savedSignature.length !== 0) ? 'hidden' : 'show'}
                                        >
                                            <img key={signature} className="" alt="staff.edit_content.user_image"
                                                 src={API_HOST + '/user/signature?code=' + this.props.user.code}/>
                                        </div>
                                        {/* eslint-disable-next-line */}
                                        <a className="btn-lg remove-profile-image" style={{position: 'absolute', right: '50px', bottom: '-35px'}}
                                           onClick={this.props.delImage}>
                                            <i className="fa fa-times"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <FormField
                                    md="4"
                                    xs="12"
                                    type="select"
                                    label="Locale"
                                    className="text-bg "
                                    searchable
                                    multi={false}
                                    disabled={false}
                                    options={this.props.locales}
                                    id="user_edit_locale"
                                    name="defaultLocaleCode"
                                    value={this.props.profile.defaultLocaleCode}
                                    onChange={this.props.onChangeDefaultLocale}
                                />
                                <FormField
                                    md="4"
                                    xs="12"
                                    type="select"
                                    label="Time Zone"
                                    className="text-bg "
                                    searchable={true}
                                    multi={false}
                                    disabled={false}
                                    options={this.props.timezones}
                                    name="timeZoneCode"
                                    value={this.props.profile.timeZoneCode}
                                    onChange={this.props.onChangeTimeZones}
                                />
                            </div>
                        </div>
                    </form>
                </BoxBody>
                <BoxFooter>

                </BoxFooter>
            </Box>
        );
    }
}


export default ProfileFormWrapper(ProfileUserDataReviewer);
