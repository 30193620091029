import React from 'react';
import { closed } from 'redux/pages/patient-case/patientCaseColumns';
import closedSlice from 'redux/pages/patient-case/slices/closedSlice';
import usePermissions from 'utils/hooks/usePermissions';
import PatientCaseGrid from '../Components/PatientCaseGrid';
import { reportFormatter } from '../Components/ReportFormatter';
import { useSelector } from 'react-redux';
import { isReviewer } from '../../../utils/helpers';

const columns = closed;

const Closed = (props) => {
  const { can } = usePermissions();
  const { roleCode } = useSelector((state) => {
    return state.app.auth.user;
  });

  return (
    <PatientCaseGrid
      variant="success"
      columns={columns}
      slice={closedSlice}
      clickable={!isReviewer(roleCode)}
      customActions={(rowData) => (can('MODIFY_CASES') ? reportFormatter(null, rowData, roleCode) : null)}
      actionsTitle="Report"
      {...props}
    />
  );
};

export default Closed;
