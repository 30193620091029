import React from 'react';
import connect from "react-redux/es/connect/connect";
import {
    closeCreateModal,
    closeDeleteModal,
    closeSubmitModal,
    openCreateModal,
    openSubmitModal,
    openDeleteModal
} from "../../../redux/action-creators/users";
import PropTypes from "prop-types";
import {getCaseFiles, saveMoveToStage, showNotValidMessage, clearFilesError} from '../../../redux/actions/cases/caseActions'
import {withRouter} from "react-router-dom";
import menuActions from "../../../redux/action-creators/menu";
import DeleteModal from "../../DeleteModal";
import SubmitModal from "../../SubmitModal";
import Modal from "react-modal";
import ClickOutsideListener from "../../../components/ClickOutsideListener";
import {isClient} from "../../../utils/helpers";

class CaseSubmitTo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            submitTitle: '',
            company: ''
        };

        this.clickSubmit = this.clickSubmit.bind(this);
        this.clickMove = this.clickMove.bind(this);
    }

    componentDidMount() {
        Modal['setAppElement']('body');
        this.props.handleCloseModals();
        this.props.closeSubmitToMenu();
        this.props.clearFilesUploadError();
    }

    clickMove(e) {
        this.setState({submitTitle: 'Move to...'});
        if (e.target) {
            let savedStage = e.target.getAttribute('id').replace('_MOVE', '');
            let stageCode = this.props.case.currentStage.code.substr(0, 3) + savedStage.toUpperCase();
            if (stageCode) {
                this.props.saveMoveToStage(savedStage);
                this.props.handleOpenDeleteModal();
            }
        }
    }

    clickSubmit(e) {
        this.setState({submitTitle: 'Submit to...'});
        if (e.target) {
            if (this.props.isValid) {
                let savedStage = e.target.getAttribute('id');
                let stageCode = this.props.case.currentStage.code.substr(0, 3) + savedStage.toUpperCase();
                if (stageCode) {
                    this.props.saveMoveToStage(savedStage);
                    this.props.handleOpenSubmitModal();
                }
            } else {
                this.props.showNotValidMessage(true);
                this.props.closeSubmitToMenu();
            }
        }
    }

    render() {
        let isDraft = false;
        let isDispatch = false;
        let isReview = false;
        let isQa = false;
        let isClosed = false;
        if (this.props.case.currentStage && this.props.case.currentStage.code) {
            isDraft = this.props.case.currentStage.code.indexOf('DRAFT') !== -1;
            isDispatch = this.props.case.currentStage.code.indexOf('DISPATCH') !== -1;
            isReview = this.props.case.currentStage.code.indexOf('REVIEW') !== -1;
            isQa = this.props.case.currentStage.code.indexOf('QA') !== -1;
            isClosed = this.props.case.currentStage.code.indexOf('CLOSED') !== -1;
        }

        let isNotClickAble = false;
        let submitHint = '';
        let selectedCase = this.props.case;
        let currentStageCode = selectedCase.currentStage ? selectedCase.currentStage.code
            : (selectedCase.currentStageCode ? selectedCase.currentStageCode : undefined);

        // CLIENT can submit only from DRAFT
        if (isClient(this.props.currentUser.roleCode)) {
            isNotClickAble = currentStageCode.indexOf('DRAFT') === -1;
            if (isNotClickAble) {
                submitHint = 'Client cannot submit';
            }
        }

        // check for CLOSED
        if (!isNotClickAble) {
            isNotClickAble = currentStageCode.indexOf('CLOSED') !== -1;
            if (isNotClickAble) {
                submitHint = 'Closed Case cannot submit';
            }
        }

        // check for EXECUTOR
        // if (!isNotClickAble) {
        //     isNotClickAble = (selectedCase.currentStage.code.indexOf('DRAFT') === -1
        //         && selectedCase.ownerDraft == null
        //     );
        //     if (isNotClickAble) {
        //         submitHint = 'Case cannot submit without Owner';
        //     }
        // }
        const isVisible = currentStageCode.indexOf('CLOSED') === -1;
        const cases = this.props.case;
        const context = this.context;

        return (
            <span id="submit-to" style={{display: isVisible ? 'inline-block' : 'none'}}>
                <ClickOutsideListener onClickOutside={this.props.closeSubmitToMenu}>
                    <div className={`dropdown btn-group tasks-menu ${this.props.submitToOpen ? "open" : ""} margin-left-5`}>
                        <button type="button" data-toggle="dropdown" className={"btn btn-default dropdown-toggle"}
                                onClick={() => {
                                    this.props.toggleSubmitToMenu()
                                }}
                                title='Submit to...'>
                            <i className={`fa fa-flip-horizontal fa-reply-all`}/>
                        </button>
                        <ul className="dropdown-menu" id="submitToMenu">
                            <li><h4 className="box-title text-blue"
                                    style={{marginLeft: '10px'}}><strong>Submit To</strong></h4></li>
                            {
                                (cases && cases['transitions']) ? cases['transitions'].sort((a, b) => {
                                    let curStage = cases.currentStage.code;
                                    curStage += '_TO_' + curStage.substr(0, 3);
                                    let ar1 = [curStage + 'DRAFT',
                                        curStage + 'DISPATCH',
                                        curStage + 'REVIEW',
                                        curStage + 'QA',
                                        curStage + 'CLOSED'];
                                    return ar1.indexOf(a.code) < ar1.indexOf(b.code) ? -1 : 1;
                                }).map((item, i) => (
                                    <li key={'submit-' + i}
                                        style={{fontColor: isNotClickAble && item.code.substr(-6) !== 'CLOSED' ? 'yellow' : 'black'}}
                                        title={submitHint && item.code.substr(-6) !== 'CLOSED' ? submitHint : 'Submit'}>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a id={item.code}
                                           onClick={isNotClickAble && item.code.substr(-6) !== 'CLOSED' ? null : this.clickSubmit}>
                                            {item['destStageName']}
                                        </a>
                                    </li>
                                )) : ''
                            }
                            <li className="divider"> </li>
                            <li><h4 className="box-title text-blue"
                                    style={{marginLeft: '10px'}}><strong>Move To</strong></h4></li>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <li style={{display: (isDraft ? 'none' : 'block')}}><a id="draft_MOVE"
                                                                                   onClick={this.clickMove}>{context.translate('common.case.draft')}</a></li>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <li style={{display: (isDispatch ? 'none' : 'block')}}><a id="dispatch_MOVE"
                                                                                      onClick={this.clickMove}>{context.translate('common.case.dispatch')}</a></li>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <li style={{display: (isReview ? 'none' : 'block')}}><a id="review_MOVE"
                                                                                    onClick={this.clickMove}>{context.translate('common.case.review')}</a></li>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <li style={{display: (isQa ? 'none' : 'block')}}><a id="qa_MOVE"
                                                                                onClick={this.clickMove}>{context.translate('common.case.qa')}</a></li>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <li style={{display: (isClosed ? 'none' : 'block')}}><a id="closed_MOVE"
                                                                                    onClick={this.clickMove}>{context.translate('common.case.closed')}</a></li>
                        </ul>
                    </div>
                </ClickOutsideListener>
                <DeleteModal
                    userId={this.props.case.code}
                    operation='move_to'
                    editTarget='case'
                    title='Move To'
                />
                <SubmitModal
                    userId={this.props.case.code}
                    operation='submit_to'
                    editTarget='case'
                    title='Submit To'
                />
            </span>
        )
    }

    static contextTypes = {
        translate: PropTypes.func
    };
}

const mapState = (state) => {
    return {
        case: state.app.cases.editedCase,
        isValid: state.app.cases.isAllValid,
        submitToOpen: state.app.menu.submitToOpen,
        currentPath: state.router.location.pathname,
        currentUser: state.app.auth.user,
        cases: state.app.cases.all,
        isOpen: state.app.users.createModalOpen,
        companies: state.app.companies.data
    }
};

const mapDispatchToProps = dispatch => {
    return {
        modalOpen: () => dispatch(openCreateModal()),
        modalClose: () => dispatch(closeCreateModal()),
        toggleSubmitToMenu: () => dispatch(menuActions.toggleSubmitToMenu()),
        closeSubmitToMenu: () => dispatch(menuActions.closeSubmitToMenu()),
        handleOpenDeleteModal: () => dispatch(openDeleteModal()),
        handleOpenSubmitModal: () => dispatch(openSubmitModal()),
        saveMoveToStage: (stage) => dispatch(saveMoveToStage(stage)),
        handleCloseModals: () => {
            dispatch(closeCreateModal());
            dispatch(closeSubmitModal());
            dispatch(closeDeleteModal());
        },
        showNotValidMessage: (data) => dispatch(showNotValidMessage(data)),
        getCaseFiles: (code) => dispatch(getCaseFiles(code)),
        clearFilesUploadError: () => dispatch(clearFilesError())
    }
};

export default connect(mapState, mapDispatchToProps)(withRouter(CaseSubmitTo));
