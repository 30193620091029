import { isObjectsEqual } from '../../../utils/helpers'

const defaultState = {
    loading: false,
    loadedCount: 0,
    editSCSystemSuccess: false,
    data: [],
    error: '',
    editedSCSystem: {
        name: '',
        code: null,
        active: true
    },
    originalSCSystem: {
        name: '',
        code: null,
        active: true
    },
    scSystemValid: undefined,
    isProfileChanged: false,
    showValidMessages: false,
    lastLoadedTime: 0,
}

const scSystem = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_SC_SYSTEM_LOADING':
        case 'FETCH_EDIT_SC_SYSTEM_LOADING':
            return { ...state, loading: true, editSCSystemSuccess: false, isProfileChanged: false, showValidMessages: false, }
        case 'FETCH_SC_SYSTEM_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            })
        case 'FETCH_CREATE_SC_SYSTEM_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                data: [...state.data, action.payload]
            })
        case 'FETCH_GET_SC_SYSTEM_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                scSystemValid: true,
                editedSCSystem: action.payload,
                originalSCSystem: action.payload
            }
        case 'FETCH_EDIT_SC_SYSTEM_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editSCSystemSuccess: true,
                originalSCSystem: action.payload,
                data: state.data.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            })

        case 'SC_SYSTEM_EDIT_FIELD':
            return Object.assign({}, state, {
                editedSCSystem: Object.assign({}, state.editedSCSystem, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedSCSystem, action.payload), state.originalSCSystem)

            })
        case 'VALIDATE_SC_SYSTEM_FIELD':
            return Object.assign({}, state, {
                scSystemValid: action.payload
            })
        case 'FETCH_SC_SYSTEM_ERROR':
            return { ...state, error: action.payload, loading: false }
        case 'SHOW_SC_SYSTEM_VALIDATION':
            return Object.assign({}, state, {
                showValidMessages: true
            })

        case 'FETCH_SC_SYSTEM_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            }
        case 'FILL_SC_SYSTEM_LOCAL_STORAGE': {
            let items = {};
            for (let i = 0; i < state.data.length; i++) {
                items[ state.data[ i ].code ] = state.data[ i ].name;
            }
            localStorage.setItem('list_medical_code', JSON.stringify(items));
            return state;
        }
        case 'FETCH_SC_SYSTEM_LOADED_COUNT':
            return { ...state, loadedCount: action.payload }

        default:
    }
    return state;
}

export default scSystem;
