import { transformResponseToTableData } from '../../../components/Datatable/dataTableUtils';
import { isNull, negate } from 'lodash';

const formatSpecialties = (specialtyList) => {
    return specialtyList.filter(negate(isNull)).join(', ');
};

const formatHourlyRateInEuro = (hourlyRateInEuro) =>{
    let hourlyString = hourlyRateInEuro.toString();
    hourlyString += ' EUR';
    return hourlyString;
}

export const transformReviewers = transformResponseToTableData((data) => {
    return {
        data: data.map(({ code, firstName, lastName, specialties: specialtyList, email, phoneNumber, country, currency, hourlyRateInEuro, active }) => ({
            code,
            firstName,
            lastName,
            specialties: formatSpecialties(specialtyList),
            email,
            phoneNumber,
            country,
            currency,
            hourlyRateInEuro: formatHourlyRateInEuro(hourlyRateInEuro),
            status: active ? 'ACTIVE' : 'INACTIVE',
        })),
    };
});
