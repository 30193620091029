import React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "utils/simple-react-validator";
import FormField from '../../../components/FormField/index'
import { countryEditField, editCountry, getCountry } from '../../../redux/actions/countryActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Box from "../../../components/Box";
import BoxHeader from "../../../components/BoxHeader";
import BoxBody from "../../../components/BoxBody";
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';

class CountryEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.country;
        this.setState({ body_parts_required: false });

        this.validator = new SimpleReactValidator();

        this.Submit = this.Submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.onChangeBodyPartCodes = this.onChangeBodyPartCodes.bind(this);
    }

    componentDidMount() {
        this.props.getCountry(this.props.match.params.id)
    }

    onChangeCheckBox(e) {
        this.props.countryEditField([e.target.name], e.target.checked)
    }

    Submit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            const data = {
                ...this.state,
            };
            this.props.editCountry(data);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onChange(e) {
        this.props.countryEditField(e.target.name, e.target.value)
    }

    onChangeBodyPartCodes(bodyPartCodes) {
        this.props.countryEditField('bodyPartCodes', bodyPartCodes)
    }

    onSave = () => {
        return this.props.editCountry({
            ...this.props.country,
            active: this.props.country.active,
            code: this.props.country.code
        })
    };

    render() {
        let showSuccess = this.props.editCountrySuccess ? 'block' : 'none';
        let showError = this.props.error ? 'block' : 'none';
        const { isProfileChanged, isEditable } = this.props;

        return (
            <div className="content" style={{ marginTop: '10px' }}>
                <PreventTransitionPrompt
                    when={isProfileChanged}
                    message='MyCustomDialogComponent'
                    isFormValid={true}
                    saveChanges={this.onSave}
                    cancelAndDiscard={() => this.props.getCountry(this.props.match.params.id)}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <div className="alert alert-success" style={{ display: showSuccess }}>
                    <strong>{this.context.translate('common.message.country_success')}</strong>
                </div>
                <div className="alert alert-danger" style={{ display: showError }}>
                    {this.props.error}
                </div>
                <Box color="green" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`${this.context.translate('common.form.country')} - ${this.props.country.name}`}
                               color="green"
                               icon="fa-list-alt" classes="box-profile-first-row-height-show"/>
                    <BoxBody>
                        <form style={{ overflow: 'visible' }}
                              className='modal-content custom-modal-content'
                              onSubmit={this.Submit}>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        label={this.context.translate('common.form.name')}
                                        required
                                        disabled={true}
                                        valids="required"
                                        validator={this.validator}
                                        value={this.props.country.name}
                                        onChange={this.onChange}
                                        name='name'
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        label={this.context.translate('common.form.code')}
                                        required
                                        disabled={true}
                                        valids="required"
                                        validator={this.validator}
                                        value={this.props.country.code}
                                        onChange={this.onChange}
                                        name='code'
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        type="checkbox"
                                        title={this.context.translate('common.form.status')}
                                        label={this.context.translate('common.form.active')}
                                        name='active'
                                        value={this.props.country.active}
                                        checked={this.props.country.active}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </form>
                    </BoxBody>
                </Box>
            </div>
        );
    }
}

CountryEditPage.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    isLoading: state.app.countries.loading,
    isEditable: state.app.users.my_permissions.includes('MODIFY_COUNTRIES'),
    error: state.app.countries.error,
    editCountrySuccess: state.app.countries.editCountrySuccess,
    isProfileChanged: state.app.countries.isProfileChanged,
    bodyParts: state.app.bodyParts.data
        .map(item => ({
            value: item.code,
            label: item.name
        })),
    country: state.app.countries.editedCountry
});

const mapDispatchToProps = dispatch => ({
    getCountry: code => dispatch(getCountry(code)),
    countryEditField: (field, value) => dispatch(countryEditField(field, value)),
    editCountry: (country) => dispatch(editCountry(country))
});

export default connect(mapStateToProps, mapDispatchToProps)(CountryEditPage)
