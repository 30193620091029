import React from 'react';
// import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import shortId from 'shortid';
import Modal from 'react-modal';
import { showValidation } from '../redux/actions/BasicEntityActions';
import PreventTransitionPromptNew from './PreventTransitionPromptNew';

// eslint-disable-next-line no-unused-vars
class PreventTransitionPrompt extends React.Component {
    state = { open: false }; // dialog state

    constructor(props) {
        super(props);

        // Define a unique global symbol to store
        // a dialog trigger reference accessible via
        // a string key. Use cuid for unique ID.
        this.__trigger = Symbol.for(`__PreventTransitionPrompt_${shortId.generate()}`);
        this.location = props.history.location.pathname;
    }

    /*
     * Attach global dialog trigger for this component
     * instance to our Symbol trigger
     */
    componentDidMount() {
        window[this.__trigger] = this.show;
    }

    /*
     * Ensure we clean up and remove the reference
     * from the global object
     */
    componentWillUnmount() {
        delete window[this.__trigger];
    }

    render() {
        const { when } = this.props;
        // const {ignore} = this.state;

        return (
            <React.Fragment>
                {/* React Router prompt, callback will return true to allow transition or dialog key to prevent */}
                <Prompt when={when} message={this.handleTransition} />
                <Modal
                    isOpen={this.state.open}
                    contentLabel="onRequestClose Example"
                    onRequestClose={this.handleClose}
                    className="custom-modal modal-dialog"
                    overlayClassName="modal-overlay"
                >
                    <form className="modal-content custom-modal-content">
                        <h3 className="text-center">Do you want to save your changes ?</h3>
                        <div className="text-center">
                            <button
                                className="btn btn-grey margin-right-5 margin-top-5"
                                onClick={() => {
                                    if (this.props.isFormValid) {
                                        this.props
                                            .saveChanges()
                                            .then(() => this.state.callback(true))
                                            .catch(() => {
                                                this.handleClose();
                                            });
                                    } else {
                                        this.props.showValidation(this.props.entity);
                                        this.handleClose();
                                    }
                                }}
                            >
                                Save
                            </button>
                            <button className="btn btn-grey margin-left-5 margin-right-5 margin-top-5" onClick={() => this.state.callback(true)}>
                                Don’t Save
                            </button>
                            <button
                                className="btn btn-grey margin-left-5 margin-top-5"
                                onClick={() => {
                                    this.handleClose();
                                    this.props.cancelAndDiscard();
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </Modal>
                {/* Example MUI dialog to show when open. You could make this
            totally customizable or a complete one-off. */}
            </React.Fragment>
        );
    }

    show = (allowTransitionCallback) => {
        this.setState({
            open: true,
            callback: allowTransitionCallback,
        });
    };

    callback = (e) => {
        // closes the dialog
        e.preventDefault();
        console.log(e);
        // allowTransitionCallback(true)
    };

    handleClose = () => {
        //This is need because if user click browser back ro forward
        //buttons location in address bar is changed
        //but your still stay on this page, it prevent not supposed behavior
        if (window.location.hash.slice(1) !== this.location) {
            window.history.pushState('object or string', 'Title', '#' + this.location);
        }

        this.setState({ open: false });
    };

    /*
     * Handles the Router transition. Returns true if allowed
     * or the dialog trigger key to enable the dialog.
     *
     * This would be a good candidate to allow optionally
     * being passed as a callback prop to let
     * caller decide if transition is allowed.
     */
    handleTransition = (location) => {
        // example: allow transitions to /two
        if (location.pathname === '/two') {
            return true;
        }

        return Symbol.keyFor(this.__trigger);
    };
}

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({
    showValidation: (entity) => dispatch(showValidation(entity)),
});

// export default connect(null, mapDispatchToProps)(withRouter(PreventTransitionPrompt));

export default PreventTransitionPromptNew;
