import {isObjectsEqual} from '../../../utils/helpers'
import { uniqBy } from "lodash";

const DEBUG = false;
const debug = (...message) => { DEBUG && console.debug(...message) };

const baseFacility = {
    name: '',
    code: null,
    active: true,
    notes: '',
    phoneNumber: '',
    postalCode: '',
    stateCode: '',
    street1: '',
    street2: '',
    street3: '',
}

const defaultState = {
    loading: false,
    loading_type: false,
    loadedCount: 0,
    loadedCount_type: 0,
    editFacilitySuccess: false,
    editFacilityTypeSuccess: false,
    data: [],
    types: [],
    error: '',
    editedFacility: baseFacility,
    originalFacility: baseFacility,
    editedFacilityType: {
        name: '',
        code: null,
        active: true,
        selectedPhysicianCodes: [],
        selectedCompanyCodes: [],
    },
    originalFacilityType: {
        name: '',
        code: null,
        active: true,
        selectedPhysicianCodes: [],
        selectedCompanyCodes: [],
    },
    currentTab: '1',
    listCurrentTab: '1',
    facilityValid: false,
    isFacilityChanged: false,
    facilityValidMessages: false,
    facilityTypeValidMessages: false,
    isFacilityTypeChanged: false,
    lastLoadedTime: 0,
    lastLoadedTypeTime: 0,
}

const companies = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_FACILITY_LOADING':
            return {
                ...state,
                loading: true,
                editFacilitySuccess: false,
                editFacilityTypeSuccess: false,
                isFacilityChanged: false,
                isFacilityTypeChanged: false,
                facilityValidMessages: false,
                facilityTypeValidMessages: false
            }
        case 'FETCH_FACILITY_TYPE_LOADING':
            return {
                ...state,
                loading_type: true,
                editFacilitySuccess: false,
                editFacilityTypeSuccess: false,
                isFacilityChanged: false,
                isFacilityTypeChanged: false,
                facilityValidMessages: false,
                facilityTypeValidMessages: false
            }
        case 'FETCH_FACILITY_PROFILE_LOADING':
            return {...state, originalFacility: baseFacility, editedFacility: baseFacility, isFacilityChanged: false}
        case 'FETCH_FACILITY_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            })
        case 'FETCH_FACILITY_TYPE_SUCCESS':
            return Object.assign({}, state, {
                loading_type: false,
                types: action.payload,
                error: null,
                lastLoadedTypeTime: Date.now(),
            })

        case 'FETCH_CREATE_FACILITY_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                data: [...state.data, action.payload]
            })

        case 'FETCH_GET_FACILITY_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                currentTab: '1',
                facilityValid: true,
                editedFacility: action.payload,
                originalFacility: action.payload,
                selectedPhysicianCodes: action.payload.physicianCodes,
                selectedCompanyCodes: action.payload.companyCodes
            }

        case 'FETCH_EDIT_FACILITY_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editFacilitySuccess: true,
                originalFacility: action.payload,
                data: state.data.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            })
        case 'FACILITY_EDIT_FIELD':
            return Object.assign({}, state, {
                editedFacility: Object.assign({}, state.editedFacility, action.payload),
                isFacilityChanged: !isObjectsEqual(Object.assign({}, state.editedFacility, action.payload), state.originalFacility)
            })

        case 'FETCH_CREATE_FACILITY_TYPE_SUCCESS':
            return Object.assign({}, state, {
                loading_type: false,
                error: null,
                types: [...state.types, action.payload]
            })

        case 'FETCH_GET_FACILITY_TYPE_SUCCESS':
            return {
                ...state,
                error: null,
                loading_type: false,
                editedFacilityType: action.payload,
                originalFacilityType: action.payload,
            }

        case 'FETCH_EDIT_FACILITY_TYPE_SUCCESS':
            return Object.assign({}, state, {
                loading_type: false,
                error: null,
                editFacilityTypeSuccess: true,
                originalFacilityType: action.payload,
                types: state.data.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            })
        case 'FACILITY_TYPE_EDIT_FIELD':
            return Object.assign({}, state, {
                editedFacilityType: Object.assign({}, state.editedFacilityType, action.payload),
                isFacilityTypeChanged: !isObjectsEqual(Object.assign({}, state.editedFacilityType, action.payload), state.originalFacilityType)
            })

        case 'SET_FACILITY_CURRENT_TAB':
            return Object.assign({}, state, {
                currentTab: action.payload
            })

        case 'SET_FACILITY_LIST_CURRENT_TAB':
            return Object.assign({}, state, {
                listCurrentTab: action.payload
            })

        case 'VALIDATE_FACILITY_FIELD':
            return Object.assign({}, state, {
                facilityValid: action.payload
            })
        case 'FETCH_FACILITY_ERROR':
            return {...state, error: action.payload, loading: false}

        case 'SHOW_FACILITY_VALIDATION':
            return Object.assign({}, state, {
                facilityValidMessages: true
            })

        case 'SHOW_FACILITY_TYPE_VALIDATION':
            return Object.assign({}, state, {
                facilityTypeValidMessages: true
            })

        case 'FETCH_FACILITY_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            }
        case 'FILL_FACILITY_LOCAL_STORAGE': {
            // console.log('**AAA***', state.data);
            let items = {};
            for (let i = 0; i < state.data.length; i += 1) {
                items[state.data[i].code] = state.data[i].name;
            }
            localStorage.setItem('list_facility', JSON.stringify(items));
            return state;
        }
        case 'FETCH_FACILITY_LOADED_COUNT':
            return {...state, loadedCount: action.payload}

        case 'FETCH_FACILITY_TYPE_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading_type: false,
                types: [...state.types, action.payload]
            }
        case 'FILL_FACILITY_TYPE_LOCAL_STORAGE': {
            let items2 = {};
            for (let i = 0; i < state.types.length; i += 1) {
                items2[state.types[i].code] = state.types[i].name;
            }
            localStorage.setItem('list_facility_type', JSON.stringify(items2));
            return state;
        }
        case 'FETCH_FACILITY_TYPE_LOADED_COUNT':
            return {...state, loadedCount_type: action.payload}

        case 'FETCH_FACILITY_MINIMAL_LOADING': {
            debug(action.type);
            return { ...state, loading: true };
        }

        case 'FETCH_FACILITY_MINIMAL_SUCCESS': {
            debug(action.type);
            debug(action.payload);
            return { ...state, loading: false, data: action.payload, error: null };
        }

        case 'FETCH_FACILITY_MINIMAL_ERROR': {
            debug(action.type);
            return { ...state, error: action.payload, loading: false };
        }

        case 'FILL_SELECTED_FACILITY_DATA': {
            const indexToExtend = state.data.findIndex(({code}) => code === action.payload.code);
            let newData = [...state.data];
            newData[indexToExtend] = action.payload;

            return {...state, data: uniqBy(newData, 'code')}
        }

        default:
            return state;
    }
}

export default companies
