
import api from "../../utils/api";
import apiFile from "../../utils/api";
import apiGetFile from "../../utils/api";
import {Statuses} from "./statuses";
import {setCurrentUser} from "./authActions";
import history from '../history'

export const saveNewPassword = (data) => (dispatch) => {
    dispatch({
        type: 'FETCH_PASSWORD_CHANGE_LOADING',
    })

    return api.post('user/change/password', data)
        .then(() => {
            dispatch({
                type: 'FETCH_PASSWORD_CHANGE_SUCCESS',
                payload: Statuses['success_change_password']
            })
            Object.keys(data).forEach(field => dispatch(editField(field, '')))
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PASSWORD_CHANGE_ERROR',
                    payload: ( error.response.status === 400 ?
                            Statuses['old_password_not_correct'] :
                            Statuses[error.response.status]
                    )
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_PASSWORD_CHANGE_ERROR',
                    payload: error.message
                });
            }
        })
}

export const getMyProfile = () => (dispatch) => {
    dispatch({
        type: 'FETCH_PROFILE_LOADING',
    })

    return api.get('user/get/mine')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_PROFILE_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PROFILE_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_PROFILE_ERROR',
                    payload: error.message
                });
            }
        })
}

export const getMySignature = (code) => (dispatch) => {
    dispatch({
        type: 'FETCH_PROFILE_SIGNATURE_LOADING',
    })

    apiGetFile.get('user/signature?code=' + code)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_PROFILE_SIGNATURE_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PROFILE_SIGNATURE_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_PROFILE_SIGNATURE_ERROR',
                    payload: error.message
                });
            }
        })
}

export const saveProfile = (data, closeAfterSave) => (dispatch) => {
    dispatch({
        type: 'FETCH_PROFILE_LOADING',
    })

    return api.post('user/update/mine', data)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_EDIT_PROFILE_SUCCESS',
                payload: data
            });
            dispatch(setCurrentUser(data));
            closeAfterSave && history.goBack();
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PROFILE_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_PROFILE_ERROR',
                    payload: error.message
                });
            }
        })
}

export const saveSignature = (data) => (dispatch) => {
    dispatch({
        type: 'FETCH_PROFILE_LOADING',
    })

    let formData = new FormData();
    formData.append('code', data.code);
    formData.append('signature', data.file);

    return apiFile.post('user/signature?code=' + data.code, formData)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_PROFILE_SUCCESS',
                payload: data
            });
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PROFILE_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_PROFILE_ERROR',
                    payload: error.message
                });
            }
        })
}

export const editField = (field, value) => ({
    type: 'PROFILE_EDIT_FIELD',
    payload:{
        [field]: value
    }
})

export const isAllValid = valid => {
    return {
        type:'VALIDATE_PROFILE_FIELD',
        payload: valid
    }
}

export const isPasswordValid = valid => {
    return {
        type:'VALIDATE_PASSWORD_FIELD',
        payload: valid
    }
}

export const setSuccess = (value) => ({
    type: 'PROFILE_SUCCESS_MESSAGE',
    payload: value
})
