import React from 'react';
import FormField from '../FormField'
import { getSelectFieldValue } from '../../utils/helpers'
import PropTypes from "prop-types";

export default class AddressInfoSection extends React.Component {
    static propTypes = {
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        postalCode: PropTypes.string,
        countryCode: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        stateCode: PropTypes.object,
        isEditable: PropTypes.bool,
        onChange: PropTypes.func,
        onChangeState: PropTypes.func,
        onChangeCountry: PropTypes.func,
    }
    static defaultProps = {
        isEditable: true,
    }

    render() {
        const { address1, address2, city, countryCode, stateCode, postalCode, isEditable, onChange, onChangeState, onChangeCountry } = this.props;

        return (
            <div className={`form-group ${!this.props[ 'noBorder' ] && "bordered"}`}>
                <div className="form-group ">
                    <div className="row">
                        <FormField
                            md="12"
                            type='label_field'
                            label="Address Information"
                            className='margin-bottom-15 fontWeight800'
                            style={{ fontSize: '1.1em' }}
                        />
                        <FormField
                            md="4"
                            label="Address 1"
                            className="form-control"
                            name="address1"
                            value={address1}
                            onChange={onChange}
                            disabled={!isEditable}
                        />
                        <FormField
                            md="4"
                            label="Address 2"
                            className="form-control"
                            name="address2"
                            value={address2}
                            onChange={onChange}
                            disabled={!isEditable}
                        />
                    </div>
                </div>
                <div className="form-group ">
                    <div className="row">
                        <FormField
                            md="4"
                            label="City"
                            className="form-control"
                            name="city"
                            value={city}
                            onChange={onChange}
                            disabled={!isEditable}
                        />
                        <FormField
                            md="4"
                            xs="6"
                            type='REF_STATE'
                            className='text-bg'
                            label='State'
                            name='stateCode'
                            disabled={getSelectFieldValue(countryCode) !== 'US' || !isEditable}
                            value={stateCode}
                            onChange={onChangeState}
                        />
                    </div>
                </div>
                <div className="form-group ">
                    <div className="row">
                        <FormField
                            md="4"
                            xs="6"
                            label="Postal Code"
                            className="form-control"
                            name="postalCode"
                            value={postalCode}
                            onChange={onChange}
                            disabled={!isEditable}
                        />
                        {this.props.validator && this.props.dispatchValidStatus ?
                            <FormField
                                md="4"
                                type="REF_COUNTRY"
                                label="Country"
                                id="Country"
                                valids="required"
                                required
                                validator={this.props.validator}
                                className="text-bg"
                                name="countryCode"
                                value={countryCode}
                                onChange={onChangeCountry}
                                onBlur={this.props.dispatchValidStatus}
                                disabled={!isEditable}
                            /> :
                            <FormField
                                md="4"
                                type="REF_COUNTRY"
                                label="Country"
                                className="text-bg"
                                name="countryCode"
                                value={countryCode}
                                onChange={onChangeCountry}
                                disabled={!isEditable}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}
