import api from "../../../utils/api";
import {Statuses} from "../statuses";
import history from '../../history'
import { isEntityLoaded } from "../../../utils/helpers";

const isAvailableInCache = (caseTypes) =>
    isEntityLoaded(caseTypes) && caseTypes.types && caseTypes.types.length > 0;

export const getAllCaseTypes = (caseTypes) => (dispatch) => {
    if (isAvailableInCache(caseTypes)) {
        return;
    }
    dispatch({
        type: 'FETCH_CASE_TYPES_LOADING',
    })
    return api.get('case/type/all')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_CASE_TYPES_SUCCESS',
                payload: data
            });
            dispatch({
                type: 'FILL_CASE_TYPE_LOCAL_STORAGE'
            });

            return;
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_CASE_TYPE_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
                dispatch({
                    type: 'FETCH_CASE_TYPE_ERROR',
                    payload: Statuses[0]
                })
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_CASE_TYPE_ERROR',
                    payload: error.message
                });
            }

            return Promise.reject(error);
        })
}

export const getAllCaseTypesIfEmpty = () => (dispatch, getState) => {
    let data = getState().app.case_types.types;
    if (data.length === 0) {
        dispatch(getAllCaseTypes());
    }
}

export const getAllCaseLevels = () => (dispatch) => {
    dispatch({
        type: 'FETCH_CASE_LEVEL_LOADING',
    })

    api.get('case_level/all')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_CASE_LEVEL_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'FILL_CASE_LEVEL_LOCAL_STORAGE'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_CASE_LEVEL_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
                dispatch({
                    type: 'FETCH_CASE_LEVEL_ERROR',
                    payload: Statuses[0]
                })
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_CASE_LEVEL_ERROR',
                    payload: error.message
                });
            }
        })

}

export const getAllCaseLevelsIfEmpty = () => (dispatch, getState) => {
    let data = getState().app.case_types.levels;
    if (data.length === 0) {
        dispatch(getAllCaseLevels());
    }
}

export const createNewCaseType = (caseType) => (dispatch) => {
    dispatch({
        type: 'FETCH_CASE_TYPES_LOADING',
    })

    api.post('case/type/create', caseType)
        .then((res) => {
            console.log(res);
            dispatch({
                type: 'FETCH_CREATE_CASE_TYPE_SUCCESS',
                payload: caseType
            })
            dispatch({
                type: 'CLOSE_CREATE_MODAL'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_CASE_TYPE_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_CASE_TYPE_ERROR',
                    payload: Statuses[error.request.status]
                });
            }
        })

}


export const editCaseType = (data, closeAfterSave) => dispatch => {
    dispatch({
        type: 'FETCH_EDIT_CASE_TYPE_LOADING',
    })

    return api.post('case/type/update', data)
        .then(({config}) => {
            dispatch({
                type: 'FETCH_EDIT_CASE_TYPE_SUCCESS',
                payload: JSON.parse(config.data)
            })
            closeAfterSave && history.goBack()
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_EDIT_CASE_TYPE_ERROR',
                payload: Statuses[err.response] || Statuses[0]
            })
        })
}


export const editField = (field, value) => {
    console.log(field);
    console.log(value);
    return {
        type: 'EDIT_CASE_FIELD',
        payload: {
            [field]: value
        }
    }
}

export const caseTypeEditField = (field, value) => ({
    type: 'CASE_TYPE_EDIT_FIELD',
    payload: {
        [field]: value
    }
})

export const isCaseTypeValid = valid => {
    return {
        type: 'VALIDATE_CASE_TYPE_FIELD',
        payload: valid
    }
}
