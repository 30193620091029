import React from 'react';
import {connect} from 'react-redux';
import LoadingIndicator from '../../components/LoadingIndicator';
import RemoteGrid from "../../components/Grid/RemoteGrid";
import {entityEditField} from "../../redux/actions/BasicEntityActions";

class ControlTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showActivateModal: false,
            showEditModal: false,
            clientId: ''
        };
    }

    handleOpenEdit = (clientId) => {
        this.props.history.push(`/clients/profile/${clientId}`)
    };

    render() {
        const {companies, currencies} = this.props;

        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true
            }, {
                'name': 'fullName',
                'title': 'Full Name',
                'sort': true,
                'width': '200',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'shortName',
                'title': 'Short Name',
                'sort': true,
                'width': '100',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'address',
                'title': 'Address',
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'vatId',
                'title': 'VAT ID',
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'currencyCode',
                'title': 'Currency',
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'SelectFilter', options: currencies}
            },
            // {
            //     'name': 'logoFile',
            //     'title': 'Logo',
            //     'dataFormat': (cell) {
            //         return cell !== '' ? (<img style={{width:100}} src={cell} alt=""/>) : '';
            //     },
            //     'width': '150'
            // },
            {
                'name': 'active',
                'title': 'Status',
                'dataFormat': (cell, row, enumObject) => {
                    return enumObject[cell]
                },
                'sort': true,
                'formatExtraData': {
                    'false': 'Inactive',
                    'true': 'Active'
                },
                'width': '100',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': 'Inactive',
                        'true': 'Active'
                    }
                }
            }
        ];
        const {sorting, pagination} = this.props.gridConfiguration;

        return (
            <div className="container-fluid table-responsive">

                <RemoteGrid
                    color="blue"
                    entity="billing_clients"
                    title={`${this.props.groupName}`}
                    data={companies}
                    total={companies.length}
                    columns={columns}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.handleOpenEdit}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    companies: state.app.billing_group.editedBillingGroup.companies,
    currencies: state.app.currencies.data
        .map(currency => (
            [currency.code]
        )),
    loading: state.app.users.loading,
    groupName: state.app.billing_group.editedBillingGroup.name,
    gridConfiguration: state.app.grid.billingClients,

});

const mapDispatchToProps = dispatch => ({
    editField: (field, value) => dispatch(entityEditField(field, value, 'billing_group')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlTable)
