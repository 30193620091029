import React from 'react';
import { connect } from 'react-redux';
import Box from '../../../components/Box';
import BoxHeader from '../../../components/BoxHeader';
import BoxBody from '../../../components/BoxBody';
import BoxFooter from '../../../components/BoxFooter';
import FormField from '../../../components/FormField';
import { caseTypeEditField, editCaseType, isCaseTypeValid } from '../../../redux/actions/cases/caseTypesActions';
import { getOne } from '../../../redux/actions/BasicEntityActions';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import SystemMessage from '../../../components/SystemMessage';
import FormValidateWrapper from '../../../containers/FormValidateWrapper';

class CaseTypeEditPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: { value: 'EN', label: 'English' },
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    }

    componentDidMount() {
        this.props.validator.showMessages();
        this.props.getCaseType(this.props.match.params.id).then(() => {
            this.props.dispatchValidState();
        });
    }

    onChangeCheckBox(e) {
        this.props.caseTypeEditField([e.target.name], e.target.checked);
    }

    onChange(e) {
        console.log(e.target.name);
        this.props.caseTypeEditField(e.target.name, e.target.value);
    }

    onChangeName = (e) => {
        const { value } = e.target;
        let names = this.props.caseType.names || {};
        console.log(names);
        names = Object.assign({}, names, {
            [this.state.language.value]: value,
        });

        this.props.caseTypeEditField('names', names);
    };

    setNameValue = () => {
        const { names } = this.props.caseType;
        const { language } = this.state;

        return names && names[language.value] ? names[language.value] : '';
    };

    onSave = () => {
        const { names, name } = this.props.caseType;

        return this.props.saveCaseType({
            ...this.props.caseType,
            name: names ? names['EN'] : name,
            names: names ? names : { EN: name },
        });
    };

    render() {
        const { isProfileChanged, editCaseTypeSuccess, error, isValid, isEditable } = this.props;

        return (
            <div className="content" style={{ marginTop: '10px' }}>
                <PreventTransitionPrompt
                    when={isProfileChanged}
                    message="MyCustomDialogComponent"
                    isFormValid={isValid}
                    entity="case_type"
                    saveChanges={this.onSave}
                    cancelAndDiscard={() => this.props.getCaseType(this.props.match.params.id)}
                />
                {this.props.loading ? <LoadingIndicator height={50} /> : null}

                <SystemMessage shown={editCaseTypeSuccess && isValid} type="success" message="Case Type successfully updated" />

                <Box color="blue" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`Case Type - ${this.setNameValue()}`} color="blue" icon="fa-user" classes="box-profile-first-row-height-show" />
                    <BoxBody>
                        <form noValidate>
                        <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="12"
                                        label="Field Key"
                                        valids="required|alpha_num_dash"
                                        validator={this.props.validator}
                                        value={this.props.caseType.code}
                                        onChange={this.onChange}
                                        name="code"
                                        required
                                        onBlur={this.props.dispatchValidState}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="12"
                                        label="Full Name"
                                        id="Full Name"
                                        required
                                        valids="required|unique"
                                        validator={this.props.validator}
                                        value={this.setNameValue()}
                                        onChange={this.onChangeName}
                                        name="name"
                                        onBlur={this.props.dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="12"
                                        label="Short Name"
                                        valids="max:5"
                                        validator={this.props.validator}
                                        value={this.props.caseType.shortName}
                                        onChange={this.onChange}
                                        name="shortName"
                                        onBlur={this.props.dispatchValidState}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="12"
                                        type="number"
                                        label="TAT"
                                        valids="positive_num"
                                        validator={this.props.validator}
                                        value={this.props.caseType.tat ? this.props.caseType.tat : ''}
                                        onChange={this.onChange}
                                        name="tat"
                                        onBlur={this.props.dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <FormField
                                md="12"
                                type="checkbox"
                                title="Status"
                                label="Active"
                                name="active"
                                value={this.props.caseType.active}
                                checked={this.props.caseType.active}
                                onChange={this.onChangeCheckBox}
                                className="checkbox"
                                disabled={!isEditable}
                            />
                            <p className="login-box-msg">{error ? <span className="text-danger">{error}</span> : null}</p>
                        </form>
                    </BoxBody>
                    <BoxFooter></BoxFooter>
                </Box>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const types_names = state.app.case_types.types.map((item) => {
        return item.fullName;
    });

    return {
        caseType: state.app.case_types.editedCaseType,
        loading: state.app.case_types.loading,
        isEditable: state.app.users.my_permissions.includes('MODIFY_CASE_TYPE'),
        isProfileChanged: state.app.case_types.isProfileChanged,
        showValidMessages: state.app.case_types.showValidMessages,
        editCaseTypeSuccess: state.app.case_types.editCaseTypeSuccess,
        error: state.app.case_types.editCaseTypeError,
        types: state.app.case_types.types,
        isValid: state.app.case_types.isCaseTypeValid,

        //VALIDATOR PROPS
        style: { marginLeft: '15px', marginRight: '15px' },
        customRules: {
            unique: {
                message: "This value already exists in the system, the new record won't be created.",
                rule: function (val) {
                    return !types_names.includes(val);
                },
            },
            positive_num: {
                message: 'This :attribute must be greater than or equal to 0.',
                rule: function (val) {
                    return parseFloat(val) > 0 || val === '';
                },
            },
        },
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCaseType: (code) => dispatch(getOne(code, 'case_type')),
    caseTypeEditField: (field, value) => dispatch(caseTypeEditField(field, value)),
    isAllValid: (valid) => dispatch(isCaseTypeValid(valid)),
    saveCaseType: (data) => dispatch(editCaseType(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(CaseTypeEditPage));
