import React from 'react';
import { Row } from 'react-bootstrap';
import { FINANCE_FIELDS } from 'redux/pages/case-finances/financeSlice';
import AmountField from './AmountField';

const ReviewerHourlyFields = () => {
  return (
    <div className="financeSection__reviewerContainer">
      <Row className="mg-top-10">
        <AmountField name={FINANCE_FIELDS.maxBillableTime} />

        <AmountField
          name={FINANCE_FIELDS.maxBillableAmountInReviewerCurrency}
          hideLabel
        />

        <AmountField name={FINANCE_FIELDS.maxBillableAmount} hideLabel />
      </Row>
      <Row className="mg-top-10">
        <AmountField name={FINANCE_FIELDS.reviewerTime} />

        <AmountField
          name={FINANCE_FIELDS.reviewerAmountInReviewerCurrency}
          hideLabel
        />
        <AmountField name={FINANCE_FIELDS.reviewerAmount} hideLabel />
      </Row>
    </div>
  );
};

export default ReviewerHourlyFields;
