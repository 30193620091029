import React, {Component} from 'react';
import SimpleReactValidator from "utils/simple-react-validator";
import SystemMessage from '../components/SystemMessage'

export default function FormValidateWrapper(WrappedComponent) {

    class FormValidateWrapper extends Component {
        constructor(props) {
            super();
            this.validator = new SimpleReactValidator({
                custom_email:
                    {
                        message: 'The Notification email must be a valid email address',
                        rule: (val) => val === '' ||
                            // eslint-disable-next-line no-control-regex,max-len
                            (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/igm).test(val)
                    },
                custom_date:
                    {
                        message: "That doesn't look right. Please re-enter date of birth.",
                        rule: (val) => val <= new Date().getTime() && val >= new Date('12/31/1899').getTime()
                    },
                ...props.customRules
            });

            this.validator.showMessages();
            this.state = {
                messagesShown: false,
                errorMessages: this.getErrorMessages()
            }
        }

        getErrorMessages = () => Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
            tab: this.props.tabToField ? this.props.tabToField[field] : null
        }));

        showMessages = () => {
            this.setState({messagesShown: true})
        };

        dispatchValidState = () => {
            // console.log('dispatchValidState', this.validator);
            const errorMessages = this.getErrorMessages();
            this.setState({errorMessages});
            this.props.isAllValid && this.props.isAllValid(this.validator.allValid())
        };

        render() {
            const {showValidMessages, error, top, style} = this.props;
            const {errorMessages, messagesShown} = this.state;
            return (
                <React.Fragment>
                    <SystemMessage
                        shown={messagesShown || showValidMessages}
                        type='error'
                        message={error}
                        validationMessages={errorMessages}
                        top={top}
                        style={style}
                        goToTab={this.props.setCurrentTab}
                    />

                    <WrappedComponent {...this.props} validator={this.validator} showMessages={this.showMessages}
                                      dispatchValidState={this.dispatchValidState}/>
                </React.Fragment>
            );
        }
    }

    return FormValidateWrapper
}
