import React from 'react'
import MySelect from "./MySelect";
import InputGroupWrapper from "./InputGroupWrapper";
import HelperText from './HelperText'; 
export default class RefField extends React.Component {

    constructor(props) {
        super(props);
        this.loadAll = this.loadAll.bind(this);
    }

    loadAll() {
        if (!this.props.loadedCount) {
            this.props.getOptions();
            this.props.setLoadedCount(1);
        }
    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        const {multi, options, value} = nextProps;

        if (!multi &&
            value &&
            JSON.stringify(this.props.value) !== JSON.stringify(value) &&
            !options.find((item) => item.value === value['value'] || item.value === value)
        ) {

            let myVal = value;
            if (this.props.value && typeof value['value'] !== "undefined") myVal = value['value'];
            this.props.getOne4Select(myVal);
        }
    }

    componentDidMount() {
        if (this.props.multi) {
            this.props.getOptions();
            this.props.setLoadedCount(1);
        } else {
            let myVal = this.props.value;
            if (this.props.value && typeof this.props.value['value'] !== "undefined") myVal = this.props.value['value'];
            myVal && this.props.getOne4Select(myVal);

            this.props.setLoadedCount(0);
        }
    }

    render() {
        let options = (this.props['creatableOptions'] && this.props['creatableOptions'].length > 0) ? this.props.options.concat(this.props['creatableOptions']) : this.props.options;
        //const disabledStyle = this.props.disabled ? ' background-grey' : '';

        let i =
            <MySelect
                {...this.props}
                options={options || []}
                multi={this.props.multi}
                allowSelectAll={this.props.multi}
                onOpen={this.loadAll}
            />;

        let classes = "";
        classes += `col-md-${this.props['labelLeft'] ? this.props.md - 2 : this.props.md}`;
        classes += " col-xs-" + (this.props.xs || "12");
       // console.log(this.props)
        return (
            <React.Fragment>
                {this.props['labelLeft'] && 
                <div className='col-md-2'>
                    <label>
                        {this.props.label}
                        {this.props.tickMark}
                    </label>
                </div>}
                <div className={classes} title={this.props.title || ''}>
                    {!this.props['labelLeft'] &&
                    <label htmlFor={this.props.id} className="required" id={'displayName-'+this.props.name}>
                        {this.props.label}
                        {this.props['labelAddon']}
                        {this.props.tickMark}
                    </label>
                    }
                    {this.props.title && <HelperText helperText={this.props.title}/>}
                    <span style={{display: 'none'}}>{this.props.validator && this.props['valids']
                        ? this.props.validator.message(this.props['validLabel'] || this.props.label,
                            this.props.value, this.props['valids'], 'text-danger') : ''}</span>
                    {this.props['actioned'] ? <InputGroupWrapper name={this.props.name} icon='code'
                                                              onActionClick={this.props.onActionClick}>{i}</InputGroupWrapper> : i}
                </div>
            </React.Fragment>
        )
    }
}
