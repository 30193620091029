import React from 'react';
import { connect } from 'react-redux';
import shortId from 'shortid';
import FormField from '../../components/FormField';
import { getSelectFieldValue } from '../../utils/helpers';
import { getActiveCountries } from '../../redux/actions/countryActions';
import FormValidateWrapper from '../../containers/FormValidateWrapper';
import PropTypes from 'prop-types';
import { createNewPatient } from '../../redux/actions/patientActions';

class PatientCreateForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: true,
            roleCode: '',
            companyCode: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.getActiveCountries();
        this.props.dispatchValidState()
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            let birthDate = this.state.birthDate;
            if (birthDate) {
                let timezoneOffsetInMilliSec = birthDate.getTimezoneOffset() * 60 * 1000;
                birthDate = birthDate.getTime() - timezoneOffsetInMilliSec;
            }
            const data = {
                ...this.state,
                code: shortId.generate(),
                gender: getSelectFieldValue(this.state.gender),
                country: getSelectFieldValue(this.state.country),
                state: getSelectFieldValue(this.state.state),
                company: getSelectFieldValue(this.state.company),
                birthDate: birthDate,
                email: this.state.email.trim(),
            };
            this.props.createNewPatient(data);
        } else {
            this.props.dispatchValidState();
            this.props.showMessages();
        }
    }

    onChange(e) {
        if(e.target.name.toLowerCase().includes('email'))
            this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, '') });
        else
            this.setState({ [e.target.name]: e.target.value });
    }

    onChangeCheckBox = (e) => {
        //const newValue = e.target.value === 'true' ? false : true
        this.setState({ [ e.target.name ]: e.target.checked });
    };

    onChangeGender(gender) {
        this.setState({ gender })
    }

    onChangeBirthDate(birthDate) {
        this.setState({ birthDate })
    }

    onChangeCompany(company) {
        this.setState({ company })
    }

    onChangeCountry(country) {
        !country || country.value !== 'US' ?
            this.setState({ country, state: null }) :
            this.setState({ country })
    }

    onChangeState(state) {
        this.setState({ state })
    }

    render() {

        return (
            <form
                className='modal-content custom-modal-content'
                onSubmit={this.onSubmit}
                noValidate
            >
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            label="First Name"
                            id="First Name"
                            className="form-control"
                            required
                            valids="required|alpha"
                            validator={this.props.validator}
                            name="firstName"
                            value={this.state.firstName}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            label="Middle Name"
                            id="Middle Name"
                            className="form-control"
                            valids="alpha"
                            validator={this.props.validator}
                            name="middleName"
                            value={this.state.middleName}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            label="Last Name"
                            id="Last Name"
                            className="form-control"
                            required
                            valids="required|alpha"
                            validator={this.props.validator}
                            name="lastName"
                            value={this.state.lastName}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            type='DATE'
                            label="Birth Date"
                            id="Birth Date"
                            name="birthDate"
                            valids="custom_date"
                            min={new Date('12/31/1899')}
                            max={new Date()}
                            validator={this.props.validator}
                            value={this.state.birthDate}
                            onChange={this.onChangeBirthDate.bind(this)}
                            onBlur={this.props.dispatchValidState}

                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            type='REF_GENDER'
                            className='text-bg'
                            label="Gender"
                            name="gender"
                            value={this.state.gender}
                            onChange={this.onChangeGender.bind(this)}
                        />
                        <FormField
                            md="6"
                            xs="6"
                            type='REF_COMPANY'
                            label='Company'
                            id='Company'
                            valids="required"
                            className="required-field text-bg"
                            validator={this.props.validator}
                            value={this.state.company}
                            onChange={this.onChangeCompany.bind(this)}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            icon="envelope-o"
                            type="input-icon"
                            name="email"
                            label={this.context.translate('common.form.email')}
                            id={this.context.translate('common.form.email')}
                            className="form-control"
                            onBlur={this.props.dispatchValidState}
                            value={this.state.email}
                            onChange={this.onChange}
                        />
                        {/*<FormField
                        md="6"
                        icon="envelope-o"
                        type="input-icon"
                        required
                        label={this.context.translate('common.form.notification_email')}
                        id={this.context.translate('common.form.notification_email')}
                        className="form-control"
                        name="notificationEmail"
                        valids="required|email"
                        validator={this.props.validator}
                        onBlur={this.props.dispatchValidState}
                        value={this.state.notificationEmail}
                        onChange={this.onChange}
                    />*/}
                        <FormField
                            md="6"
                            type="input-icon"
                            icon="phone"
                            label={this.context.translate('common.form.phone_number')}
                            id={this.context.translate('common.form.phone_number')}
                            className="form-control"
                            name="phoneNumber"
                            value={this.state.phoneNumber}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            type="input-icon"
                            icon="phone"
                            label={this.context.translate('common.users.alternative_phone_number')}
                            id={this.context.translate('common.users.alternative_phone_number')}
                            className="form-control"
                            name="alternativePhoneNumber"
                            value={this.state.alternativePhoneNumber}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            label="Address 1"
                            className="form-control"
                            name="address1"
                            value={this.state.address1}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            label="Address 2"
                            className="form-control"
                            name="address2"
                            value={this.state.address2}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            label="City"
                            className="form-control"
                            name="city"
                            value={this.state.city}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            xs="6"
                            type='REF_STATE'
                            label='State'
                            name='stateCode'
                            disabled={getSelectFieldValue(this.state.country) + '' !== 'US'}
                            value={this.state.state}
                            onChange={this.onChangeState.bind(this)}
                        />
                        <FormField
                            md="6"
                            type="select"
                            label="Country"
                            className="text-bg "
                            disabled={false}
                            options={this.props.countries}
                            id="user_edit_country"
                            name="countryCode"
                            value={this.state.country}
                            onChange={this.onChangeCountry.bind(this)}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            type="checkbox"
                            title='Active'
                            label='Active'
                            name='active'
                            value={this.state.active}
                            checked={this.state.active}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                            classBox="checkbox-padding"
                        />
                        <FormField
                            md="6"
                            xs="6"
                            label="Postal Code"
                            className="form-control"
                            id="user_edit_postal_code"
                            name="postalCode"
                            value={this.state.postalCode}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <p className="login-box-msg">
                    {(this.props.error) ?
                        <span className="text-danger">
              {this.props.error}
            </span>
                        :
                        null
                    }
                </p>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right">
                            <button
                                className='btn btn-block btn-flat btn-primary'
                                disabled={this.props.isLoading}
                            >
                                Add Patient
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

PatientCreateForm.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    isLoading: state.app.patients.loading,
    error: state.app.patients.error,
    profile: {},
    countries: state.app.countries.data
        .map(country => ({
            value: country.code,
            label: country.name
        })),
    top: 0
});

const mapDispatchToProps = dispatch => ({
    getActiveCountries: () => dispatch(getActiveCountries()),
    createNewPatient: (patient) => {
        dispatch(createNewPatient(patient));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(PatientCreateForm));
