import React from 'react'
import RemoteGrid from "../../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import {getAllFields} from "../../../redux/actions/fieldsActions";
import {capitalizeAllFirstLetters, convertStyledText, fromMatrixToString, getLanguagedNameFromCode, titleFormatter} from "../../../utils/helpers";
import {renderDefaultValue} from "../../../utils/fields";
import PropTypes from "prop-types";

class FieldsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.onRowClick = this.onRowClick.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
    }

    componentDidMount() {
        this.props.getAllFields();
    }

    onRowClick(item) {
        this.props.history.push(`fields/update/${item}`)
    }

    enumFormatter = (cell, row, enumObject) => {
        return enumObject[cell];
    };

    render() {
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true
            }, {
                'name': 'code',
                'title': 'Field Key',
                'sort': true,
                'hidden': false,
                'width': '120',
                'filter': {type: 'TextFilter', delay: 500},
            }, {
                'name': 'name',
                'title': 'Display Name',
                'sort': true,
                'width': '120',
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            }, {
                'name': 'fieldType',
                'title': 'Field Type',
                'width': '100',
                'dataAlign': 'left',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            }, {
                'name': 'defaultValue',
                'title': 'Value',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '120'
            }, {
                'name': 'visibilityMatrix',
                'title': 'Visibility',
                'dataFormat': titleFormatter,
                'columnTitle': false,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '250'
            }, {
                'name': 'readOnlyMatrix',
                'title': 'Read Only',
                'dataFormat': titleFormatter,
                'columnTitle': false,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '250'
            }, {
                'name': 'requiredMatrix',
                'title': 'Required',
                'dataFormat': titleFormatter,
                'columnTitle': false,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '250'
            }, {
                'name': 'active',
                'title': 'Status',
                'dataFormat': this.enumFormatter,
                'dataAlign': 'left',
                'sort': true,
                'formatExtraData': {
                    'false': 'Inactive',
                    'true': 'Active'
                },
                'width': '100',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': 'Inactive',
                        'true': 'Active'
                    }
                }
            }
        ];
        const {isLoading} = this.props;
        const {pagination, sorting} = this.props.gridConfiguration;
        return (
            <div className='container-fluid'>
                <div className="table-responsive" style={{marginTop: '15px', "padding": '10px'}}>
                    {isLoading && <LoadingIndicator type="lister"/>}
                    <RemoteGrid
                        color="blue"
                        title="Fields"
                        entity="field"
                        data={this.props.data}
                        total={this.props.totalElements}
                        columns={columns}
                        pagination={true}
                        page={pagination.page}
                        sizePerPage={pagination.sizePerPage}
                        search={true}
                        onRowClick={this.onRowClick}
                        defaultSort
                        sortName={sorting.sortName}
                        sortOrder={sorting.sortOrder}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        gridConfiguration: PropTypes.object,
    }
    static defaultProps = {
        isLoading: false
    };
}

const mapState = (state) => {
    return {
        data: state.app.fields.data.map((item) => {
            //if (item.code === 'color') console.log(item);
            return {
                ...item,
                name: convertStyledText(item.name),
                helpTextStaff: item.helpTextStaff ? convertStyledText(item.helpTextStaff['EN']) : item.helpTextStaff,
                helpTextReviewer: item.helpTextReviewer ? convertStyledText(item.helpTextReviewer['EN']) : item.helpTextReviewer,
                helpTextClient: item.helpTextClient ? convertStyledText(item.helpTextClient['EN']) : item.helpTextClient,
                fieldType: capitalizeAllFirstLetters(item.fieldType),
                position: item.position,
                readOnly: item.readOnly,
                defaultValue: renderDefaultValue(item),
                customClasses: item.customClasses ? item.customClasses.join(' ') : item.customClasses,
                defaultOptions: item.defaultOptions ? Object.entries(item.defaultOptions).map((item) => item.join(': ')).join(', ') : item.defaultOptions,
                section: getLanguagedNameFromCode(item.sectionCode, state.app.templates.sectionDto, 'EN'),
                order: item.position,
                requiredMatrix: fromMatrixToString(item.requiredMatrix, true),
                formattedRequiredMatrix: fromMatrixToString(item.requiredMatrix),
                visibilityMatrix: fromMatrixToString(item.visibilityMatrix, true),
                formattedVisibilityMatrix: fromMatrixToString(item.visibilityMatrix),
                readOnlyMatrix: fromMatrixToString(item.readOnlyMatrix, true),
                formattedReadOnlyMatrix: fromMatrixToString(item.readOnlyMatrix),
            }
        }),
        fields: state.app.templates.fields,
        size: state.app.fields.data.length,
        totalElements: state.app.fields.data.length,
        isLoading: state.app.fields.loading,
        gridConfiguration: state.app.grid.field,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllFields: (data) => dispatch(getAllFields(data)),
    }
};

export default connect(mapState, mapDispatchToProps)(FieldsList);
