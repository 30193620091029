import axios from 'axios';
import { API_HOST } from '../config';

import history from './../redux/history';
import { setCurrentUser } from '../redux/actions/authActions';
import { setRedirectPathAfterLogin } from '../redux/pages/login/loginRedirectSlice';
import { store } from '../redux/store';

const api = axios.create({
    baseURL: API_HOST,
    timeout: 5 * 60 * 1000,
    withCredentials: true,
    // transformRequest: [(data) => JSON.stringify(data.data)],
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // if (error.response && [403, 401].includes(error.response.status)) { // TODO ?
        if (error.response && [401].includes(error.response.status)) {
            store.dispatch(setCurrentUser({}));
            console.log(error.response);

            const { pathname } = history.location;

            if (pathname !== '/login') {
                store.dispatch(setRedirectPathAfterLogin(pathname));
                history.push('/login');
            }
        }
        return new Promise(function (resolve, reject) {
            reject(error);
        });
    },
);

api.mockResolve = (mockResult = [], time = 3000) => new Promise((resolve) => setTimeout(() => resolve(mockResult), time));
api.mockReject = (mockError = 'MockedError', time = 3000) => new Promise((_, reject) => setTimeout(() => reject(mockError), time));

export default api;
