import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'
import PropTypes from "prop-types";
import { editSpecialty } from '../../../redux/actions/specialtyActions'
import { showValidation } from '../../../redux/actions/BasicEntityActions'

class SaveSpecialty extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        e.preventDefault();
        if (this.props.isValid) {
            let bodyPartCodes = this.props.specialty.bodyPartCodes.map((item) => item.value ? item.value : item);
            this.props.saveSpecialty({
                ...this.props.specialty,
                active: this.props.specialty.active,
                newCode: this.props.specialty.code,
                code: this.props.originalCode,
                bodyPartCodes: bodyPartCodes,
                names: this.props.specialty.names ? this.props.specialty.names : { 'EN': '' }
            }, closeAfterSave)
        } else {
            this.props.showValidation()
        }
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={this.onSave}
                />
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={(e) => this.onSave(e, true)}
                    title={'Save And Close'}
                />
            </React.Fragment>
        )
    }
}

SaveSpecialty.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    specialty: state.app.specialties.editedSpecialty,
    isValid: state.app.specialties.specialtyValid,
    isProfileChanged: state.app.specialties.isProfileChanged,
    isEditable: state.app.users.my_permissions.includes('MODIFY_SPECIALTIES'),
    originalCode: state.app.specialties.originalSpecialty.code,
});

const mapDispatchToProps = dispatch => ({
    saveSpecialty: (data, closeAfterSave) => dispatch(editSpecialty(data, closeAfterSave)),
    showValidation: () => dispatch(showValidation('specialty'))
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveSpecialty)
