import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { mfaAccessCodeForRecovery, mfaOptionsForRecovery, mfaPasswordRecovery } from '../../../redux/actions/authActions';
import useInterval from './useInterval';
import {
  AUTH_TYPE,
  Button,
  delayedApiCall,
  err,
  GoToLogin,
  log,
  PasswordHint,
  ReCaptcha,
  TabNav,
  TabNavItem,
  TWAuthLayout,
  TWFormElementAccessCode,
  TWFormElementAuthenticatorCode,
  TWFormElementPassword,
  warn,
} from './utils';

const FORM_STATE = {
  PASSWORD: 'PASSWORD',
  ACCESS_CODE: 'ACCESS_CODE',
  SUCCESS: 'SUCCESS',
};

export const PasswordRecovery2FAForm = (props) => {
  const recoveryKey = props.match.params.key;
  const dispatch = useDispatch();
  const newPasswordRef = useRef();
  const repeatNewPasswordRef = useRef();
  const accessCodeRef = useRef();
  const authenticatorCodeRef = useRef();
  const recaptchaRef = useRef();

  const [state, setStateOriginal] = useState({
    formState: FORM_STATE.PASSWORD,
    isLoading: false,
    needRecaptcha: false,
    needMfa: false,
    authType: null,
    extNewPasswordError: '',
    extRepeatNewPasswordError: '',
    canShowEmailTab: false,
    canShowAuthenticatorTab: false,
    canShowSmsTab: false,
  });

  const setState = useCallback(
    (newState) => {
      setStateOriginal({ ...state, ...newState });
    },
    [setStateOriginal, state],
  );

  const [accessCodeCounter, setAccessCodeCounter] = useState(0);
  useInterval(() => {
    if (formState === FORM_STATE.ACCESS_CODE && accessCodeCounter === 0) {
      warn('Access code has expired (Password Recovery)');
      setState({ formState: FORM_STATE.EDIT });
      return;
    }
    if (formState !== FORM_STATE.ACCESS_CODE || accessCodeCounter <= 0) {
      return;
    }
    // log('accessCodeCounter', accessCodeCounter);
    setAccessCodeCounter((currentCount) => currentCount - 1);
  }, 1000);

  const authTypeFromActiveTab = () => {
    switch (activeTab) {
      case 'mfa-email':
        return AUTH_TYPE.EMAIL;
      case 'mfa-sms':
        return AUTH_TYPE.SMS;
      case 'mfa-authenticator':
        return AUTH_TYPE.AUTHENTICATOR;
      default:
        return null;
    }
  };

  const ctaMfaRequestCodeVia = () => {
    const dto = { recoveryKey: recoveryKey, authType: authTypeFromActiveTab() };
    if (!dto || !newPasswordRef.current.isValid() || !repeatNewPasswordRef.current.isValid()) {
      return;
    } else if (newPasswordRef.current.getValue() !== repeatNewPasswordRef.current.getValue()) {
        warn('generateDtoForFirstLogin PASSWORDS MUST MATCH', newPasswordRef.current.getValue(), repeatNewPasswordRef.current.getValue());
        setState({ extRepeatNewPasswordError: 'Passwords must match.' });
        return;
    }
    setState({ isLoading: true });
    delayedApiCall(() => {
      log('ctaMfaRequestCodeVia', dto);
      dispatch(mfaAccessCodeForRecovery(dto)).then(
        (result) => {
          if (result) {
            setState({
              formState: FORM_STATE.ACCESS_CODE,
              isLoading: false,
              extNewPasswordError: '',
              extRepeatNewPasswordError: '',
              extAccessCodeError: '',
              extAuthenticatorCodeError: '',
            });
            setAccessCodeCounter(result.expireInSeconds);
            // setAccessCodeCounter(10);
          } else {
            setState({ isLoading: false, extNewPasswordError: 'Access Code Error' });
          }
        },
        (e) => {
          setState({ isLoading: false });
          err('error', e);
        },
      );
    });
  };

  const generateDtoForPasswordRecovery = (captchaValue) => {
    const newPassword = newPasswordRef.current;
    const repeatNewPassword = repeatNewPasswordRef.current;
    const accessCode = accessCodeRef.current;
    const authenticatorCode = authenticatorCodeRef.current;
    const authType = authTypeFromActiveTab();
    const result = {
      recoveryKey: recoveryKey,
      newPassword: newPassword.getValue(),
      accessCode: accessCode,
      authenticatorCode: authenticatorCode,
      authType: authType,
      reCaptchaValue: captchaValue,
    };
    const isValidNewPassword = newPassword && newPassword.isValid();
    const isValidRepeatNewPassword = repeatNewPassword && repeatNewPassword.isValid();
    if (newPassword.getValue() !== repeatNewPassword.getValue()) {
      warn('generateDtoForPasswordRecovery PASSWORDS MUST MATCH', newPassword.getValue(), repeatNewPassword.getValue());
      setState({ extRepeatNewPasswordError: 'Passwords must match.' });
      return null;
    } else {
      setState({ extRepeatNewPasswordError: '' });
    }
    if (needMfa) {
      if (authType === AUTH_TYPE.AUTHENTICATOR) {
        const isValidAuthenticatorCode = authenticatorCode && authenticatorCode.isValid();
        if (!isValidNewPassword || !isValidRepeatNewPassword || !isValidAuthenticatorCode) {
          warn('generateDtoForPasswordRecovery (AuthenticatorCode) - MISSING PARAM', result);
          return null;
        }
        result['authenticatorCode'] = authenticatorCode.getValue();
      } else if (authType === AUTH_TYPE.EMAIL || authType === AUTH_TYPE.SMS) {
        const isValidAccessCode = accessCode && accessCode.isValid();
        if (!isValidNewPassword || !isValidRepeatNewPassword || !isValidAccessCode) {
          warn('generateDtoForPasswordRecovery (AccessCode) - MISSING PARAM', result);
          return null;
        }
        result['accessCode'] = accessCode.getValue();
      }
    } else {
      if (!isValidNewPassword || !isValidRepeatNewPassword) {
        warn('generateDtoForPasswordRecovery - MISSING PARAM', result);
        return null;
      }
    }
    return result;
  };

  const ctaPasswordRecovery = (captchaValue) => {
    log('ctaPasswordRecovery', state);
    const dto = generateDtoForPasswordRecovery(captchaValue);
    if (!dto) {
      return;
    }
    setState({ isLoading: true });
    delayedApiCall(() => {
      log('ctaPasswordRecovery', dto);
      dispatch(mfaPasswordRecovery(dto)).then(
        (result) => {
          log('passwordRecoveryResult', result);
          if (result && result.error) {
            let errorMessage;
            if (result.statusCode === 204) {
              errorMessage = 'This user is not existing in the system';
            } else {
              errorMessage = result.error.response.data;
            }
            if (needMfa) {
              setState({ isLoading: false, extAccessCodeError: errorMessage });
            } else {
              setState({ isLoading: false, extRepeatNewPasswordError: errorMessage });
            }
          } else if (result) {
            setState({ formState: FORM_STATE.ACCESS_CODE, isLoading: false });
          } else {
            setState({ formState: FORM_STATE.SUCCESS, isLoading: false });
          }
        },
        (e) => {
          setState({ isLoading: false });
          err('error', e);
        },
      );
    });
  };

  const ctaPasswordRecoveryCaptcha = () => {
    log('ctaPasswordRecoveryCaptcha', state, needRecaptcha, recaptchaRef);
    if (needRecaptcha && recaptchaRef) {
      const captcha = recaptchaRef.current;
      if (!generateDtoForPasswordRecovery()) {
        warn('Invalid form before captcha');
        return;
      }
      if (!captcha || !captcha.isReady()) {
        warn('Captcha is not ready');
        return;
      }
      captcha.callback((captchaValue) => {
        ctaPasswordRecovery(captchaValue);
      });
    } else {
      ctaPasswordRecovery();
    }
  };

  const ctaPasswordRecoveryAuthenticator = (captchaValue) => {
    log('ctaPasswordRecoveryAuthenticator', state);
    const dto = generateDtoForPasswordRecovery(captchaValue);
    if (!dto) {
      return;
    }
    setState({ isLoading: true });
    delayedApiCall(() => {
      log('recoveryPassword', dto);
      dispatch(mfaPasswordRecovery(dto)).then(
        (result) => {
          log('recoveryPasswordResult', result);
          if (result && result.error) {
            let errorMessage;
            if (result.statusCode === 204) {
              errorMessage = 'This user is not existing in the system';
            } else {
              errorMessage = result.error.response.data;
            }
            setState({ isLoading: false, extAuthenticatorCodeError: errorMessage });
          } else if (result) {
            setState({ formState: FORM_STATE.ACCESS_CODE, isLoading: false });
          } else {
            setState({ formState: FORM_STATE.SUCCESS, isLoading: false });
          }
        },
        (e) => {
          setState({ isLoading: false });
          err('error', e);
        },
      );
    });
  };

  const ctaPasswordRecoveryCaptchaAuthenticator = () => {
    log('ctaPasswordRecoveryCaptchaAuthenticator', state, needRecaptcha, recaptchaRef);
    if (needRecaptcha && recaptchaRef) {
      const captcha = recaptchaRef.current;
      if (!generateDtoForPasswordRecovery()) {
        warn('Invalid form before captcha');
        return;
      }
      if (!captcha || !captcha.isReady()) {
        warn('Captcha is not ready');
        return;
      }
      captcha.callback((captchaValue) => {
        ctaPasswordRecoveryAuthenticator(captchaValue);
      });
    } else {
      ctaPasswordRecoveryAuthenticator();
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    // log('onSubmit', event, activeTab, formState);
  };

  const onTabSelection = (e) => {
    log('onTabSelection', e);
    setState({ activeTab: e });
  };

  useEffect(() => {
    dispatch(mfaOptionsForRecovery({ recoveryKey: recoveryKey })).then(
      (result) => {
        log('mfaOptionsForRecoveryResult', result);
        if (!result) return;
        if (result.error) {
          err('ctaMfaOptions-ERROR', result.error);
          setState({ isLoading: false, errors: { email: 'Backend is unavailable at the moment. Please try again later.' } });
        } else if (result.authOptions) {
          const canShowSmsTab = result.authOptions.includes(AUTH_TYPE.SMS);
          const canShowEmailTab = result.authOptions.includes(AUTH_TYPE.EMAIL);
          const canShowAuthenticatorTab = result.authOptions.includes(AUTH_TYPE.AUTHENTICATOR);
          const activeTab = canShowEmailTab ? 'mfa-email' : canShowAuthenticatorTab ? 'mfa-authenticator' : canShowSmsTab ? 'mfa-sms' : null;

          setState({
            formState: FORM_STATE.PASSWORD,
            isLoading: false,
            needRecaptcha: result.needRecaptcha,
            needMfa: result.authOptions.length > 0,
            canShowSmsTab,
            canShowEmailTab,
            canShowAuthenticatorTab,
            activeTab,
            extNewPassword: '',
            extRepeatNewPassword: '',
            extAccessCodeError: '',
            extAuthenticatorCodeError: '',
          });
        } else {
          setState({ isLoading: false, errors: { email: 'Something went wrong.' } });
        }
      },
      (e) => {
        setState({ isLoading: false, errors: { email: 'Unknown error.' } });
        err('2FA-ERROR', e);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    formState,
    activeTab,
    isLoading,
    needRecaptcha,
    needMfa,
    canShowEmailTab,
    canShowAuthenticatorTab,
    canShowSmsTab,
    extNewPasswordError,
    extRepeatNewPasswordError,
    extAuthenticatorCodeError,
    extAccessCodeError,
  } = state;

  return (
    <TWAuthLayout title={'Restore your password'}>
      <form className="space-y-2" action="#" method="POST" noValidate={true} onSubmit={onSubmit}>
        <PasswordHint show={formState === FORM_STATE.PASSWORD || formState === FORM_STATE.ACCESS_CODE} />
        <TWFormElementPassword
          show={formState === FORM_STATE.PASSWORD || formState === FORM_STATE.ACCESS_CODE}
          id={'2fa-new-password'}
          name={'newPassword'}
          ref={newPasswordRef}
          disabled={isLoading}
          title={'New password'}
          placeholder={'Your new password'}
          emptyErrorMessage={'Please enter your new password.'}
          invalidErrorMessage={'Please enter a valid new password.'}
          extError={extNewPasswordError}
        />
        {/*repeat-password*/}
        <TWFormElementPassword
          show={formState === FORM_STATE.PASSWORD || formState === FORM_STATE.ACCESS_CODE}
          id={'2fa-repeat-new-password'}
          name={'repeatNewPassword'}
          ref={repeatNewPasswordRef}
          disabled={isLoading}
          title={'Repeat new password'}
          placeholder={'Your new password again'}
          emptyErrorMessage={'Please enter your new password again.'}
          invalidErrorMessage={'Please enter a valid new password again.'}
          extError={extRepeatNewPasswordError}
        />
        {/*tab navigation*/}
        {needMfa && formState === FORM_STATE.PASSWORD && (
          <>
            <p className={'font-medium'}>Please select a multi-factor authentication (MFA)</p>
            <TabNav defaultActiveTab={activeTab ? activeTab : 'mfa-email'} onTabSelection={onTabSelection}>
              {/*Email*/}
              <TabNavItem show={canShowEmailTab} title={'Email'} tabKey={'mfa-email'}>
                {formState === FORM_STATE.PASSWORD && (
                  <>
                    <p className={'mb-4 text-sm'}>Hint: You can request an access code via Email, which you can use to change your password.</p>
                    <Button isLoading={isLoading} cta={ctaMfaRequestCodeVia} show={!activeTab || activeTab === 'mfa-email'}>
                      Request code via Email
                    </Button>
                  </>
                )}
              </TabNavItem>
              {/*Google Authenticator*/}
              <TabNavItem show={canShowAuthenticatorTab} title={'Authenticator'} tabKey={'mfa-authenticator'}>
                <>
                  <TWFormElementAuthenticatorCode
                    show={true}
                    id={'2fa-authenticator-code'}
                    ref={authenticatorCodeRef}
                    disabled={isLoading}
                    extError={extAuthenticatorCodeError}
                  />
                  <Button isLoading={isLoading} cta={ctaPasswordRecoveryCaptchaAuthenticator} show={activeTab === 'mfa-authenticator'}>
                    Change password
                  </Button>
                </>
              </TabNavItem>
              {/*SMS*/}
              <TabNavItem show={canShowSmsTab} title={'SMS'} tabKey={'mfa-sms'}>
                <>
                  <p className={'mb-4 text-sm'}>Hint: You can request an access code via SMS, which you can use to change your password.</p>
                  <Button isLoading={isLoading} cta={ctaMfaRequestCodeVia} show={activeTab === 'mfa-sms'}>
                    Request code via SMS
                  </Button>
                </>
              </TabNavItem>
            </TabNav>
          </>
        )}
        {/*access code*/}
        {needMfa && formState === FORM_STATE.ACCESS_CODE && (
          <>
            <TWFormElementAccessCode
              show={true}
              id={'2fa-access-code'}
              ref={accessCodeRef}
              disabled={isLoading || formState !== FORM_STATE.ACCESS_CODE}
              accessCodeExpirationCounter={accessCodeCounter}
              extError={extAccessCodeError}
            />
            <p className={'mb-4 text-sm'}>Hint: Please enter the access code you received by e-mail.</p>
            <Button isLoading={isLoading} cta={ctaPasswordRecoveryCaptcha} show={true}>
              Change password
            </Button>
          </>
        )}
        {!needMfa && formState === FORM_STATE.PASSWORD && (
          <>
            <Button isLoading={isLoading} cta={ctaPasswordRecoveryCaptcha} show={true}>
              Change password
            </Button>
          </>
        )}
        {/*recaptcha*/}
        <ReCaptcha enabled={needRecaptcha} ref={recaptchaRef} />

        {formState === FORM_STATE.SUCCESS && (
          <>
            <p className={'font-medium mb-4'}>If your recovery key was valid, your password has been restored successfully.</p>
            <GoToLogin />
          </>
        )}
      </form>
    </TWAuthLayout>
  );
};
PasswordRecovery2FAForm.propTypes = {
  match: PropTypes.object,
};
