const defaultState = {
    loading: false,
    loadedCount: 0,
    data: [],
    error: ''
};

const procedures = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_CASE_DETERMINATION_LOADING':
            return {...state, loading: true};
        case 'FETCH_CASE_DETERMINATION_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null
            });

        case 'FETCH_CASE_DETERMINATION_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            };
        case 'FILL_CASE_DETERMINATION_LOCAL_STORAGE':
            let items = {};
            for (let i = 0; i < state.data.length; i++) {
                items[state.data[i].code] = state.data[i].name;
            }
            localStorage.setItem('list_case_determination', JSON.stringify(items));
            return state;
        case 'FETCH_CASE_DETERMINATION_LOADED_COUNT':
            return {...state, loadedCount: action.payload};

        case 'FETCH_CASE_DETERMINATION_ERROR':
            return {...state, error: action.payload, loading: false};

        case 'FETCH_CREATE_CASE_DETERMINATION_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                createError: null,
                data: [...state.data, action.payload]
            });
        default:
            return state
    }
};

export default procedures
