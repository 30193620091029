/* eslint-disable react/display-name */
import { groupBy } from 'lodash';
import React, { useState, useCallback } from 'react';
import Select from 'react-select/creatable';
import { notificationAssigneeTypes } from 'utils/Constants';
import ColoredBox from '../../components/ColoredBox/ColoredBox';

// eslint-disable-next-line no-control-regex
const RFC_5322_EMAIL_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const FIELD_REFERENCE_REGEXP = /%[a-zA-Z]+(_*[a-zA-Z]+)*%/;

const groupedOptions = notificationAssigneeTypes.map((t) => ({
  ...t,
  type: 'group',
}));

export default () => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [result, setResult] = useState('');

  const handleChange = (values) => {
    setSelectedValues(values);
  };

  const handleCreate = useCallback(
    (inputValue) => {
      if (FIELD_REFERENCE_REGEXP.test(inputValue)) {
        const newItem = {
          label: inputValue,
          value: inputValue,
          type: 'field_reference',
        };
        setSelectedValues([...selectedValues, newItem]);
      }

      if (RFC_5322_EMAIL_REGEXP.test(inputValue)) {
        const newItem = {
          label: inputValue,
          value: inputValue,
          type: 'email',
        };
        setSelectedValues([...selectedValues, newItem]);
      }
    },
    [selectedValues],
  );

  const isValidNewOption = (inputValue) => {
    return (
      RFC_5322_EMAIL_REGEXP.test(inputValue) ||
      FIELD_REFERENCE_REGEXP.test(inputValue)
    );
  };

  const submit = () => {
    const grouped = groupBy(selectedValues, 'type');

    const formatGroup = (key) => (
      <div style={{ marginTop: 20 }}>
        <h3>{key}</h3>
        <div>Values: [{grouped[key].map(({ value }) => value).join(', ')}]</div>
      </div>
    );
    setResult(Object.keys(grouped).map((key) => formatGroup(key)));
  };

  return (
    <div className="container">
      <ColoredBox variant="default" title="Select Demo">
        <div className="row">
          <div className="col-lg-4">
            <Select
              className="legacy-select"
              classNamePrefix="legacy-select"
              options={groupedOptions}
              onChange={handleChange}
              onCreateOption={handleCreate}
              isValidNewOption={isValidNewOption}
              value={selectedValues}
              isMulti
            />
          </div>
          <button style={{ display: 'inline-block' }} onClick={submit}>
            Test
          </button>
        </div>
        <div>{result}</div>
      </ColoredBox>
    </div>
  );
};
