import {initialWysiwygValue, reducedRoleStageMatrix} from '../../utils/Constants'
import {isObjectsEqual} from '../../utils/helpers'
import {convertReceivedField} from '../../utils/fields'
import { update } from 'lodash';

const defaultTemplate = {
    code: "",
    workflowCode: '',
    active: true,
    name: '',
    names: {
        SV: '',
        EN: ''
    },
    timeCreated: '',
    sectionDto: [],
    templateQuestionCodes: [],
    fieldInstancesDto: [],
    fieldForReportDto: {
      fieldReferences: Map
    },
    allTemplates: [],
    lastLoadedTime: 0,
};

const defaultField = {
    active: true,
    names: {'EN': '', 'SV': ''},
    name: initialWysiwygValue,
    code: '',
    fieldType: null,
    defaultValue: null,
    sectionCode: null,
    listOptions: [],
    customClasses: [],
    phi: false,
    skippDisplayName: false,
    allowMultiSelect: false,
    requiredMatrix: reducedRoleStageMatrix,
    visibilityMatrix: reducedRoleStageMatrix,
    readOnlyMatrix: reducedRoleStageMatrix,
    fieldValidationDtos: []
};

// const fieldGridConfiguration = {
//     sorting: {
//         sortName: ['position', 'sectionOrder'],
//         sortOrder: ['asc', 'asc'],
//     },
//     pagination: {
//         page: 1,
//         sizePerPage: 50
//     }
// };

const defaultState = {
    code: "",
    workflowCode: '',
    active: true,
    name: '',
    displayName: {
        SV: '',
        EN: ''
    },
    timeCreated: '',
    sectionDto: [],
    fieldInstancesDto: [],
    fieldForReportDto: {
      fieldReferences: Map
    },
    fieldInstanceCodes: [],
    templateQuestionCodes: [],

    loading: false,
    error: null,
    sectionError: null,
    currentTab: '',
    allTemplates: [],
    editedField: defaultField,
    originalField: defaultField,
    editedSection: {},
    originalTemplate: defaultTemplate,
    fieldValid: false,
    isTemplateValid: false,
    fieldEditSuccess: false,
    templateEditSuccess: false,
    sectionEditSuccess: false,
    isFIFormValid: true,
    isTemplateFormValid: true,
    isFieldChanged: false,
    isTemplateChanged: false
  };

const templates = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_COPY_TEMPLATE_LOADING':
            return {
                ...state,
                loading: true,
                originalTemplate: defaultState.originalTemplate,
                templateEditSuccess: false,
            };
        case 'FETCH_TEMPLATES_LOADING':
            return {...state, loading: true, templateEditSuccess: false};
        case 'FETCH_UPDATE_TEMPLATE_LOADING':
            return {...state, loading: true, templateEditSuccess: false};
        case 'FETCH_TEMPLATE_LOADING':
            return {...defaultState, allTemplates: state.allTemplates, loading: true, templateEditSuccess: false};
        case 'FETCH_FIELD_INSTANCE_LOADING':
            return {
                ...state,
                loading: true,
                editedField: defaultState.editedField,
                fieldEditSuccess: false,
                isFieldChanged: false
            };
        case 'FETCH_CREATE_FIELD_INSTANCE_LOADING':
        case 'FETCH_UPDATE_FIELD_INSTANCE_LOADING':
            return {...state, loading: true, fieldEditSuccess: false, isFieldChanged: false};

        case 'FETCH_UPDATE_SECTION_LOADING':
        case 'FETCH_SECTION_PROFILE_LOADING':
        case 'FETCH_SECTION_LOADING':
            return {...state, loading: true, sectionEditSuccess: false, sectionError: null};

        case 'FETCH_TEMPLATE_ERROR':
        case 'FETCH_CREATE_TEMPLATE_ERROR':
        case 'FETCH_UPDATE_TEMPLATE_ERROR':
        case 'FETCH_FIELD_INSTANCE_ERROR':
        case 'FETCH_COPY_TEMPLATE_ERROR':
            return {...state, loading: false, error: action.payload};
        case 'FETCH_SECTION_ERROR':
        case 'FETCH_CREATE_SECTION_ERROR':
        case 'FETCH_REMOVE_SECTION_ERROR':
            return {...state, loading: false, sectionError: action.payload};
        case 'FETCH_SECTION_PROFILE_ERROR':
            return {...state, loading: false, error: action.payload, editedSection: {}};

        case 'FETCH_TEMPLATES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                allTemplates: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            });

        case 'FETCH_TEMPLATE_FULL_SUCCESS':
            return Object.assign({}, state, action.payload,
                {
                    loading: false,
                    originalTemplate: action.payload,
                    error: null
                });
        case 'FETCH_TEMPLATE_FULL_CLEAR':
            return Object.assign({}, state, defaultState);

        case 'FETCH_SECTION_PROFILE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                editedSection: action.payload,
                error: null
            });

        case 'FETCH_CREATE_TEMPLATE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                allTemplates: [...state.allTemplates, action.payload],
                code: action.payload.code
            });

        case 'FETCH_COPY_TEMPLATE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
            });

        case 'FETCH_UPDATE_TEMPLATE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                templateEditSuccess: true,
                isTemplateChanged: false,
                originalTemplate: action.payload,
                // allTemplates: state.allTemplates.map((item) => {
                // if (item.code === action.payload.code) return action.payload;
                // return item
                // }),
            });

        case 'FETCH_CREATE_SECTION_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                sectionDto: [...state.sectionDto, action.payload]
            });

        case 'FETCH_UPDATE_SECTION_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                sectionEditSuccess: true,
                // editedSection: action.payload,
                sectionDto: state.sectionDto.map((item) => item.code === action.payload.code ? action.payload : item)
            });

        case 'FETCH_REMOVE_SECTION_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                sectionDto: state.sectionDto.filter((item) => item.code !== action.payload)
            });

        case 'FETCH_FIELD_INSTANCE_SUCCESS': {
            let converted = convertReceivedField(action.payload);
            return Object.assign({}, state, {
                loading: false,
                editedField: converted,
                originalField: converted,
                fieldEditSuccess: false,
                error: null
            });
        }
        case 'FETCH_CREATE_FIELD_INSTANCE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                fieldInstancesDto: [...state.fieldInstancesDto, action.payload],
                fieldInstanceCodes: [...state.fieldInstanceCodes, action.payload.code]
            });

        case 'FETCH_UPDATE_FIELD_INSTANCE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                fieldEditSuccess: true,
                originalField: action.payload,
                // editedField: action.payload,
                fieldInstancesDto: state.fieldInstancesDto.map((item) => item.code === action.payload.code ? action.payload : item)
            });

        case 'FETCH_REMOVE_FIELD_INSTANCE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                fieldInstancesDto: state.fieldInstancesDto.filter((item) => item.code !== action.payload),
                fieldInstanceCodes: state.fieldInstanceCodes.filter((item) => item !== action.payload),
            });

        case 'EDIT_CURRENT_SECTION':
            return Object.assign({}, state, {
                editedSection: Object.assign({}, state.editedSection, action.payload)
            });

        case 'EDIT_CURRENT_INSTANCE_FIELD': {
            let saved = action.payload;
            if (typeof saved['defaultValue'] !== "undefined") {
                let data = saved['defaultValue'];
                if (Array.isArray(data) && typeof data[0] !== "undefined"
                    && typeof data[0].value !== "undefined" && typeof data[0].label !== "undefined") {
                    let defNames = [];
                    for (let i = 0, len = data.length; i < len; i += 1) {
                        defNames.push(data[i].label);
                    }
                    saved['defaultValueName'] = JSON.stringify(defNames);
                }
            }
            return Object.assign({}, state, {
                editedField: Object.assign({}, state.editedField, saved),
                isFieldChanged: !isObjectsEqual(Object.assign({}, state.editedField, saved), state.originalField),
                fieldEditSuccess: false
            });
        }
        case 'EDIT_TEMPLATE_FIELD': {
            const {name, workflowCode, active, originalTemplate} = {...state, ...action.payload};
            const isTemplateChanged = !isObjectsEqual({name: name, workflowCode: workflowCode, active: active}, {
                name: originalTemplate.name,
                workflowCode: originalTemplate.workflowCode,
                active: originalTemplate.active
            });
            return Object.assign({}, state, {...action.payload, isTemplateChanged: isTemplateChanged});
        }
        case 'EDIT_FIELDS_FOR_REPORT': {
          const updatedFieldForReportDto = {
              ...state.fieldForReportDto,
              ...action.payload,
              isTemplateChanged: true
          };
      
          return {
              ...state,
              fieldForReportDto: updatedFieldForReportDto,
              isTemplateChanged: true
          };
        }
        case 'EDIT_OUTCOME': {
          const newMap = new Map();
          for (const [key, value] of Object.entries(state.fieldForReportDto.fieldReferences)) {
            newMap.set(key, value);
          }
          
          newMap.set(action.payload.value, action.payload.event?.value);
          return{
            ...state,
              fieldForReportDto: {
                ...state.fieldForReportDto,
                fieldReferences: Object.fromEntries(newMap)
            },
            isTemplateChanged: true
          };
        }           
        case 'SET_CURRENT_TEMPLATE':
            return action.payload ? Object.assign({}, state, action.payload) : Object.assign({}, state, defaultTemplate);

        case 'SET_CURRENT_SECTION':
            return Object.assign({}, state, {
                editedSection: action.payload ? action.payload : defaultState.editedSection
            });

        case 'SET_CURRENT_FIELD_INSTANCE':
            return Object.assign({}, state, {
                editedField: action.payload ? convertReceivedField(action.payload) : defaultState.editedField,
                originalField: action.payload ? convertReceivedField(action.payload) : defaultState.editedField,
                error: null
            });

        case 'SET_CURRENT_TEMPLATE_MANAGE_TAB':
            return Object.assign({}, state, {
                currentTab: action.payload
            });
        case 'VALIDATE_TEMPLATE_FIELD':
            return Object.assign({}, state, {
                isTemplateValid: action.payload
            });
        case 'VALIDATE_FIELD_INSTANCE_FIELD':
            return Object.assign({}, state, {
                fieldValid: action.payload
            });
        case 'SHOW_FIELD_INSTANCE_VALIDATION':
            return Object.assign({}, state, {
                isFIFormValid: action.payload
            });
        case 'SHOW_TEMPLATE_VALIDATION':
            return Object.assign({}, state, {
                isTemplateFormValid: action.payload
            });

        default:
    }
    return state
};

export default templates
