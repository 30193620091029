import React from 'react'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import {getNameFromCode} from "../../utils/helpers";
import {openDeleteModal} from '../../redux/action-creators/users'
import {facilityEditField} from '../../redux/actions/facilitiesActions'
import {withRouter} from "react-router-dom";
import {getAllCompanies} from "../../redux/actions/companiesActions";

class FacilityEditClients extends React.Component {

    constructor(props) {
        super(props);

        this.enumFormatter = this.enumFormatter.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        // this.onDeleteFaciltiy = this.onDeleteFaciltiy.bind(this);
        this.onActionClick = this.onActionClick.bind(this);
    }

    componentDidMount() {
        this.props.getAllCompanies();
    }

    fetchData(data) {
        this.setState(data);
    }

    onRowClick(item) {
        this.props.history.push(`/clients/profile/${item}`)
    }

    enumFormatter(cell, row, enumObject) {
        return enumObject[cell];
    }

    onActionClick(company) {
        const {code} = company;
        const {companyCodes} = this.props.facility;
        const {editField} = this.props;

        if (!Array.isArray(companyCodes)) {
            return editField('companyCodes', [code])
        }

        if (companyCodes.indexOf(code) !== -1) {
            editField('companyCodes', companyCodes.filter((item) => item !== code))
        } else {
            editField('companyCodes', [...companyCodes, code])
        }
    }

    onRowSelect = (row, isSelected) => {
        const {code} = row;
        const {selectedCompanyCodes} = this.props.facility;
        const {editField} = this.props;

        if (!Array.isArray(selectedCompanyCodes)) {
            return editField('selectedCompanyCodes', [code])
        }

        if (isSelected) {
            editField('selectedCompanyCodes', [...selectedCompanyCodes, code])
        } else {
            editField('selectedCompanyCodes', selectedCompanyCodes.filter((item) => item !== code))
        }
        console.log(row);
        console.log(isSelected);
    }

    onSelectAll = (isSelected) => {
        const {editField} = this.props;

        if (isSelected) {
            const data = this.props.data.map((item) => item.code)
            editField('selectedCompanyCodes', data)
        } else editField('selectedCompanyCodes', [])
    }

    render() {
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true
            }, {
                'name': 'fullName',
                'title': 'Full Name',
                'sort': true,
                'width': '200',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'shortName',
                'title': 'Short Name',
                'sort': true,
                'width': '100',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'city',
                'title': 'City',
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'state',
                'title': 'State',
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'country',
                'title': 'Country',
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'linked',
                'title': 'Linking',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': 'Unlinked',
                    'true': 'Linked'
                },
                'width': '200',
                'filter': {
                    type: 'SelectFilter',
                    options: {
                        'false': 'Unlinked',
                        'true': 'Linked',
                    },
                    defaultValue: 'true'
                }
            }
        ];

        const selectRow = {
            mode: 'checkbox',
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll,
        };

        const {sorting, pagination} = this.props.gridConfiguration;
        return (
            <div>
                <RemoteGrid
                    color="green"
                    entity="facility_client"
                    title={`Facility - ${this.props.facility.name}`}
                    data={this.props.data}
                    total={this.props.totalElements}
                    columns={columns}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.onRowClick}
                    selectRow={selectRow}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.app.companies.data
            .map(item => {
                let linked;
                const {companyCodes} = state.app.facilities.editedFacility;
                if (companyCodes) linked = companyCodes.indexOf(item.code) !== -1;
                else linked = false;
                return {
                    ...item,
                    country: getNameFromCode(item.countryCode, state.app.countries.data),
                    state: getNameFromCode(item.stateCode, state.app.states.data),
                    linked
                }
            }),
        totalElements: state.app.companies.data.length,
        facility: state.app.facilities.editedFacility,
        gridConfiguration: state.app.grid.facilityClient,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllCompanies: () => dispatch(getAllCompanies()),
        handleOpenDeleteModal: () => {
            dispatch(openDeleteModal())
        },
        editField: (field, value) => dispatch(facilityEditField(field, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FacilityEditClients));
