import React from 'react';

// Import Brace and the AceEditor Component
import AceEditor from 'react-ace';

// Import a Mode (language)
import 'brace/mode/javascript';

// Import a Theme (okadia, github, xcode etc)
import 'brace/theme/monokai';

export default class CodeEditor extends React.Component {

    render() {
        return (
            <React.Fragment>
                <label className='pull-left'>
                    {this.props.label}
                    {this.props.tickMark}
                </label>
                <AceEditor
                    className={this.props.className + ' pull-right'}
                    mode="javascript"
                    theme="monokai"
                    onChange={this.props.onChange}
                    height={this.props.height ? this.props.height : '400px'}
                    width={'80%'}
                    name="UNIQUE_ID_OF_DIV"
                    fontSize={14}
                    value={this.props.value ? this.props.value : ''}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    readOnly={this.props.readOnly}
                    editorProps={{
                        $blockScrolling: true
                    }}
                />
            </React.Fragment>

        );
    }
}
