import React from 'react'
import RemoteGrid from "../../../components/Grid/RemoteGrid";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {getSCSystem} from "../../../redux/actions/scsystemsActions";
import {getMCSystem} from "../../../redux/actions/mcsystemsActions";

class CodingSystemList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.onRowClick = this.onRowClick.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
    }

    componentDidMount() {
        this.props.getScSystem('');
    }

    onRowClick(item) {
        this.setState({item})
        this.props.getMcSystem(item);
        setTimeout(this.props.history.push(`/coding_system/${item}`), 2000);
    }

    enumFormatter(cell, row, enumObject) {
        return enumObject[cell];
    }

    render() {
        let mcCols = [
            {
                'isKey': true,
                'name': 'code',
                'title': 'Code',
                'sort': false,
                'hidden': true
            }, {
                'name': 'name',
                'title': 'Coding system',
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'serviceS',
                'title': 'Services',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'no': this.context.translate('common.form.no'),
                    'yes': this.context.translate('common.form.yes')
                },
                'width': '200',
                'filter': {
                    type: 'SelectFilter', options: {
                        'no': this.context.translate('common.form.no'),
                        'yes': this.context.translate('common.form.yes')
                    }
                }
            }, {
                'name': 'diagnosisS',
                'title': 'Diagnoses',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'no': this.context.translate('common.form.no'),
                    'yes': this.context.translate('common.form.yes')
                },
                'width': '200',
                'filter': {
                    type: 'SelectFilter', options: {
                        'no': this.context.translate('common.form.no'),
                        'yes': this.context.translate('common.form.yes')
                    }
                }
            }, {
                'name': 'active',
                'title': 'Status',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': 'Inactive',
                    'true': 'Active'
                },
                'width': '200',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': 'Inactive',
                        'true': 'Active'
                    }
                }
            }
        ];
        const {isLoading} = this.props;
        const {sorting, pagination} = this.props.gridConfiguration
        return (
            <div className="table-responsive" style={{marginTop: '15px', "padding": '1px'}}>
                <RemoteGrid
                    color="green"
                    entity="mc_system"
                    title={this.context.translate('common.form.medical_coding_systems')}
                    data={this.props.mc_systems}
                    total={this.props.mc_systems.length}
                    columns={mcCols}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.onRowClick}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                    isLoading={isLoading}
                />
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        gridConfiguration: PropTypes.object,
    }
    static defaultProps = {
        isLoading: false
    };
}

CodingSystemList.contextTypes = {
    translate: PropTypes.func
}

const mapState = (state) => {
    return {
        mc_systems: state.app.mcSystem.data
            .map(item => {
                return {
                    ...item,
                    serviceS: item.service === true ? 'yes' : 'no',
                    diagnosisS: item.diagnosis === true ? 'yes' : 'no'
                }
            }),
        gridConfiguration: state.app.grid.mcSystem,
        isLoading: state.app.mcSystem.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMcSystem: code => dispatch(getMCSystem(code)),
        getScSystem: code => dispatch(getSCSystem(code)),
    }
}

// CodingSystemList = withRouter(CodingSystemList);
export default connect(mapState, mapDispatchToProps)(withRouter(CodingSystemList));
