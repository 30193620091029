import React from 'react';
import {connect} from 'react-redux';
import shortId from 'shortid'
import SimpleReactValidator from "utils/simple-react-validator";
import FormField from '../../components/FormField'
import {createEntity} from '../../redux/actions/BasicEntityActions'
import {getSelectFieldValue} from '../../utils/helpers'
import {getActiveCountries} from '../../redux/actions/countryActions'

class CaseTypeCreateForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: true,
            roleCode: '',
            companyCode: ''
        };

        this.validator = new SimpleReactValidator({
            custom_email:
                {
                    message: 'The Notification email must be a valid email address',
                    rule: (val) => val === '' ||
                        // eslint-disable-next-line no-control-regex,max-len
                        (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/igm).test(val)
                },
        });

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.getActiveCountries();
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            const data = {
                ...this.state,
                code: shortId.generate(),
                gender: getSelectFieldValue(this.state.gender),
                country: getSelectFieldValue(this.state.country),
                state: getSelectFieldValue(this.state.state),
                company: getSelectFieldValue(this.state.company),
                birthDate: this.state.birthDate ? this.state.birthDate.getTime() : null,
            };
            this.props.signUp(data);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onChangeGender(gender) {
        this.setState({gender})
    }

    onChangeBirthDate(birthDate) {
        this.setState({birthDate})
    }

    onChangeCompany(company) {
        this.setState({company})
    }

    onChangeCountry(country) {
        this.setState({country})
    }

    onChangeState(state) {
        this.setState({state})
    }

    render() {
        return (
            <form
                className='modal-content custom-modal-content'
                onSubmit={this.onSubmit}
            >
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            label="First Name"
                            className="form-control"
                            required
                            valids="required|alpha"
                            validator={this.validator}
                            name="firstName"
                            value={this.state.firstName}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            label="Middle Name"
                            className="form-control"
                            id="user_edit_middleName"
                            name="middleName"
                            value={this.state.middleName}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            label="Last Name"
                            className="form-control"
                            required
                            valids="required|alpha"
                            validator={this.validator}
                            id="user_edit_lastName"
                            name="lastName"
                            value={this.state.lastName}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            type='DATE_TIME'
                            label="Birth Date"
                            className='required-field'
                            name="birthDate"
                            valids="required"
                            validator={this.validator}
                            value={this.state.birthDate}
                            onChange={this.onChangeBirthDate.bind(this)}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            icon="envelope-o"
                            type="input-icon"
                            label="Email"
                            className="form-control"
                            name="email"
                            valids='custom_email'
                            validator={this.validator}
                            value={this.state.email}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            type='REF_GENDER'
                            className='required-field'
                            label="gender"
                            name="gender"
                            valids="required"
                            validator={this.validator}
                            value={this.state.gender}
                            onChange={this.onChangeGender.bind(this)}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            label="Address 1"
                            className="form-control"
                            name="addressLine1"
                            value={this.state['addressLine1']}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            label="Address 2"
                            className="form-control"
                            name="addressLine2"
                            value={this.state['addressLine2']}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            label="Apartment"
                            className="form-control"
                            name="apartment"
                            value={this.state['apartment']}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            xs="6"
                            type='REF_COMPANY'
                            label='Company'
                            valids="required"
                            className="required-field"
                            validator={this.validator}
                            value={this.state.company}
                            onChange={this.onChangeCompany.bind(this)}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            type="select"
                            label="Country"
                            className="text-bg "
                            disabled={false}
                            options={this.props.countries}
                            id="user_edit_country"
                            name="countryCode"
                            value={this.state.country}
                            onChange={this.onChangeCountry.bind(this)}
                        />
                        <FormField
                            md="6"
                            xs="6"
                            type='REF_STATE'
                            label='State'
                            name='stateCode'
                            disabled={getSelectFieldValue(this.state.country) !== 'US'}
                            value={this.state.state}
                            onChange={this.onChangeState.bind(this)}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            label="City"
                            className="form-control"
                            name="city"
                            value={this.state.city}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            xs="6"
                            label="Postal Code"
                            className="form-control"
                            id="user_edit_postal_code"
                            name="postalCode"
                            value={this.state.postalCode}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            type="input-icon"
                            icon="phone"
                            label="Primary Phone Number"
                            className="form-control"
                            id="user_edit_phoneNumber"
                            name="primaryPhoneNumber"
                            value={this.state['primaryPhoneNumber']}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            type="input-icon"
                            icon="phone"
                            label="Secondary Phone Number"
                            className="form-control"
                            name="secondaryPhoneNumber"
                            value={this.state.alternativePhoneNumber}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <p className="login-box-msg">
                    {(this.props.error) ?
                        <span className="text-danger">
              {this.props.error}
            </span>
                        :
                        null
                    }
                </p>
                <div className="row no-padding">
                    <div className="col-xs-4 pull-right">
                        <button
                            className='btn btn-block btn-flat btn-primary'
                            disabled={this.props.isLoading}
                        >
                            Add Patient
                        </button>
                    </div>
                </div>

            </form>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.app.patients.loading,
    error: state.app.patients.error,
    profile: {},
    countries: state.app.countries.data
        .map(country => ({
            value: country.code,
            label: country.name
        })),
});

const mapDispatchToProps = dispatch => ({
    getActiveCountries: () => dispatch(getActiveCountries()),
    signUp: (patient) => {
        dispatch(createEntity('patient', patient))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseTypeCreateForm)
