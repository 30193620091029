import { isObjectsEqual } from '../../utils/helpers';

const defaultSpecialtiesState = {
    loading: false,
    loadedCount: 0,
    data: [],
    error: '',
    editSpecialtySuccess: false,
    editedSpecialty: {
        name: '',
        code: null,
        names: {},
        primarySpecialty: true,
        active: true,
        bodyPartCodes: []
    },
    originalSpecialty: {
        name: '',
        code: null,
        names: {},
        primarySpecialty: true,
        active: true,
        bodyPartCodes: []
    },
    specialtyValid: undefined,
    isProfileChanged: false,
    showValidMessages: false,
    lastLoadedTime: 0,
};

const specialties = (state = defaultSpecialtiesState, action) => {
    switch (action.type) {
        case 'FETCH_SPECIALTY_LOADING':
            return {
                ...state,
                loading: true,
                editSpecialtySuccess: false,
                isProfileChanged: false,
                showValidMessages: false,
            };
        case 'FETCH_SPECIALTY_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            });
        case 'FETCH_CREATE_SPECIALTY_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                data: [...state.data, action.payload]
            });

        case 'FETCH_GET_SPECIALTY_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                specialtyValid: true,
                isProfileChanged: false,
                editedSpecialty: action.payload,
                originalSpecialty: action.payload
            };

        case 'FETCH_SPECIALTY_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                specialtyValid: true,
                data: [...state.data, action.payload]
            };
        case 'FILL_SPECIALTY_LOCAL_STORAGE': {
            let items = {};
            for (let i = 0; i < state.data.length; i++) {
                items[ state.data[ i ].code ] = state.data[ i ].name;
            }
            localStorage.setItem('list_specialty', JSON.stringify(items));
            return state;
        }
        case 'FETCH_SPECIALTY_LOADED_COUNT':
            return { ...state, loadedCount: action.payload };

        case 'FETCH_EDIT_SPECIALTY_SUCCESS':
            if(action.payload.code !== action.payload.newCode) {
              action.payload.code = action.payload.newCode
            }
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editSpecialtySuccess: true,
                originalSpecialty: action.payload,
                data: state.data.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            });
        case 'SPECIALTY_EDIT_FIELD':
            return Object.assign({}, state, {
                editedSpecialty: Object.assign({}, state.editedSpecialty, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedSpecialty, action.payload), state.originalSpecialty)
            });
        case 'VALIDATE_SPECIALTY_FIELD':
            return Object.assign({}, state, {
                specialtyValid: action.payload
            });
        case 'FETCH_SPECIALTY_ERROR':
            return { ...state, error: action.payload, loading: false };

        case 'SHOW_SPECIALTY_VALIDATION':
            return Object.assign({}, state, {
                showValidMessages: true
            });
        default:
    }

    return state
};

export default specialties
