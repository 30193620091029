import React from 'react'
import RemoteGrid from "../../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import {
    capitalizeAllFirstLetters,
    convertStyledText,
    fromMatrixToString,
    getLanguagedNameFromCode,
    titleFormatter,
} from '../../../utils/helpers'
import {renderDefaultValue} from "../../../utils/fields";

class FieldInstanceList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.onRowClick = this.onRowClick.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
    }

    onRowClick(item) {
        this.props.history.push(`/templates/field_instances/update/${item}`)
    }

    enumFormatter = (cell, row, enumObject) => {
        return enumObject[cell];
    };

    resetSorting = () => {
        this.props.resetSorting()
    };

    render() {
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true
            }, {
                'name': 'originalFieldCode',
                'title': 'Field Key',
                'sort': true,
                'hidden': false,
                'width': '120',
                'filter': {type: 'TextFilter', delay: 500},
            }, {
                'name': 'name',
                'title': 'Display Name',
                'sort': true,
                'width': '180',
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            }, {
                'name': 'fieldType',
                'title': 'Field Type',
                'width': '150',
                'dataAlign': 'left',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            }, {
                'name': 'defaultValue',
                'title': 'Value',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '180'
            }, {
                'name': 'section',
                'title': 'Section',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '120'
            }, {
                'name': 'sectionOrder',
                'title': 'Section Order',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '120'
            }, {
                'name': 'position',
                'title': 'Order',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '100'
            }, {
                'name': 'visibilityMatrix',
                'title': 'Visibility',
                'dataFormat': titleFormatter,
                'columnTitle': false,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '250'
            }, {
                'name': 'readOnlyMatrix',
                'title': 'Read Only',
                'dataFormat': titleFormatter,
                'columnTitle': false,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '250'
            }, {
                'name': 'requiredMatrix',
                'title': 'Required',
                'dataFormat': titleFormatter,
                'columnTitle': false,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '250'
            }
        ];
        const {sorting, pagination} = this.props.gridConfiguration;
        return (
            <div className="container-fluid table-responsive">
                <RemoteGrid
                    color="blue"
                    title={`Template - ${this.props.templateName}`}
                    entity="field_instance"
                    data={this.props.data}
                    total={this.props.totalElements}
                    columns={columns}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.onRowClick}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                    multiColumnSort={2}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
            </div>
        )
    }
}

const mapState = (state) => {
    return {
        data: state.app.templates.fieldInstancesDto.map((item) => {
            return {
                ...item,
                name: convertStyledText(item.name),
                helpTextStaff: item.helpTextStaff ? convertStyledText(item.helpTextStaff['EN']) : item.helpTextStaff,
                helpTextReviewer: item.helpTextReviewer ? convertStyledText(item.helpTextReviewer['EN']) : item.helpTextReviewer,
                helpTextClient: item.helpTextClient ? convertStyledText(item.helpTextClient['EN']) : item.helpTextClient,
                fieldType: capitalizeAllFirstLetters(item.fieldType),
                position: item.position,
                readOnly: item.readOnly,
                defaultValue: renderDefaultValue(item),
                customClasses: item.customClasses ? item.customClasses.join(' ') : item.customClasses,
                defaultOptions: item.defaultOptions ? Object.entries(item.defaultOptions).map((item) => item.join(': ')).join(', ') : item.defaultOptions,
                section: getLanguagedNameFromCode(item.sectionCode, state.app.templates.sectionDto, 'EN'),
                sectionOrder: item.sectionOrder,
                order: item.position,
                requiredMatrix: fromMatrixToString(item.requiredMatrix, true),
                formattedRequiredMatrix: fromMatrixToString(item.requiredMatrix),
                visibilityMatrix: fromMatrixToString(item.visibilityMatrix, true),
                formattedVisibilityMatrix: fromMatrixToString(item.visibilityMatrix),
                readOnlyMatrix: fromMatrixToString(item.readOnlyMatrix, true),
                formattedReadOnlyMatrix: fromMatrixToString(item.readOnlyMatrix),
            }
        }),
        totalElements: state.app.templates.fieldInstancesDto.length,
        gridConfiguration: state.app.grid.fieldInstance,
        templateName: state.app.templates.name,
    }
};

const mapDispatchToProps = () => {
    return {}
};

export default connect(mapState, mapDispatchToProps)(FieldInstanceList);
