import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import ColoredBox from '../../../components/ColoredBox/ColoredBox';
import ConnectedDataTable from '../../../components/Datatable/ConnectedDataTable';

import Spinner from '../../Develop/Spinner';
import { get } from 'lodash';

const LinkingConnectedDataTable = (
  {
    stateKey,
    actions,
    title,
    onLinkClick,
    onUnlinkClick,
    medicalCodingSystemCodes,
    ...props
  },
) => {

  const { code } = useSelector((state) => {
    return state.app.companies.editedCompany;
  });

  const tableState = useSelector((state) => get(state.app, stateKey));

  const { selectedEntities, loadingLink, loadingUnlink, settings: datatableSettings } = tableState;

  const { search } = useSelector((state) => {
    // console.log('APP:', state.app);
    return state.app.globalSearch;
  });

  const dispatch = useDispatch();

  const connectedDataTableRef = useRef();

  useEffect(() => {
    // console.log('useEffect');
    dispatch(actions.resetState());
  }, [actions, dispatch]);

  useEffect(() => {
    if (code) {
      dispatch(actions.loadData({ ...datatableSettings, globalSearch: search, code: code, medicalCodingSystemCodes: medicalCodingSystemCodes }));
    }
  }, [actions, dispatch, datatableSettings, search, code, medicalCodingSystemCodes]);

  const handleRowSelect = (id, rowData) => {
    // console.log('handleRowSelect', id, ':::', rowData);
    dispatch(actions.addSelectedEntities([rowData]));
  };

  const handleRowDeselect = (id, rowData) => {
    // console.log('handleRowDeselect', id, ':::', rowData);
    dispatch(actions.removeSelectedEntities([rowData]));
  };

  const handleRowSelectAll = (idArr, rowDataArr) => {
    // console.log('handleRowSelectAll', idArr, ':::', rowDataArr);
    dispatch(actions.addSelectedEntities(rowDataArr));
  };

  const handleRowDeselectAll = (idArr, rowDataArr) => {
    // console.log('handleRowDeselectAll', idArr, ':::', rowDataArr);
    dispatch(actions.removeSelectedEntities(rowDataArr));
  };

  const handleResetSelectedPhysicians = () => {
    // console.log('handleResetSelectedPhysicians');
    if (selectedEntities.length === 0) {
      return;
    }
    dispatch(actions.resetEntities());
    connectedDataTableRef.current.resetSelectedRows();
  };

  return (
    <ColoredBox variant="success" title={title}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginLeft: '50px' }}>
        <Button
          style={{ width: '80px', display: 'inline-flex', justifyContent: 'center', marginRight: '10px' }}
          onClick={onLinkClick}
          disabled={loadingLink || loadingUnlink}
        >
          <span>{'Link'}</span>&nbsp;{loadingLink && <Spinner />}
        </Button>
        <Button
          style={{ width: '80px', display: 'inline-flex', justifyContent: 'center' }}
          onClick={onUnlinkClick}
          disabled={loadingLink || loadingUnlink}
        >
          <span>{'Unlink'}</span>&nbsp;{loadingUnlink && <Spinner />}
        </Button>
        {selectedEntities && selectedEntities.length > 0 &&
        <>
          <div style={{ display: 'inline-flex', marginLeft: '30px', marginRight: '10px' }}>
            {selectedEntities && selectedEntities.length} Selected
          </div>
          <Button
            style={{ width: '120px', display: 'inline-flex', justifyContent: 'center' }}
            onClick={handleResetSelectedPhysicians}
          >Clear Selection</Button>
        </>
        }
      </div>
      <ConnectedDataTable
        ref={connectedDataTableRef}
        defaultLoadData={false}
        onRowSelect={handleRowSelect}
        onRowDeselect={handleRowDeselect}
        onRowSelectAll={handleRowSelectAll}
        onRowDeselectAll={handleRowDeselectAll}
        variant="success"
        actions={actions}
        stateKey={stateKey}
        keyExtractor={({ code }) => code}
        selectedKeys={selectedEntities && selectedEntities.map(({ code }) => code)}
        selectable
        {...props}
      />
    </ColoredBox>
  );
};

LinkingConnectedDataTable.propTypes = {
  stateKey: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  title: PropTypes.string,
  onLinkClick: PropTypes.func,
  onUnlinkClick: PropTypes.func,
  medicalCodingSystemCodes: PropTypes.array,
};

export default LinkingConnectedDataTable;
