import React from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import SubmitButton from '../../../components/SubmitButton'
import {dispatchValidStatus, updateField} from '../../../redux/actions/fieldsActions'
import convertFieldData from '../../../utils/fields'

class CreateFieldButton extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        e.preventDefault();
        const {field} = this.props;

        if (this.props.fieldValid) {
            const data = convertFieldData(field);

            this.props.updateField(data, closeAfterSave)
        } else this.props.dispatchValidStatus(false)
    }

    render() {
        return (
            <React.Fragment>
                <SubmitButton color='blue' disabled={!this.props.isProfileChanged} onSubmit={this.onSave}
                              showTooltip={!this.props.fieldValid}/>
                <SubmitButton color='blue' disabled={!this.props.isProfileChanged}
                              onSubmit={(e) => this.onSave(e, true)} title='Save And Close'
                              showTooltip={!this.props.fieldValid}/>
            </React.Fragment>
        )
    }
}

CreateFieldButton.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    field: state.app.fields.editedField,
    fieldValid: state.app.fields.fieldValid,
    isProfileChanged: state.app.fields.isProfileChanged,
});

const mapDispatchToProps = dispatch => ({
    updateField: (data, closeAfterSave) => dispatch(updateField(data, closeAfterSave)),
    dispatchValidStatus: (data) => dispatch(dispatchValidStatus(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFieldButton)
