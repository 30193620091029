import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { transitionCase } from '../../../redux/actions/cases/caseListActions';
import { showSearchPanel } from '../../../redux/actions/gridActions';
import { setCaseMessage, setSubmitAfterLoad, setSubmitAfterValidation, showNotValidMessage } from '../../../redux/actions/cases/caseActions';
import { withRouter } from 'react-router-dom';
import { isClient } from '../../../utils/helpers';
import { fetchCaseCount } from 'redux/action-creators/init';
import { loadCaseLocalStorageData } from '../../../utils/case-helpers';

class CaseSubmit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitProcess: false,
            company: '',
        };

        this.clickSubmit = this.clickSubmit.bind(this);
    }

    transitCase(caseCode, transitionCode) {
        if (transitionCode === 'CW_DISPATCH_TO_CW_REVIEW') {
            const { assignmentDetails } = this.props;
            if (!assignmentDetails || assignmentDetails.length === 0) {
                this.setState({ isSubmitProcess: false });
                return this.props.setCaseMessage({
                    text: 'For submitting a case you have to assign reviewer.',
                    type: 'error',
                    time: 8500,
                });
            }
        }
        this.props
            .transitionCase({
                caseCode: caseCode,
                transitionCode: transitionCode,
            })
            .then(
                () => {
                    // console.log('CaseSubmit transitionCase then 1', this.props.caseBackendMessage);
                    this.props.setSubmitAfterLoad();
                    this.props.setSubmitAfterValidation();
                    if (!this.props.caseBackendMessage) {
                        this.props.history.push(`/home`);
                    }
                    this.setState({ isSubmitProcess: false });
                    this.props.fetchCaseCount();
                },
                () => {
                    this.props.setSubmitAfterLoad();
                    this.props.setSubmitAfterValidation();
                    if (this.props.selectedCaseCodes[0]) {
                        this.props.history.push(`/home`);
                    }
                },
            );
    }

    clickSubmit = () => () =>  {
        this.setState({ isSubmitProcess: true });
        let transitionCode = '';
        let caseInner = this.props.currentPath.indexOf('case/') === 1;

        let caseCode = '';
        let selectedCase = '';
        let currentStageCode = '';
        if (caseInner) {
            caseCode = this.props.case.code;
            selectedCase = this.props.case;
            currentStageCode = selectedCase.currentStage.code ? selectedCase.currentStage.code : '';

            if (currentStageCode) {
                switch (currentStageCode) {
                    case 'CW_DRAFT':
                        transitionCode = 'CW_DRAFT_TO_CW_DISPATCH';
                        break;
                    case 'CW_DISPATCH':
                        transitionCode = 'CW_DISPATCH_TO_CW_REVIEW';
                        break;
                    case 'CW_REVIEW':
                        transitionCode = 'CW_REVIEW_TO_CW_QA';
                        break;
                    case 'CW_QA':
                        transitionCode = 'CW_QA_TO_CW_CLOSED';
                        break;
                    default:
                        transitionCode = null;
                }
            }

            let newValues = loadCaseLocalStorageData(caseCode, 'values');
            let savedValues = loadCaseLocalStorageData(caseCode, 'values_save');
            compareJSONObjects(newValues, savedValues.fieldData)

            if(!compareJSONObjects(newValues, savedValues.fieldData)) {
              this.props.createErrorMessage()
              return;
            }
            if (this.props.isValid) {
                this.transitCase(caseCode, transitionCode);
                this.props.showSearchPanel();               
            } else {
                this.props.showNotValidMessage(true);
                this.setState({ isSubmitProcess: false });
            }
        }
    }

    render() {
        // console.log(this.props.financeLoading);
        let caseInner = this.props.currentPath.indexOf('case/') === 1;
        let isNotClickAble = false;
        let submitHint = '';
        let selectedCase = null;
        let currentStageCode = null;

        if (caseInner) {
            selectedCase = this.props.case;
            currentStageCode = selectedCase.currentStage ? selectedCase.currentStage.code : '';
        }

        if (selectedCase != null && currentStageCode != null) {
            // CLIENT can submit only from DRAFT
            if (isClient(this.props.currentUser.roleCode)) {
                isNotClickAble = currentStageCode.indexOf('DRAFT') === -1;
                if (isNotClickAble) {
                    submitHint = 'Client cannot submit';
                }
            }

            // check for CLOSED
            if (!isNotClickAble) {
                isNotClickAble = currentStageCode.indexOf('CLOSED') !== -1;
                if (isNotClickAble) {
                    submitHint = 'Closed Case cannot submit';
                }
            }
            if (!isNotClickAble && (this.props.loading === true || this.state.isSubmitProcess)) {
                isNotClickAble = true;
            }

            if (this.props.loading === false && this.props.submitAfterLoad === true && this.props.submitAfterValidation === true) {
                this.props.setSubmitAfterLoad();
                this.props.setSubmitAfterValidation();
                this.clickSubmit();
            }
        }

        if (this.props.financeLoading) {
            isNotClickAble = true;
        }

        const btnColor = isNotClickAble ? '' : 'btn-success';
        const isVisible = currentStageCode && currentStageCode.indexOf('CLOSED') === -1;

        return (
            <span style={{ display: isVisible ? 'inline-block' : 'none' }}>
                <button
                    className={`btn btn-default ${btnColor} resizable-button tooltip-button margin-left-5`}
                    onClick={this.clickSubmit()}
                    disabled={isNotClickAble}
                    title={submitHint ? submitHint : 'Submit'}
                >
                    <i className={`fa fa-share `} />
                </button>
            </span>
        );
    }
}

CaseSubmit.contextTypes = {
    translate: PropTypes.func,
};

function compareJSONObjects(newValues, savedValues) {
  if (Object.keys(newValues).length !== Object.keys(savedValues).length) {
      return false;
  }

  for (const key in newValues) {
      if (!(key in savedValues) || newValues[key] !== savedValues[key]) {
          return false;
      }
  }
  return true;
}

const mapState = (state) => {
    // console.log('CASESUBMIT app', state.app);
    return {
        loading: state.app.cases.loading,
        financeLoading: state.app.finances.isLoading,
        submitAfterLoad: state.app.cases.submitAfterLoad,
        submitAfterValidation: state.app.cases.submitAfterValidation,
        case: state.app.cases.editedCase,
        isValid: state.app.cases.isAllValid,
        currentPath: state.router.location.pathname,
        currentUser: state.app.auth.user,
        selectedCaseCodes: state.app.case_list.selectedCaseCodes,
        selectedCaseCodesCount: state.app.case_list.selectedCaseCodes.length,
        assignmentDetails: state.app.case_assign.appliedReviewers.filter((item) => item.tier !== 'NO_TIER'),
        caseBackendMessage: state.app.cases.caseBackendMessage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createErrorMessage: () => dispatch({ type: 'CASE_FIELD_UPDATE_ERROR' }),
        transitionCase: (data) => dispatch(transitionCase(data)),
        setCaseMessage: (data) => dispatch(setCaseMessage(data)),
        showNotValidMessage: (data) => dispatch(showNotValidMessage(data)),
        setSubmitAfterLoad: () => dispatch(setSubmitAfterLoad(false)),
        setSubmitAfterValidation: () => dispatch(setSubmitAfterValidation(false)),
        showSearchPanel: () => dispatch(showSearchPanel()),
        fetchCaseCount: () => dispatch(fetchCaseCount()),
    };
};

export default connect(mapState, mapDispatchToProps)(withRouter(CaseSubmit));
