import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'
import PropTypes from "prop-types";
import { editFacility, facilityEditField } from '../../../redux/actions/facilitiesActions'
import { onSort } from "../../../redux/actions/gridActions";

class SaveEntity extends React.Component {

    onLink = () => {
        const { editField, companyCodes, selectedCompanyCodes } = this.props;
        let physiciansSet = new Set([...companyCodes, ...selectedCompanyCodes]);
        editField('companyCodes', Array.from(physiciansSet));
        this.props.sort('linked', 'desc');
    }

    onUnlink = () => {
        const { editField, companyCodes, selectedCompanyCodes } = this.props;
        const physicians = companyCodes.filter(item => selectedCompanyCodes.indexOf(item) === -1)
        editField('companyCodes', physicians);
        this.props.sort('linked', 'asc');
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <span>
                <SubmitButton color='grey' onSubmit={this.onLink} showTooltip={false} title='Link'/>
                <SubmitButton color='grey' onSubmit={this.onUnlink} showTooltip={false} title='Unlink'/>
            </span>
        )
    }
}

SaveEntity.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => ({
    companyCodes: state.app.facilities.editedFacility.companyCodes || [],
    selectedCompanyCodes: state.app.facilities.editedFacility.selectedCompanyCodes,
    isEditable: state.app.users.my_permissions.includes('MODIFY_FACILITIES'),
})

const mapDispatchToProps = dispatch => ({
    saveFacilityProfile: data => dispatch(editFacility(data)),
    editField: (field, value) => dispatch(facilityEditField(field, value)),
    sort: (value, dir) => dispatch(onSort(value, dir))
})

export default connect(mapStateToProps, mapDispatchToProps)(SaveEntity)
