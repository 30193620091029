import { connect } from 'react-redux'
import RefField from './RefField'
import { getOne4Select, setLoadedCount } from "../../redux/actions/BasicEntityActions";
import { getAllCompaniesMinimal } from "../../redux/actions/financialReportActions";



const mapStateToProps = (state) => ({
    isLoading: state.app.companies.loading,
    loadedCount: state.app.companies.loadedCount,
    options: state.app.financialReport.companiesMinimalData
        .map(item => ({
            value: item.code,
            label: item.fullName,
            defaultLanguage: item.defaultLanguage,
            supportedLanguages: item.supportedLanguages,
            templateCode: item.templateCode
        })),
})

const mapDispatchToProps = (dispatch) => ({
    // getOptions: () => dispatch(getAllCompanies()),
    getOptions: () => dispatch(getAllCompaniesMinimal()),
    getOne4Select: (code) => dispatch(getOne4Select(code, 'company')),
    setLoadedCount: (count) => dispatch(setLoadedCount(count, 'company'))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefField)
