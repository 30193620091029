import api from "../../utils/api";
import { Statuses } from "./statuses";
import { getSelectFieldValue, isEntityLoaded } from "../../utils/helpers";
import history from "../history";

const isAvailableInCache = (billing) =>
    isEntityLoaded(billing) && billing.codes && billing.codes.length > 0;

export const getAllBillingCodes = (billing) => (dispatch) => {
    if (isAvailableInCache(billing)) {
        return;
    }
    dispatch({
        type: 'FETCH_BILLING_CODES_LOADING'
    });
    api.get('billing/group/all')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_BILLING_CODES_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_BILLING_CODES_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_BILLING_CODES_ERROR',
                    payload: error.message
                });
            }
        })
};

export const getCompanyBillingTiers = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_COMPANY_BILLING_TIERS_LOADING'
    });

    let { billingGroup } = getState().app.companies.editedCompany;
    billingGroup = getSelectFieldValue(billingGroup);

    billingGroup && api.get(`billing/group/get?code=${billingGroup}`)
        .then(({ data }) => data.billingTiers)
        .then(tiers => tiers.length ? api.post('billing/tier/determined', tiers) : null)
        .then((response) => {
            dispatch({
                type: 'FETCH_COMPANY_BILLING_TIERS_SUCCESS',
                payload: response ? response.data : []
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_COMPANY_ERROR',
                payload: err.message
            });
        })
};

export const getFullBillingGroup = (code) => (dispatch) => {
    dispatch({
        type: 'FETCH_BILLING_GROUP_LOADING',
    });
    let billingGroup;
    const emptyResponse = { data: [] };

    return api.get(`billing/group/get?code=${code}`)
        .then(({ data }) => {
            billingGroup = data;
            const { companies } = billingGroup;
            return companies && companies.length ? api.post('company/determined', companies) : emptyResponse
        })
        .then(({ data }) => {
            billingGroup = { ...billingGroup, companies: data };
            const { billingTiers } = billingGroup;
            return billingTiers && billingTiers.length ? api.post('billing/tier/determined', billingTiers) : emptyResponse
        })
        .then(({ data }) => {
            billingGroup = { ...billingGroup, billingTiers: data };
            dispatch({
                type: 'FETCH_BILLING_GROUP_PROFILE_SUCCESS',
                payload: billingGroup
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_BILLING_GROUP_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ]
            })
        })
};

export const updateTierNumber = (tier, step) => (dispatch, getState) => {
    let myTier = Object.assign({}, tier);

    const { billingTiers } = getState().app.billing_group.editedBillingGroup;

    billingTiers.sort((a, b) => {
        if (a.tierNumber > b.tierNumber) return 1;
        if (a.tierNumber < b.tierNumber) return -1;
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 0;
    });
    if (step === -1) billingTiers.reverse();

    let changedTier = {};
    let next = false;
    let firstNumber = 0;
    let changeNumber = 0;
    for (let i = 0, len = billingTiers.length; i < len; i++) {
        let curTier = billingTiers[ i ];
        if (next) {
            changedTier = Object.assign({}, curTier);
            changeNumber = step === 1 ? i + 1 : len - i;
            next = false;
        }
        if (curTier.code === myTier.code) {
            firstNumber = step === 1 ? i + 1 : len - i;
            next = true;
        }
    }

    if (typeof changedTier.tierNumber !== "undefined" && firstNumber > 0 && changeNumber > 0) {
        dispatch({
            type: 'FETCH_BILLING_GROUP_LOADING'
        });

        changedTier.tierNumber = firstNumber;
        myTier.tierNumber = changeNumber;
        changedTier.name = 'Tier ' + firstNumber;
        myTier.name = 'Tier ' + changeNumber;

        if (changedTier.reviewerTypes === 'Any') changedTier.reviewerTypes = [];
        if (changedTier.caseTypes === 'Any') changedTier.caseTypes = [];
        if (myTier.reviewerTypes === 'Any') myTier.reviewerTypes = [];
        if (myTier.caseTypes === 'Any') myTier.caseTypes = [];

        return api.post(`billing/tier/update`, changedTier)
            .then(() => api.post(`billing/tier/update`, myTier))
            .then(() => {
                setTimeout(() => {
                    dispatch({
                        type: 'UPDATE_TIER_NUMBER_SUCCESS',
                        payload: {
                            [ myTier.code ]: myTier,
                            [ changedTier.code ]: changedTier,
                        }
                    })
                }, 300);
            })
            .catch((err) => {
                dispatch({
                    type: 'FETCH_BILLING_GROUP_ERROR',
                    payload: Statuses[ err.response ] || Statuses[ 0 ]
                })
            })
    }
};

export const setCurrentTier = (tier) => {
    return {
        type: 'SET_CURRENT_TIER',
        payload: tier
    }
};

export const updateBillingGroup = (data, closeAfterSave) => (dispatch) => {
    dispatch({
        type: `FETCH_BILLING_GROUP_LOADING`,
    });

    return api.post('billing/group/update', data)
        .then(({ config }) => {
            dispatch({
                type: `FETCH_UPDATE_BILLING_GROUP_SUCCESS`,
                payload: JSON.parse(config.data)
            });
            dispatch(getAllBillingCodes());
            closeAfterSave && history.goBack();
        })
        .catch((err) => {
            let errorMessage = '';
            switch (err.response && err.response.status) {
                case 409:
                    errorMessage = 'Cannot change currency because at least one client linked to the Billing group.';
                    break;
                default:
                    errorMessage = 'Unknown Error';
            }
            dispatch({
                type: `FETCH_UPDATE_BILLING_GROUP_ERROR`,
                payload: errorMessage
            })
        })
};
