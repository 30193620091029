import React, {Component} from "react";
import {connect} from 'react-redux'
import FormField from '../../components/FormField'
import {editPhysician, editField, createNewPhysician} from '../../redux/actions/physicianActions'
import {getMultiSelectValue, getSelectFieldValue} from '../../utils/helpers'
import PropTypes from "prop-types";
import shortId from "shortid";
import BoxHeader from "../../components/BoxHeader";
import BoxBody from "../../components/BoxBody";
import Box from "../../components/Box";

class PhysicianProfessionalInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.onChange = this.onChange.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeSpecialty = this.onChangeSpecialty.bind(this);
        this.onChangeLanguagesCode = this.onChangeLanguagesCode.bind(this);
        this.onChangeBodyParts = this.onChangeBodyParts.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.Submit = this.Submit.bind(this);
    }

    componentDidMount() {
        setTimeout(this.props.dispatchValidState, 500)

    }

    Submit(e) {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            this.props.newPhysician({
                ...this.props.physician,
                code: shortId.generate(),
                countryCode: getSelectFieldValue(this.props.physician.countryCode),
                stateCode: getSelectFieldValue(this.props.physician.stateCode),
                specialtyCodes: getMultiSelectValue(this.props.physician.specialtyCodes),
                bodyPartCodes: getMultiSelectValue(this.props.physician.bodyPartCodes),
                languageCodes: getMultiSelectValue(this.props.physician.languageCodes)
            });
        } else {
            // this.setState({messagesShown: true})
            this.props.showMessages()
        }
    }

    onChange(e) {
        this.props.editField(e.target.name, e.target.value);


    }

    changeCountry(countryCode) {
        this.props.editField('countryCode', countryCode);
        if (countryCode) {
            if (countryCode.value !== 'US') {
                this.props.editField('stateCode', null);
            }
        } else {
            this.props.editField('stateCode', null)
        }
    }

    onChangeState(stateCode) {
        this.props.editField('stateCode', stateCode)

    }

    onChangeLanguagesCode(languageCodes) {
        this.props.editField('languageCodes', languageCodes)


    }

    onChangeSpecialty(specialtyCodes) {
        this.props.editField('specialtyCodes', specialtyCodes)

    }

    onChangeBodyParts(bodyPartCodes) {
        this.props.editField('bodyPartCodes', bodyPartCodes)

    }

    onChangeCheckBox(e) {
        this.props.editField(e.target.name, e.target.checked)
    }

    render() {
        const {isEditable} = this.props;
        return (
            <Box color="green" classes="box-profile-first-row-height-show">
                <BoxHeader title={`Physician - ${this.props.physician.firstName} ${this.props.physician.lastName}`}
                           color="green"
                           icon="fa-list-alt" classes="box-profile-first-row-height-show"/>
                <BoxBody>
                    <form onSubmit={this.Submit} noValidate autoComplete="off">

                        <div className="form-group bordered">
                            <div className="row">
                                <FormField
                                    md="8"
                                    type="REF_SPECIALTY"
                                    label={this.context.translate('common.form.specialties')}
                                    id={this.context.translate('common.form.specialties')}
                                    className="required-field"
                                    required
                                    value={this.props.physician.specialtyCodes}
                                    onChange={this.onChangeSpecialty}
                                    onBlur={this.props.dispatchValidState}
                                    validator={this.props.validator}
                                    name="specialtyCodes"
                                    valids="required"
                                    multi
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <div className="form-group bordered">
                            <div className="row">
                                <FormField
                                    md="8"
                                    type="REF_BODY_PART"
                                    label={this.context.translate('common.form.body_parts')}
                                    id={this.context.translate('common.form.body_parts')}
                                    className="required-field"
                                    name="bodyPartCodes"
                                    required
                                    value={this.props.physician.bodyPartCodes}
                                    onChange={this.onChangeBodyParts}
                                    onBlur={this.props.dispatchValidState}
                                    validator={this.props.validator}
                                    valids="required"
                                    multi
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>

                        <div className="form-group bordered">
                            <div className="row">
                                <FormField
                                    md="8"
                                    type="select"
                                    label={this.context.translate('common.form.tables.languages')}
                                    multi={true}
                                    options={this.props.languages}
                                    id="user_edit_language"
                                    name="languageCodes"
                                    value={this.props.physician.languageCodes}
                                    onChange={this.onChangeLanguagesCode}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>

                        <div className="form-group bordered">
                            <div className='row'>
                                <FormField
                                    md="4"
                                    type="checkbox"
                                    title={this.context.translate('common.form.private_facility')}
                                    label={this.context.translate('common.form.private_facility')}
                                    name='private_facility'
                                    value={this.props.physician.private_facility}
                                    checked={this.props.physician.private_facility}
                                    onChange={this.onChangeCheckBox}
                                    className="checkbox"
                                    disabled={!isEditable}
                                />

                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className='col-md-8 col-xs-6 fields'>
                                    <label className="">{this.context.translate('common.form.notes')}</label>
                                    <textarea name="notes" id="" cols="50" rows="8" value={this.props.physician.notes}
                                              onChange={this.onChange} disabled={!isEditable}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </BoxBody>
            </Box>
        );
    }
}

PhysicianProfessionalInfo.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        physician: state.app.physician.editedPhysician,
        error: state.app.physician.editGeneralError,
        isLoading: state.app.physician.loading,
        countries: state.app.countries.data
            .map(country => ({
                value: country.code,
                label: country.name
            })),
        languages: state.app.languages.data
            .filter((item) => item.active)
            .map(lang => ({
                value: lang.code,
                label: lang.name
            }))

    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveProfile: data => dispatch(editPhysician(data, 'admin')),
        newPhysician: data => dispatch(createNewPhysician(data)),
        editField: (field, value) => dispatch(editField(field, value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianProfessionalInfo);
