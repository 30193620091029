import { connect } from 'react-redux'
import { getAllCaseTypes } from '../../redux/actions/cases/caseTypesActions'
import RefField from './RefField'
import {getOne4Select, setLoadedCount} from "../../redux/actions/BasicEntityActions";

const mapStateToProps = (state) => ({
    isLoading:  state.app.case_types.loading,
    loadedCount: state.app.case_types.loadedCount,
    options: state.app.case_types.types
        .filter((item) => item.active)
        .map(item => ({
            value: item.code,
            label: item.name
        })),
})

const mapDispatchToProps = (dispatch) => ({
    getOptions: () => dispatch(getAllCaseTypes()),
    getOne4Select: (code) => dispatch(getOne4Select(code, 'case_type')),
    setLoadedCount: (count) => dispatch(setLoadedCount(count, 'case_type'))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefField)
