import isEqual from 'lodash/isEqual';

const mutationInitialState = {
    entity: {},
    entityOriginal: {},
    isListLoading: false,
    isFormValid: false,
    isFormChanged: false,
    isUpdateSuccess: false,
    isCreateSuccess: false,
    isLoading: false,
    error: [],
    serverErrors: [],
    inputStates: {},
};

export const initialInputState = {
    isTouched: false,
    isFocused: false,
    isVisited: false,
    isSubmitted: false,
    isValid: true,
};

const createEntityMutationTemplateSlice = ({ initialState = {}, reducers = {}, ...sliceConfig }) => {
    const { entity: initialEntity } = initialState;
    return {
        ...sliceConfig,
        initialState: { ...mutationInitialState, ...initialState },

        reducers: {
            setInputStates: (state, { payload: { name, stateObject } }) => {
                const inputState = state.inputStates[name] || initialState;
                state.inputStates[name] = { ...inputState, ...stateObject };
            },
            submitFields: (state) => {
                state.serverErrors = [];
                Object.keys(state.inputStates).forEach((field) => {
                    state.inputStates[field] = { ...initialInputState, isSubmitted: true };
                });
            },
            editField: (state, { payload: changedEntity }) => {
                const newEntity = { ...state.entity, ...changedEntity };
                state.entity = newEntity;
                state.isFormChanged = !isEqual(newEntity, state.entityOriginal);
            },
            setServerErrors: (state, { payload: fieldErrors }) => {
                console.log('setServerErrors', fieldErrors);
                state.serverErrors = fieldErrors;
            },
            setValidityStatus: (state, { payload: isFormValid }) => {
                console.log('setValidityStatus', isFormValid);
                state.isFormValid = isFormValid;
            },
            setFieldValidity: (state, { payload: { field, validity } }) => {
                console.log('setFieldValidity', field, ':::', validity);
                const newState = { ...state.inputStates[field], isValid: validity };
                const inputStates = { ...state.inputStates, [field]: newState };
                const isFormValid = Object.values(inputStates).every(({ isValid }) => isValid);

                state.inputStates[field] = newState;
                state.isFormValid = isFormValid;
            },
            setFieldValidities: (state, { payload: fieldValidities }) => {
                Object.keys(fieldValidities).forEach((field) => {
                    state.inputStates[field] = { ...state.inputStates[field], isValid: fieldValidities[field] };
                });

                state.isFormValid = Object.values(fieldValidities).every((isValid) => isValid);
            },
            redirectAfterSave: () => {},
            resetEntity: (state) => {
                state.serverErrors = [];
                state.error = null;
                state.isLoading = false;
                state.isFormValid = false;
                state.isFormChanged = false;
                state.entity = initialEntity;
                state.entityOriginal = initialEntity;

                Object.keys(initialEntity).forEach((field) => {
                    state.inputStates[field] = { ...initialInputState, isValid: false };
                });
            },
            createEntityLoading: (state) => {
                state.isLoading = true;
                state.isUpdateSuccess = false;
                state.isFormValid = true;
            },
            createEntitySuccess: (state) => {
                state.isLoading = false;
                state.isCreateSuccess = true;
                state.error = null;
            },
            createEntityError: (state, { payload }) => {
                state.error = payload;
                state.isLoading = false;
                state.isCreateSuccess = false;
            },
            fetchEntityLoading: (state) => {
                state.serverErrors = [];
                state.isLoading = true;
                state.isUpdateSuccess = false;
                state.isFormValid = true;
                state.error = null;
                state.entity = initialEntity;
                state.entityOriginal = initialEntity;
            },
            fetchEntitySuccess: (state, { payload }) => {
                state.isLoading = false;
                state.isUpdateSuccess = false;
                state.isFormValid = true;
                state.isFormChanged = false;
                state.error = null;
                state.entity = payload;
                state.entityOriginal = payload;
                Object.keys(initialEntity).forEach((field) => {
                    state.inputStates[field] = { ...initialInputState, isValid: true };
                });
                // console.log('fetchEntitySuccess', state, payload, state.entity);
            },
            fetchEntityError: (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
            },
            updateEntityLoading: (state) => {
                state.isLoading = true;
                state.isUpdateSuccess = false;
                state.isFormValid = true;
                state.error = null;
            },
            updateEntitySuccess: (state, { payload }) => {
                state.isLoading = false;
                state.isUpdateSuccess = true;
                state.isFormValid = true;
                state.isFormChanged = false;
                state.error = null;
                state.entityOriginal = payload;
            },
            updateEntityError: (state, { payload }) => {
                state.isLoading = false;
                state.isUpdateSuccess = false;
                state.error = payload;
            },
            ...reducers,
        },
    };
};

export default createEntityMutationTemplateSlice;
