import React from 'react';

const Spinner = () => {
  return (
    <div className={'spinner'}>
      <i className="fa fa-refresh fa-spin fa-3x fa-fw" aria-hidden="true" />
    </div>
  );
};

export default Spinner;
