import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DataTableContext from './DataTableContext';
import Select from 'react-select';
import DataTableFilterInput from './DataTableFilterInput';

const DataTableFilterField = ({
  label,
  columnKey,
  onChange,
  defaultValue,
  options,
  multiSelect,
  filterable,
}) => {
  const ref = useRef();
  const [clearVisibility, setClearVisibility] = useState(!!defaultValue);

  const { filters, serverside } = useContext(DataTableContext);

  const handleChange = () => {
    onChange(columnKey, ref.current.value);
    setClearVisibility(ref.current.value);
  };

  const handleClear = () => {
    onChange(columnKey, '');
    ref.current.value = '';
    setClearVisibility(false);
  };

  const handleSelectChange = (value) => {
    const newValue = value ? value.value : '';
    onChange(columnKey, newValue);
  };

  const handleMultiSelectChange = (value) => {
    const mappedValue = value ? value.map(({ value }) => value) : [];
    const newValue = value === null || value.length === 0 ? '' : mappedValue;
    onChange(columnKey, newValue);
  };

  const value = options && options.find((o) => o.value === filters[columnKey]);

  return (
    <th className="datatable__filter-cell">
      {options ? (
        <Select
          className="legacy-select"
          classNamePrefix="legacy-select"
          onChange={multiSelect ? handleMultiSelectChange : handleSelectChange}
          options={options}
          value={value}
          isMulti={multiSelect && !serverside}
          disabled={!filterable}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          isClearable
        />
      ) : (
        <DataTableFilterInput
          ref={ref}
          onChange={handleChange}
          onClear={handleClear}
          label={label}
          defaultValue={defaultValue}
          disabled={!filterable}
          clearVisibility={clearVisibility}
        />
      )}
    </th>
  );
};

DataTableFilterField.propTypes = {
  label: PropTypes.string.isRequired,
  columnKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  multiSelect: PropTypes.bool,
  filterable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

DataTableFilterField.defaultProps = {
  multiSelect: false,
};

export default DataTableFilterField;
