import React from 'react'
import RemoteGrid from "../../../components/Grid/RemoteGrid";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {getProcedure} from "../../../redux/actions/proceduresActions";
import {getNameFromCode, getNamesFromCodes} from "../../../utils/helpers";

class ProceduresList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.onRowClick = this.onRowClick.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
    }

    componentDidMount() {
        this.props.getProcedure('')
    }

    onRowClick(item) {
        this.setState({item})
        this.props.history.push(`/procedure/${item}`);
    }

    enumFormatter(cell, row, enumObject) {
        return enumObject[cell];
    }

    render() {
        let mcCols = [];
        let data = this.props.procedures;
        this.props.mc_systems.forEach((mc) => {
            if (mc.active && mc.service) {
                mcCols.push(
                    {
                        'name': mc.code,
                        'title': mc.name,
                        'columnTitle': true,
                        'width': '200',
                        'sort': true,
                        'filter': {type: 'TextFilter', delay: 500}
                    }
                );
                data = data.map(item => {
                        let mcCodes = [];
                        if (Array.isArray(item.medicalCodingSystemCodes)) {
                            item.medicalCodingSystemCodes.forEach((mcName) => {
                                if (mc.medicalCodeCodes && mc.medicalCodeCodes.includes(mcName)) {
                                    mcCodes.push(getNameFromCode(mcName, this.props.sc_systems));
                                }
                            });
                        }
                        item[mc.code] = mcCodes.join(', ');
                        return item;
                    }
                );
            }
        });

        let procedureCols = [
            {
                'isKey': true,
                'name': 'code',
                'title': 'Code',
                'sort': false,
                'hidden': true
            },
            {
                'name': 'name',
                'title': this.context.translate('common.form.service'),
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'specialtyCodes',
                'title': this.context.translate('common.form.specialties'),
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'bodyPartCodes',
                'title': this.context.translate('common.form.body_parts'),
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }];
        procedureCols = procedureCols.concat(mcCols).concat(
            [
                {
                    'name': 'active',
                    'title': 'Status',
                    'dataFormat': this.enumFormatter,
                    'sort': true,
                    'formatExtraData': {
                        'false': 'Inactive',
                        'true': 'Active'
                    },
                    'width': '200',
                    'filter': {
                        type: 'SelectFilter', defaultValue: 'true', options: {
                            'false': 'Inactive',
                            'true': 'Active'
                        }
                    }
                }
            ]
        );
        const {isLoading} = this.props;
        const {sorting, pagination} = this.props.gridConfiguration
        return (
            <div className="table-responsive" style={{marginTop: '15px', "padding": '1px'}}>
                <RemoteGrid
                    color="green"
                    entity="procedure"
                    title="Medical Services"
                    data={data}
                    total={data.length}
                    columns={procedureCols}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.onRowClick}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                    isLoading={isLoading}
                />
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        gridConfiguration: PropTypes.object,
    }
}

ProceduresList.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => {
    const allSpecialties = state.app.specialties.data
    const allBodyParts = state.app.bodyParts.data

    return {
        mc_systems: state.app.mcSystem.data,
        sc_systems: state.app.scSystem.data.filter((item) => item.active && item.service),
        procedures: state.app.procedures.data
            .map(item => {
                if (item.specialtyCodes) item.specialtyCodes.sort();
                if (item.bodyPartCodes) item.bodyPartCodes.sort();
                return {
                    ...item,
                    bodyPartCodes: getNamesFromCodes(item.bodyPartCodes, allBodyParts),
                    specialtyCodes: getNamesFromCodes(item.specialtyCodes, allSpecialties)
                }
            }),
        gridConfiguration: state.app.grid.procedure,
        isLoading: state.app.procedures.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProcedure: code => dispatch(getProcedure(code)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProceduresList));
