import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InitAction from '../redux/action-creators/init';
import { capitalizeAllFirstLetters } from '../utils/helpers';
import { getMyPermissions } from 'redux/action-creators/users';

export const LegacyContext = React.createContext({});

class AppInitializer extends React.Component {
    constructor(props) {
        super(props);
        this.translate = this.translate.bind(this);
    }

    componentDidMount() {
        this.props.init();
    }

    getChildContext() {
        return {
            translate: this.translate,
        };
    }

    translate(trPath) {
        if (!trPath) {
            return false;
        }
        if (!this.props.translation || !this.props.translation.items) {
            return capitalizeAllFirstLetters(trPath.substring(trPath.lastIndexOf('.') + 1));
        }

        for (let i = 0; i < this.props.translation.items.length; i++) {
            if (this.props.translation.items[ i ].group + '.' + this.props.translation.items[ i ].key === trPath) {
                return this.props.translation.items[ i ].en;
            }
        }
        // if (trPath === 'common.cases.create') {
        //     console.log(trPath.substring(trPath.lastIndexOf('.') + 1));
        // }
        return capitalizeAllFirstLetters(trPath.substring(trPath.lastIndexOf('.') + 1));
    }

    render() {
        return <LegacyContext.Provider value={this.getChildContext()}>{this.props.children}</LegacyContext.Provider>;
    }
}

AppInitializer.childContextTypes = {
    translate: PropTypes.func,
};

const mapStateToProps = (state) => ({
    language: state.app.context.language,
    loading: state.app.context.loading,
    translation: state.app.context.translation,
});

const mapDispatchToProps = (dispatch) => ({
    init: () => {
        dispatch(InitAction());
        dispatch(getMyPermissions());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppInitializer);
