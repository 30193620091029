import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';

const patientSlice = createDatatableTemplateSlice({
    name: 'patient',
    initialSettings: {
        sortBy: 'firstName',
    },
});

export const { loadData, dataLoaded, dataLoadError, changeSettings } = patientSlice.actions;

export default patientSlice;
