import { isObjectsEqual } from '../../../utils/helpers'

const gridConfiguration = {
    sorting: {
        sortName: 'name',
        sortOrder: 'asc'
    }
};
const reportsConfiguration = {
    sorting: {
        sortName: 'date',
        sortOrder: 'desc'
    }
};

const defaultState = {
    loading: false,
    loading_level: false,
    loadedCount: 0,
    loadedCount_level: 0,
    currentCase: {
        patient: 'patient'
    },
    types: [],
    editedCaseType: {},
    originalCaseType: {},
    isCaseTypeValid: true,
    editCaseTypeSuccess: false,
    isProfileChanged: false,
    levels: [],
    error: '',
    gridConfiguration: gridConfiguration,
    filesGridConfiguration: gridConfiguration,
    reportsGridConfiguration: reportsConfiguration,
    internalGridConfiguration: reportsConfiguration,
    showValidMessages: false,
    lastLoadedTime: 0,
};

const companies = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_CASE_LEVEL_LOADING':
            return { ...state, loading_level: true };

        case 'FETCH_CASE_TYPES_LOADING':
        case 'FETCH_CASE_TYPE_LOADING':
        case 'FETCH_EDIT_CASE_TYPE_LOADING':
            return {
                ...state,
                loading: true,
                error: null,
                editCaseTypeSuccess: false,
                isProfileChanged: false,
                showValidMessages: false,
            };

        case 'FETCH_CREATE_CASE_TYPE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                types: [...state.types, action.payload]
            });

        case 'FETCH_EDIT_CASE_TYPE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                editCaseTypeSuccess: true,
                error: null,
                originalCaseType: action.payload,
                types: state.types.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            });

        case 'FETCH_CASE_TYPE_PROFILE_SUCCESS': {
            const caseType = {
                ...action.payload,
                names: action.payload.names ? action.payload.names : { 'EN': action.payload.name }
            };
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editedCaseType: caseType,
                originalCaseType: caseType
            });
        }
        case 'FETCH_CASE_TYPES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                types: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            });
        case 'FETCH_CASE_LEVEL_SUCCESS':
            return Object.assign({}, state, {
                loading_level: false,
                levels: action.payload,
                error: null
            });

        case 'FETCH_CASE_TYPE_ERROR':
        case 'FETCH_EDIT_CASE_TYPE_ERROR':
        case 'FETCH_CASE_TYPE_PROFILE_ERROR':
            return { ...state, error: action.payload, loading: false };

        case 'FETCH_CASE_LEVEL_ERROR':
            return { ...state, error: action.payload, loading_level: false };

        case 'EDIT_CASE_FIELD':
            return Object.assign({}, state, {
                currentCase: Object.assign({}, state.currentCase, action.payload),
            });

        case 'CASE_TYPE_EDIT_FIELD':
            return Object.assign({}, state, {
                editedCaseType: Object.assign({}, state.editedCaseType, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedCaseType, action.payload), state.originalCaseType)
            });

        case 'VALIDATE_CASE_TYPE_FIELD':
            return Object.assign({}, state, {
                isCaseTypeValid: action.payload
            });
        case 'SHOW_CASE_TYPE_VALIDATION':
            return Object.assign({}, state, {
                showValidMessages: true
            });

        case 'FETCH_CASE_TYPE_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                types: [...state.types, action.payload]
            };
        case 'FILL_CASE_TYPE_LOCAL_STORAGE': {
            let items = {};
            for (let i = 0; i < state.types.length; i++) {
                items[ state.types[ i ].code ] = state.types[ i ].name;
            }
            localStorage.setItem('list_case_type', JSON.stringify(items));
            return state;
        }
        case 'FETCH_CASE_TYPE_LOADED_COUNT':
            return { ...state, loadedCount: action.payload };

        case 'FETCH_CASE_LEVEL_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading_level: false,
                levels: [...state.levels, action.payload]
            };
        case 'FILL_CASE_LEVEL_LOCAL_STORAGE': {
            let items2 = {};
            for (let i = 0; i < state.levels.length; i++) {
                items2[ state.levels[ i ].code ] = state.levels[ i ].displayName[ 'EN' ];
            }
            localStorage.setItem('list_case_level', JSON.stringify(items2));
            return state;
        }
        case 'FETCH_CASE_LEVEL_LOADED_COUNT':
            return { ...state, loadedCount_level: action.payload };

        default:
    }

    return state
};

export default companies
