import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearch } from '../../redux/global-search/globalSearchSlice';

const FORBIDDEN_PAGES = [
  '/#/case/', '/#/assign/', // Cases
  '/#/fields/update/', '/#/notification_templates', '/#/case_file_templates', // Templates
  '/#/submitters/profile/', // Clients
  '/#/staff/profile/', // Staff
  '/#/permissions', // Tools
  '/#/specialties/', '/#/case_types/update/', '/#/countries/', // Tables
  '/#/financial_reports', // Finances
  '/#/case_reports',
  '/#/notifications', // Notifications
  '/#/dev_random_populate', '/#/dev_api_tester', '/#/dev_test', // Dev Utils
];

const GlobalSearch = () => {
  const dispatch = useDispatch();

  const { search } = useSelector((state) => {
    return state.app.globalSearch;
  });

  const [value, setValue] = useState(search);

  const updateValue = (val) => {
    dispatch(updateSearch(val));
  };

  useEffect(() => {
    setValue(search);
  }, [search]);

  const handleChange = ({ target }) => {
    setValue(target.value);
    if (!target.value) {
      updateValue(target.value);
    }
  };

  const handleSearch = () => {
    updateValue(value);
  };

  const handleKeyUp = ({ nativeEvent }) => {
    if (nativeEvent.key === 'Enter') {
      updateValue(value);
    }
  };

  const isVisible = !FORBIDDEN_PAGES.some((value) => window.location.href.includes(value));

  return (isVisible &&
          <li className="global-search">
            <div className="input-group input-group-sm">
              <input
                id="search-everywhere" className="form-control"
                type="text" placeholder="Search..."
                onChange={handleChange}
                onKeyUp={handleKeyUp}
                onBlur={handleSearch}
                value={value}
              />
              <div className="input-group-btn">
                <button
                  type="submit"
                  className="btn btn-default search-btn"
                  onClick={handleSearch}
                >
                  <i className="fa fa-search"/>
                </button>
              </div>
            </div>
          </li>
  );
};

GlobalSearch.propTypes = {
  search: PropTypes.string,
};

GlobalSearch.defaultProps = {
  search: '',
};

export default withRouter(GlobalSearch);
