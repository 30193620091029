import React from 'react';
import {connect} from 'react-redux';
import {createNewSection} from "../../../redux/actions/templateActions";
import SimpleReactValidator from "utils/simple-react-validator";
import FormField from '../../../components/FormField/index'
import PropTypes from "prop-types";
import shortId from 'shortid'
import {closeCreateModal} from '../../../redux/action-creators/users'
import SystemMessage from '../../../components/SystemMessage';

class SectionManageForm extends React.Component {
    constructor(props) {
        super(props);
        this.defaultState = {
            language: {value: 'EN', label: 'English'},
            displayName: {'EN': '', 'SV': ''},
            helpText: {'EN': '', 'SV': ''},
            position: '',
            active: true,
        };

        this.state = this.defaultState;
        this.validator = new SimpleReactValidator({
            positive_num: {
                message: 'The :attribute must be greater than 0.',
                rule: function (val) {
                    return parseFloat(val) > 0 || val === ''
                }
            }
        });
        this.validator.showMessages();

        this.Submit = this.Submit.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    }

    componentDidMount() {
        this.props.resetError()
    }

    // TODO: deprecated method in react
    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.section) !== JSON.stringify(this.props.section)) {
            this.setState(Object.assign(
                this.state,
                nextProps.section
            ))
        }

    }

    onChangeCheckBox(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: Object.assign({}, this.state.displayName, {
                [this.state.language.value]: e.target.value
            })
        });
    };

    dispatchValidState = () => {
        this.forceUpdate()
    };

    onChangeLanguage = (language) => {
        this.setState({language})
    };

    Submit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            console.log(this.props);
            this.props.onSubmit({
                ...this.state,
                code: shortId.generate(),
                deletable: true,
                expanded: true,
                active: true,
                position: Number.parseInt(this.state.position, 10),
                templateCode: this.props.templateCode,
            })
        } else {
            this.setState({messagesShown: true})

        }
    }

    onChangeOrder = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    render() {
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
        }));

        return (
            <form
                className='modal-content custom-modal-content'
                onSubmit={this.Submit}
                noValidate
            >
                <div className="row no-padding margin-bottom-15">
                    <div className="col-xs-4 col-md-6 pull-left">
                        <h3>{this.props.label}</h3>
                    </div>
                    <div className="col-xs-4 col-md-6 pull-right text-right">
                        <button
                            className="btn btn-flat btn-primary"
                            disabled={this.props.isLoading}
                        >
                            Save
                        </button>
                        <button
                            className="btn btn-flat btn-default margin-left-5"
                            disabled={this.props.isLoading}
                            onClick={this.props.closeCreateModal}
                        >
                            Cancel
                        </button>
                        {/*<i className='fa fa-times close'  onClick={this.props.closeCreateModal}></i>*/}
                    </div>

                </div>

                <SystemMessage shown={this.state.messagesShown} type='error' message={this.props.error}
                               validationMessages={errorMessages}/>

                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="10"
                            label="Section Name"
                            id="Section Name"
                            className="form-control"
                            required
                            name="displayName"
                            value={this.state.displayName[this.state.language.value]}
                            onChange={this.onChange}
                            validator={this.validator}
                            valids='required'
                            onBlur={this.dispatchValidState}
                        />
                        {/*  <FormField
                    md="4"
                    type="select"
                    label="Language"
                    clearable={false}
                    className="text-bg "
                    options={this.props.languageCodes}
                    name="languageCode"
                    value={this.state.language}
                    onChange={this.onChangeLanguage}
                    /> */}
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            required
                            md="10"
                            type='number'
                            label='Section Order'
                            id='Section Order'
                            value={this.state.position}
                            onChange={this.onChangeOrder}
                            validator={this.validator}
                            name='position'
                            valids='required|positive_num'
                            onBlur={this.dispatchValidState}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

SectionManageForm.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    languageCodes: state.app.languages.data.filter((item) => item.active).map((item) => ({
        value: item.code,
        label: item.name
    })),
    templateCode: state.app.templates.code,
    isLoading: state.app.templates.loading,
    error: state.app.templates.sectionError,
    label: 'Add Section'
});

const mapDispatchToProps = dispatch => ({
    closeCreateModal: () => {
        dispatch(closeCreateModal())
    },
    onSubmit: (section) => {
        dispatch(createNewSection(section))
    },
    resetError: () => {
        dispatch({type: 'FETCH_CREATE_SECTION_ERROR', payload: null})
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(SectionManageForm)
