import Modal from 'react-modal';
import React from 'react';
import {DropdownButton, MenuItem} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import AddFromLibrary from './AddFromLibrary';
import CloneExisting from './CloneExisting';
import {closeCreateModal, openCreateModal} from '../../../redux/action-creators/users';
import {menuItemNames} from '../../../constants';

const {ADD_FIELD_FROM_LIBRARY, ADD_NEW, COPY_FIELD} = menuItemNames;

class AddFieldButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {activeLink: ADD_NEW};
    }

    handleSelect = (selectedKey) => {
        this.setState({activeLink: selectedKey});
        this.props.modalOpen();
    };

    render() {
        const {history, isOpen, modalClose} = this.props;

        const {activeLink} = this.state;

        const onSelect = () => {
            history.push('/templates/field_instances/create');
        };

        return (
            <span className={'margin-left-5'}>
                <DropdownButton
                    bsStyle={'primary'}
                    id={'dropdown-basic-1'}
                    key={ADD_NEW}
                    title={'Add Field'}
                >
                    <MenuItem eventKey={ADD_NEW} onSelect={onSelect}>
                        {ADD_NEW}
                    </MenuItem>
                    <MenuItem eventKey={ADD_FIELD_FROM_LIBRARY} onSelect={this.handleSelect}>
                        {ADD_FIELD_FROM_LIBRARY}
                    </MenuItem>
                    <MenuItem eventKey={COPY_FIELD} onSelect={this.handleSelect}>
                        {COPY_FIELD}
                    </MenuItem>
                </DropdownButton>
                <Modal
                    className={'custom-modal modal-dialog'}
                    contentLabel={'onRequestClose Example'}
                    isOpen={isOpen}
                    onRequestClose={modalClose}
                    overlayClassName={'modal-overlay'}
                >
                    <h3 className={'modal-title text-center'}>
                        <span>
                            {activeLink}
                            <i className={'fa fa-times close'} onClick={modalClose}/>
                        </span>
                    </h3>
                    <div className={'white-bg'}>
                        {activeLink === ADD_FIELD_FROM_LIBRARY && (
                            <AddFromLibrary history={history}/>
                        )}
                        {activeLink === COPY_FIELD && <CloneExisting history={history}/>}
                    </div>
                </Modal>
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentHash: state.router.location.hash,
        currentPage: state.router.location.pathname,
        isOpen: state.app.users.createModalOpen,
        templateCurrentTab: state.app.templates.currentTab,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        modalClose: () => {
            return dispatch(closeCreateModal());
        },
        modalOpen: () => {
            return dispatch(openCreateModal());
        },
    };
};

AddFieldButton = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddFieldButton);

export default withRouter(AddFieldButton);
