import React from 'react';
import PropTypes from 'prop-types'
import Modal from 'react-modal';
import {connect} from 'react-redux';
import {closeFilesModal} from '../redux/action-creators/users'
import {detachFile, getCase, getCaseFiles} from "../redux/actions/cases/caseActions";
import {deactivateReport} from "../redux/actions/cases/caseReportsActions";
import {withRouter} from "react-router-dom";

class FilesModal extends React.Component {

    constructor(props) {
        super(props);
        this.modalHeader = <h3 className="modal-title text-center">{props.title}</h3>;
        this.modalButtons = null;
        this.getModalHeader = this.getModalHeader.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
    }

    toggleActive() {
        if (this.props['editTarget'] === 'case') {
            if (this.props.deletedType === 'file') {
                this.props.detachFile({
                    code: this.props.deletedCode,
                }).then(() => {
                    this.props.getCase(this.props.case.code)
                        .then(() => {
                            this.props.handleCloseModal();
                            this.props.getCaseFiles(this.props.case.code);
                        });
                });
            }
            if (this.props.deletedType === 'report') {
                this.props.deactivateReport({
                    code: this.props.deletedCode,
                }).then(() => {
                    this.props.handleCloseModal();
                    this.props.getCaseFiles(this.props.case.code);
                });
            }
        }
    }

    getModalHeader() {
        if (this.props['editTarget'] === 'case') {
            return (<h4 className=''>Are you sure you want to delete?</h4>)
        }
        return '';
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.handleCloseModal}
                className="custom-modal modal-dialog"
                overlayClassName="modal-overlay"
            >
                {this.modalHeader}
                <div className='modal-content custom-modal-content text-center'>
                    {this.getModalHeader()}
                    <div>
                        <button
                            className='btn btn-grey margin-right-5 margin-top-5'
                            onClick={this.toggleActive}>
                            Yes
                        </button>
                        <button
                            className='btn btn-grey margin-left-5 margin-top-5'
                            onClick={this.props.handleCloseModal}>
                            No
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

FilesModal.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    case: state.app.cases.editedCase,
    isOpen: state.app.users.filesModalOpen,
    deletedCode: state.app.users.deletedCode,
    deletedType: state.app.users.deletedType
});

const mapDispatchToProps = dispatch => ({
    handleCloseModal: () => {
        dispatch(closeFilesModal())
    },
    getCase: (code) => dispatch(getCase(code)),
    getCaseFiles: (code) => dispatch(getCaseFiles(code)),
    detachFile: (code) => dispatch(detachFile(code)),
    deactivateReport: (code) => dispatch(deactivateReport(code))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FilesModal))
