import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'
import PropTypes from "prop-types";
import { showValidation } from '../../../redux/actions/BasicEntityActions'
import { getObjKey, getSelectFieldValue } from '../../../utils/helpers'
import { Link, matchPath, withRouter } from 'react-router-dom'
import { updateBillingGroup } from "../../../redux/actions/billingActions";

class SaveBillingGroupButton extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
    }

    onSave(e, closeAfterSave) {
        const { isValid, group, showValidation } = this.props;

        if (isValid) {
            const data = {
                ...group,
                billingTiers: group.billingTiers.map(getObjKey('code')),
                companies: group.companies.map(getObjKey('code')),
                currency: getSelectFieldValue(group.currency),
            };

            this.props.updateGroup(data, closeAfterSave);
        } else {
            showValidation(false);
        }
    }

    render() {
        const match = matchPath(this.props.history.location.pathname, {
            path: '/billing_groups/update/:id',
            exact: false,
            strict: false
        });

        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton color='blue' disabled={!this.props.isProfileChanged} onSubmit={this.onSave}
                              showTooltip={!this.props.isTemplateValid}/>
                <SubmitButton color='blue' disabled={!this.props.isProfileChanged}
                              onSubmit={(e) => this.onSave(e, true)} title='Save And Close'
                              showTooltip={!this.props.isTemplateValid}/>
                <Link
                    className="btn resizable-button tooltip-button btn-primary margin-left-5"
                    to={`/billing_tier/create/tier/${match ? match.params.id : null}`}>
                    {this.context.translate('common.cases.add_new_tier')}
                </Link>
            </React.Fragment>
        )
    }

    static propTypes = {
        isEditable: PropTypes.bool,
        isValid: PropTypes.bool,
        isProfileChanged: PropTypes.bool,
        isTemplateValid: PropTypes.bool,
        group: PropTypes.object,
        showValidation: PropTypes.func,
        updateGroup: PropTypes.func,
        history: PropTypes.object
    };

    static contextTypes = {
        translate: PropTypes.func
    };
}

const mapStateToProps = (state) => ({
    isEditable: state.app.users.my_permissions.includes('MODIFY_BILLING_TIERS'),
    isValid: state.app.billing_group.isValid,
    isProfileChanged: state.app.billing_group.isProfileChanged,
    group: state.app.billing_group.editedBillingGroup,
});

const mapDispatchToProps = dispatch => ({
    updateGroup: (data, closeAfterSave) => dispatch(updateBillingGroup(data, closeAfterSave)),
    showValidation: () => dispatch(showValidation('billing_group')),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SaveBillingGroupButton))
