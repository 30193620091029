import {
    RESPONSE_ERROR,
    SET_CURRENT_USER,
    VERIFY_FIRST_LOGIN,
    CHANGE_FIRST_LOGIN,
    RESET_PASSWORD_STATUS,
    VERIFY_RECOVERY,
    CHANGE_RECOVERY_PASSWORD
} from '../actions/types';
import { Statuses } from '../actions/statuses';

const initialState = {
    user: {},
    res:  {},
    status: '',
    active: true,
    mfaQrCodeLink: '',
    mfaSecretKey: '',
    error: '',
    errorExpiration: '',
    logoutPath: {},
};

export default (state = initialState, action = {}) => {
    // console.log('auth-State', state, action);
    switch(action.type) {
        case 'RESET_AUTH_ERRORS' :
            return {
                ...initialState,
                errorExpiration: '',
                error: ''
            }
        case RESPONSE_ERROR:
            return {
                ...initialState,
                errorExpiration: '',
                error: (Statuses[action.status] ? Statuses[action.status] : action.status)
            };
        case 'RESPONSE_ERROR_EXPIRATION':
            return {
              ...initialState,
                errorExpiration: (Statuses[action.status] ? Statuses[action.status] : action.status),
                error: ''
            };
        case SET_CURRENT_USER:
            localStorage.setItem('current_user', JSON.stringify(action.payload));
            return {
                ...state,
                user: action.payload ? action.payload : {}
            };
        case VERIFY_FIRST_LOGIN:
            return {
                ...initialState,
                res: action.res,
                active: action.res.active,
                mfaQrCodeLink: action.res.mfaQrCodeLink,
                mfaSecretKey: action.res.mfaSecretKey
            };
        case CHANGE_FIRST_LOGIN:
            return {
              ...initialState,
                res: action.res,
                active: action.res.active
            };
        case RESET_PASSWORD_STATUS:
            return {
              ...initialState,
                status: (Statuses[action.res.status] ? Statuses[action.res.status] : action.res.status)
            };
        case VERIFY_RECOVERY:
            return {
              ...initialState,
                res: action.res,
                active: action.res.active
            };
        case CHANGE_RECOVERY_PASSWORD:
            return {
              ...initialState,
                res: action.res,
                active: action.res.active
            };
        case 'CHANGE_EXPIRATION_PASSWORD':
            return {
              ...initialState,
                res: action.res,
                active: action.res.active
            };
        case 'SET_LAST_VISITED_PATH':
            return {
                ...state,
                logoutPath: {
                  ...state.logoutPath,
                  ...action.payload
                },
            };
        default: return state;
    }
}
