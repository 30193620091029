import getTranslatesItems from "../../utils/translates-items";
import api from "../../utils/api";
import {Statuses} from "../actions/statuses";

/*
const testChild = {
    "code": "create_item",
    "link": "/some_child",
    "icon": "fa-file-text-o",
    "position": 1,
    "name": {
        "EN": "Menu Item"
    },
};
const testTwo = {
    "code": "home_item",
    "link": "/some_linlk",
    "icon": "fa-link",
    "position": 2,
    "name": {
        "EN": "Link"
    },
};

const test = {
    "code": "menu_manage",
    "active": true,
    "link": null,
    "icon": "fa-file-text-o",
    "position": 0,
    "parentMenuItemCode": null,
    "adminMenu": false,
    "name": {
        "EN": "Menu Manager"
    },
    "permissionCodes": [],
    "childrenCodes": [],
    "children": [testChild, testTwo],
    "tooltip": null
};
*/

export const getLayoutItems = () => (dispatch) => {
    dispatch({
        type: 'CONTEXT_LAYOUT_LIST_LOADING'
    });

    return api.get('menu/full')
        .then(({data}) => {
            dispatch({
                type: 'CONTEXT_LAYOUT_LIST_SUCCESS',
                payload: data
            });

            return;
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: `CONTEXT_LAYOUT_LIST_PROFILE_ERROR`,
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                // console.log('Error ', error.message);
                dispatch({
                    type: `CONTEXT_LAYOUT_LIST_ERROR`,
                    payload: error.message
                });
            }

            return Promise.reject(error);
        })
};

export const fetchTranslatesItems = () => (dispatch) => {
    dispatch({
        type: 'CONTEXT_TRANSLATION_LIST_LOADING'
    });
    setTimeout(() => {
        let translateItems = getTranslatesItems('common');
        dispatch({
            type: 'CONTEXT_TRANSLATION_LIST_SUCCESS',
            payload: translateItems
        })
    }, 500)
};

export const fetchCaseCount = () => (dispatch) => {
    dispatch({
        type: 'CONTEXT_CASE_COUNT_LOADING'
    });

    const stageCodes = [
        'CW_CLOSED',
        'CW_REVIEW',
        'CW_DISPATCH',
        'CW_DRAFT',
        'CW_QA'
    ];

    api.post('case/count_by_stage', stageCodes)
        .then(({data}) => {
            dispatch({
                type: 'CONTEXT_CASE_COUNT_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: `CONTEXT_CASE_COUNT_ERROR`,
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                // console.log('Error ', error.message);
                dispatch({
                    type: `CONTEXT_CASE_COUNT_ERROR`,
                    payload: error.message
                });
            }
        })
};

const InitAction = () => (dispatch, getState) => {
    fetchCaseCount()(dispatch, getState);
    // dispatch({type:'RESET_GRIDS_CONFIGURATION'})
};
// const InitAction = (user) => (dispatch, getState) => {
//     dispatch({
//         type: 'CONTEXT_LAYOUT_LIST_LOADING'
//     })
//     setTimeout(() => {
//         let menuItems = [];
//         let roleCode;
//         if (user.roleCode) {
//             roleCode = user.roleCode == 'ROLE_ADMIN' ? 'STAFF' : user.roleCode;
//         } else roleCode ='STAFF'
//
//         menuItems = getLayoutItems(roleCode)
//         dispatch({
//             type: 'CONTEXT_LAYOUT_LIST_SUCCESS',
//             payload: menuItems
//         })
//     }, 500);
//
//     dispatch(getAllLocales());
//
//     dispatch({
//         type: 'CONTEXT_TRANSLATION_LIST_LOADING'
//     })
//     setTimeout(() => {
//         let translateItems = getTranslatesItems('common')
//         dispatch({
//             type: 'CONTEXT_TRANSLATION_LIST_SUCCESS',
//             payload: translateItems
//         })
//     }, 500)
// }

export default InitAction
