import { isObjectsEqual } from '../../../utils/helpers';

const baseDiagnose = {
    medicalNecessity: null,
    medicalNecessityAlternative: null,
    noteReferences: null,
    notes: null,
    name: '',
    names: {},
    code: null,
    active: true,
    systemToCode: {}
};

const defaultState = {
    loading: false,
    loadedCount: 0,
    editDiagnoseSuccess: false,
    data: [],
    error: '',
    editedDiagnose: baseDiagnose,
    originalDiagnose: baseDiagnose,
    diagnoseLoaded: false,
    isProfileChanged: false,
    showValidMessages: false,
    diagnoseValid: undefined,
    lastLoadedTime: 0,
};

const diagnoses = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_DIAGNOSIS_LOADING':
        case 'FETCH_DIAGNOSE_LOADING':
        case 'FETCH_MED_DIAGNOSIS_LOADING':
        case 'FETCH_EDIT_DIAGNOSE_LOADING':
            return { ...state, loading: true, diagnoseLoaded: false, editDiagnoseSuccess: false, isProfileChanged: false, showValidMessages: false };
        case 'FETCH_DIAGNOSIS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                diagnoseLoaded: false,
                data: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            });
        case 'FETCH_MED_DIAGNOSIS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                diagnoseLoaded: false,
                data: action.payload,
                error: null,
            });
        case 'FETCH_CREATE_DIAGNOSE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editDiagnoseSuccess: true,
                data: [...state.data, action.payload]
            });

        case 'FETCH_GET_DIAGNOSE_SUCCESS':
        case 'FETCH_DIAGNOSIS_PROFILE_SUCCESS': {
            const diagnose = action.payload ? action.payload : defaultState.editedDiagnose;
            return {
                ...state,
                error: null,
                loading: false,
                diagnoseValid: true,
                diagnoseLoaded: true,
                editedDiagnose: { ...defaultState.editedDiagnose, ...diagnose },
                originalDiagnose: { ...defaultState.editedDiagnose, ...diagnose },
            };
        }
        case 'FETCH_EDIT_DIAGNOSE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editDiagnoseSuccess: true,
                originalDiagnose: action.payload,
                data: state.data.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            });
        case 'DIAGNOSE_EDIT_FIELD':
            return Object.assign({}, state, {
                editedDiagnose: Object.assign({}, state.editedDiagnose, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedDiagnose, action.payload), state.originalDiagnose)
            });
        case 'VALIDATE_DIAGNOSE_FIELD':
            return Object.assign({}, state, {
                diagnoseValid: action.payload
            });
        case 'FETCH_DIAGNOSIS_ERROR':
        case 'FETCH_DIAGNOSE_ERROR':
            return { ...state, error: action.payload, loading: false, editedDiagnose: defaultState.editedDiagnose };

        case 'SHOW_DIAGNOSE_VALIDATION':
            return Object.assign({}, state, {
                showValidMessages: true
            });

        case 'FETCH_DIAGNOSIS_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            };
        case 'FILL_DIAGNOSIS_LOCAL_STORAGE': {
            let items = {};
            for (let i = 0; i < state.data.length; i++) {
                items[ state.data[ i ].code ] = state.data[ i ].name;
            }
            localStorage.setItem('list_medical_diagnosis', JSON.stringify(items));
            return state;
        }
        case 'FETCH_DIAGNOSIS_LOADED_COUNT':
            return { ...state, loadedCount: action.payload };

        default:
    }
    return state
};

export default diagnoses
