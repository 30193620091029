import pickBy from 'lodash/pickBy';
import { createSlice } from '@reduxjs/toolkit';

const sortDirections = {
    DESC: 'desc',
    ASC: 'asc',
};

const defaultSettingsGlobal = {
    sortBy: '',
    sortDirection: sortDirections.ASC,
    pageSize: 10,
    filters: {},
    page: 1,
    state: '',
    globalSearch: '',
};

const isValidFilter = (filter) => {
    if (typeof filter === 'string') {
        return filter.length > 0;
    }
    return true;
};

const createApiParams = (params, additionalFilters, state) => {
    // eslint-disable-next-line no-unused-vars
    const { page, sortDirection, pageSize, filters, sortBy, globalSearch } = params;
    return {
        pageNumber: page - 1,
        pageSize: pageSize,
        filters: { ...additionalFilters, ...pickBy(filters, isValidFilter) },
        sortBy: sortBy,
        sortDirection: sortDirection,
        state: state,
        globalSearch: globalSearch
    };
};

const transformResponseToTableData = (transform) => (responseData) => {
    const { data, ...metaData } = responseData;

    return { ...metaData, ...transform(data) };
};

const createDatatableTemplateSlice = ({ name, initialState = {}, overrideInitialState = {}, reducers = {}, initialSettings = {}, ...rest }) => {
    return createSlice({
        name: name,
        initialState: {
            ...initialState,
            deleting: null,
            data: [],
            totalRecords: 0,
            totalRecordsFiltered: 0,
            fetching: false,
            fetched: true,
            initialSettings: {
                ...defaultSettingsGlobal,
                ...initialSettings,
            },
            settings: {
                ...defaultSettingsGlobal,
                ...initialSettings,
            },
            ...overrideInitialState,
        },
        reducers: {
            deleteEntity: (state, { payload }) => {
                state.deleting = payload;
            },
            deleteError: (state, { payload }) => {
                state.error = payload;
                state.deleting = null;
            },
            deleteSuccess: (state) => {
                state.deleting = null;
            },
            cancelLoad: () => {
            },
            loadData: (state, { payload }) => {
                state.fetching = true;
                state.error = '';
                state.deleting = null;
                if (state.settings.globalSearch !== payload.globalSearch) {
                    state.settings.page = 1;
                }
                state.settings.globalSearch = payload.globalSearch;
            },
            dataLoaded: (state, { payload }) => {
                state.data = payload.data;
                state.totalRecords = payload.recordsTotal;
                state.totalRecordsFiltered = payload.recordsFiltered;
                state.fetched = true;
                state.fetching = false;
                if (payload.sortColumn && payload.sortDirection && payload.defaultSortColumn && payload.defaultSortDirection) {
                    state.settings.sortBy = payload.sortColumn;
                    state.settings.sortDirection = payload.sortDirection.toLowerCase();
                    state.initialSettings.sortBy = payload.defaultSortColumn;
                    state.initialSettings.sortDirection = payload.defaultSortDirection.toLowerCase();
                }
            },
            dataLoadError: (state) => {
                state.fetching = false;
            },
            changeSettings: (state, { payload }) => {
                state.settings = { ...state.settings, ...payload };
            },
            ...reducers,
        },
        ...rest,
    });
};

export { defaultSettingsGlobal, sortDirections, createApiParams, createDatatableTemplateSlice, transformResponseToTableData, isValidFilter };
