import React from 'react';
import {connect} from 'react-redux';
import SubmitButton from '../../components/SubmitButton'
import PropTypes from "prop-types";
import {editUser, showValidation} from '../../redux/action-creators/users'
import {getSelectFieldValue, isStaff} from '../../utils/helpers'


class SaveUserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        e.preventDefault();
        if (this.props.isProfileValid) {
            this.setState({showTooltip: false});
            this.props.saveProfile({
                ...this.props.profile,
                active: this.props.profile.active,
                code: this.props.profile.code,
                email: this.props.profile.email.trim(),
                notificationEmail: this.props.profile.notificationEmail.trim(),
                roleCode: getSelectFieldValue(this.props.profile.roleCode),
                defaultLanguageCode: getSelectFieldValue(this.props.profile.defaultLanguageCode),
                timeZoneCode: getSelectFieldValue(this.props.profile.timeZoneCode),
                countryCode: getSelectFieldValue(this.props.profile.countryCode),
                stateCode: getSelectFieldValue(this.props.profile.stateCode),
                defaultLocaleCode: getSelectFieldValue(this.props.profile.defaultLocaleCode),
            }, closeAfterSave)
            console.log(this.props)
        } else {
            this.props.showValidation()
        }
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton disabled={!this.props.isProfileChanged} color='yellow' onSubmit={this.onSave}
                              showTooltip={!this.props.isProfileValid}/>
                <SubmitButton
                    color='yellow'
                    disabled={!this.props.isProfileChanged}
                    title='Save And Close'
                    showTooltip={!this.props.isProfileValid}
                    onSubmit={(e) => this.onSave(e, true)}
                />
            </React.Fragment>
        )
    }
}

SaveUserProfile.contextTypes = {
    translate: PropTypes.func
};

const allowToEdit = (role) => (isStaff(role) || role === "ROLE_CLIENT_ADMIN" || role === 'ROLE_ADMIN');

const mapStateToProps = (state) => ({
    profile: state.app.users.editedUser,
    isProfileValid: state.app.users.userValid,
    isProfileChanged: state.app.users.isProfileChanged,
    isEditable: allowToEdit(state.app.auth.user.roleCode),

});

const mapDispatchToProps = dispatch => ({
    saveProfile: (data, closeAfterSave) => dispatch(editUser(data, closeAfterSave)),
    showValidation: () => dispatch(showValidation()),

});

export default connect(mapStateToProps, mapDispatchToProps)(SaveUserProfile)
