import {camelize} from '../../utils/helpers'
import {gridDefaultSettings} from '../../utils/Constants'
import {HIDE_SEARCH_PANEL, SHOW_SEARCH_PANEL} from '../actions/gridActions';

export const defaultGridState = {
    rows: [],
    loading: false,
    error: null,
    totalItems: 0,
    columns: [],
    editFields: [],
    index: null,
    maxItemsPerPage: 0,
    loadParams: {
        url: '',
        page: 1,
        sort: [],
        filters: {},
    },
    isOpen: false,
    isSearchVisible: true,
    searchValue: '',

    fieldInstance: gridDefaultSettings.fieldInstance,
    template: gridDefaultSettings.template,
    notificationTemplate: gridDefaultSettings.notificationTemplate,
    section: gridDefaultSettings.section,
    templateClient: gridDefaultSettings.templateClient,
    billingClients: gridDefaultSettings.templateClient,
    billingGroupTiers: gridDefaultSettings.billingGroupTiers,
    patient: gridDefaultSettings.patient,
    staff: gridDefaultSettings.staff,
    user: gridDefaultSettings.user,
    reviewer: gridDefaultSettings.reviewer,
    field: gridDefaultSettings.field,
    client: gridDefaultSettings.client,
    clientSubmitters: gridDefaultSettings.clientSubmitters,
    clientService: gridDefaultSettings.clientService,
    clientDiagnosis: gridDefaultSettings.clientDiagnosis,
    clientFacility: gridDefaultSettings.clientFacility,
    clientPhysician: gridDefaultSettings.clientPhysician,
    clientPricingTiers: gridDefaultSettings.clientPricingTiers,
    physician: gridDefaultSettings.physician,
    physicianFacilities: gridDefaultSettings.physicianFacilities,
    facility: gridDefaultSettings.facility,
    facilityType: gridDefaultSettings.facilityType,
    facilityClient: gridDefaultSettings.facilityClient,
    facilityPhysician: gridDefaultSettings.facilityPhysician,
    specialties: gridDefaultSettings.specialties,
    billingGroup: gridDefaultSettings.billingGroup,
    caseType: gridDefaultSettings.caseType,
    country: gridDefaultSettings.country,
    mcSystem: gridDefaultSettings.country,
    scSystem: gridDefaultSettings.scSystem,
    procedure: gridDefaultSettings.procedure,
    diagnosis: gridDefaultSettings.diagnosis,

    caseDraft: gridDefaultSettings.caseDraft,
    caseDispatch: gridDefaultSettings.caseDispatch,
    caseReview: gridDefaultSettings.caseReview,
    caseQa: gridDefaultSettings.caseQa,
    caseClosed: gridDefaultSettings.caseClosed,
    caseAll: gridDefaultSettings.caseAll,
    caseInProcess: gridDefaultSettings.caseInProcess,
    caseAvailable: gridDefaultSettings.caseAvailable,
    caseAccepted: gridDefaultSettings.caseAccepted,
    caseClosedLastMonth: gridDefaultSettings.caseClosedLastMonth,
    caseClosedThisMonth: gridDefaultSettings.caseClosedThisMonth,

    caseFlagged: gridDefaultSettings.caseAll,

    caseDraftHome: gridDefaultSettings.caseDraftHome,
    caseDispatchHome: gridDefaultSettings.caseDispatchHome,
    caseReviewHome: gridDefaultSettings.caseReviewHome,
    caseQaHome: gridDefaultSettings.caseQaHome,
    caseClosedHome: gridDefaultSettings.caseClosedHome,
    caseAllHome: gridDefaultSettings.caseAllHome,
    caseInProcessHome: gridDefaultSettings.caseInProcessHome,
    caseAvailableHome: gridDefaultSettings.caseAvailableHome,
    caseAcceptedHome: gridDefaultSettings.caseAcceptedHome,
    caseClosedLastMonthHome: gridDefaultSettings.caseClosedLastMonthHome,
    caseClosedThisMonthHome: gridDefaultSettings.caseClosedThisMonthHome,

    caseMedicalRecords: gridDefaultSettings.caseMedicalRecords,
    caseReports: gridDefaultSettings.caseReports,
    caseInternalCaseFiles: gridDefaultSettings.caseInternalCaseFiles
};

const grid = (state = defaultGridState, action) => {
    const entityName = camelize(action.entity);

    switch (action.type) {
        case 'LOAD_PAGE_SUCCESS':
            return Object.assign({}, state, {
                loading: true,
                index: action.grid.id,
                maxItemsPerPage: action.grid.maxItemsPerPage,
                columns: action.columns || [],
                editFields: action.editFields || [],
            });
        case 'LOAD_GRID_LOADING':
            return Object.assign({}, state, {
                loading: true,
                index: action.index,
            });
        case 'LOAD_GRID_SUCCESS':
            return Object.assign({}, state, {
                rows: action.data["hydra:member"],
                totalItems: action.data["hydra:totalItems"],
                loading: false,
                error: null,
            });
        case 'LOAD_GRID_ERROR':
            return Object.assign({}, state, {
                loading: false,
                error: action.error,
            });
        case 'CHANGE_GRID_LOAD_PARAMS':
            return Object.assign({}, state, {
                loadParams: {
                    ...state.loadParams,
                    ...action.loadParams,
                }
            });
        case 'TOGGLE_GRID_FILTER':
            return Object.assign({}, state, {
                isOpen: !state.isOpen
            });
        case SHOW_SEARCH_PANEL:
            return Object.assign({}, state, {
                isSearchVisible: true
            });
        case HIDE_SEARCH_PANEL:
            return Object.assign({}, state, {
                isSearchVisible: false
            });
        case 'CHANGE_SEARCH_VALUE':
            return Object.assign({}, state, {
                searchValue: action.payload
            });
        case 'CHANGE_GRID_SORT':
            return Object.assign({}, state, {
                sortName: action.payload.value,
                sortOrder: action.payload.direction
            });

        case 'CHANGE_GRID_PAGINATION':
            return Object.assign({}, state, {
                [entityName]: {
                    ...state[entityName],
                    pagination: action.payload
                }

            });

        case 'CHANGE_GRID_SORTING':
            return Object.assign({}, state, {
                [entityName]: {
                    ...state[entityName],
                    sorting: action.payload ? action.payload : gridDefaultSettings[entityName].sorting
                }
            });

        case 'SAVE_GRID_TOTAL':
            return Object.assign({}, state, {
                [entityName]: {
                    ...state[entityName],
                    total: action.payload
                }
            });

        case 'RESET_GRIDS_CONFIGURATION':
            return defaultGridState;

        default:
            return state
    }
};

export default grid
