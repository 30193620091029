import React from 'react';
import {connect} from 'react-redux';
import SubmitButton from '../../components/SubmitButton'
import PropTypes from "prop-types";
import {saveNewPassword} from '../../redux/actions/profileActions'
import {showValidation} from '../../redux/actions/BasicEntityActions'


class SavePasswordButton extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e) {
        e.preventDefault();
        if (this.props.isPasswordValid) {
            const {oldPassword, newPassword, repeatNewPassword} = this.props.profile;
            this.props.saveNewPassword({oldPassword, newPassword, repeatNewPassword})
        } else {
            this.props.showValidation()
        }
    }


    render() {
        const {oldPassword, newPassword, repeatNewPassword} = this.props.profile;
        let disabled = oldPassword === '' && newPassword === '' && repeatNewPassword === '';
        return <SubmitButton
            color='blue'
            disabled={disabled}
            title='Change Password'
            onSubmit={this.onSave}
            showTooltip={!this.props.isPasswordValid}/>
    }
}

SavePasswordButton.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    profile: state.app.profile.data,
    error: state.app.profile.error,
    isPasswordValid: state.app.profile.isPasswordValid
});

const mapDispatchToProps = dispatch => ({
    saveNewPassword: ChangePassword => dispatch(saveNewPassword(ChangePassword)),
    showValidation: () => dispatch(showValidation('password'))
});

export default connect(mapStateToProps, mapDispatchToProps)(SavePasswordButton)
