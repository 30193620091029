import api from "../../utils/api";
import { Statuses } from "./statuses";
import { isEntityLoaded } from "../../utils/helpers";

const isAvailableInCache = (languages) =>
    isEntityLoaded(languages) && languages.data && languages.data.length > 0;

export const getLanguages = (languages) => (dispatch) => {
    if (isAvailableInCache(languages)) {
        return;
    }
    dispatch({
        type: 'FETCH_LANGUAGE_LOADING',
    })
    api.get('language/all')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_LANGUAGES_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_LANGUAGE_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_LANGUAGE_ERROR',
                    payload: error.message
                });
            }
        })
}
