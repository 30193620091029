import React from 'react'
import {Redirect} from 'react-router-dom'
import LoadingIndicator from '../../components/LoadingIndicator'
import {userStatuses} from '../../redux/actions/statuses'

const AuthRoute = status => {
    console.log('AuthRoute', status);
    switch (status) {
        case userStatuses['LOGGED_IN']:
            return <Redirect to="/"/>;
        case userStatuses['AUTHENTICATED']:
        case userStatuses['LOGGING_IN']:
            return <LoadingIndicator height={100}/>;
        case userStatuses['LOGGED_OUT']:
        case userStatuses['LOGIN_FAILURE']:
            return <Redirect to="/login"/>;
        // case MFA_REQUIRED:
        //     return <Redirect to="/login-step2"/>
        // case NEW_PASSWORD_REQUIRED:
        //     return <Redirect to="/login/force-change-password"/>
        // case EMAIL_VERIFICATION_REQUIRED:
        //     return <Redirect to="/login/force-verify-email"/>
        // case CONFIRMATION_REQUIRED:
        //     return <Redirect to="/login/force-confirm"/>
        default:
            return <p>Unrecognised authentication status: {status}</p>
    }
};

export default AuthRoute
