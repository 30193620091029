import React from 'react';
import { connect } from 'react-redux';
import shortId from 'shortid';
import '../../components/ModalWin.css';
import FormField from '../../components/FormField';
import { createNewUser, userLoaded } from '../../redux/action-creators/users';
import { currencyToIcon } from '../../utils/Constants';
import { getMultiSelectValue, getSelectFieldValue, reformatCurrency } from '../../utils/helpers';
import FormValidateWrapper from '../../containers/FormValidateWrapper';
import { getAllTypes } from '../../redux/actions/reviewerTypesAction';
import { getActiveCountries } from '../../redux/actions/countryActions';
import { getAll } from 'redux/actions/BasicEntityActions';

class ReviewerCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            roleCode: 'ROLE_REVIEWER',
            acceptedPerMonthMax: 100,
            acceptedPerMonthMin: 5,
            reviewerFlatMargin: 70,
            timezone: '',
        };

        this.createUser = this.createUser.bind(this);
        this.onChangeHrlyRate = this.onChangeHrlyRate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.ChangeReviewerTypeCodes = this.ChangeReviewerTypeCodes.bind(this);
        this.onChangeSpecialties = this.onChangeSpecialties.bind(this);
        this.onChangeReviewerCurrencyCode = this.onChangeReviewerCurrencyCode.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.getCurrencyIcon = this.getCurrencyIcon.bind(this);
        this.onChangeTimeZones = this.onChangeTimeZones.bind(this);
    }

    componentDidMount() {
        this.props.dispatchValidState();
        this.props.getAllTypes();
        this.props.getActiveCountries();
        this.props.userLoaded();
        this.props.getAllTimeZones(this.props.timezones);
    }

    createUser(e) {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            let data = {
                ...this.state,
                code: shortId.generate(),
                reviewerCurrencyCode: getSelectFieldValue(this.state.reviewerCurrencyCode),
                caseTypeCodesExcluded: getMultiSelectValue(this.state.caseTypeCodesExcluded),
                countryCode: getSelectFieldValue(this.state.countryCode),
                stateCode: getSelectFieldValue(this.state.stateCode),
                reviewerTypeCodes: this.state.reviewerTypeCodes.map((specCode) => specCode.value),
                specialtyCodes: this.state.specialtyCodes.map((specCode) => specCode.value),
                email: this.state.email.trim(),
                notificationEmail: this.state.email.trim(),
                assignedPerDayMax: 10,
                timeZoneCode: this.state.timezone.value,
            };
            this.props.signUp(data);
        } else {
            this.props.showMessages();
        }
    }

    onChange(e) {
        if(e.target.name.toLowerCase().includes('email'))
            this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, '') });
        else
            this.setState({ [e.target.name]: e.target.value });
    }

    getCurrencyIcon() {
        const { reviewerCurrencyCode } = this.state;

        if (!reviewerCurrencyCode) return currencyToIcon['ENY'];
        if (typeof reviewerCurrencyCode === 'string') return currencyToIcon[reviewerCurrencyCode];
        if (typeof reviewerCurrencyCode === 'object') return currencyToIcon[reviewerCurrencyCode.value];
    }

    onChangeHrlyRate(e) {
        if (!e.target.value) return this.setState({ hrlyRate: '' });
        this.setState({
            hrlyRate: parseFloat(e.target.value).toFixed(2),
        });
    }

    onChangeCountry(countryCode) {
        !countryCode || countryCode.value !== 'US' ? this.setState({ countryCode, stateCode: null }) : this.setState({ countryCode });
    }

    onChangeState(stateCode) {
        this.setState({ stateCode });
    }

    ChangeReviewerTypeCodes(reviewerTypeCodes) {
        this.setState({ reviewerTypeCodes });
    }

    onChangeSpecialties(specialtyCodes) {
        this.setState({ specialtyCodes: specialtyCodes });
    }

    onChangeCaseTypeCodes = (caseTypeCodesExcluded) => {
        this.setState({ caseTypeCodesExcluded });
    };

    onChangeReviewerCurrencyCode(reviewerCurrencyCode) {
        this.setState({ reviewerCurrencyCode });
    }

    onChangeCheckBox(e) {
        this.setState({ [e.target.name]: e.target.checked });
    }

    onChangeTimeZones(timeZone) {
        this.setState({ timezone: timeZone });
    }

    render() {
        return (
            <form className="modal-content custom-modal-content" onSubmit={this.createUser} noValidate>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            required
                            label="First Name"
                            id="First Name"
                            className="form-control"
                            divClasses="show"
                            name="firstName"
                            valids="required|alpha_dash_space"
                            validator={this.props.validator}
                            value={this.state.firstName}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            required
                            label="Last Name"
                            id="Last Name"
                            className="form-control"
                            divClasses="show"
                            name="lastName"
                            valids="required|alpha_dash_space"
                            validator={this.props.validator}
                            value={this.state.lastName}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            icon="envelope-o"
                            type="input-icon"
                            required
                            label="Email"
                            id="Email"
                            className="form-control"
                            name="email"
                            valids="required|email|unique"
                            validator={this.props.validator}
                            value={this.state.email}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            icon="phone"
                            type="input-icon"
                            required
                            label="Phone Number"
                            id="Phone Number"
                            className="form-control"
                            name="phoneNumber"
                            validator={this.props.validator}
                            value={this.state.phoneNumber}
                            valids="required"
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            xs="6"
                            type="REF_STATE"
                            label="State"
                            id="State"
                            name="stateCode"
                            disabled={getSelectFieldValue(this.state.countryCode) + '' !== 'US'}
                            value={this.state.stateCode}
                            onChange={this.onChangeState.bind(this)}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            type="select"
                            label="Country"
                            id="Country"
                            className="text-bg"
                            required
                            disabled={false}
                            options={this.props.countries}
                            name="countryCode"
                            validator={this.props.validator}
                            value={this.state.countryCode}
                            valids="required"
                            onChange={this.onChangeCountry.bind(this)}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            className="required-field"
                            type="REF_SPECIALTY"
                            required
                            label="Specialties"
                            id="Specialties"
                            searchable={true}
                            multi={true}
                            name="specialtyCodes"
                            validator={this.props.validator}
                            value={this.state.specialtyCodes}
                            valids="required"
                            onChange={this.onChangeSpecialties}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            type="REF_CASE_TYPE"
                            label="Case Types Excluded"
                            id="Case Types"
                            className="text-bg"
                            searchable
                            multi
                            name="caseTypeCodesExcluded"
                            validator={this.props.validator}
                            value={this.state.caseTypeCodesExcluded}
                            onChange={this.onChangeCaseTypeCodes}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            className="text-bg required-field"
                            type="select"
                            label="Reviewer Type"
                            id="Reviewer Type"
                            required
                            searchable={true}
                            multi={true}
                            options={this.props.reviewerTypeCodes}
                            name="reviewerTypeCode"
                            validator={this.props.validator}
                            value={this.state.reviewerTypeCodes}
                            valids="required"
                            onChange={this.ChangeReviewerTypeCodes}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            required
                            label="Accepted Per Month - Max"
                            id="Accepted Per Month - Max"
                            className="form-control"
                            divClasses="show"
                            name="acceptedPerMonthMax"
                            validator={this.props.validator}
                            value={this.state.acceptedPerMonthMax}
                            valids="required"
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            required
                            label="Accepted Per Month - Min"
                            id="Accepted Per Month - Min"
                            className="form-control"
                            divClasses="show"
                            name="acceptedPerMonthMin"
                            validator={this.props.validator}
                            value={this.state.acceptedPerMonthMin}
                            valids="required"
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                        <FormField
                            md="6"
                            required
                            type="REF_CURRENCY"
                            className="text-bg required-field"
                            label="Reviewer Currency "
                            id="Reviewer Currency "
                            searchable={true}
                            name="specialtyCodes"
                            validator={this.props.validator}
                            value={this.state.reviewerCurrencyCode}
                            valids="required"
                            onChange={this.onChangeReviewerCurrencyCode}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <FormField
                            type="input-icon"
                            icon={this.getCurrencyIcon()}
                            text="Kr"
                            step={0.01}
                            md="6"
                            required
                            label="Hourly Rate"
                            id="Hourly Rate"
                            className="form-control"
                            divClasses="show"
                            name="hrlyRate"
                            value={this.state.hrlyRate}
                            valids="required"
                            validator={this.props.validator}
                            onChange={this.onChange}
                            onBlur={(e) => {
                                this.setState(
                                    {
                                        hrlyRate: reformatCurrency(e.target.value),
                                    },
                                    this.props.dispatchValidState,
                                );
                            }}
                        />
                        <FormField
                            md="6"
                            required
                            type="input-icon"
                            icon="percent"
                            label="Reviewer Flat Margin"
                            id="Reviewer Flat Margin"
                            className="form-control"
                            divClasses="show "
                            name="reviewerFlatMargin"
                            valids="required|integer"
                            validator={this.props.validator}
                            value={this.state.reviewerFlatMargin}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            id="Time Zone"
                            type="select"
                            label="Time Zone"
                            className="text-bg required-field"
                            required
                            valids="required"
                            validator={this.props.validator}
                            searchable={true}
                            multi={false}
                            disabled={false}
                            options={this.props.timezones}
                            name="timeZoneCode"
                            value={this.state.timezone}
                            onChange={this.onChangeTimeZones}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            type="checkbox"
                            title="Status"
                            label="Active"
                            id="Active"
                            name="active"
                            value={this.state.active}
                            checked={this.state.active}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                            classBox="checkbox-padding"
                        />
                    </div>
                </div>

                <p className="login-box-msg">{this.props.error ? <span className="text-danger">{this.props.error} </span> : null}</p>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right text-right">
                            <button className="btn btn-flat btn-primary " disabled={this.props.isLoading}>
                                Add Reviewer
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    const users_emails = state.app.users.users.map((item) => {
        return item.email;
    });

    return {
        isLoading: state.app.users.loading,
        error: state.app.users.error,
        countries: state.app.countries.all.map((country) => ({
            value: country.code,
            label: country.name,
        })),

        specialties: state.app.specialties.data.map((spec) => ({
            value: spec.code,
            label: spec.name,
        })),

        reviewerTypeCodes: state.app.reviewerTypes.data
            .filter((item) => item.active)
            .map((type) => ({
                value: type.code,
                label: type.name,
            })),
        currencies: state.app.currencies.data.map((currency) => ({
            value: currency.code,
            label: currency.fullName,
        })),
        customRules: {
            unique: {
                message: 'User with this email address is existing.',
                rule: function (val) {
                    return !users_emails.includes(val);
                },
            },
        },
        top: 0,
        timezones: state.app.timezone.data
            .filter((item) => item.active)
            .map((zone) => ({
                value: zone.code,
                label: zone.name,
            })),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (user, userType = 'reviewers') => {
            dispatch(createNewUser(user, userType, 'auth/user/create'));
        },
        getAllTypes: () => dispatch(getAllTypes()),
        getActiveCountries: () => dispatch(getActiveCountries()),
        userLoaded: () => dispatch(userLoaded()),
        getAllTimeZones: (timezone) => dispatch(getAll('time_zone', timezone)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(ReviewerCreateForm));
