import React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "utils/simple-react-validator";
import FormField from '../../../components/FormField/index'
import { editField, editMCSystem, getMCSystem, isAllValid } from '../../../redux/actions/mcsystemsActions'
import PropTypes from "prop-types";
import RemoteGrid from "../../../components/Grid/RemoteGrid";
import { withRouter } from "react-router-dom";
import { getSCSystem } from "../../../redux/actions/scsystemsActions";
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import { getAll } from "../../../redux/actions/BasicEntityActions";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Box from "../../../components/Box";
import BoxHeader from "../../../components/BoxHeader";
import BoxBody from "../../../components/BoxBody";
import SystemMessage from '../../../components/SystemMessage';

class CodingSystemForm extends React.Component {
    constructor(props) {
        super(props);
        let this_props = this.props;
        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This name already exists in the system.',
                rule: function (val) {
                    let mc_names = [];
                    this_props.mcSystems.forEach((item) => {
                        if (item.code !== this_props.match.params.id) {
                            mc_names.push(item.name);
                        }
                    });
                    return !mc_names.includes(val);
                }
            }
        });
        this.validator.showMessages()

        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
        this.props.getMcSystem(this.props.match.params.id);
        this.props.getAllSc(this.props.match.params.id);
    }

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onChange(e) {
        this.props.editField(e.target.name, e.target.value);
    }

    onChangeCheckBox(e) {
        this.props.editField(e.target.name, e.target.checked);
    }

    onRowClick(item) {
        this.props.getScSystem(item);
        setTimeout(this.props.history.push(`/coding_system_code/${item}`), 2000);
    }

    enumFormatter(cell, row, enumObject) {
        return enumObject[ cell ];
    }

    onSave = () => {
        return this.props.editMCSystem({ ...this.props.mcSystem })
    }

    render() {
        let mcCols = [
            {
                'isKey': true,
                'name': 'code',
                'sort': false,
                'hidden': true
            }, {
                'name': 'name',
                'title': 'Code',
                'columnTitle': true,
                'width': '150',
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'description',
                'title': 'Description',
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'serviceS',
                'title': 'Services',
                'dataFormat': this.enumFormatter,
                'sort': this.props.mcSystem.service,
                'formatExtraData': {
                    'no': this.context.translate('common.form.no'),
                    'yes': this.props.mcSystem.service ? this.context.translate('common.form.yes') : this.context.translate('common.form.no')
                },
                'width': '100',
                'filter': this.props.mcSystem.service ? {
                    type: 'SelectFilter', options: {
                        'no': this.context.translate('common.form.no'),
                        'yes': this.context.translate('common.form.yes')
                    }
                } : ''
            }, {
                'name': 'diagnosisS',
                'title': 'Diagnoses',
                'dataFormat': this.enumFormatter,
                'sort': this.props.mcSystem.diagnosis,
                'formatExtraData': {
                    'no': this.context.translate('common.form.no'),
                    'yes': this.props.mcSystem.diagnosis ? this.context.translate('common.form.yes') : this.context.translate('common.form.no')
                },
                'width': '100',
                'filter': this.props.mcSystem.diagnosis ? {
                    type: 'SelectFilter', options: {
                        'no': this.context.translate('common.form.no'),
                        'yes': this.context.translate('common.form.yes')
                    }
                } : ''
            }, {
                'name': 'active',
                'title': 'Status',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': 'Inactive',
                    'true': 'Active'
                },
                'width': '150',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': 'Inactive',
                        'true': 'Active'
                    }
                }
            }
        ];

        let valid_names = 'required|unique:' + this.props.mc_names;
        const { sorting, pagination } = this.props.gridConfiguration

        const { editMCSystemSuccess, isProfileChanged, isValid, showValidMessages, isLoading, isEditable } = this.props;
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[ field ],
        }))
        return (
            <div className="content" style={{ marginTop: '10px' }}>
                <SystemMessage shown={editMCSystemSuccess && isValid} type='success' message='Medical System successfully updated'/>
                <SystemMessage shown={showValidMessages} type='error' message={this.props.error} validationMessages={errorMessages}/>
                <Box color="green" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`${this.context.translate('common.form.coding_system')} - ${this.props.mcSystem.name}`} color="green"
                               icon="fa-list-alt" classes="box-profile-first-row-height-show"/>
                    {isLoading && <LoadingIndicator type="form"/>}
                    <BoxBody>
                        <PreventTransitionPrompt
                            when={isProfileChanged}
                            message='MyCustomDialogComponent'
                            isFormValid={isValid}
                            entity='mc_system'
                            saveChanges={this.onSave}
                            cancelAndDiscard={() => this.props.getMcSystem(this.props.mcSystem.code)}
                        />
                        <form
                            className='modal-content custom-modal-content'
                            onSubmit={this.Submit}>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        label={this.context.translate('common.form.coding_system')}
                                        id={this.context.translate('common.form.coding_system')}
                                        required
                                        valids={valid_names}
                                        validator={this.validator}
                                        value={this.props.mcSystem.name}
                                        onChange={this.onChange}
                                        name='name'
                                        onBlur={this.dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="4"
                                        type="checkbox"
                                        title={this.context.translate('common.form.status')}
                                        label={this.context.translate('common.form.active')}
                                        name='active'
                                        value={this.props.mcSystem.active}
                                        checked={this.props.mcSystem.active}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        disabled={!isEditable}
                                    />
                                    <FormField
                                        md="4"
                                        type="checkbox"
                                        title={this.context.translate('common.form.services')}
                                        label={this.context.translate('common.form.services')}
                                        name='service'
                                        value={this.props.mcSystem.service}
                                        checked={this.props.mcSystem.service}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        disabled={!isEditable}
                                    />
                                    <FormField
                                        md="4"
                                        type="checkbox"
                                        title={this.context.translate('common.form.diagnoses')}
                                        label={this.context.translate('common.form.diagnoses')}
                                        name='diagnosis'
                                        value={this.props.mcSystem.diagnosis}
                                        checked={this.props.mcSystem.diagnosis}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </form>
                        <RemoteGrid
                            color="green"
                            entity="sc_system"
                            title=""
                            data={this.props.codes}
                            total={this.props.codes ? this.props.codes.length : 0}
                            columns={mcCols}
                            pagination={true}
                            page={pagination.page}
                            sizePerPage={pagination.sizePerPage}
                            search={true}
                            onRowClick={this.onRowClick}
                            defaultSort
                            sortName={sorting.sortName}
                            sortOrder={sorting.sortOrder}
                        />
                    </BoxBody>
                </Box>
            </div>
        );
    }

    static propTypes = {
        isLoading: PropTypes.bool,
    }
    static defaultProps = {
        isLoading: false
    };
}

CodingSystemForm.contextTypes = {
    translate: PropTypes.func
}

const allowToEdit = (role) => (role === 'ROLE_ADMIN' || role === 'ROLE_CLIENT_ADMIN');

const mapStateToProps = (state, ownProps) => ({
    isLoading: state.app.mcSystem.loading,
    isEditable: allowToEdit(state.app.auth.user.roleCode) || state.app.users.my_permissions.includes('MANAGE_SUR'),
    error: state.app.mcSystem.error,
    editMCSystemSuccess: state.app.mcSystem.editMCSystemSuccess,
    showValidMessages: state.app.mcSystem.showValidMessages,
    mcSystem: state.app.mcSystem.editedMCSystem,
    isProfileChanged: state.app.mcSystem.isProfileChanged,
    codes: state.app.scSystem.data.filter((item) => item.medicalCodingSystemCode === ownProps.match.params.id)
        .map(item => {
            return {
                ...item,
                serviceS: item.service === true ? 'yes' : 'no',
                diagnosisS: item.diagnosis === true ? 'yes' : 'no',
                description: item.description ? item.description[ 'EN' ] : item.description
            }
        }),
    mcSystems: state.app.mcSystem.data,
    isValid: state.app.mcSystem.mcSystemValid,
    gridConfiguration: state.app.grid.scSystem,
})

const mapDispatchToProps = dispatch => ({
    getMcSystem: code => dispatch(getMCSystem(code)),
    getAllSc: () => dispatch(getAll('sc_system')),
    getScSystem: code => dispatch(getSCSystem(code)),
    editMCSystem: (caseType) => dispatch(editMCSystem(caseType)),
    editField: (field, value) => dispatch(editField(field, value)),
    isAllValid: (valid) => dispatch(isAllValid(valid))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CodingSystemForm))
