import React from 'react';
import PropTypes from 'prop-types';

const LoginFields = ({ onChange }) => {
  return (
    <>
      <div className="form-group has-feedback">
        <input
          id="username"
          autoFocus
          className="form-control"
          required="required"
          placeholder="Email"
          name="username"
          autoComplete="email"
          onChange={onChange}
        />
        <span className="glyphicon glyphicon-user form-control-feedback" />
      </div>
      <div className="form-group has-feedback">
        <input
          id="password"
          className="form-control"
          type="password"
          name="password"
          required="required"
          placeholder="Password"
          autoComplete="off"
          onChange={onChange}
        />
        <span className="glyphicon glyphicon-lock form-control-feedback" />
      </div>
      <div className="form-group has-feedback">
        <input
          id="google_authenticator"
          className="form-control"
          type="input"
          name="Google_authenticator"
          placeholder="Google Authenticator Code"
          autoComplete="off"
          onChange={onChange}
        />
      </div>
    </>
  );
};

LoginFields.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default LoginFields;
