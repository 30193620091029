import React from 'react';
import {connect} from 'react-redux';
import SimpleReactValidator from "../../utils/simple-react-validator";
import {getPhysician, isAllValid, setDefaultPhysician} from '../../redux/actions/physicianActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../components/LoadingIndicator";
import PhysicianCommonInfo from "./PhysicianCommonInfo";
import {withRouter} from "react-router-dom";
import FormValidateWrapper from '../../containers/FormValidateWrapper'

class PhysicianCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.validator = new SimpleReactValidator({
            emailOrEmpty: {
                message: 'The Email must be a valid email address.',
                rule: function (val) {
                    return this._testRegex(val, /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) || val === '';
                }
            }
        });
        this.validator.showMessages();
    }

    componentDidMount() {
        this.props.setDefaultPhysician('')
    }

    render() {
        let showSuccess = this.props.editPhysicianSuccess ? 'block' : 'none';
        const ValidatedForm = FormValidateWrapper(PhysicianCommonInfo);

        return (
            <div className='modal-content custom-modal-content'>
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <div className="alert alert-success" style={{display: showSuccess}}>
                    <strong>{this.context.translate('common.message.physician_success')}</strong>
                </div>
                <ValidatedForm top='0' isAllValid={this.props.isAllValid}
                               showValidMessages={this.props.showValidMessages}
                               error={this.props.error}>
                </ValidatedForm>
            </div>
        );
    }
}

PhysicianCreateForm.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    editPhysicianSuccess: state.app.physician.editPhysicianSuccess,
    showValidMessages: state.app.physician.showValidMessages,
    error: state.app.physician.error,
});

const mapDispatchToProps = dispatch => ({
    setDefaultPhysician: () => dispatch(setDefaultPhysician('')),
    getPhysician: () => dispatch(getPhysician('')),
    isAllValid: (valid) => dispatch(isAllValid(valid))
});

PhysicianCreateForm = withRouter(PhysicianCreateForm);
export default connect(mapStateToProps, mapDispatchToProps)(PhysicianCreateForm)
