import { connect } from 'react-redux'
import { getAll } from '../../redux/actions/BasicEntityActions'
import RefField from './RefField'

const mapStateToProps = (state, ownProps) => ({
  // isLoading:  state.app.templates.loading,
  options: state.app.templates.sections
  .filter((item) => item.active)
      .map(item => ({
          value: item.code,
          label: item.displayName['EN']
      })),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getOptions: () => dispatch(getAll('diagnosis')),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefField)
