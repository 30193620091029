import React from 'react';
import ColoredBox from 'components/ColoredBox/ColoredBox';
import PageContainer from 'components/PageContainer/PageContainer';
import ConnectedDataTable from '../../components/Datatable/ConnectedDataTable';
import queuedMessageSlice from '../../redux/pages/queued-message/queuedMessageSlice';
import { sendMessage } from '../../redux/actions/queuedMessageActions';
import { useDispatch, useSelector } from 'react-redux';

const columns = {
  notificationTemplateName: {
    label: 'Notification Template Name',
    colStyle: { width: '40%' },
  },
  patientCaseId: { label: 'Patient Case ID', colStyle: { width: '20%' } },
  queuedFor: {
    label: 'Queued For',
    colStyle: { width: '15%' },
    filterable: false,
  },
  status: { label: 'Status', colStyle: { width: '10%' } },
  sentAt: { label: 'Sent At', colStyle: { width: '15%' }, filterable: false },
};

// eslint-disable-next-line react/prop-types
const QueuedMessagesPage = () => {
  const dispatch = useDispatch();

  const {
    settings,
  } = useSelector((state) => state.app.queuedMessages);

  return (
    <PageContainer>
      <ColoredBox variant="primary" title="Queued Messages">
        <ConnectedDataTable
          columns={columns}
          stateKey="queuedMessages"
          actions={queuedMessageSlice.actions}
          variant="primary"
          customActions={(rowData) => {
            return (
              <button
                className={'btn btn-primary'}
                onClick={() => {
                  // console.log('Send Message', settings);
                  dispatch(sendMessage(rowData, settings));
                }}
              >
                Send
              </button>
            );
          }}
        />
      </ColoredBox>
    </PageContainer>
  );
};

export default QueuedMessagesPage;
