import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'
import PropTypes from "prop-types";
import { editFacility } from '../../../redux/actions/facilitiesActions'
import { getMultiSelectValue, getSelectFieldValue } from "../../../utils/helpers";
import { showValidation } from '../../../redux/actions/BasicEntityActions'

class FacilitySave extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        e.preventDefault();
        if (this.props.isValid) {
            this.props.saveFacility({
                ...this.props.facility,
                countryCode: getSelectFieldValue(this.props.facility.countryCode),
                stateCode: getSelectFieldValue(this.props.facility.stateCode),
                bodyPartCodes: getMultiSelectValue(this.props.facility.bodyPartCodes),
                facilityTypeCode: getSelectFieldValue(this.props.facility.facilityTypeCode),
                active: this.props.facility.active,
                code: this.props.facility.code
            }, closeAfterSave)
        } else {
            this.props.showValidation()
        }
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isFacilityChanged}
                    onSubmit={this.onSave}
                />
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isFacilityChanged}
                    onSubmit={(e) => this.onSave(e, true)}
                    title={'Save And Close'}
                />
            </React.Fragment>
        )
    }
}

FacilitySave.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        facility: state.app.facilities.editedFacility,
        isValid: state.app.facilities.facilityValid,
        isFacilityChanged: state.app.facilities.isFacilityChanged,
        isEditable: state.app.users.my_permissions.includes('MODIFY_FACILITIES'),
    }
};

const mapDispatchToProps = dispatch => ({
    saveFacility: (data, closeAfterSave) => dispatch(editFacility(data, closeAfterSave)),
    showValidation: () => dispatch(showValidation('facility'))
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilitySave)
