import React from 'react';
import {connect} from 'react-redux';
import shortId from 'shortid'
import SimpleReactValidator from "utils/simple-react-validator";
import FormField from '../../components/FormField/index'
import {createNewFacilityType} from '../../redux/actions/facilitiesActions'
import PropTypes from "prop-types";
import SystemMessage from '../../components/SystemMessage';

class FacilityTypeCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            name: ''
        };

        let match = this.props;
        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This value already exists in the system, the new record won\'t be created.',
                rule: function (val) {
                    return !match.facilities_names.split(',').includes(val);
                }
            }
        });
        this.validator.showMessages();

        this.Submit = this.Submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);

    }

    onChangeCheckBox(e) {
        // const newValue = e.target.value === 'true' ? false : true
        this.setState({[e.target.name]: e.target.checked});
    }

    Submit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            const data = {
                ...this.state,
                code: shortId.generate(),
            }
            this.props.signUp(data);
        } else {
            this.setState({messagesShown: true})

        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        // let showError = this.props.error ? 'block' : 'none'
        let valid_names = 'required|unique:' + this.props.facilities_names;
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
        }))

        return (
            <form noValidate autoComplete="off"
                  className='modal-content custom-modal-content'
                  onSubmit={this.Submit}>
                <SystemMessage shown={this.state.messagesShown} type='error' message={this.props.error} validationMessages={errorMessages}/>

                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="12"
                            label={this.context.translate('common.form.name')}
                            id={this.context.translate('common.form.name')}
                            required
                            valids={valid_names}
                            validator={this.validator}
                            value={this.state.name}
                            onChange={this.onChange}
                            name='name'
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="12"
                            type="checkbox"
                            title={this.context.translate('common.form.status')}
                            label={this.context.translate('common.form.active')}
                            name='active'
                            value={this.state.active}
                            checked={this.state.active}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right">
                            <button
                                className='btn btn-block btn-flat btn-primary'
                                disabled={this.props.isLoading}
                            >
                                {this.context.translate('common.client.add_facility_type')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

FacilityTypeCreateForm.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => ({
    facilities_names: state.app.facilities.types
        .map((item) => {
            return item.name;
        }).join(','),
    isLoading: state.app.facilities.loading,
    error: state.app.facilities.error,
})

const mapDispatchToProps = dispatch => ({
    signUp: (facility_type) => {
        dispatch(createNewFacilityType(facility_type))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(FacilityTypeCreateForm)
