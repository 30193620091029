import React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "utils/simple-react-validator";
import FormField from '../../components/FormField/index'
import { editFacility, facilityEditField, getFacility, isAllValid, setCurrentTab } from '../../redux/actions/facilitiesActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../components/LoadingIndicator";
import { getMultiSelectValue, getSelectFieldValue } from "../../utils/helpers";
import { Tab, Tabs } from "react-bootstrap";
import { getActiveCountries, getActiveStates } from "../../redux/actions/countryActions";
import { getAll } from "../../redux/actions/BasicEntityActions";
import FacilityEditPhysicians from "./FacilityEditPhysicians";
import FacilityEditClients from "./FacilityEditClients";
import Box from "../../components/Box";
import BoxHeader from "../../components/BoxHeader";
import BoxBody from "../../components/BoxBody";
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';
import SystemMessage from '../../components/SystemMessage';

const tabToField = {
    'Name': '1',
};

class FacilityEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.facility;

        let this_props = this.props;
        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This name already exists in the system.',
                rule: function (val) {
                    let facilities_names = [];
                    this_props.facilities.forEach((item) => {
                        if (item.code !== this_props.match.params.id) {
                            facilities_names.push(item.name);
                        }
                    });
                    return !facilities_names.includes(val);
                }
            }
        });
        this.validator.showMessages();


        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeFacilityType = this.onChangeFacilityType.bind(this);
        this.onChangeBodyPartCodes = this.onChangeBodyPartCodes.bind(this);
        this.dispatchValidState = this.dispatchValidState.bind(this);
    }

    componentDidMount() {
        this.props.getActiveCountries();
        this.props.getActiveStates();
        this.props.getAllBodyParts();
        this.props.getAllTypes();
        this.props.getFacility(this.props.match.params.id);
    }

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    onChangeCheckBox(e) {
        this.props.facilityEditField([e.target.name], e.target.checked)
    }

    onChange(e) {
        this.setState({ [ e.target.name ]: e.target.value });
        this.props.facilityEditField(e.target.name, e.target.value);
        if (e.target.name === 'name') {
            this.dispatchValidState();
        }
    }

    changeCountry(countryCode) {
        this.props.facilityEditField('countryCode', countryCode);
        if (countryCode) {
            if (countryCode.value !== 'US') {
                this.props.facilityEditField('stateCode', '');
            }
        } else {
            this.setState({ stateCode: {} })
        }
    }

    onChangeState(stateCode) {
        this.props.facilityEditField('stateCode', stateCode)
    }

    onChangeFacilityType(facilityTypeCode) {
        this.props.facilityEditField('facilityTypeCode', facilityTypeCode)
    }

    onChangeBodyPartCodes(bodyPartCodes) {
        this.props.facilityEditField('bodyPartCodes', bodyPartCodes)
    }

    handleSelect = (key) => {
        this.props.setCurrentTab(key)
    };

    onSave = () => {
        return this.props.saveFacility({
            ...this.props.facility,
            countryCode: getSelectFieldValue(this.props.facility.countryCode),
            stateCode: getSelectFieldValue(this.props.facility.stateCode),
            bodyPartCodes: getMultiSelectValue(this.props.facility.bodyPartCodes),
            facilityTypeCode: getSelectFieldValue(this.props.facility.facilityTypeCode),
            active: this.props.facility.active,
            code: this.props.facility.code
        })
    };

    render() {
        const { match, isFacilityChanged, editFacilitySuccess, facilityValidMessages, error, isValid, isLoading, isEditable } = this.props;
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[ field ],
            tab: tabToField[ field ]
        }));

        return (
            <div className='content'>
                {isLoading && <LoadingIndicator type="tabs"/>}
                <PreventTransitionPrompt
                    when={isFacilityChanged}
                    message='MyCustomDialogComponent'
                    isFormValid={this.props.isValid}
                    entity='facility'
                    saveChanges={this.onSave}
                    cancelAndDiscard={() => this.props.getFacility(this.props.facility.code)}
                />
                <Tabs activeKey={this.props.currentTab} defaultActiveKey="1" animation={true} id="noanim-tab-example"
                      onSelect={this.handleSelect}>
                    <SystemMessage
                        shown={editFacilitySuccess && isValid}
                        type='success'
                        message='Facility successfully updated'
                        top='7em'
                    />
                    <SystemMessage
                        shown={facilityValidMessages}
                        type='error' message={error}
                        validationMessages={errorMessages}
                        goToTab={this.handleSelect}
                        top='7em'
                    />
                    <Tab eventKey="1" title={this.context.translate('common.form.general_information')}>
                        {isLoading && <LoadingIndicator height={50}/>}
                        <Box color="green" classes="box-profile-first-row-height-show">
                            <BoxHeader title={`Facility - ${this.props.facility.name} `} color="green"
                                       icon="fa-list-alt" classes="box-profile-first-row-height-show"/>
                            <BoxBody>
                                <form noValidate autoComplete="off">
                                    <div className="form-group">
                                        <div className='row'>
                                            <FormField
                                                md="6"
                                                label={this.context.translate('common.form.name')}
                                                id={this.context.translate('common.form.name')}
                                                required
                                                valids="required|unique"
                                                validator={this.validator}
                                                value={this.props.facility.name}
                                                onChange={this.onChange}
                                                name='name'
                                                onBlur={this.dispatchValidState}
                                                disabled={!isEditable}
                                            />
                                            <FormField
                                                md="6"
                                                type='REF_FACILITY_TYPE'
                                                label={this.context.translate('common.form.type')}
                                                name='facilityTypeCode'
                                                className="text-bg "
                                                value={this.props.facility.facilityTypeCode}
                                                onChange={this.onChangeFacilityType}
                                                disabled={!isEditable}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <FormField
                                                md="6"
                                                label={this.context.translate('common.form.code')}
                                                value={this.props.facility.facilityCode}
                                                onChange={this.onChange}
                                                name='facilityCode'
                                                disabled={!isEditable}
                                            />
                                            <FormField
                                                md="6"
                                                label={this.context.translate('common.users.address_1')}
                                                className="form-control"
                                                id="user_edit_address1"
                                                name="street1"
                                                value={this.props.facility.street1}
                                                onChange={this.onChange}
                                                disabled={!isEditable}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <FormField
                                                md="6"
                                                label={this.context.translate('common.users.address_2')}
                                                className="form-control"
                                                id="user_edit_address2"
                                                name="street2"
                                                value={this.props.facility.street2}
                                                onChange={this.onChange}
                                                disabled={!isEditable}
                                            />
                                            <FormField
                                                md="6"
                                                label={this.context.translate('common.users.address_3')}
                                                className="form-control"
                                                id="user_edit_address3"
                                                name="street3"
                                                value={this.props.facility.street3}
                                                onChange={this.onChange}
                                                disabled={!isEditable}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <FormField
                                                md="6"
                                                label={this.context.translate('common.form.city')}
                                                className="form-control"
                                                name="city"
                                                value={this.props.facility.city}
                                                onChange={this.onChange}
                                                disabled={!isEditable}
                                            />
                                            <FormField
                                                md="6"
                                                xs="6"
                                                type='REF_STATE'
                                                label={this.context.translate('common.form.state')}
                                                name='stateCode'
                                                className="text-bg "
                                                disabled={!isEditable || getSelectFieldValue(this.props.facility.countryCode) + '' !== 'US'}
                                                value={this.props.facility.stateCode}
                                                onChange={this.onChangeState}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <FormField
                                                md="6"
                                                xs="6"
                                                label={this.context.translate('common.form.postal_code')}
                                                className="form-control"
                                                name="postalCode"
                                                value={this.props.facility.postalCode}
                                                onChange={this.onChange}
                                                disabled={!isEditable}
                                            />
                                            <FormField
                                                md="6"
                                                type="select"
                                                label={this.context.translate('common.form.country')}
                                                className="text-bg "
                                                options={this.props.countries}
                                                id="user_edit_country"
                                                name="countryCode"
                                                value={this.props.facility.countryCode}
                                                onChange={this.changeCountry}
                                                disabled={!isEditable}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <FormField
                                                md="6"
                                                label={this.context.translate('common.form.phone_number')}
                                                className="form-control"
                                                name="phoneNumber"
                                                value={this.props.facility.phoneNumber}
                                                onChange={this.onChange}
                                                disabled={!isEditable}
                                            />
                                            <FormField
                                                md="6"
                                                type="select"
                                                label="Body Parts"
                                                className="text-bg "
                                                searchable
                                                multi={true}
                                                options={this.props.bodyParts}
                                                id="edit_body_parts"
                                                name="bodyPartCodes"
                                                value={this.props.facility.bodyPartCodes}
                                                onChange={this.onChangeBodyPartCodes}
                                                disabled={!isEditable}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <FormField
                                                md="12"
                                                type="checkbox"
                                                title={this.context.translate('common.form.status')}
                                                label={this.context.translate('common.form.active')}
                                                name='active'
                                                value={this.props.facility.active}
                                                checked={this.props.facility.active}
                                                onChange={this.onChangeCheckBox}
                                                className="checkbox"
                                                disabled={!isEditable}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className='col-md-12 col-xs-12 fields'>
                                                <label htmlFor="notes_note"
                                                       className="required">{this.context.translate('common.form.notes')}</label>
                                                <textarea name="notes"
                                                          id="notes_note"
                                                          cols="30" rows="7"
                                                          value={this.props.facility.notes}
                                                          onChange={this.onChange}
                                                          disabled={!isEditable}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </BoxBody>
                        </Box>
                    </Tab>
                    <Tab eventKey={'clients'} title={this.context.translate('common.form.clients')}>
                        {isLoading && <LoadingIndicator height={50}/>}
                        <div className="table-responsive">
                            <FacilityEditClients match={match} validator={this.validator}/>
                        </div>
                    </Tab>
                    <Tab eventKey={'physicians'} title={this.context.translate('common.form.physicians')}>
                        {isLoading && <LoadingIndicator height={50}/>}
                        <div className="table-responsive">
                            <FacilityEditPhysicians match={match} validator={this.validator}/>
                        </div>
                    </Tab>
                    {isLoading && <LoadingIndicator height={50}/>}
                </Tabs>
            </div>
        );
    }

    static propTypes = {
        isLoading: PropTypes.bool,
    }
    static defaultProps = {
        isLoading: false
    };
}

FacilityEditPage.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    isLoading: state.app.facilities.loading,
    isEditable: state.app.users.my_permissions.includes('MODIFY_FACILITIES'),
    error: state.app.facilities.error,
    facilities_types: state.app.facilities.types.filter((item) => item.active),
    facilities: state.app.facilities.data,
    currentTab: state.app.facilities.currentTab,
    isFacilityChanged: state.app.facilities.isFacilityChanged,
    facilityValidMessages: state.app.facilities.facilityValidMessages,

    editFacilitySuccess: state.app.facilities.editFacilitySuccess,
    countries: state.app.countries.data
        .map(country => ({
            value: country.code,
            label: country.name
        })),
    states: state.app.countries.states
        .map(state => ({
            value: state.code,
            label: state.name
        })),
    bodyParts: state.app.bodyParts.data
        .filter((item) => item.active)
        .map(item => ({
            value: item.code,
            label: item.name
        })),
    facility: state.app.facilities.editedFacility,
    isValid: state.app.facilities.facilityValid
});

const mapDispatchToProps = dispatch => ({
    getActiveCountries: () => dispatch(getActiveCountries()),
    getActiveStates: () => dispatch(getActiveStates()),
    getAllBodyParts: () => dispatch(getAll('body_part')),
    getAllTypes: () => dispatch(getAll('facility_type')),
    getFacility: code => dispatch(getFacility(code)),
    facilityEditField: (field, value) => dispatch(facilityEditField(field, value)),
    setCurrentTab: (tab) => dispatch(setCurrentTab(tab)),
    isAllValid: (valid) => dispatch(isAllValid(valid)),
    saveFacility: data => dispatch(editFacility(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilityEditPage)
