import React from 'react'
import {connect} from 'react-redux';
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import {getSelectFieldValue, isStaff} from '../../utils/helpers'
import {editUser, getUserProfile, isAllValid, userEditField} from '../../redux/action-creators/users'
import FormField from "../../components/FormField";
import ContactInfoSection from "../../components/Sections/ContactInfoSection";
import AddressInfoSection from "../../components/Sections/AddressInfoSection";
import LoadingIndicator from "../../components/LoadingIndicator";
import PropTypes from "prop-types";

class StaffGeneralInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        if (nextProps.languages && (this.state.firstLoad || JSON.stringify(nextProps.user) !== JSON.stringify(this.props.user))) {
            let myLanguage = '';
            let myLocale = '';
            if (nextProps.profile) {
                myLanguage = this.props.languages.find((item) => {
                    return (item.value === nextProps.user.defaultLanguageCode) ? item : null;
                });
                myLocale = this.props.locales.find((item) => {
                    return (item.value === nextProps.user.defaultLocaleCode) ? item : null;
                });
                this.setState({
                    defaultLanguageCode: myLanguage,
                    defaultLocaleCode: myLocale
                });
            }
            this.setState({firstLoad: false});
        }

    }

    onChangeCheckBox(e) {
        const newValue = e.target.value !== 'true'
        this.setState({[e.target.name]: newValue});
        this.props.userEditField([e.target.name], newValue)
    }

    onChange(e) {
        if(e.target.name.toLowerCase().includes('email'))
            this.props.userEditField(e.target.name, e.target.value.replace(/\s+/g, ''));
        else
            this.props.userEditField(e.target.name, e.target.value );
    }

    onChangeState = (stateCode) => {
        this.props.userEditField('stateCode', stateCode)
    }

    onChangeCountry = (countryCode) => {
        console.log(countryCode);
        this.props.userEditField('countryCode', countryCode)
        if (!countryCode || getSelectFieldValue(countryCode) !== 'US') {
            this.props.userEditField('stateCode', null)
        }
    }

    onChangeDefaultLocale(defaultLocaleCode) {
        this.setState({defaultLocaleCode});
        this.props.userEditField('defaultLocaleCode', defaultLocaleCode)
    }

    render() {
        // const {isProfileChanged, editUserSuccess, isProfileValid, error} = this.props;
        // const staffIpWhitelist = this.props.user.staffIpWhitelist;
        const {isLoading} = this.props;
        return (
            <div className="content">
                <Box color="blue" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`Staff - ${this.props.user.firstName} ${this.props.user.lastName}`}
                               color="blue" icon="fa-user" classes="box-profile-first-row-height-show"/>
                    {isLoading && <LoadingIndicator type="form"/>}
                    <BoxBody>
                        <form onSubmit={this.submitUpdateUser} noValidate autoComplete="off">
                            <div className="bordered form-group">
                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="12"
                                            type='label_field'
                                            label="Personal Information"
                                            className='margin-bottom-15'
                                            style={{fontSize: '1.1em'}}
                                        />
                                        <FormField
                                            md="4"
                                            label="First Name"
                                            id="First Name"
                                            className="form-control"
                                            required
                                            name="firstName"
                                            valids="required|alpha_dash_space"
                                            validator={this.props.validator}
                                            value={this.props.user.firstName}
                                            onChange={this.onChange}
                                            onBlur={this.props.dispatchValidState}
                                        />
                                        <FormField
                                            md="4"
                                            label="Last Name"
                                            id="Last Name"
                                            className="form-control"
                                            required
                                            name="lastName"
                                            valids="required|alpha_dash_space"
                                            validator={this.props.validator}
                                            value={this.props.user.lastName}
                                            onChange={this.onChange}
                                            onBlur={this.props.dispatchValidState}
                                        />
                                    </div>
                                </div>
                            </div>
                            <ContactInfoSection
                                validator={this.props.validator}
                                dispatchValidState={this.props.dispatchValidState}
                                onChange={this.onChange}
                                email={this.props.user.email}
                                notificationEmail={this.props.user.notificationEmail}
                                phoneNumber={this.props.user.phoneNumber}
                                alternativePhoneNumber={this.props.user.alternativePhoneNumber}
                            />
                            <AddressInfoSection
                                onChange={this.onChange}
                                onChangeCountry={this.onChangeCountry}
                                onChangeState={this.onChangeState}

                                address1={this.props.user.address1}
                                address2={this.props.user.address2}
                                city={this.props.user.city}
                                countryCode={this.props.user.countryCode}
                                stateCode={this.props.user.stateCode}
                                postalCode={this.props.user.postalCode}
                            />
                        </form>
                    </BoxBody>
                </Box>
            </div>
        );
    }

    static propTypes = {
        isLoading: PropTypes.bool,
    }
    static defaultProps = {
        isLoading: false
    };
}

const mapStateToProps = (state) => ({
    user: state.app.users.editedUser,
    isLoading: state.app.users.profileLoading,
    isProfileValid: state.app.users.userValid,
    showValidMessages: state.app.users.showValidMessages,
    editUserSuccess: state.app.users.editUserSuccess,
    isProfileChanged: state.app.users.isProfileChanged,
    error: state.app.users.editUserError,
    roles: state.app.users.roles
        .filter((item) => isStaff(item.code) && item.active)
        .map(role => ({
            value: role.code,
            label: role.name
        })),
    languages: state.app.languages.data
        .filter((item) => item.active)
        .map(lang => ({
            value: lang.code,
            label: lang.name
        })),
    locales: state.app.locales.data
        .filter((item) => item.active)
        .map(locale => ({
            value: locale.code,
            label: locale.displayName
        })),
})

const mapDispatchToProps = dispatch => ({
    getUserProfile: code => dispatch(getUserProfile(code)),
    userEditField: (field, value) => dispatch(userEditField(field, value)),
    isAllValid: (valid) => dispatch(isAllValid(valid)),
    saveProfile: data => dispatch(editUser(data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(StaffGeneralInfo)
