import React from 'react';
import { connect } from 'react-redux';
import { getDiagnose, isAllValid} from '../../../redux/actions/diagnosesActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../../components/LoadingIndicator";
import DiagnoseForm from "./DiagnoseForm";
import SimpleReactValidator from "utils/simple-react-validator";
import SystemMessage from '../../../components/SystemMessage';

class DiagnoseCreateForm extends React.Component {
    constructor (props) {
        super(props);

        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This Diagnosis already exists in the system.',
                rule: function(val, options) {
                    let diagnoses_names = [];
                    props.diagnoses.forEach((item) => {
                        if (item.code !== props.params_id) {
                            diagnoses_names.push(item.name);
                        }
                    });
                    return !diagnoses_names.includes(val);
                }
            }
        });

        this.validator.showMessages();
        this.state = {};
    }

    componentDidMount() {
        this.props.getDiagnose('');
        this.setState(this.props.diagnose);
    }

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render () {
        const { match, showValidMessages } = this.props;
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
                  id:field,
                  field,
                  message: this.validator.errorMessages[field],
                }))

        return (
            <div>
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <SystemMessage shown={showValidMessages} type='error' message={this.props.error} validationMessages={errorMessages} top='0'/>

                <DiagnoseForm match={match} params_id="" diagnose={this.props.diagnose} validator={this.validator} dispatchValidState={this.dispatchValidState}/>
            </div>
        );
    }
}

DiagnoseCreateForm.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    diagnose: state.app.diagnoses.editedDiagnose,
    showValidMessages: state.app.diagnoses.showValidMessages,
    diagnoses: state.app.diagnoses.data,
    editDiagnoseSuccess: state.app.diagnoses.editDiagnoseSuccess,
    isLoading: state.app.diagnoses.loading,
    mc_systems: state.app.mcSystem.data
        .map(item => {
            return {
                ...item,
                sCSC: item.medicalCodeCodes.map(sc => {
                    return {
                        value: sc,
                        label: sc
                    }
                })
            }
        }),

    error: state.app.diagnoses.error,
})

const mapDispatchToProps = dispatch => ({
    getDiagnose: code => dispatch(getDiagnose('')),
    isAllValid: ( valid ) => dispatch(isAllValid( valid )),

})

export default connect(mapStateToProps, mapDispatchToProps)(DiagnoseCreateForm)
