import React from 'react';
import { connect } from 'react-redux';
import FormField from '../../../components/FormField';
import { createNewCaseType } from '../../../redux/actions/cases/caseTypesActions';
import FormValidateWrapper from '../../../containers/FormValidateWrapper';

class CaseTypeCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            roleCode: '',
            companyCode: '',
            language: { value: 'EN', label: 'English' },
            names: {},
            tat: null,
        };

        this.Submit = this.Submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    }

    componentDidMount() {
        this.props.dispatchValidState();
    }

    onChangeCheckBox(e) {
        // const newValue = e.target.value === 'true' ? false : true
        this.setState({ [e.target.name]: e.target.checked });
    }

    Submit(e) {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            const data = {
                ...this.state,
                name: this.state.names['EN'],
                code: this.state.code,
                shortName: this.state.shortName,
            };
            console.log(data);
            this.props.signUp(data);
        } else {
            this.props.dispatchValidState();
            this.props.showMessages();
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChangeName = (e) => {
        const { value } = e.target;

        const names = Object.assign({}, this.state.names, {
            [this.state.language.value]: value,
        });
        this.setState({ names });
    };

    onChangeLanguage = (language) => this.setState({ language });

    render() {
        let showError = this.props.error ? 'block' : 'none';
        const { names, shortName, code, tat, active, language } = this.state;

        return (
            <form className="modal-content custom-modal-content" onSubmit={this.Submit} noValidate>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            label="Field Key"
                            valids="required|alpha_num_dash"
                            validator={this.props.validator}
                            value={code}
                            onChange={this.onChange}
                            name="code"
                            required
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            label="Full Name"
                            id="Full Name"
                            required
                            valids="required|unique"
                            validator={this.props.validator}
                            value={names[language.value] ? names[language.value] : ''}
                            onChange={this.onChangeName}
                            onBlur={this.props.dispatchValidState}
                            name="name"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            label="Short Name"
                            valids="max:5|required"
                            validator={this.props.validator}
                            value={shortName}
                            onChange={this.onChange}
                            name="shortName"
                            required
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type="number"
                            label="TAT"
                            min="0"
                            name="tat"
                            value={tat}
                            valids="positive_num"
                            validator={this.props.validator}
                            onBlur={this.props.dispatchValidState}
                            onChange={this.onChange}
                        />
                    </div>
                </div>

                <FormField
                    md="12"
                    type="checkbox"
                    title="Status"
                    label="Active"
                    name="active"
                    value={active}
                    checked={active}
                    onChange={this.onChangeCheckBox}
                    className="checkbox"
                />
                <p className="login-box-msg" style={{ display: showError }}>
                    <span className="text-danger">{this.props.error}</span>
                </p>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right">
                            <button className="btn btn-block btn-flat btn-primary" disabled={this.props.isLoading}>
                                Add Case Type
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const types_names = state.app.case_types.types.map((item) => {
        return item.name;
    });

    return {
        isLoading: state.app.case_types.loading,
        error: state.app.case_types.error,

        //VALIDATOR PROPS
        top: 0,
        customRules: {
            unique: {
                message: "This value already exists in the system, the new record won't be created.",
                rule: function (val, options) {
                    return !types_names.includes(val);
                },
            },
            positive_num: {
                message: 'The :attribute must be greater than or equal to 0.',
                rule: function (val, options) {
                    return parseFloat(val) > 0 || val === '';
                },
            },
        },
    };
};

const mapDispatchToProps = (dispatch) => ({
    signUp: (caseType) => {
        dispatch(createNewCaseType(caseType));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(CaseTypeCreateForm));
