import {logout, setCurrentUser} from './actions/authActions'
import {sessionExpired, triggerLogout, resetLastUserActivityTimeStamp, getCurrentTimeStamp} from './actions/autoLogoutActions'

// eslint-disable-next-line no-unused-vars
export default function autoLogoutMiddleware({dispatch, getState}) {
    const inactionTimeLimitSeconds = 60 * 60;

    return next => action => {
        if (isFromLoggedInUser(action)){
            sessionExpired(lastUserActivityTimeStamp(), inactionTimeLimitSeconds) ?  triggerLogout() : resetLastUserActivityTimeStamp();
        }

        if (isLogin(action)) {
            setSessionStatus('active');
            return next(action);
        }

        if (localStorage.getItem('MEDREV_SESSION_STATUS') === 'finished' && getState().app.auth.user.code) {
            next(logout())
        }

        if (localStorage.getItem('MEDREV_SESSION_STATUS') === 'active' && !getState().app.auth.user.code) {
            const user = JSON.parse(localStorage.getItem('current_user')) || {};
            //const lastVisitedPath = getState().app.auth.logoutPath[user.code];
            next(setCurrentUser(user))
        }

        return next(action)
    }
}

function lastUserActivityTimeStamp(){
    let lastUserActivityTimeStamp = parseInt(localStorage.getItem('LAST_USER_ACTIVITY_TIMESTAMP'), 10);
    if (typeof lastUserActivityTimeStamp === 'undefined'){
        lastUserActivityTimeStamp = getCurrentTimeStamp();
        localStorage.setItem('LAST_USER_ACTIVITY_TIMESTAMP', '' + lastUserActivityTimeStamp);
    }
    return lastUserActivityTimeStamp;
}

function isFromLoggedInUser(action) {
    return ((typeof action.type !== "undefined") && (localStorage.getItem('MEDREV_SESSION_STATUS') === 'active'));
}

function setSessionStatus(status) {
    localStorage.setItem('MEDREV_SESSION_STATUS', status)
}

function isLogin(action){
    return (action && action.type === 'LOGIN_SUCCESS');
}
