import React from 'react';
import ColoredBox from 'components/ColoredBox/ColoredBox';
import PageContainer from 'components/PageContainer/PageContainer';
import ConnectedDataTable from '../../components/Datatable/ConnectedDataTable';
import facilitySlice from '../../redux/pages/facility/facilitySlice';
import PropTypes from 'prop-types';

const getColumns = () => ({
  name: { label: 'Name', colStyle: { maxWidth: 150 } },
  facilityTypeCode: { label: 'Type', colStyle: { maxWidth: 150 } },
  facilityCode: { label: 'Code', colStyle: { maxWidth: 150 } },
  status: {
    label: 'Status',
    colStyle: { maxWidth: 150, width: 150 },
    filterOptions: [
      { value: true, label: 'ACTIVE' },
      { value: false, label: 'INACTIVE' },
    ],
  },
});

const FacilitiesPage = ({ history }) => {
  const handleRowClick = ({ code }) => {
    history.push(`/facility/${code}`);
  };

  return (
    <PageContainer>
      <ColoredBox variant="primary" title="Facilities">
        <ConnectedDataTable
          columns={getColumns()}
          onRowClick={handleRowClick}
          stateKey="facilitiesReducer"
          actions={facilitySlice.actions}
          variant="primary"
        />
      </ColoredBox>
    </PageContainer>
  );
};

FacilitiesPage.propTypes = {
  history: PropTypes.object,
};

export default FacilitiesPage;
