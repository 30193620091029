import React from 'react'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import { getAll } from '../../redux/actions/BasicEntityActions'
import { openDeleteModal } from '../../redux/action-creators/users'
import { facilityEditField } from '../../redux/actions/facilitiesActions'
import { getNamesFromCodes } from '../../utils/helpers'
import {withRouter} from "react-router-dom";

class FacilityEditPhysicians extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            size: 10,
            page: 1
        };
        this.enumFormatter = this.enumFormatter.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        // this.onDeleteFaciltiy = this.onDeleteFaciltiy.bind(this);
        this.onActionClick = this.onActionClick.bind(this);
    }

    componentDidMount() {
        this.props.getAll();
    }

    fetchData(data) {
        this.setState(data);
    }

    onRowClick(item) {
        this.props.history.push(`/physicians/${item}`)
    }

    enumFormatter(cell, row, enumObject) {
        return enumObject[cell];
    }

    onActionClick(physician) {
        const {code} = physician;
        const {physicianCodes} = this.props.facility;
        const {editField} = this.props;

        if (!Array.isArray(physicianCodes)) {
            return editField('physicianCodes', [code])
        }

        if (physicianCodes.indexOf(code) !== -1) {
            editField('physicianCodes', physicianCodes.filter((item) => item !== code))
        } else {
            editField('physicianCodes', [...physicianCodes, code])
        }
    }

    onRowSelect = (row, isSelected) => {
        const {code} = row;
        const { selectedPhysicianCodes } = this.props.facility;
        const {editField} = this.props;

        if (!Array.isArray(selectedPhysicianCodes)) {
            return editField('selectedPhysicianCodes', [code])
        }

        if ( isSelected ) {
            editField('selectedPhysicianCodes', [...selectedPhysicianCodes, code])
        } else {
            editField('selectedPhysicianCodes', selectedPhysicianCodes.filter((item) => item !== code))
        }
        console.log(row);
        console.log(isSelected);
    }

    onSelectAll = (isSelected, currentDisplayAndSelectedData) => {
        const {editField} = this.props;

        if ( isSelected ) {
            const data = this.props.data.map((item) => item.code)
            editField('selectedPhysicianCodes', data)
        } else editField('selectedPhysicianCodes', [])
    }

    render() {
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'title': 'Code',
                'sort': false,
                'hidden': true
            }, {
                'name': 'firstName',
                'title': 'First Name',
                'width': '150',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'lastName',
                'title': 'Last Name',
                'width': '150',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'physicianCode',
                'title': 'Code',
                'columnTitle': true,
                'width': '100',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'specialtyCodes',
                'title': 'Specialties',
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'bodyPartCodes',
                'title': 'Body Parts',
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'languageCodes',
                'title': ' Languages',
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'linked',
                'title': 'Linking',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': 'Unlinked',
                    'true': 'Linked'
                },
                'width': '100',
                'filter': {
                  type: 'SelectFilter',
                  options: {
                        'false': 'Unlinked',
                        'true': 'Linked',
                      },
                      defaultValue: 'true'
                    }
            }
        ];

        const selectRow = {
            mode: 'checkbox',
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll
        };
        const { sorting, pagination } = this.props.gridConfiguration;

        return(
            <div>
                {/*<ClientAddFacilityPanel></ClientAddFacilityPanel>*/}
                <RemoteGrid
                    color="green"
                    entity="facility_physician"
                    title={`Facility - ${this.props.facility.name}`}
                    data={this.props.data}
                    total={this.props.totalElements}
                    columns={columns}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.onRowClick}
                    selectRow={ selectRow }
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.app.physician.data
            .filter((item) => item.active)
            .map(item => {
                let linked;
                const { physicianCodes } = state.app.facilities.editedFacility;
                if (physicianCodes) linked = physicianCodes.indexOf(item.code) !== -1;
                else linked = false;
                return {
                    ...item,
                    specialtyCodes: getNamesFromCodes(item.specialtyCodes, state.app.specialties.data),
                    bodyPartCodes: getNamesFromCodes(item.bodyPartCodes, state.app.bodyParts.data),
                    languageCodes: getNamesFromCodes(item.languageCodes, state.app.languages.data),
                    linked
                }
            }),
        totalElements: state.app.physician.data.length,
        facility: state.app.facilities.editedFacility,
        gridConfiguration: state.app.grid.facilityPhysician,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAll: (data) => dispatch(getAll('physician')),
        handleOpenDeleteModal: () => {
            dispatch(openDeleteModal())
        },
        editField: (field, value) => dispatch(facilityEditField(field, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FacilityEditPhysicians));
