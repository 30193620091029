import React from 'react';
import { connect } from 'react-redux'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import FormField from "../../components/FormField";
import { entityEditField, getAll } from "../../redux/actions/BasicEntityActions";
import { updateTierNumber } from "../../redux/actions/billingActions";
import { capitalizeFirstLetter, getNamesFromCodes } from '../../utils/helpers'
import { getAllTypes } from "../../redux/actions/reviewerTypesAction";
import { getAllCaseTypes } from "../../redux/actions/cases/caseTypesActions"
import PropTypes from "prop-types";

class EditBillingGroup extends React.Component {

    componentDidMount() {
        this.props.getAllCaseTypes();
        this.props.getAll('case_level');
        this.props.getAllReviewerTypes();
    }

    onChangeCheckBox = (e) => {
        this.props.editField([e.target.name], e.target.checked);
        setTimeout(() => {
            this.props.dispatchValidState();
        }, 600);
    };

    onChangeCurrency = (currency) => this.props.editField('currency', currency);
    onChange = (e) => {
        this.props.editField([e.target.name], e.target.value);
        setTimeout(() => {
            this.props.dispatchValidState();
        }, 600);
    };

    handleOpenEdit = (code) => this.props.history.push(`/billing_tier/update/tier/${code}`);

    moveTier = (step, tier) => () => this.props.updateTierNumber(tier, step);

    caseTypesFormatter = (col) => <span>{col}</span>;

    caseLevelFormatter = (col) => capitalizeFirstLetter(col) || 'Any';

    reviewerTypesFormatter = (col) => <span>{col}</span>;

    rateTypeFormatter = (col) => capitalizeFirstLetter(col);

    refTierFormatter = (col) => {
        const { billingTiers } = this.props;
        const codeToName = billingTiers.reduce((result, tier) => ({
            ...result,
            [ tier.code ]: 'Tier ' + tier.tierNumber
        }), {});
        return codeToName[ col ] || '';
    };

    numbersFormatter = (colName) => (col, row) => {
        const minVal = row[ colName + 'Min' ];
        const maxVal = row[ colName + 'Max' ];

        if (maxVal === 0) return 0;

        if (!minVal && !maxVal) return 'Any';

        if (minVal && !maxVal) return '>=' + minVal;

        if (!minVal && maxVal) return '<=' + maxVal;

        if (minVal && maxVal) return minVal + '-' + maxVal
    };

    orderFormatter = (col, row) => {
        return (
            <span>
                <button
                    className={`btn resizable-button tooltip-button btn-default margin-right-5`}
                    style={{ padding: '3px 8px' }}
                    onClick={this.moveTier(-1, row)}
                    disabled={row.tierNumber === 1}
                >
                    <i className='fa fa-arrow-up' style={{ color: '#555' }}/>
                </button>
                <button
                    className={`btn resizable-button tooltip-button btn-default`}
                    style={{ padding: '3px 8px' }}
                    onClick={this.moveTier(1, row)}
                    disabled={row.tierNumber === this.props.billingTiers.length}
                >
                    <i className='fa fa-arrow-down' style={{ color: '#555' }}/>
                </button>
            </span>
        )
    };

    enumFormatter = (cell, row, enumObject) => {
        return (<span>{enumObject[ cell ]}</span>)
    };

    render() {
        const { isEditable, billingTiers } = this.props;
        const { pagination, sorting } = this.props.gridConfiguration;
        const { name, active, currency } = this.props.profile;

        const columns = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true
            }, {
                'title': 'Tier ID',
                'name': 'tierNumber',
                'sort': true,
                'width': '100',
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'caseTypesAsString',
                'title': 'Case Types',
                'sort': true,
                'dataAlign': 'left',
                'dataFormat': this.caseTypesFormatter,
                'columnTitle': (title) => {
                    return title
                },
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'caseLevel',
                'title': 'Case Level',
                'sort': true,
                'dataAlign': 'left',
                'dataFormat': this.caseLevelFormatter,
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'reviewerTypesAsString',
                'title': 'Reviewer Type',
                'sort': true,
                'dataAlign': 'left',
                'dataFormat': this.reviewerTypesFormatter,
                'columnTitle': (title) => {
                    return title
                },
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'callsMin',
                'title': 'Calls',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'dataFormat': this.numbersFormatter('calls'),
                'filterFormatted': true,
            }, {
                'name': 'tatMin',
                'title': 'TAT',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'dataFormat': this.numbersFormatter('tat'),
                'filterFormatted': true,
            }, {
                'name': 'pagesMin',
                'title': 'Pages',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'dataFormat': this.numbersFormatter('pages'),
                'filterFormatted': true,
            }, {
                'name': 'questionsMin',
                'title': 'Question',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'dataFormat': this.numbersFormatter('questions'),
                'filterFormatted': true,
                'tdStyle': { borderRightColor: '#000' },
                'thStyle': { borderRightColor: '#000' }
            }, {
                'name': 'rateType',
                'title': 'F/H/A',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'dataFormat': this.rateTypeFormatter,
                'filterFormatted': true,
            }, {
                'name': 'rate',
                'title': 'Rate',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'filterFormatted': true
            }, {
                'name': 'adjust',
                'title': 'Adjust',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'filterFormatted': true,
            }, {
                'name': 'refTier',
                'title': 'Ref Tier',
                'sort': true,
                'dataAlign': 'left',
                'dataFormat': this.refTierFormatter,
                'filter': { type: 'TextFilter', delay: 500 },
                'filterFormatted': true,
            }, {
                'name': 'timeMin',
                'title': 'Min Time',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'filterFormatted': true,
            }, {
                'name': 'chargeMin',
                'title': 'Min Charge',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'filterFormatted': true,
            }, {
                'name': 'active',
                'title': 'Status',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': 'Inactive',
                    'true': 'Active'
                },
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': 'Inactive',
                        'true': 'Active'
                    }
                }
            }
        ];
        if (isEditable) {
            columns.splice(1, 0, {
                'name': 'code',
                'title': 'Order',
                'dataFormat': this.orderFormatter,
            });
        }
        return (
            <div className="container-fluid table-responsive">
                <RemoteGrid
                    color="blue"
                    entity="billing_group_tiers"
                    title={name}
                    data={billingTiers}
                    total={billingTiers.length}
                    columns={columns}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.handleOpenEdit}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                >
                    <div>
                        <div className="row">
                            <FormField
                                md="4"
                                label="Name"
                                id="Name"
                                className="form-control"
                                name="name"
                                required
                                value={name}
                                onChange={this.onChange}
                                valids="required|unique"
                                validator={this.props.validator}
                                onBlur={this.props.dispatchValidState}
                                disabled={!isEditable}
                            />
                            <FormField
                                type="REF_CURRENCY"
                                md="3"
                                label="Currency"
                                id="Currency"
                                value={currency}
                                onChange={this.onChangeCurrency}
                                validator={this.props.validator}
                                valids='required'
                                onBlur={this.props.dispatchValidState}
                                disabled={!isEditable}
                            />
                            <FormField
                                md="2"
                                type="checkbox"
                                title="Status"
                                label="Active"
                                name='active'
                                value={active}
                                checked={active}
                                onChange={this.onChangeCheckBox}
                                style={{ marginTop: '20px' }}
                                className="checkbox"
                                disabled={!isEditable}
                            />
                        </div>
                        <div style={{ fontSize: '1.2em', fontWeight: 'bold', marginTop: '30px', marginBottom: '-10px' }}>
                            Tiers
                        </div>
                    </div>
                </RemoteGrid>
            </div>
        );
    }

    static propTypes = {
        isEditable: PropTypes.bool,
        billingTiers: PropTypes.array,
        gridConfiguration: PropTypes.object,
        profile: PropTypes.object,
        validator: PropTypes.object,
        history: PropTypes.object,
        dispatchValidState: PropTypes.func,
        editField: PropTypes.func,
        getAllReviewerTypes: PropTypes.func,
        getAllCaseTypes: PropTypes.func,
        getAll: PropTypes.func,
        updateTierNumber: PropTypes.func,
    }
}

const mapStateToProps = (state) => {
    let reviewerTypes = state.app.reviewerTypes.data;
    let caseTypes = state.app.case_types.types;
    let editedBillingGroup = state.app.billing_group.editedBillingGroup;

    const billingTiers = editedBillingGroup.billingTiers.map((tier) => {
        let rate = tier.rateType === 'HOURLY' ? tier.hourlyRate : tier.flatRate;
        if (!rate) rate = 0;
        return {
            ...tier,
            rate: rate,
            caseLevel: tier.caseLevel || 'Any',
            reviewerTypesAsString: getNamesFromCodes(tier.reviewerTypes, reviewerTypes) || 'Any',
            caseTypesAsString: getNamesFromCodes(tier.caseTypes, caseTypes) || 'Any',
            adjust: tier.rateType === 'HOURLY' ? tier.adjust : '',
            refTier: tier.rateType === 'HOURLY' ? tier.refTier : '',
            timeMin: tier.rateType === 'HOURLY' ? tier.timeMin : '',
            chargeMin: tier.rateType === 'HOURLY' ? tier.chargeMin : ''
        }
    });

    return {
        isEditable: state.app.users.my_permissions.includes('MODIFY_BILLING_TIERS'),
        profile: editedBillingGroup,
        billingTiers,
        gridConfiguration: state.app.grid.billingGroupTiers,
        caseLevels: state.app.case_types.levels,
        caseTypes,
        reviewerTypes,
        currencies: state.app.currencies.data
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        editField: (field, value) => dispatch(entityEditField(field, value, 'billing_group')),
        getAllReviewerTypes: () => dispatch(getAllTypes()),
        getAllCaseTypes: (entity) => dispatch(getAllCaseTypes(entity)),
        getAll: (entity) => dispatch(getAll(entity)),
        updateTierNumber: (field, value) => dispatch(updateTierNumber(field, value, 'billing_group')),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBillingGroup);
