import { connect } from 'react-redux'
import { getAllFields } from '../../redux/actions/fieldsActions'
import RefField from './RefField'
import {getOne4Select, setLoadedCount} from "../../redux/actions/BasicEntityActions";

const mapStateToProps = (state) => ({
    isLoading:  state.app.fields.loading,
    loadedCount: state.app.fields.loadedCount,
    options: state.app.fields.data
        .filter((item) => item.active)
        .map(item => ({
            value: item.code,
            // label: item.displayName ? convertStyledText(item.displayName['EN']) : item.displayName,
            label: item.code,
        })),
})

const mapDispatchToProps = (dispatch) => ({
    getOptions: () => dispatch(getAllFields()),
    getOne4Select: (code) => dispatch(getOne4Select(code, 'field')),
    setLoadedCount: (count) => dispatch(setLoadedCount(count, 'field'))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefField)
