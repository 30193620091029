import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateApplyFilter } from '../../../../redux/actions/cases/caseAssignActions';
import Spinner from '../../../Develop/Spinner';

class ApplyFilterButton extends React.Component {

    static propTypes = {
        loading: PropTypes.bool,
        editedCaseCode: PropTypes.string,
        currentFilters: PropTypes.object,
        updateApplyFilter: PropTypes.func,
    }

    render() {
        const { loading, editedCaseCode, currentFilters, updateApplyFilter } = this.props;
        return <button
            color='blue'
            className="btn btn-primary margin-left-5"
            style={{ width: '100px', display: 'inline-flex', justifyContent: 'center' }}
            disabled={loading}
            onClick={
                (e) => {
                    e.preventDefault();
                    updateApplyFilter(editedCaseCode, currentFilters);
                }
            }
        >
            <span>{'Apply Filter'}</span>&nbsp;{loading && <Spinner />}
        </button>
    }
}

ApplyFilterButton.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    // console.log('mapStateToProps', state.app);
    return {
        loading: state.app.case_assign.loading,
        editedCaseCode: state.app.cases.editedCase.code,
        currentFilters: state.app.case_assign.currentFilters,
    };
}

const mapDispatchToProps = dispatch => ({
    updateApplyFilter: (caseCode, filter) => dispatch(updateApplyFilter(caseCode, filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyFilterButton)
