import React from 'react';
import PropTypes from 'prop-types';
import indicator from '../images/loading-indicator.svg';

export default function LoadingIndicator(props) {
    const type = props.type ? props.type : 'default';
    const imgHeight = props.height ? props.height + 'px' : '32px';
    let imgStyle = { height: imgHeight };
    if (type === 'tabs') {
        imgStyle = { position: 'absolute', height: '32px', right: '5px', top: '13px' };
    }
    let wrapper = styles.wrapper[type];
    wrapper = {
        ...wrapper,
        top: props.top ? props.top : wrapper.top,
        right: props.right ? props.right : wrapper.right,
    };
    return (
        <div style={wrapper}>
            <img alt={props.alt || 'Loading'} src={indicator} style={imgStyle} />
        </div>
    );
}

LoadingIndicator.propTypes = {
    type: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string,
};

const styles = {
    wrapper: {
        static: {
            position: 'static',
            zIndex: 1999,
        },
        datatable: {
            position: 'absolute',
            top: '4px',
            right: '4px',
            zIndex: 1999,
        },
        default: {
            position: 'absolute',
            top: '35%',
            left: '50%',
            zIndex: 1999,
        },
        lister: {
            position: 'absolute',
            top: '14px',
            right: '14px',
            left: 'auto',
            zIndex: 1999,
        },
        tabs: {
            position: 'sticky',
            top: '50px',
            zIndex: 1999,
        },
        form: {
            position: 'absolute',
            top: '5px',
            right: '5px',
            left: 'auto',
            zIndex: 1999,
        },
        button: {
            position: 'relative',
            top: '-25px',
            zIndex: 1999,
        },
        caseSubmit: {
            height: '34px',
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '34px',
            marginLeft: '5px',
            zIndex: '1999',
        },
    },
};
