import React from 'react';
import Modal from 'react-modal';
import { isFilePasswordCorrect } from './Case/Attachments/attachmentUtils';

class FilePasswordModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filePassword: '',
            isOpen: props.isOpen,
            isError: false
        };
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
    }

    handlePasswordChange(event) {
        this.setState({ filePassword: event.target.value, isError: false });
    }

    async handleSubmitPassword() {
        const { onSubmit } = this.props;
        const { filePassword } = this.state;
        filePassword.trim();
        if (await isFilePasswordCorrect(filePassword)) {
            onSubmit(filePassword);
        } else {
            this.setState({isError: true});
        }
    }

    render() {
        const { filename } = this.props;
        const { filePassword, isError, isOpen } = this.state;
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={this.handleCloseFilePasswordModal}
                className="custom-modal modal-dialog"
                overlayClassName="modal-overlay"
            >
                <h3 className="modal-title text-center">Password-protected Document</h3>
                <div className='modal-content custom-modal-content text-center'>
                    <h4>
                        You are uploading a password protected document.<br/>
                        To be able to process the documents for the Reviewers<br/>
                        and create the merged document, you need to provide a password.<br/>
                    </h4>
                    <h4>Please enter the password for the following document: <strong>{filename}</strong></h4>
                    <input
                        style={{width: '40%'}}
                        type="password"
                        value={filePassword}
                        onChange={this.handlePasswordChange} />
                    {isError && 
                        <div>
                            <small className="text-danger">Incorrect password!</small>
                        </div>
                    }
                    <div>
                        <button
                            className='btn btn-grey margin-right-5 margin-top-5'
                            onClick={this.handleSubmitPassword}>
                            Submit
                        </button>
                        <button
                            className='btn btn-grey margin-left-5 margin-top-5'
                            onClick={this.props.onCancel}>
                            Cancel
                        </button>
                    </div>
                    <div>
                        <small>(if you are uploading more than one document, you might see this dialog multiple times)</small>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default FilePasswordModal;
