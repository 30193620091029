import React from 'react';
import ReactDOM from 'react-dom';
import SubmitButton from './SubmitButton';

const UpdateButtons = ({
  onSave,
  isFormChanged,
  isFormValid,
  color = 'green',
}) => {
  const container = document.getElementById('SaveButtonContainer');

  if (!container) return null;

  return ReactDOM.createPortal(
    <React.Fragment>
      <SubmitButton
        title="Save"
        color={color}
        disabled={!isFormChanged || !isFormValid}
        onSubmit={() => onSave(false)}
      />
      <SubmitButton
        title="Save And Close"
        color={color}
        disabled={!isFormChanged || !isFormValid}
        onSubmit={() => onSave(true)}
      />
    </React.Fragment>,
    container,
  );
};

export default UpdateButtons;
