import createLinkingConnectedDataTableSlice from '../../utils/createLinkingConnectedDataTableSlice';

const clientEditFacilitySlice = createLinkingConnectedDataTableSlice(
    'clientEditFacility',
    'name'
);

export const {
    loadData,
    linkingEntities, unlinkingEntities, linkingEntitiesSuccess, linkingEntitiesError,
} = clientEditFacilitySlice.actions;

export const clientEditFacilitySliceReducer = clientEditFacilitySlice.reducer;

export default clientEditFacilitySlice;
