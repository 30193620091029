import React from 'react';
import PropTypes from 'prop-types'
import Modal from 'react-modal';
import {connect} from 'react-redux';
import {editUser, closeSubmitModal} from '../redux/action-creators/users'
import {getCase, getCaseFiles, setCaseMessage} from "../redux/actions/cases/caseActions";
import {moveCase, transitionCase} from "../redux/actions/cases/caseListActions";
import menuActions from "../redux/action-creators/menu";
import {withRouter} from "react-router-dom";

class SubmitModal extends React.Component {

    constructor(props) {
        super(props);
        this.modalHeader = <h3 className="modal-title text-center">{props.title}</h3>;
        this.modalButtons = null;
        this.getModalHeader = this.getModalHeader.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
    }

    toggleActive() {
        if (this.props['editTarget'] === 'case') {
            if (this.props.moveToStage.indexOf('_') !== -1) {
                // SUBMIT TO
                if (this.props.moveToStage === 'CW_DISPATCH_TO_CW_REVIEW') {
                    const {assignmentDetails} = this.props;
                    if (!assignmentDetails || assignmentDetails.length === 0) {
                        this.props.closeSubmitToMenu();
                        this.props.handleCloseModal();

                        return this.props.setCaseMessage({
                            text: "For submitting a case you have to assign reviewer.",
                            type: 'error',
                            time: 5000
                        })
                    }
                }

                this.props.transitionCase({
                    caseCode: this.props.case.code,
                    transitionCode: this.props.moveToStage
                }).then(
                    () => {
                        this.props.handleCloseModal();
                        this.props.closeSubmitToMenu();
                        this.props.history.push(`/home`);
                    },
                    () => {
                        this.props.closeSubmitToMenu();
                    }
                );
            } else {
                // MOVE TO
                let stage_letter = this.props.case.currentStage.code.substr(0, 3);
                let stageCode = stage_letter + this.props.moveToStage.toUpperCase();
                if (stageCode !== '') {
                    this.props.moveCase({
                        caseCode: this.props.case.code,
                        stageCode: stageCode
                    }).then(
                        () => {
                            let caseCode = this.props.case.code;
                            this.props.getCase(caseCode)
                                .then(() => {
                                    console.log('Tick!');
                                    setTimeout(() => {
                                        console.log('Tack!');
                                        this.props.getCaseFiles(caseCode);
                                    }, 10000);
                                });
                            this.props.handleCloseModal();
                            this.props.closeSubmitToMenu();
                        },
                        () => {
                            this.props.closeSubmitToMenu();
                        }
                    );
                }
            }
        } else {
            this.props.updateUser({...this.props.user, active: !this.props.user.active})
        }
    }

    getModalHeader() {
        if (this.props['editTarget'] === 'case') {
            let stageName = this.context.translate('common.cases.case.' + this.props.moveToStage.substr(this.props.moveToStage.lastIndexOf('_') + 1).toLowerCase());
            return (<h4 className=''>Are you sure you want to submit the case to {stageName} stage ?</h4>)
        }
        return '';
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.handleCloseModal}
                className="custom-modal modal-dialog"
                overlayClassName="modal-overlay"
            >
                {this.modalHeader}
                <div className='modal-content custom-modal-content text-center'>
                    {this.getModalHeader()}
                    <div>
                        <button
                            className='btn btn-grey margin-right-5 margin-top-5'
                            disabled={this.props.isLoading}
                            onClick={this.toggleActive}>
                            Yes
                        </button>
                        <button
                            className='btn btn-grey margin-left-5 margin-top-5'
                            disabled={this.props.isLoading}
                            onClick={this.props.handleCloseModal}>
                            No
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

SubmitModal.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    moveToStage: state.app.cases.moveToStage,
    case: state.app.cases.editedCase,
    isOpen: state.app.users.submitModalOpen,
    isLoading: state.app.users.loading || state.app.companies.loading,
    user: state.app.users.users.find(usr => usr.code === ownProps.userId) || {},
    assignmentDetails: state.app.case_assign.appliedReviewers
        .filter((item) => item.tier !== 'NO_TIER')
});

const mapDispatchToProps = dispatch => ({
    updateUser: (data) => {
        dispatch(editUser(data))
    },
    handleCloseModal: () => {
        dispatch(closeSubmitModal())
    },
    closeSubmitToMenu: () => dispatch(menuActions.closeSubmitToMenu()),
    getCase: (code) => dispatch(getCase(code)),
    getCaseFiles: (code) => dispatch(getCaseFiles(code)),
    transitionCase: (data) => dispatch(transitionCase(data)),
    moveCase: (data) => dispatch(moveCase(data)),
    setCaseMessage: (data) => dispatch(setCaseMessage(data))
});

SubmitModal = withRouter(SubmitModal);
export default connect(mapStateToProps, mapDispatchToProps)(SubmitModal)
