import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import {logout} from "../../redux/actions/authActions";

class UserMenu extends Component {
    render() {
        // let currentUser = localStorage.getItem('current_user');
        // if (typeof currentUser == "undefined" || currentUser === "{}") {
        //     console.log('here');
        //     // this.props.logout();
        // }
        return (
            <li className="user user-menu">
                <Link to='/profile'>
                    <i className="fa fa-user"/>
                    <span className="hidden-xs">{this.props.username}</span>
                </Link>
            </li>
        )
    }
}

const mapState = (state) => {
    return {
        username: state.app.auth.user.firstName + ' ' + state.app.auth.user.lastName
    }
};

const mapActions = dispatch => {
    return {
        logout: Logout => dispatch(logout(Logout))
    }
};

export default connect(mapState, mapActions)(UserMenu);
