import { isObjectsEqual } from '../../../utils/helpers'

const basePhysician = {
    firstName: '',
    middleName: '',
    lastName: '',
    title: '',
    physicianCode: '',
    email: '',
    phoneNumber: '',
    address1: '',
    address2: '',
    city: '',
    state: null,
    countryCode: null,
    postalCode: '',
    active: true,
    specialtyCodes: [],
    bodyPartCodes: [],
    selectedFacilityCodes: [],
    private_facility: false
}
const defaultState = {
    loading: false,
    loadedCount: 0,
    editPhysicianSuccess: false,
    data: [],
    error: '',
    editedPhysician: basePhysician,
    originalPhysician: basePhysician,
    currentTab: 'general',
    physicianValid: undefined,
    isProfileChanged: false,
    showValidMessages: false,
    lastLoadedTime: 0,
}

const physician = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_PHYSICIAN_LOADING':
            return { ...state, loading: true, editPhysicianSuccess: false, isProfileChanged: false, showValidMessages: false, }
        case 'FETCH_PHYSICIAN_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            })
        case 'FETCH_CREATE_PHYSICIAN_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editPhysicianSuccess: true,
                data: [...state.data, action.payload]
            })

        case 'FETCH_GET_PHYSICIAN_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                physicianValid: true,
                editedPhysician: action.payload ? {
                    ...action.payload
                } : defaultState.editedPhysician,
                originalPhysician: action.payload ? {
                    ...action.payload
                } : defaultState.editedPhysician,
                selectedFacilityCodes: action.payload.facilityCodes,
            }

        case 'SET_DEFAULT_PHYSICIAN':
            return {
                ...state,
                editedPhysician: defaultState.editedPhysician,
                originalPhysician: defaultState.editedPhysician,
            }

        case 'FETCH_EDIT_PHYSICIAN_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editPhysicianSuccess: true,
                originalPhysician: action.payload,
                data: state.data.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            })
        case 'PHYSICIAN_EDIT_FIELD':
            return Object.assign({}, state, {
                editedPhysician: Object.assign({}, state.editedPhysician, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedPhysician, action.payload), state.originalPhysician)
            })

        case 'SET_PHYSICIAN_CURRENT_TAB':
            return Object.assign({}, state, {
                currentTab: action.payload
            })
        case 'VALIDATE_PHYSICIAN_FIELD':
            return Object.assign({}, state, {
                physicianValid: action.payload
            })
        case 'FETCH_PHYSICIAN_ERROR':
            return { ...state, error: action.payload, loading: false }

        case 'SHOW_PHYSICIAN_VALIDATION':
            return Object.assign({}, state, {
                showValidMessages: true
            })

        case 'FETCH_PHYSICIAN_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            }
        case 'FILL_PHYSICIAN_LOCAL_STORAGE': {
            let items = {};
            for (let i = 0; i < state.data.length; i++) {
                items[ state.data[ i ].code ] =
                    (state.data[ i ].firstName ? state.data[ i ].firstName : '')
                    + ' ' +
                    (state.data[ i ].lastName ? state.data[ i ].lastName : '');
            }
            localStorage.setItem('list_physician', JSON.stringify(items));
            return state;
        }
        case 'FETCH_PHYSICIAN_LOADED_COUNT':
            return { ...state, loadedCount: action.payload }

        default:
            return state
    }
}

export default physician
