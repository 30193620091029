import PropTypes from 'prop-types';
import React from 'react';
import SimpleReactValidator from 'utils/simple-react-validator';
import {connect} from 'react-redux';
import {getTemplates} from "../../../redux/actions/templateActions";
import FormField from '../../../components/FormField/index';
import {closeCreateModal} from '../../../redux/action-creators/users';
import {getFieldInstance} from '../../../redux/actions/templateActions';

class AddFromLibrary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayName: {EN: '', SV: ''},
            field: '',
            fieldInstancesDto: [],
            language: {label: 'English', value: 'EN'},
            template: '',
        };

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        this.props.getTemplates();
    }

    onChangeCheckBox = (event) => {
        this.setState({[event.target.name]: event.target.checked});
    };

    onChangeField = (field) => {
        this.setState({field});
    };

    onChangeTemplate = (code) => {
        if (code) {
            const {templates} = this.props;

            const template = templates.find((item) => {
                return item.code === code.value;
            });

            this.setState({
                fieldInstancesDto: template.fieldInstanceCodes.map((item) => {
                    return {
                        label: item.replace(`_${template.code}`, ''),
                        value: item,
                    };
                }),
                template: code,
            });
        } else {
            this.setState({template: '', field: ''});
        }
    };

    onSubmit = (event) => {
        event.preventDefault();

        if (this.validator.allValid()) {
            const {value} = this.state.field;

            this.props.getFieldInstance(value).then(() => {
                this.props.history.push('/templates/field_instances/clone');
            });
        }
    };

    render() {
        return (
            <form className={'modal-content custom-modal-content'} onSubmit={this.onSubmit}>
                <div className={'form-group'}>
                    <div className={'row'}>
                        <FormField
                            className={'required-field text-bg'}
                            id={'user_edit_country'}
                            label={'Select Template'}
                            md={12}
                            onChange={this.onChangeTemplate}
                            options={this.props.templatesOptions}
                            type={'select'}
                            validator={this.validator}
                            valids={'required'}
                            value={this.state.template}
                        />
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className={'row'}>
                        <FormField
                            className={'required-field text-bg'}
                            label={'Select Field'}
                            md={12}
                            onChange={this.onChangeField}
                            options={this.state.fieldInstancesDto}
                            type={'select'}
                            validator={this.validator}
                            valids={'required'}
                            value={this.state.field}
                        />
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className={'row no-padding'}>
                        <div className={'col-xs-4 pull-right text-right'}>
                            <button className={'btn btn-flat btn-primary'}>Copy Field</button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

AddFromLibrary.contextTypes = {
    translate: PropTypes.func,
};

const mapStateToProps = (state) => {
    
    return {
        error: state.app.templates.error,
        isLoading: state.app.templates.loading,
        languageCodes: state.app.languages.data.map((item) => {
            return {
                label: item.name,
                value: item.code,
            };
        }),
        templates: state.app.templates.allTemplates,
        templatesOptions: state.app.templates.allTemplates
            .filter((item) => {
                return item.active && item.code !== state.app.templates.code && item.fieldInstanceCodes !== null;
            })
            .map((template) => {
                return {
                    label: template.name,
                    value: template.code,
                };
            }),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeCreateModal: () => {
            return dispatch(closeCreateModal());
        },
        getFieldInstance: (code) => {
            return dispatch(getFieldInstance(code));
        },
        getTemplates: () => dispatch(getTemplates()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddFromLibrary);
