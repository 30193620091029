import React, {Component} from "react";
import {connect} from 'react-redux'
import FormField from '../../components/FormField'
import {editPhysician, editField, createNewPhysician} from '../../redux/actions/physicianActions'
import {getMultiSelectValue, getSelectFieldValue} from '../../utils/helpers'
import PropTypes from "prop-types";
import shortId from "shortid";
import AddressInfoSection from "../../components/Sections/AddressInfoSection";
import BoxHeader from "../../components/BoxHeader";
import BoxBody from "../../components/BoxBody";
import Box from "../../components/Box";

class PhysicianGeneralInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.onChange = this.onChange.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeSpecialty = this.onChangeSpecialty.bind(this);
        this.onChangeLanguagesCode = this.onChangeLanguagesCode.bind(this);
        this.onChangeBodyParts = this.onChangeBodyParts.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.Submit = this.Submit.bind(this);
    }

    componentDidMount() {
        setTimeout(this.props.dispatchValidState, 500)

    }

    Submit(e) {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            this.props.newPhysician({
                ...this.props.physician,
                code: shortId.generate(),
                countryCode: getSelectFieldValue(this.props.physician.countryCode),
                stateCode: getSelectFieldValue(this.props.physician.stateCode),
                specialtyCodes: getMultiSelectValue(this.props.physician.specialtyCodes),
                bodyPartCodes: getMultiSelectValue(this.props.physician.bodyPartCodes),
                languageCodes: getMultiSelectValue(this.props.physician.languageCodes)
            });
        } else {
            // this.setState({messagesShown: true})
            this.props.showMessages()
        }
    }

    onChange(e) {
        this.setState({showSuccess: false});
        this.props.editField(e.target.name, e.target.value);


    }

    changeCountry(countryCode) {
        this.setState({showSuccess: false});
        this.props.editField('countryCode', countryCode);
        if (countryCode) {
            if (countryCode.value !== 'US') {
                this.props.editField('stateCode', null);
            }
        } else {
            this.props.editField('stateCode', null)
        }
    }

    onChangeState(stateCode) {
        this.setState({showSuccess: false});
        this.props.editField('stateCode', stateCode)

    }

    onChangeLanguagesCode(languageCodes) {
        this.setState({showSuccess: false});
        this.props.editField('languageCodes', languageCodes)


    }

    onChangeSpecialty(specialtyCodes) {
        this.setState({showSuccess: false});
        this.props.editField('specialtyCodes', specialtyCodes)

    }

    onChangeBodyParts(bodyPartCodes) {
        this.setState({showSuccess: false});
        this.props.editField('bodyPartCodes', bodyPartCodes)

    }

    onChangeCheckBox(e) {
        this.props.editField(e.target.name, e.target.checked)

    }

    render() {
        const {isEditable} = this.props;
        return (
            <div>
                <Box color="green" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`Physician - ${this.props.physician.firstName} ${this.props.physician.lastName}`}
                               color="green"
                               icon="fa-list-alt" classes="box-profile-first-row-height-show"/>
                    <BoxBody>
                        <form onSubmit={this.Submit} noValidate autoComplete="off">
                            <div className="bordered form-group">
                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="12"
                                            type='label_field'
                                            label="Personal Information"
                                            className='margin-bottom-15'
                                            style={{fontSize: '1.1em'}}
                                        />
                                        <FormField
                                            md="4"
                                            label={this.context.translate('common.users.first_name')}
                                            id={this.context.translate('common.users.first_name')}
                                            className="form-control"
                                            required
                                            valids="required|alpha_dash_space"
                                            validator={this.props.validator}
                                            name="firstName"
                                            value={this.props.physician.firstName}
                                            onChange={this.onChange}
                                            onBlur={this.props.dispatchValidState}
                                            disabled={!isEditable}
                                        />

                                        <FormField
                                            md="4"
                                            label={this.context.translate('common.users.middle_name')}
                                            id={this.context.translate('common.users.middle_name')}
                                            className="form-control"
                                            name="middleName"
                                            value={this.props.physician.middleName}
                                            onChange={this.onChange}
                                            valids="alpha_dash_space"
                                            validator={this.props.validator}
                                            onBlur={this.props.dispatchValidState}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="4"
                                            label={this.context.translate('common.users.last_name')}
                                            id={this.context.translate('common.users.last_name')}
                                            className="form-control"
                                            required
                                            valids="required|alpha_dash_space"
                                            validator={this.props.validator}
                                            name="lastName"
                                            value={this.props.physician.lastName}
                                            onChange={this.onChange}
                                            onBlur={this.props.dispatchValidState}
                                            disabled={!isEditable}
                                        />

                                        <FormField
                                            md="4"
                                            label={this.context.translate('common.users.title')}
                                            className="form-control"
                                            name="title"
                                            value={this.props.physician.title}
                                            onChange={this.onChange}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="4"
                                            label={this.context.translate('common.form.code')}
                                            className="form-control"
                                            name="physicianCode"
                                            value={this.props.physician.physicianCode}
                                            onChange={this.onChange}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group bordered">
                                <div className="form-group bordered">
                                    <div className="row">
                                        <FormField
                                            md="12"
                                            type='label_field'
                                            label="Contact Information"
                                            className='margin-bottom-15'
                                            style={{fontSize: '1.1em'}}
                                        />
                                        <FormField
                                            md="4"
                                            icon="envelope-o"
                                            type="input-icon"
                                            label={this.context.translate('common.form.email')}
                                            id={this.context.translate('common.form.email')}
                                            className="form-control"
                                            name="email"
                                            value={this.props.physician.email}
                                            onChange={this.onChange}
                                            valids="custom_email"
                                            validator={this.props.validator}
                                            onBlur={this.props.dispatchValidState}
                                            disabled={!isEditable}
                                        />
                                        <FormField
                                            md="4"
                                            type="input-icon"
                                            icon="phone"
                                            label={this.context.translate('common.form.phone_number')}
                                            id={this.context.translate('common.form.phone_number')}
                                            className="form-control"
                                            name="phoneNumber"
                                            value={this.props.physician.phoneNumber}
                                            onChange={this.onChange}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                            </div>

                            <AddressInfoSection
                                onChange={this.onChange}
                                onChangeCountry={this.changeCountry}
                                onChangeState={this.onChangeState}
                                address1={this.props.physician.address1}
                                address2={this.props.physician.address2}
                                city={this.props.physician.city}
                                countryCode={this.props.physician.countryCode}
                                stateCode={this.props.physician.stateCode}
                                postalCode={this.props.physician.postalCode}
                                noBorder
                                isEditable={isEditable}
                            />

                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        type='label_field'
                                        label="Other"
                                        style={{fontSize: '1.1em'}}
                                    />
                                    <FormField
                                        md="4"
                                        type="checkbox"
                                        title={this.context.translate('common.form.status')}
                                        label={this.context.translate('common.form.active')}
                                        name='active'
                                        value={this.props.physician.active}
                                        checked={this.props.physician.active}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        classBox="checkbox-padding"
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </form>
                    </BoxBody>
                </Box>

            </div>
        );
    }
}

PhysicianGeneralInfo.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        physician: state.app.physician.editedPhysician,
        error: state.app.physician.editGeneralError,
        isLoading: state.app.physician.loading,
        countries: state.app.countries.data
            .map(country => ({
                value: country.code,
                label: country.name
            })),
        languages: state.app.languages.data
            .filter((item) => item.active)
            .map(lang => ({
                value: lang.code,
                label: lang.name
            }))

    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveProfile: data => dispatch(editPhysician(data, 'admin')),
        newPhysician: data => dispatch(createNewPhysician(data)),
        editField: (field, value) => dispatch(editField(field, value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianGeneralInfo);
