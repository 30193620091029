import React from 'react';
import PropTypes from 'prop-types'
import Modal from 'react-modal';
import {connect} from 'react-redux';
import {editUser, closeDeleteModal} from '../redux/action-creators/users'
import {editCompany} from '../redux/actions/companiesActions'
import {editCaseType} from '../redux/actions/cases/caseTypesActions'
import {getCase, getCaseFiles, getCaseValues} from "../redux/actions/cases/caseActions";
import {moveCase, transitionCase} from "../redux/actions/cases/caseListActions";
import menuActions from "../redux/action-creators/menu";
import {getCaseAssignment} from "../redux/actions/cases/caseAssignActions";
import { fetchCaseCount } from 'redux/action-creators/init';

class DeleteModal extends React.Component {

    constructor(props) {
        super(props);
        this.modalHeader = <h3 className="modal-title text-center">{props.title}</h3>;
        this.modalButtons = null;
        this.getModalHeader = this.getModalHeader.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
        this.toggleActiveCompany = this.toggleActiveCompany.bind(this);
    }

    toggleActive() {
        switch (this.props['editTarget']) {
            case 'client':
                this.props.updateCompany({...this.props.company, active: !this.props.company.active});
                break;
            case 'case type':
                this.props.editCaseType({...this.props.entity, active: !this.props.entity.active});
                break;
            case 'case':
                if (this.props.moveToStage.indexOf('_') !== -1) {
                    // SUBMIT TO
                    this.props.transitionCase({
                        caseCode: this.props.case.code,
                        transitionCode: this.props.moveToStage
                    }).then(
                        () => {
                            this.props.getCase(this.props.case.code);
                            this.props.getCaseValues(this.props.case.code);
                            this.props.getCaseFiles(this.props.case.code);
                            this.props.handleCloseModal();
                            this.props.closeSubmitToMenu();
                        },
                        () => {
                            this.props.closeSubmitToMenu();
                        }
                    );
                } else {
                    // MOVE TO
                    let stageCode = '';
                    let stage_letter = this.props.case.currentStage.code.substr(0, 3);
                    stageCode = stage_letter + this.props.moveToStage.toUpperCase();
                    if (stageCode !== '') {
                        this.props.moveCase({
                            caseCode: this.props.case.code,
                            stageCode: stageCode
                        }).then(
                            () => {
                                let caseCode = this.props.case.code;
                                this.props.getCase(caseCode).then(() => {
                                    this.props.getCaseValues(caseCode);
                                    setTimeout(() => {
                                        this.props.getCaseFiles(caseCode);
                                    }, 5000);
                                });
                                console.log(stageCode);
                                if (stageCode.indexOf('REVIEW') !== -1 || stageCode.indexOf('DISPATCH') !== -1) {
                                    this.props.getCaseAssignment(this.props.case.code);
                                }
                                this.props.handleCloseModal();
                                this.props.closeSubmitToMenu();
                                this.props.fetchCaseCount();
                            },
                            () => {
                                this.props.closeSubmitToMenu();
                            }
                        );
                    }
                }
                break;
            default:
                this.props.updateUser({...this.props.user, active: !this.props.user.active})

        }
    }

    toggleActiveCompany() {
        this.props.updateCompany({...this.props.user, active: !this.props.user.active})
    }

    getModalHeader() {
        const {user, company, entity} = this.props;
        switch (this.props['editTarget']) {
            case 'staff':
                return (
                    <h4 className=''>Do you want to {user.active ? 'deactivate' : 'activate'} user ?</h4>);
            case 'client':
                return (<h4 className=''>Do you want to {company.active ? 'deactivate' : 'activate'} client
                    ?</h4>);
            case 'field':
                return (
                    <h4 className=''>Do you want to {entity.active ? 'deactivate' : 'activate'} field ?</h4>);
            case 'case type':
                return (<h4 className=''>Do you want to {entity.active ? 'deactivate' : 'activate'} case type
                    ?</h4>);
            case 'case':
                let stageName = this.context.translate('common.case.' + this.props.moveToStage.substr(this.props.moveToStage.lastIndexOf('_') + 1).toLowerCase());
                return (<h4 className=''>Are you sure you want to move the case to {stageName} stage ?</h4>);
            default:
        }
    }

    render() {
        let _this = this;
        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.handleCloseModal}
                className="custom-modal modal-dialog"
                overlayClassName="modal-overlay"
            >
                {_this.modalHeader}
                <div className='modal-content custom-modal-content text-center'>
                    {_this.getModalHeader()}
                    <div>
                        <button
                            className='btn btn-grey margin-right-5 margin-top-5'
                            disabled={this.props.isLoading}
                            onClick={this.toggleActive}>
                            Yes
                        </button>
                        <button
                            className='btn btn-grey margin-left-5 margin-top-5'
                            disabled={this.props.isLoading}
                            onClick={this.props.handleCloseModal}>
                            No
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

DeleteModal.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    moveToStage: state.app.cases.moveToStage,
    case: state.app.cases.editedCase,
    isOpen: state.app.users.deleteModalOpen,
    isLoading: state.app.users.loading || state.app.companies.loading,
    error: state.app.users.error,
    user: state.app.users.users.find(usr => usr.code === ownProps.userId) || {},
    company: state.app.companies.data.find(item => item.code === ownProps.userId) || {}
});

const mapDispatchToProps = dispatch => ({
    updateUser: (data) => {
        dispatch(editUser(data))
    },
    updateCompany: (data) => {
        dispatch(editCompany(data))
    },

    editCaseType: (data) => {
        dispatch(editCaseType(data))
    },
    handleCloseModal: () => {
        dispatch(closeDeleteModal())
    },
    closeSubmitToMenu: () => dispatch(menuActions.closeSubmitToMenu()),
    getCase: (code) => dispatch(getCase(code)),
    getCaseValues: (code) => dispatch(getCaseValues(code)),
    getCaseFiles: (code) => dispatch(getCaseFiles(code)),
    getCaseAssignment: (code) => dispatch(getCaseAssignment(code)),
    transitionCase: (data) => dispatch(transitionCase(data)),
    moveCase: (data) => dispatch(moveCase(data)),
    fetchCaseCount: () => dispatch(fetchCaseCount()),

});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal)
