const defaultState = {
	loading: false,
	error: null,
	options: [],
	selectedValues: null
}

export default (state = defaultState, action) => {
	switch (action.type) {
		case "SELECT_INPUT_LOADING":
			return Object.assign({}, state, {
				loading: true,
				options: []
			})
		default:
			return state
		case "SELECT_INPUT_SUCCESS":
			return Object.assign({}, state, {
				loading: false,
				options: action.data["hydra:member"] || []
			})
		case "SELECT_INPUT_ERROR":
			return Object.assign({}, state, {
				loading: false,
				error: action.error
			})
		case "SELECT_INPUT_VALUE_UPDATE":
			return Object.assign({}, state, {
				selectedValues: action.value
			})
	}
}