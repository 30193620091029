import { createApiParams } from 'components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import { catchError$ } from 'redux/epicUtils/commonEpics';
import draftSlice from './slices/draftSlice';
import availableSlice from './slices/availableSlice';
import allSlice from './slices/allSlice';
import closedLastMonthSlice from './slices/closedLastMonthSlice';
import closedSlice from './slices/closedSlice';
import dispatchSlice from './slices/dispatchSlice';
import qaSlice from './slices/qaSlice';
import reviewSlice from './slices/reviewSlice';
import acceptedSlice from './slices/acceptedSlice';
import flaggedSlice from './slices/flaggedSlice';
import completeSlice from './slices/completeSlice';
import inProcessSlice from './slices/inprocessSlice';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { from } from 'rxjs';
import api from 'utils/api';
import closedThisMonthSlice from './slices/closedThisMonthSlice';
import patientCaseTransform from './patientCaseTransform';
import axios from 'axios';

const SLICES = [
    acceptedSlice,
    allSlice,
    availableSlice,
    closedLastMonthSlice,
    closedSlice,
    closedThisMonthSlice,
    completeSlice,
    dispatchSlice,
    draftSlice,
    flaggedSlice,
    inProcessSlice,
    qaSlice,
    reviewSlice
];

const createPatientCaseListEpic$ = ({
                                        actions: { loadData, dataLoaded, dataLoadError, cancelLoad },
                                        name
                                    }, additionalParams, state) => (action$, state$) =>
    action$.pipe(
        filter(loadData.match),
        mergeMap(({ payload: params }) => {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            return from(
                api.get('case-minimal/page', {
                    params: createApiParams(params, additionalParams, state),
                    cancelToken: source.token,
                }),
            ).pipe(
                map((response) => response.data),
                takeUntil(
                    action$.pipe(
                        filter(cancelLoad.match),
                        tap(() => source.cancel(cancelLoad.toString() + 'canceled')),
                    ),
                ),
            );
        }),
        map(patientCaseTransform(state$.value)),
        map(dataLoaded),
        catchError(catchError$(dataLoadError)),
    );

const epicsFromSlices = [];
for (let i = 0; i < SLICES.length; i++) epicsFromSlices.push(createPatientCaseListEpic$(SLICES[i], {}, SLICES[i].name));

export default combineEpics(...epicsFromSlices);
