import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const DataTableWrapper = ({ style, wrapperStyle, children }) => (
  <div className="datatable__table-wrapper" style={wrapperStyle}>
    <table
      style={style}
      className={classnames([
        'table table-striped table-bordered table-hover datatable__table',
      ])}
    >
      {children}
    </table>
  </div>
);

DataTableWrapper.propTypes = {
  style: PropTypes.object,
  wrapperStyle: PropTypes.object,
  children: PropTypes.node,
};

DataTableWrapper.defaultProps = {
  style: {},
  wrapperStyle: {},
};

export default DataTableWrapper;
