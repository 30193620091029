import { createSlice } from '@reduxjs/toolkit';

const globalSearchSlice = createSlice({
        name: 'globalSearch',
        initialState: {
            search: '',
        },
        reducers: {
            updateSearch: (state, { payload }) => {
                state.search = payload;
            },
            resetSearch: (state) => {
                state.search = '';
            },
        },
    }
);

export const {
    updateSearch,
    resetSearch,
} = globalSearchSlice.actions;

export const globalSearchReducer = globalSearchSlice.reducer;

export default globalSearchSlice;
