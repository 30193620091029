import React from 'react';
import ColoredBox from 'components/ColoredBox/ColoredBox';
import PageContainer from 'components/PageContainer/PageContainer';
import ConnectedDataTable from '../../components/Datatable/ConnectedDataTable';
import notificationTemplateSlice from 'redux/pages/notification-template/notificationTemplateSlice';
import { Button } from 'react-bootstrap';

const columns = {
  templateName: {
    label: 'Notification Template Name',
    colStyle: { width: '30%' },
  },
  to: { label: 'To', colStyle: { width: '25%' } },
  cc: { label: 'Cc', colStyle: { width: '20%' } },
  caseTemplateName: { label: 'Case Template Name', colStyle: { width: '20%' } },
};

// eslint-disable-next-line react/prop-types
const NotificationTemplatePage = ({ history }) => {
  const handleRowClick = ({ id }) => {
    history.push(`notification_templates/update/${id}`);
  };

  const handleSend = (id) => {
    alert(id);
  };

  return (
    <PageContainer>
      <ColoredBox variant="success" title="Notification Templates">
        <ConnectedDataTable
          columns={columns}
          onRowClick={handleRowClick}
          stateKey="notificationTemplates"
          actions={notificationTemplateSlice.actions}
          customActions={(entity) => {
            return (
              <Button
                id={entity.code}
                onClick={(event) => {
                  event.stopPropagation();
                  handleSend(entity.code);
                }}
                disabled={entity.isActive}
              >
                Send
              </Button>
            );
          }}
          variant="success"
        />
      </ColoredBox>
    </PageContainer>
  );
};

export default NotificationTemplatePage;
