import React from 'react';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import SubmitButton from '../../../components/SubmitButton'
import {createNewField, dispatchValidStatus} from '../../../redux/actions/fieldsActions'
import convertFieldData from '../../../utils/fields'


class CreateFieldButton extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e) {
        e.preventDefault();
        const {field, dispatchValidStatus} = this.props;

        if (this.props.fieldValid) {
            dispatchValidStatus(true);
            const data = convertFieldData(field);
            this.props.createField(data)
                .then(() => this.props.history.push('/fields'))
        } else dispatchValidStatus(false)

    }

    render() {
        return <SubmitButton title='Add Field' color='blue' onSubmit={this.onSave}
                             showTooltip={!this.props.fieldValid}/>
    }
}

CreateFieldButton.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    field: state.app.fields.editedField,
    fieldValid: state.app.fields.fieldValid,
});

const mapDispatchToProps = dispatch => ({
    createField: data => dispatch(createNewField(data)),
    dispatchValidStatus: data => dispatch(dispatchValidStatus(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateFieldButton))
