import React from 'react';
import { connect } from 'react-redux'
import { Tab, Tabs } from 'react-bootstrap';
import LoadingIndicator from '../../components/LoadingIndicator';
import ClientEditGeneralInfo from './ClientEditGeneralInfo'
import ClientEditAdminInfo from './ClientEditAdminInfo'
import ClientEditBillingInfo from './ClientEditBillingInfo'
import ClientSubmittersInfo from './ClientSubmittersInfo'
import ClientEditLogo from './ClientEditLogo'
import ClientPricingTiers from './ClientPricingTiers'
import ClientEditTemplateInfo from './ClientEditTemplateInfo'
import ClientSettings from './ClientSettings'
import { getActiveCountries } from '../../redux/actions/countryActions'
import { getAllCurrencies } from '../../redux/actions/currencyActions'
import { getLanguages } from '../../redux/actions/languageActions'
import { getAllLocales } from '../../redux/actions/localeActions'
import { getAllBillingCodes, getCompanyBillingTiers } from '../../redux/actions/billingActions'
import { getTemplates } from '../../redux/actions/templateActions'
import { getAllCaseFileTemplates } from '../../redux/actions/cases/caseFileTemplateActions'
import { editCompany, getCompanyProfile, isAllValid, saveLogo, setCurrentTab } from '../../redux/actions/companiesActions'
import { closeEditModal, getAllRoles } from '../../redux/action-creators/users'
import SimpleReactValidator from "../../utils/simple-react-validator";
import { getMultiSelectValue, isUser, testIp } from '../../utils/helpers'
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';
import { getAll } from "../../redux/actions/BasicEntityActions";
import SystemMessage from '../../components/SystemMessage';
import { getAllTypes } from "../../redux/actions/reviewerTypesAction";
import { getAllCaseTypes } from "../../redux/actions/cases/caseTypesActions";
import PropTypes from "prop-types";
import ClientEditPhysiciansNew from "./ClientEditPhysiciansNew";
import ClientEditFacilitiesNew from "./ClientEditFacilitiesNew";
import ClientEditMedicalServices from "./ClientEditMedicalServices";
import { getAllSpecialties } from "../../redux/actions/specialtyActions";
import { getAllFacilities, getAllFacilityTypes } from "../../redux/actions/facilitiesActions";
import './ClientPage.css'

// const styles = {
//     wrapper: {
//         width: '100%',
//         height: '100%',
//         textAlign: 'center',
//         display: 'flex',
//         justifyContent: 'center',
//         position: 'absolute',
//         zIndex: '999',
//         top: '0',
//         backgroundColor: 'rgba(0,0,0,.1)'
//     }
// };

const tabToField = {
    'Full Name': 'general',
    'Short Name': 'general',
    'Default Template': 'template',
    'Case File Template': 'template',
    'IP White List': 'administration',
    'Case File': 'administration',
    'Roles Allowed To Reopen': 'administration',
    'Reopen Days': 'administration',
    'Accounting Email': 'billing',
    'Hourly Margin': 'billing',
    'Currency': 'billing',
    'Billing Group': 'pricing_tiers',
    'Coding Systems': 'mcSystem'
};

class ClientEditPage extends React.Component {

    static propTypes = {
        isLoading: PropTypes.bool,
        isEditable: PropTypes.bool,
    }
    static defaultProps = {
        isLoading: false,
    };

    constructor(props) {
        super(props);

        let this_props = this.props;
        this.validator = new SimpleReactValidator({
            custom_email:
                {
                    message: 'The Notification email must be a valid email address',
                    rule: (val) => val === '' ||
                        // TODO: check this
                        // eslint-disable-next-line no-control-regex,max-len
                        (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/igm).test(val)
                },
            unique: {
                message: 'This name already exists in the system.',
                rule: function (val) {
                    let client_names = [];
                    this_props.clients.forEach((item) => {
                        if (item.code !== this_props.match.params.id) {
                            client_names.push(item.fullName);
                        }
                    });
                    return !client_names.includes(val);
                },
            },
            ip_list: {
                message: 'Invalid ip address.',
                rule: function (value) {
                    const ipList = getMultiSelectValue(value);
                    return testIp(ipList);
                }
            },
            case_file: {
                message: 'Case File is required',
                rule: this.isCaseFileChecked
            },
            reopen_days: {
                message: 'The Reopen Days field is required',
                rule: this.isAllowReopenChecked
            }
        });
        this.validator.showMessages();

        this.state = {
            errorMessages: Object.keys(this.validator.errorMessages).map((field) => ({
                id: field,
                field,
                message: this.validator.errorMessages[ field ],
                tab: tabToField[ field ]
            }))
        }
    }

    componentDidMount() {
        let companyId = this.props.match.params.id;
        if (!companyId) {
            companyId = this.props[ 'companyId' ];
        }
        this.props.getActiveCountries(this.props.countries);
        this.props.getAllCurrencies(this.props.currencies);
        this.props.getLanguages(this.props.languages);
        this.props.getAllBillingCodes(this.props.billing);
        this.props.getAllRoles(this.props.users); //after testing change it to get user profile
        this.props.getTemplates(this.props.templates);
        this.props.getAllCaseFileTemplates(this.props.caseFileTemplates);
        this.props.getAllTimeZones(this.props.timezone);
        this.props.getAllCaseTypes(this.props.caseTypes);
        this.props.getAllReviewerTypes(this.props.reviewerTypes);
        this.props.getCompanyProfile(companyId).then(() => {
            !isUser(this.props.currentUserRole) && this.props.getCompanyBillingTiers();
            this.dispatchValidStatus()
        });
        this.props.setCurrentTab('general');

        // Facilities
        this.props.getAllFacilities(this.props.facilities);
        this.props.getAllFacilityTypes(this.props.facilities);

        // MedicalCodingSystem
        this.props.getAllProcedures(this.props.procedures);
        this.props.getAllDiagnoses(this.props.diagnoses);
        this.props.getAllScSystem(this.props.scSystem);
        this.props.getBodyParts(this.props.bodyParts);
        this.props.getAllSpecialties(this.props.specialties);
        // this.props.getAllMsNotes(this.props.company.code);
        // this.props.getAllMdNotes(this.props.company.code);
        this.props.closeEditModal();
    }

    handleSelect = (key) => {
        this.props.setCurrentTab(key)
    };

    isCaseFileChecked = () => this.props.profile[ 'pdfAvailable' ] || this.props.profile[ 'wordAvailable' ];

    isAllowReopenChecked = () => (this.props.profile[ 'allowReopens' ] && this.props.profile[ 'reopenDays' ] !== ''
        && typeof this.props.profile[ 'reopenDays' ] !== 'undefined' && this.props.profile[ 'reopenDays' ] != null)
        || !this.props.profile[ 'allowReopens' ];

    onSave = () => {
        const { savedLogo } = this.props.profile;
        if (savedLogo && savedLogo.length) {
            this.props.saveLogo({
                code: this.props.editedCompany.code,
                file: this.props.editedCompany.savedLogo
            })
        }

        return this.props.saveProfile(this.props.profile)
    };

    dispatchValidStatus = () => {
        this.props.isAllValid(this.validator.allValid());
        this.setState({
            errorMessages: Object.keys(this.validator.errorMessages)
                .map((field) => ({
                    id: field,
                    field,
                    message: this.validator.errorMessages[ field ],
                    tab: tabToField[ field ]
                }))
        })
    };

    render() {
        const { match, isProfileChanged, editedSuccess, validationError, error, isLoading, isEditable } = this.props;
        const { errorMessages } = this.state;
        // console.log(isUser(this.props.currentUserRole))
        return (
            <section className="content user-profile-content row">
                <PreventTransitionPrompt
                    when={isProfileChanged}
                    message='MyCustomDialogComponent'
                    entity='company'
                    isFormValid={this.props.isCompanyValid}
                    saveChanges={this.onSave}
                    cancelAndDiscard={() => this.props.getCompanyProfile(this.props.profile.code)}
                />
                <div className='col-md-12'>
                    {isLoading && <LoadingIndicator type="tabs" />}
                    <Tabs defaultActiveKey={'general'} activeKey={this.props.currentTab} animation={true}
                          id="noanim-tab-example" onSelect={this.handleSelect}>
                        <SystemMessage
                            shown={editedSuccess && !isProfileChanged && !validationError}
                            type='success'
                            message='Client successfully updated'
                            top='7em'
                        />
                        <SystemMessage
                            shown={validationError}
                            type='error' message={error}
                            validationMessages={errorMessages}
                            goToTab={this.handleSelect}
                            top='7em'
                        />
                        <Tab eventKey={'general'} title="General">
                            <div className="row">
                                <div className="col-md-9">
                                    <ClientEditGeneralInfo
                                        match={match} validator={this.validator}
                                        dispatchValidStatus={this.dispatchValidStatus}
                                        isEditable={isEditable}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <ClientEditLogo match={match} isEditable={isEditable} />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey={'administration'} title="Administration" tabClassName={isUser(this.props.currentUserRole) ? 'd-none' : ''}>
                            <ClientEditAdminInfo
                                match={match} validator={this.validator}
                                dispatchValidStatus={this.dispatchValidStatus}
                                isEditable={isEditable}
                            />
                        </Tab>
                        <Tab eventKey={'template'} title="Template" tabClassName={isUser(this.props.currentUserRole) ? 'd-none' : ''}>
                            <ClientEditTemplateInfo
                                match={match} validator={this.validator}
                                dispatchValidStatus={this.dispatchValidStatus}
                                isEditable={isEditable}
                            />
                        </Tab>
                        <Tab eventKey={5} title="Submitters" style={{display: isUser(this.props.currentUserRole) ? 'none' : 'none'}}>
                            <div className="row">
                                <ClientSubmittersInfo match={match} />
                            </div>
                        </Tab>
                        <Tab eventKey={'billing'} title="Billing" tabClassName={isUser(this.props.currentUserRole) ? 'd-none' : ''}>
                            <ClientEditBillingInfo
                                match={match} validator={this.validator}
                                dispatchValidStatus={this.dispatchValidStatus}
                                isEditable={isEditable}
                            />
                        </Tab>
                        <Tab eventKey={'pricing_tiers'} title="Pricing Tiers"
                             dispatchValidStatus={this.dispatchValidStatus}
                             tabClassName={isUser(this.props.currentUserRole) ? 'd-none' : ''}
                             >
                            <div className="row">
                                <div className="col-md-12">
                                    <ClientPricingTiers
                                        match={match} validator={this.validator}
                                        dispatchValidStatus={this.dispatchValidStatus}
                                        isEditable={isEditable}
                                    />
                                </div>
                            </div>
                        </Tab>
                        {/*<Tab eventKey={'mcSystem'} title="Medical Services">*/}
                        {/*    <div className="row">*/}
                        {/*        <div className="col-md-12">*/}
                        {/*            <ClientMedicalCodingSystem*/}
                        {/*                match={match} validator={this.validator}*/}
                        {/*                dispatchValidStatus={this.dispatchValidStatus}*/}
                        {/*                isEditable={isEditable}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Tab>*/}
                        <Tab eventKey={'mcSystem'} title="Medical Services">
                            <div className="row">
                                <div className="col-md-12">
                                    <ClientEditMedicalServices
                                        validator={this.validator}
                                        dispatchValidStatus={this.dispatchValidStatus}
                                        isEditable={isEditable}
                                    />
                                </div>
                            </div>
                        </Tab>
                        {/*<Tab eventKey={'facilities'} title="Facilities">*/}
                        {/*    <div className="row">*/}
                        {/*        <div className="col-md-12">*/}
                        {/*            <ClientEditFacilities*/}
                        {/*                match={match} validator={this.validator}*/}
                        {/*                isEditable={isEditable}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Tab>*/}
                        <Tab eventKey={'facilities'} title="Facilities">
                            <div className="row">
                                <div className="col-md-12">
                                    <ClientEditFacilitiesNew isEditable={this.props.isFacilityAndPhysicianEditable}/>
                                </div>
                            </div>
                        </Tab>
                        {/*<Tab eventKey={'physicians'} title="Physicians">*/}
                        {/*    <div className="row">*/}
                        {/*        <div className="col-md-12">*/}
                        {/*            <ClientEditPhysicians*/}
                        {/*                match={match} validator={this.validator}*/}
                        {/*                isEditable={isEditable}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Tab>*/}
                        <Tab eventKey={'physicians'} title="Physicians">
                            <div className="row">
                                <div className="col-md-12">
                                    <ClientEditPhysiciansNew isEditable={this.props.isFacilityAndPhysicianEditable}/>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey={'settings'} title="Settings" tabClassName={isUser(this.props.currentUserRole) ? 'd-none' : ''}>
                            <div className="row">
                                <div className="col-md-12">
                                    <ClientSettings match={match} isEditable={isEditable} />
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                    {/*{*/}
                    {/*    error && false ? (<div style={styles.wrapper}>*/}
                    {/*        <p className="login-box-msg">*/}
                    {/*            <h2 className="text-muted">Cannot load Client</h2>*/}
                    {/*            <h4 className="text-danger">Error: {this.props.error} </h4>*/}
                    {/*        </p>*/}
                    {/*    </div>) : null*/}
                    {/*}*/}
                </div>
            </section>
        )
    }
}

const allowToEdit = (role) => (role === 'ROLE_CLIENT_ADMIN' || role === 'ROLE_ADMIN');

const mapStateToProps = (state) => {
    return ({
        isLoading: state.app.companies.loading,
        isFacilityAndPhysicianEditable: state.app.auth.user.roleCode==='ROLE_ADMIN',
        isEditable: allowToEdit(state.app.auth.user.roleCode) || state.app.users.my_permissions.includes('MANAGE_CLIENT'),
        error: state.app.companies.error,
        profile: state.app.companies.editedCompany,
        isProfileChanged: state.app.companies.isProfileChanged,
        editedSuccess: state.app.companies.editedSuccess,
        validationError: state.app.companies.validationError,
        currentTab: state.app.companies.currentTab,
        currentPage: state.router.location.pathname,
        clients: state.app.companies.data,
        currentUserRole: state.app.auth.user.roleCode,
        isCompanyValid: state.app.companies.allValid,
        countries: state.app.countries,
        currencies: state.app.currencies,
        languages: state.app.languages,
        billing: state.app.billing,
        users: state.app.users,
        templates: state.app.templates,
        caseFileTemplates: state.app.case_file_templates,
        timezone: state.app.timezone,
        caseTypes: state.app.case_types,
        reviewerTypes: state.app.reviewerTypes,
        facilities: state.app.facilities,
        // company: state.app.companies.editedCompany,
    })
};

const mapDispatchToProps = dispatch => {
    return {
        getActiveCountries: (countries) => dispatch(getActiveCountries(countries)),
        getAllCurrencies: (currencies) => dispatch(getAllCurrencies(currencies)),
        getLanguages: (languages) => dispatch(getLanguages(languages)),
        getAllLocales: () => dispatch(getAllLocales()),
        getAllBillingCodes: (billing) => dispatch(getAllBillingCodes(billing)),
        getAllRoles: (users) => dispatch(getAllRoles(users)),
        getTemplates: (templates) => dispatch(getTemplates(templates)),
        getAllCaseFileTemplates: (caseFileTemplates) => dispatch(getAllCaseFileTemplates(caseFileTemplates)),
        getCompanyProfile: (code) => dispatch(getCompanyProfile(code)),
        getCompanyBillingTiers: () => dispatch(getCompanyBillingTiers()),
        saveProfile: data => dispatch(editCompany(data)),
        saveLogo: data => dispatch(saveLogo(data)),
        isAllValid: (valid) => dispatch(isAllValid(valid)),
        setCurrentTab: (tab) => dispatch(setCurrentTab(tab)),
        getAllTimeZones: (timezone) => dispatch(getAll('time_zone', timezone)),
        getAllCaseTypes: (caseTypes) => dispatch(getAllCaseTypes(caseTypes)),
        getAllReviewerTypes: (reviewerTypes) => dispatch(getAllTypes(reviewerTypes)),
        // Facilities
        getAllFacilities: (facilities) => dispatch(getAllFacilities(facilities)),
        getAllFacilityTypes: (facilities) => dispatch(getAllFacilityTypes(facilities)),
        // MedicalCodingSystem
        getAllProcedures: (procedures) => dispatch(getAll('procedure', procedures)),
        getAllDiagnoses: (diagnoses) => dispatch(getAll('diagnosis', diagnoses)),
        getAllScSystem: (scSystem) => dispatch(getAll('sc_system', scSystem)),
        getBodyParts: (bodyParts) => dispatch(getAll('body_part', bodyParts)),
        getAllSpecialties: (specialties) => dispatch(getAllSpecialties(specialties)),
        // getAllMsNotes: (companyCode) => dispatch(getAllMsNotes(companyCode)),
        // getAllMdNotes: (companyCode) => dispatch(getAllMdNotes(companyCode)),
        closeEditModal: () => dispatch(closeEditModal()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientEditPage);
