import { transformResponseToTableData } from 'components/Datatable/dataTableUtils';
import { groupBy, invert, isNull, mapValues, negate, omit, values } from 'lodash';

export const TO_ASSIGNEE_TYPES = {
    assigneeFieldReferences: 'toAssigneeFieldReferences',
    assigneeTypes: 'toAssigneeTypes',
    assigneeEmails: 'toAssigneeEmails',
};
export const CC_ASSIGNEE_TYPES = {
    assigneeFieldReferences: 'ccAssigneeFieldReferences',
    assigneeEmails: 'ccAssigneeEmails',
};
export const CASE_TEMPLATES = {
    caseTemplateName: 'caseTemplateName',
    caseTemplateId: 'caseTemplateId',
};

export const initialToAssigneeTypes = mapValues(invert(TO_ASSIGNEE_TYPES), () => null);
export const initialCcAssigneeTypes = mapValues(invert(CC_ASSIGNEE_TYPES), () => null);

export const selectedAllTemplates = { label: 'All Templates', value: '' };

export const transformAssigneeTypesValuesToSelect = (types, entity) =>
    Object.values(types)
        .map((type) =>
            ((entity !== null && entity[ type ]) || [])
                .filter((v) => v)
                .map((value) => ({
                    label: value,
                    type,
                    value,
                })),
        )
        .flat();

export const encodeAssigneeTypeValues = (values) => {
    const grouped = groupBy(values, 'type');
    return Object.fromEntries(Object.keys(grouped).map((groupKey) => [groupKey, grouped[ groupKey ].map(({ value }) => value)]));
};

export const transformNotificationEntityToSubmit = (entity) => {
    const { toAssignee, ccAssignee, caseTemplate, caseTemplateId, caseTemplateName, triggerType, triggerTypeName, ...entityOtherAttributes } = entity;
    return {
        ...entityOtherAttributes,
        ...encodeAssigneeTypeValues(toAssignee),
        ...encodeAssigneeTypeValues(ccAssignee),
        caseTemplateId: (caseTemplate && caseTemplate.value) || caseTemplateId,
        caseTemplateName: (caseTemplate && caseTemplate.label) || caseTemplateName,
        triggerType: (triggerType && triggerType.value) || triggerType,
    };
};

export const transformNotificationTemplate = (data) => {
    return ({
        ...omit(data, [...values(CC_ASSIGNEE_TYPES), ...values(TO_ASSIGNEE_TYPES), ...values(CASE_TEMPLATES)]),
        ccAssignee: transformAssigneeTypesValuesToSelect(CC_ASSIGNEE_TYPES, data),
        toAssignee: transformAssigneeTypesValuesToSelect(TO_ASSIGNEE_TYPES, data),
        caseTemplate: data.caseTemplateName && data.caseTemplateId ? {
            label: data.caseTemplateName,
            value: data.caseTemplateId
        } : selectedAllTemplates,
        triggerType: data.triggerType && data.triggerTypeName ? {
            label: data.triggerTypeName,
            value: data.triggerType
        } : null,
    });
};

export const formatAssigneeField = (...fields) =>
    fields
        .filter(negate(isNull))
        .map((field) => (field || []).join(', '))
        .join(' | ');

export const transformNotificationTemplates = transformResponseToTableData((data) => {
    return {
        data: data.map(
            ({
                 toAssigneeTypes, toAssigneeEmails, toAssigneeFieldReferences, ccAssigneeEmails,
                 ccAssigneeFieldReferences, caseTemplateName, name, code, id
             }) => {
                return ({
                    id,
                    templateName: name,
                    to: formatAssigneeField(toAssigneeTypes, toAssigneeEmails, toAssigneeFieldReferences),
                    cc: formatAssigneeField(ccAssigneeEmails, ccAssigneeFieldReferences),
                    caseTemplateName: caseTemplateName || 'All Templates',
                    code,
                })
            },
        ),
    };
});
