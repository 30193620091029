import React from 'react';
import { useContext } from 'react';
import { Button, Col, FormGroup, Row } from 'react-bootstrap';
import FormContext from './FormContext';
import PropTypes from 'prop-types';
import Spinner from 'pages/Develop/Spinner';

const SubmitButton = ({ title, disabled, className = '', ...props }) => {
  return (
    <Button
      type="submit"
      bsStyle="success"
      className={`btn-block btn-flat mg-top-30 ${className}`}
      disabled={disabled}
      {...props}
    >
      {title}
    </Button>
  );
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const SubmitButtonGroup = ({ disabled, title, align, ...props }) => {
  const { isLoading } = useContext(FormContext);

  return (
    <FormGroup>
      <Row>
        <Col md={5} className={`pull-${align}`}>
          <SubmitButton
            {...props}
            disabled={isLoading || disabled}
            title={isLoading ? <Spinner /> : title}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

SubmitButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string,
  align: PropTypes.oneOf(['right', 'left']),
};

SubmitButtonGroup.defaultProps = {
  title: 'Submit',
  disabled: false,
  align: 'right',
};

export default SubmitButtonGroup;
