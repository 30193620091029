import { isObjectsEqual, makeSelectOptions } from '../../utils/helpers';

const userGridConfiguration = {
    sorting: {
        sortName: 'firstName',
        sortOrder: 'asc',
    },
};
const staffGridConfiguration = {
    sorting: {
        sortName: 'firstName',
        sortOrder: 'asc',
    },
};

const reviewerGridConfiguration = {
    sorting: {
        sortName: 'name',
        sortOrder: 'asc',
    },
};

const defaultUsersState = {
    loading: false,
    loadedCount: 0,
    profileLoading: false,
    error: null,
    editUserSuccess: false,
    editUserError: null,
    editRevProfileError: null,
    editRevProfessionError: null,
    editRevFinanceError: null,
    editRevNotesError: null,
    editedUser: {
        error: '',
        isLoading: false,
        firstLoad: true,
        code: null,
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        notificationEmail: '',
        phoneNumber: '',
        alternativePhoneNumber: '',
        address1: '',
        address2: '',
        city: '',
        stateCode: null,
        postalCode: '',
        countryCode: '',
        notes: '',
        toDo: '',
        roleCode: '',
        specialtyCodes: '',
        reviewerTypeCode: '',
        caseTypesCode: '',
        companyExcludedCodes: [],
        companyIncludedCodes: [],
        title: '',
        iban: '',
        bicnum: '',
        routingNum: '',
        accountNum: '',
        trainDate: '',
        dispatchNotes: '',
        backUp: false,
        signature: null,
        savedSignature: [],
    },
    originalUser: {},
    users: [],
    roles: [],
    lastLoadedRolesTime: 0,
    permissions: [],
    my_permissions: [],
    createModalOpen: false,
    editModalOpen: false,
    deleteModalOpen: false,
    submitModalOpen: false,
    filesModalOpen: false,
    caseReopenModalOpen: false,
    deletedCode: '',
    deletedType: 'file',
    userValid: false,
    showValidMessages: false,
    isProfileChanged: false,
    userGridConfiguration: userGridConfiguration,
    staffGridConfiguration: staffGridConfiguration,
    reviewerGridConfiguration: reviewerGridConfiguration,
    statistics: {
        acceptedFirstDate: null,
        acceptedLastDate: null,
        currentMonthAccepted: 0,
        currentMonthPreassigned: 0,
        currentMonthRejected: 0,
        currentMonthScooped: 0,
        id: null,
        lastMonthAccepted: 0,
        lastMonthPreassigned: 0,
        lastMonthRejected: 0,
        lastMonthScooped: 0,
        preAssignedFirstDate: null,
        preAssignedLastDate: null,
        rejectedFirstDate: null,
        rejectedLastDate: null,
        scoopedFirstDate: null,
        scoopedLastDate: null,
        totalAccepted: 0,
        totalPreAssigned: 0,
        totalRejected: 0,
        totalScooped: 0,
        userCode: null,
        userId: null,
    },
};

const users = (state = defaultUsersState, action) => {
    // console.log('users REDUCER', action.type);
    switch (action.type) {
        case 'OPEN_DELETE_MODAL':
            return { ...state, deleteModalOpen: true };
        case 'CLOSE_DELETE_MODAL':
            return { ...state, deleteModalOpen: false };
        case 'OPEN_SUBMIT_MODAL':
            return { ...state, submitModalOpen: true };
        case 'CLOSE_SUBMIT_MODAL':
            return { ...state, submitModalOpen: false };
        case 'OPEN_EDIT_MODAL':
            return { ...state, editModalOpen: true };
        case 'CLOSE_EDIT_MODAL':
            return { ...state, editModalOpen: false };
        case 'OPEN_CREATE_MODAL':
            return { ...state, createModalOpen: true };
        case 'CLOSE_CREATE_MODAL':
            return { ...state, createModalOpen: false };
        case 'OPEN_FILES_MODAL':
            return { ...state, filesModalOpen: true };
        case 'CLOSE_FILES_MODAL':
            return { ...state, filesModalOpen: false };
        case 'OPEN_CASE_REOPEN_MODAL':
            return { ...state, caseReopenModalOpen: true };
        case 'CLOSE_CASE_REOPEN_MODAL':
            return { ...state, caseReopenModalOpen: false };
        case 'SAVE_DELETED_CODE':
            return { ...state, deletedCode: action.code, deletedType: action.deletedType };
        case 'FETCH_USER_LOADING':
            return { ...state, loading: true, error: null, editUserSuccess: false };
        case 'FETCH_USER_ROLES_LOADING':
            return { ...state, loadingRoles: true };

        case 'FETCH_USER_STATISTICS':
            return { ...state, statistics: action.payload };

        case 'FETCH_USER_PERMISSIONS_LOADING':
            return { ...state, loadingPermissions: true };

        case 'FETCH_USER_PROFILE_LOADING':
            return {
                ...state,
                profileLoading: true,
                error: null,
                loading: false,
                editRevProfileError: null,
                editRevProfessionError: null,
                editRevFinanceError: null,
                editRevNotesError: null,
                editUserError: null,
                editUserSuccess: false,
                showValidMessages: false,
                editedUser: defaultUsersState.editedUser,
                originalUser: defaultUsersState.editedUser,
            };
        case 'FETCH_USER_PROFILE_SUCCESS': {
            const fetchedUser = {
                ...action.payload,
                notificationEmail: action.payload.notificationEmail ? action.payload.notificationEmail : action.payload.email,
                hrlyRate: action.payload.hrlyRate,
                trainDate: action.payload.trainDate ? new Date(action.payload.trainDate) : null,
                staffIpWhitelist: makeSelectOptions(action.payload.staffIpWhitelist),
                savedSignature: [],
                active: !!action.payload.active,
            };
            return {
                ...state,
                error: null,
                loading: false,
                editRevProfileError: null,
                editRevProfessionError: null,
                editRevFinanceError: null,
                editRevNotesError: null,
                editUserError: null,
                profileLoading: false,
                editedUser: fetchedUser,
                originalUser: fetchedUser,
                isProfileChanged: false,
            };
        }
        case 'FETCH_USER_PROFILE_ERROR':
            return {
                ...state,
                profileLoading: false,
                error: null,
                loading: false,
                editUserError: action.payload,
            };
        case 'FETCH_USER_ERROR':
            return { ...state, error: action.payload, loading: false, loadingRoles: false, loadingPermissions: false };
        case 'USER_EDIT_FIELD':
            return Object.assign({}, state, {
                editedUser: Object.assign({}, state.editedUser, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedUser, action.payload), state.originalUser),
            });
        case 'VALIDATE_USER_FIELD':
            return Object.assign({}, state, {
                userValid: action.payload,
            });
        case 'SET_USER_CURRENT_TAB':
            return Object.assign({}, state, {
                currentTab: action.payload,
            });
        case 'SHOW_USER_VALIDATION':
            return Object.assign({}, state, {
                showValidMessages: true,
            });
        case 'FETCH_EDIT_USER_ERROR':
            return { ...state, editUserError: action.payload, loading: false };
        case 'FETCH_EDIT_PROFILE_ERROR':
            return { ...state, editRevProfileError: action.payload, loading: false };
        case 'FETCH_EDIT_PROFESSION_ERROR':
            return { ...state, editRevProfessionError: action.payload, loading: false };
        case 'FETCH_EDIT_FINANCE_ERROR':
            return { ...state, editRevFinanceError: action.payload, loading: false };
        case 'FETCH_EDIT_NOTES_ERROR':
            return { ...state, editRevNotesError: action.payload, loading: false };
        case 'FETCH_ALL_USERS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                users: action.payload,
                error: null,
                editRevProfileError: null,
                editRevProfessionError: null,
                editRevFinanceError: null,
                editRevNotesError: null,
                editUserError: null,
            });
        case 'FETCH_USER_ROLES_SUCCESS':
            return Object.assign({}, state, {
                loadingRoles: false,
                roles: action.payload,
                error: null,
                lastLoadedRolesTime: Date.now(),
            });
        case 'FETCH_USER_PERMISSIONS_SUCCESS':
            return Object.assign({}, state, {
                loadingPermissions: false,
                permissions: action.payload,
                error: null,
            });
        case 'FETCH_MY_PERMISSIONS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                my_permissions: action.payload,
                error: null,
            });
        case 'FETCH_CREATE_USER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                users: [...state.users, action.payload],
            });
        // {...state, users: [...state.users, action.payload ], loading: false, error:null };
        case 'FETCH_EDIT_USER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editUserSuccess: true,
                isProfileChanged: false,
                editRevProfileError: null,
                editRevProfessionError: null,
                editRevFinanceError: null,
                editRevNotesError: null,
                editUserError: null,
                originalUser: action.payload,
                users: state.users.map((usr) => {
                    if (usr.code === action.payload.code) return action.payload;
                    return usr;
                }),
            });
        case 'FETCH_UPDATE_ROLE_SUCCESS':
            return Object.assign({}, state, {
                loadingRoles: false,
                error: null,
                roles: state.roles.map((role) => {
                    if (role.code === action.payload.code) return action.payload;
                    return role;
                }),
            });

        case 'FETCH_USER_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                users: [...state.users, action.payload],
            };
        case 'FILL_USER_LOCAL_STORAGE': {
            let items = {};
            for (let i = 0; i < state.users.length; i++) {
                let item = state.users[i];
                if (/*isUser(item.roleCode) && */ item.active == null || item.active === true) {
                    items[item.code] = item.firstName + ' ' + item.lastName;
                }
            }
            localStorage.setItem('list_user', JSON.stringify(items));
            return state;
        }
        case 'FETCH_USER_LOADED_COUNT':
            return { ...state, loadedCount: action.payload };

        case 'USER_LOADED':
            return { ...state, loading: false };

        default:
            return state;
    }
};

export default users;
