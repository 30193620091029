import React from 'react';

const InputGroupWrapper = ({children, onActionClick, icon, name, text}) => (
    <div className="input-group ">
        {children}
        <div className="input-group-addon cursor-pointer"
             onClick={onActionClick}
             data-name={name}>
            <i className ={"fa fa-" + icon} id={name} data-name={name}/>
            <b>{!icon && <span className="input-addon-text">{text}</span>}</b>
        </div>
    </div>
)

export default InputGroupWrapper
