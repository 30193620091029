import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import Modal from 'react-modal';
import LoadingIndicator from '../../components/LoadingIndicator';
import {closeCreateModal} from '../../redux/action-creators/users'
import {getAll} from '../../redux/actions/BasicEntityActions'
import {getAllCompanies} from '../../redux/actions/companiesActions'
import {getFullNameFromCode, getNameFromCode} from '../../utils/helpers'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import PropTypes from "prop-types";

class PatientList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showActivateModal: false,
            showEditModal: false,
            userId: '',
        };

        this.handleOpenEdit = this.handleOpenEdit.bind(this);
        this.roleFormatter = this.roleFormatter.bind(this);
    }

    componentDidMount() {
        Modal['setAppElement']('body');
        this.props.getAllCompanies();
        this.props.handleCloseModals();
        this.props.getAllUsers();
        this.props.getAllCountries();
        this.props.getGenders();
    }

    handleOpenEdit(userId) {
        this.setState({userId});
        this.props.history.push(`/patients/profile/${userId}`)
    }

    enumFormatter = (cell, row, enumObject) => {
        return enumObject[cell];
    };

    roleFormatter(cell, row) {
        let roleName = '';
        if (row) {
            this.props.roles.forEach((item) => {
                if (item.code === cell) {
                    roleName = item.name;
                }
            });
        }
        return roleName;
    }

    contactFormatter = (cell, row) => {
        const {email, primaryPhoneNumber} = row;
        return `${email ? email : ''} ${primaryPhoneNumber ? primaryPhoneNumber : ''}`
    };

    render() {
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true
            }, {
                'name': 'firstName',
                'title': this.context.translate('common.users.first_name'),
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'lastName',
                'title': this.context.translate('common.users.last_name'),
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'gender',
                'title': this.context.translate('common.users.gender'),
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'company',
                'title': this.context.translate('common.form.company'),
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'email',
                'title': this.context.translate('common.contact_info'),
                'dataFormat': this.contactFormatter,
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'primaryPhoneNumber',
                'hidden': true,
                'title': this.context.translate('common.form.phone_number'),
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'active',
                'title': this.context.translate('common.form.status'),
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': this.context.translate('common.form.inactive'),
                    'true': this.context.translate('common.form.active')
                },
                'width': '100',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': this.context.translate('common.form.inactive'),
                        'true': this.context.translate('common.form.active')
                    }, selectText: this.context.translate('common.form.select')
                }
            }
        ];
        const {isLoading, patients} = this.props;
        const {sorting, pagination} = this.props.gridConfiguration;
        return (
            <div className='container-fluid'>
                <div className="table-responsive" style={{marginTop: '15px', "padding": '10px'}}>
                    {isLoading && <LoadingIndicator type="lister" />}
                    <RemoteGrid
                        color="blue"
                        title="Patients"
                        entity="patient"
                        data={patients}
                        total={patients.length}
                        columns={columns}
                        pagination={true}
                        page={pagination.page}
                        sizePerPage={pagination.sizePerPage}
                        search={true}
                        onRowClick={this.handleOpenEdit}
                        defaultSort
                        sortName={sorting.sortName}
                        sortOrder={sorting.sortOrder}
                        multiColumnSort={3}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        gridConfiguration: PropTypes.object,
        patients: PropTypes.array,
    }
}

PatientList.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    patients: state.app.patients.data
        .map((item) => ({
            ...item,
            company: getFullNameFromCode(item.company, state.app.companies.data),
            gender: getNameFromCode(item.gender, state.app.gender.data),

        })),
    isLoading: state.app.patients.loading,
    error: state.app.patients.error,
    gridConfiguration: state.app.grid.patient
});

const mapDispatchToProps = dispatch => ({
    handleCloseModals: () => {
        dispatch(closeCreateModal())
    },
    getAllUsers: () => {
        dispatch(getAll('patient'))
    },
    getAllCompanies: () => dispatch(getAllCompanies()),
    getAllCountries: () => dispatch(getAll('country')),
    getGenders: () => dispatch(getAll('gender'))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PatientList))
