import api from "../../utils/api";
import apiFile from "../../utils/api";
import {Statuses} from "./statuses";
import {getSelectFieldValue, getMultiSelectValue} from '../../utils/helpers'
import history from '../history'
import {getCompanyBillingTiers} from './billingActions';

export const getAllCompanies = () => (dispatch) => {
    dispatch({
        type: 'FETCH_COMPANIES_LOADING',
    });

    api.get('company/all')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_COMPANIES_SUCCESS',
                payload: data
            });
            dispatch({
                type: 'FILL_COMPANY_LOCAL_STORAGE'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_COMPANY_ERROR',
                    payload: Statuses[error.response ? error.response.status : '0']
                })
            } else if (error.request) {
                console.log(error.request);
                dispatch({
                    type: 'FETCH_COMPANY_ERROR',
                    payload: error.request.message
                });
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_COMPANY_ERROR',
                    payload: error.request.message
                });
            }
        })

};

export const getAllCompaniesIfEmpty = () => (dispatch, getState) => {
    let data = getState().app.companies.data;
    if (data.length === 0) {
        dispatch(getAllCompanies());
    }
};

export const getCompanyProfile = code => dispatch => {
    dispatch({
        type: 'FETCH_COMPANY_LOADING',
    });

    return api.get(`company/get?code=${code}`)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_COMPANY_SUCCESS',
                payload: data
            })
        })
        .catch((err) => {
            console.log(Statuses[err.response ? err.response.status : '0']);
            dispatch({
                type: 'FETCH_COMPANY_ERROR',
                payload: Statuses[err.response ? err.response.status : '0']
            })
        })
};

export const editCompany = (data, closeAfterSave) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_EDIT_COMPANY_LOADING',
    });

    let isBillingGroupChanged =
        getSelectFieldValue(data.billingGroup) !== getState().app.companies.editedCompany.billingGroup;

    const convertedData = {
        ...data,
        countryCode: getSelectFieldValue(data.countryCode),
        stateCode: getSelectFieldValue(data.stateCode),
        defaultLanguageCode: getSelectFieldValue(data.defaultLanguageCode),
        defaultLocaleCode: getSelectFieldValue(data.defaultLocaleCode),
        currencyCode: getSelectFieldValue(data.currencyCode),
        billingGroup: getSelectFieldValue(data.billingGroup),
        ipWhiteList: getMultiSelectValue(data.ipWhiteList),
        rolesAllowedToReopenCodes: getMultiSelectValue(data.rolesAllowedToReopenCodes),
        languageCodes: getMultiSelectValue(data.languageCodes),
        defaultTemplateCode: getSelectFieldValue(data.defaultTemplateCode),
        caseFileTemplateCode: getSelectFieldValue(data.caseFileTemplateCode),
        contractDate: data.contractDate ? data.contractDate.getTime() : null,
        firstInvoiceDate: data.firstInvoiceDate ? data.firstInvoiceDate.getTime() : null,
        caseLevels: getMultiSelectValue(data.caseLevels),
        defaultTimezone: getSelectFieldValue(data.defaultTimezone),
        timeZoneCode: getSelectFieldValue(data.timeZoneCode),
        medicalCodingSystemCodes: getMultiSelectValue(data.medicalCodingSystemCodes)
    };

    return api.post('company/update', convertedData)
        .then(({config}) => {
            // console.log(JSON.parse(config.data));
            dispatch({
                type: 'FETCH_EDIT_COMPANY_SUCCESS',
                payload: JSON.parse(config.data)
            });
            closeAfterSave && history.goBack();
            !closeAfterSave && isBillingGroupChanged && dispatch(getCompanyBillingTiers());
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_COMPANY_ERROR',
                payload: Statuses[err.response ? err.response.status : '0']
            })

        })
};

export const saveLogo = (data) => (dispatch) => {
    dispatch({
        type: 'FETCH_COMPANY_LOGO_LOADING',
    });

    let formData = new FormData();
    formData.append('logo', data.file[0]);
    formData.append('code', data.code);

    return apiFile.post('company/logo?code=' + data.code, formData)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_COMPANY_LOGO_SUCCESS',
                payload: data
            });
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response);
                // dispatch({
                //     type: 'FETCH_COMPANY_LOGO_ERROR',
                //     payload: Statuses[error.response.status]
                // })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_COMPANY_LOGO_ERROR',
                    payload: error.message
                });
            }
        })
};

export const createNewCompany = (company) => (dispatch) => {
    dispatch({
        type: 'FETCH_COMPANIES_LOADING',
    });

    api.post('company/create', company)
        .then((res) => {
            console.log(res);
            dispatch({
                type: 'FETCH_CREATE_COMPANY_SUCCESS',
                payload: company
            });
            dispatch({
                type: 'CLOSE_CREATE_MODAL'
            });
            history.push(`clients/profile/${company.code}`)
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_COMPANY_ERROR',
                    payload: Statuses[error.response ? error.response.status : '0']
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_COMPANY_ERROR',
                    payload: Statuses[error.response ? error.response.status : '0']
                });
            }
            // dispatch({
            //     type: 'FETCH_COMPANY_ERROR',
            //     payload: error.message
            // });
            // console.log(error.status);
        })

};

export const getAllMsNotes = (companyCode) => (dispatch) => {
    dispatch({
        type: 'FETCH_COMPANY_LOADING',
    });

    api.get(`company/all_ms_notes?companyCode=${companyCode}`)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_ALL_MS_NOTES_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_ALL_MS_NOTES_ERROR',
                    payload: Statuses[error.response ? error.response.status : '0']
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_ALL_MS_NOTES_ERROR',
                    payload: Statuses[error.response ? error.response.status : '0']
                });
            }
        })
};

export const getAllMdNotes = (companyCode) => (dispatch) => {
    dispatch({
        type: 'FETCH_COMPANY_LOADING',
    });

    api.get(`company/all_md_notes?companyCode=${companyCode}`)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_ALL_MD_NOTES_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_ALL_MD_NOTES_ERROR',
                    payload: Statuses[error.response ? error.response.status : '0']
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_ALL_MD_NOTES_ERROR',
                    payload: Statuses[error.response ? error.response.status : '0']
                });
            }
        })
};

export const editMsNotes = (data) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_COMPANIES_LOADING',
    });

    api.post('company/ms_notes', {
        ...data,
        medicalCode: data.code,
        companyCode: getState().app.companies.editedCompany.code,
    })
        .then((res) => {
            console.log(res);
            dispatch({
                type: 'FETCH_EDIT_MS_NOTES_SUCCESS',
                payload: data
            });
            dispatch({
                type: 'CLOSE_EDIT_MODAL'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_EDIT_MS_NOTES_ERROR',
                    payload: Statuses[error.response ? error.response.status : '0']
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_EDIT_MS_NOTES_ERROR',
                    payload: Statuses[error.response ? error.response.status : '0']
                });
            }
        })
};

export const editMdNotes = (data) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_COMPANIES_LOADING',
    });

    api.post('company/md_notes', {
        ...data,
        medicalCode: data.code,
        companyCode: getState().app.companies.editedCompany.code,
    })
        .then((res) => {
            console.log(res);
            dispatch({
                type: 'FETCH_EDIT_MD_NOTES_SUCCESS',
                payload: data
            });
            dispatch({
                type: 'CLOSE_EDIT_MODAL'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_EDIT_MD_NOTES_ERROR',
                    payload: Statuses[error.response ? error.response.status : '0']
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_EDIT_MD_NOTES_ERROR',
                    payload: Statuses[error.response ? error.response.status : '0']
                });
            }
        })
};


export const emitValidationError = () => {
    return {
        type: 'SHOW_COMPANY_VALIDATION',
    }
};

export const isAllValid = valid => {
    return {
        type: 'VALIDATE_COMPANY_FIELD',
        payload: valid
    }
};

export const editField = (field, value) => {
    return {
        type: 'EDIT_COMPANY_FIELD',
        payload: {
            [field]: value
        }
    }
};

export const editLogo = (value) => {
    return {
        type: 'FETCH_COMPANY_LOGO_SUCCESS',
        payload: value
    }
};

export const setCurrentTab = (tab) => {
    return {
        type: 'SET_CURRENT_TAB',
        payload: tab
    }
};

export const clearBillingGroup = () => {
    return {
        type: 'CLEAR_COMPANY_BILLING_GROUP',
    };
}
