import React from 'react';
import UnitInput from './ControlUnit';
import PropTypes from 'prop-types';

// alias
const CurrencyInput = ({ currency, ...props }) => {
  return <UnitInput unit={currency} {...props} />;
};

CurrencyInput.propTypes = {
  currency: PropTypes.string, // todo restrict this to list of currencies
};

export default CurrencyInput;
