import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from "prop-types";

class RemoteGridSearchPanel extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.searchValue) !== JSON.stringify(this.props.searchValue)) {
            this.props.search(this.props.searchValue);
        }
    }

    componentDidMount() {
        this.props.search(this.props.searchValue);
    }

    render() {
        return (
            <div className='input-group' style={{ display: 'none' }}>
            </div>
        );
    }

    static propTypes = {
        searchValue: PropTypes.string,
        search: PropTypes.func,
    }
}

const mapStateToProps = (state) => {
    return {
        // searchValue: state.app.grid.searchValue,
        searchValue: state.app.globalSearch.search,
    }
}

export default connect(mapStateToProps, null)(RemoteGridSearchPanel);
