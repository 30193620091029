import React from 'react';
import {connect} from 'react-redux';
import SimpleReactValidator from "utils/simple-react-validator";
import FormField from '../../components/FormField/index'
import {createNewFacility, getFacility} from '../../redux/actions/facilitiesActions'
import PropTypes from "prop-types";
import {getSelectFieldValue, idFromString} from "../../utils/helpers";
import {getActiveCountries, getActiveStates} from "../../redux/actions/countryActions";
import shortId from "shortid";
import {getAll} from "../../redux/actions/BasicEntityActions";
import SystemMessage from '../../components/SystemMessage';

class FacilityCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            name: ''
        };

        let match = this.props;
        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This value already exists in the system, the new record won\'t be created.',
                rule: function (val) {
                    return !match.facilities_names.split(',').includes(val);
                }
            }
        });
        this.validator.showMessages();

        this.Submit = this.Submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeFacilityType = this.onChangeFacilityType.bind(this);
        this.onChangeBodyPartCodes = this.onChangeBodyPartCodes.bind(this);
    }

    componentDidMount() {
        this.props.getFacility('');
        this.props.getActiveCountries();
        this.props.getActiveStates();
        this.props.getAllBodyParts();
        this.props.getAllTypes();
    }

    onChangeCheckBox(e) {
        // const newValue = e.target.value === 'true' ? false : true
        this.setState({[e.target.name]: e.target.checked});
    }

    Submit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            let bodyPartCodes = this.state.bodyPartCodes ? this.state.bodyPartCodes.map((item) => item.value) : [];
            const data = {
                ...this.state,
                code: idFromString(shortId.generate()),
                countryCode: getSelectFieldValue(this.state.countryCode) || '',
                stateCode: getSelectFieldValue(this.state.stateCode) || '',
                bodyPartCodes: bodyPartCodes,
                facilityTypeCode: getSelectFieldValue(this.state.facilityTypeCode) || ''
            }
            this.props.createNewFacility(data);
        } else {
            this.setState({messagesShown: true})

        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    changeCountry(countryCode) {
        this.setState({countryCode});
        if (countryCode) {
            if (countryCode.value !== 'US') {
                this.setState({stateCode: {}});
            }
        } else {
            this.setState({stateCode: {}})
        }
    }

    onChangeState(stateCode) {
        this.setState({stateCode});
    }

    onChangeFacilityType(facilityTypeCode) {
        this.setState({facilityTypeCode});
    }

    onChangeBodyPartCodes(bodyPartCodes) {
        this.setState({bodyPartCodes: bodyPartCodes});
    }

    render() {
        // let showError = this.props.error ? 'block' : 'none';
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
        }))

        return (
            <form noValidate autoComplete="off"
                  className='modal-content custom-modal-content'
                  onSubmit={this.Submit}>
                <SystemMessage shown={this.state.messagesShown} type='error' message={this.props.error} validationMessages={errorMessages}/>

                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="6"
                            label={this.context.translate('common.form.name')}
                            id={this.context.translate('common.form.name')}
                            required
                            valids="required|unique"
                            validator={this.validator}
                            value={this.state.name}
                            onChange={this.onChange}
                            name='name'
                        />
                        <FormField
                            md="6"
                            type='REF_FACILITY_TYPE'
                            label={this.context.translate('common.form.type')}
                            name='facilityTypeCode'
                            className="text-bg "
                            disabled={false}
                            value={this.state.facilityTypeCode}
                            onChange={this.onChangeFacilityType}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="6"
                            label={this.context.translate('common.form.code')}
                            value={this.state.facilityCode}
                            onChange={this.onChange}
                            name='facilityCode'
                        />
                        <FormField
                            md="6"
                            label={this.context.translate('common.form.address_1')}
                            className="form-control"
                            id="user_edit_address1"
                            name="street1"
                            value={this.state.street1}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            label={this.context.translate('common.form.address_2')}
                            className="form-control"
                            id="user_edit_address2"
                            name="street2"
                            value={this.state.street2}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            label={this.context.translate('common.form.address_3')}
                            className="form-control"
                            id="user_edit_address3"
                            name="street3"
                            value={this.state.street3}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            label={this.context.translate('common.form.city')}
                            className="form-control"
                            name="city"
                            value={this.state.city}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            xs="6"
                            type='REF_STATE'
                            label={this.context.translate('common.form.state')}
                            name='stateCode'
                            className="text-bg "
                            disabled={getSelectFieldValue(this.state.countryCode) !== 'US'}
                            value={this.state.stateCode}
                            onChange={this.onChangeState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            xs="6"
                            label={this.context.translate('common.form.postal_code')}
                            className="form-control"
                            name="postalCode"
                            value={this.state.postalCode}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            type="select"
                            label={this.context.translate('common.form.country')}
                            className="text-bg "
                            disabled={false}
                            options={this.props.countries}
                            id="user_edit_country"
                            name="countryCode"
                            value={this.state.countryCode}
                            onChange={this.changeCountry}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="6"
                            label={this.context.translate('common.form.phone_number')}
                            className="form-control"
                            name="phoneNumber"
                            value={this.state.phoneNumber}
                            onChange={this.onChange}
                        />
                        <FormField
                            md="6"
                            type="select"
                            label={this.context.translate('common.form.body_parts')}
                            className="text-bg "
                            searchable
                            multi={true}
                            disabled={false}
                            options={this.props.bodyParts}
                            id="edit_body_parts"
                            name="bodyPartCodes"
                            value={this.state.bodyPartCodes}
                            onChange={this.onChangeBodyPartCodes}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="12"
                            type="checkbox"
                            title={this.context.translate('common.form.status')}
                            label={this.context.translate('common.form.active')}
                            name='active'
                            value={this.state.active}
                            checked={this.state.active}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className='col-md-12 col-xs-12 fields'>
                            <label htmlFor="notes_note" className="required">{this.context.translate('common.form.notes')}</label>
                            <textarea name="notes" id="notes_note" cols="30" rows="7" value={this.state.notes} onChange={this.onChange}/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right">
                            <button
                                className='btn btn-block btn-flat btn-success'
                                disabled={this.props.isLoading}
                            >
                                {this.context.translate('common.client.add_facility')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

FacilityCreateForm.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => ({
    isLoading: state.app.facilities.loading,
    facilities_names: state.app.facilities.data
        .map((item) => {
            return item.name;
        }).join(','),
    facilities_types: state.app.facilities.types.filter((item) => item.active),
    countries: state.app.countries.data
        .map(country => ({
            value: country.code,
            label: country.name
        })),
    states: state.app.countries.states
        .map(state => ({
            value: state.code,
            label: state.name
        })),
    bodyParts: state.app.bodyParts.data
        .filter((item) => item.active)
        .map(item => ({
            value: item.code,
            label: item.name
        })),
    error: state.app.facilities.error,
})

const mapDispatchToProps = dispatch => ({
    getActiveCountries: () => dispatch(getActiveCountries()),
    getActiveStates: () => dispatch(getActiveStates()),
    getAllBodyParts: () => dispatch(getAll('body_part')),
    getAllTypes: () => dispatch(getAll('facility_type')),
    getFacility: code => dispatch(getFacility(code)),
    createNewFacility: (facility) => {
        dispatch(createNewFacility(facility));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(FacilityCreateForm);
