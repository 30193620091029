import { isObjectsEqual } from '../../../utils/helpers';

const baseBillGroup = {
    code: '',
    name: '',
    tierCount: '',
    active: false,
    billingTiers: [],
    companies: [],
    currency: ''
};

const defaultState = {
    data: [],
    editedBillingGroup: baseBillGroup,
    originalBillingGroup: baseBillGroup,
    error: '',
    currentTab: 'billing-group',
    editSuccess: false,
    loading: false,
    loadedCount: 0,
    isValid: false,
    isProfileChanged: false,
    showValidMessages: false,
};

const billing_group = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_BILLING_GROUP_LOADING':
            return {
                ...state,
                loading: true,
                editSuccess: false,
                showValidMessages: false
            };

        case 'FETCH_BILLING_GROUP_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null
            });

        case 'FETCH_UPDATE_BILLING_GROUP_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editSuccess: true,
                isProfileChanged: false,
                originalBillingGroup: state.editedBillingGroup
            });

        case 'FETCH_CREATE_BILLING_GROUP_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                data: [...state.data, action.payload]
            });

        case 'FETCH_BILLING_GROUP_PROFILE_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                isValid: true,
                isProfileChanged: false,
                editedBillingGroup: action.payload,
                originalBillingGroup: action.payload,
            };

        case 'FETCH_EDIT_BILLING_GROUP_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editSuccess: true,
                originalBillingGroup: action.payload,
                data: state.data.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            });

        case 'UPDATE_TIER_NUMBER_SUCCESS': {
            const billingTiers = state.editedBillingGroup.billingTiers.map((item) => {
                if (action.payload[ item.code ]) return action.payload[ item.code ];
                return item;
            });

            return Object.assign({}, state, {
                loading: false,
                editedBillingGroup: { ...state.editedBillingGroup, billingTiers },
                originalBillingGroup: { ...state.originalBillingGroup, billingTiers }
            });
        }
        case 'SET_BILLING_GROUP_CURRENT_TAB':
            return Object.assign({}, state, {
                currentTab: action.payload
            });

        case 'BILLING_GROUP_EDIT_FIELD':
            return Object.assign({}, state, {
                editedBillingGroup: Object.assign({}, state.editedBillingGroup, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedBillingGroup, action.payload), state.originalBillingGroup)
            });
        case 'VALIDATE_BILLING_GROUP_FIELD':
            return Object.assign({}, state, {
                isValid: action.payload
            });
        case 'FETCH_BILLING_GROUP_ERROR':
            return { ...state, error: action.payload, loading: false };

        case 'SHOW_BILLING_GROUP_VALIDATION':
            return Object.assign({}, state, {
                showValidMessages: true
            });

        case 'FETCH_BILLING_GROUP_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            };
        case 'FETCH_UPDATE_BILLING_GROUP_ERROR':
            return {
                ...state,
                error: action.payload,
                loading: false,
                editedBillingGroup: { ...state.editedBillingGroup, currency: state.originalBillingGroup.currency },
            };
        case 'FETCH_BILLING_GROUP_LOADED_COUNT':
            return { ...state, loadedCount: action.payload };

        default:
            return state
    }
};

export default billing_group;
