import api from "../../utils/api";
import {Statuses} from "./statuses";

export const getAllLocales = () => (dispatch) => {
    dispatch({
        type: 'FETCH_LOCALE_LOADING',
    })

    api.get('entity/locale/all')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_LOCALES_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_LOCALE_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_LOCALE_ERROR',
                    payload: error.message
                });
            }
        })

}
