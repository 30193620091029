import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { mfaResetForgottenPassword } from '../../../redux/actions/authActions';
import { Button, delayedApiCall, err, GoToLogin, log, TWAuthLayout, TWFormElementEmail } from './utils';

const FORM_STATE = {
  EDIT: 'EDIT',
  SUCCESS: 'SUCCESS',
};

export const PasswordReset2FAForm = () => {
  const dispatch = useDispatch();
  const emailRef = useRef();

  const [state, setStateOriginal] = useState({
    formState: FORM_STATE.EDIT,
    isLoading: false,
    extEmailError: '',
  });

  const setState = useCallback(
    (newState) => {
      setStateOriginal({ ...state, ...newState });
    },
    [setStateOriginal, state],
  );

  const ctaRequestNewPassword = () => {
    const email = emailRef.current;
    if (!email || !email.isValid()) {
      return;
    }
    setState({ isLoading: true });
    const currentEmail = email.getValue();
    const dto = { email: currentEmail };
    delayedApiCall(() => {
      dispatch(mfaResetForgottenPassword(dto)).then(
        (result) => {
          log('resetForgottenPassword', result);
          if (result.error) {
            setState({ isLoading: false, extEmailError: 'Backend is unavailable at the moment. Please try again later.' });
          } else {
            setState({ formState: FORM_STATE.SUCCESS, isLoading: false, extEmailError: '' });
          }
        },
        (e) => {
          err(e);
          setState({ isLoading: false, extEmailError: 'Something went wrong.' });
        },
      );
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    // log('onSubmit', event, formState);
  };

  const { formState, isLoading } = state;

  return (
    <TWAuthLayout title={'Request password reset link'}>
      <form className="space-y-2" action="#" method="POST" noValidate={true} onSubmit={onSubmit}>
        {/*email*/}
        <TWFormElementEmail
          id={'2fa-email'}
          ref={emailRef}
          show={formState === FORM_STATE.EDIT}
          disabled={isLoading}
          showEditButton={false}
          extError={state.extEmailError}
        />
        {/*continue button*/}
        <Button show={formState === FORM_STATE.EDIT} isLoading={isLoading} cta={ctaRequestNewPassword}>
          Request new password
        </Button>
        {formState === FORM_STATE.SUCCESS && (
          <>
            <p className={'font-medium mb-4'}>
              If an account associated with the provided E-mail address exists, an E-mail containing instructions on how to reset the password will be sent.
            </p>
            <GoToLogin />
          </>
        )}
      </form>
    </TWAuthLayout>
  );
};
