const initialFilters = {
    code: '',
    reviewerScore: NaN,
    reviewerRate: NaN,
    respectClientExclusions: false,
    includeBackup: false,
    preferNonBackupReviewers: false,
    sortByLastAssignedDate: false,
    reviewerTypes: null,
    countries: null,
    specialties: null,
    caseTypes: null,
    specialityFilteringType: 'ANY',
    caseTypeFilteringType: 'ANY',
};

const defaultState = {
    loading: false,
    assignmentDetailsLoading: false,
    currentFilters: initialFilters,

    appliedReviewers: [],

    defaultActivationDates: {},
    tiersActivationDates: {},
    originalTiersActivationDates: {},
    accepted: {},
    savedButton: {},
    savedNext: {},
    error: '',

    extendedAssignments: [],
};

const removeAppliedDoubles = (appliedReviewers) => {
    let result = [];
    let userCodes = [];

    for (let i = 0, len = appliedReviewers.length; i < len; i += 1) {
        let applied = appliedReviewers[ i ];
        if (userCodes.includes(applied[ 'user' ])) continue;
        result.push(applied);
        userCodes.push(applied[ 'user' ]);
    }
    return result;
};

const getOriginalTiersActivationsDates = (appliedReviewers) => {
    return appliedReviewers
        .reduce((p, detail) => {
            p[ detail.tier ] = detail.activationTime;
            return p
        }, { 'TIER_1': null, 'TIER_2': null, 'TIER_3': null });
};

// const getTiersActivationsDates = (appliedReviewers) => {
//     return appliedReviewers
//         .reduce((p, detail) => {
//             p[detail.tier] = getCorrectDateFormat(detail.activationTime, '', true);
//             return p
//         }, {'TIER_1': null, 'TIER_2': null, 'TIER_3': null});
// };

const case_assign = (state = defaultState, action) => {
    switch (action.type) {

        case 'FETCH_ASSIGNMENT_LOADING':
        case 'FETCH_ASSIGNMENT_FILTER_LOADING':
        case 'UPDATE_ASSIGNMENT_DETAILS_LOADING':
        case 'FETCH_CASE_ASSIGN_LOADING':
            return { ...state, loading: true, error: null, assignmentDetailsLoading: true };
        case 'FETCH_ASSIGNMENT_ERROR':
        case 'FETCH_ASSIGNMENT_FILTER_ERROR':
        case 'UPDATE_ASSIGNMENT_DETAILS_ERROR':
        case 'FETCH_CASE_ASSIGN_ERROR':
            return { ...state, error: action.payload, loading: false, assignmentDetailsLoading: false };

        case 'FETCH_ASSIGNMENT_SUCCESS': {
            // console.log('FETCH_ASSIGNMENT_SUCCESS', action.payload);
            let accepted = {};
            if (typeof action.payload !== "undefined" && action.payload.length > 0) {
                for (let k = 0; k < action.payload.length; k += 1) {

                    if (action.payload[ k ].action === 'ACCEPTED') {
                        accepted = action.payload[ k ];
                    }
                }
            }
            // const appliedReviewers = removeAppliedDoubles(action.payload);
            const appliedReviewers = action.payload;
            return Object.assign({}, state, {
                appliedReviewers: appliedReviewers,
                defaultActivationDates: action.defaultActivationDates,
                tiersActivationDates: getOriginalTiersActivationsDates(appliedReviewers),//getTiersActivationsDates(appliedReviewers),
                originalTiersActivationDates: getOriginalTiersActivationsDates(appliedReviewers),
                accepted: accepted,
                loading: false,
                assignmentDetailsLoading: false
            });
        }
        case 'FETCH_ASSIGNMENT_FILTER_SUCCESS': {
            // console.log('FETCH_ASSIGNMENT_FILTER_SUCCESS', action.payload);
            return { ...state, currentFilters: action.payload, loading: false, error: null };
        }
        case 'FETCH_ASSIGN_BY_FILTER_SUCCESS':
        case 'FETCH_ASSIGN_BY_PREVIOUS_SUCCESS': {
            const { filteredReviewers, appliedFilters, tiersActivationDates } = action.payload;

            return Object.assign({}, state, {
                loading: false,
                error: null,
                defaultActivationDates: tiersActivationDates,
                filteredReviewers: filteredReviewers,
                appliedFilters: appliedFilters,
            });
        }
        case 'UPDATE_ASSIGNMENT_SUCCESS': {
            let assignmentDetails = state.appliedReviewers.map((item) => {
                if (item.code === action.payload.code) item.action = action.payload.action;
                return item
            });
            return Object.assign({}, state, {
                loading: false,
                error: null,
                accepted: assignmentDetails.find((item) => item.action === 'ACCEPTED') || {}
            });
        }
        case 'UPDATE_ASSIGNMENT_FILTER_SUCCESS': {
            return {...state, loading: false, error: null}
        }

        case 'FETCH_GET_EXTENDED_ASSIGNMENTS_SUCCESS': {
            return {...state, extendedAssignments: action.payload, loading: false, error: null}
        }

        case 'SET_CURRENT_FILTER':
            return Object.assign({}, state,
                { currentFilters: action.payload ? action.payload : initialFilters });
        case 'SET_APPLIED_FILTER':
            return Object.assign({}, state,
                { appliedFilters: action.payload ? action.payload : initialFilters });

        case 'EDIT_ASSIGN_FILTER':
            // console.log('EDIT_ASSIGN_FILTER', state.currentFilters, ':::', action.payload);
            return Object.assign({}, state, {
                currentFilters: {
                    ...state.currentFilters,
                    ...action.payload
                }
            });

        case 'UPDATE_APPLIED_REVIEWERS': {
            // console.log('UPDATE_APPLIED_REVIEWERS');
            let appliedReviewers = removeAppliedDoubles(action.payload);
            return Object.assign({}, state, {
                appliedReviewers: appliedReviewers,
                tiersActivationDates: getOriginalTiersActivationsDates(appliedReviewers),//getTiersActivationsDates(appliedReviewers),
                originalTiersActivationDates: getOriginalTiersActivationsDates(appliedReviewers),
            });
        }
        case 'UPDATE_ASSIGNMENT_DETAILS_SUCCESS':
            return Object.assign({}, state, { loading: false });

        case 'SAVE_DROPDOWN_DATA':
            return Object.assign({}, state, { savedButton: action.button, savedNext: action.next });

        case 'UPDATE_TIERS_DATES':
            // var tiersActivationDates = {};
            // for (let key in action.payload) {
            //     if (!action.payload.hasOwnProperty(key)) continue;
            //     tiersActivationDates[key] = getCorrectDateFormat(action.payload[key], '', true);
            // }
            return Object.assign({}, state, {
                originalTiersActivationDates: action.payload,
                tiersActivationDates: action.payload//tiersActivationDates
            });

        default:
            return state;
    }
};

export default case_assign
