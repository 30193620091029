import React, { Component } from "react";
import { connect } from 'react-redux'
import shortId from 'shortid'
import FormField from '../../../components/FormField'
import { createNewDiagnose, editDiagnose, editField, isAllValid } from '../../../redux/actions/diagnosesActions'
import { getMultiSelectValue, getNameFromCode } from '../../../utils/helpers'
import PropTypes from "prop-types";
import { showValidation } from '../../../redux/actions/BasicEntityActions'

class DiagnoseForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nameLanguage: { value: 'EN', label: 'English' },
            notesLanguage: { value: 'EN', label: 'English' },
            firstLoad: 0
        };


        this.onChange = this.onChange.bind(this);
        this.onChangeSpecialty = this.onChangeSpecialty.bind(this);
        this.onChangeBodyParts = this.onChangeBodyParts.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.Submit = this.Submit.bind(this);
    }

    Submit(e) {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            let { systemToCode, names } = this.props.diagnose
            const medicalCodeCodes = Object.keys(systemToCode).reduce((p, c) => {
                return [...p, ...systemToCode[ c ]]
            }, [])

            const data = {
                ...this.props.diagnose,
                code: shortId.generate(),
                name: names[ 'EN' ],
                medicalCodingSystemCodes: medicalCodeCodes,
                specialtyCodes: getMultiSelectValue(this.props.diagnose.specialtyCodes),
                bodyPartCodes: getMultiSelectValue(this.props.diagnose.bodyPartCodes),
            }
            this.props.signUp(data);
        } else {
            this.props.showValidation();
        }
    }

    onChange(e) {
        this.setState({ showSuccess: false });
        this.props.editField(e.target.name, e.target.value)

    }

    onChangeName = (e) => {
        const { value } = e.target
        let names = this.props.diagnose.names || {}
        names = Object.assign({}, names, {
            [ this.state.nameLanguage.value ]: value
        })

        this.props.editField('names', names)
    }

    onChangeNotes = (e) => {
        const { value, name } = e.target
        let names = this.props.diagnose[ name ] || {}

        names = Object.assign({}, names, {
            [ this.state.notesLanguage.value ]: value
        })

        this.props.editField(name, names)
    }

    onChangeNameLanguage = (nameLanguage) => this.setState({ nameLanguage })

    onChangeNotesLanguage = (notesLanguage) => this.setState({ notesLanguage })

    // onChangeNameLanguage = (language) => this.setState({ language })

    onChangeSpecialty(specialtyCodes) {
        this.props.editField('specialtyCodes', specialtyCodes)
    }

    onChangeBodyParts(bodyPartCodes) {
        this.props.editField('bodyPartCodes', bodyPartCodes)
    }

    onChangeCheckBox(e) {
        this.props.editField(e.target.name, e.target.checked)
    }

    onChangeMedicalCodes = (system, codes) => {
        this.props.editField('systemToCode', {
            ...this.props.diagnose.systemToCode,
            [ system ]: getMultiSelectValue(codes)
        });
    }

    setNameValue = () => {
        const { names } = this.props.diagnose;
        const { nameLanguage } = this.state;

        return names && names[ nameLanguage.value ] ? names[ nameLanguage.value ] : ''
    }

    setNoteValue = (noteName) => {
        const note = this.props.diagnose[ noteName ];
        const { notesLanguage } = this.state;

        return note && note[ notesLanguage.value ] ? note[ notesLanguage.value ] : ''
    }

    render() {
        const { isEditable } = this.props;
        return (
            <div>
                <form
                    className="modal-content custom-modal-content"
                    onSubmit={this.Submit}
                    noValidate
                    autoComplete="off">
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="6"
                                label={this.context.translate('common.form.diagnosis')}
                                id={this.context.translate('common.form.diagnosis')}
                                className="form-control"
                                required
                                valids="required|unique"
                                validator={this.props.validator}
                                name="name"
                                value={this.setNameValue()}
                                onChange={this.onChangeName}
                                onBlur={this.props.dispatchValidState}
                                disabled={!isEditable}
                            />
                            <FormField
                                md="6"
                                type="REF_SPECIALTY"
                                label={this.context.translate('common.form.specialties')}
                                value={this.props.diagnose.specialtyCodes}
                                onChange={this.onChangeSpecialty}
                                name="specialtyCodes"
                                multi
                                onBlur={this.props.dispatchValidState}
                                disabled={!isEditable}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="6"
                                type="REF_BODY_PART"
                                label={this.context.translate('common.form.body_parts')}
                                name="bodyPartCodes"
                                value={this.props.diagnose.bodyPartCodes}
                                onChange={this.onChangeBodyParts}
                                multi
                                onBlur={this.props.dispatchValidState}
                                disabled={!isEditable}
                            />
                        </div>
                    </div>
                    <hr/>
                    <h4 style={{ color: '#61a12f ' }}>
                        Coding Systems
                    </h4>
                    <div className="form-group">
                        <div className="row">
                            {
                                this.props.mc_systems.map((mc) => (
                                    <FormField
                                        md="6"
                                        type="select"
                                        label={mc.name}
                                        title={mc.name}
                                        key={mc.code}
                                        name={'sCSC-' + mc.code}
                                        multi
                                        options={mc.medicalCodeCodes}
                                        value={this.props.diagnose.systemToCode[ mc.code ]}
                                        onChange={(codes) => this.onChangeMedicalCodes(mc.code, codes)}
                                        disabled={!isEditable}
                                    />
                                ))
                            }
                        </div>
                    </div>
                    <hr/>
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="6"
                                type="checkbox"
                                title={this.context.translate('common.form.status')}
                                label={this.context.translate('common.form.active')}
                                name='active'
                                value={this.props.diagnose.active}
                                checked={this.props.diagnose.active}
                                onChange={this.onChangeCheckBox}
                                className="checkbox"
                                disabled={!isEditable}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className='row'>
                            <FormField
                                md="6"
                                type='label_field'
                                label="Notes"
                                className='margin-bottom-15'
                                style={{ fontSize: '1.2em' }}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className='col-md-12 col-xs-6 fields'>
                                <label className="">
                                    {this.context.translate('common.form.medical_necessity')}
                                </label>
                                <textarea
                                    name="medicalNecessity"
                                    id=""
                                    cols="30"
                                    rows="5"
                                    value={this.setNoteValue('medicalNecessity')}
                                    onChange={this.onChangeNotes}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className='col-md-12 col-xs-6 fields'>
                                <label className="">
                                    {this.context.translate('common.form.medical_necessity_alternative')}
                                </label>
                                <textarea
                                    name="medicalNecessityAlternative"
                                    id=""
                                    cols="30"
                                    rows="5"
                                    value={this.setNoteValue('medicalNecessityAlternative')}
                                    onChange={this.onChangeNotes}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className='col-md-12 col-xs-6 fields'>
                                <label className="">
                                    {this.context.translate('common.form.references')}
                                </label>
                                <textarea
                                    name="noteReferences"
                                    id=""
                                    cols="30"
                                    rows="5"
                                    value={this.setNoteValue('noteReferences')}
                                    onChange={this.onChangeNotes}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className='col-md-12 col-xs-6 fields'>
                                <label className="">
                                    {this.context.translate('common.form.other')}
                                </label>
                                <textarea
                                    name="notes"
                                    id=""
                                    cols="30"
                                    rows="5"
                                    value={this.setNoteValue('notes')}
                                    onChange={this.onChangeNotes}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div
                            className="row no-padding"
                            style={{ display: this.props.diagnose.code ? 'none' : 'block' }}>
                            <div className="col-xs-4 pull-right">
                                <button
                                    className='btn btn-block btn-flat btn-success'
                                    disabled={!isEditable || this.props.isLoading}
                                >
                                    {this.context.translate('common.client.add_diagnosis')}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

DiagnoseForm.contextTypes = {
    translate: PropTypes.func
}

const allowToEdit = (role) => (role === 'ROLE_ADMIN' || role === 'ROLE_CLIENT_ADMIN');

const mapStateToProps = (state) => {
    return {
        diagnose: state.app.diagnoses.editedDiagnose,
        diagnoseLoaded: state.app.diagnoses.diagnoseLoaded,
        error: state.app.diagnoses.editGeneralError,
        isLoading: state.app.diagnoses.loading,
        isEditable: allowToEdit(state.app.auth.user.roleCode) || state.app.users.my_permissions.includes('MANAGE_SUR'),
        mc_systems: state.app.mcSystem.data
            .filter((item) => item.active && item.diagnosis)
            .map(item => {
                return {
                    ...item,
                    medicalCodeCodes: item.medicalCodeCodes
                        .filter((scCode) => {
                            const medicalCode = state.app.scSystem.data.find((item) => item.code === scCode)
                            return medicalCode && medicalCode.diagnosis && medicalCode.active
                        })
                        .map((scCode) => ({ value: scCode, label: getNameFromCode(scCode, state.app.scSystem.data) }))
                }
            }),
        countries: state.app.countries.data
            .map(country => ({
                value: country.code,
                label: country.name
            })),
        languages: state.app.languages.data
            .map(lang => ({
                value: lang.code,
                label: lang.name
            })),
        diagnoses: state.app.diagnoses.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signUp: (diagnose) => {
            dispatch(createNewDiagnose(diagnose))
        },
        saveProfile: data => dispatch(editDiagnose(data, 'admin')),
        editField: (field, value) => dispatch(editField(field, value)),
        isAllValid: (valid) => dispatch(isAllValid(valid)),
        showValidation: () => dispatch(showValidation('diagnose')),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnoseForm);
