import React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "utils/simple-react-validator";
import FormField from '../../components/FormField/index'
import {
    getFacilityType,
    editFacilityType,
    facilityTypeEditField,
    isAllValid,
} from '../../redux/actions/facilitiesActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../components/LoadingIndicator";
import Box from "../../components/Box";
import BoxHeader from "../../components/BoxHeader";
import BoxBody from "../../components/BoxBody";
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';
import SystemMessage from '../../components/SystemMessage';


class FacilityTypeEditPage extends React.Component {
    constructor (props) {
        super(props);
        let match = this.props;
        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This name already exists in the system.',
                rule: function(val) {
                    let facilities_names = [];
                    match.facilities_types.forEach((item) => {
                        if (item.code !== match.match.params.id) {
                            facilities_names.push(item.name);
                        }
                    });
                    return !facilities_names.includes(val);
                }
            }
        });
        this.validator.showMessages();

        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.dispatchValidState = this.dispatchValidState.bind(this);
    }

    componentDidMount() {
        this.props.getFacilityType(this.props.match.params.id)
    }

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onChangeCheckBox(e) {
        this.props.facilityTypeEditField([e.target.name], e.target.checked)
    }

    onChange(e) {
        this.props.facilityTypeEditField(e.target.name, e.target.value)
    }

    onSave = () => {
      return this.props.saveFacilityType({...this.props.facility_type,
          active:this.props.facility_type.active,
          code: this.props.facility_type.code
      })
    }

    render () {
        const { isFacilityTypeChanged, editFacilityTypeSuccess, error, facilityTypeValidMessages, isEditable } = this.props;
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
                  id:field,
                  field,
                  message: this.validator.errorMessages[field],
                }))

        return (
            <div className="content" style={{ marginTop:'10px'}}>
              <PreventTransitionPrompt
                when={isFacilityTypeChanged}
                message='MyCustomDialogComponent'
                isFormValid={this.props.isValid}
                entity='facility_type'
                saveChanges={this.onSave}
                cancelAndDiscard={() => this.props.getFacilityType(this.props.facility_type.code)}

                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <SystemMessage
                  shown={editFacilityTypeSuccess && !isFacilityTypeChanged}
                  type='success'
                  message='Facility Type successfully updated'
                  />

                <SystemMessage
                  shown={facilityTypeValidMessages}
                  type='error' message={error}
                  validationMessages={errorMessages}
                  />

                <Box color = "green" classes="box-profile-first-row-height-show">
                    <BoxHeader title={this.context.translate('common.form.facility_type') + ' - ' + this.props.facility_type.name } color="green"
                               icon="fa-list-alt" classes="box-profile-first-row-height-show"/>
                    <BoxBody>
                        <form noValidate autoComplete="off">
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        label={this.context.translate('common.form.name')}
                                        id={this.context.translate('common.form.name')}
                                        required
                                        valids='required|unique'
                                        validator={this.validator}
                                        value={this.props.facility_type.name}
                                        onChange={this.onChange}
                                        name='name'
                                        onBlur={this.dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        type="checkbox"
                                        title={this.context.translate('common.form.status')}
                                        label={this.context.translate('common.form.active')}
                                        name='active'
                                        value={this.props.facility_type.active}
                                        checked={this.props.facility_type.active}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        onBlur={this.dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </form>
                    </BoxBody>
                </Box>
            </div>
        );
    }
}

FacilityTypeEditPage.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => ({
    isLoading: state.app.countries.loading,
    isEditable: state.app.users.my_permissions.includes('MODIFY_FACILITIES'),
    error: state.app.facilities.error,
    editFacilityTypeSuccess: state.app.facilities.editFacilityTypeSuccess,
    facilityTypeValidMessages: state.app.facilities.facilityTypeValidMessages,
    isFacilityTypeChanged: state.app.facilities.isFacilityTypeChanged,
    facilities_types: state.app.facilities.types,
    facility_type: state.app.facilities.editedFacilityType,
    isValid: state.app.facilities.facilityValid
})

const mapDispatchToProps = dispatch => ({
    getFacilityType: code => dispatch(getFacilityType(code)),
    facilityTypeEditField: (field, value) => dispatch(facilityTypeEditField(field, value)),
    editFacilityType: (facility_type) => {
        dispatch(editFacilityType(facility_type))
    },
    isAllValid: ( valid ) => dispatch(isAllValid( valid )),
    saveFacilityType: data => dispatch(editFacilityType(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FacilityTypeEditPage)
