import React from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {setCaseMessage, disableReviewerButtons} from '../../../redux/actions/cases/caseActions'
import {caseUpdateAssignment} from '../../../redux/actions/cases/caseAssignActions'
import {withRouter} from "react-router-dom";

class CaseReject extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.rejectCase = this.rejectCase.bind(this);
    }

    rejectCase(e) {
        e.preventDefault();
        let aDetails = this.props.assignmentDetails;
        let detailCode = '';
        for (let i = 0; i < aDetails.length; i++) {
            if (aDetails[i].reviewerCode === this.props.currentUser.code) {
                detailCode = aDetails[i].code;
            }
        }
        if (detailCode !== '') {
            this.props.caseUpdateAssignment(detailCode, 'REJECTED').then(() => {
                this.props.disableReviewerButtons(true);
                this.props.setCaseMessage({
                    'text': 'Case successfully rejected',
                    'type': 'success'
                });
                setTimeout(() => {
                    this.props.disableReviewerButtons(false);
                    this.props.history.goBack();
                }, 3000);
            });
        }
    }

    render() {
        let disabled = this.props.disabledReviewerButtons || this.props.loading;
        return (
            <span>
                <button
                    disabled={disabled}
                    className="btn resizable-button tooltip-button margin-left-5"
                    onClick={this.rejectCase}>
                    {this.context.translate('common.cases.reject')}
                </button>
            </span>
        )
    }
}

CaseReject.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state) => {
    return {
        loading: state.app.cases.loading,
        currentUser: state.app.auth.user,
        case: state.app.cases.editedCase,
        assignmentDetails: state.app.case_assign.appliedReviewers,
        disabledReviewerButtons: state.app.cases.disabledReviewerButtons
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setCaseMessage: (data) => dispatch(setCaseMessage(data)),
        disableReviewerButtons: (state) => dispatch(disableReviewerButtons(state)),
        caseUpdateAssignment: (detailCode, action) => dispatch(caseUpdateAssignment(detailCode, action))
    }
};

CaseReject = withRouter(CaseReject);
export default connect(mapState, mapDispatchToProps)(CaseReject);
