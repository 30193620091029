import createLinkingConnectedDataTableSlice from "../../utils/createLinkingConnectedDataTableSlice";

const clientEditPhysicianSlice = createLinkingConnectedDataTableSlice(
    'clientEditPhysician',
    'firstName'
);

export const {
    loadData,
    linkingEntities, unlinkingEntities, linkingEntitiesSuccess, linkingEntitiesError,
} = clientEditPhysicianSlice.actions;

export const clientEditPhysicianSliceReducer = clientEditPhysicianSlice.reducer;

export default clientEditPhysicianSlice;
