import React from 'react';
import indicatorSVG from '../../images/loading-indicator.svg';

const DataTableLoadingIndicator = () => {
  return (
    <div className="datatable__loading-indicator">
      <img alt="Loading..." src={indicatorSVG} />
    </div>
  );
};

export default DataTableLoadingIndicator;
