import { connect } from 'react-redux'
import { getAllCaseLevels } from '../../redux/actions/cases/caseTypesActions'
import RefField from './RefField'
import {getOne4Select, setLoadedCount} from "../../redux/actions/BasicEntityActions";

const mapStateToProps = (state) => ({
    isLoading:  state.app.case_types.loading_level,
    loadedCount: state.app.case_types.loadedCount_level,
    options: state.app.case_types.levels
        .filter((item) => item.active)
        .map(item => ({
            value: item.code,
            label: item.displayName['EN']
        })),
})

const mapDispatchToProps = (dispatch) => ({
    getOptions: () => dispatch(getAllCaseLevels()),
    getOne4Select: (code) => dispatch(getOne4Select(code, 'case_level')),
    setLoadedCount: (count) => dispatch(setLoadedCount(count, 'case_level'))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefField)
