import api from "../../utils/api";
import {fieldsErrors} from "./statuses";
import history from '../history'

export const getAllFields = () => (dispatch) => {
    dispatch({
        type: 'FETCH_FIELDS_LOADING',
    });

    api.get('field/all')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_FIELDS_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_FIELD_ERROR',
                    payload: fieldsErrors[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
                dispatch({
                    type: 'FETCH_FIELD_ERROR',
                    payload: fieldsErrors[error.request.status]
                })
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_FIELD_ERROR',
                    payload: error.message
                });
            }
        })
};

export const getField = code => dispatch => {
    dispatch({
        type: 'FETCH_FIELDS_LOADING',
    });
    dispatch({
        type: 'SET_CURRENT_FIELD',
    });

    return api.get(`field/get?code=${code}`)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_FIELD_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_FIELD_ERROR',
                    payload: fieldsErrors[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
                dispatch({
                    type: 'FETCH_FIELD_ERROR',
                    payload: error.request.message
                });
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_FIELD_ERROR',
                    payload: error.message
                });
            }
        })
};

export const createNewField = (field) => (dispatch) => {
    dispatch({
        type: 'FETCH_FIELDS_LOADING',
    });

    return new Promise((resolve, reject) => {
        api.post('field/create', field)
            .then(() => {
                dispatch({
                    type: 'FETCH_CREATE_FIELD_SUCCESS',
                    payload: field
                });
                resolve()
            })
            .catch((error) => {
                if (error.response) {
                    dispatch({
                        type: 'FETCH_CREATE_FIELD_ERROR',
                        payload: fieldsErrors[error.response.status]
                    })
                } else if (error.request) {
                    console.log(error.request);
                    dispatch({
                        type: 'FETCH_CREATE_FIELD_ERROR',
                        payload: error.message
                    });
                } else {
                    console.log('Error ', error.message);
                    dispatch({
                        type: 'FETCH_CREATE_FIELD_ERROR',
                        payload: error.message
                    });
                }
                reject(error)
            })
    })
};

export const updateField = (data, closeAfterSave) => dispatch => {
    dispatch({
        type: 'FETCH_FIELDS_LOADING',
    });

    return api.post('field/update', data)
        .then(({config}) => {
            dispatch({
                type: 'FETCH_EDIT_FIELD_SUCCESS',
                payload: JSON.parse(config.data)
            });
            closeAfterSave && history.goBack();
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'FETCH_EDIT_FIELD_ERROR',
                payload: fieldsErrors[err.response ? err.response.status : err]
            })
        })
};

export const setCurrentField = (field) => {
    return {
        type: 'SET_CURRENT_FIELD',
        payload: field
    }
};

export const editField = (field, value) => ({
    type: 'FIELD_EDIT_FIELD',
    payload: {
        [field]: value
    }
});

export const isAllValid = valid => {
    return {
        type: 'VALIDATE_FIELD_FIELD',
        payload: valid
    }
};

export const dispatchValidStatus = validStatus => {
    return {
        type: 'SHOW_FIELD_VALIDATION',
        payload: validStatus
    }
};
