
const defaultState = {
    loading: false,
    loadedCount: 0,
    data: [],
    error: ''
}

const procedures = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_GENDER_LOADING':
            return { ...state, loading: true }
        case 'FETCH_GENDER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null
            })

        case 'FETCH_GENDER_4SELECT_SUCCESS':
            return { ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload ]
            }
        case 'FILL_GENDER_LOCAL_STORAGE':
            let items = {};
            for (let i = 0; i < state.data.length; i++) {
                items[state.data[i].code] = state.data[i].name;
            }
            localStorage.setItem('list_gender', JSON.stringify(items));
            return state;
        case 'FETCH_GENDER_LOADED_COUNT':
            return {...state, loadedCount: action.payload}

        case 'FETCH_GENDER_ERROR':
            return {...state, error: action.payload, loading: false}
        default:
    }

    return state
}

export default procedures
