import React, { useEffect } from 'react';
import { addWeekdays, addWeekdaysAutistic } from '../../utils/case-helpers';

let moment = require('moment');

const DevelopTestPage = () => {

  useEffect(() => {
    //const cdd = onChangeTat(2, {}, true);

    let defTime = new Date('2021-02-20 12:00');
    defTime = moment(defTime).format('H:mm');
    console.log('1>', defTime);

    const numDays = 100;

    console.time('1');
    const mom = addWeekdaysAutistic(new Date(), numDays, defTime, true);
    console.timeEnd('1');
    console.log('2>', mom);

    console.time('2');
    addWeekdays(new Date(), numDays, defTime, true);
    console.timeEnd('2');

  })

  return (
    <div className="content">
      <h3>Develop Sandbox Page</h3>
    </div>
  );
};

export default DevelopTestPage;
