// import React from 'react';
import connect from 'react-redux/es/connect/connect';
import CaseButton from './CaseButton';
import { reopenCaseFromGrid } from '../../../redux/actions/cases/caseListActions';
import { patientCaseStages } from 'redux/pages/patient-case/patientCaseStages';

const isPathAllowed = (path) => {
    switch (path) {
        case '/':
            return true;
        case '/home':
            return true;
        case '/all_cases':
            return true;
        case '/complete':
            return true;
        case '/closed':
            return true;
        default:
            return false;
    }
};

const isCaseClosed = (code, caseList) => {
    if (!code || !caseList) return false;

    let f_item = caseList.find((item) => item.code === code);
    if (typeof f_item === 'undefined') return false;
    const stage = f_item.stage;

    return stage === patientCaseStages.CLOSED;
};

const getTitle = (lengthCondition, closedCondition) => {
    if (lengthCondition) return 'You should select only one case';

    if (closedCondition) return 'Selected case should be closed';

    return 'Reopen';
};

const mapState = (state) => {
    const { pathname } = state.router.location;
    const { selectedCaseCodes } = state.app.case_list;

    const all = Object.values(state.app.patientCase).reduce((all, current) => [...all, ...current.data], []);

    const isOne = selectedCaseCodes.length === 1;
    const isClosed = isCaseClosed(selectedCaseCodes[0], all);

    return {
        className: 'btn-default margin-left-5',
        isVisible: isPathAllowed(pathname) && state.app.auth.user.roleCode !== 'ROLE_REVIEWER',
        icon: 'fa-reply',
        title: getTitle(!selectedCaseCodes.length, !isClosed),
        needConfirmation: true,
        disabled: !isOne || !isClosed,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClick: () => dispatch(reopenCaseFromGrid()),
    };
};

export default connect(mapState, mapDispatchToProps)(CaseButton);
