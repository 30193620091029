import { createApiParams } from 'components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import notificationTemplateSlice, {
    fetchEntitySuccess,
    updateEntitySuccess,
    createEntitySuccess,
    fetchEntityError,
} from './notificationTemplateSlice';
import { fetchEntity$, createRedirectEpic$, fetchEntityList$, createEntityEpic$, updateEntityEpic$ } from 'redux/epicUtils/commonEpics';
import { transformNotificationTemplate, transformNotificationTemplates } from './utils';
import history from '../../history';
import { omit } from 'lodash';

const loadNotificationTemplatesEpic = fetchEntityList$({
    ...notificationTemplateSlice.actions,
    resolveUrl: () => 'notification_template/page',
    resolveParams: (params) => [{ params: createApiParams(params) }],
    dataTransformer: transformNotificationTemplates,
});

const loadNotificationTemplateEpic = fetchEntity$({
    ...notificationTemplateSlice.actions,
    resolveUrl: ({ id }) => `notification_template/get?id=${id}`,
    fetchEntitySuccess: (data) => fetchEntitySuccess(transformNotificationTemplate(data)),
});

const createNotificationTemplateEpic = createEntityEpic$({
    ...notificationTemplateSlice.actions,
    resolveUrl: () => `notification_template/create`,
    createEntitySuccess: (data) => createEntitySuccess(transformNotificationTemplate(data)),
});

const updateNotificationTemplateEpic = updateEntityEpic$({
    ...notificationTemplateSlice.actions,
    resolveUrl: () => `notification_template/update`,
    resolveParams: (params) => {
        console.log('resolveParams', params);
        return [omit(params, 'code')]},
    updateEntitySuccess: (data) => updateEntitySuccess(transformNotificationTemplate(data)),
});

const redirectAfterUpdate = createRedirectEpic$(updateEntitySuccess, ({ closeAfterSave }) => {
    if (closeAfterSave) {
        history.goBack();
    }
});

const redirectAfterCreateEpic = createRedirectEpic$(createEntitySuccess, ({ id }) => {
    history.push(`/notification_templates/update/${id}`);
});

const redirectIfFetchErrorEpic = createRedirectEpic$(fetchEntityError, () => {
    history.push(`/notification_templates`);
});

export default combineEpics(
    loadNotificationTemplateEpic,
    loadNotificationTemplatesEpic,
    redirectAfterUpdate,
    redirectAfterCreateEpic,
    updateNotificationTemplateEpic,
    createNotificationTemplateEpic,
    redirectIfFetchErrorEpic,
);
