import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';

const facilitySlice = createDatatableTemplateSlice({
    name: 'facility',
    initialSettings: {
        sortBy: 'name',
    },
});

export const { loadData, dataLoaded, dataLoadError, changeSettings } = facilitySlice.actions;

export default facilitySlice;
