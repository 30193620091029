import React from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import SubmitButton from '../../../../components/SubmitButton'
import {
    createFieldAndInstance,
    createFieldInstance,
    dispatchFIValidStatus
} from '../../../../redux/actions/templateActions'
import convertFieldData from '../../../../utils/fields'

class CreateFieldInstanceButton extends React.Component {

    onSave = (e) => {
        e.preventDefault();
        const {field, dispatchFIValidStatus} = this.props;

        if (this.props.fieldValid) {
            dispatchFIValidStatus(true);
            const data = convertFieldData(field);

            data.originalFieldCode ? this.props.createFieldInstance({
                ...data,
                code: data.originalFieldCode + '_' + this.props.templateCode
            }) : this.props.createFieldAndInstance(data)
        } else dispatchFIValidStatus(false)

    };

    render() {
        return <SubmitButton color='blue' title='Add Field Instance' onSubmit={this.onSave}
                             showTooltip={!this.props.fieldValid}/>
    }
}

CreateFieldInstanceButton.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    field: state.app.templates.editedField,
    fieldValid: state.app.templates.fieldValid,
    templateCode: state.app.templates.code,
});

const mapDispatchToProps = dispatch => ({
    createFieldInstance: data => dispatch(createFieldInstance(data)),
    createFieldAndInstance: data => dispatch(createFieldAndInstance(data)),
    dispatchFIValidStatus: data => dispatch(dispatchFIValidStatus(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFieldInstanceButton)
