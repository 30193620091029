import { connect } from 'react-redux'
import { getAllCurrencies } from '../../redux/actions/currencyActions'
import RefField from './RefField'
import {getOne4Select, setLoadedCount} from "../../redux/actions/BasicEntityActions";

const mapStateToProps = (state) => ({
    isLoading:  state.app.currencies.loading,
    loadedCount: state.app.currencies.loadedCount,
    options: state.app.currencies.data
        .filter((item) => item.active)
        .map(spec => ({
            value: spec.code,
            label: spec.fullName
        })),
})

const mapDispatchToProps = (dispatch) => ({
    getOptions:  () =>  dispatch(getAllCurrencies()),
    getOne4Select: (code) => dispatch(getOne4Select(code, 'currency')),
    setLoadedCount: (count) => dispatch(setLoadedCount(count, 'currency'))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefField)
