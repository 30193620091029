import { getLanguagedNameFromCode, isClient, isReviewer } from 'utils/helpers';

const patientCaseTransform = (state) => ({ data: cases, ...rest }) => {
    const currentUser = state.app.auth.user;
    const isOnlyWord = !isClient(currentUser.roleCode) && !isReviewer(currentUser.roleCode);

    return {
        data: cases.map((item) => {
            return {
                ...item,
                determination: getLanguagedNameFromCode(item.determinationCode, state.app.case_determination.data, 'EN'),
                isOnlyWord: isOnlyWord,
            };
        }),
        ...rest,
    };
};

export default patientCaseTransform;
