import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toolTipId } from '../../utils/helpers';
import DataTablecontext from './DataTableContext';
import classnames from 'classnames';

const DataTableRow = ({
  id,
  selected,
  rowData,
  onClick,
  customActions,
  onDelete,
  formatters,
  deleteDisabled,
  deleteLoading,
}) => {
  const { selectable, onRowSelect, columnKeys, columns } = useContext(
    DataTablecontext,
  );

  return (
    <tr
      className={classnames([
        'datatable__row',
        { datatable__clickable: !!onClick },
      ])}
      onClick={onClick}
    >
      {selectable && (
        <td
          className="datatable__row datatable-select"
          onClick={(event) => event.stopPropagation()}
        >
          <input
            type="checkbox"
            checked={selected}
            onChange={() => onRowSelect(id, rowData)}
          />
        </td>
      )}
      {columnKeys.map((columnKey) => (
        <td
          className="datatable__row__td"
          key={columnKey}
          style={columns[columnKey].tdStyle}
          title={formatters[columnKey](rowData[columnKey], rowData)}
        >
          {formatters[columnKey](rowData[columnKey], rowData)}
        </td>
      ))}

      {customActions && (
        <td
          className="datatable__actions"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {customActions(rowData)}
        </td>
      )}
      {onDelete && (
        <td className="datatable__remove">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={toolTipId('dt-remove')}>Remove</Tooltip>}
          >
            <Button
              disabled={deleteDisabled && !deleteLoading}
              bsStyle="danger"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(rowData);
              }}
            >
              {deleteLoading ? (
                <i className="fa fa-cog fa-spin" />
              ) : (
                <i className="fa fa-trash" />
              )}
            </Button>
          </OverlayTrigger>
        </td>
      )}
    </tr>
  );
};

DataTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  customActions: PropTypes.func,
  onDelete: PropTypes.func,
  deleteDisabled: PropTypes.bool.isRequired,
  deleteLoading: PropTypes.bool.isRequired,
  formatters: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selected: PropTypes.bool,
};

DataTableRow.defaultProps = {
  customActions: null,
};

export default DataTableRow;
