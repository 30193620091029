import React, { Component } from "react";
import { connect } from 'react-redux'
import SimpleReactValidator from "../../utils/simple-react-validator";
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import FormField from '../../components/FormField'
import { clearBillingGroup, editCompany, editField, isAllValid } from '../../redux/actions/companiesActions'
import { isClient } from '../../utils/helpers'

class ClientEditBillingInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstLoad: true,
            accountingEmail: '',
            currencyCode: null,
            contractDate: null,
            firstInvoiceDate: null,
            rolesAllowedToReopenCodes: [],
            billingGroupCodes: [],
            showSuccess: false
        };

        this.validator = new SimpleReactValidator();

        this.onChange = this.onChange.bind(this);
        this.onChangeCurrencyCode = this.onChangeCurrencyCode.bind(this);
        this.onChangeFirstInvoiceDate = this.onChangeFirstInvoiceDate.bind(this);

        this.onChangeContractDate = this.onChangeContractDate.bind(this);
    }

    onChange(e) {
        this.setState({ showSuccess: false });
        this.props.editField(e.target.name, e.target.value);
    }

    onChangeCurrencyCode(currencyCode) {
        this.setState({ showSuccess: false });
        this.props.editField('currencyCode', currencyCode);
        this.props.clearBillingGroup();
    }

    onChangeContractDate(contractDate) {
        this.setState({ showSuccess: false });
        this.props.editField('contractDate', contractDate)
    }

    onChangeFirstInvoiceDate(firstInvoiceDate) {
        this.setState({ showSuccess: false });
        this.props.editField('firstInvoiceDate', firstInvoiceDate)
    }


    render() {
        const { showSuccess } = this.state;
        const {
            accountingFirstName,
            accountingLastName,
            accountingPhone,
            alternativePhoneNumber,
            accountingEmail,
            vatId,
            margin,
            contractDate,
            firstInvoiceDate,
            currencyCode,
            fullName
        } = this.props.profile;

        const { isEditable } = this.props;

        return (
            <Box color="green" classes="box-profile-first-row-height-show">
                <BoxHeader title={`Client - ${fullName}`} color="green" icon="fa-credit-card"/>
                <BoxBody>
                    <p className="login-box-msg">
                        {this.props.error ?
                            <span className="text-danger">{this.props.error} </span>
                            :
                            (
                                showSuccess ?
                                    <span className="text-success">Profile successfully updated </span>
                                    : ""
                            )
                        }
                    </p>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <div className="row">
                                <FormField
                                    md="4"
                                    label="Accounting First Name"
                                    className="form-control"
                                    id="user_edit_reviewer_iban"
                                    divClasses="show"
                                    name="accountingFirstName"
                                    value={accountingFirstName}
                                    onChange={this.onChange}
                                    disabled={!isEditable}
                                />
                                <FormField
                                    md="4"
                                    label="Accounting Last Name"
                                    className="form-control"
                                    id="user_edit_reviewer_iban"
                                    divClasses="show"
                                    name="accountingLastName"
                                    value={accountingLastName}
                                    onChange={this.onChange}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <FormField
                                    md="4"
                                    type="input-icon"
                                    icon="phone"
                                    label="Accounting Phone Number"
                                    className="form-control"
                                    id="user_edit_phoneNumber"
                                    name="accountingPhone"
                                    value={accountingPhone}
                                    onChange={this.onChange}
                                    disabled={!isEditable}
                                />
                                <FormField
                                    md="4"
                                    type="input-icon"
                                    icon="phone"
                                    label="Alternative Phone Number"
                                    className="form-control"
                                    id="user_edit_phoneNumber"
                                    name="alternativePhoneNumber"
                                    value={alternativePhoneNumber}
                                    onChange={this.onChange}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <FormField
                                    md="4"
                                    icon="envelope-o"
                                    type="input-icon"
                                    label="Accounting Email"
                                    id="Accounting Email"
                                    className="form-control"
                                    name="accountingEmail"
                                    valids="custom_email"
                                    validator={this.props.validator}
                                    value={accountingEmail}
                                    onChange={this.onChange}
                                    onBlur={this.props.dispatchValidStatus}
                                    disabled={!isEditable}
                                />
                                <FormField
                                    md="4"
                                    label="VAT ID"
                                    className="form-control"
                                    id="user_edit_reviewer_accountNumber"
                                    divClasses="hide"
                                    name="vatId"
                                    value={vatId}
                                    onChange={this.onChange}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <FormField
                                    md="4"
                                    type='input-icon'
                                    icon='percent'
                                    label="Hourly Margin"
                                    id="Hourly Margin"
                                    className="form-control"
                                    required
                                    valids="required|integer"
                                    validator={this.props.validator}
                                    divClasses="show"
                                    name="margin"
                                    value={margin}
                                    onChange={this.onChange}
                                    onBlur={this.props.dispatchValidStatus}
                                    disabled={!isEditable}
                                />
                                <FormField
                                    type='DATE'
                                    name="contractDate"
                                    md="4"
                                    label="Contract Date"
                                    className="text-bg  "
                                    value={contractDate}
                                    onChange={this.onChangeContractDate}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <FormField
                                    type='DATE'
                                    name="firstInvoiceDate"
                                    md="4"
                                    label="First Invoice Date"
                                    className="text-bg  "
                                    value={firstInvoiceDate}
                                    onChange={this.onChangeFirstInvoiceDate}
                                    disabled={!isEditable}
                                />
                                <FormField
                                    md="4"
                                    type="REF_CURRENCY"
                                    label="Currency"
                                    id="Currency"
                                    valids="required"
                                    validator={this.props.validator}
                                    value={currencyCode}
                                    onChange={this.onChangeCurrencyCode}
                                    onBlur={this.props.dispatchValidStatus}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                    </form>
                </BoxBody>
                <BoxFooter>
                </BoxFooter>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // profile: state.app.companies.users.filter(usr => usr.code == getParamFromUrl(state.router.location.pathname))[0],
        profile: state.app.companies.editedCompany,
        error: state.app.companies.editBillingError,
        isLoading: state.app.companies.loading,
        countries: state.app.countries.data
            .map(country => ({
                value: country.code,
                label: country.name
            })),
        languages: state.app.languages.data
            .map(lang => ({
                value: lang.code,
                label: lang.name
            })),
        locales: state.app.locales.data
            .map(locale => ({
                value: locale.code,
                label: locale.displayName
            })),
        currencies: state.app.currencies.data
            .map(currency => ({
                value: currency.code,
                label: currency.fullName
            })),
        roles: state.app.users.roles
            .filter((item) => isClient(item.code))
            .map(role => ({
                value: role.code,
                label: role.name.toLowerCase()
            })),
        billingGroupCodes: state.app.billing.codes
            .map((billing) => ({
                value: billing.code,
                label: billing.name
            }))

    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveProfile: data => dispatch(editCompany(data, 'admin')),
        editField: (field, value) => dispatch(editField(field, value)),
        clearBillingGroup: () => dispatch(clearBillingGroup()),
        isAllValid: (valid) => dispatch(isAllValid(valid)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientEditBillingInfo);
