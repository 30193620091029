import { connect } from 'react-redux'
import { getAllUsers } from '../../redux/action-creators/users'
import RefField from './RefField'
import { isUser } from '../../utils/helpers'
import {getOne4Select, setLoadedCount} from "../../redux/actions/BasicEntityActions";

const mapStateToProps = (state) => ({
    isLoading:  state.app.users.loading,
    loadedCount: state.app.users.loadedCount,
    options: state.app.users.users
        .filter((item) => isUser(item.roleCode)  && (item.active == null || item.active === true))
        .map(item => ({
            value: item.code,
            label: item.firstName + ' ' + item.lastName
        })),
})

const mapDispatchToProps = (dispatch) => ({
    getOptions: () => dispatch(getAllUsers()),
    getOne4Select: (code) => dispatch(getOne4Select(code, 'user')),
    setLoadedCount: (count) => dispatch(setLoadedCount(count, 'user'))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefField)
