import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'
import PropTypes from "prop-types";
import { editCountry } from '../../../redux/actions/countryActions'

class SaveCountry extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        e.preventDefault();
        this.props.saveCountry({
            ...this.props.country,
            active: this.props.country.active,
            code: this.props.country.code
        }, closeAfterSave)
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={this.onSave}
                />
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={(e) => this.onSave(e, true)}
                    title={'Save And Close'}
                />
            </React.Fragment>
        )
    }
}

SaveCountry.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    country: state.app.countries.editedCountry,
    isProfileChanged: state.app.countries.isProfileChanged,
    isEditable: state.app.users.my_permissions.includes('MODIFY_COUNTRIES'),
});

const mapDispatchToProps = dispatch => ({
    saveCountry: (data, closeAfterSave) => dispatch(editCountry(data, closeAfterSave))
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveCountry)
