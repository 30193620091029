import React from 'react'

export default ({onChange, value, name}) => {
    const fontWeight = 500;

    return (
        <span style={{marginLeft: '30px'}}>
            <div className="radio-inline">
              <label style={{fontWeight}}><input
                  type="radio"
                  name={name}
                  onChange={onChange}
                  checked={value === "ALL"}
                  value='ALL'/>All</label>
            </div>
            <div className="radio-inline">
              <label style={{fontWeight}}>
              <input
                  type="radio"
                  name={name}
                  onChange={onChange}
                  checked={value === "ANY"}
                  value='ANY'/>Any
              </label>
            </div>
        </span>
    )
}
