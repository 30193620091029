import React from 'react';
import { dispatch } from 'redux/pages/patient-case/patientCaseColumns';
import dispatchSlice from 'redux/pages/patient-case/slices/dispatchSlice';
import PatientCaseGrid from '../Components/PatientCaseGrid';

const columns = dispatch;

const Dispatch = (props) => (
  <PatientCaseGrid
    variant="warning"
    columns={columns}
    slice={dispatchSlice}
    {...props}
  />
);

export default Dispatch;
