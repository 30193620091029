import React from 'react';
import {connect} from 'react-redux';
import SubmitButton from '../../components/SubmitButton'
import PropTypes from "prop-types";
import {getMyProfile, saveProfile, saveSignature} from "../../redux/actions/profileActions";
import {showValidation} from '../../redux/actions/BasicEntityActions'
import {getSelectFieldValue, getMultiSelectValue, isReviewer} from '../../utils/helpers'

class SaveProfileButton extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        if (this.props.isProfileValid) {
            e.preventDefault();
            let data = {
                ...this.props.profile,
                active: true,
                email: this.props.profile.email.trim(),
                notificationEmail: this.props.profile.notificationEmail.trim(),
                timeZoneCode: getSelectFieldValue(this.props.profile.timeZoneCode),
                countryCode: getSelectFieldValue(this.props.profile.countryCode),
                stateCode: getSelectFieldValue(this.props.profile.stateCode),
                specialtyCodes: getMultiSelectValue(this.props.profile.specialtyCodes),
                languageCodes: getMultiSelectValue(this.props.profile.languageCodes),
                defaultLanguageCode: getSelectFieldValue(this.props.profile.defaultLanguageCode),
                defaultLocaleCode: getSelectFieldValue(this.props.profile.defaultLocaleCode)
            };

            this.props.saveProfile(data, closeAfterSave);
            let this_props = this.props;
            if (isReviewer(this.props.profile.roleCode) && this_props.profile.savedSignature[0]) {
                setTimeout(() => {
                    this_props.saveSignature({
                        code: this_props.profile.code,
                        file: this_props.profile.savedSignature[0]
                    }).then(() => {
                        this_props.getProfile()
                    })
                }, 500);
            }
        } else {
            this.props.showValidation()
        }
    }

    render() {
        let disabled = !this.props.isProfileChanged
            && !(isReviewer(this.props.profile.roleCode) && this.props.profile.savedSignature[0]);
        return (
            <React.Fragment>
                <SubmitButton disabled={disabled} color='blue' onSubmit={this.onSave}
                              showTooltip={!this.props.isProfileValid}/>
                <SubmitButton disabled={disabled} color='blue'
                              onSubmit={(e) => this.onSave(e, true)} title='Save And Close'
                              showTooltip={!this.props.isValid}/>
            </React.Fragment>
        )
    }
}

SaveProfileButton.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    isProfileChanged: state.app.profile.isProfileChanged,
    profile: state.app.profile.data,
    error: state.app.profile.error,
    isProfileValid: state.app.profile.profileValid
});

const mapDispatchToProps = dispatch => ({
    saveProfile: (data, closeAfterSave) => dispatch(saveProfile(data, closeAfterSave)),
    saveSignature: data => dispatch(saveSignature(data)),
    showValidation: () => dispatch(showValidation('profile')),
    getProfile: () => dispatch(getMyProfile())
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveProfileButton)
