import React from "react";
import {connect} from 'react-redux'
import {changePasswordExpiration, resetErrors} from '../../redux/actions/authActions'
import FormField from '../../components/FormField'
import SimpleReactValidator from "utils/simple-react-validator";
import SystemMessage from '../../components/SystemMessage';

class ExpirationPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: ''
        }
        this.validator = new SimpleReactValidator({
            password: {
                message: 'The password must contain at least one uppercase character, at least one digit and at least one special character.',
                rule: function (val) {
                    return this._testRegex(val,
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{8,}$/)
                }
            },
            equal: {
                message: 'New Passwords must be equal',
                rule: () => this.state.newPassword === this.state.repeatNewPassword
            }
        });
        this.validator.showMessages();

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }


    onSubmit(e) {
        e.preventDefault();

        if (this.validator.allValid()) {
            this.setState({errors: {}, isLoading: true});
            this.props.expirePassword(this.state).then(
                () => {
                    this.props.history.push(`/login`);
                },
                (err) => this.setState({errors: err, isLoading: false})
            );
        } else {
            this.setState({messagesShown: true})
        }
    }

    dispatchValidState = () => {
        const {fields} = this.validator;
        this.props.isPasswordValid(Object.keys(fields).filter(this.needPassword).reduce((p, c) => p && fields[c], true));
        this.validator.showMessages();
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
        setTimeout(() => {
            this.validator.showMessages();
            this.forceUpdate();
        }, 500);
    }

    render() {
        const {isLoading, firstLoad} = this.state;
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
        }))
        if (this.props.error && !firstLoad) {
            setTimeout(() => {
                this.props.resetErrors()
            }, 5000);
        }

        return (
            <React.Fragment>
                <SystemMessage
                    shown={this.state.messagesShown}
                    type='error'
                    validationMessages={errorMessages}
                />
                <div>
                    <p className="login-box-msg" style={{wordBreak: 'normal'}}>
                        {(this.props.error && !firstLoad) ?
                            <span className="text-danger">{this.props.error} </span>
                            :
                            (
                                this.props.status ?
                                    <span className="text-success" style={{wordBreak: 'normal'}}>{this.props.status} </span>
                                    :
                                    "Your password has expired and must be changed"
                            )
                        }
                    </p>
                    <form onSubmit={this.onSubmit} noValidate className='wide-form'>
                        <div className="form-group ">
                            <div className="row">
                                <FormField
                                    md="12"
                                    type="email"
                                    component="text"
                                    required
                                    label="Email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    valids="required"
                                    validator={this.validator}
                                    value={this.state.email}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="row">
                                <FormField
                                    md="12"
                                    type="password"
                                    component="text"
                                    required
                                    label="Current Password (for confirmation)"
                                    className="form-control"
                                    id="oldPassword"
                                    name="oldPassword"
                                    valids="required"
                                    validator={this.validator}
                                    value={this.state.oldPassword}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="row">
                                <FormField
                                    md="12"
                                    type="password"
                                    required
                                    label="New Password"
                                    className="form-control"
                                    id="newPassword"
                                    name="newPassword"
                                    valids="required|min:8|password"
                                    validator={this.validator}
                                    value={this.state.newPassword}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="row">
                                <FormField
                                    md="12"
                                    type="password"
                                    required
                                    label="Repeat Password"
                                    className="form-control"
                                    id="repeatNewPassword"
                                    name="repeatNewPassword"
                                    valids="required|min:8|password|equal"
                                    validator={this.validator}
                                    value={this.state.repeatNewPassword}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div className="row no-padding">
                            <div className="col-xs-4 pull-right">
                                <button
                                    disabled={isLoading}
                                    className="btn btn-primary btn-block btn-flat">
                                    Send
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

const mapState = (state) => {
    return {
        profile: state.app.profile.data,
        status: state.app.auth.status,
        error: state.app.auth.errorExpiration
    }
}

const mapActions = dispatch => {
    return {
        resetErrors: () => dispatch(resetErrors()),
        expirePassword: data => dispatch(changePasswordExpiration(data))
    }
}

export default connect(mapState, mapActions)(ExpirationPassword);
