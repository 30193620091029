import React from 'react';
import {connect} from 'react-redux';
import {getAllPermissions} from '../../redux/action-creators/users'
import 'rc-table/assets/index.css';
import Box from "../../components/Box";
import BoxHeader from "../../components/BoxHeader";
import BoxFooter from "../../components/BoxFooter";

class SettingsManagePage extends React.Component {

    componentDidMount() {
        this.props.getAllPermissions();
    }

    render() {
        return (
            <div className='container-fluid'>
                <div className="table-responsive" style={{marginTop: '15px', padding: '10px'}}>
                    <Box color="blue" classes="box-profile-first-row-height-show"
                         style={{marginTop: '15px', padding: '10px'}}>
                        <BoxHeader title="General Settings" color="blue"/>
                        <form>

                        </form>
                        <BoxFooter/>
                    </Box>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.app.users.loading,
    error: state.app.users.loading
});

const mapDispatchToProps = dispatch => ({
    getAllPermissions: () => {
        dispatch(getAllPermissions())
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsManagePage);
