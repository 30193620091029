
const defaultState = {
    loading: false,
    data: [],
    error: ''
}

const procedures = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_WORKFLOW_LOADING':
            return { ...state, loading: true }
        case 'FETCH_WORKFLOW_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload.elements,
                error: null
            })
        case 'FETCH_WORKFLOW_ERROR':
            return {...state, error: action.payload, loading: false}
        default:
    }

    return state
}

export default procedures
