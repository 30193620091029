import React from 'react';
import { flagged } from 'redux/pages/patient-case/patientCaseColumns';
import flaggedSlice from 'redux/pages/patient-case/slices/flaggedSlice';
import PatientCaseGrid from '../Components/PatientCaseGrid';

const columns = flagged;

const Flagged = (props) => (
  <PatientCaseGrid columns={columns} slice={flaggedSlice} {...props} />
);

export default Flagged;
