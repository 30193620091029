import { createApiParams } from 'components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import { fetchEntityList$ } from "../../epicUtils/commonEpics";
import physicianSlice from "./physicianSlice";
import { transformPatients } from "./utils";

const loadPatientsEpic = fetchEntityList$({
    ...physicianSlice.actions,
    resolveUrl: () => 'physician/page',
    resolveParams: (params) => [{ params: createApiParams(params) }],
    dataTransformer: transformPatients,
});

export default combineEpics(loadPatientsEpic);
