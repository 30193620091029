import React from 'react';

import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import momentLocalizer from 'react-widgets-moment';

import './styles/outer/main.css';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import history from './redux/history';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/lib/integration/react';

// #region screen imports
import MainLayout from './components/MainLayout';
import AuthLayout from './pages/Auth/AuthLayout';

import { AUTH_ROUTES, NEW_AUTH_ROUTES, DEV_ROUTES, DEV_ROUTES_WITHOUT_LAYOUT, MAIN_ROUTES } from 'routes';
import moment from 'moment';
import AppInitializer from 'components/AppInitializer';
// #endregion

moment.locale('en');
momentLocalizer(moment);
export default class App extends React.Component {
  state = { hasError: false };

  componentDidCatch(error, errorInfo) {
    console.error('[App error]', error, errorInfo);
  }

  static getDerivedStateFromError(error) {
    return { hasError: error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="alert alert-danger" role="alert">
          {JSON.stringify(this.state.hasError.message)}
        </div>
      );
    }

    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ConnectedRouter history={history}>
            <HashRouter>
              <Switch>
                {/*TODO: remove*/}
                {AUTH_ROUTES.map(({ path, component: Component }) => (
                  <Route
                    key={path}
                    exact
                    path={path}
                    render={(routeComponentProps) => (
                      <AuthLayout>
                        <Component {...routeComponentProps} />
                      </AuthLayout>
                    )}
                  />
                ))}

                {/*TODO*/}
                {NEW_AUTH_ROUTES.map(({ path, component: Component }) => (
                  <Route key={path} exact path={path} render={(routeComponentProps) => <Component {...routeComponentProps} />} />
                ))}

                {process.env.NODE_ENV === 'development' &&
                  DEV_ROUTES_WITHOUT_LAYOUT.map(({ path, component: Component }) => (
                    <Route key={path} exact path={path} render={(routeComponentProps) => <Component {...routeComponentProps} />} />
                  ))}

                <AppInitializer>
                  <MainLayout>
                    <Switch>
                      {MAIN_ROUTES.map(({ path, component: Component }) => (
                        <Route key={path} exact path={path} render={(routeComponentProps) => <Component {...routeComponentProps} />} />
                      ))}
                      {DEV_ROUTES.map(({ path, component: Component }) => (
                        <Route key={path} exact path={path} render={(routeComponentProps) => <Component {...routeComponentProps} />} />
                      ))}
                    </Switch>
                  </MainLayout>
                </AppInitializer>
              </Switch>
            </HashRouter>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}
