import React, { Component } from "react";
import { connect } from 'react-redux'
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import PortalSettingsSection from '../../components/Sections/PortalSettingsSection'
import OtherSection from '../../components/Sections/OtherSection'
import MfaSection from '../../components/Sections/MfaSection'
import { editField, } from '../../redux/actions/companiesActions'

class ClientSettings extends Component {

    render() {
        const { editField, isEditable } = this.props;
        const { defaultLocaleCode, timeZoneCode, active,
                mfaAuthenticator, mfaEmail, mfaSms} = this.props.profile;

        return (
            <Box color="green" classes="box-profile-first-row-height-show">
                <BoxHeader title={`Client - ${this.props.profile.fullName}`} color="green" icon="fa-list-alt"/>
                <BoxBody>
                    <form>
                        <PortalSettingsSection
                            defaultLocaleCode={defaultLocaleCode}
                            timeZoneCode={timeZoneCode}
                            onChange={editField}
                            isEditable={isEditable}
                        />
                        <OtherSection
                            active={active}
                            onChange={editField}
                            isEditable={isEditable}
                        />
                        <MfaSection
                            mfaAuthenticator={mfaAuthenticator}
                            mfaEmail={mfaEmail}
                            mfaSms={mfaSms}
                            onChange={editField}
                            isEditable={isEditable}
                        />
                    </form>
                </BoxBody>
                <BoxFooter>
                </BoxFooter>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.app.companies.editedCompany,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        editField: (field, value) => dispatch(editField(field, value)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientSettings);
