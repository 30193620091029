import {connect} from 'react-redux'
import ManageFieldForm from './ManageFieldForm'
import {
    createNewField,
    dispatchValidStatus,
    editField,
    isAllValid,
    setCurrentField,
    updateField
} from '../../../redux/actions/fieldsActions'
import {getAllRoles, openEditModal} from '../../../redux/action-creators/users'
import {getLanguages} from '../../../redux/actions/languageActions'
import {getAllStages} from '../../../redux/actions/stages'

const mapStateToProps = (state) => ({
    mode: 'create',
    label: 'Create Field',
    loading: state.app.fields.loading,
    error: state.app.fields.error,
    field: state.app.fields.editedField,
    isProfileChanged: state.app.fields.isProfileChanged,
    isFormValid: state.app.fields.isFormValid,
    isFieldValid: state.app.fields.fieldValid,
    fieldsCount: state.app.fields.data.length,
    languageCodes: state.app.languages.data,
});

const mapDispatchToProps = (dispatch) => ({
    getInitData: () => {
        dispatch(getLanguages());
        dispatch(setCurrentField());
        dispatch(getAllStages());
        dispatch(getAllRoles());
        dispatch(dispatchValidStatus(true))
    },
    editField: (field, value) => dispatch(editField(field, value)),
    isAllValid: (valid) => dispatch(isAllValid(valid)),
    handleOpenModal: () => {
        dispatch(openEditModal())
    },
    createField: data => dispatch(createNewField(data)),
    updateField: data => dispatch(updateField(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageFieldForm)
