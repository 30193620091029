import React, { useContext } from 'react';
import ConnectedDataTable from 'components/Datatable/ConnectedDataTable';
import CollapseBox from 'components/CollapseBox/CollapseBox';
import { getPatientCaseStateKey } from 'redux/pages/patient-case/patientCaseUtils';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { PatientCaseContext } from '../PatientCaseContext';
import useUserRole from 'utils/hooks/useUserRole';
import useGridVisibility from '../hooks/useGridVisibility';
import classnames from 'classnames';

import './PatientCaseGrid.css';

const PatientCaseGrid = ({ slice, columns, title, variant, forceShow, clickable, ...rest }) => {
  const { selectedCaseCodes, history, ...contextProps } = useContext(PatientCaseContext);

  const visible = useGridVisibility(slice.name);

  const role = useUserRole().toLowerCase();
  const selectedColumnsByUserRole = columns[role];

  if (!selectedColumnsByUserRole) {
    return null;
  }

  const handleRowClick = clickable && (({ code }) => history.push(`/case/${code}`));

  return (
    <CollapseBox
      variant={variant}
      title={title || startCase(slice.name)}
      bodyStyles={{ paddingRight: 20 }}
      className={classnames({ hidden: !visible && !forceShow })}
    >
      <ConnectedDataTable
        className="patient-case-grid"
        columns={selectedColumnsByUserRole}
        stateKey={getPatientCaseStateKey(slice.name)}
        actions={slice.actions}
        variant={variant}
        keyExtractor={({ code }) => code}
        selectedKeys={selectedCaseCodes}
        onRowClick={handleRowClick}
        loadingPlaceholderCount={5}
        selectable
        {...contextProps}
        {...rest}
      />
    </CollapseBox>
  );
};

PatientCaseGrid.propTypes = {
  title: PropTypes.string,
  slice: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  //columns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  variant: PropTypes.string,
  forceShow: PropTypes.bool,
  clickable: PropTypes.bool,
};

PatientCaseGrid.defaultProps = {
  variant: 'primary',
  title: '',
  forceShow: false,
  clickable: true,
};

export default PatientCaseGrid;
