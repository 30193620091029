import React from 'react'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";

class FacilitiesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            key: props.match.params.type ? props.match.params.type : "1",
            params: {
                'page': 0,
                'size': 10,
                'sort': '',
                'dir': 'asc',
                'search': ''
            }
        };
        this.onRowClick = this.onRowClick.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
    }

    onRowClick(item) {
        this.setState({item})
        console.log(this.props);
        // this.props.history.push(`/facility/${item}`)
    }

    enumFormatter(cell, row, enumObject) {
        return enumObject[cell];
    }

    iconFormatter = (cell, row, formatExtraData) => {
        return (
            <i className={'fa ' + cell} onClick={() => console.log(row)}/>
        );
    }

    laguageFormatter = (cell, row, formatExtraData) => {
        return cell['EN']
    }

    isExpandableRow = (row) => {

        if (row.children && row.children.length) return true;
        else return false;
    }

    expandComponent = (row) => {
        return (
            // row.children.map((child) => {
            // <tr>
            //   <td>{child.position}</td>
            //   <td><i className={'fa ' +  child.icon}/></td>
            //   <td>{child.name['EN']}</td>
            //   <td>{child.link}</td>
            // </tr>
            //  })
            <table className="react-bs-table table table-bordered">
                <tbody>
                <tr>
                    <td>1</td>
                    <td><i className={'fa fa-home'}/></td>
                    <td>name</td>
                    <td>/link</td>
                </tr>
                </tbody>
            </table>
        )
    }

    render() {
        let cols = [
            {
                'isKey': true,
                'name': 'code',
                'title': this.context.translate('common.form.code'),
                'sort': false,
                'hidden': true
            },
            {
                'name': 'position',
                'title': 'Position',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '100',
                'dataAlign': 'center',
            },
            {
                'name': 'icon',
                'title': 'Icon',
                'dataFormat': this.iconFormatter,
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '100',
                'dataAlign': 'center',

            },
            {
                'name': 'name',
                'title': 'Title',
                'dataFormat': this.laguageFormatter,
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'link',
                'title': 'Link',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            // {
            //     'name': 'active',
            //     'title': this.context.translate('common.form.status'),
            //     'dataFormat': this.enumFormatter,
            //     'sort': true,
            //     'formatExtraData': {
            //         'false': this.context.translate('common.form.inactive'),
            //         'true': this.context.translate('common.form.active'),
            //     },
            //     'width': '200',
            //     'filter': { type: 'SelectFilter', options: {
            //             'false': this.context.translate('common.form.inactive'),
            //             'true': this.context.translate('common.form.active'),
            //         }}
            // }
        ];
        return (
            <div className='container-fluid'>
                <div className="table-responsive" style={{marginTop: '15px', "padding": '1px'}}>
                    <RemoteGrid
                        color="blue"
                        title={this.context.translate('common.form.menu_items')}
                        data={this.props.menuItems}
                        total={this.props.menuItems.length}
                        columns={cols}
                        pagination={true}
                        search={true}
                        onRowClick={this.onRowClick}
                        expandableRow={this.isExpandableRow}
                        expandComponent={this.expandComponent}
                        defaultSort
                        sortName='position'
                        sortOrder='ask'
                    />
                </div>
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
            </div>
        )
    }
}

FacilitiesList.contextTypes = {
    translate: PropTypes.func
}

const mapState = (state) => {
    return {
        menuItems: state.app.context.layout.items,
    }
}

export default connect(mapState, null)(FacilitiesList);
