import React from 'react'
import Footer from './Footer'
import SideBar from './SideBar'
import Header from './HeaderBlocks/Header'
import NotFound from "./ErrorPages/NotFound";
import { isAdmin, isStaff } from "../utils/helpers";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import { logout } from '../redux/actions/authActions'
import { getMyPermissions } from "../redux/action-creators/users";
import LoadingIndicator from "./LoadingIndicator";

class MainLayout extends React.Component {

    componentDidMount() {
        if (!this.props.hasLoadedPermissions) {
            this.props.getMyPermissions();
        }
    }

    render() {
        const {
            currentLocation, availableLinks, role,
            isDeveloper, hasLoadedPermissions
        } = this.props;

        if (!currentLocation) {
            this.props.logout();
        } else {
            let currentPage = currentLocation.pathname;
            let hasAvailableLinks = availableLinks && availableLinks.links && availableLinks.links.length > 0;
            let pageFound = false; // MED-403: page is not found by default for MainLayout
            if (role && hasAvailableLinks) {
                // find current page in the available links
                pageFound = availableLinks.links.indexOf(currentPage) > -1;
                // find current page in the available partial links
                if (!pageFound) {
                    let l = availableLinks.parts.length;
                    for (let i = 0; i < l; i++) {
                        if (currentPage.indexOf(availableLinks.parts[ i ]) !== -1) {
                            pageFound = true;
                            break;
                        }
                    }
                }
                // TODO: we cannot handle sub pages with page permissions?
                if (!isStaff(role) && !isAdmin(role)
                    && currentPage.indexOf('/assign') !== -1) {
                    pageFound = false;
                }
                // Developer check for dev utils pages
                if (currentPage.startsWith('/dev_') && !isDeveloper) {
                    pageFound = false;
                }
            }

            return (
                (hasAvailableLinks && hasLoadedPermissions)
                    ?
                    <div className="wrapper">
                        <Header/>
                        <SideBar/>
                        <div className="content-wrapper">
                            {pageFound ? this.props.children : <NotFound/>}
                        </div>
                        <Footer/>
                    </div>
                    :
                    <LoadingIndicator height={50}/>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        currentLocation: state.router.location,
        availableLinks: state.app.context.layout.availableLinks,
        role: state.app.auth.user.roleCode,
        hasLoadedPermissions: state.app.users.my_permissions && state.app.users.my_permissions.length > 0,
        isDeveloper: state.app.users.my_permissions.includes('DEVELOPER'),
    }
};

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
    getMyPermissions: () => dispatch(getMyPermissions()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout));
