import React from 'react';
import {connect} from 'react-redux';
import Modal from 'react-modal';
import PropTypes from "prop-types";


// class EditModal extends React.Component {
//
//     constructor (props) {
//         super();
//         this.getEditForm = this.getEditForm.bind(this);
//     }
//
//     getEditForm() {
//         switch (this.props.entityType) {
//             case 'case type':
//                 return <CaseTypeEditForm caseTypeCode={this.props.userId}/>
//             case 'medical_system_code':
//                 return <MCSystemEditForm mcSystemCode={this.props.userId}/>
//             case 'procedure':
//                 return <ProcedureEditForm procedureCode={this.props.userId}/>
//             case 'diagnose':
//                 return <DiagnoseEditForm diagnoseCode={this.props.userId}/>
//         }
//     }
//
// getH3Name() {
//     switch (this.props.entityType) {
//         case 'case type':
//             return 'common.cases.edit_' + this.props.entityType
//         case 'procedure':
//         case 'diagnose':
//         case 'medical_system_code':
//             return 'common.client.edit_' + this.props.entityType
//         default:
//             return 'common.form.edit_' + this.props.entityType
//     }
// }

//     render () {
//         return (
//             <Modal
//                 isOpen={this.props.isOpen}
//                 contentLabel="onRequestClose Example"
//                 onRequestClose={this.props.handleCloseModal}
//                 className="custom-modal modal-dialog"
//                 overlayClassName="modal-overlay"
//             >
//                 <h3 className="modal-title text-center">
//                     {this.context.translate(this.getH3Name())}
//                     <i className='fa fa-times close'  onClick={this.props.handleCloseModal}></i>
//                 </h3>
//                 {this.getEditForm()}
//             </Modal>
//         );
//     }
// }

class EditModal extends React.Component {
    render() {
        // const {isOpen, handleCloseModal, H3Name, children} = this.props;
        const {isOpen, children} = this.props;
        return (
            <Modal
                isOpen={isOpen}
                contentLabel="onRequestClose Example"
                className="custom-modal modal-dialog"
                overlayClassName="modal-overlay"
            >
                {/*                <h3 className="modal-title text-center" style={{background:'#fff'}}>
                    { this.context.translate(H3Name) }
                    <i className='fa fa-times close'  onClick={handleCloseModal}></i>
                </h3> */}
                {children}
            </Modal>
        );
    }
}

EditModal.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => ({
    isOpen: state.app.users.editModalOpen,

})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(EditModal)
