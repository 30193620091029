import {reducedRoleStageMatrix} from '../../utils/Constants'
import {isObjectsEqual} from '../../utils/helpers'
import {convertReceivedField} from '../../utils/fields'

const baseField = {
    active: true,
    // language: { value: 'EN', label:'English' },
    names: {'EN': '', 'SV': ''},
    code: '',
    name: '',
    fieldType: null,
    defaultValue: null,
    listOptions: [],
    customClasses: [],
    phi: false,
    skippDisplayName: false,
    allowMultiSelect: false,
    requiredMatrix: reducedRoleStageMatrix,
    visibilityMatrix: reducedRoleStageMatrix,
    readOnlyMatrix: reducedRoleStageMatrix,
    fieldValidationDtos: [],
    nameScript: '',
    customClassesScript: '',
    sectionCodeScript: '',
    updateSuccess: null,
    positionScript: '',
    listOptionsScript: '',
    defaultValueScript: '',
    visibilityMatrixScript: '',
    readOnlyMatrixScript: '',
    requiredMatrixScript: '',
    helpTextStaffScript: '',
    helpTextReviewerScript: '',
    helpTextClientScript: '',
};
const defaultState = {
    loading: false,
    updateSuccess: null,
    loadedCount: 0,
    data: [],
    editedField: baseField,
    originalField: baseField,
    fieldValid: true,
    editedSuccess: false,
    error: null,
    isFormValid: true,
    isProfileChanged: false,
};

const fields = (state = defaultState, action) => {
    switch (action.type) {
        case 'FIELD_UPDATE_SUCCESS':
          return {...state, updateSuccess: true};
        case 'FIELD_UPDATE_ERROR':
            return {...state, updateSuccess: false};
        case 'FETCH_FIELDS_LOADING':
            return {...state, loading: true, editedSuccess: false, isProfileChanged: false};
        case 'FETCH_FIELDS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null
            });
        case 'FETCH_FIELD_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                editedField: convertReceivedField(action.payload),
                originalField: convertReceivedField(action.payload),
                error: null
            });
        case 'FETCH_FIELD_ERROR':
            return {...state, error: action.payload, loading: false};
        case 'FETCH_CREATE_FIELD_ERROR':
            return {...state, error: action.payload, loading: false};
        case 'FETCH_EDIT_FIELD_ERROR':
            return {...state, error: action.payload, loading: false};
        case 'FETCH_CREATE_FIELD_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                data: [...state.data, action.payload]
            });
        case 'FETCH_EDIT_FIELD_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editedSuccess: true,
                originalField: action.payload,
                data: state.data.map((cp) => {
                    if (cp.code === action.payload.code) return action.payload;
                    return cp
                })
            });

        case 'SET_CURRENT_FIELD':
            return Object.assign({}, state, {
                editedField: action.payload ? action.payload : defaultState.editedField,
                originalField: action.payload ? action.payload : defaultState.editedField,
                error: null
            });

        case 'FIELD_EDIT_FIELD':
            let saved = action.payload;
            if (typeof saved['defaultValue'] !== "undefined") {
                let data = saved['defaultValue'];
                if (Array.isArray(data) && typeof data[0] !== "undefined"
                    && typeof data[0].value !== "undefined" && typeof data[0].label !== "undefined") {
                    let defNames = [];
                    for (let i = 0, len = data.length; i < len; i++) {
                        defNames.push(data[i].label);
                    }
                    saved['defaultValueName'] = JSON.stringify(defNames);
                }
            }
            return Object.assign({}, state, {
                editedField: Object.assign({}, state.editedField, saved),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedField, saved), state.originalField),
                editedSuccess: false

            });

        case 'EDIT_FIELD_VALIDATION':
            return Object.assign({}, state, {
                editedField: Object.assign({}, state.editedField, action.payload)
            });

        case 'VALIDATE_FIELD_FIELD':
            return Object.assign({}, state, {
                fieldValid: action.payload
            });
        case 'SHOW_FIELD_VALIDATION':
            return Object.assign({}, state, {
                isFormValid: action.payload
            });

        case 'FETCH_FIELD_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            };
        case 'FETCH_FIELD_LOADED_COUNT':
            return {...state, loadedCount: action.payload};

        default:
    }

    return state
};

export default fields
