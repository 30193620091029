import React from 'react';
import { connect } from 'react-redux';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { getNamesFromCodes } from '../../../utils/helpers';
import FilteredReviewersPager from './FilteredReviewersPager';

const FilterBadge = ({ field, value }) => {
    return (
        <span className='filter_details'><span className='field'>{field}: </span><p>{value}</p></span>
    )
};

class FilteredReviewers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
        };
    }

    renderFilterValues = () => {
        const { filters } = this.props;

        return Object.keys(filters)
            .filter((item) => {
                if (typeof filters[ item ] !== 'boolean') return !!filters[ item ];
                return true
            })
            .map((item, i) => <FilterBadge key={i} field={item} value={filters[ item ]}> </FilterBadge>)
    };

    filtered = (item) => {
        if (item.filter && item.filter.type === 'TextFilter') {
            item.filter.placeholder = this.context.translate('common.form.enter') + ' ' + item.title;
        }
        return item.filter;
    };

    render() {
        return (
            <div className='tier' id="filtered_reviewers_table">
                <div className='tier_header border_bottom background_light_grey'>
                    <div className='row margin-bottom-5'>
                        <div style={{ fontSize: '1.3em', fontWeight: 700, }} className='col-md-4'>Selected Parameters
                        </div>
                    </div>
                    {this.renderFilterValues()}
                </div>
                <FilteredReviewersPager />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // console.log('mapStateToProps', state.app);
    const { countries, case_types, specialties, reviewerTypes } = state.app;
    const { currentFilters } = state.app.case_assign;

    const filters = {
        'Quality score min': currentFilters.reviewerScore,
        'Hourly rate max': currentFilters.reviewerRate,
        'Respect Client Exclusion': currentFilters.respectClientExclusions ? 'Yes' : 'No',
        'Respect Client Inclusion': currentFilters.respectClientInclusions ? 'Yes' : 'No',
        'Include Backup Reviewers': currentFilters.includeBackup ? 'Yes' : 'No',
        'Prefer Non Backup Reviewers': currentFilters.preferNonBackupReviewers ? 'Yes' : 'No',
        'Prefer More Recent Assignment': currentFilters.sortByLastAssignedDate ? 'Yes' : 'No',
        'Reviewer Type': getNamesFromCodes(currentFilters.reviewerTypes, reviewerTypes.data),
        'Countries': getNamesFromCodes(currentFilters.countries, countries.data),
        'Specialties': getNamesFromCodes(currentFilters.specialties, specialties.data),
        'Case Types': getNamesFromCodes(currentFilters.caseTypes, case_types.types)
    };

    return {
        filters,
    }
};

const mapDispatchToProps = () => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(FilteredReviewers);
