import React, {Component} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {ContentState, convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import {isJson} from '../../utils/helpers'

export class CustomOption extends Component {
    render() {
        const style = {
            fontSize: '1em',
            padding: '13px',
            borderRadius: '4px',
            border: '1px solid #d2d6de',
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            position: 'absolute',
            top: 0,
            right: '16px',
        };
        const {name, onActionClick} = this.props;

        return (
            <div className='margin-left-5 cursor-pointer' onClick={onActionClick} data-name={name}>
                <i style={this.props.style ? {...style, ...this.props.style} : style} className="fa fa-code"
                   data-name={name}/>
            </div>
        );
    }
}

export default class WysiwygEditor extends Component {
    constructor(props) {
        super(props);
        let editorState = EditorState.createEmpty();
        // if (!props.value)  editorState = EditorState.createEmpty()

        if (props.value && typeof props.value === 'object') editorState = EditorState.createWithContent(convertFromRaw(props.value));

        if (isJson(props.value)) {
            // console.log(props.value);
            try {
                editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(props.value)))
            } catch (e) {
                console.error(e);
            }
        } else if (typeof props.value === 'string') {
            editorState = EditorState.createWithContent(ContentState.createFromText(props.value))
        }

        this.state = {editorState, firstLoad: true}
    }

    componentDidMount() {
        if (!this.props.id) return;
        const element = document.getElementsByClassName(this.props.id);
        if (element[0]) { element[0].id = this.props.id; }
    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        // if (!nextProps.value)  this.setState({ editorState: EditorState.createEmpty() })
        if (JSON.stringify(this.props.value) !== JSON.stringify(nextProps.value)) {
            if (!nextProps.value) this.setState({editorState: EditorState.createEmpty()});

            if (nextProps.value && typeof nextProps.value === 'object') {
                this.setState({
                    firstLoad: false,
                    editorState: EditorState.createWithContent(convertFromRaw(nextProps.value))
                });
            }

            if (isJson(nextProps.value)) {
                try {
                    this.setState({editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(nextProps.value)))})
                } catch (e) {
                    // console.log(e);
                }
            } else if (typeof nextProps.value === 'string') {
                this.setState({editorState: nextProps.value
                        ? EditorState.createWithContent(ContentState.createFromText(nextProps.value))
                        : EditorState.createEmpty()});
            }
        }

    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    onBlur = () => {
        this.props.onChange(convertToRaw(this.state.editorState.getCurrentContent()))
    };

    setEditorReference = (ref) => {
        this.editorReferece = ref
    };

    render() {
        const disabled = this.props.readOnly ? 'background-grey' : '';
        const {editorState} = this.state;
        const {label, actioned, labelLeft} = this.props;
        const toolbarStyle = {paddingRight: '45px'};
        const classes = `col-md-${labelLeft ? this.props.md - 2 : this.props.md}`;

        const editor = <Editor
            editorState={editorState}
            editorRef={this.setEditorReference}
            wrapperClassName="wrapperClassName"
            editorClassName={`form-control ${this.props.className} ${disabled} ${this.props.id} `}
            toolbarClassName="toolbar-class"
            onEditorStateChange={this.onEditorStateChange}
            readOnly={this.props.readOnly}
            toolbar={{
                options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'fontFamily',
                    'textAlign',
                    'colorPicker',
                    'link'
                ]
            }}
            toolbarStyle={toolbarStyle}
            onBlur={() => {
                this.onBlur();
                this.props.onBlur && this.props.onBlur();
            }}
            toolbarCustomButtons={actioned ? [<CustomOption key={this.props.name}
                                                            onActionClick={this.props.onActionClick}
                                                            name={this.props.name} />] : []}
        />;
        return (
            <React.Fragment>
                {labelLeft && 
                <div className='col-md-2'>
                    {this.props.type !== 'LABEL' && 
                    <label>
                        {label}
                        {this.props.tickMark}
                    </label>}
                </div>}
                <div className={classes}>
          <span style={{display: 'none'}}>
            {this.props.validator && this.props['valids'] ? this.props.validator.message(this.props['validLabel'] || this.props.label, editorState.getCurrentContent().getPlainText().trim(), this.props['valids'], 'text-danger') : ''}
          </span>
                </div>
                <div className={classes} style={this.props.style}>
                    {!labelLeft && 
                    <label htmlFor={this.props.id} className="required" id={'displayName-'+this.props.name}>
                        {this.props.label}
                        {this.props.tickMark}
                    </label>}
                    {/* this.props.actioned ? <InputGroupWrapper name={this.props.name} icon='code' onActionClick={this.props.onActionClick}>{editor}</InputGroupWrapper> : editor */}
                    {editor}
                </div>
            </React.Fragment>


        )
    }
}
