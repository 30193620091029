import React from 'react'
import Modal from 'react-modal'
import RemoteGrid from "../../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import EditModal from '../../EditModal'
import UpdateSection from './UpdateSection'
import {closeEditModal, openEditModal} from '../../../redux/action-creators/users'
import {setCurrentSection} from '../../../redux/actions/templateActions'

class SectionsList extends React.Component {

    constructor(props) {
        super(props);
        this.onRowClick = this.onRowClick.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
        this.state = {}
    }

    componentDidMount() {
        this.props.closeEditModal();
        Modal['setAppElement']('body');
    }

    onRowClick(sectionId) {
        this.setState({sectionId});
        this.props.openEditModal()
    }

    enumFormatter = (cell, row, enumObject) => {
        return enumObject[cell];
    };

    render() {
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'title': 'Code',
                'sort': false,
                'hidden': true
            },
            {
                'name': 'displayName',
                'title': 'Section Name',
                'sort': true,
                'width': '200',
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            },
            {
                'name': 'position',
                'title': 'Section Order',
                'sort': true,
                'width': '200',
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            },
            {
                'name': 'fieldsCount',
                'title': 'Fields Count',
                'sort': true,
                'width': '200',
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            },
            // {
            //     'name': 'helpText',
            //     'title': 'Help Text',
            //     'sort': true,
            //     'width': '200',
            //     'filter': {type: 'TextFilter', delay: 500},
            //     'headerAlign': 'left'
            // },
            // {
            //     'name': 'expanded',
            //     'title': 'Expanded',
            //     'sort': true,
            //     'width': '200',
            //     'formatExtraData': {
            //         'false': 'No',
            //         'true': 'Yes'
            //     },
            //     'filter': { type: 'SelectFilter', options: {
            //             'false': 'No',
            //             'true': 'Yes'
            //         }}
            // },
            // {
            //     'name': 'active',
            //     'title': 'Status',
            //     'dataFormat': this.enumFormatter,
            //     'sort': true,
            //     'formatExtraData': {
            //         'false': 'Inactive',
            //         'true': 'Active'
            //     },
            //     'width': '100',
            //     'filter': { type: 'SelectFilter', options: {
            //             'false': 'Inactive',
            //             'true': 'Active'
            //         }}
            // }
        ];
        const {sorting, pagination} = this.props.gridConfiguration;

        return (
            <div className='col-md-12'>
                <RemoteGrid
                    color="blue"
                    entity="section"
                    title={`Template - ${this.props.templateName}`}
                    data={this.props.data}
                    total={this.props.totalElements}
                    columns={columns}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.onRowClick}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <EditModal handleCloseModal={this.props.closeEditModal} entityType='section'>
                    <UpdateSection sectionId={this.state.sectionId}/>
                </EditModal>
            </div>
        )
    }
}

const mapState = (state) => {
    return {
        data: state.app.templates.sectionDto.map((item) => ({
            ...item,
            displayName: item.displayName ? item.displayName['EN'] : '',
            fieldsCount: item['fieldInstances'] ? item['fieldInstances'].length : 0,
        })),
        totalElements: state.app.templates.sectionDto.length,
        gridConfiguration: state.app.grid.section,
        templateName: state.app.templates.name,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentSection: (section) => {
            dispatch(setCurrentSection(section))
        },
        openEditModal: () => {
            dispatch(openEditModal())
        },
        closeEditModal: () => {
            dispatch(closeEditModal())
        },
    }
};

export default connect(mapState, mapDispatchToProps)(SectionsList);
