/* eslint-disable object-shorthand */
/* eslint-disable comma-dangle */
import { createApiParams } from 'components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import { transformResponseToTableData } from "../../../components/Datatable/dataTableUtils";
import { fetchEntityList$ } from "../../epicUtils/commonEpics";
import patientSlice from "./patientSlice";

const transformPatients = transformResponseToTableData((data) => {
    return {
        data: data.map((
            {
                code, firstName, lastName, gender,
                companyFullName,
                companyShortName,
                active,
                email, phoneNumber, alternativePhoneNumber
            }) => ({
            code, firstName, lastName, gender,
            company: companyFullName || companyShortName,
            contactInfo: email || phoneNumber || alternativePhoneNumber,
            status: (active ? 'ACTIVE' : 'INACTIVE')
        })),
    };
});

const loadPatientsEpic = fetchEntityList$({
    ...patientSlice.actions,
    resolveUrl: () => 'patient/page',
    resolveParams: (params) => {
        return [{ params: createApiParams(params) }]
    },
    dataTransformer: transformPatients,
});

export default combineEpics(loadPatientsEpic);
