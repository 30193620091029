import { transformResponseToTableData } from "../../../components/Datatable/dataTableUtils";
import { isNull, negate } from "lodash";

const formatList = (list) => {
    return list.filter(negate(isNull)).join(', ');
};

export const transformClientEditPhysicians = transformResponseToTableData((data) => {
    return {
        data: data.map((
            {
                code, firstName, lastName, physicianCode, specialtyNames, bodyPartNames, languageNames, private_facility, linked
            }) => ({
            code, firstName, lastName, physicianCode,
            specialtyNames: formatList(specialtyNames),
            bodyPartNames: formatList(bodyPartNames),
            languageNames: formatList(languageNames),
            privateFacility: private_facility ? 'Yes' : 'No',
            linked: linked ? 'Linked' : 'Unlinked',
        })),
    };
});
