import { useSelector } from 'react-redux';

const getLanguage = (state) => state.app.context.language;

const useLanguage = () => {
    const language = useSelector(getLanguage);

    return language.toUpperCase();
};

export { getLanguage, useLanguage };
