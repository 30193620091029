const DEBUG = false;
const debug = (...message) => {
    DEBUG && console.debug(...message)
};

const initialState = {
    reviewers: [],
    reviewersOption: 'All',
    companies: [],
    companiesOption: 'All',
    startDate: null,
    endDate: null,
    validationMessages: [],
    isReportLoading: false,
    isReviewersMinimalLoading: false,
    isReviewersMinimalError: false,
    reviewersMinimalData: [],
    isCompaniesMinimalLoading: false,
    isCompaniesMinimalError: false,
    companiesMinimalData: [],
};

const financialReport = (state = initialState, action) => {
    // console.log('***financialReport*** state:', state, action);
    switch (action.type) {
        case 'EXPORT_FINANCIAL_REPORT':
            // console.log('SAVE_FILTER_FINANCIAL_REPORT:', state, action);
            return { ...state, validationMessages: [], isReportLoading: true };
        case 'SAVE_FILTER_FINANCIAL_REPORT':
            // console.log('SAVE_FILTER_FINANCIAL_REPORT:', state, action);
            return { ...state, ...action.payload };
        case 'FINANCIAL_REPORT_VALIDATION_CHANGE':
            // console.log('FINANCIAL_REPORT_VALIDATION_CHANGE:', state, action);
            return { ...state, ...action.payload };
        case 'REPORT_FINANCIAL_REPORT_FAILED':
            return {
                ...state,
                validationMessages: [
                    { id: 'Generate', field: 'Generate', message: action.payload ? action.payload : 'Report generation has failed!' }
                ],
                isReportLoading: false
            };
        case 'REPORT_FINANCIAL_REPORT_SUCCESS':
            return { ...state, validationMessages: [], isReportLoading: false };

        case 'FETCH_REVIEWERS_MINIMAL_LOADING': {
            debug(action.type);
            return { ...state, isReviewersMinimalLoading: true };
        }
        case 'FETCH_REVIEWERS_MINIMAL_SUCCESS': {
            debug(action.type);
            debug(action.payload);
            return { ...state, isReviewersMinimalLoading: false, reviewersMinimalData: action.payload, isReviewersMinimalError: null };
        }
        case 'FETCH_REVIEWERS_MINIMAL_ERROR': {
            debug(action.type);
            return { ...state, isReviewersMinimalError: action.payload, isReviewersMinimalLoading: false };
        }
        case 'FETCH_COMPANIES_MINIMAL_LOADING': {
            debug(action.type);
            return { ...state, isCompaniesMinimalLoading: true };
        }
        case 'FETCH_COMPANIES_MINIMAL_SUCCESS': {
            debug(action.type);
            debug(action.payload);
            return { ...state, isCompaniesMinimalLoading: false, companiesMinimalData: action.payload, isCompaniesMinimalError: null };
        }
        case 'FETCH_COMPANIES_MINIMAL_ERROR': {
            debug(action.type);
            return { ...state, isCompaniesMinimalError: action.payload, isCompaniesMinimalLoading: false };
        }
        default:
            return { ...state };
    }
};

export default financialReport;
