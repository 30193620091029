import React from 'react'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import connect from "react-redux/es/connect/connect";
import {getAll} from '../../redux/actions/BasicEntityActions'
import PropTypes from "prop-types";
import {getNamesFromCodes} from "../../utils/helpers";
import {withRouter} from "react-router-dom";
import {getPhysician, setDefaultPhysician} from "../../redux/actions/physicianActions";
import {getActiveCountries} from "../../redux/actions/countryActions";
import LoadingIndicator from '../../components/LoadingIndicator';

class PhysiciansList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: {
                'page': 0,
                'size': 10,
                'sort': '',
                'dir': 'asc',
                'search': ''
            }
        };
        this.onRowClick = this.onRowClick.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
    }

    componentDidMount() {
        this.props.getActiveCountries();
        this.props.getAllPhysicians();
        this.props.setDefaultPhysician()
    }

    onRowClick(item) {
        this.setState({item});
        console.log(this.props);
        this.props.history.push(`/physicians/${item}`)
    }

    enumFormatter = (cell, row, enumObject) => {
        return enumObject[cell];
    };

    render() {
        let cols = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true
            },
            {
                'name': 'firstName',
                'title': this.context.translate('common.form.first_name'),
                'width': '200',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'lastName',
                'title': this.context.translate('common.form.last_name'),
                'width': '200',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'physicianCode',
                'title': this.context.translate('common.form.code'),
                'columnTitle': true,
                'width': '100',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'specialtyCodes',
                'title': this.context.translate('common.form.specialties'),
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'bodyPartCodes',
                'title': this.context.translate('common.form.body_parts'),
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'private_facilityS',
                'title': this.context.translate('common.form.private_facility'),
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'no': this.context.translate('common.form.no'),
                    'yes': this.context.translate('common.form.yes')
                },
                'width': '120',
                'filter': {
                    type: 'SelectFilter', options: {
                        'no': this.context.translate('common.form.no'),
                        'yes': this.context.translate('common.form.yes')
                    }
                }
            },
            {
                'name': 'languageCodes',
                'title': this.context.translate('common.form.tables.languages'),
                'columnTitle': true,
                'width': '200',
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'active',
                'title': this.context.translate('common.form.status'),
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': this.context.translate('common.form.inactive'),
                    'true': this.context.translate('common.form.active')
                },
                'width': '100',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': this.context.translate('common.form.inactive'),
                        'true': this.context.translate('common.form.active'),
                    }
                }
            }
        ];
        const {isLoading} = this.props;
        const {sorting, pagination} = this.props.gridConfiguration;
        return (
            <React.Fragment>
                {isLoading && <LoadingIndicator type="lister"/>}
                <RemoteGrid
                    color="green"
                    entity="physician"
                    title={this.context.translate('common.form.physicians')}
                    data={this.props.physicians}
                    total={this.props.physicians.length}
                    columns={cols}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.onRowClick}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                    onSortChange={(name, order) => this.props.onSortChange(name, order)}
                    resetSorting={() => this.props.resetSorting()}
                    multiColumnSort={2}
                    isLoading={isLoading}
                />
            </React.Fragment>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        gridConfiguration: PropTypes.object,
    }
    static defaultProps = {
        isLoading: false
    };
}

PhysiciansList.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state) => {
    return {
        physicians: state.app.physician.data
            .map(item => {
                return {
                    ...item,
                    specialtyCodes: (item.specialtyCodes) ? getNamesFromCodes(item.specialtyCodes, state.app.specialties.data) : [],
                    bodyPartCodes: (item.bodyPartCodes) ? getNamesFromCodes(item.bodyPartCodes, state.app.bodyParts.data) : [],
                    languageCodes: (item.languageCodes) ? getNamesFromCodes(item.languageCodes, state.app.languages.data) : [],
                    private_facilityS: item.private_facility === true ? 'yes' : 'no',
                }
            }),
        gridConfiguration: state.app.grid.physician,
        isLoading: state.app.physician.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getActiveCountries: () => dispatch(getActiveCountries()),
        setDefaultPhysician: () => dispatch(setDefaultPhysician()),
        getPhysician: code => dispatch(getPhysician(code)),
        getAllPhysicians: () => dispatch(getAll('physician'))
    }
};

// PhysiciansList = withRouter(PhysiciansList);
export default connect(mapState, mapDispatchToProps)(withRouter(PhysiciansList));
