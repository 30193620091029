import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import ColoredBox from '../../components/ColoredBox/ColoredBox';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CaseFileTemplateForm, { FORM_TYPES } from './CaseFileTemplateForm';
import ClientSideDataTable from 'components/Datatable/ClientSideDataTable';
import PreventTransitionPromptNew from 'components/PreventTransitionPrompt';
import SystemMessage from 'components/SystemMessage';
import {
  updateEntityLoading as updateCaseFileTemplate,
  fetchEntityLoading as getCaseFileTemplate,
} from 'redux/pages/case-file-template/caseFileTemplateSlice';
import UpdateButtons from 'components/UpdateButtons';
import { useRef } from 'react';

const columns = {
  fullName: {
    label: 'Full Name',
    colStyle: { width: '70%' },
  },
  shortName: {
    label: 'Short Name',
    colStyle: { width: '30%' },
    multiSelect: true,
  },
};

const createLeftColumnWrapper = ({ originalName, isLoading }) => {
  // eslint-disable-next-line react/prop-types
  const ColWrapper = ({ children }) => {
    return (
      <ColoredBox
        variant="primary"
        title={`Case File Template - ${originalName}`}
        bodyClasses="pd-20"
        loader={isLoading}
      >
        {children}
      </ColoredBox>
    );
  };

  return ColWrapper;
};

const createRightColumnWrapper = ({ isLoading }) => {
  // eslint-disable-next-line react/prop-types
  const ColWrapper = ({ children }) => {
    return (
      <ColoredBox
        variant="primary"
        title="Case File Template - Upload document"
        icon="camera"
        bodyClasses="pd-20"
        loader={isLoading}
      >
        {children}
      </ColoredBox>
    );
  };

  return ColWrapper;
};

const CaseFileTemplateEditPage = ({
  match: {
    params: { id: code },
  },
}) => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const {
    entity,
    entityOriginal,
    isLoading,
    isFormChanged,
    isFormValid,
    isUpdateSuccess,
    error,
  } = useSelector((state) => {
    return state.app.caseFileTemplates;
  });

  const { clients } = entity;
  const { name: originalName } = entityOriginal;

  useEffect(() => {
    dispatch(getCaseFileTemplate({ code }));
  }, [dispatch, code]);

  const saveChanges = (entity, _event, { closeAfterSave }) => {
    dispatch(
      updateCaseFileTemplate({
        ...entity,
        closeAfterSave,
      }),
    );
  };

  const submitForm = (closeAfterSave = true) => {
    formRef.current.submit({ closeAfterSave });
  };

  const leftColumnWrapper = useMemo(() => {
    return createLeftColumnWrapper({ isLoading, originalName });
  }, [isLoading, originalName]);

  const rightColumnWrapper = useMemo(() => {
    return createRightColumnWrapper({ isLoading, originalName });
  }, [isLoading, originalName]);

  return (
    <section className="content" id="caseFileTemplateEditPage">
      <PreventTransitionPromptNew
        when={isFormChanged}
        saveChanges={submitForm}
        isFormValid={isFormValid}
      />

      <SystemMessage
        shown={isFormValid && isUpdateSuccess}
        type="success"
        message="Case file template has been updated successfully"
        style={{ top: '0' }}
      />

      <SystemMessage shown={!isLoading && error} type="error" message={error} />

      <UpdateButtons
        isFormChanged={isFormChanged}
        isFormValid={isFormValid}
        onSave={submitForm}
        color="blue"
      />
      <Row>
        <CaseFileTemplateForm
          ref={formRef}
          leftColumnWrapper={leftColumnWrapper}
          rightColumnWrapper={rightColumnWrapper}
          type={FORM_TYPES.edit}
          onSubmit={saveChanges}
        />
      </Row>
      <Row>
        <Col md={12}>
          <ColoredBox variant="primary" title="Clients">
            <ClientSideDataTable data={clients} columns={columns} />
          </ColoredBox>
        </Col>
      </Row>
    </section>
  );
};

CaseFileTemplateEditPage.propTypes = {
  match: PropTypes.object,
};

export default CaseFileTemplateEditPage;
