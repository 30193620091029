import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import '../ModalWin.css';
import { closeCreateModal, openCreateModal } from '../../redux/action-creators/users';
import { setCurrentTab } from '../../redux/actions/templateActions';
import StaffCreateForm from '../../pages/Staff/StaffCreateForm';
import ReviewerCreateForm from '../../pages/Admin/ReviewerCreateForm';
import ClientCreateForm from '../../pages/Company/ClientCreateForm';
import UserCreateForm from '../../pages/ClientUsers/UserCreateForm';
import CaseTypeCreateForm from '../../pages/Entities/CaseTypes/CaseTypeCreateForm';
import PatientCreateForm from '../../pages/Patients/PatientCreateForm';
import CodingSystemCreateForm from '../../pages/MedicalServices/CodingSystems/CodingSystemCreateForm';
import CodeCreateForm from '../../pages/MedicalServices/Codes/CodeCreateForm';
import ProcedureCreateForm from '../../pages/MedicalServices/Procedures/ProcedureCreateForm';
import DiagnoseCreateForm from '../../pages/MedicalServices/Diagnoses/DiagnoseCreateForm';
import PropTypes from 'prop-types';
import SpecialtyCreateForm from '../../pages/BasicEntities/Specialties/SpecialtyCreateForm';
import FacilityCreateForm from '../../pages/Facility/FacilityCreateForm';
import FacilityTypeCreateForm from '../../pages/Facility/FacilityTypeCreateForm';
import PhysicianCreateForm from '../../pages/Physician/PhysicianCreateForm';

import TemplateCreateButton from '../../pages/Template/TemplateCreateButton';
import CreateBillingGroupButton from '../../pages/BillingGroups/Buttons/CreateBillingGroupButton';
import ExcelExportButton from '../../pages/FinancialReport/Button/ExcelExportButton';
import CaseFileTemplateCreateForm from '../../pages/CaseFileTemplate/CaseFileTemplateCreateForm';
// import CreateNotificationTemplateButton from "../../pages/NotificationTemplate/CreateNotificationTemplateButton";
import NotificationTemplateCreateForm from '../../pages/NotificationTemplate/NotificationTemplateCreateForm';


class CreateEntity extends React.Component {
    constructor(props) {
        super(props);
        this.getModalInitInfo = this.getModalInitInfo.bind(this);
        this.getLink = this.getLink.bind(this);
        Modal[ 'setAppElement' ]('body');
    }

    getModalInitInfo() {
        if (this.props.currentPage.indexOf('coding_system/') === 1) {
            return {
                form: <CodeCreateForm/>,
                buttonColor: 'btn-success',
                entity: 'common.form.add_code',
                icon: 'fa-file-text',
                permission: ['MANAGE_SUR'],
            }
        } else {
            switch (this.props.currentPage) {
                case '/staff':
                    return {
                        form: <StaffCreateForm/>,
                        buttonColor: 'btn-primary',
                        entity: 'common.users.add_staff',
                        icon: 'fa-user'
                    };
                case '/reviewers':
                    return {
                        form: <ReviewerCreateForm/>,
                        buttonColor: 'btn-primary',
                        entity: 'common.users.add_reviewer',
                        icon: 'fa-user-md'
                    };
                case '/clients':
                    return {
                        form: <ClientCreateForm/>,
                        buttonColor: 'btn-success',
                        entity: 'common.client.add_client',
                        icon: 'fa-building',
                        permission: ['MANAGE_CLIENT'],
                    };
                case '/case_file_templates':
                    return {
                        form: <CaseFileTemplateCreateForm/>,
                        buttonColor: 'btn-primary',
                        entity: 'common.case.add_case_file_template',
                        // icon: 'fa-building'
                    };
                case '/submitters':
                    return {
                        form: <UserCreateForm/>,
                        buttonColor: 'btn-warning',
                        entity: 'common.users.add_user',
                        icon: 'fa-user',
                        permission: ['MANAGE_MY_COMPANY_USERS']
                    };
                // return <UserCreateForm defaultCompany={this.props.company} showCompanies={this.props.showCompanies}/>
                case '/case_types':
                    return {
                        form: <CaseTypeCreateForm/>,
                        buttonColor: 'btn-primary',
                        entity: 'common.form.add_case_type',
                        icon: 'fa-th-list',
                        permission: ['MODIFY_CASE_TYPE'],
                    };
                case '/patients':
                    return {
                        form: <PatientCreateForm/>,
                        buttonColor: 'btn-primary',
                        entity: 'common.form.add_patient',
                        icon: 'fa-user',
                        permission: ['MODIFY_PATIENTS'],
                        role: ['ROLE_ADMIN', 'ROLE_CLIENT_ADMIN', 'ROLE_CLIENT_USER', 'ROLE_CLIENT_SUPERVISOR'],
                    };
                case '/medical_services':
                    switch (this.props.mcSystemCurrentTab) {
                        case 'codes':
                            return {
                                form: <CodingSystemCreateForm/>,
                                buttonColor: 'btn-success',
                                entity: 'common.form.add_coding_system',
                                icon: 'fa-file-text',
                                permission: ['MANAGE_SUR'],
                                role: ['ROLE_ADMIN', 'ROLE_CLIENT_ADMIN'],
                            };
                        case 'procedures':
                            return {
                                form: <ProcedureCreateForm/>,
                                buttonColor: 'btn-success',
                                entity: 'common.form.add_service',
                                icon: 'fa-file-text',
                                permission: ['MANAGE_SUR'],
                                role: ['ROLE_ADMIN', 'ROLE_CLIENT_ADMIN'],
                            };
                        case 'diagnoses':
                            return {
                                form: <DiagnoseCreateForm/>,
                                buttonColor: 'btn-success',
                                entity: 'common.form.add_diagnosis',
                                icon: 'fa-file-text',
                                permission: ['MANAGE_SUR'],
                                role: ['ROLE_ADMIN', 'ROLE_CLIENT_ADMIN'],
                            }
                        default:
                            return null;
                    }

                case '/specialties':
                    return {
                        form: <SpecialtyCreateForm/>,
                        buttonColor: 'btn-success',
                        entity: 'common.form.add_specialty',
                        icon: 'fa-file-text',
                        permission: ['MODIFY_SPECIALTIES'],
                    };

                case '/facilities':
                    switch (this.props.facilitiesCurrentTab) {
                        case '1':
                            return {
                                form: <FacilityCreateForm/>,
                                buttonColor: 'btn-success',
                                entity: 'common.form.add_facility',
                                icon: 'fa-hospital-o',
                                permission: ['MODIFY_FACILITIES'],
                            };
                        case '2':
                            return {
                                form: <FacilityTypeCreateForm/>,
                                buttonColor: 'btn-success',
                                entity: 'common.form.add_facility_type',
                                icon: 'fa-file-text',
                                permission: ['MODIFY_FACILITIES'],
                            }
                        default:
                            return null;
                    }

                case '/physicians/':
                case '/physicians':
                    return {
                        form: <PhysicianCreateForm/>,
                        buttonColor: 'btn-success',
                        entity: 'common.form.add_physician',
                        icon: 'fa-file-text',
                        permission: ['MODIFY_PHYSICIANS'],
                    }
                case '/notification_templates':
                    return {
                        form: <NotificationTemplateCreateForm/>,
                        buttonColor: 'btn-success',
                        entity: 'common.form.add_notification_template',
                        icon: 'fa-envelope-open'
                    }
                default:
                    return null;
            }
        }
    }

    getLink() {
        switch (this.props.currentPage) {
            case '/fields':
                return (
                    <Link
                        className="btn resizable-button tooltip-button btn-primary margin-left-5"
                        to='fields/create'>
                        <i className={`fa fa-align-justify`}/> {this.context.translate('common.cases.add_field')}
                    </Link>
                );
            //case: '/notification_template':
            //return <CreateNotificationTemplateButton/>;
            case '/templates':
                return <TemplateCreateButton/>;
            case '/billing_groups':
                return <CreateBillingGroupButton/>;
            case '/financial_reports':  
                return <ExcelExportButton/>;
            case '/case_reports':  
                return <ExcelExportButton/>;    
            default:
                return '';
        }
    }

    render() {
        const modalInfo = this.getModalInitInfo();
        if (modalInfo) {
            const { buttonColor, entity, icon, form, permission, role } = modalInfo;
            if (!(role && role.includes(this.props.my_role))) {
                if (permission && !this.props.my_permissions.some(r => permission.includes(r))) {
                    return (<></>);
                }
            }
            return (
                <span>
                  <button className={`btn resizable-button tooltip-button ${buttonColor} margin-left-5`}
                          onClick={this.props.modalOpen}> <i
                      className={`fa ${icon}`}/> {this.context.translate(entity)}</button>
                  <Modal
                      isOpen={this.props.isOpen}
                      contentLabel="onRequestClose Example"
                      className="custom-modal modal-dialog"
                      overlayClassName="modal-overlay"
                  >
            <h3 className="modal-title text-center"> {this.context.translate(entity)}
                <i className='fa fa-times close' onClick={this.props.modalClose}/>
            </h3>
            <div className='white-bg'>
              {form}
            </div>
          </Modal>
        </span>
            );
        } else return <span>{this.getLink()}</span>
    }
}

CreateEntity.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    // console.log('mapStateToProps', state.app);
    return ({
        isOpen: state.app.users.createModalOpen,
        currentPage: state.router.location.pathname,
        templateCurrentTab: state.app.templates.currentTab,
        mcSystemCurrentTab: state.app.mcSystem.currentTab,
        facilitiesCurrentTab: state.app.facilities.listCurrentTab,
        my_role: state.app.auth.user.roleCode,
        my_permissions: state.app.users.my_permissions,
    })
};

const mapDispatchToProps = dispatch => ({
    modalOpen: () => dispatch(openCreateModal()),
    modalClose: () => dispatch(closeCreateModal()),
    setCurrentTemplateTab: (tab) => dispatch(setCurrentTab(tab))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEntity)
