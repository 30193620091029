import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { toolTipId } from '../../utils/helpers';
import { sortDirections } from './dataTableUtils';
import DataTableContext from './DataTableContext';
import { isArray, zipObject } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// eslint-disable-next-line react/prop-types
const UpArrow = ({ hidden, active }) =>
  hidden ? null : (
    <span className="dropdown">
      <span
        className="caret"
        style={{
          margin: '10px 0px 10px 5px',
          color: active ? '#333' : 'rgb(204, 204, 204)',
        }}
      />
    </span>
  );

// eslint-disable-next-line react/prop-types
const DownArrow = ({ hidden, active }) =>
  hidden ? null : (
    <span className="dropup">
      <span
        className="caret"
        style={{
          margin: '10px 0',
          color: active ? '#333' : 'rgb(204, 204, 204)',
        }}
      />
    </span>
  );

const DataTableHeader = ({
  children,
  onSort,
  sortBy,
  sortDirection,

  actionsTitle,
}) => {
  const { selectable,
    onRowSelectAll,
    selectAllValue,
    setSelectAllValue,
    singleSelect,
    columns,
    columnKeys,
    hasActions,
    hasDelete,
  } = useContext(DataTableContext);

  return (
    <>
      <thead>
        <tr>
          {selectable && (
            <th
              className="datatable__header datatable-select-all"
              style={{ border: +!singleSelect }}
            >
              {!singleSelect && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={toolTipId('select-deselect-all')}>
                      {selectAllValue ? 'Deselect' : 'Select'} all
                    </Tooltip>
                  }
                >
                  <input
                    type="checkbox"
                    onChange={() => {
                      onRowSelectAll();
                      setSelectAllValue(!selectAllValue);
                    }}
                    checked={selectAllValue}
                  />
                </OverlayTrigger>
              )}
            </th>
          )}
          {Object.values(columns).map(({ label, sortable = true }, index) => {
            const sortDirectionObject = isArray(sortDirection)
              ? zipObject(sortBy, sortDirection)
              : { [sortBy]: sortDirection };

            return (
              <th
                onClick={sortable ? () => onSort(columnKeys[index]) : undefined}
                scope="col"
                className={classnames([
                  'datatable__header',
                  { sortable },
                  { 'not-sortable': !sortable },
                ])}
                key={label}
              >
                {label}
                {sortable && (
                  <span className="order">
                    <UpArrow active={sortDirectionObject[columnKeys[index]]}
                      hidden={
                        sortDirectionObject[columnKeys[index]] ===
                        sortDirections.ASC
                      }
                    />
                    <DownArrow
                      active={sortDirectionObject[columnKeys[index]]}
                      hidden={
                        sortDirectionObject[columnKeys[index]] ===
                        sortDirections.DESC
                      }
                    />
                  </span>
                )}
              </th>
            );
          })}

          {hasActions && <th className="datatable__header not-sortable action">{actionsTitle}</th>}
          {hasDelete && <th className="datatable__header not-sortable action">Remove</th>}
        </tr>
        {children}
      </thead>
    </>
  );
};

DataTableHeader.propTypes = {
  children: PropTypes.node,
  sortDirection: PropTypes.string.isRequired,
  sortBy: PropTypes.string,
  actionsTitle: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default DataTableHeader;
