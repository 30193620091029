import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './PageContainer.css';

const PageContainer = ({ children, className }) => {
  return (
    <div
      className={classnames(['container-fluid', 'page-container', className])}
    >
      {children}
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

PageContainer.defaultProps = {
  className: '',
};

export default PageContainer;
