import React from 'react';
import {connect} from 'react-redux';
import shortId from 'shortid'
import PropTypes from "prop-types";
import FormField from '../../components/FormField/index'
import {closeCreateModal} from '../../redux/action-creators/users'
import {getSelectFieldValue, idFromString} from '../../utils/helpers'
import {copyTemplate} from "../../redux/actions/templateActions";
import FormValidateWrapper from '../../containers/FormValidateWrapper';

//
class TemplateCopyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            originalTemplateCode: null,
            name: '',
        }
    }

    componentDidMount() {
        this.props.dispatchValidState()
    }

    Submit = (e) => {
        e.preventDefault();

        if (this.props.validator.allValid()) {
            const {originalTemplateCode, name} = this.state;
            const newTemplateCode = idFromString(shortId.generate());

            this.props.copyTemplate({
                originalTemplateCode: getSelectFieldValue(originalTemplateCode),
                name,
                names: {'EN': name},
                newTemplateCode

            })
        } else {
            this.props.showMessages()
        }

    };

    onChangeDisplayName = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value})
    };

    onChangeTemplate = (originalTemplateCode) => {
        this.setState({originalTemplateCode})
    };

    render() {
        let {name} = this.state;

        return (
            <form
                className='modal-content custom-modal-content'
            >
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            label="Template Name"
                            className="form-control"
                            name="name"
                            required
                            value={name}
                            onChange={this.onChangeDisplayName}
                            valids="required|unique"
                            validator={this.props.validator}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type="select"
                            label="Select Template"
                            className="required-field text-bg"
                            valids="required"
                            validator={this.props.validator}
                            options={this.props.templatesOptions}
                            value={this.state.originalTemplateCode}
                            onChange={this.onChangeTemplate}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right text-right">
                            <button
                                className="btn btn-flat btn-primary "
                                disabled={this.props.isLoading}
                                onClick={this.Submit}
                            >
                                Copy Template
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

TemplateCopyForm.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    const templateNames = state.app.templates.allTemplates.map((item) => item.name);

    return {
        templatesOptions: state.app.templates.allTemplates
            .map(template => ({
                value: template.code,
                label: template.name
            })),
        isLoading: state.app.templates.loading,
        error: state.app.templates.error,

        //VALIDATION options
        top: 0,
        customRules: {
            unique: {
                message: 'This Template already exists in the system.',
                rule: (val) => !templateNames.includes(val)
            }
        },

    }
};

const mapDispatchToProps = dispatch => ({
    closeCreateModal: () => {
        dispatch(closeCreateModal())
    },
    copyTemplate: (data) => {
        dispatch(copyTemplate(data))
    },

});


export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(TemplateCopyForm))
