/* eslint-enable react-hooks/exhaustive-deps */
/* eslint "react-hooks/exhaustive-deps": "error" */

import { isEmpty, zipObject } from 'lodash';
import { useState, useCallback } from 'react';

const useInnerFilter = ({ setCurrentPage, onSettingsChange, defaultSettings, columnKeys }) => {
    const [filters, setFilters] = useState({
        ...zipObject(columnKeys, Array(columnKeys.length).fill('')),
        ...defaultSettings.filters,
    });

    const [isFilterRowVisible, setFilterRowVisible] = useState(!Object.values(filters).every(isEmpty));

    const handleToggleFilter = useCallback(() => {
        setFilterRowVisible(!isFilterRowVisible);
    }, [isFilterRowVisible, setFilterRowVisible]);

    const handleFilterChange = useCallback(
        (filterKey, filterValue) => {
            const updatedFilters = { ...filters, [filterKey]: filterValue };
            setFilters(updatedFilters);
            onSettingsChange({ filters: updatedFilters, page: 1 });
            setCurrentPage(1);
        },
        [filters, onSettingsChange, setCurrentPage],
    );
    return { handleFilterChange, handleToggleFilter, isFilterRowVisible, filters };
};

export default useInnerFilter;
