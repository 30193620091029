import { useDispatch, useSelector } from 'react-redux';
import { getOne4Select } from '../../redux/actions/BasicEntityActions';
import React, { useCallback, useEffect, useState } from 'react';
import MySelect from './MySelect'
import InputGroupWrapper from './InputGroupWrapper';
import PropTypes from 'prop-types';
import { fillSelectedFacilityData, getAllCompanyFacilityMinimal  } from '../../redux/actions/facilitiesActions';

const RefFacility = ({
                      options,
                      creatableOptions,
                      tickMark,
                      name,
                      value,
                      labelLeft,
                      md, xs,
                      multi,
                      validLabel, label, labelAddon,
                      title, id,
                      valids, validator,
                      onChange,
                      // onBlur,
                      actioned, onActionClick,
                      required,
                      ...rest
                    }) => {

  let opt = (creatableOptions && creatableOptions.length > 0) ? options.concat(creatableOptions) : options;

  const dispatch = useDispatch();

  const [facilityCode, setFacilityCode] = useState(() => value);

  const {
    data, loading,
  } = useSelector((state) => {
    //console.log('***', state.app);
    return state.app.facilities;
  });

  const {
    company: companyCode,
  } = useSelector((state) => {
    // console.log('useSelector App:::', state.app.cases.editedCase);
    return state.app.cases.editedCase;
  });

  opt = data
    .map(({ name, code }) => ({
      label: name,
      value: code,
    })) || opt;

  useEffect(() => {
    // console.log('useEffect', facilityCode);
    dispatch(getAllCompanyFacilityMinimal(companyCode));

    if (facilityCode) {
      dispatch(getOne4Select(facilityCode, 'facility'))
        .then((data) => {
          dispatch(fillSelectedFacilityData(data));
        });
    }
  }, [dispatch, facilityCode, companyCode]);


  const handleChange = useCallback(
    (newFacility) => {
      let newFacilityCode = newFacility ? newFacility.value : null; // null is important because of TemplatesInner !!!
      setFacilityCode(newFacilityCode);
      onChange(newFacilityCode);
    },
    [onChange],
  );

  const emulatedLegacySelect = (
    <MySelect
      {...rest}
      id={id}
      options={opt}
      value={facilityCode}
      isLoading={loading}
      required={required}
      placeholder={'Select...'}
      onChange={handleChange}
    />
  );

  let classes = '';
  classes += `col-md-${labelLeft ? md - 2 : md}`;
  classes += ' col-xs-' + (xs || '12');

  return (
    <>
      {labelLeft && 
      <div className='col-md-2'>
        <label>
          {label}
          {tickMark}
        </label>
      </div>}
      <div className={classes}
           style={{ height: '71px' }} // need input same height in same row
           title={title || ''}
      >
        {!labelLeft && 
        <label htmlFor={id} className="required">
          {label} {labelAddon}
          {tickMark}
        </label>}
        <span
          style={{ display: 'none' }}>{validator && valids ? validator.message(validLabel || label, facilityCode, valids, 'text-danger') : ''}</span>
        {actioned ?
          <InputGroupWrapper name={name} icon='code' onActionClick={onActionClick}>{emulatedLegacySelect}</InputGroupWrapper> : emulatedLegacySelect}
      </div>
    </>
  );
};

RefFacility.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
};

RefFacility.defaultProps = {
  className: '',
  onChange: () => {
  },
};

export default RefFacility;
