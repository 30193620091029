const gridConfiguration = {
    sorting: {
        sortName: 'name',
        sortOrder: 'asc'
    }
};

const defaultState = {
    loading: false,
    loadedCount: 0,
    data: [],
    error: '',
    gridConfiguration: gridConfiguration,
    lastLoadedTime: 0,
};

const case_file_templates = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_CASE_FILE_TEMPLATE_LOADING':
            return {...state, loading: true, error: null};

        case 'FETCH_CASE_FILE_TEMPLATES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                data: action.payload,
                lastLoadedTime: Date.now(),
            });

        case 'FETCH_CASE_FILE_TEMPLATE_ERROR':
            return {...state, error: action.payload, loading: false};
        case 'FETCH_CREATE_CASE_FILE_TEMPLATE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                all: [...state.data, action.payload]
            });
        default:
            return state
    }
};

export default case_file_templates
