/* eslint-disable strict */

'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var trim = function trim(str) {
  return str.replace(/^\s+|\s+$/g, '');
};

exports.default = trim;
