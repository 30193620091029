import React from 'react'
import { connect } from 'react-redux'

import userActions from '../redux/action-creators/user'
import LoadingIndicator from '../components/LoadingIndicator'

class UsersPage extends React.Component {
	componentWillMount() {
		this.props.loadUsers()
	}

	createUser() {
		this.props.createUser({
			name: this.name.value,
			mail: this.mail.value,
			role: this.role.value,
		})
	}

	render() {
		if (this.props.users.loading) {
			return <LoadingIndicator height="64"/>
		}
		if (this.props.users.error) {
			return <p>
				Error while loading the users: "{this.props.users.error}"<br/>
				<button className="btn btn-sm btn-primary" onClick={this.props.loadUsers}>Retry</button>
			</p>
		}
		return <div className="users-grid">
			<table className="table">
				<tbody>
				<tr>
					<th>ID</th>
					<th>Name</th>
					<th>Email</th>
					<th>Role</th>
					<th></th>
				</tr>
				{this.props.users.data.map((u) => {
					if (u.deleting === true) {
						return <tr key={u.id} className="deleting">
							<td colSpan="5"><LoadingIndicator/></td>
						</tr>
					}
					return <tr key={u.id} className={u.saving ? 'saving' : ''}>
						<td>
							{u.saving ? <LoadingIndicator height="20"/> : u.id}
						</td>
						<td>{u.name}</td>
						<td>{u.mail}</td>
						<td>{u.role}</td>
						<td>
							{!u.saving ?
								<button className="btn btn-sm btn-danger" onClick={() => this.props.deleteUser(u.id)}>
									Delete</button>
								: null}
						</td>
					</tr>
				})}
				<tr>
					<td></td>
					<td><input type="text" ref={input => this.name = input}/></td>
					<td><input type="text" ref={input => this.mail = input}/></td>
					<td>
						<select type="checkbox" ref={input => this.role = input}>
							<option value="admin">Admin</option>
							<option value="staff">Staff</option>
						</select>
					</td>
					<td>
						<button className="btn btn-sm btn-primary" onClick={this.createUser.bind(this)}>Create</button>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	}
}

const mapState = (state, history) => {
	return {
		users: state.app.users
	}
}

const mapActions = dispatch => {
	return {
		loadUsers: (filters = null, pagination = null, sortBy = null) => {
			dispatch(userActions.load(filters, pagination, sortBy))
		},
		createUser: user => dispatch(userActions.create(user)),
		deleteUser: id => dispatch(userActions.delete(id)),
	}
}

export default connect(mapState, mapActions)(UsersPage)
