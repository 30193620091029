import React, { useEffect, useState } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { showValidation } from '../redux/actions/BasicEntityActions';
import { Button } from 'react-bootstrap';
import { useCallback } from 'react';

const PreventTransitionPromptNew = ({
  when,
  history,
  cancelAndDiscard,
  isFormValid,
  entity,
  saveChanges,
}) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState({ pathname: '/' });
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };

  const navigate = useCallback((path) => history.push(path), [history]);

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && when) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      if (lastLocation.pathname !== window.location.hash.replace('#', '')) {
        navigate(lastLocation.pathname);
      }
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  const saveAndClose = () => {
    handleConfirmNavigationClick();
    if (isFormValid) {
      saveChanges();
    } else {
      dispatch(showValidation(entity));
    }
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        isOpen={modalVisible}
        contentLabel="onRequestClose Example"
        onRequestClose={closeModal}
        className="custom-modal modal-dialog"
        overlayClassName="modal-overlay"
      >
        <form className="modal-content custom-modal-content">
          <h3 className="text-center">Do you want to save your changes ?</h3>
          <div className="text-center">
            <Button
              className="btn-grey margin-right-5 margin-top-5"
              onClick={saveAndClose}
              disabled={!isFormValid}
            >
              Save
            </Button>

            <Button
              className="btn-grey margin-left-5 margin-right-5 margin-top-5"
              onClick={handleConfirmNavigationClick}
            >
              Don’t Save
            </Button>
            <Button
              className="btn-grey margin-left-5 margin-top-5"
              onClick={() => {
                closeModal();
                cancelAndDiscard();
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

PreventTransitionPromptNew.defaultProps = {
  cancelAndDiscard: () => {},
  saveChanges: () => {},
  isFormValid: true,
  entity: '',
};

export default withRouter(PreventTransitionPromptNew);
