const defaultState = {
    loading: false,
    loadedCount: 0,
    data: [],
    error: '',
    lastLoadedTime: 0,
}

const languages = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_LANGUAGE_LOADING':
            return { ...state, loading: true }
        case 'FETCH_LANGUAGE_SUCCESS':
        case 'FETCH_LANGUAGES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            })

        case 'FETCH_LANGUAGE_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            }
        case 'FETCH_LANGUAGE_LOADED_COUNT':
            return { ...state, loadedCount: action.payload }

        case 'FETCH_LANGUAGE_ERROR':
            return { ...state, error: action.payload, loading: false }
        default:
    }

    return state
}

export default languages;
