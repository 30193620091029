import React, { Component } from "react";
import { connect } from 'react-redux'
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import FormField from '../../components/FormField'
import AddressInfoSection from '../../components/Sections/AddressInfoSection'
import { editCompany, editField, isAllValid } from '../../redux/actions/companiesActions'
import { getSelectFieldValue } from '../../utils/helpers'


class ClientEditGeneralInfo extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeDefaultLocale = this.onChangeDefaultLocale.bind(this);
        this.onChangeLanguagesCode = this.onChangeLanguagesCode.bind(this);
        this.onChangeDefaultLanguageCode = this.onChangeDefaultLanguageCode.bind(this);
    }

    onChange(e) {
        this.setState({ showSuccess: false });
        this.props.editField(e.target.name, e.target.value)
    }

    onChangeLanguagesCode(languageCodes) {
        this.props.editField('languageCodes', languageCodes);
    }

    onChangeDefaultLanguageCode(defaultLanguageCode) {
        this.setState({ showSuccess: false });
        this.props.editField('defaultLanguageCode', defaultLanguageCode)
    }

    onChangeState = (stateCode) => {
        this.props.editField('stateCode', stateCode)
    };

    onChangeCountry = (countryCode) => {
        this.props.editField('countryCode', countryCode);
        if (!countryCode || getSelectFieldValue(countryCode) + '' !== 'US') {
            this.props.editField('stateCode', null)
        }
    };

    onChangeDefaultLocale(defaultLocaleCode) {
        this.setState({ showSuccess: false });
        this.props.editField('defaultLocaleCode', defaultLocaleCode)
    }

    render() {
        const {
            fullName,
            shortName,
            address1,
            address2,
            countryCode,
            stateCode,
            city,
            postalCode,
            notes
        } = this.props.profile;
        let valid_names = 'required|unique:' + this.props.client_names;

        const { isEditable } = this.props;

        return (
            <Box color="green" classes="box-profile-first-row-height-show">
                <BoxHeader title={`Client - ${fullName}`} color="green" icon="fa-building"/>
                <BoxBody>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group bordered">
                            <div className="row">
                                <FormField
                                    md="12"
                                    type='label_field'
                                    label="Personal Information"
                                    className='margin-bottom-15 fontWeight800'
                                    style={{ fontSize: '1.1em' }}
                                />
                                <FormField
                                    md="4"
                                    label="Full Name"
                                    id="Full Name"
                                    className="form-control"
                                    required
                                    valids={valid_names}
                                    validator={this.props.validator}
                                    name="fullName"
                                    value={fullName}
                                    onChange={this.onChange}
                                    onBlur={this.props.dispatchValidStatus}
                                    disabled={!isEditable}
                                />
                                <FormField
                                    md="4"
                                    label="Short Name"
                                    id="Short Name"
                                    required
                                    valids="required|max:5"
                                    validator={this.props.validator}
                                    className="form-control"
                                    name="shortName"
                                    value={shortName}
                                    onChange={this.onChange}
                                    onBlur={this.props.dispatchValidStatus}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <AddressInfoSection
                            onChangeState={this.onChangeState}
                            onChangeCountry={this.onChangeCountry}
                            onChange={this.onChange}
                            address1={address1}
                            address2={address2}
                            city={city}
                            countryCode={countryCode}
                            stateCode={stateCode}
                            postalCode={postalCode}
                            isEditable={isEditable}
                        />
                        <div className="form-group bordered">
                            <div className='row'>
                                <FormField
                                    md="8"
                                    type="select"
                                    label="Supported Case languages"
                                    id="Languages"
                                    searchable
                                    multi={true}
                                    options={this.props.languages}
                                    name="languageCodes"
                                    value={this.props.company.languageCodes}
                                    onChange={this.onChangeLanguagesCode}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <div className="form-group bordered">
                            <div className='row'>
                                <FormField
                                    md="8"
                                    type="select"
                                    label="Default Case Language"
                                    id="Default Case Language"
                                    valids="required"
                                    validator={this.props.validator}
                                    searchable
                                    options={this.props.languages}
                                    name="defaultLanguageCode"
                                    value={this.props.company.defaultLanguageCode}
                                    onChange={this.onChangeDefaultLanguageCode}
                                    onBlur={this.props.dispatchValidStatus}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className='col-md-8 fields'>
                                    <label className="required">Notes</label>
                                    <textarea
                                        name="notes" id="" cols="50" rows="8"
                                        onChange={this.onChange}
                                        disabled={!isEditable}
                                        value={notes}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </form>
                </BoxBody>
                <BoxFooter>
                </BoxFooter>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.app.companies.editedCompany,
        error: state.app.companies.editGeneralError,
        isLoading: state.app.companies.loading,
        timezones: state.app.timezone.data.filter((item) => item.active).map(tz => ({ value: tz.code, label: tz.name })),
        countries: state.app.countries.data
            .map(country => ({
                value: country.code,
                label: country.name
            })),
        company: state.app.companies.editedCompany,
        languages: state.app.languages.data
            .filter((item) => item.active)
            .map(lang => ({
                value: lang.code,
                label: lang.name
            })),
        locales: state.app.locales.data
            .filter((item) => item.active)
            .map(locale => ({
                value: locale.code,
                label: locale.displayName
            })),
        templates: state.app.templates.allTemplates
            .filter((item) => item.active)
            .map(template => ({
                value: template.code,
                label: template.name
            })),
        client_names: state.app.companies.data
            .map((item) => {
                return item.fullName;
            }).join(',')
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveProfile: data => dispatch(editCompany(data, 'admin')),
        editField: (field, value) => dispatch(editField(field, value)),
        isAllValid: (valid) => dispatch(isAllValid(valid)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientEditGeneralInfo);
