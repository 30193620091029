import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import helperIcon from '../../images/help_icon.svg';
import PropTypes from "prop-types";

const HelperText = (props) => {

  const iconStyle = { 
    display: 'inline', 
    marginLeft: '7px',
    height: '16px',
    verticalAlign: 'text-top',
  }

  const helperId = props.id ? props.id : 'helper-' + (100000 + (Math.random() * 100000));

  return (
    <OverlayTrigger
      placement="right"
      overlay={    
        <Popover id={helperId}>
          <div dangerouslySetInnerHTML={{__html: props.helperText}} />
        </Popover>}
      trigger={['hover', 'click']}
    >
      <img alt='Helper text' src={helperIcon} style={iconStyle} id="helper"/>
    </OverlayTrigger>
  );

}

HelperText.propTypes = {
    id: PropTypes.string,
    helperText: PropTypes.string,
}

export default HelperText;
