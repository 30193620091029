import api from "../../utils/api";
import {fieldsErrors} from "./statuses";
import { getMultiSelectValue, getSelectFieldValue, isEntityLoaded } from '../../utils/helpers';
import history from '../history'

const isAvailableInCache = (templates) =>
    isEntityLoaded(templates) && templates.allTemplates && templates.allTemplates.length > 0;

export const getTemplates = (templates) => (dispatch) => {
    if (isAvailableInCache(templates)) {
        return;
    }
    dispatch({
        type: 'FETCH_TEMPLATES_LOADING',
    });
    api.get('template/all')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_TEMPLATES_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_TEMPLATE_ERROR',
                    payload: fieldsErrors[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_TEMPLATE_ERROR',
                    payload: error.message
                });
            }
        })

};

export const getFullTemplate = (templateCode) => (dispatch) => {
    dispatch({
        type: 'FETCH_TEMPLATE_LOADING',
    });

    return api.get('template/full?template_code=' + templateCode)
        .then((response) => {
            const {data} = response;
            dispatch({
                type: 'FETCH_TEMPLATE_FULL_SUCCESS',
                payload: data
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_TEMPLATE_ERROR',
                    payload: fieldsErrors[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
                dispatch({
                    type: 'FETCH_TEMPLATE_ERROR',
                    payload: error.message
                });
            } else {
                console.log('Error ', error.message);

            }
        })
};

export const getCaseTemplate = (caseCode) => (dispatch) => {
    // dispatch({
    //     type: 'FETCH_TEMPLATE_LOADING',
    // })
    dispatch({
        type: 'FETCH_CASE_TEMPLATE_LOADING',
    });

    return api.get('template/case?case_code=' + caseCode)
        .then((response) => {
            const {data} = response;
            dispatch({
                type: 'FETCH_TEMPLATE_FULL_SUCCESS',
                payload: data,
                caseCode: caseCode
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_TEMPLATE_ERROR',
                    payload: fieldsErrors[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
                dispatch({
                    type: 'FETCH_TEMPLATE_ERROR',
                    payload: error.message
                });
            } else {
                console.log('Error ', error.message);

            }
        })
};

export const clearEditedTemplate = () => {
    return {
        type: 'FETCH_TEMPLATE_FULL_CLEAR',
        payload: ''
    }
};

export const createTemplate = (template) => (dispatch) => {
    dispatch({
        type: 'FETCH_TEMPLATE_LOADING',
    });

    return api.post('template/create', template)
        .then(() => {
            return dispatch({
                type: 'FETCH_CREATE_TEMPLATE_SUCCESS',
                payload: template
            })
        })
        .then(() => history.push(`templates/update/${template.code}`))
        .catch((err) => {
            dispatch({
                type: 'FETCH_CREATE_TEMPLATE_ERROR',
                payload: fieldsErrors[err.response ? err.response.status : '400']
            })
        })
};

export const copyTemplate = (data) => (dispatch) => {
    dispatch({
        type: 'FETCH_COPY_TEMPLATE_LOADING',
    });

    return api.post('template/copy', data)
        .then(() => {

            dispatch({
                type: 'FETCH_COPY_TEMPLATE_SUCCESS',
            })
        })
        .then(() => history.push(`templates/update/${data.newTemplateCode}`))
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'FETCH_COPY_TEMPLATE_ERROR',
                payload: fieldsErrors[err.response ? err.response.status : '400']
            })
        })
};

export const updateTemplate = (template, closeAfterSave) => (dispatch) => {
    dispatch({
        type: 'FETCH_UPDATE_TEMPLATE_LOADING',
    });

    return api.post('template/update', {
        ...template,
        fieldInstanceCodes: getMultiSelectValue(template.fieldInstanceCodes),
        sectionCodes: getMultiSelectValue(template.sectionCodes),
        workflowCode: getSelectFieldValue(template.workflowCode),
    })
        .then(() => {
            dispatch({
                type: 'FETCH_UPDATE_TEMPLATE_SUCCESS',
                payload: template
            });
            closeAfterSave && history.goBack()
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_UPDATE_TEMPLATE_ERROR',
                payload: fieldsErrors[err.response ? err.response.status : '400']
            })
        })
};

export const updateFieldForReport = (template, closeAfterSave) => (dispatch) => {
  const outCome = new Map();          
  for (const [key, value] of Object.entries(template.fieldForReportDto.fieldReferences)) {
    outCome.set(key, value);
  }
  
  dispatch({
      type: 'FETCH_UPDATE_TEMPLATE_LOADING',
  });
  const convertedData = {
    templateId: template.code,
    fieldReferences: Object.fromEntries(outCome)

  }

  return api.post('template/saveFields', convertedData)
      .then(() => {
          dispatch({
              type: 'FETCH_UPDATE_TEMPLATE_SUCCESS',
              payload: template
          });
          closeAfterSave && history.goBack()
      })
      .catch((err) => {
          dispatch({
              type: 'FETCH_UPDATE_TEMPLATE_ERROR',
              payload: fieldsErrors[err.response ? err.response.status : '400']
          })
      })
};

// export const getLastVersionTemplate = (templateCode) => (dispatch) => {
//     dispatch({
//         type: 'FETCH_TEMPLATE_LOADING',
//     })
//
//     api.get('template/tv_last?template_code=' + templateCode)
//         .then((response) => {
//             const {data} = response;
//             dispatch({
//                 type: 'FETCH_TEMPLATE_LAST_VERSION_SUCCESS',
//                 payload: data
//             })
//         })
//         .catch((error) => {
//             if (error.response) {
//                 dispatch({
//                     type: 'FETCH_TEMPLATE_ERROR',
//                     payload: fieldsErrors[error.response.status]
//                 })
//             } else if (error.request) {
//                 console.log(error.request);
//                 dispatch({
//                     type: 'FETCH_TEMPLATE_ERROR',
//                     payload: error.message
//                 });
//             } else {
//                 console.log('Error ', error.message);
//
//             }
//         })
// }

export const getSection = (sectionCode) => (dispatch) => {
    dispatch({
        type: 'FETCH_SECTION_LOADING',
    });

    return api.get(`section/get?code=${sectionCode}`)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_SECTION_PROFILE_SUCCESS',
                payload: data
            })
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'FETCH_SECTION_PROFILE_ERROR',
                payload: fieldsErrors[err.response ? err.response.status : '0']
            })
        })
};

export const removeSection = (sectionCode) => (dispatch) => {
    dispatch({
        type: 'FETCH_SECTION_LOADING',
    });

    return api.post(`section/remove?code=${sectionCode}`)
        .then(() => {
            dispatch({
                type: 'FETCH_REMOVE_SECTION_SUCCESS',
                payload: sectionCode
            });
            dispatch({
                type: 'CLOSE_EDIT_MODAL',
            })
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'FETCH_REMOVE_SECTION_ERROR',
                payload: 'This section contains fields. You can not delete it'
            })
        })
};

export const createNewSection = (section) => (dispatch) => {
    dispatch({
        type: 'FETCH_CREATE_SECTION_LOADING',
    });

    return api.post('section/create', section)
        .then(() => {

            dispatch({
                type: 'FETCH_CREATE_SECTION_SUCCESS',
                payload: section
            });
            dispatch({
                type: 'CLOSE_CREATE_MODAL',
            })

        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_CREATE_SECTION_ERROR',
                payload: fieldsErrors[err.response ? err.response.status : '0']
            })
        })
};

export const updateSection = (section) => (dispatch) => {
    dispatch({
        type: 'FETCH_UPDATE_SECTION_LOADING',
    });

    return api.post('section/update', section)
        .then(() => {
            dispatch({
                type: 'FETCH_UPDATE_SECTION_SUCCESS',
                payload: section
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_UPDATE_SECTION_ERROR',
                payload: fieldsErrors[err.response ? err.response.status : '400']
            })
        })
};

export const getFieldInstance = code => dispatch => {
    dispatch({
        type: 'FETCH_FIELD_INSTANCE_LOADING',
    });


    return api.get(`field_instance/get?code=${code}`)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_FIELD_INSTANCE_SUCCESS',
                payload: data
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_FIELD_INSTANCE_ERROR',
                payload: fieldsErrors[err.response ? err.response.status : '400']
            })
        })
};

export const createFieldInstance = (field) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_CREATE_FIELD_INSTANCE_LOADING',
    });
    const template = getState().app.templates;

    return new Promise((resolve, reject) => {
        api.post('field_instance/create', {...field, templateCode: template.code})
            .then(() => {
                dispatch({
                    type: 'FETCH_CREATE_FIELD_INSTANCE_SUCCESS',
                    payload: field
                });
                history.push(`/templates/update/${template.code}`);
                resolve()
            })
            .catch((err) => {
                dispatch({
                    type: 'FETCH_FIELD_INSTANCE_ERROR',
                    payload: fieldsErrors[err.response ? err.response.status : '400']
                });
                reject(err)
            })
    })

};

export const createFieldAndInstance = (field) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_CREATE_FIELD_INSTANCE_LOADING',
    });

    const instanceCode = field.code + '_' + getState().app.templates.code;

    return new Promise((resolve, reject) => {
        api.post('field/create', {...field, code: field.code})
            .then(() => {
                dispatch({
                    type: 'FETCH_CREATE_FIELD_SUCCESS',
                    payload: field
                });

                createFieldInstance({...field, code: instanceCode, originalFieldCode: field.code,})(dispatch, getState);
                resolve()
            })
            .catch((err) => {
                dispatch({
                    type: 'FETCH_FIELD_INSTANCE_ERROR',
                    payload: fieldsErrors[err.response ? err.response.status : '0']
                });
                reject(err)
            })
    })

};

export const updateFieldInstance = (data, closeAfterSave) => (dispatch) => {

    dispatch({
        type: 'FETCH_UPDATE_FIELD_INSTANCE_LOADING',
    });

    return api.post('field_instance/update', data)
        .then(() => {
            dispatch({
                type: 'FETCH_UPDATE_FIELD_INSTANCE_SUCCESS',
                payload: data
            });
            closeAfterSave && history.goBack()
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_FIELD_INSTANCE_ERROR',
                payload: fieldsErrors[err.response ? err.response.status : '0']
            })
        })
};

export const removeFieldInstance = (field) => (dispatch, getState) => {
    const {code} = field;
    let template = getState().app.templates;

    template = {
        ...template,
        fieldInstanceCodes: template.fieldInstancesDto.filter((item) => item.code !== code).map((item) => item.code)
    };

    dispatch({
        type: 'FETCH_UPDATE_FIELD_INSTANCE_LOADING',
    });

    updateTemplate(template)(dispatch)
        .then(() => {
            dispatch({
                type: 'FETCH_REMOVE_FIELD_INSTANCE_SUCCESS',
                payload: code
            });
            history.goBack()
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'FETCH_FIELD_INSTANCE_ERROR',
                payload: fieldsErrors[err.response ? err.response.status : '400']
            })
        })
};

export const editFieldInstance = (field, value) => {
    return {
        type: 'EDIT_CURRENT_INSTANCE_FIELD',
        payload: {
            [field]: value
        }
    }
};

export const editSection = (field, value) => {
    return {
        type: 'EDIT_CURRENT_SECTION',
        payload: {
            [field]: value
        }
    }
};

export const editTemplate = (field, value) => {
    return {
        type: 'EDIT_TEMPLATE_FIELD',
        payload: {
            [field]: value
        }
    }
};

export const editedFieldForReport = (field, value) => {
  return {
      type: 'EDIT_FIELDS_FOR_REPORT',
      payload: {
          [field]: value
      }
  }
};

export const editedOutCome = (value) => {
  return {
      type: 'EDIT_OUTCOME',
      payload: value
  }
};

export const setCurrentTemplate = (template) => {
    return {
        type: 'SET_CURRENT_TEMPLATE',
        payload: template
    }
};

export const setCurrentSection = (section) => {
    return {
        type: 'SET_CURRENT_SECTION',
        payload: section
    }
};

export const setCurrentField = (field) => {
    return {
        type: 'SET_CURRENT_FIELD_INSTANCE',
        payload: field
    }
};

export const setCurrentTab = (tab) => {
    return {
        type: 'SET_CURRENT_TEMPLATE_MANAGE_TAB',
        payload: tab
    }
};

export const isFieldValid = valid => {
    return {
        type: 'VALIDATE_FIELD_INSTANCE_FIELD',
        payload: valid
    }
};

export const isTemplateValid = valid => {
    return {
        type: 'VALIDATE_TEMPLATE_FIELD',
        payload: valid
    }
};

export const dispatchFIValidStatus = validStatus => {
    return {
        type: 'SHOW_FIELD_INSTANCE_VALIDATION',
        payload: validStatus
    }
};

export const dispatchTemplateValidStatus = validStatus => {
    return {
        type: 'SHOW_TEMPLATE_VALIDATION',
        payload: validStatus
    }
};

export const exportTemplate = (templateCode) => (dispatch) => {
    dispatch({
        type: 'EXPORT_TEMPLATE',
    });
    console.warn('exportTemplate', templateCode);
    return api.request({
        responseType: 'arraybuffer',
        url: 'template/export?template_code=' + templateCode,
        method: 'post',
        // data: data,
        timeout: 30000,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then((res) => {
        if (res.status === 200) {
            const name = res.headers['content-disposition'];
            const fileName = name ? name.substring(name.indexOf('filename=') + 9) : 'template-export.json';
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            dispatch({
                type: 'EXPORT_TEMPLATE_SUCCESS',
                payload: templateCode
            });
        } else {
            console.warn('EMPTY FILE');
            dispatch({
                type: 'EXPORT_TEMPLATE_ERROR',
                payload: templateCode
            });
        }
    }).catch((err) => {
        dispatch({
            type: 'EXPORT_TEMPLATE_ERROR',
            payload: fieldsErrors[err.response ? err.response.status : '400']
        });
    });
};

export const importTemplate = (data) => (dispatch) => {
    dispatch({
        type: 'FETCH_IMPORT_TEMPLATE_LOADING',
    });
    let newTemplateCode;
    return api.post('template/import', data)
        .then((response) => {
            newTemplateCode = response.data.newTemplateCode || 'unknown';
            dispatch({
                type: 'FETCH_IMPORT_TEMPLATE_SUCCESS',
            })
        })
        .then(() => {
            history.push(`templates/update/${newTemplateCode}`)
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'FETCH_IMPORT_TEMPLATE_ERROR',
                payload: fieldsErrors[err.response ? err.response.status : '400']
            })
        });
};
