import api from "../../../utils/api";
import {Statuses} from "../statuses";
import {isJSON} from "../../../utils/helpers";
import {setCaseMessage} from "./caseActions"
import filteredReviewersSlice from "../../pages/filtered-reviewers/filteredReviewersSlice";
import { pickBy } from "lodash";
import { isValidFilter } from "../../../components/Datatable/dataTableUtils";

export const getCaseAssignment = (code) => (dispatch) => {
    dispatch({
        type: 'FETCH_ASSIGNMENT_LOADING',
    });

    return api.get(`assignment/assignment_details?caseCode=${code}`)
        .then(({data}) => {
            // console.log('Ass Details', data);
            const assignedReviewers = data;
            api.get(`/assignment/default-tier-activation-date?caseCode=${code}`)
                .then(({data}) => {
                    dispatch({
                        type: 'FETCH_ASSIGNMENT_SUCCESS',
                        payload: assignedReviewers,
                        defaultActivationDates: data,
                        code: code
                    })
                })
                .catch(() => {
                    dispatch({
                        type: 'FETCH_ASSIGNMENT_SUCCESS',
                        payload: assignedReviewers,
                        defaultActivationDates: [],
                        code: code
                    });
                });
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_ASSIGNMENT_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_ASSIGNMENT_ERROR',
                    payload: error.message
                });
            }
        })
};

export const updateAssignmentDetails = (code, assignments) => (dispatch, getState) => {
    dispatch({
        type: 'UPDATE_ASSIGNMENT_DETAILS_LOADING',
    });
    let savedAssignments = [];
    for (let i = 0, len = assignments.length; i < len; i += 1) {
        let item = assignments[i];
        if (item.tier !== 'NO_TIER') {
            savedAssignments.push({
                acceptedCasesCount: item['acceptedCasesCount'],
                action: item['action'],
                activationTime: item['activationTime'],
                active: item['active'],
                assignmentTime: item['assignmentTime'],
                code: item['code'],
                tier: item['tier'],
                reviewerCode: item['user']
            });
        }
    }
    return api.post(`assignment/update_assignment_details?caseCode=${code}`, savedAssignments)
        .then(() => {
            const {code} = getState().app.cases.editedCase;
            dispatch(getCaseAssignment(code));
            dispatch({
                type: 'UPDATE_ASSIGNMENT_DETAILS_SUCCESS'
            })
            dispatch(filteredReviewersSlice.actions.changeSettings({ page: 1 }));
        })
        .catch((err) => {
            dispatch({
                type: 'UPDATE_ASSIGNMENT_DETAILS_ERROR',
                payload: Statuses[err.response] || Statuses[0]
            })
        });
};

export const getFiltersByCase = (code) => (dispatch) => {
    dispatch({
        type: 'FETCH_ASSIGNMENT_FILTER_LOADING',
    });

    return api.get(`reviewer_filter/get_for_case?patientCaseCode=${code}`)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_ASSIGNMENT_FILTER_SUCCESS',
                payload: data,
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_ASSIGNMENT_FILTER_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_ASSIGNMENT_FILTER_ERROR',
                    payload: error.message
                });
            }
        })
};

export const setDefaultFilter = () => (dispatch, getState) => {
    const {case_types, cases, case_assign, templates} = getState().app;
    let specialtiesDefaultValue;

    const {fieldInstancesDto} = templates;
    const {fieldData} = cases.values;
    const specialtiesField = fieldInstancesDto.find((item) => item.fieldType === 'REF_SPECIALTY');

    if (!specialtiesField) {
        specialtiesDefaultValue = null
    } else {
        const {code} = specialtiesField;
        specialtiesDefaultValue = fieldData[code] || null;
        if (isJSON(specialtiesDefaultValue)) {
            specialtiesDefaultValue = JSON.parse(fieldData[code]) || null;

        } else {
            specialtiesDefaultValue = specialtiesDefaultValue ? [specialtiesDefaultValue] : null
        }
    }

    const filter0 = {
        code: case_assign.currentFilters.code,
        reviewerScore: NaN,
        reviewerRate: NaN,
        countries: null,
        reviewerTypes: ['Network'],
        respectClientExclusions: true,
        respectClientInclusions: true,
        includeBackup: true,
        specialityFilteringType: 'ANY',
        caseTypeFilteringType: 'ANY',
        caseTypes: cases.editedCase.patientCaseType ? [cases.editedCase.patientCaseType] : case_types.types.map(({code}) => code),
        specialties: specialtiesDefaultValue
    };

    dispatch({
        type: 'SET_CURRENT_FILTER',
        payload: filter0
    });

    dispatch(updateAssignmentFilter(filter0, 0));

    const filter1 = Object.assign({}, filter0, {code: case_assign.appliedFilters.code});
    return dispatch(updateAssignmentFilter(filter1, 1)).then(() => {
        dispatch(filteredReviewersByFilter(
            filter1,
            cases.editedCase.code
        ));
    });
};

export const resetFilter = (code) => (dispatch) => {
    dispatch({
        type: 'FETCH_ASSIGNMENT_FILTER_LOADING'
    });
    return api.post(`/reviewer_filter/set-to-default?patientCaseCode=${code}`)
        .then((res) => {
            dispatch({
                type: 'FETCH_ASSIGNMENT_FILTER_SUCCESS',
                payload: res.data,
            })
            dispatch(filteredReviewersSlice.actions.changeSettings({ page: 1 }));
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_ASSIGNMENT_FILTER_ERROR',
                payload: (err.response && err.response.data && err.response.data.message) || Statuses[0]
            })
        })
}

export const updateAssignmentFilter = (filter, index) => (dispatch) => {
    dispatch({
        type: 'FETCH_ASSIGNMENT_FILTER_LOADING'
    });
    console.log('updateAssignmentFilter', filter);
    return api.post(`/reviewer_filter/update`, filter)
        .then(() => {
            dispatch({
                type: 'UPDATE_ASSIGNMENT_FILTER_SUCCESS',
                payload: filter,
                index: index
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_ASSIGNMENT_FILTER_ERROR',
                payload: Statuses[err.response.status] || Statuses[0]
            })
        })
};

export const updateApplyFilter = (caseCode, filter) => (dispatch) => {
    dispatch({
        type: 'FETCH_ASSIGNMENT_FILTER_LOADING'
    });
    return api.post(`/assignment/save_filter?caseCode=${caseCode}`, filter)
        .then(() => {
            dispatch(filteredReviewersSlice.actions.changeSettings({ page: 1 }));
            dispatch({
                type: 'UPDATE_ASSIGNMENT_FILTER_SUCCESS',
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_ASSIGNMENT_FILTER_ERROR',
                payload: Statuses[err.response.status] || Statuses[0]
            })
        })
};

export const editCurrentFilter = (field) => {
    return {
        type: 'EDIT_ASSIGN_FILTER',
        payload: field
    }
};

export const filteredReviewersByFilter = (appliedFilters, code) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_CASE_ASSIGN_LOADING',
    });

    const caseCode = code || getState().app.cases.editedCase.code;
    console.log('filteredReviewersByFilter', appliedFilters);
    return api.post(`/assignment/find_by_filter?caseCode=${caseCode}`, appliedFilters)
        .then(({data}) => {
            const filteredReviewers = data;

            api.get(`/assignment/default-tier-activation-date?caseCode=${caseCode}`)
                .then(({data}) => {
                    dispatch({
                        type: 'FETCH_ASSIGN_BY_FILTER_SUCCESS',
                        payload: {
                            filteredReviewers: filteredReviewers,
                            appliedFilters: appliedFilters,
                            tiersActivationDates: data
                        }
                    })
                })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_CASE_ASSIGN_ERROR',
                payload: Statuses[err.response.status] || Statuses[0]
            })
        })
};

export const caseUpdateAssignment = (detailCode, action) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_ASSIGNMENT_LOADING',
    });

    return api.post(`/assignment/update_action?detailCode=${detailCode}&action=${action}`)
        .then(() => {
            const {code} = getState().app.cases.editedCase;
            dispatch(getCaseAssignment(code));
            dispatch({
                type: 'UPDATE_ASSIGNMENT_SUCCESS',
                payload: {
                    code: detailCode,
                    action: action
                }
            });
            dispatch(setCaseMessage({
                'text': action === 'ACCEPTED' ? 'Case successfully accepted' : '',
                'type': 'success',
                'time': 3000
            }));
        })
        .catch((err) => {
            console.log(err);
            // dispatch({
            //     type: 'FETCH_CASE_ASSIGN_ERROR',
            //     payload: 'The case was accepted another Reviewer.'
            // })
            dispatch(setCaseMessage({
                'text': 'The case was accepted another Reviewer.',
                'type': 'error',
                'time': 3000

            }))
        })
};

export const getExtendedAssignments = (caseCode) => (dispatch) => {
    dispatch({
        type: 'FETCH_CASE_ASSIGN_LOADING',
    });

    return api.get(`/assignment/get_extended_assignments?caseCode=${caseCode}`)
        .then((res) => {
            // console.log('getExtendedAssignments', res);
            dispatch({
                type: 'FETCH_GET_EXTENDED_ASSIGNMENTS_SUCCESS',
                payload: res.data,
            });
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: 'FETCH_CASE_ASSIGN_ERROR',
                payload: (err.response && err.response.data && err.response.data.error) || Statuses[err.response] || Statuses[0]
            })
        })
};

export const updateExtendedAssignments = (detailCode, action) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_ASSIGNMENT_LOADING',
    });

    return api.post(`/assignment/update_action?detailCode=${detailCode}&action=${action}`)
        .then(() => {
            const {code} = getState().app.cases.editedCase;
            dispatch(getExtendedAssignments(code));
            dispatch(setCaseMessage({
                'text': action === 'ACCEPTED' ? 'Case successfully accepted' : '',
                'type': 'success',
                'time': 3000
            }));
        })
        .catch((err) => {
            console.log(err);
            // dispatch({
            //     type: 'FETCH_CASE_ASSIGN_ERROR',
            //     payload: 'The case was accepted another Reviewer.'
            // })
            dispatch(setCaseMessage({
                'text': 'The case was accepted another Reviewer.',
                'type': 'error',
                'time': 3000
            }))
        })
};

export const updateExtendedAssignmentsDetails = (code, assignments) => (dispatch, getState) => {
    dispatch({
        type: 'UPDATE_ASSIGNMENT_DETAILS_LOADING',
    });
    let savedAssignments = [];
    for (let i = 0, len = assignments.length; i < len; i += 1) {
        let item = assignments[i];
        if (item.tier !== 'NO_TIER') {
            savedAssignments.push({
                acceptedCasesCount: item.additionalInfo.acceptedCurrentMonth,
                action: item.action,
                activationTime: item.activationTime,
                active: item.active,
                assignmentTime: item.assignmentTime,
                code: item.code,
                tier: item.tier,
                reviewerCode: item.reviewerCode,
            });
        }
    }
    return api.post(`assignment/update_assignment_details?caseCode=${code}`, savedAssignments)
        .then(() => {
            const {code} = getState().app.cases.editedCase;
            dispatch(getExtendedAssignments(code));
            dispatch({
                type: 'UPDATE_ASSIGNMENT_DETAILS_SUCCESS'
            })
            // dispatch(filteredReviewersSlice.actions.changeSettings({ page: 1 }));
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: 'FETCH_CASE_ASSIGN_ERROR',
                payload: (err.response && err.response.data && err.response.data.error) || Statuses[err.response] || Statuses[0]
            })
        });
};

export const updateExtendedAssignmentsTier = (detailCode, tier) => (dispatch, getState) => {
    dispatch({
        type: 'UPDATE_ASSIGNMENT_DETAILS_LOADING',
    });
    return api.post(`assignment/update_tier?detailCode=${detailCode}&tier=${tier}`)
        .then((data) => {
            const {code} = getState().app.cases.editedCase;
            dispatch(getExtendedAssignments(code));
            dispatch(filteredReviewersSlice.actions.changeSettings({ page: 1 }));
            console.log('UPDATE ASSIGN DATE....', data);
        })
        .catch((err) => {
            dispatch({
                type: 'UPDATE_ASSIGNMENT_DETAILS_ERROR',
                payload: Statuses[err.response] || Statuses[0]
            })
        });
};

export const createAssignment = (caseCode, reviewerCode, tier) => (dispatch) => {
    dispatch({
        type: 'UPDATE_ASSIGNMENT_DETAILS_LOADING',
    });
    return api.post(`assignment/create?caseCode=${caseCode}&reviewerCode=${reviewerCode}&tier=${tier}`)
        .then(() => {
            dispatch(getExtendedAssignments(caseCode));
            dispatch(filteredReviewersSlice.actions.changeSettings({ page: 1 }));
        })
        .catch((err) => {
            dispatch({
                type: 'UPDATE_ASSIGNMENT_DETAILS_ERROR',
                payload: Statuses[err.response] || Statuses[0]
            })
        });
};

export const createAssignmentsTiers = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_CASE_ASSIGN_LOADING',
    });
    const {code} = getState().app.cases.editedCase;
    const {filters, sortBy, sortDirection, pageSize, page} = getState().app.filteredReviewersReducer.settings;
    const pageNumber = page - 1;
    const filtersForUrl = JSON.stringify(pickBy(filters, isValidFilter));
    const filtersEncodedForUrl = encodeURI(filtersForUrl);
    return api.post(`/assignment/create_tiers?patientCaseCode=${code}&sortBy=${sortBy}&sortDirection=${sortDirection}&pageSize=${pageSize}&pageNumber=${pageNumber}&filters=${filtersEncodedForUrl}`)
        .then(() => {
            // console.log('getExtendedAssignments', res);
            const {code} = getState().app.cases.editedCase;
            dispatch(getExtendedAssignments(code));
            dispatch(filteredReviewersSlice.actions.changeSettings({ page: 1 }));
            dispatch({
                type: 'UPDATE_ASSIGNMENT_FILTER_SUCCESS',
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_CASE_ASSIGN_ERROR',
                payload: Statuses[err.response] || Statuses[0]
            })
        })
};

export const updateAppliedReviewers = (reviewers) => ({
    type: 'UPDATE_APPLIED_REVIEWERS',
    payload: reviewers
});

export const updateTiersDates = (dates) => ({
    type: 'UPDATE_TIERS_DATES',
    payload: dates
});

export const saveDropDownData = (button, next) => ({
    type: 'SAVE_DROPDOWN_DATA',
    button: button,
    next: next
});

export const deleteExtendedAssignmentsNoneTierOne = (detailCode, tier) => (dispatch, getState) => {
    dispatch({
        type: 'UPDATE_ASSIGNMENT_DETAILS_LOADING',
    });
    return api.delete(`assignment/de_populate_all_tiers_except_first?detailCode=${detailCode}`)
        .then((data) => {
            const {code} = getState().app.cases.editedCase;
            dispatch(getExtendedAssignments(code));
            dispatch(filteredReviewersSlice.actions.changeSettings({ page: 1 }));
        })
        .catch((err) => {
            dispatch({
                type: 'UPDATE_ASSIGNMENT_DETAILS_ERROR',
                payload: Statuses[err.response] || Statuses[0]
        })
    });
};
