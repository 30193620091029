import { connect } from 'react-redux'
import {getAll, getOne4Select, setLoadedCount} from '../../redux/actions/BasicEntityActions'
import RefField from './RefField'

const mapStateToProps = (state) => ({
  isLoading:  state.app.timezone.loading,
  loadedCount: state.app.timezone.loadedCount,
  options: state.app.timezone.data.filter((item) => item.active).map(tz => ({value: tz.code, label: tz.name})),

})

const mapDispatchToProps = (dispatch) => ({
  getOptions: () => dispatch(getAll('time_zone')),
  getOne4Select: (code) => dispatch(getOne4Select(code, 'time_zone')),
  setLoadedCount: (count) => dispatch(setLoadedCount(count, 'time_zone'))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefField)
