import React from 'react'
import RemoteGrid from "../../../components/Grid/RemoteGrid";
import connect from "react-redux/es/connect/connect";
import {getAllSpecialties} from '../../../redux/actions/specialtyActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../../components/LoadingIndicator";

class SpecialtiesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mcSystem: '',
            params: {
                'page': 0,
                'size': 10,
                'sort': '',
                'dir': 'asc',
                'search': ''
            }
        };
        this.onRowClick = this.onRowClick.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
        this.filterFunction = this.filterFunction.bind(this);
    }

    componentDidMount() {
        this.props.getAllSpecialties();
    }

    onRowClick(item) {
        this.setState({item})
        this.props.history.push(`/specialties/${item}`)
    }

    enumFormatter(cell, row, enumObject) {
        return enumObject[cell];
    }

    filterFunction(cell) {
        let data = {
            'false': this.context.translate('common.form.no'),
            'true': this.context.translate('common.form.yes')
        };

        return data[cell];
    }

    render() {
        let cols = [
            {
                'isKey': true,
                'name': 'code',
                'title': 'Code',
                'hidden': true,
                'sort': false
            },
            {
                'name': 'name',
                'title': this.context.translate('common.form.name'),
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'primarySpecialityS',
                'title': this.context.translate('common.form.primary_specialty'),
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'no': this.context.translate('common.form.no'),
                    'yes': this.context.translate('common.form.yes')
                },
                'width': '200',
                'filter': {
                    type: 'SelectFilter', options: {
                        'no': this.context.translate('common.form.no'),
                        'yes': this.context.translate('common.form.yes')
                    }
                }
            },
            {
                'name': 'bodyParts',
                'title': this.context.translate('common.form.body_part_codes'),
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            },
            {
                'name': 'activeS',
                'title': this.context.translate('common.form.status'),
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': this.context.translate('common.form.inactive'),
                    'true': this.context.translate('common.form.active')
                },
                'width': '200',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': this.context.translate('common.form.inactive'),
                        'true': this.context.translate('common.form.active')
                    }
                }
            }
        ];
        const {isLoading} = this.props;
        const {sorting, pagination} = this.props.gridConfiguration
        return (
            <div className='container-fluid'>
                <div className="table-responsive" style={{marginTop: '15px', "padding": '10px'}}>
                    {isLoading && <LoadingIndicator type="lister"/>}
                    <RemoteGrid
                        color="green"
                        entity="specialties"
                        title={this.context.translate('common.form.specialties')}
                        data={this.props.specialties}
                        total={this.props.specialties.length}
                        columns={cols}
                        pagination={true}
                        page={pagination.page}
                        sizePerPage={pagination.sizePerPage}
                        search={true}
                        onRowClick={this.onRowClick}
                        defaultSort
                        sortName={sorting.sortName}
                        sortOrder={sorting.sortOrder}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        gridConfiguration: PropTypes.object,
    }
    static defaultProps = {
        isLoading: false
    };
}

SpecialtiesList.contextTypes = {
    translate: PropTypes.func
}

const mapState = (state) => {
    return {
        specialties: state.app.specialties.data
            .map(item => {
                return {
                    ...item,
                    primarySpecialityS: item.primarySpeciality === true ? 'yes' : 'no',
                    activeS: item.active === true ? 'true' : 'false'
                }
            }),
        gridConfiguration: state.app.grid.specialties,
        isLoading: state.app.specialties.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllSpecialties: () => dispatch(getAllSpecialties('specialty')),
    }
}

export default connect(mapState, mapDispatchToProps)(SpecialtiesList);
