import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../components/SubmitButton'
import PropTypes from "prop-types";
import { editFacilityType } from '../../redux/actions/facilitiesActions'
import { showValidation } from '../../redux/actions/BasicEntityActions'

class FacilityTypeSave extends React.Component {
    constructor (props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        e.preventDefault();
        if (this.props.isValid) {
            this.props.saveFacilityType({...this.props.facility_type,
                active:this.props.facility_type.active,
                code: this.props.facility_type.code
            }, closeAfterSave)
        } else {
            this.props.showValidation()
        }
    }

    render () {
        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isFacilityTypeChanged}
                    onSubmit={this.onSave}
                />
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isFacilityTypeChanged}
                    onSubmit={(e) => this.onSave(e, true)}
                    title='Save And Close'
                />
            </React.Fragment>)
    }
}

FacilityTypeSave.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    facility_type: state.app.facilities.editedFacilityType,
    isValid: state.app.facilities.facilityValid,
    isFacilityTypeChanged: state.app.facilities.isFacilityTypeChanged,
    isEditable: state.app.users.my_permissions.includes('MODIFY_FACILITIES'),
});

const mapDispatchToProps = dispatch => ({
    saveFacilityType: (data, closeAfterSave) => dispatch(editFacilityType(data, closeAfterSave)),
    showValidation: () => dispatch(showValidation('facility_type'))
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilityTypeSave)
