import { tierFormatter } from "../../../utils/helpers";

const { transformResponseToTableData } = require('components/Datatable/dataTableUtils');

const backupFormatter = (cell) => {
    switch (cell) {
        case true:
            return 'Yes';
        case false:
            return 'No';
        default:
            return ' ';
    }
};

const mbaFormatter = (maxBillableAmount, maxBillableAmountCurrencyCode) => {
    switch (maxBillableAmount) {
        case 'Unavailable':
            return 'N/A';
        default:
            return maxBillableAmount + ' ' + maxBillableAmountCurrencyCode;
    }
};

export const transformFilteredReviewers = transformResponseToTableData((data) => {
    return {
        data: data.map(({ code, acceptedCaseCountStatus, name, specialityNames, maxBillableAmount, maxBillableAmountCurrencyCode, qualityScore, countryName, recommendedTier, assignedTier, backUp, lastAssignment }) => ({
            code,
            acceptedCaseCountStatus,
            name,
            specialties: specialityNames,
            mba: mbaFormatter(maxBillableAmount, maxBillableAmountCurrencyCode),
            qualityScore,
            country: countryName,
            recommended: tierFormatter(recommendedTier),
            tier: tierFormatter(assignedTier),
            assignedTier,
            backup: backupFormatter(backUp),
            lastAssignment: lastAssignment,
        })),
    };
});
