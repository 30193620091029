import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { mfaAccessCodeForFirstLogin, mfaOptionsForFirstLogin, mfaPasswordChangeAtFirstLogin } from '../../../redux/actions/authActions';
import useInterval from './useInterval';
import {
  AccessCodeHint,
  AUTH_TYPE,
  Button,
  delayedApiCall,
  err,
  GoToLogin,
  log,
  PasswordHint,
  ReCaptcha,
  TWAuthLayout,
  TWFormElementAccessCode,
  TWFormElementAuthenticatorCode,
  TWFormElementPassword,
  TWInlineError,
  warn,
} from './utils';

const FORM_STATE = {
  INIT: 'INIT',
  SELECT: 'SELECT',
  AUTHENTICATOR1: 'AUTHENTICATOR1',
  AUTHENTICATOR2: 'AUTHENTICATOR2',
  PASSWORD: 'PASSWORD',
  ACCESS_CODE: 'ACCESS_CODE',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const FirstLogin2FAForm = ({ match, ...props }) => {
  const firstLoginKey = match.params.key;
  const dispatch = useDispatch();
  const newPasswordRef = useRef();
  const repeatNewPasswordRef = useRef();
  const accessCodeRef = useRef();
  const authenticatorCodeRef = useRef();
  const recaptchaRef = useRef();

  const [state, setStateOriginal] = useState({
    formState: FORM_STATE.INIT,
    isLoading: false,
    authOptions: [],
    authType: null,
    qrCodeLink: '',
    secretKey: '',
    recaptchaLoaded: false,
    needRecaptcha: false,
    needMfa: false,
    redirectPage: '',
    extEmailError: '',
    extPasswordError: '',
    extAccessCodeError: '',
    extAuthenticatorCodeError: '',
  });

  const setState = useCallback(
    (newState) => {
      setStateOriginal({ ...state, ...newState });
    },
    [setStateOriginal, state],
  );

  // const log = (message, ...vars) => (debug > 2 ? console.log(message, vars) : null);
  // const warn = (message, ...vars) => (debug > 1 ? console.warn(message, vars) : null);
  // const err = (message, ...vars) => (debug > 0 ? console.error(message, vars) : null);

  useEffect(() => {
    dispatch(mfaOptionsForFirstLogin({ firstLoginKey: firstLoginKey })).then(
      (result) => {
        log('mfaOptionsForRecoveryResult', result);
        if (!result) return;
        if (result.error) {
          err('ctaMfaOptions-ERROR', result.error);
          setState({ isLoading: false, formState: FORM_STATE.ERROR });
        } else if (result.authOptions) {
          setState({
            formState: result.authOptions.length > 0 ? FORM_STATE.SELECT : FORM_STATE.PASSWORD,
            isLoading: false,
            authOptions: result.authOptions,
            qrCodeLink: result.qrCodeLink,
            secretKey: result.secretKey,
            needRecaptcha: result.needRecaptcha,
            needMfa: result.authOptions.length > 0,
            extNewPassword: '',
            extRepeatNewPassword: '',
            extAccessCodeError: '',
            extAuthenticatorCodeError: '',
          });
        } else {
          setState({ isLoading: false, errors: { email: 'Something went wrong.' } });
        }
      },
      (e) => {
        setState({ isLoading: false, errors: { email: 'Unknown error.' } });
        err('2FA-ERROR', e);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateDtoForRequestCodeVia = () => {
      return { firstLoginKey: firstLoginKey, authType: authType };
  };

  const [accessCodeCounter, setAccessCodeCounter] = useState(0);
  useInterval(() => {
    if (formState === FORM_STATE.ACCESS_CODE && accessCodeCounter === 0) {
      warn('Access code has expired');
      setState({ formState: FORM_STATE.PASSWORD });
      return;
    }
    if (formState !== FORM_STATE.ACCESS_CODE || accessCodeCounter <= 0) {
      return;
    }
    // log('accessCodeCounter', accessCodeCounter);
    setAccessCodeCounter((currentCount) => currentCount - 1);
  }, 1000);

  
  const ctaMfaRequestCodeVia = () => {
    const dto = generateDtoForRequestCodeVia();
    if (!dto || !newPasswordRef.current.isValid() || !repeatNewPasswordRef.current.isValid()) {
      return;
    } else if (newPasswordRef.current.getValue() !== repeatNewPasswordRef.current.getValue()) {
        warn('generateDtoForFirstLogin PASSWORDS MUST MATCH', newPasswordRef.current.getValue(), repeatNewPasswordRef.current.getValue());
        setState({ extRepeatNewPasswordError: 'Passwords must match.' });
        return;
    }

    setState({ isLoading: true });
    delayedApiCall(() => {
      log('ctaMfaRequestCodeVia', dto);
      dispatch(mfaAccessCodeForFirstLogin(dto)).then(
        (result) => {
          if (result) {
            setState({
              formState: FORM_STATE.ACCESS_CODE,
              isLoading: false,
              extEmailError: '',
              extPasswordError: '',
              extAccessCodeError: '',
              extAuthenticatorCodeError: '',
            });
            setAccessCodeCounter(result.expireInSeconds);
          } else {
            setState({ isLoading: false, extPasswordError: 'Access Code Error' });
          }
        },
        (e) => {
          setState({ isLoading: false });
          err('error', e);
        },
      );
    });
  };

  const generateDtoForFirstLogin = (captchaValue) => {
    const newPassword = newPasswordRef.current;
    const repeatNewPassword = repeatNewPasswordRef.current;
    const accessCode = accessCodeRef.current;
    const authenticatorCode = authenticatorCodeRef.current;
    const result = {
      authType: authType === null ? AUTH_TYPE.NONE : authType,
      firstLoginKey: firstLoginKey,
      newPassword: newPassword.getValue(),
      reCaptchaValue: captchaValue,
    };
    const isValidNewPassword = newPassword && newPassword.isValid();
    const isValidRepeatNewPassword = repeatNewPassword && repeatNewPassword.isValid();
    if (newPassword.getValue() !== repeatNewPassword.getValue()) {
      warn('generateDtoForFirstLogin PASSWORDS MUST MATCH', newPassword.getValue(), repeatNewPassword.getValue());
      setState({ extRepeatNewPasswordError: 'Passwords must match.' });
      return null;
    } else {
      setState({ extRepeatNewPasswordError: '' });
    }
    if (needMfa) {
      if (authType === AUTH_TYPE.AUTHENTICATOR) {
        const isValidAuthenticatorCode = authenticatorCode && authenticatorCode.isValid();
        if (!isValidNewPassword || !isValidRepeatNewPassword || !isValidAuthenticatorCode) {
          warn('generateDtoForFirstLogin (AuthenticatorCode) - MISSING PARAM', result);
          return null;
        }
        result['authenticatorCode'] = authenticatorCode.getValue();
      } else if (authType === AUTH_TYPE.EMAIL || authType === AUTH_TYPE.SMS) {
        const isValidAccessCode = accessCode && accessCode.isValid();
        if (!isValidNewPassword || !isValidRepeatNewPassword || !isValidAccessCode) {
          warn('generateDtoForFirstLogin (AccessCode) - MISSING PARAM', result);
          return null;
        }
        result['accessCode'] = accessCode.getValue();
      }
    } else {
      if (!isValidNewPassword || !isValidRepeatNewPassword) {
        warn('generateDtoForFirstLogin - MISSING PARAM', result);
        return null;
      }
    }
    return result;
  };

  const ctaFirstLoginAuthenticator = (captchaValue) => {
    log('ctaFirstLoginAuthenticator', state);
    const dto = generateDtoForFirstLogin(captchaValue);
    if (!dto) {
      return;
    }
    setState({ isLoading: true });
    delayedApiCall(() => {
      log('firstLogin', dto);
      dispatch(mfaPasswordChangeAtFirstLogin(dto)).then(
        (result) => {
          log('recoveryPasswordResult', result);
          if (result && result.error) {
            let errorMessage;
            if (result.statusCode === 204) {
              errorMessage = 'This user is not existing in the system';
            } else {
              errorMessage = 'Invalid or expired password or authenticator code.';
              setState({ isLoading: false, extAccessCodeError: errorMessage });
            }
          } else if (result) {
            setState({ formState: FORM_STATE.SUCCESS, isLoading: false });
          } else {
            setState({ formState: FORM_STATE.ACCESS_CODE, isLoading: false });
          }
        },
        (e) => {
          setState({ isLoading: false });
          err('error', e);
        },
      );
    });
  };

  const ctaFirstLoginCaptchaAuthenticator = () => {
    log('ctaFirstLoginCaptchaAuthenticator', state, needRecaptcha, recaptchaRef);
    if (needRecaptcha && recaptchaRef) {
      const captcha = recaptchaRef.current;
      if (!generateDtoForFirstLogin()) {
        warn('Invalid form before captcha');
        return;
      }
      if (!captcha || !captcha.isReady()) {
        warn('Captcha is not ready');
        return;
      }
      captcha.callback((captchaValue) => {
        ctaFirstLoginAuthenticator(captchaValue);
      });
    } else {
      ctaFirstLoginAuthenticator();
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const {
      formState,
      isLoading,
      recaptchaLoaded,
      needRecaptcha,
      needMfa,
      qrCodeLink,
      secretKey,
      authType,
      extAccessCodeError,
      extNewPasswordError,
      extRepeatNewPasswordError,
      extAuthenticatorCodeError,
      authOptions
     } = state;

  const availableAuthOption = (option) => {
    return authOptions.includes(option);
  };

  return (
    <TWAuthLayout title={'First login'}>
      {formState === FORM_STATE.ERROR && (
        <>
          <TWInlineError errorMessage={'Invalid or expired URL. Please double check your received URL.'} />
          <GoToLogin />
        </>
      )}
      {formState === FORM_STATE.SUCCESS && (
        <>
          <p className={'font-medium mb-4'}>The registration process has been completed successfully. You can now use the normal login process.</p>
          <GoToLogin />
        </>
      )}
      <form className="space-y-4" action="#" method="POST" noValidate={true} onSubmit={onSubmit}>
        {/*select*/}
        {needMfa && formState === FORM_STATE.SELECT && state.authOptions.length > 0 && (
          <>
            <p className={'font-medium'}>Please select a multi-factor authentication (MFA)</p>
            <Button
              show={availableAuthOption(AUTH_TYPE.AUTHENTICATOR)} isLoading={isLoading}
              cta={() => setState({ formState: FORM_STATE.AUTHENTICATOR1, authType: AUTH_TYPE.AUTHENTICATOR })}
            >
              Google Authenticator
            </Button>
            <Button show={availableAuthOption(AUTH_TYPE.EMAIL)} isLoading={isLoading}
              cta={() => setState({ formState: FORM_STATE.PASSWORD, authType: AUTH_TYPE.EMAIL })}
            >
              Email MFA
            </Button>
            <Button show={availableAuthOption(AUTH_TYPE.SMS)} isLoading={isLoading}
              cta={() => setState({ formState: FORM_STATE.PASSWORD, authType: AUTH_TYPE.SMS })}
            >
              SMS MFA
            </Button>
          </>
        )}
        {needMfa && formState === FORM_STATE.AUTHENTICATOR1 && (
          <>
            <StepIndicator step={1} title={'Set up 2-factor authentication'} />

            <p className={'text-red-600'}>
              You must install Google Authenticator on your smartphone before you continue, otherwise you won&apos;t be able to login later.
            </p>
            <p className={'text-center'}>
              <strong>For installing use this QR-code:</strong>
            </p>
            <p className={'text-center py-4 bg-white border-b border-medrev-green'}>{qrCodeLink ? <img src={qrCodeLink} alt={'QR Code'} /> : ''}</p>
            <p className={'text-center'}>
              <strong>Or you can use the next code (if installing manually):</strong>
            </p>
            <p className={'text-center py-4 bg-white border-b border-medrev-green'}>
              <strong>{secretKey}</strong>
            </p>
            <p className={'text-red-600'}>Keep this code in a safe place, it can be useful if you lose your Google Authenticator application</p>

            <Button
              show={availableAuthOption(AUTH_TYPE.AUTHENTICATOR)}
              isLoading={isLoading}
              cta={() => setState({ formState: FORM_STATE.AUTHENTICATOR2, authType: AUTH_TYPE.AUTHENTICATOR })}
            >
              Continue
            </Button>
          </>
        )}
        {needMfa && formState === FORM_STATE.AUTHENTICATOR2 && (
          <>
            <StepIndicator step={2} title={'Verify 2-factor authentication'} />

            <p className={'text-red-600'}>
              Before proceeding, make sure you can see 6-digit codes appearing in Google Authenticator for your MedRev account as this step will not
              be repeatable once you have set a password for your account.
            </p>

            <StepIndicator step={3} title={'Create a secure password'} />

            {/*new-password*/}
            <PasswordHint show={true} />
            <TWFormElementPassword
              show={true}
              id={'2fa-new-password'}
              name={'newPassword'}
              ref={newPasswordRef}
              disabled={isLoading}
              autoFocus={true}
              title={'New password'}
              placeholder={'Your new password'}
              emptyErrorMessage={'Please enter your new password.'}
              invalidErrorMessage={'Please enter a valid new password.'}
              extError={extNewPasswordError}
              {...props}
            />
            {/*repeat-password*/}
            <TWFormElementPassword
              show={true}
              id={'2fa-repeat-new-password'}
              name={'repeatNewPassword'}
              ref={repeatNewPasswordRef}
              disabled={isLoading}
              title={'Repeat new password'}
              placeholder={'Your new password again'}
              emptyErrorMessage={'Please enter your new password again.'}
              invalidErrorMessage={'Please enter a valid new password again.'}
              extError={extRepeatNewPasswordError}
            />
            <TWFormElementAuthenticatorCode
              show={true}
              id={'2fa-authenticator-code'}
              ref={authenticatorCodeRef}
              disabled={isLoading}
              extError={extAuthenticatorCodeError}
            />
            {/*recaptcha*/}
            <Button show={availableAuthOption(AUTH_TYPE.AUTHENTICATOR)} isLoading={isLoading} cta={ctaFirstLoginCaptchaAuthenticator}>
              Finish registration
            </Button>
          </>
        )}

        {formState !== FORM_STATE.AUTHENTICATOR2 && (
          <>
            <PasswordHint show={formState === FORM_STATE.PASSWORD || formState === FORM_STATE.ACCESS_CODE} />
            
            <TWFormElementPassword
              show={formState === FORM_STATE.PASSWORD || formState === FORM_STATE.ACCESS_CODE}
              id={'2fa-first-login-password'}
              name={'password'}
              ref={newPasswordRef}
              disabled={isLoading}
              title={'Password'}
              placeholder={'Your password'}
              emptyErrorMessage={'Please enter your password.'}
              invalidErrorMessage={'Please enter a valid new password.'}
              extError={extNewPasswordError}
            />
        
            {/*repeat-password*/}
            <TWFormElementPassword
              show={formState === FORM_STATE.PASSWORD || formState === FORM_STATE.ACCESS_CODE}
              id={'2fa-first-login-repeat-new-password'}
              name={'repeatPassword'}
              ref={repeatNewPasswordRef}
              disabled={isLoading}
              title={'Repeat password'}
              placeholder={'Your password again'}
              emptyErrorMessage={'Please enter your password again.'}
              invalidErrorMessage={'Please enter a valid password again.'}
              extError={extRepeatNewPasswordError}
            />
        
        <AccessCodeHint show={needMfa && formState === FORM_STATE.PASSWORD} authType={authType} />
        <Button isLoading={isLoading && !recaptchaLoaded} cta={ctaMfaRequestCodeVia} show={needMfa && formState === FORM_STATE.PASSWORD}>
          {authType === AUTH_TYPE.SMS ? 'Request code via SMS' : 'Request code via Email'}
        </Button>
        </>
        )}

        {/*access code*/}
        {needMfa && formState === FORM_STATE.ACCESS_CODE && (
            <>
            <TWFormElementAccessCode
              show={true}
              id={'2fa-access-code'}
              ref={accessCodeRef}
              disabled={isLoading || formState !== FORM_STATE.ACCESS_CODE}
              accessCodeExpirationCounter={accessCodeCounter}
              extError={extAccessCodeError}
            />
            <p className={'mb-4 text-sm'}>{AUTH_TYPE.SMS === authType ?
              'Hint: Please enter the access code you received by SMS.' :
              'Hint: Please enter the access code you received by e-mail.'}</p>
            <Button isLoading={isLoading && !recaptchaLoaded} cta={ctaFirstLoginCaptchaAuthenticator} show={true}>
              Finish registration
            </Button>
            </>
        )}
        {/*Finish registration without 2FA*/}
        <Button isLoading={isLoading && !recaptchaLoaded} cta={ctaFirstLoginCaptchaAuthenticator} show={!needMfa && formState === FORM_STATE.PASSWORD}>
          Finish registration
        </Button>
        <ReCaptcha enabled={needRecaptcha} ref={recaptchaRef} />
      </form>
    </TWAuthLayout>
  );
};
FirstLogin2FAForm.propTypes = {
  match: PropTypes.object,
};

// TODO
const StepIndicator = ({ step, title }) => {
  return (
    <p className={'flex'}>
      <span className="stepIndicator">{step}</span>
      <span className="stepText">{title}</span>
    </p>
  );
};
StepIndicator.propTypes = {
  step: PropTypes.number,
  title: PropTypes.string,
};
