import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { attachFiles, getCase, getCaseFiles, setCaseMessage } from '../../../redux/actions/cases/caseActions';
import { isClient, isReviewer } from '../../../utils/helpers';
import { getAllCases } from '../../../redux/actions/cases/caseListActions';
import { patientCaseStages } from 'redux/pages/patient-case/patientCaseStages';
import { checkFileEncryption } from '../Attachments/attachmentUtils';
import FilePasswordModal from 'pages/FilePasswordModal';

const isValidPath = (path, currentUser) => {
    if (isReviewer(currentUser.roleCode)) {
        return path === '/accepted' || path === '/' || path === '/home';
    }
    const notValidPaths = ['/complete', '/closed'];
    return !notValidPaths.includes(path);
};

const isDisabled = (caseCodes, caseList, currentUser) => {
    if (!caseCodes.length) return true;

    const stages = caseCodes.map((code) => {
        let f_item = caseList.find((item) => item.code === code);
        if (typeof f_item !== 'undefined') {
            let fItem = caseList.find((item) => item.code === code);
            if (isReviewer(currentUser.roleCode)) return fItem['stage'] + '_' + fItem['assignmentAction'];
            return fItem['stage'];
        }
        return '';
    });
    if (isReviewer(currentUser.roleCode)) return stages.reduce((p, stage) => stage !== 'CW_REVIEW_ACCEPTED' && p, true);

    return stages.reduce((p, stage) => stage === 'CW_CLOSED' && p, true);
};

class GetSeveralAttachments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            modalFileName: '',
            modalPassword: '',
            resolveModalPassword: null,
        };

        this.onSaveFiles = this.onSaveFiles.bind(this);
        
        this.openFilePasswordModal = this.openFilePasswordModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
    }

    async openFilePasswordModal(filename) {
        return new Promise((resolve, reject) => {
            this.setState({ modalOpen: true, modalFileName: filename });
            this.resolveModalPassword = resolve;
        });
    };

    handleCloseModal() {
        this.setState({ modalOpen: false, modalFileName: '', modalPassword: '' });
        this.resolveModalPassword(null);
    }

    handleSubmitPassword(filePassword) {
        this.setState({ modalOpen: false, modalFileName: '', modalPassword: '' });
        this.resolveModalPassword(filePassword);
    }

    async onSaveFiles(savedFiles) {
        const { selectedCaseCodes, all } = this.props;
        let caseItems = [];
        for (let index = 0, len = selectedCaseCodes.length; index < len; index += 1) {
            let caseCode = selectedCaseCodes[index];
            let caseItem = all.find((item) => item.code === caseCode);
            if (isReviewer(this.props.currentUser.roleCode)) {
                if (caseItem.stage !== patientCaseStages.REVIEW || caseItem['assignmentAction'] !== 'ACCEPTED') continue;
            }
            if (caseItem.stage === patientCaseStages.CLOSED) continue;
            caseItems.push(caseItem);
        }
        let files = savedFiles.target.files;
        let filesLength = files.length;
        let len = caseItems.length;
        let count = 0;
        let maxCount = filesLength * len;

        const passwords = [];
        for (const file of files) {
            const isEncrypted = await checkFileEncryption(file);
            if (isEncrypted) {
                const password = await this.openFilePasswordModal(file.name);
                passwords.push(password);
            } else {
                passwords.push(null);
            }
        }

        // const attachSingleFile = (caseCode, type, i) => {
        //     this.props
        //         .attachFile({
        //             userCode: this.props.currentUser.code,
        //             code: caseCode,
        //             type: type,
        //             file: files[i],
        //         })
        //         .then(() => {
        //             this.props.getCase(caseCode);
        //             count += 1;
        //             if (count === maxCount) {
        //                 this.props.setCaseMessage({
        //                     text: 'Your file was attached successfully',
        //                     type: 'success',
        //                     time: 5000,
        //                 });
        //             }
        //         });
        // };
        for (let index = 0; index < len; index += 1) {
            const caseItem = caseItems[index];
            const caseCode = caseItem['code'];
            let type = 'UNKNOWN';
            if (isClient(this.props.currentUser.roleCode)) {
                type = 'CLIENT_ATTACHMENT';
            } else if (isReviewer(this.props.currentUser.roleCode)) {
                type = 'REVIEWER_ATTACHMENT';
            } else {
                type = 'STAFF_ATTACHMENT';
            }
            // for (let i = 0; i < filesLength; i += 1) {
            //     attachSingleFile(caseCode, type, i);
            // }

            this.props.attachFiles({
                userCode: this.props.currentUser.code,
                code: caseCode,
                type: type,
                files: files,
                passwords: passwords
            }).then((res) => {
                // It will show success even if a file or 2 fails.
                // These messages should reworked with different api responses,
                // to better inform the user about the processes.
                this.props.getCase(caseCode);
                count += files.length;
                if (count === maxCount) {
                    if (res < files.length) {
                        this.props.setCaseMessage({
                            text: `${files.length - res} file's upload went wrong!`,
                            type: 'error',
                            time: '5000',
                        });
                    } else {
                        this.props.setCaseMessage({
                            text: `All files were attached succesfully`,
                            type: 'success',
                            time: '5000',
                        });
                    }
                }
            });
        }
    }

    resetFileField(event) {
        event.target.value = null;
    }

    render() {
        const { modalOpen, modalFileName } = this.state;
        let uploadIsAvailable = !this.props.disabled;
        let title = 'Formats, available for uploading: pdf, jpg, jpeg, png, doc, docx, ppt, pptx';
        if (!uploadIsAvailable) {
            if (isReviewer(this.props.currentUser.roleCode)) {
                title = 'Select cases only in Accepted stage';
            } else {
                title = 'Select cases not in Closed stage';
            }
        }
        return (
            <span className={'upload_button ' + this.props.visibility_class} title={title}>
                <form className="md-form">
                    <label style={{ maxWidth: '50px' }}>
                        {uploadIsAvailable ? (
                            <input
                                type="file"
                                onChange={this.onSaveFiles}
                                onClick={this.resetFileField}
                                multiple
                                accept="image/jpeg,image/png,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,.doc,.docs,.ppt,.pptx,.pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                            />
                        ) : (
                            ''
                        )}
                        <span
                            className={`btn btn-default resizable-button tooltip-button margin-left-5`}
                            style={{
                                borderColor: 'rgb(221,221,221)',
                                cursor: uploadIsAvailable ? 'pointer' : 'not-allowed',
                            }}
                        >
                            <i className={`fa fa-paperclip`} />
                        </span>
                    </label>
                </form>
                {modalOpen && (
                        <FilePasswordModal
                            isOpen={modalOpen}
                            filename={modalFileName}
                            onCancel={this.handleCloseModal}
                            onSubmit={this.handleSubmitPassword}
                        />
                    )}
            </span>
        );
    }
}

GetSeveralAttachments.contextTypes = {
    translate: PropTypes.func,
};

const mapState = (state, ownProps) => {
    const { case_list } = state.app;
    let my_permissions = state.app.users.my_permissions;
    let currentUser = state.app.auth.user;
    const isVisible = isValidPath(ownProps.currentPage, currentUser) && (my_permissions.length === 0 || my_permissions.includes('MODIFY_CASES'));
    const visibility_class = isVisible ? 'visibility-button-flat' : 'visibility-button-none';

    const all = Object.values(state.app.patientCase).reduce((all, current) => [...all, ...current.data], []);

    const disabled = isDisabled(case_list.selectedCaseCodes, all, currentUser);
    return {
        all,
        selectedCaseCodes: case_list.selectedCaseCodes,
        currentUser: currentUser,
        my_permissions: my_permissions,
        modify_cases: my_permissions.includes('MODIFY_CASES'),
        files: state.app.cases.files,
        visibility_class: visibility_class,
        disabled: disabled,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCaseMessage: (data) => dispatch(setCaseMessage(data)),
        attachFiles: (code) => dispatch(attachFiles(code)),
        getAllCases: (role) => dispatch(getAllCases(role)),
        getCase: (code) => dispatch(getCase(code, true)),
        getCaseFiles: (code) => dispatch(getCaseFiles(code)),
    };
};

export default connect(mapState, mapDispatchToProps)(withRouter(GetSeveralAttachments));
