import api from "../../../utils/api";
import clientEditPhysicianSlice, {
    linkingEntities,
    linkingEntitiesError,
    linkingEntitiesSuccess,
    unlinkingEntities
} from "./clientEditPhysicianSlice";

export const linkClientEditPhysician = (selectedPhysicianCodes, storedSettings, search, getPageCode) => (dispatch) => {
    if (selectedPhysicianCodes && selectedPhysicianCodes.length === 0) {
        return;
    }
    dispatch(linkingEntities());
    const entity = {
        physicianCodes: selectedPhysicianCodes,
    };

    return api
        .post('physician/client_edit_physician/link?code=' + getPageCode, entity)
        .then((res) => {
            // console.log('res', res);
            dispatch(linkingEntitiesSuccess());
            dispatch(clientEditPhysicianSlice.actions.loadData({ ...storedSettings, globalSearch: search, code: getPageCode }));
        })
        .catch((error) => {
            // console.log('catch', error.response);
            dispatch(linkingEntitiesError());
            if (error.response) {
                // dispatch(fetchThreadListError(fieldsErrors[ error.response.status ]));
            } else if (error.request) {
                console.error(error.request);
                // dispatch(fetchThreadListError(error));
            } else {
                console.error('Error ', error.message);
            }
        });
}

export const unlinkClientEditPhysician = (selectedPhysicianCodes, storedSettings, search, getPageCode) => (dispatch) => {
    if (selectedPhysicianCodes && selectedPhysicianCodes.length === 0) {
        return;
    }
    dispatch(unlinkingEntities());
    const entity = {
        physicianCodes: selectedPhysicianCodes,
    };
    return api
        .post('physician/client_edit_physician/unlink?code=' + getPageCode, entity)
        .then((res) => {
            // console.log('res', res);
            dispatch(linkingEntitiesSuccess());
            dispatch(clientEditPhysicianSlice.actions.loadData({ ...storedSettings, globalSearch: search, code: getPageCode }));
        })
        .catch((error) => {
            // console.log('catch', error.response);
            dispatch(linkingEntitiesError());
            if (error.response) {
                // dispatch(fetchThreadListError(fieldsErrors[ error.response.status ]));
            } else if (error.request) {
                console.error(error.request);
                // dispatch(fetchThreadListError(error));
            } else {
                console.error('Error ', error.message);
            }
        });
}
