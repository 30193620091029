import createLinkingConnectedDataTableSlice from "../../../utils/createLinkingConnectedDataTableSlice";

const clientEditMedicalServicesServicesSlice = createLinkingConnectedDataTableSlice(
    'clientEditMedicalServicesServices',
    'name'
);

export const {
    loadData,
    linkingEntities, unlinkingEntities, linkingEntitiesSuccess, linkingEntitiesError,
} = clientEditMedicalServicesServicesSlice.actions;

export const clientEditMedicalServicesServicesSliceReducer = clientEditMedicalServicesServicesSlice.reducer;

export default clientEditMedicalServicesServicesSlice;
