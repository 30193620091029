import { transformResponseToTableData } from '../../../components/Datatable/dataTableUtils';

export const transformClientEditFacilities = transformResponseToTableData((data) => {
    return {
        data: data.map(({ code, name, facilityTypeCode, countryName, facilityCode, linked }) => ({
            code,
            name,
            facilityTypeCode,
            facilityCode,
            country: countryName,
            linked: linked ? 'Linked' : 'Unlinked',
        })),
    };
});
