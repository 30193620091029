import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import Modal from 'react-modal';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
    closeCreateModal,
    closeDeleteModal,
    closeEditModal,
    getAllPermissions,
    getAllRoles,
    getAllUsers,
    openDeleteModal,
    openEditModal
} from '../../redux/action-creators/users'
import {getAllCompanies} from '../../redux/actions/companiesActions'
import {getAll} from '../../redux/actions/BasicEntityActions'
import {getFullNameFromCode, isUser} from '../../utils/helpers'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import PropTypes from "prop-types";

class ControlTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showActivateModal: false,
            showEditModal: false,
            userId: ''
        };

        this.handleOpenActivate = this.handleOpenActivate.bind(this);
        this.handleOpenEdit = this.handleOpenEdit.bind(this);
        this.roleFormatter = this.roleFormatter.bind(this);
    }

    componentDidMount() {
        Modal['setAppElement']('body');
        this.props.getAllRoles();
        this.props.getAllPermissions();
        this.props.getAllCompanies();
        this.props.handleCloseModals();
        this.props.getAllUsers();
        this.props.getAllCountries();
    }

    handleOpenActivate(userId) {
        if (userId.code) userId = userId.code;
        this.setState({userId});
        this.props.handleOpenDeleteModal();
    }

    handleOpenEdit(userId) {
        this.setState({userId});
        this.props.history.push(`/submitters/profile/${userId}`)
    }

    roleFormatter(cell, row) {
        let roleName = '';
        if (row) {
            this.props.roles.forEach((item) => {
                if (item.code === cell) {
                    roleName = item.name;
                }
            });
        }
        return roleName;
    }

    render() {
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true
            }, {
                'name': 'firstName',
                'title': this.context.translate('common.users.first_name'),
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            },
            //  {
            //     'name': 'middleName',
            //     'title': this.context.translate('common.users.middle_name'),
            //     'sort': true,
            //     'width': '150',
            //     'dataAlign': 'left',
            //     'filter': {type: 'TextFilter', delay: 500}
            // },
            {
                'name': 'lastName',
                'title': this.context.translate('common.users.last_name'),
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'email',
                'title': this.context.translate('common.form.email'),
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'company',
                'title': this.context.translate('common.form.company'),
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'roleCode',
                'title': this.context.translate('common.users.role'),
                'dataFormat': this.roleFormatter,
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'phoneNumber',
                'title': this.context.translate('common.form.phone_number'),
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'active',
                'hidden': !!this.props.companyCode,
                'title': this.context.translate('common.form.status'),
                'dataFormat': (cell, row, enumObject) => {
                    return enumObject[cell];
                },
                'sort': true,
                'formatExtraData': {
                    'false': this.context.translate('common.form.inactive'),
                    'true': this.context.translate('common.form.active')
                },
                'width': '100',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': this.context.translate('common.form.inactive'),
                        'true': this.context.translate('common.form.active')
                    }, selectText: this.context.translate('common.form.select')
                }
            }
        ];
        const {users, isLoading} = this.props;
        const {sorting, pagination} = this.props.gridConfiguration;
        return (
            <div className="table-responsive" style={{"padding": '10px', marginTop: '15px'}}>
                {isLoading && <LoadingIndicator type="lister"/>}
                <RemoteGrid
                    color="orange"
                    title="Submitters"
                    entity="user"
                    data={users}
                    total={users.length}
                    columns={columns}
                    search={true}
                    onRowClick={this.handleOpenEdit}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                    multiColumnSort={2}
                    isLoading={isLoading}
                />
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
    }
    static defaultProps = {
        isLoading: false
    };
}

ControlTable.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    users: state.app.users.users
        .filter((item) => isUser(item.roleCode) && (ownProps.companyCode ? item.companyCode === ownProps.companyCode : true))
        .map((item) => ({
            ...item,
            company: getFullNameFromCode(item.companyCode, state.app.companies.data)
        })),
    roles: state.app.users.roles.filter((item) => isUser(item.code)),
    isLoading: state.app.users.loading,
    error: state.app.users.error,
    gridConfiguration: state.app.grid.user
});

const mapDispatchToProps = dispatch => ({
    handleOpenEditModal: (data) => {
        dispatch(openEditModal(data))
    },
    handleCloseModals: () => {
        dispatch(closeCreateModal());
        dispatch(closeEditModal());
        dispatch(closeDeleteModal());
    },
    handleOpenDeleteModal: () => {
        dispatch(openDeleteModal())
    },
    getAllUsers: () => {
        dispatch(getAllUsers('auth/user/all', true))
    },
    getAllRoles: () => {
        dispatch(getAllRoles())
    },
    getAllPermissions: () => {
        dispatch(getAllPermissions())
    },
    getAllCompanies: () => dispatch(getAllCompanies()),
    getAllCountries: () => dispatch(getAll('country'))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ControlTable));
