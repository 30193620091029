import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import PropTypes from "prop-types";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {getCorrectDateFormat} from '../../../utils/helpers'
import {editCase} from '../../../redux/actions/cases/caseListActions'
import Box from '../../../components/Box'
import BoxHeader from '../../../components/BoxHeader'
import BoxBody from '../../../components/BoxBody'
import BoxFooter from '../../../components/BoxFooter'
import RemoveOwnerBtn from '../Buttons/RemoveOwnerBtn'
import ChangeOwnerForm from './ChangeOwnerForm'
import Modal from "react-modal";
import {closeCreateModal, openCreateModal, closeEditModal} from "../../../redux/action-creators/users";


class OwnersList extends Component {

    constructor(props) {
        super(props);
        this.state = {closed: false}
    }


    toggleSection = (e) => {
        if (e.target.tagName === 'A') return;

        this.setState({closed: !this.state.closed})
    };

    onChangeOwnerClick = (stage) => {
        this.setState({stage});
        this.props.modalOpen()
    };

    nameFormatter = (cell, row) => {
        return <a className={!row.link ? 'disabled_link' : ''} href={row.link || '/home'} target="_blank" rel={'noopener noreferrer'}>{cell}</a>
    };

    dateFormatter = (cell) => cell ? getCorrectDateFormat(cell, 'L LT') : "";

    stageFormatter = (cell) => {
        if (!cell) return '';

        if (cell === 'ownerQa') return 'QA';
        return cell.slice(5)
    };

    actionFormatter = (cell, row) => {
        const visibility = row.stage === 'ownerReview' ? 'hidden' : 'visible';
        return (<span style={{visibility}}>
      <button className="btn btn-default margin-left-5" onClick={() => this.onChangeOwnerClick(row.stage)}><i
          className="fa fa-edit"> </i></button>
      <RemoveOwnerBtn stage={row.stage}/>
    </span>)
    };

    render() {
        const {ownerDraft, ownerDispatch, ownerReview, ownerQa} = this.props.ownersInfo;
        const data = [ownerDraft, ownerDispatch, ownerReview, ownerQa];
        const display = this.state.closed ? 'none' : 'block';
        return (
            <Box
                color="blue"
                classes="box-profile-first-row-height-show">
                <BoxHeader
                    title={this.props.name}
                    color="blue"
                    onClick={this.toggleSection}
                    icon="fa-user-md">
                    <span>
                        <Link style={{display: this.props['showEdit'] ? 'flat' : 'none', marginLeft: '15px'}}
                              className="btn btn-default margin-left-5"
                              to={`/assign/${this.props.caseId}`}
                        >
                            <i className="fa fa-edit"> </i>
                            Edit
                        </Link>
                        <i className={`btn-box-tool fa toggle-fa-icon-reviewer-info ${this.state.closed ? 'fa-plus' : 'fa-minus'}`}
                           style={{right: '20px'}}> </i>
                    </span>
                </BoxHeader>
                <BoxBody style={{display}}>
                    <div className='margin-top-15 tier ownerSection without-bottom'>
                        <BootstrapTable
                            striped
                            data={data}
                            bodyStyle={{overflow: 'visible'}}
                            //headerContainerClass='without_top_border_header'
                            bordered={true}
                            hover
                        >
                            <TableHeaderColumn
                                dataField='code'
                                isKey={true}
                                hidden>
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='link'
                                hidden>
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='stage'
                                dataFormat={this.stageFormatter}
                            >
                                Stage
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='name'
                                dataFormat={this.nameFormatter}
                            >
                                User
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='enterStageDate'
                                dataFormat={this.dateFormatter}
                            >
                                Entered
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='submitStageDate'
                                dataFormat={this.dateFormatter}
                            >
                                Submitted
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='action'
                                dataFormat={this.actionFormatter}
                                dataAlign='right'
                                headerTitle={false}
                            >
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>

                    <Modal
                        isOpen={this.props.isOpen}
                        contentLabel="onRequestClose Example"
                        onRequestClose={this.props.modalClose}
                        className="modal-dialog"
                        overlayClassName="modal-overlay"
                    >
                        <h3 className="modal-title text-center"> {this.context.translate('common.form.change_owner')}
                            <i className='fa fa-times close' onClick={this.props.modalClose}> </i>
                        </h3>
                        <ChangeOwnerForm stage={this.state.stage}> </ChangeOwnerForm>
                    </Modal>
                </BoxBody>
                <BoxFooter>

                </BoxFooter>
            </Box>
        );
    }
}

OwnersList.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state) => {
    return {
        editedCase: state.app.cases.editedCase,
        ownersInfo: state.app.cases.editedCase.ownersInfo || {},
        isOpen: state.app.users.createModalOpen,
    }
};

const mapDispatchToProps = dispatch => ({
    updateCase: (data) => dispatch(editCase(data)),
    modalOpen: () => dispatch(openCreateModal()),
    modalClose: () => dispatch(closeCreateModal()),
    modalCloseEdit: () => dispatch(closeEditModal())
});

export default connect(mapState, mapDispatchToProps)(OwnersList);
