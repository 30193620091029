import React, {Component} from 'react'
import PropTypes from "prop-types"
import {connect} from 'react-redux'
import {logout} from '../../redux/actions/authActions'
import {userStatuses} from "../../redux/actions/statuses";
import AuthRoute from "../../pages/Auth/AuthRoute"

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogout: false
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        e.preventDefault();
        this.props.logout().then(
            () => this.setState({isLogout: true})
        );
    }

    componentWillUnmount() {
        // console.log('FY-UNMOUNT: ');
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => null;
    }

    render() {
        if (this.state.isLogout) {
            return AuthRoute(userStatuses['LOGIN_FAILURE']);
        }
        return (
            <li onClick={this.onClick}>
                <a href={'/'}>
                    <i className="fa fa-sign-out"/>
                    <span>{this.context.translate('common.core.logout')}</span>
                </a>
            </li>
        );
    }
}

Logout.contextTypes = {
    translate: PropTypes.func,
};

const mapState = () => {
    return {}
};

const mapActions = dispatch => {
    return {
        logout: Logout => dispatch(logout(Logout))
    }
};

export default connect(mapState, mapActions)(Logout)
