import PropTypes from 'prop-types';
import React from 'react';
import SimpleReactValidator from 'utils/simple-react-validator';
import {connect} from 'react-redux';

import FormField from '../../../components/FormField/index';
import {closeCreateModal} from '../../../redux/action-creators/users';
import {setCurrentField} from '../../../redux/actions/templateActions';

class AddFromLibrary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayName: {EN: '', SV: ''},
            language: {label: 'English', value: 'EN'},
            position: '',
        };

        this.validator = new SimpleReactValidator();
    }

    onChangeCheckBox = (event) => {
        this.setState({[event.target.name]: event.target.checked});
    };

    onChangeLibField = (field) => {
        this.setState({field});
    };

    onChangeLanguage = (language) => {
        this.setState({language});
    };

    Submit = (event) => {
        event.preventDefault();

        if (this.validator.allValid()) {
            const value = this.props.fields.find((item) => item.code === this.state.field.value);

            const linkToDefault = Object.keys(value).reduce((useDefault, field) => {
                useDefault[`${field}UseDefault`] = true;
                //     !(
                //     !isFieldTypeSelect(value.fieldType) &&
                //     (field === 'allowMultiSelect' || field === 'includeOther')
                // );
                return useDefault;
            }, {});

            linkToDefault.fieldValidationsUseDefault = true;

            this.props.setCurrentField({
                ...value,
                originalFieldCode: value.code,
                ...linkToDefault,
            });
            this.props.closeCreateModal();
            this.props.history.push('/templates/field_instances/clone');
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {
        return (
            <form className={'modal-content custom-modal-content'}>
                <div className={'form-group'}>
                    <div className={'row'}>
                        <FormField
                            className={'text-bg required-field'}
                            label={'Select Field'}
                            md={'12'}
                            name={'libField'}
                            onChange={this.onChangeLibField}
                            type={'REF_FIELD'}
                            validator={this.validator}
                            valids={'required'}
                            value={this.state.field}
                        />
                    </div>
                </div>
                <div className={'form-group'}>
                    <div className={'row no-padding'}>
                        <div className={'col-xs-4 pull-right text-right'}>
                            <button className={'btn btn-flat btn-primary'} onClick={this.Submit}>
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

AddFromLibrary.contextTypes = {
    translate: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        error: state.app.case_types.error,
        fields: state.app.fields.data,
        isLoading: state.app.case_types.loading,
        languageCodes: state.app.languages.data.map((item) => {
            return {
                label: item.name,
                value: item.code,
            };
        }),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeCreateModal: () => {
            return dispatch(closeCreateModal());
        },
        setCurrentField: (field) => {
            return dispatch(setCurrentField(field));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddFromLibrary);
