import React from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'

const tooltip = (text) => (
    <Popover id="tooltip_common">
        {text}
    </Popover>
);

const TextTooltip = ({text, placement, formattedText = null}) => (
    <OverlayTrigger placement={placement} overlay={tooltip(formattedText === null ? text : formattedText)}>
        <span style={{display: 'inline-block', width: '100%'}}>{text}</span>
    </OverlayTrigger>
)

export default TextTooltip;
