import React from 'react';
import {connect} from 'react-redux';
import SimpleReactValidator from "../../utils/simple-react-validator";
import {getPhysician, setCurrentTab, editPhysician, isAllValid} from '../../redux/actions/physicianActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../components/LoadingIndicator";
import PhysicianGeneralInfo from "./PhysicianGeneralInfo";
import PhysicianProfessionalInfo from "./PhysicianProfessionalInfo";
import {Tab, Tabs} from "react-bootstrap";
import PhysicianEditFacilities from "./PhysicianEditFacilities";
import {getActiveCountries} from "../../redux/actions/countryActions";
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';
import {getMultiSelectValue, getSelectFieldValue} from "../../utils/helpers";
import SystemMessage from '../../components/SystemMessage';

const tabToField = {
    'First Name': 'general',
    'Middle Name': 'general',
    'Last Name': 'general',
    'Notification Email': 'general',
    'Phone Number': 'general',

    'Specialties': 'professional',
    'Body Parts': 'professional'
};

class PhysicianEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            custom_email: {
                message: 'The Email must be a valid email address.',
                rule: function (val) {
                    return this._testRegex(val, /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) || val === '';
                }
            }
        });
        this.validator.showMessages();

    }

    componentDidMount() {
        this.props.getPhysician(this.props.match.params.id);
    }

    handleSelect = (key) => {
        this.props.setCurrentTab(key)
    };

    onSave = () => {
        return this.props.savePhysician({
            ...this.props.physician,
            active: this.props.physician.active,
            countryCode: getSelectFieldValue(this.props.physician.countryCode),
            stateCode: getSelectFieldValue(this.props.physician.stateCode),
            specialtyCodes: getMultiSelectValue(this.props.physician.specialtyCodes),
            bodyPartCodes: getMultiSelectValue(this.props.physician.bodyPartCodes),
            languageCodes: getMultiSelectValue(this.props.physician.languageCodes)
        })
    };

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());

        this.forceUpdate();
    };

    render() {
        const {match, isProfileChanged, editPhysicianSuccess, isLoading} = this.props;

        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
            tab: tabToField[field]
        }));

        return (
            <div className='content'>
                <PreventTransitionPrompt
                    when={isProfileChanged}
                    message='MyCustomDialogComponent'
                    entity='physician'
                    isFormValid={this.props.isValid}
                    saveChanges={this.onSave}
                    cancelAndDiscard={() => this.props.getPhysician(this.props.physician.code)}
                />
                {isLoading && <LoadingIndicator type="tabs"/>}
                <Tabs activeKey={this.props.currentTab} defaultActiveKey="general" animation={true}
                      id="noanim-tab-example" onSelect={this.handleSelect}>
                    <SystemMessage
                        shown={this.props.showValidMessages}
                        type='error' message={this.props.error}
                        validationMessages={errorMessages}
                        goToTab={this.handleSelect}
                        top='7em'
                    />
                    <SystemMessage
                        shown={!isProfileChanged && editPhysicianSuccess}
                        type='success'
                        message={this.context.translate('common.message.physician_success')}
                        top='7em'
                    />
                    <Tab eventKey="general" title={this.context.translate('common.form.general_information')}>
                        {isLoading && <LoadingIndicator height={'50'}/>}
                        <PhysicianGeneralInfo match={match} validator={this.validator}
                                              dispatchValidState={this.dispatchValidState}
                                              isEditable={this.props.isEditable}
                        />
                    </Tab>
                    <Tab eventKey="professional" title={this.context.translate('common.form.professional_information')}>
                        {isLoading && <LoadingIndicator height={'50'}/>}
                        <PhysicianProfessionalInfo match={match} validator={this.validator}
                                                   dispatchValidState={this.dispatchValidState}
                                                   isEditable={this.props.isEditable}/>
                    </Tab>
                    <Tab eventKey={'facilities'} title={this.context.translate('common.form.facilities')}>
                        {isLoading && <LoadingIndicator height={'50'}/>}
                        <div className="table-responsive">
                            <PhysicianEditFacilities match={match} isEditable={this.props.isEditable}/>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

PhysicianEditPage.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    isLoading: state.app.physician.loading,
    isValid: state.app.physician.physicianValid,
    isEditable: state.app.users.my_permissions.includes('MODIFY_PHYSICIANS'),
    showValidMessages: state.app.physician.showValidMessages,
    currentTab: state.app.physician.currentTab,
    isProfileChanged: state.app.physician.isProfileChanged,
    error: state.app.physician.error,
    editPhysicianSuccess: state.app.physician.editPhysicianSuccess,
    bodyParts: state.app.bodyParts.data
        .map(item => ({
            value: item.code,
            label: item.name
        })),
    physician: state.app.physician.editedPhysician,
});

const mapDispatchToProps = dispatch => ({
    getActiveCountries: () => dispatch(getActiveCountries()),
    setCurrentTab: (tab) => dispatch(setCurrentTab(tab)),
    getPhysician: code => dispatch(getPhysician(code)),
    savePhysician: data => dispatch(editPhysician(data)),
    isAllValid: (valid) => dispatch(isAllValid(valid))
});

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianEditPage)
