import {getCorrectDateFormat, getDateTimeFormat4Field, isLastMonthDate, isThisMonthDate} from "../../../utils/helpers";

const defaultState = {
    loading: false,
    error: '',
    editCaseSuccess: false,
    selectedCaseCodes: [],
    all: [],
    visibleCodes: [],
    useBy: [],
    fetch: {
        'draft': [],
        'dispatch': [],
        'review': [],
        'qa': [],
        'closed': [],
        'available': [],
        'accepted': [],
        'in_process': [],
        'closed_cases_this_month': [],
        'closed_cases_last_month': [],
        'all': []
    }
};

const case_list = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_ALL_CASES_LOADING':
        case 'FETCH_CREATE_CASE_LOADING':
        case 'FETCH_EDIT_CASE_LOADING':
        case 'FETCH_REOPEN_CASE_LOADING':
            return {...state, loading: true, error: null, editCaseSuccess: false};

        case 'FETCH_ALL_CASES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                selectedCaseCodes: [],
                all: action.payload.map((item) => {
                    let whenComplete = '';
                    if (item.completionDate !== null) {
                        if (isThisMonthDate(item.completionDate)) {
                            whenComplete = '1';
                        }
                        if (isLastMonthDate(item.completionDate)) {
                            whenComplete = '-1';
                        }
                    }
                    return {
                        ...item,
                        clientDueDate: getDateTimeFormat4Field('DATE_TIME', item.clientDueDate),
                        completionDate: getCorrectDateFormat(item.completionDate, 'L LT'),
                        whenComplete: whenComplete,
                        creationDate: getCorrectDateFormat(item.creationDate, 'L LT'),
                        reviewerDueDate: getDateTimeFormat4Field('DATE_TIME', item.reviewerDueDate),
                        submitDate: getCorrectDateFormat(item.submitDate, 'L LT')
                    }
                }),
                error: null
            });

        case 'FETCH_CREATE_CASE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                all: [...state.data, action.payload]
            });

        case 'FETCH_EDIT_CASE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                editCaseSuccess: true,
                error: null,
                all: state.all.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            });

        case 'FETCH_REOPEN_CASE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                all: state.all.map((item) => {
                    if (item.code === action.payload) return {...item, currentStageCode: "CW_DISPATCH"};
                    return item
                })
            });

        case 'SET_SELECTED_CASES':
            return Object.assign({}, state, {
                selectedCaseCodes: action.payload,
            });

        case 'BUILD_LIST_CASES':
            let role = action.payload;
            let data = defaultState.fetch;
            state.all.forEach((item) => {
                if (typeof item.currentStageCode !== "undefined") {
                    switch (role) {
                        case 'STAFF' :
                            if (item.currentStageCode.indexOf('DRAFT') > 0) data['draft'].push(item);
                            if (item.currentStageCode.indexOf('DISPATCH') > 0) data['dispatch'].push(item);
                            if (item.currentStageCode.indexOf('REVIEW') > 0) data['review'].push(item);
                            if (item.currentStageCode.indexOf('QA') > 0) data['qa'].push(item);
                            if (item.currentStageCode.indexOf('CLOSED') > 0) data['closed'].push(item);
                            break;
                        case 'CLIENT' :
                            if (item.currentStageCode.indexOf('DRAFT') > 0) data['draft'].push(item);
                            if (item.currentStageCode.indexOf('DISPATCH') > 0 ||
                                item.currentStageCode.indexOf('REVIEW') > 0 ||
                                item.currentStageCode.indexOf('QA') > 0)
                                data['in_progress'].push(item);
                            if (item.currentStageCode.indexOf('CLOSED') > 0) data['closed'].push(item);
                            break;
                        case 'REVIEWER' :
                            if (item.currentStageCode.indexOf('REVIEW') > 0 && item['assignmentAction'] !== 'ACCEPTED')
                                data['available'].push(item);
                            if (item.currentStageCode.indexOf('REVIEW') > 0 && item['assignmentAction'] === 'ACCEPTED')
                                data['accepted'].push(item);
                            if (item.currentStageCode.indexOf('CLOSED') > 0) data['closed'].push(item);
                            if (item.currentStageCode.indexOf('CLOSED') > 0 && isThisMonthDate(item.completionDate))
                                data['closed_cases_this_month'].push(item);
                            if (item.currentStageCode.indexOf('CLOSED') > 0 && isLastMonthDate(item.completionDate))
                                data['closed_cases_last_month'].push(item);
                            break;
                        default:
                    }
                }
            });
            return Object.assign({}, state, {
                loading: false,
                error: null,
                fetch: data
            });

        case 'FETCH_ALL_CASES_ERROR':
        case 'FETCH_CREATE_CASE_ERROR':
        case 'FETCH_EDIT_CASE_ERROR': {
            // console.log('CASE_LIST FETCH_EDIT_CASE_ERROR', state, ':::', action.payload);
            return {...state, loading: false};
        }
        case 'FETCH_REOPEN_CASE_ERROR':
            return {...state, error: action.payload, loading: false};

        case 'FETCH_CASES_ACTIVITIES_ERROR':
            return {...state, error: action.payload};

        case 'ADD_VISIBLE_CODES':
            let visibleCodes = state.visibleCodes.concat(action.payload);
            return Object.assign({}, state, {
                visibleCodes: visibleCodes
            });

        case 'CLEAR_VISIBLE_CODES':
            return Object.assign({}, state, {
                visibleCodes: []
            });

        case 'FETCH_CASES_ACTIVITIES_SUCCESS':
            return Object.assign({}, state, {
                useBy: action.payload
            });

        case 'FETCH_UPDATE_PAGES_IN_LIST':
            let all = [];
            for (let i = 0, len = state.all.length; i < len; i++) {
                let caseItem = state.all[i];
                if (caseItem['code'] === action.code)
                    caseItem['pages'] = action.payload['mergedPagesCount'];
                all.push(caseItem);
            }
            return Object.assign({}, state, {
                all: all
            });

        case 'REMOVE_CASE_LIST_ERROR': {
            return { ...state, error: null };
        }

        default:
            return state;
    }
};

export default case_list;
