import React from 'react'
import Menu from '../components/Menu'
import UserPanel from './UserPanel'

const SideBar = () => (
    <aside className="main-sidebar">
        <section className="sidebar">
            <UserPanel/>
            <Menu/>
        </section>
    </aside>
)

export default SideBar
