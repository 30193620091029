import { createHashHistory } from 'history';

import { routerMiddleware } from 'react-router-redux';

// Create a history of your choosing (we're using a browser history in this case)
const history = createHashHistory({
  getUserConfirmation(dialogKey, callback) {
    // use "message" as Symbol-based key
    const dialogTrigger = window[Symbol.for(dialogKey)];

    if (dialogTrigger) {
      // pass the callback to delegate
      // to the invoked dialog
      return dialogTrigger(callback);
    }

    // Fallback to allowing navigation
    callback(true);
  },
});

history.listen((location, action) => {
  // console.log(
  //   `The current URL is ${location.pathname}${location.search}${location.hash}`
  // );
  // console.log(`The last navigation action was ${action}`);
});

// Build the middleware for intercepting and dispatching navigation actions
export const confirmationMiddleware = routerMiddleware(history);

export default history;
