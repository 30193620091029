import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'
import PropTypes from "prop-types";
import { editField } from '../../../redux/actions/physicianActions'
import { onSort } from "../../../redux/actions/gridActions";

class SaveEntity extends React.Component {

    onLink = () => {
        const { editField, facilityCodes, selectedFacilityCodes } = this.props;
        let facilitiesSet = new Set([...facilityCodes, ...selectedFacilityCodes]);
        editField('facilityCodes', Array.from(facilitiesSet));
        this.props.sort('linked', 'desc');
    };

    onUnlink = () => {
        const { editField, facilityCodes, selectedFacilityCodes } = this.props;
        const facilities = facilityCodes.filter(item => selectedFacilityCodes.indexOf(item) === -1);
        editField('facilityCodes', facilities);
        this.props.sort('linked', 'asc');
    };

    render() {
        if (!this.props.isEditable) return (<></>);
        return (<span>
        <SubmitButton color='grey' onSubmit={this.onLink} showTooltip={false} title='Link'/>
        <SubmitButton color='grey' onSubmit={this.onUnlink} showTooltip={false} title='Unlink'/>
      </span>)
    }
}

SaveEntity.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    facilityCodes: state.app.physician.editedPhysician.facilityCodes || [],
    selectedFacilityCodes: state.app.physician.editedPhysician.selectedFacilityCodes || [],
    isEditable: state.app.users.my_permissions.includes('MODIFY_PHYSICIANS'),
});

const mapDispatchToProps = dispatch => ({
    editField: (field, value) => dispatch(editField(field, value)),
    sort: (value, dir) => dispatch(onSort(value, dir))
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveEntity)
