/* eslint-disable object-shorthand */
/* eslint-enable react-hooks/exhaustive-deps */
/* eslint "react-hooks/exhaustive-deps": "error" */

import { isEmpty, omit } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import SimpleReactValidator from '../../utils/simple-react-validator';
import useFormState from './useFormState';

const useValidator = ({ setFieldValidity, formKey, additionalRules = {} }) => {
    const validatorRef = useRef(new SimpleReactValidator(additionalRules));
    const { current: validator } = validatorRef;

    const [errorMessages, setErrorMessages] = useState({});

    const { isFormValid } = useFormState(formKey);

    const setFieldError = (field, error) => {
        setErrorMessages({ ...errorMessages, [field]: error });
    };

    const removeFieldError = (field) => {
        setErrorMessages(omit(errorMessages, field));
    };

    const validate = (field, valueInput, rules, { label: labelInput }) => {
        let tests = rules.split('|');

        const value = valueInput || '';
        let result = '';
        tests.forEach((test) => {
            const rule = validator._getRule(test);
            const options = validator._getOptions(test);
            const label = labelInput || field;
            if (!rule) return;

            const validationResult = validator.rules[rule].rule(value, options);
            if (validationResult) {
                removeFieldError(field);
                setFieldValidity(field, true);
            } else {
                const message = validator.rules[rule].message.replace(':attribute', label.replace(/_/g, ' '));
                const { messageReplace } = validator.rules[rule];

                if (options.length > 0 && messageReplace) {
                    result = { message: messageReplace(message, options), label };
                    setFieldError(field, result);
                    setFieldValidity(field, false);
                } else {
                    result = { message, label };
                    setFieldError(field, result);
                    setFieldValidity(field, false);
                }
            }
        });

        return result;
    };

    const hasError = useMemo(() => !isEmpty(errorMessages), [errorMessages]);
    const allValid = isFormValid;

    return { validate, errorMessages, hasError, allValid, setErrorMessages };
};

export default useValidator;
