import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup } from 'react-bootstrap';

export const UnitInput = ({ unit, isLoading, ...props }) => {
  return (
    <InputGroup>
      <FormControl type="number" min="0" {...props} />
      <InputGroup.Addon>
        {isLoading ? <i className="fa fa-circle-o-notch fa-spin" /> : unit}
      </InputGroup.Addon>
    </InputGroup>
  );
};

UnitInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  unit: PropTypes.string,
  isLoading: PropTypes.bool,
};

UnitInput.defaultProps = {
  isLoading: false,
};

export default UnitInput;
