import React from 'react';
import PropTypes from 'prop-types';
import ControlContext from './ControlContext';

const propTypes = {
  children: PropTypes.any.isRequired,
};

const contextTypes = {
  $bs_formGroup: PropTypes.object,
};

class ControlContextProvider extends React.Component {
  render() {
    const formGroup = this.context.$bs_formGroup;

    return (
      <ControlContext.Provider value={formGroup}>
        {this.props.children}
      </ControlContext.Provider>
    );
  }
}

ControlContextProvider.propTypes = propTypes;
ControlContextProvider.contextTypes = contextTypes;

export default ControlContextProvider;
