import DataTable from 'components/Datatable/DataTable';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const ConnectedDataTable = forwardRef((
  {
    stateKey,
    actions,
    deleteDisabled,
    keyExtractor,
    defaultLoadData,
    ...props
  }, ref) => {
  const dispatch = useDispatch();

  const tableState = useSelector((state) => get(state.app, stateKey));

  const { search } = useSelector((state) => {
    return state.app.globalSearch;
  });

  if (tableState === undefined) {
    throw new Error(
      `Connected datatable should have a corresponding state object in redux store defined by ${stateKey} check your root reducer`,
    );
  }

  const {
    deleting,
    data,
    totalRecords,
    totalRecordsFiltered,
    fetching,
    settings: storedSettings,
    initialSettings,
  } = tableState;

  const datatableRef = useRef();

  useEffect(() => {
    // console.log('ConnectedDataTable useEffect');
    if (defaultLoadData) {
      dispatch(actions.loadData({ ...storedSettings, globalSearch: search }));
    }

    return () => dispatch(actions.cancelLoad());
  }, [dispatch, storedSettings, actions, defaultLoadData, search]);

  const handleChangeSettings = useCallback(
    (settings) => {
      dispatch(actions.changeSettings(settings));
    },
    [dispatch, actions],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        resetSelectedRows: () => datatableRef && datatableRef.current.resetSelectedRows(),
      };
    },
    [],
  );

  return (
    <DataTable
      ref={datatableRef}
      {...props}
      totalRecords={totalRecords}
      totalRecordsFiltered={totalRecordsFiltered}
      data={data}
      connectedCurrentPage={storedSettings.page}
      defaultSettings={storedSettings}
      initialSettings={initialSettings}
      onSettingsChange={handleChangeSettings}
      loading={fetching}
      keyExtractor={keyExtractor}
      deleteDisabled={(rowData, index) =>
        deleteDisabled(rowData) || deleting === keyExtractor(rowData, index)
      }
      deleteLoading={(rowData, index) =>
        deleting === keyExtractor(rowData, index)
      }
      isDeleting={deleting !== null}
      serverside
    />
  );
});

ConnectedDataTable.propTypes = {
  stateKey: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  keyExtractor: PropTypes.func,
  deleteDisabled: PropTypes.func,
  defaultLoadData: PropTypes.bool,
};

ConnectedDataTable.defaultProps = {
  keyExtractor: (_, index) => index,
  deleteDisabled: () => false,
  defaultLoadData: true,
};

export default ConnectedDataTable;
