import React from 'react';
import StaffControlTable from './StaffControlTable';

const StaffManagementPage = () => (
    <div className='container-fluid'>
        <StaffControlTable/>
    </div>
);

export default StaffManagementPage;
