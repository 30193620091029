const defaultInputState = {
	loading: false,
	error: null,
	data: [],
}

const input = (state = defaultInputState, action) => {
	switch (action.type) {
		case 'LOAD_INPUT_START':
			return Object.assign({}, state, {
				loading: true,
				error: null,
			})
		case 'LOAD_INPUT_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				data: action.input,
				error: null,
			})
		default:
	}

	return state
}

export default input