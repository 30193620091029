import React from 'react';
import ColoredBox from 'components/ColoredBox/ColoredBox';
import PageContainer from 'components/PageContainer/PageContainer';
import ConnectedDataTable from '../../components/Datatable/ConnectedDataTable';
import patientSlice from '../../redux/pages/patient/patientSlice';
import PropTypes from 'prop-types';

const columns = {
  firstName: { label: 'First Name', colStyle: { width: '16%' } },
  lastName: { label: 'Last Name', colStyle: { width: '16%' } },
  gender: {
    label: 'Gender',
    colStyle: { width: '16%' },
    filterOptions: [
      { value: 'MALE', label: 'MALE' },
      { value: 'FEMALE', label: 'FEMALE' },
    ],
  },
  company: { label: 'Company', colStyle: { width: '16%' } },
  contactInfo: { label: 'Contact Info', colStyle: { width: '16%' } },
  status: {
    label: 'Status',
    colStyle: { width: '16%' },
    filterOptions: [
      { value: true, label: 'ACTIVE' },
      { value: false, label: 'INACTIVE' },
    ],
  },
};

const PatientsPage = ({ history }) => {
  const handleRowClick = ({ code }) => {
    // console.log('handleRowClick', history, code);
    history.push(`/patients/profile/${code}`);
  };

  return (
    <PageContainer>
      <ColoredBox variant="primary" title="Patients">
        <ConnectedDataTable
          columns={columns}
          onRowClick={handleRowClick}
          stateKey="patientsReducer"
          actions={patientSlice.actions}
          variant="primary"
        />
      </ColoredBox>
    </PageContainer>
  );
};

PatientsPage.propTypes = {
  history: PropTypes.object,
};

export default PatientsPage;
