import {connect} from 'react-redux'
import ManageFieldForm from './ManageFieldForm'
import {
    createFieldInstance,
    dispatchFIValidStatus,
    editFieldInstance,
    isFieldValid,
    setCurrentTab
} from '../../../redux/actions/templateActions'
import {getLanguages} from '../../../redux/actions/languageActions'
import {getAllRoles, openEditModal} from '../../../redux/action-creators/users'
import {getAllStages} from '../../../redux/actions/stages'
import {convertReceivedField} from '../../../utils/helpers'
import {serviceSectionFilter} from '../../../utils/fields'

const mapStateToProps = (state) => {
    let {editedField} = state.app.templates;

    const sectionCode = state.app.templates.sectionDto.findIndex((item) => {
        if (editedField.sectionCode) {
            return item.code === editedField.sectionCode || item.code === editedField.sectionCode.value
        } else return false
    }) !== -1 ? editedField.sectionCode : null;

    editedField = {...editedField, sectionCode};

    console.log(editedField);

    return {
        // field: {},
        mode: 'clone',
        label: 'Create Field Instance',
        loading: state.app.templates.loading,
        error: state.app.templates.error,
        field: editedField,
        isFormValid: state.app.templates.isFIFormValid,
        fieldValid: state.app.templates.fieldValid,
        isFieldChanged: state.app.templates.isFieldChanged,
        templateCode: state.app.templates.code,
        baseField: convertReceivedField(state.app.fields.data.find((item) => item.code === state.app.templates.editedField.originalFieldCode)) || {},
        sections: state.app.templates.sectionDto
            .filter(serviceSectionFilter)
            .sort((prev, next) => prev.position - next.position)
            .map(item => ({value: item.code, label: item.displayName['EN']})),
        fieldsCount: state.app.templates.fieldInstancesDto.length,
        languageCodes: state.app.languages.data,
    }
};

const mapDispatchToProps = (dispatch) => ({
    getInitData: () => {
        dispatch(getLanguages());
        dispatch(getAllStages());
        dispatch(getAllRoles());
        dispatch(dispatchFIValidStatus(true));
    },
    createFieldInstance: data => dispatch(createFieldInstance(data)),
    editField: (field, value) => dispatch(editFieldInstance(field, value)),
    isAllValid: (valid) => dispatch(isFieldValid(valid)),
    handleOpenModal: () => {
        dispatch(openEditModal())
    },
    setCurrentTab: () => dispatch(setCurrentTab('field_instances')),
    dispatchFIValidStatus: data => dispatch(dispatchFIValidStatus(data)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageFieldForm)
