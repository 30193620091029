/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { isClient } from '../../../utils/helpers';
import { setSubmitAfterLoad } from '../../../redux/actions/cases/caseActions';
import useUserRole from 'utils/hooks/useUserRole';
import { useDispatch, useSelector } from 'react-redux';

const CaseSubmitFromGrid = ({ history }) => {
  const dispatch = useDispatch();
  const role = useUserRole();

  const {
    selectedCaseCodesCount,
    selectedCase,
    selectedCaseCode,
    isVisible,
  } = useSelector((state) => {
    const currentPage = state.router.location.pathname;
    const selectedCaseCodes = state.app.case_list.selectedCaseCodes;

    const selectedCaseCode = selectedCaseCodes[0] || null;

    const selectedCase = Object.values(state.app.patientCase)
      .reduce((all, current) => [...all, ...current.data], [])
      .find(({ code }) => code === selectedCaseCodes[0]);

    return {
      selectedCase,
      selectedCaseCode,
      selectedCaseCodesCount: selectedCaseCodes.length,
      isVisible:
        currentPage !== '/closed' &&
        currentPage !== '/complete' &&
        currentPage !== '/in_progress',
    };
  });

  const clickSubmit = (selectedCaseCode) => {
    if (selectedCaseCode != null) {
      dispatch(setSubmitAfterLoad(true));
      history.push(`/case/${selectedCaseCode}`);
    } else {
      console.log('ERROR :: UNDEFINED CASE');
    }
  };

  const disabled = useMemo(() => {
    if (!selectedCase || selectedCaseCodesCount > 1) {
      return true;
    }

    const currentStageCode = selectedCase.stage;

    if (isClient(role) && !currentStageCode.includes('DRAFT')) {
      return true;
    }

    if (currentStageCode.includes('CLOSED')) {
      return true;
    }
  }, [role, selectedCase, selectedCaseCodesCount]);

  const submitHint = useMemo(() => {
    if (selectedCaseCodesCount > 1) {
      return 'You should select only one case';
    }

    if (isClient(role)) {
      return 'Client cannot submit';
    }
  }, [selectedCaseCodesCount, role]);

  const btnColor = disabled ? '' : 'btn-success';

  return (
    <span style={{ display: isVisible ? 'inline-block' : 'none' }}>
      <button
        className={`btn ${btnColor} btn-default resizable-button tooltip-button margin-left-5`}
        onClick={() => clickSubmit(selectedCaseCode)}
        disabled={disabled}
        title={submitHint ? submitHint : 'Submit'}
      >
        <i className={`fa fa-share `} />
      </button>
    </span>
  );
};

export default withRouter(CaseSubmitFromGrid);
