// import React from 'react';
import connect from 'react-redux/es/connect/connect';
import CaseButton from './CaseButton';
import { getReportFromGridClient } from '../../../redux/actions/cases/caseReportsActions';

const isValidPath = (path) => {
    const validPaths = ['/complete', '/closed', '/all_cases', '/home', '/'];
    return validPaths.includes(path);
};

const isCasesClosed = (caseCodes, caseList) => {
    const stages = caseCodes.map((code) => {
        let f_item = caseList.find((item) => item.code === code);
        if (typeof f_item !== 'undefined') return caseList.find((item) => item.code === code).stage;
        return '';
    });

    return stages.reduce((p, stage) => stage === 'Closed'  && p, true);
};

const mapState = (state, ownProps) => {
    const { case_list } = state.app;
    const { my_permissions } = state.app.users;

    const all = Object.values(state.app.patientCase).reduce((all, current) => [...all, ...current.data], []);

    const isVisible = isValidPath(ownProps.currentPage) && my_permissions.includes('MODIFY_CASES');
    const disabled = !isCasesClosed(case_list.selectedCaseCodes, all) || !case_list.selectedCaseCodes.length;

    const className = isVisible && !disabled ? 'btn-success margin-left-5' : 'btn-default margin-left-5';

    return {
        icon: 'fa-file',
        title: 'Download Report',
        isVisible: isVisible,
        disabled: disabled,
        className: className,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClick: () => dispatch(getReportFromGridClient()),
    };
};

export default connect(mapState, mapDispatchToProps)(CaseButton);
