import { isObjectsEqual } from '../../../utils/helpers';

const defaultState = {
    loading: false,
    loadedCount: 0,
    editMCSystemSuccess: false,
    data: [],
    error: '',
    editedMCSystem: {
        name: '',
        code: null,
        active: true
    },
    originalMCSystem: {
        name: '',
        code: null,
        active: true
    },
    mcSystemValid: undefined,
    isProfileChanged: false,
    currentTab: 'codes',
    showValidMessages: false,
    lastLoadedTime: 0,
}

const mcSystem = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_MC_SYSTEM_LOADING':
        case 'FETCH_EDIT_MC_SYSTEM_LOADING':
            return { ...state, loading: true, editMCSystemSuccess: false, isProfileChanged: false, showValidMessages: false, }
        case 'FETCH_MC_SYSTEM_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            })
        case 'FETCH_CREATE_MC_SYSTEM_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                data: [...state.data, action.payload]
            })
        case 'FETCH_GET_MC_SYSTEM_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                mcSystemValid: true,
                editedMCSystem: action.payload,
                originalMCSystem: action.payload,
            }
        case 'FETCH_EDIT_MC_SYSTEM_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editMCSystemSuccess: true,
                originalMCSystem: action.payload,
                data: state.data.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            })

        case 'SET_MC_SYSTEM_CURRENT_TAB':
            return Object.assign({}, state, {
                currentTab: action.payload
            })

        case 'MC_SYSTEM_EDIT_FIELD':
            return Object.assign({}, state, {
                editedMCSystem: Object.assign({}, state.editedMCSystem, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedMCSystem, action.payload), state.originalMCSystem)
            })
        case 'VALIDATE_MC_SYSTEM_FIELD':
            return Object.assign({}, state, {
                mcSystemValid: action.payload
            })
        case 'FETCH_MC_SYSTEM_ERROR':
            return { ...state, error: action.payload, loading: false }

        case 'SHOW_MC_SYSTEM_VALIDATION':
            return Object.assign({}, state, {
                showValidMessages: true
            })

        case 'FETCH_MC_SYSTEM_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            }
        case 'FILL_MC_SYSTEM_LOCAL_STORAGE': {
            let items = {};
            for (let i = 0; i < state.data.length; i++) {
                items[ state.data[ i ].code ] = state.data[ i ].name;
            }
            localStorage.setItem('list_medical_coding_system', JSON.stringify(items));
            return state;
        }
        case 'FETCH_MC_SYSTEM_LOADED_COUNT':
            return { ...state, loadedCount: action.payload }

        default:
    }
    return state;
}

export default mcSystem;
