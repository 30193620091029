import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';
import { patientCaseStages } from '../patientCaseStages';

const dispatchSlice = createDatatableTemplateSlice({
    name: patientCaseStages.DISPATCH,
    initialSettings: {},
});

export const dispatchReducer = dispatchSlice.reducer;

export const { loadData, dataLoaded, dataLoadError } = dispatchSlice.actions;

export default dispatchSlice;
