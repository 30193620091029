import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { editUser, getAllRoles, getUserProfile, isAllValid, setCurrentTab } from '../../redux/action-creators/users'
import UserEditGeneralInfo from "./UserEditGeneralInfo";
import UserSettings from "../UserSettings";
import { getSelectFieldValue, isStaff } from '../../utils/helpers'
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';
import SystemMessage from '../../components/SystemMessage';
import LoadingIndicator from '../../components/LoadingIndicator';
import SimpleReactValidator from "utils/simple-react-validator";
import PropTypes from "prop-types";

const tabToField = {
    'First Name': 'general',
    'Last Name': 'general',
    'Role': 'general',
    'Notification Email': 'general',
    'Phone Number': 'general',
};

class UserEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            custom_email:
                {
                    message: 'The Notification email must be a valid email address',
                    rule: (val) => val === '' ||
                        // eslint-disable-next-line no-control-regex,max-len
                        (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/igm).test(val)
                },
            custom_date:
                {
                    message: "That doesn't look right. Please re-enter date of birth.",
                    rule: (val) => val <= new Date().getTime() && val >= new Date('12/31/1899').getTime()
                },
        });

        this.validator.showMessages();

        this.state = {
            errorMessages: Object.keys(this.validator.errorMessages).map((field) => ({
                id: field,
                field,
                message: this.validator.errorMessages[ field ],
                tab: tabToField[ field ]
            }))
        }
    }

    componentDidMount() {
        this.props.setCurrentTab('general');
        this.props.getAllRoles();
        this.props.getUserProfile(this.props.match.params.id)
            .then(() => {
                this.dispatchValidState()
            });
    }

    handleSelect = (key) => {
        this.props.setCurrentTab(key)
    };

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        this.setState({
            errorMessages: Object.keys(this.validator.errorMessages)
                .map((field) => ({
                    id: field,
                    field,
                    message: this.validator.errorMessages[ field ],
                    tab: tabToField[ field ]
                }))
        })
    };

    submitUpdateUser = () => {
        return this.props.updateUser({
            ...this.props.user,
            roleCode: getSelectFieldValue(this.props.user.roleCode),
            defaultLanguageCode: getSelectFieldValue(this.props.user.defaultLanguageCode),
            timeZoneCode: getSelectFieldValue(this.props.user.timeZoneCode),
            defaultLocaleCode: getSelectFieldValue(this.props.user.defaultLocaleCode),
        })
    };

    render() {
        const disabledForm = this.props.isEditable ? {} : { pointerEvents: 'none' };
        const { isProfileChanged, editUserSuccess, error, showValidMessages, isLoading, isEditable } = this.props;
        const { errorMessages } = this.state;
        return (
            <section className="content user-profile-content row">
                <div className='col-md-12'>
                    {isLoading && <LoadingIndicator type="tabs"/>}
                    <Tabs defaultActiveKey={'general'} activeKey={this.props.currentTab} animation={true}
                          id="noanim-tab-example" onSelect={this.handleSelect}>
                        <SystemMessage shown={editUserSuccess} type='success' message='Profile successfully updated'
                                       top='65px'/>
                        <SystemMessage
                            shown={showValidMessages}
                            type='error' message={error}
                            validationMessages={errorMessages}
                            goToTab={this.handleSelect}
                            top='7em'
                        />
                        <PreventTransitionPrompt
                            when={isProfileChanged}
                            message='MyCustomDialogComponent'
                            entity='user'
                            saveChanges={this.submitUpdateUser}
                            isFormValid={this.props.isProfileValid}
                            cancelAndDiscard={() => this.props.getUserProfile(this.props.match.params.id)}
                        />
                        <Tab eventKey={'general'} title="General">
                            <div className="row">
                                <UserEditGeneralInfo
                                    validator={this.validator}
                                    dispatchValidState={this.dispatchValidState}
                                    isEditable={isEditable}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey={'settings'} title="Settings">
                            <div className="row" style={disabledForm}>
                                <UserSettings
                                    color='orange'
                                    title={`Submitter - ${this.props.profile.firstName} ${this.props.profile.lastName}`}
                                    isEditable={isEditable}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </section>
        );
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        isEditable: PropTypes.bool,
    }
    static defaultProps = {
        isLoading: false
    };
}

const allowToEdit = (role) => (isStaff(role) || role === "ROLE_ADMIN" || role === 'ROLE_CLIENT_ADMIN');

const mapStateToProps = (state) => ({
    isLoading: state.app.users.loading || state.app.users.loadingRoles,
    profile: state.app.users.editedUser,
    isEditable: allowToEdit(state.app.auth.user.roleCode),
    editUserSuccess: state.app.users.editUserSuccess,
    currentTab: state.app.users.currentTab,
    isProfileChanged: state.app.users.isProfileChanged,
    error: state.app.users.editUserError,
    showValidMessages: state.app.users.showValidMessages,
});

const mapDispatchToProps = dispatch => ({
    getUserProfile: code => dispatch(getUserProfile(code)),
    getAllRoles: () => {
        dispatch(getAllRoles())
    },
    isAllValid: (valid) => dispatch(isAllValid(valid)),
    updateUser: data => dispatch(editUser(data)),
    setCurrentTab: (tab) => dispatch(setCurrentTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPage)
