import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GroupCheckBox from '../../../components/FormField/GroupCheckBox';
import { editCurrentFilter, getFiltersByCase } from '../../../redux/actions/cases/caseAssignActions';
import FormField from '../../../components/FormField';
import { getCompanyProfile } from '../../../redux/actions/companiesActions';
import { getObjKey } from '../../../utils/helpers';
import { getAll } from '../../../redux/actions/BasicEntityActions';
import { getAllTypes } from '../../../redux/actions/reviewerTypesAction';
import AllAnyAddon from '../../../components/FormField/AllAnyAddon';

const getValue = getObjKey('value');

const ReviewersFilteringNew = () => {
  const dispatch = useDispatch();

  const { code: patientCaseCode, company } = useSelector((state) => {
    return state.app.cases.editedCase;
  });

  const { currencyCode } = useSelector((state) => {
    return state.app.companies.editedCompany;
  });

  const { reviewerTypes, countries } = useSelector((state) => {
    return state.app;
  });
  const reviewerTypesItems = reviewerTypes.data.map((item) => ({ value: item.code, label: item.name }));
  const countriesItems = countries.data.map((item) => ({ value: item.code, label: item.name }));

  const { currentFilters } = useSelector((state) => {
    // console.log('state.app', state.app);
    return state.app.case_assign;
  });

  useEffect(() => {
    if (countries.data.length === 0) {
      dispatch(getAll('country'));
    }
    if (reviewerTypes.data.length === 0) {
      dispatch(getAllTypes());
    }
  }, [dispatch, countries.data.length, reviewerTypes.data.length]);

  useEffect(() => {
    // console.log('ReviewersFiltering useEffect getFiltersByCase');
    if (patientCaseCode) {
      dispatch(getFiltersByCase(patientCaseCode));
    }
  }, [dispatch, patientCaseCode]);

  useEffect(() => {
    // console.log('ReviewersFiltering useEffect company');
    if (company && !currencyCode) {
      dispatch(getCompanyProfile(company));
    }
  }, [dispatch, company, currencyCode]);

  const handleNumberInputChange = ({ target }) => {
    dispatch(editCurrentFilter({ [target.name]: parseInt(target.value, 10) }));
  };

  const handleChangeReviewerType = (reviewerTypes) => {
    dispatch(editCurrentFilter({ reviewerTypes: reviewerTypes }));
  };

  const handleChangeCheckBox = ({ target }) => {
    dispatch(editCurrentFilter({ [target.name]: target.checked }));
  };

  const handleChangeCountries = (countries) => {
    dispatch(editCurrentFilter({ countries: countries.map(getValue) }));
  };

  const handleChangeSpecialties = (specialties) => {
    dispatch(editCurrentFilter({ specialties: specialties.map(getValue) }));
  };

  const handleLabelChange = ({ target }) => {
    dispatch(editCurrentFilter({ [target.name]: target.value }));
  };

  const handleChangeReviewerCaseType = (caseTypes) => {
    dispatch(editCurrentFilter({ caseTypes: caseTypes.map(getValue) }));
  };

  return (
    <div className="container-fluid" id="reviewer_filter">
      <div className="row" style={{ padding: 0, marginBottom: '30px' }}>
        <h2>Reviewer Filtering</h2>
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div style={{ display: 'flex', alignItems: 'center', padding: '0 15px' }}>
              <label className="control-label" htmlFor="" style={{ marginLeft: '-15px', marginRight: '10px', paddingRight: '0' }}>
                Quality score Min:
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  className="form-control"
                  name="reviewerScore"
                  value={currentFilters.reviewerScore || ''}
                  onChange={handleNumberInputChange}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div style={{ display: 'flex', alignItems: 'center', padding: '0 15px' }}>
              <label className="control-label" htmlFor="" style={{ marginLeft: '-15px', marginRight: '10px', paddingRight: '0' }}>
                Hourly rate max:
              </label>
              <div className="input-group">
                <input
                  type="number"
                  name="reviewerRate"
                  className="form-control"
                  value={currentFilters.reviewerRate || ''}
                  onChange={handleNumberInputChange}
                  style={{ borderRadius: '4px 0 0 4px' }}
                />
                <span className="input-group-addon">
                  {currencyCode || (
                    <span className="Select-loading-zone" aria-hidden="true">
                      <span className="Select-loading" />
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>

          <GroupCheckBox
            items={reviewerTypesItems}
            label="Reviewer Type"
            initValue={currentFilters.reviewerTypes}
            onChange={handleChangeReviewerType}
          />
        </div>
      </div>

      <div className="form-group margin-top-15">
        <div className="row">
          <FormField
            type="checkbox"
            classBox={'label-max-width-100'}
            value={currentFilters.respectClientExclusions}
            checked={currentFilters.respectClientExclusions}
            name="respectClientExclusions"
            md="2"
            sm="6"
            aligment="left"
            label="Respect Client Exclusion"
            onChange={handleChangeCheckBox}
          />
          <FormField
            type="checkbox"
            classBox={'label-max-width-100'}
            value={currentFilters.respectClientInclusions}
            checked={currentFilters.respectClientInclusions}
            name="respectClientInclusions"
            md="2"
            sm="6"
            aligment="left"
            label="Respect Client Inclusion"
            onChange={handleChangeCheckBox}
          />
          <FormField
            type="checkbox"
            classBox={'label-max-width-100'}
            value={currentFilters.includeBackup}
            checked={currentFilters.includeBackup}
            name="includeBackup"
            md="2"
            sm="6"
            aligment="left"
            label="Include Backup Reviewers"
            onChange={handleChangeCheckBox}
          />
          <FormField
            type="checkbox"
            classBox={'label-max-width-100'}
            value={currentFilters.preferNonBackupReviewers}
            checked={currentFilters.preferNonBackupReviewers}
            name="preferNonBackupReviewers"
            md="2"
            sm="6"
            aligment="left"
            label="Prefer Non Backup Reviewers"
            onChange={handleChangeCheckBox}
          />
          <FormField
            type="checkbox"
            classBox={'label-max-width-100'}
            value={currentFilters.sortByLastAssignedDate}
            checked={currentFilters.sortByLastAssignedDate}
            name="sortByLastAssignedDate"
            md="2"
            sm="6"
            aligment="left"
            label="Prefer More Recent Assignment"
            onChange={handleChangeCheckBox}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <FormField
            md="12"
            type="select"
            label="Countries"
            multi={true}
            options={countriesItems}
            name="countryCode"
            value={currentFilters.countries}
            onChange={handleChangeCountries}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <FormField
            type="REF_SPECIALTY"
            md="12"
            multi={true}
            label="Specialties"
            value={currentFilters.specialties}
            onChange={handleChangeSpecialties}
            labelAddon={<AllAnyAddon name={'specialityFilteringType'} onChange={handleLabelChange} value={currentFilters.specialityFilteringType} />}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <FormField
            type="REF_CASE_TYPE"
            md="12"
            multi={true}
            label="Case Types"
            value={currentFilters.caseTypes}
            onChange={handleChangeReviewerCaseType}
            labelAddon={<AllAnyAddon name={'caseTypeFilteringType'} onChange={handleLabelChange} value={currentFilters.caseTypeFilteringType} />}
          />
        </div>
      </div>
    </div>
  );
};

ReviewersFilteringNew.propTypes = {};

export default ReviewersFilteringNew;
