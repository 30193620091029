import React from 'react';
import {connect} from 'react-redux';
import shortId from 'shortid'
import FormField from '../../components/FormField'
import {createTemplate} from "../../redux/actions/templateActions";
import {getAll} from '../../redux/actions/BasicEntityActions'
import {getSelectFieldValue, idFromString} from '../../utils/helpers'
import FormValidateWrapper from '../../containers/FormValidateWrapper';

class TemplateCreateForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            workflowCode: '',
            name: '',
            active: true
        }
    }

    componentDidMount() {
        this.props.getInitData();
        this.props.dispatchValidState()
    }

    onChangeDisplayName = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value})
    };

    onChangeWorkflow = (workflowCode) => {
        // this.props.templateEditField('workflowCode', workflowCode)
        // console.log(workflowCode);
        this.setState({workflowCode})
    };

    onChangeLanguage = (language) => this.setState({language});

    onChangeCheckBox = (e) => {
        this.setState({[e.target.name]: e.target.checked})
    };

    onSubmit = (e) => {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            const code = idFromString(shortId.generate());
            const {workflowCode, name, active} = this.state;

            this.props.createTemplate({
                code,
                active: active,
                name: name,
                names: {'EN': name},
                workflowCode: getSelectFieldValue(workflowCode),
            })

        } else {
            this.props.showMessages()

        }
    };

    render() {
        return (<form className='modal-content custom-modal-content' onSubmit={this.onSubmit} noValidate>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            label="Template Name"
                            id="Template Name"
                            className="form-control"
                            name="name"
                            required
                            value={this.state.name}
                            onChange={this.onChangeDisplayName}
                            valids="required|unique"
                            validator={this.props.validator}
                            onBlur={this.props.dispatchValidState}
                        />
                        {/*  <FormField
                     md="2"
                     type="REF_LANGUAGE"
                     label="Language"
                     className="text-bg "
                     name="language"
                     value={this.state.language}
                     onChange={this.onChangeLanguage}
                     clearable={false}
                     /> */}
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type="select"
                            label="Work Flow"
                            id="Work Flow"
                            className="text-bg required-field"
                            options={this.props.workFlows}
                            name="workflowCode"
                            value={this.state.workflowCode}
                            onChange={this.onChangeWorkflow}
                            valids="required"
                            validator={this.props.validator}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type="checkbox"
                            title="Status"
                            label="Active"
                            name='active'
                            value={this.state.active}
                            checked={this.state.active}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"/>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right text-right">
                            <button
                                className="btn btn-flat btn-primary "
                                disabled={this.props.isLoading}
                            >
                                Add Template
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state) => {
    const templateNames = state.app.templates.allTemplates.map((item) => item.name);

    return {
        workFlows: state.app.workflows.data
            .filter((item) => item.active)
            .map((item) => ({label: item.name, value: item.code})),
        error: state.app.templates.error,
        loading: state.app.templates.loading,

        //VALIDATION options
        top: 0,
        customRules: {
            unique: {
                message: 'This Template already exists in the system.',
                rule: (val) => !templateNames.includes(val)
            }
        },
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInitData: () => {
            dispatch(getAll('workflow'))
        },
        createTemplate: data => dispatch(createTemplate(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(TemplateCreateForm))
