import {isObjectsEqual, makeSelectOptions} from '../../utils/helpers'

const baseCompany = {
    fullName: '',
    shortName: '',
    accountingEmail: '',
    accountingLastName: '',
    accountingPhone: '',
    accountingFirstName: '',
    margin: '',
    street1: '',
    street2: '',
    street3: '',
    city: '',
    stateCode: null,
    postalCode: '',
    countryCode: '',
    notes: '',
    vatId: '',
    currencyCode: '',
    defaultLanguageCode: '',
    defaultTemplateCode: '',
    caseFileTemplateCode: '',
    defaultTimezone: '',
    defaultLocale: '',
    ipWhiteList: [],
    contractDate: null,
    rolesAllowedToReopenCodes: [],
    logo: null,
    savedLogo: [],
    billingTiers: [],
    selectedPhysicianCodes: [],
    selectedFacilityCodes: [],
    msNotes: [],
    mdNotes: [],
};

const defaultState = {
    loading: false,
    loadedCount: 0,
    data: [],
    logo: '',
    editedCompany: baseCompany,
    originalCompany: baseCompany,
    error: '',
    editGeneralError: null,
    editAdminError: null,
    editBillingError: null,
    editTemplateError: null,
    editFacilitiesError: null,
    editedSuccess: false,
    allValid: false,
    currentTab: '',
    isProfileChanged: false
};

const companies = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_COMPANY_LOGO_LOADING':
        case 'FETCH_COMPANIES_LOADING':
            return {...state, loading: true, error: null};
        case 'FETCH_COMPANY_LOADING':
            return {
                ...state,
                loading: true,
                error: null,
                editedCompany: defaultState.editedCompany,
                editedSuccess: false,
                validationError: false,
                isProfileChanged: false,
            };
        case 'FETCH_EDIT_COMPANY_LOADING':
            return {
                ...state,
                loading: true,
                error: null,
                editedSuccess: false,
                validationError: false,
            };
        case 'FETCH_COMPANIES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null
            });
        case 'FETCH_COMPANY_SUCCESS': {
            const profile = {
                ...action.payload,
                notificationEmail: action.payload.notificationEmail ? action.payload.notificationEmail : action.payload.email,
                ipWhiteList: makeSelectOptions(action.payload.ipWhiteList),
                savedLogo: [],
                msNotes: [],
                mdNotes: [],
                firstInvoiceDate: action.payload.firstInvoiceDate ? new Date(action.payload.firstInvoiceDate) : null,
                contractDate: action.payload.contractDate ? new Date(action.payload.contractDate) : null,
            };

            return Object.assign({}, state, {
                loading: false,
                error: null,
                editedCompany: profile,
                originalCompany: profile,
            });
        }
        case 'FETCH_COMPANY_BILLING_TIERS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editedCompany: {
                    ...state.editedCompany,
                    billingTiers: action.payload,
                }
            });

        case 'CLEAR_COMPANY_BILLING_GROUP':
            return Object.assign({}, state, {
                editedCompany: {
                    ...state.editedCompany,
                    billingGroup: null,
                    billingTiers: [],
                }
            });

        case 'FETCH_COMPANY_LOGO_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                logo: action.payload,
                error: null
            });
        case 'FETCH_COMPANY_LOGO_ERROR':
        case 'FETCH_ALL_MS_NOTES_ERROR':
        case 'FETCH_ALL_MD_NOTES_ERROR':
        case 'FETCH_EDIT_MS_NOTES_ERROR':
        case 'FETCH_EDIT_MD_NOTES_ERROR':
        case 'FETCH_COMPANY_ERROR':
            return {...state, error: action.payload, loading: false};
        case 'FETCH_CREATE_COMPANY_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                data: [...state.data, action.payload]
            });
        case 'FETCH_EDIT_COMPANY_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editGeneralError: null,
                editAdminError: null,
                editBillingError: null,
                editTemplateError: null,
                data: state.data.map((cp) => {
                    if (cp.code === action.payload.code) return action.payload;
                    return cp
                }),
                editedCompany: {
                    ...action.payload,
                    ipWhiteList: makeSelectOptions(action.payload.ipWhiteList)
                },
                originalCompany: {
                    ...action.payload,
                    ipWhiteList: makeSelectOptions(action.payload.ipWhiteList)
                },
                editedSuccess: true,
                isProfileChanged: false
            });

        case 'FETCH_ALL_MS_NOTES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editedCompany: {
                    ...state.editedCompany,
                    msNotes: action.payload
                }
            });

        case 'FETCH_ALL_MD_NOTES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editedCompany: {
                    ...state.editedCompany,
                    mdNotes: action.payload
                }
            });

        case 'FETCH_EDIT_MS_NOTES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                // editedCompany: {
                //     ...state.editedCompany,
                //     msNotes:  editOrPush(state.editedCompany.msNotes, action.payload, 'code')
                // }
            });

        case 'FETCH_EDIT_MD_NOTES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                // editedCompany: {
                //     ...state.editedCompany,
                //     mdNotes:  editOrPush(state.editedCompany.mdNotes, action.payload, 'code')
                // }
            });

        case 'EDIT_COMPANY_FIELD':
            return Object.assign({}, state, {
                editedCompany: Object.assign({}, state.editedCompany, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedCompany, action.payload), state.originalCompany)
            });
        case 'VALIDATE_COMPANY_FIELD':
            return Object.assign({}, state, {
                allValid: action.payload
            });
        case 'SHOW_COMPANY_VALIDATION':
            return Object.assign({}, state, {
                validationError: true
            });
        case 'SET_CURRENT_TAB':
            return Object.assign({}, state, {
                currentTab: action.payload
            });

        case 'FETCH_COMPANY_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            };
        case 'FILL_COMPANY_LOCAL_STORAGE': {
            let items = {};
            for (let i = 0; i < state.data.length; i += 1) {
                items[state.data[i].code] = state.data[i].fullName;
            }
            localStorage.setItem('list_company', JSON.stringify(items));
            return state;
        }
        case 'FETCH_COMPANY_LOADED_COUNT':
            return {...state, loadedCount: action.payload}

        default:
            return {...state};
    }
};

export default companies
