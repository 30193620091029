import ColoredBox from 'components/ColoredBox/ColoredBox';
import React from 'react';
import { useState } from 'react';
import {
  Col,
  DropdownButton,
  FormControl,
  FormGroup,
  MenuItem,
  Row,
  Form,
  Button,
  ControlLabel,
} from 'react-bootstrap';
import axios from 'axios';
import { LOCAL_API_HOST } from '../../config';

const ApiTester = () => {
  const [method, setMethod] = useState('get');
  const [url, setUrl] = useState('/api/');
  const [body, setBody] = useState({});
  const [response, setResponse] = useState('');
  const [status, setStatus] = useState(null);
  const [validationErrors, setValidationErrors] = useState('');

  const handleBodyChange = ({ target: { value } }) => {
    try {
      const body = JSON.parse(value);
      setBody(body);
      setValidationErrors('');
    } catch (e) {
      setValidationErrors('Invalid json');
      setBody({});
    }
  };

  const submit = () => {
    let endPoint = LOCAL_API_HOST + url;
    //console.log('Submit', endPoint, method);
    axios[method](endPoint,
                  method === 'get' ? { withCredentials: true } : body,
                  method === 'get' ? undefined : { withCredentials: true })
      .then((response) => {
        setResponse(JSON.stringify(response.data));
        setStatus(response.status);
      })
      .catch(({ response }) => {
        setResponse(JSON.stringify(response.data));
        setStatus(response.status);
      });
  };

  return (
    <ColoredBox title="Api tester" variant="danger" className="mg-20">
      <Row>
        <Col sm={12}>
          <Form inline>
            <FormGroup>
              <DropdownButton
                id="methoddropdown"
                title={method.toUpperCase()}
                onSelect={(m) => setMethod(m)}
              >
                <MenuItem eventKey="get" key="get">
                  GET
                </MenuItem>
                <MenuItem eventKey="post" key="post">
                  POST
                </MenuItem>
              </DropdownButton>

              <FormControl
                value={url}
                onChange={(event) => setUrl(event.target.value)}
                style={{ minWidth: 500 }}
              />
            </FormGroup>
          </Form>
        </Col>
      </Row>

      <Row className="mg-top-20">
        <Col md={12}>
          <Form inline>
            <FormGroup>
              <FormControl
                componentClass="textarea"
                defaultValue="{}"
                onChange={handleBodyChange}
                style={{ minWidth: 582 }}
              />
            </FormGroup>
          </Form>
        </Col>
      </Row>

      <Row className="mg-top-10">
        <Col md={12}>
          <span className="text-danger">{validationErrors}</span>
        </Col>
      </Row>

      <Row className="mg-top-10">
        <Col md={12}>
          <Form inline>
            <FormGroup>
              <Button onClick={() => submit()}>Submit</Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>

      <Row className="mg-top-20">
        <Col md={12}>
          <Form>
            <FormGroup>
              <ControlLabel>Last status: {status}</ControlLabel>
              <FormControl
                componentClass="textarea"
                placeholder="Response goes here..."
                style={{ minWidth: 582, minHeight: 300 }}
                readOnly
                value={response}
              />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </ColoredBox>
  );
};

export default ApiTester;
