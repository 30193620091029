const { capitalize } = require('lodash');

const mapUnprocessableEntityErrorMessages = (errors) =>
    errors.map(({ fieldName, errorMessage }) => ({
        id: fieldName,
        field: capitalize(fieldName),
        message: errorMessage,
    }));

export { mapUnprocessableEntityErrorMessages };
