import React from 'react';
import {connect} from 'react-redux';
import shortId from 'shortid'
import PropTypes from "prop-types";
import FormField from '../../components/FormField/index'
import {closeCreateModal} from '../../redux/action-creators/users'
import {idFromString} from '../../utils/helpers'
import {createEntity} from "../../redux/actions/BasicEntityActions";
import FormValidateWrapper from '../../containers/FormValidateWrapper';
import {getFullBillingGroup} from "../../redux/actions/billingActions";

class CopyBillingGroupForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            originalBillingCode: null,
            name: '',
            copiedValues: {}
        };
    }

    componentDidMount() {
        this.props.dispatchValidState();
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.props.validator.allValid()) {
            const {copiedValues, name} = this.state;
            const code = idFromString(shortId.generate());
            const data = {
                ...copiedValues,
                code,
                name
            };
            this.props.getFullBillingGroup(this.state.originalBillingCode)
                .then(() => {
                    this.props.createBillingGroup(data)
                        .then(() => {
                            let billingTiers = this.props.group.billingTiers;
                            if (billingTiers && billingTiers.length) {
                                for (let i = 0, len = billingTiers.length; i < len; i++) {
                                    let tier = billingTiers[i];
                                    tier['billingGroup'] = code;
                                    tier['code'] = shortId.generate();
                                    this.props.createTier(tier);
                                }
                            }
                        });
                });

        } else {
            this.props.showMessages()
        }
    };

    onChangeName = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
        setTimeout(() => {
            this.props.dispatchValidState();
        }, 600);
    };

    onChangeBillingGroup = (data) => {
        if (data) {
            const {value, entity} = data;
            this.setState({originalBillingCode: value, copiedValues: entity})
        } else this.setState({originalBillingCode: null, copiedValues: {}})
    };

    render() {
        let {originalBillingCode, name} = this.state;
        let {validator, dispatchValidState, isLoading} = this.props;

        return (
            <form
                className='modal-content custom-modal-content'
                noValidate={true}
                onSubmit={this.onSubmit}
            >
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            label="Billing Group Name"
                            className="form-control"
                            name="name"
                            required
                            value={name}
                            onChange={this.onChangeName}
                            valids="required|unique"
                            validator={validator}
                            onBlur={dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type="REF_BILLING_GROUP"
                            label="Select Billing Group"
                            className="required-field text-bg"
                            valids="required"
                            validator={validator}
                            value={originalBillingCode}
                            onChange={this.onChangeBillingGroup}
                            onBlur={dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right text-right">
                            <button
                                className="btn btn-flat btn-primary "
                                disabled={isLoading}
                            >
                                Copy Billing Group
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

CopyBillingGroupForm.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    let BGNames = state.app.billing_group.data
        .map((item) => item.name);
    return {
        isLoading: state.app.billing_group.loading,
        error: state.app.billing_group.error,
        group: state.app.billing_group.editedBillingGroup,

        //VALIDATION options
        top: 0,
        customRules: {
            unique: {
                message: 'This Billing Group already exists in the system.',
                rule: function(val) {
                    return !BGNames.includes(val);
                }
            }
        }
    }
};

const mapDispatchToProps = dispatch => ({
    getFullBillingGroup: (code) => dispatch(getFullBillingGroup(code)),
    createTier: (data) => dispatch(createEntity('billing_tier', data)),
    closeCreateModal: () => {
        dispatch(closeCreateModal())
    },
    createBillingGroup: data => dispatch(createEntity('billing_group', data))
});


export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(CopyBillingGroupForm))
