import {SubmissionError} from 'redux-form';
//import { performLogin, Action } from 'react-cognito';
// import store from '../redux/store'
import { API_PATH, API_HOST } from '../config';

const jsonLdMimeType = 'application/ld+json';

// var Tokens = null;
// store.subscribe(() => {
// 	Tokens = store.getState().cognito.user
// })

const provideToken = () => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
// 		if (!localStorage.getItem('user')) {
// 			console.warn("Not authenticated");
// 			reject("Not authenticated")
// 			return
// 		}
// 		const idToken = Tokens.signInUserSession.idToken;
// 		if ((idToken.payload.exp * 1000) < (new Date().getTime())) {
// 			let {user, config} = store.getState().cognito
// 			performLogin(user, config)
// 				.then(successAction => {
// 					console.info("Refreshed id token")
// 					store.dispatch(successAction)
// 					resolve(Tokens.signInUserSession)
// 				})
// 				.catch(() => {
// 					store.dispatch(Action.logout())
// 					console.warn("Can not refresh idToken. Needs to re-login")
// 					reject("Needs re-login")
// 				});
// 		} else {
// 			resolve(Tokens.signInUserSession)
// 		}
    })
}

export default function (url, options = {}) {
    // eslint-disable-next-line no-unused-vars
    return provideToken().then(({idToken}) => {
        if ('undefined' === typeof options.headers) options.headers = new Headers();
        if (null === options.headers.get('Accept')) options.headers.set('Accept', jsonLdMimeType);

        if ('undefined' !== options.body && !(options.body instanceof FormData) && null === options.headers.get('Content-Type')) {
            options.headers.set('Content-Type', jsonLdMimeType);
        }

        const link = url.includes(API_PATH) ? API_HOST + url : API_HOST + API_PATH + url;

        //options.headers.set("Authorization", `Bearer ${idToken.jwtToken}`)

        return fetch(link, options).then(response => {
            if (response.ok) {
                return response;
            }
            return response
                .json()
                .then(json => {
                    const error = json['description'] ? json['description'] : response.statusText;
                    if (!json.violations) throw Error(error);

                    let errors = {_error: error};
                    json.violations.map((violation) => errors[violation.propertyPath] = violation.message);

                    throw new SubmissionError(errors);
                });
        });
    })
}
