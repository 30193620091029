import React from 'react';
import { connect } from 'react-redux';
import { editField, editSCSystem, getSCSystem, isAllValid } from '../../../redux/actions/scsystemsActions'
import PropTypes from "prop-types";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Box from "../../../components/Box";
import BoxHeader from "../../../components/BoxHeader";
import BoxBody from "../../../components/BoxBody";
import FormField from "../../../components/FormField";
import SimpleReactValidator from "utils/simple-react-validator";
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import SystemMessage from '../../../components/SystemMessage';

class CodeEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: { value: 'EN', label: 'English' },
        }

        let this_props = this.props;
        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This Code already exists in the system.',
                rule: function (val, options) {
                    let code_names = [];
                    this_props.codes.forEach((item) => {
                        if (item.code !== this_props.match.params.id) {
                            code_names.push(item.name);
                        }
                    });
                    return !code_names.includes(val);
                }
            }
        });
        this.validator.showMessages()

        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    }

    componentDidMount() {
        this.props.getScSystem(this.props.match.params.id);
    }

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onChange(e) {
        this.props.editField(e.target.name, e.target.value,)

    }

    onChangeDescription = (e) => {
        const { value } = e.target

        const description = value ? Object.assign({}, this.props.scSystem.description, {
                [ this.state.language.value ]: value
            }) :
            { ...this.props.scSystem.description, 'EN': undefined }

        this.props.editField('description', description)
    }

    onChangeCheckBox(e) {
        this.props.editField(e.target.name, e.target.checked)
    }

    setDescValue = () => {
        const { description } = this.props.scSystem;
        const { language } = this.state;

        return description && description[ language.value ] ? description[ language.value ] : ''
    }

    onSave = () => {
        const { names, name } = this.props.scSystem

        return this.props.editSCSystem({
            ...this.props.scSystem,
            name: names ? names[ 'EN' ] : name,
        })
    }

    render() {
        let valid_names = 'required|unique:' + this.props.code_names;
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[ field ],
        }))
        const { isEditable } = this.props;
        return (
            <div className="content" style={{ marginTop: '30px' }}>
                <PreventTransitionPrompt
                    when={this.props.isProfileChanged}
                    message='MyCustomDialogComponent'
                    entity='sc_system'
                    isFormValid={this.props.isValid}
                    saveChanges={this.onSave}
                    cancelAndDiscard={() => this.props.getScSystem(this.props.scSystem.code)}
                />

                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <SystemMessage shown={this.props.editSCSystemSuccess && this.props.isValid} type='success'
                               message={this.context.translate('common.message.code_success')}/>

                <SystemMessage shown={this.props.showValidMessages} type='error' message={this.props.error} validationMessages={errorMessages}/>

                <Box color="green" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`${this.context.translate('common.form.code')} - ${this.props.scSystem.name}`} color="green"
                               icon="fa-list-alt" classes="box-profile-first-row-height-show"/>
                    <BoxBody>
                        <form
                            className='modal-content custom-modal-content'
                            onSubmit={this.Submit}>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        label={this.context.translate('common.form.code')}
                                        id={this.context.translate('common.form.code')}
                                        required
                                        valids={valid_names}
                                        validator={this.validator}
                                        value={this.props.scSystem.name}
                                        onChange={this.onChange}
                                        name='name'
                                        onBlur={this.dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        label={this.context.translate('common.form.description')}
                                        value={this.setDescValue()}
                                        onChange={this.onChangeDescription}
                                        name='description'
                                        onBlur={this.dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="4"
                                        type="checkbox"
                                        title={this.context.translate('common.form.status')}
                                        label={this.context.translate('common.form.active')}
                                        name='active'
                                        value={this.props.scSystem.active}
                                        checked={this.props.scSystem.active}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        onBlur={this.dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                    <FormField
                                        md="4"
                                        type="checkbox"
                                        title={!this.props.mcSystem.service ? this.context.translate('common.message.only_for_diagnoses') : this.context.translate('common.form.services')}
                                        label={this.context.translate('common.form.services')}
                                        name='service'
                                        disabled={!isEditable || !this.props.mcSystem.service}
                                        value={!this.props.mcSystem.service ? false : this.props.scSystem.service}
                                        checked={!this.props.mcSystem.service ? false : this.props.scSystem.service}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        onBlur={this.dispatchValidState}
                                    />
                                    <FormField
                                        md="4"
                                        type="checkbox"
                                        title={!this.props.mcSystem.diagnosis ? this.context.translate('common.message.only_for_services') : this.context.translate('common.form.diagnoses')}
                                        label={this.context.translate('common.form.diagnoses')}
                                        name='diagnosis'
                                        disabled={!isEditable || !this.props.mcSystem.diagnosis}
                                        value={!this.props.mcSystem.diagnosis ? false : this.props.scSystem.diagnosis}
                                        checked={!this.props.mcSystem.diagnosis ? false : this.props.scSystem.diagnosis}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        onBlur={this.dispatchValidState}
                                    />
                                </div>
                            </div>
                        </form>
                    </BoxBody>
                </Box>
            </div>
        );
    }
}

CodeEditPage.contextTypes = {
    translate: PropTypes.func
}

const allowToEdit = (role) => (role === 'ROLE_ADMIN' || role === 'ROLE_CLIENT_ADMIN');

const mapStateToProps = (state) => ({
    isLoading: state.app.scSystem.isLoading,
    isEditable: allowToEdit(state.app.auth.user.roleCode) || state.app.users.my_permissions.includes('MANAGE_SUR'),
    isProfileChanged: state.app.scSystem.isProfileChanged,
    showValidMessages: state.app.scSystem.showValidMessages,
    error: state.app.scSystem.error,
    mcSystem: state.app.mcSystem.editedMCSystem,
    scSystem: state.app.scSystem.editedSCSystem,
    editSCSystemSuccess: state.app.scSystem.editSCSystemSuccess,
    codes: state.app.scSystem.data,
    isValid: state.app.scSystem.scSystemValid
})

const mapDispatchToProps = dispatch => ({
    getScSystem: code => dispatch(getSCSystem(code)),
    editField: (field, value) => dispatch(editField(field, value)),
    isAllValid: (valid) => dispatch(isAllValid(valid)),
    editSCSystem: (scSystem) => dispatch(editSCSystem(scSystem))

})

export default connect(mapStateToProps, mapDispatchToProps)(CodeEditPage)
