import React from 'react';
import PropTypes from "prop-types";

const RadioAddon = (props) => {
    const { data, name, onChange, value, disabled } = props;

    const inputs = data.map((labelRadioPair) => {
        const { label, radio } = labelRadioPair;

        const checkedCondition = value === radio;
        const styleDisabled = disabled && !checkedCondition;
        return (
            <React.Fragment key={radio}>
                <div className="radio-inline" style={{ paddingLeft: '10px', paddingRight: '5px' }}>
                    <label className="input-container"
                           htmlFor={`${label}${name}${radio}`}
                           style={{ fontWeight: 500, marginLeft: 5, maxWidth: '100%', marginBottom: 0 }}
                           id={'displayName-' + name}
                    >
                        {label}
                        <input
                            checked={checkedCondition}
                            id={`${label}${name}${radio}`}
                            name={name}
                            onChange={onChange}
                            style={{ marginLeft: 15 }}
                            type={'radio'}
                            value={radio}
                            disabled={disabled}
                        />
                        <span className="input-checkmark" style={styleDisabled ? { backgroundColor: '#ddd', borderColor: '#999' } : null}> </span>
                    </label>
                </div>
            </React.Fragment>
        );
    });

    return <span className={'margin-left-5'}>{inputs}</span>;
};

RadioAddon.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
};

export default RadioAddon;
