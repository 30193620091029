import React, {Component} from "react";
import {connect} from 'react-redux'

import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'

import {userEditField} from '../../redux/action-creators/users'

import { API_HOST } from '../../config';
import Dropzone from "../../components/deprecated/react-dropzone/src";

class ReviewerEditSignature extends Component {
    constructor(props) {
        super(props);

        this.delImage = this.delImage.bind(this);
    }

    onDrop(savedSignature) {
        this.props.editField('savedSignature', savedSignature)
    }

    delImage() {
        this.props.editField('signature', null);
        this.props.editField('savedSignature', [])
        // this.setState({'savedSignature': []});
    }

    render() {
        const {savedSignature, signature} = this.props.user;

        return (
            <Box color="blue" classes="box-profile-first-row-height">
                <BoxHeader title="Reviewer - Signature" color="blue" icon="fa-file-signature"
                />
                <BoxBody>
                    <div className="form-group has-feedback">
                        <div className="form-group has-feedback">
                            <Dropzone
                                id="user-profile-image"
                                className={`dz-clickable ${signature != null || savedSignature.length !== 0 ? 'hidden' : null}`}
                                onDrop={this.onDrop.bind(this)}
                                multiple={false}
                            />
                            <div id="image-div"
                                 className={savedSignature && savedSignature.length === 0 ? 'hidden' : 'show'}>
                                {
                                    savedSignature.map(f =>
                                        <img key={f.name} className="" alt="client logo" src={f.preview}/>
                                    )
                                }
                            </div>
                            <div id="image-div"
                                 className={signature === null || (savedSignature && savedSignature.length !== 0) ? 'hidden' : 'show'}>
                                <img key={signature} className="" alt="staff.edit_content.user_image"
                                     src={API_HOST + '/user/signature?code=' + this.props.user.code}/>
                            </div>
                            {/* eslint-disable-next-line */}
                            <a className="btn-lg remove-profile-image" onClick={this.delImage}>
                                <i className="fa fa-times pull-right"/>
                            </a>
                        </div>
                    </div>
                </BoxBody>
                <BoxFooter>
                    Click inside the grey rectangle or drag & drop a file
                </BoxFooter>
            </Box>
        );
    }
}

const mapState = (state) => {
    return {
        user: state.app.users.editedUser,
        error: state.app.users.error
    }
};

const mapActions = dispatch => {
    return {
        editField: (field, value) => dispatch(userEditField(field, value)),
    }
};

export default connect(mapState, mapActions)(ReviewerEditSignature);
