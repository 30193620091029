import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';
import { patientCaseStages } from '../patientCaseStages';

const closedLastMonthSlice = createDatatableTemplateSlice({
    name: patientCaseStages.CLOSED_LAST_MONTH,
    initialSettings: {},
});

export const closedLastMonthReducer = closedLastMonthSlice.reducer;

export const { loadData, dataLoaded, dataLoadError } = closedLastMonthSlice.actions;

export default closedLastMonthSlice;
