import React, { useEffect } from 'react';
import ColoredBox from 'components/ColoredBox/ColoredBox';
import PageContainer from 'components/PageContainer/PageContainer';
import ConnectedDataTable from '../../components/Datatable/ConnectedDataTable';
import reviewerSlice from '../../redux/pages/reviewer/reviewerSlice';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getAllCurrencies } from 'redux/actions/currencyActions';
import { getAllSpecialties } from 'redux/actions/specialtyActions';

const getColumns = (currencies, specialties) => ({
  firstName: { label: 'First Name', colStyle: { maxWidth: 150 } },
  lastName: { label: 'Last Name', colStyle: { maxWidth: 150 } },
  specialties: {
    label: 'Specialties',
    colStyle: {
      minWidth: 250,
      width: 400,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    filterOptions: specialties,
    sortable: false,
  },
  email: { label: 'Email', colStyle: { maxWidth: 150 } },
  phoneNumber: { label: 'Phone', colStyle: { maxWidth: 150 } },
  country: { label: 'Country', colStyle: { maxWidth: 200 }, multiSelect: true },
  currency: {
    label: 'Currency',
    colStyle: { maxWidth: 150, width: 150 },
    filterOptions: currencies,
  },
  hourlyRateInEuro: {label: 'Hourly Rate', colStyle: { minWidth: 200 } },
  status: {
    label: 'Status',
    colStyle: { maxWidth: 150, width: 150 },
    filterOptions: [
      { value: true, label: 'ACTIVE' },
      { value: false, label: 'INACTIVE' },
    ],
  },
});

const ReviewersPage = ({ history }) => {
  const dispatch = useDispatch();

  const handleRowClick = ({ code }) => {
    history.push(`/reviewers/profile/${code}`);
  };

  const { specialties, currencies } = useSelector((state) => ({
    specialties: state.app.specialties.data.map((spec) => ({
      label: spec.name,
      value: spec.name,
    })),
    currencies: state.app.currencies.data.map((currency) => ({
      label: currency.shortName,
      value: currency.shortName,
    })),
  }));

  useEffect(() => {
    dispatch(getAllCurrencies());
    dispatch(getAllSpecialties());
  }, [dispatch]);

  return (
    <PageContainer>
      <ColoredBox variant="primary" title="Reviewers">
        <ConnectedDataTable
          columns={getColumns(currencies, specialties)}
          onRowClick={handleRowClick}
          stateKey="reviewersReducer"
          actions={reviewerSlice.actions}
          variant="primary"
        />
      </ColoredBox>
    </PageContainer>
  );
};

ReviewersPage.propTypes = {
  history: PropTypes.object,
};

export default ReviewersPage;
