import { connect } from 'react-redux'
import {getAll, getOne4Select, setLoadedCount} from '../../redux/actions/BasicEntityActions'
import RefField from './RefField'

const mapStateToProps = (state) => ({
    isLoading:  state.app.billingTiers.loading,
    loadedCount: state.app.billingTiers.loadedCount,
    options: state.app.billingTiers.data
        .filter((item) => item.active)
        .map(item => ({
            value: item.code,
            label: item.tierName
        })),
})

const mapDispatchToProps = (dispatch) => ({
    getOptions: () => dispatch(getAll('billing_tier')),
    getOne4Select: (code) => dispatch(getOne4Select(code, 'billing_tier')),
    setLoadedCount: (count) => dispatch(setLoadedCount(count, 'billing_tier'))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefField)
