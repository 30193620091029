import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'
import PropTypes from "prop-types";
import { editCaseType } from '../../../redux/actions/cases/caseTypesActions'
import { showValidation } from '../../../redux/actions/BasicEntityActions'


class SaveReviwerProfile extends React.Component {
    constructor() {
        super();
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        if (this.props.isProfileValid) {
            e.preventDefault();
            const { names, name } = this.props.profile

            this.props.saveProfile({
                ...this.props.profile,
                name: names ? names[ 'EN' ] : name,
                names: names ? names : { 'EN': name }
            }, closeAfterSave)
        } else {
            this.props.showValidation()
        }
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton color='blue' disabled={!this.props.isProfileChanged} onSubmit={this.onSave}
                              showTooltip={!this.props.isProfileValid}></SubmitButton>
                <SubmitButton color='blue' disabled={!this.props.isProfileChanged} onSubmit={(e) => this.onSave(e, true)} title='Save And Close'
                              showTooltip={!this.props.isProfileValid}></SubmitButton>
            </React.Fragment>
        )
    }
}

SaveReviwerProfile.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => ({
    profile: state.app.case_types.editedCaseType,
    isProfileValid: state.app.case_types.isCaseTypeValid,
    isProfileChanged: state.app.case_types.isProfileChanged,
    isEditable: state.app.users.my_permissions.includes('MODIFY_CASE_TYPE'),
})

const mapDispatchToProps = dispatch => ({
    saveProfile: (data, closeAfterSave) => dispatch(editCaseType(data, closeAfterSave)),
    showValidation: () => dispatch(showValidation('case_type')),

})

export default connect(mapStateToProps, mapDispatchToProps)(SaveReviwerProfile)
