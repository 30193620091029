import React from 'react'
import HelperText from './HelperText';

const TextReadField = (props) => {
    let i = <textarea
        type={props.type}
        className={props.className + ' form-control'}
        name={props.name}
        id={props.id}
        required={props.required}
        disabled={props.disabled}
        checked={props.checked}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        title={props.title}
        rows={props.rows || 5}
        value={props.value}
    />
    let classes = ""
    classes += " col-md-" + (props.md || "6")
    classes += " col-xs-" + (props.xs || "12")

    const validMessage = (
     <span style={{display:'none'}}>
      {
        props.validator && props.valids ?
        props.validator.message(
          props.validLabel || props.label,
           props.value && typeof props.value === 'string' ? props.value.trim() : props.value, props.valids,
            'text-danger') : ''
      }
    </span>)

    return(
        <div className={classes}>
            <label htmlFor={props.id} className="required" id={'displayName-' + props.name}>
                {props.label}
                {props.tickMark}
            </label>
            {props.title && <HelperText helperText={props.title}/>}
            {validMessage}
            {i}
        </div>
    )
}

export default TextReadField
