import api from "../../utils/api";
import {Statuses} from "./statuses";
import history from '../history'

export const getActivePhysicians = () => (dispatch) => {
    dispatch({
        type: 'FETCH_PHYSICIAN_LOADING',
    });

    api.get('physician/all')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_PHYSICIAN_SUCCESS',
                payload: data
            });
            dispatch({
                type: 'FILL_PHYSICIAN_LOCAL_STORAGE'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PHYSICIAN_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_PHYSICIAN_ERROR',
                    payload: error.message
                });
            }
        })
};

export const getCompanyPhysicians = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_PHYSICIAN_LOADING',
    });

    let companyCode = getState().app.cases.editedCase.company;

    api.get('physician/all_by_company?companyCode=' + companyCode)
        .then(({data}) => {
            dispatch({
                type: 'FETCH_PHYSICIAN_SUCCESS',
                payload: data
            });
            dispatch({
                type: 'FILL_PHYSICIAN_LOCAL_STORAGE'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PHYSICIAN_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_PHYSICIAN_ERROR',
                    payload: error.message
                });
            }
        })
};

export const getPhysician = (code) => dispatch => {
    dispatch({
        type: 'FETCH_PHYSICIAN_LOADING',
    });

    return api.get(`physician/get?code=${code}`)
        .then(({data}) => dispatch({
            type: 'FETCH_GET_PHYSICIAN_SUCCESS',
            payload: data
        }))
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PHYSICIAN_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_PHYSICIAN_ERROR',
                    payload: error.message
                });
            }
        })
};

export const createNewPhysician = (physician) => (dispatch) => {
    dispatch({
        type: 'FETCH_PHYSICIAN_LOADING',
    });

    api.post('physician/create', physician)
        .then(() => {
            dispatch({
                type: 'FETCH_CREATE_PHYSICIAN_SUCCESS',
                payload: physician
            });
            dispatch({
                type: 'CLOSE_CREATE_MODAL'
            });
            history.push(`/physicians/${physician.code}`)
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_PHYSICIAN_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_PHYSICIAN_ERROR',
                    payload: Statuses[error.request.status]
                });
            }
        })
};


export const editPhysician = (data, closeAfterSave) => dispatch => {
    dispatch({
        type: 'FETCH_PHYSICIAN_LOADING',
    });

    return api.post('physician/update', data)
        .then(({config}) => {
            dispatch({
                type: 'FETCH_EDIT_PHYSICIAN_SUCCESS',
                payload: JSON.parse(config.data)
            });
            closeAfterSave && history.goBack()
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_EDIT_PHYSICIAN_ERROR',
                payload: Statuses[err.response] || Statuses[0]
            })
        })
};

export const editField = (field, value) => ({
    type: 'PHYSICIAN_EDIT_FIELD',
    payload: {
        [field]: value
    }
});

export const setCurrentTab = (tab) => {
    return {
        type: 'SET_PHYSICIAN_CURRENT_TAB',
        payload: tab
    }
};

export const isAllValid = valid => {
    return {
        type: 'VALIDATE_PHYSICIAN_FIELD',
        payload: valid
    }
};

export const setDefaultPhysician = valid => {
    return {
        type: 'SET_DEFAULT_PHYSICIAN',
        payload: valid
    }
};
