import { useSelector } from 'react-redux';

const usePermissions = () => {
    const permissions = useSelector((state) => state.app.users.my_permissions);

    return {
        permissions,
        can: (p) => permissions.includes(p),
    };
};

export default usePermissions;
