import React from 'react'
import { connect } from 'react-redux';
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import { entityEditField } from '../../redux/actions/BasicEntityActions'
import FormField from "../../components/FormField";
import PropTypes from "prop-types";
import AddressInfoSection from "../../components/Sections/AddressInfoSection";
import { getAllCompaniesMinimal } from "../../redux/actions/financialReportActions";

class PatientGeneralInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.getAllCompaniesMinimal();
    }

    onChangeCheckBox = (e) => {
        this.props.userEditField([e.target.name], e.target.checked)
    };

    onChange = (e) => {
        if(e.target.name.toLowerCase().includes('email'))
            this.props.userEditField(e.target.name, e.target.value.replace(/\s+/g, ''));
        else
            this.props.userEditField(e.target.name, e.target.value );
    };

    onChangeGender = (gender) => {
        this.props.userEditField('gender', gender)
    };

    onChangeBirthDate = (birthDate) => {
        if (birthDate) {
            let timezoneOffsetInMilliSec = birthDate.getTimezoneOffset() * 60 * 1000;
            birthDate = birthDate.getTime() - timezoneOffsetInMilliSec;
        }
        this.props.userEditField('birthDate', birthDate);
    };

    onChangeCompany = (company) => {
        this.props.userEditField('company', company)
    };

    onChangeCountry = (country) => {
        if (!country || country.value !== 'US') {
            this.props.userEditField('country', country);
            this.props.userEditField('state', null);
        } else {
            this.props.userEditField('country', country);
        }
    };

    onChangeState = (state) => {
        this.props.userEditField('state', state)
    };

    render() {
        const { patient, isEditable } = this.props;
        let title = 'Patient';
        if (patient.firstName || patient.lastName) {
            title = `Patient - ${patient.firstName} ${patient.lastName}`;
        }
        let timezoneOffsetInMilliSec = new Date(patient.birthDate).getTimezoneOffset() * 60 * 1000;
        let birthDate = patient.birthDate - timezoneOffsetInMilliSec;
        return (
            <div className="content">
                <Box color="blue" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`${title}`} color="blue" icon="fa-user"
                               classes="box-profile-first-row-height-show"/>
                    <BoxBody>
                        <form onSubmit={this.onSubmit}>
                            <div className="bordered form-group">
                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="12"
                                            type='label_field'
                                            label="Personal Information"
                                            className='margin-bottom-15 fontWeight800'
                                            style={{ fontSize: '1.1em' }}
                                        />
                                        <FormField
                                            md="4"
                                            label="First Name"
                                            id="First Name"
                                            className="form-control"
                                            required
                                            valids="required|alpha"
                                            validator={this.props.validator}
                                            name="firstName"
                                            value={patient.firstName}
                                            onChange={this.onChange}
                                            onBlur={this.props.dispatchValidState}
                                            disabled={!isEditable}
                                        />
                                        <FormField
                                            md="4"
                                            label="Middle Name"
                                            id="Middle Name"
                                            className="form-control"
                                            name="middleName"
                                            valids="alpha"
                                            validator={this.props.validator}
                                            value={patient.middleName}
                                            onChange={this.onChange}
                                            onBlur={this.props.dispatchValidState}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="4"
                                            label="Last Name"
                                            id="Last Name"
                                            className="form-control"
                                            required
                                            valids="required|alpha"
                                            validator={this.props.validator}
                                            name="lastName"
                                            value={patient.lastName}
                                            onChange={this.onChange}
                                            onBlur={this.props.dispatchValidState}
                                            disabled={!isEditable}
                                        />
                                        <FormField
                                            md="4"
                                            type='DATE'
                                            label="Birth Date"
                                            id="Birth Date"
                                            name="birthDate"
                                            min={new Date('12/31/1899')}
                                            max={new Date()}
                                            valids="custom_date"
                                            validator={this.props.validator}
                                            value={birthDate}
                                            onChange={this.onChangeBirthDate}
                                            onBlur={this.props.dispatchValidState}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="4"
                                            type='REF_GENDER'
                                            className='text-bg'
                                            label="Gender"
                                            name="gender"
                                            value={patient.gender}
                                            onChange={this.onChangeGender}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group bordered">
                                <div className="row">
                                    <FormField
                                        md="12"
                                        type='label_field'
                                        label="Security Information"
                                        className='margin-bottom-15 fontWeight800'
                                        style={{ fontSize: '1.1em' }}
                                    />
                                    <FormField
                                        md="4"
                                        xs="6"
                                        type='select'
                                        label='Company'
                                        id='Company'
                                        name='company'
                                        valids="required"
                                        className="text-bg"
                                        validator={this.props.validator}
                                        options={this.props.companies}
                                        value={patient.company}
                                        onChange={this.onChangeCompany}
                                        onBlur={this.props.dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className="form-group bordered">
                                <div className="form-group bordered">
                                    <div className="row">
                                        <FormField
                                            md="12"
                                            type='label_field'
                                            label="Contact Information"
                                            className='margin-bottom-15 fontWeight800'
                                            style={{ fontSize: '1.1em' }}
                                        />
                                        <FormField
                                            md="4"
                                            icon="envelope-o"
                                            type="input-icon"
                                            label={this.context.translate('common.form.email')}
                                            id={this.context.translate('common.form.email')}
                                            className="form-control"
                                            name="email"
                                            value={this.props.patient.email}
                                            onChange={this.onChange}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                                <div className="form-group ">
                                    <div className="row">
                                        <FormField
                                            md="4"
                                            type="input-icon"
                                            icon="phone"
                                            label={this.context.translate('common.form.phone_number')}
                                            id={this.context.translate('common.form.phone_number')}
                                            className="form-control"
                                            name="phoneNumber"
                                            value={this.props.patient.phoneNumber}
                                            onChange={this.onChange}
                                            onBlur={this.props.dispatchValidState}
                                            disabled={!isEditable}
                                        />
                                        <FormField
                                            md="4"
                                            type="input-icon"
                                            icon="phone"
                                            label={this.context.translate('common.users.alternative_phone_number')}
                                            id={this.context.translate('common.users.alternative_phone_number')}
                                            className="form-control"
                                            name="alternativePhoneNumber"
                                            value={this.props.patient.alternativePhoneNumber}
                                            onChange={this.onChange}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                            </div>
                            <AddressInfoSection
                                onChange={this.onChange}
                                onChangeCountry={this.onChangeCountry}
                                onChangeState={this.onChangeState}

                                address1={this.props.patient.address1}
                                address2={this.props.patient.address2}
                                city={this.props.patient.city}
                                countryCode={this.props.patient.country}
                                stateCode={this.props.patient.state}
                                postalCode={this.props.patient.postalCode}
                                isEditable={isEditable}
                            />
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        type='label_field'
                                        label="Other"
                                        style={{ fontSize: '1.1em' }}
                                    />
                                    <FormField
                                        md="4"
                                        type="checkbox"
                                        title={this.context.translate('common.form.status')}
                                        label={this.context.translate('common.form.active')}
                                        name='active'
                                        value={patient.active}
                                        checked={patient.active}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        classBox="checkbox-padding"
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </form>
                    </BoxBody>
                    <BoxFooter>
                    </BoxFooter>
                </Box>
            </div>
        );
    }

    static contextTypes = {
        translate: PropTypes.func,
    };

    static defaultProps = {
        isEditable: true,
    }
}

const mapStateToProps = (state) => ({
    patient: state.app.patients.editedPatient,
    companies: state.app.financialReport.companiesMinimalData
        .map(item => ({
            value: item.code,
            label: item.fullName
        })),
});

const mapDispatchToProps = dispatch => ({
    userEditField: (field, value) => dispatch(entityEditField(field, value, 'patient')),
    getAllCompaniesMinimal: () => dispatch(getAllCompaniesMinimal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientGeneralInfo)
