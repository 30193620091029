import React from 'react';
import { connect } from 'react-redux';
// ----- HARDCODING! we are changing this component to the new one -> package.json -----
// import Select from 'react-select'
import Select from '../deprecated/react-select';
// -------------------------------------------------------------------------------------
import selectInput from '../../redux/action-creators/select-input';
import PropTypes from 'prop-types';

class Reference extends React.Component {
    handleChange = (selectedOption) => {
        this.props.updateValue(selectedOption)

        let column = this.props.refEndpoint.optionIdColumn

        const value = selectedOption ? selectedOption[ column ] : null

        this.props.onChange(value)
    }

    shouldComponentUpdate() {
        console.log(1);
        return true
    }

    openSelect() {
        this.props.loadSelect('/' + this.props.refEndpoint.url)
    }

    render() {
        let values = this.props.values
        if (this.props.refEndpoint.optionTextColumn.indexOf("translationKey") === 0) {
            values = values.map(v => {
                return {
                    ...v,
                    [ this.props.refEndpoint.optionTextColumn ]: this.context.translate(v[ this.props.refEndpoint.optionTextColumn ])
                }
            })
        }

        return (<Select
            value={this.props.selectedValues || this.props.value || null}
            options={{ values }}
            searchable={true}
            onChange={this.handleChange}
            multi={false}
            className={this.props.className}
            onOpen={() => {
                this.openSelect()
            }}
            isLoading={this.props.loading}
            labelKey={this.props.refEndpoint.optionTextColumn}
            valueKey={this.props.refEndpoint.optionIdColumn}
            title={this.props.title}
        />)

    }
}

Reference.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state) => {
    return {
        values: state.app.selectInput.options,
        loading: state.app.selectInput.loading,
        selectedValues: state.app.selectInput.selectedValues
    }
}

const mapActions = dispatch => {
    return {
        loadSelect: apiEndpoint => dispatch(selectInput.loadSelect(apiEndpoint)),
        updateValue: value => dispatch(selectInput.updateValue(value)),
    }
}

export default connect(mapState, mapActions)(Reference)
