import api from "../../utils/api";
import { Statuses } from "./statuses";
import { isEntityLoaded } from "../../utils/helpers";

const isAvailableInCache = (currencies) =>
    isEntityLoaded(currencies) && currencies.data && currencies.data.length > 0;

export const getAllCurrencies = (currencies) => (dispatch) => {
    if (isAvailableInCache(currencies)) {
        return;
    }
    dispatch({
        type: 'FETCH_CURRENCY_LOADING',
    })
    api.get('currency/all')
        .then(({ data }) => {
            dispatch({
                type: 'FETCH_CURRENCIES_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'FILL_CURRENCY_LOCAL_STORAGE'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_CURRENCY_ERROR',
                    payload: Statuses[ error.response.status ]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_CURRENCY_ERROR',
                    payload: error.message
                });
            }
        })

}
