/* eslint-disable object-shorthand */
/* eslint-disable comma-dangle */
import { createApiParams } from 'components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import { fetchEntityList$ } from "../../epicUtils/commonEpics";
import reviewerSlice from "./reviewerSlice";
import { transformReviewers } from "./utils";

const loadReviewersEpic = fetchEntityList$({
    ...reviewerSlice.actions,
    resolveUrl: () => 'reviewer/page',
    resolveParams: (params) => [{ params: createApiParams(params) }],
    dataTransformer: transformReviewers,
});

export default combineEpics(loadReviewersEpic);
