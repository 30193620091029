import PropTypes from 'prop-types';
import React, { Children, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { RECAPTCHA_KEY } from '../../../config';
import medrevLogo from '../../../images/medrev-logo-medium.png';
import { validAccessCode, validAuthenticatorCode, validEmail, validPassword } from './validators';

const API_CALL_DELAY = 200;
// debug
const DEBUG = 1;
export const log = (message, ...vars) => (DEBUG > 2 ? console.log(message, vars) : null);
export const warn = (message, ...vars) => (DEBUG > 1 ? console.warn(message, vars) : null);
export const err = (message, ...vars) => (DEBUG > 0 ? console.error(message, vars) : null);

export const AUTH_TYPE = {
  AUTHENTICATOR: 'AUTHENTICATOR',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  NONE: 'NONE'
};

export const delayedApiCall = (callback) => {
  setTimeout(callback, API_CALL_DELAY);
};

export const Button = ({ children, isLoading, cta, show }) => {
  // warn('CTA', cta);
  return (
    show && (
      <button
        type="submit"
        className="login-2fa__button"
        disabled={isLoading}
        onClick={(e) => {
          // e.preventDefault();
          // log('onClick', e.target, show);
          show && cta && cta.apply(e);
        }}
      >
        {isLoading && (
          <>
            <svg className="animate-spin mt-[2px] mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            &nbsp;
          </>
        )}
        {!isLoading && <>{children}</>}
      </button>
    )
  );
};
Button.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  cta: PropTypes.func,
};

export const GoToLogin = () => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link to="/login" className={'login-2fa__button'}>
      Go to Login page
    </Link>
  );
};

export const TabNav = ({ children, defaultActiveTab, onTabSelection }) => {
  // log('ch', children, defaultActiveTab, onTabSelection);
  const [activeTabKey, setActiveTabKey] = useState(defaultActiveTab);
  return (
    <>
      <div className={'border-b text-gray-200'}>
        <nav className="-mb-[1px] flex abi" aria-label="Tabs">
          {children.map((child, index) => {
            const { title, tabKey, show } = child.props;
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
            return (
              show && (
                <a
                  href={'#' + tabKey}
                  key={'tab-nav-' + index}
                  className={
                    activeTabKey === tabKey
                      ? 'border-indigo-500 border-medrev-green text-medrev-green whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                  }
                  onClick={(event) => {
                    // log('event', event);
                    event.preventDefault();
                    setActiveTabKey(tabKey);
                    if (onTabSelection) {
                      onTabSelection(tabKey);
                    }
                  }}
                >
                  {title}
                </a>
              )
            );
          })}
        </nav>
      </div>
      <div className={'tab-content'}>
        {Children.map(children, (child, index) => {
          const { tabKey } = child.props;
          // log('ccc', child, tabKey);
          return (
            <div className={activeTabKey === tabKey ? '' : 'hidden'} key={index}>
              {child}
            </div>
          );
        })}
      </div>
    </>
  );
};
TabNav.propTypes = {
  children: PropTypes.any,
  activeTab: PropTypes.string,
  onTabSelection: PropTypes.func,
  defaultActiveTab: PropTypes.string,
  login: PropTypes.func,
  currentUser: PropTypes.object,
  lastVisitedPath: PropTypes.string,
  pathToRedirectAfterLogin: PropTypes.string,
  automaticLogoutPreviousPath: PropTypes.string,
  error: PropTypes.string,
};

export const TabNavItem = ({ children, title, show }) => {
  return (
    <>
      {show && (
        <div className={''} title={title}>
          {children}
        </div>
      )}
    </>
  );
};
TabNavItem.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  show: PropTypes.bool,
};

export const TWAuthLayout = ({ children, title, comment }) => {
  return (
    <div className={'tw login-page'}>
      <div className="login-2fa">
        <div className="login-2fa__logo">
          <img className="mx-auto h-16 sm:h-20 md:h-28 lg:h-32 w-auto" src={medrevLogo} alt="MedRev logo" />
          <h2 className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-[#61a32e]">{title}</h2>
          {comment && <p>{comment}</p>}
        </div>
        <div className="login-2fa__form">{children}</div>
      </div>
    </div>
  );
};
TWAuthLayout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  comment: PropTypes.string,
};

export const TWInlineError = ({ errorMessage }) => {
  return <p className="mt-1 text-sm text-red-600 font-medium">{errorMessage}&nbsp;</p>;
};
TWInlineError.propTypes = {
  errorMessage: PropTypes.string,
};

// eslint-disable-next-line react/display-name
export const TWFormElementEmail = forwardRef(({ id, show, disabled, showEditButton, onEditClick, showBackToLogin, extError }, ref) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = useState('');
  const inpRef = useRef();

  useImperativeHandle(ref, () => ({
    isValid() {
      return validate(value);
    },
    getValue() {
      return value;
    },
  }));

  const validate = useCallback(
    (email) => {
      // log('validateEmail', email);
      let error = '';
      if (!email) {
        error = 'Please enter your email address.';
      } else if (!validEmail(email)) {
        error = 'Please enter a valid email address.';
      }
      setErrorMessage(error);
      if (error !== '') {
        warn('validateEmail', { error, email });
      }
      return error === '';
    },
    [setErrorMessage],
  );

  const onBlur = useCallback(
    ({ target: { value } }) => {
      // log('onBlur', name, ':', value);
      validate(value);
    },
    [validate],
  );

  const onChange = useCallback(({ target: { value } }) => {
    // log('onChange', name, ':', value);
    setValue(value);
  }, []);

  // warn('email', email);

  return (
    show && (
      <div className={errorMessage || extError ? 'has-error' : ''}>
        <div className="flex items-center justify-between">
          <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
            Email
          </label>
          {showBackToLogin && (
            <div className="text-sm">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link to="/login" className="font-semibold text-[#08427a] hover:text-[#145a9e] hover:underline">
                Back to login
              </Link>
            </div>
          )}
        </div>
        <div className="mt-2 relative flex grow items-stretch bgh">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="h-5 w-5 text-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
          </div>
          <input
            id={id}
            ref={inpRef}
            className="login-2fa__input"
            autoFocus={true}
            required
            name="email"
            type="email"
            value={value}
            autoComplete="email"
            placeholder={'Your email address'}
            maxLength={50}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
          {/*Edit button*/}
          {showEditButton && (
            <span className={'absolute top-[10px] right-[24px] font-bold'}>
              <a
                href="#edit"
                className={'text-gray-500'}
                onClick={(e) => {
                  e.preventDefault();
                  // log('Edit', e);
                  onEditClick && onEditClick(e);
                  setTimeout(() => {
                    inpRef && inpRef.current.focus();
                  }, 50);
                }}
              >
                Edit
              </a>
            </span>
          )}
        </div>
        <TWInlineError errorMessage={errorMessage || extError} />
      </div>
    )
  );
});
TWFormElementEmail.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  showEditButton: PropTypes.bool,
  onEditClick: PropTypes.func,
  showBackToLogin: PropTypes.bool,
  extError: PropTypes.string,
};

// eslint-disable-next-line react/display-name
export const TWFormElementPassword = forwardRef(
  ({ id, name, show, disabled, autoFocus, extError, title, placeholder, showForgotPassword, emptyErrorMessage, invalidErrorMessage }, ref) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [value, setValue] = useState('');
    const inpRef = useRef();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    useImperativeHandle(ref, () => ({
      isValid() {
        return validate(value);
      },
      getValue() {
        return value;
      },
    }));

    const validate = useCallback(
      (password) => {
        // log('validateUserPassword', password);
        let error = '';
        if (!password) {
          error = emptyErrorMessage || 'Please enter your password.';
        } else if (!validPassword(password)) {
          error = invalidErrorMessage || 'Please enter a valid password.';
        }
        setErrorMessage(error);
        if (error !== '') {
          warn('validateUserPassword', { error, password });
        }
        return error === '';
      },
      [emptyErrorMessage, invalidErrorMessage],
    );

    const onBlur = useCallback(
      ({ target: { value } }) => {
        // log('onBlur', name, ':', value);
        validate(value);
      },
      [validate],
    );

    const onChange = useCallback(({ target: { value } }) => {
      // log('onChange', name, ':', value);
      setValue(value);
      // validate(value);
    }, []);

    // warn('password', value);

    return (
      show && (
        <div className={errorMessage || extError ? 'has-error' : ''}>
          <div className="flex items-center justify-between">
            <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
              {title || 'Password'}
            </label>
            {showForgotPassword && (
              <div className="text-sm">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link to="/login/password-reset" className="font-semibold text-[#08427a] hover:text-[#145a9e] hover:underline">
                  Forgot password?
                </Link>
              </div>
            )}
          </div>
          <div className="mt-2 relative flex grow items-stretch bgh">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="h-5 w-5 text-gray-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
            </div>
            <input
              id={id}
              ref={inpRef}
              className="login-2fa__input"
              name={name || 'password'}
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              required
              autoFocus={autoFocus}
              placeholder={placeholder || 'Your password'}
              maxLength={30}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
            />
            <span className={'absolute top-[10px] right-[24px] font-bold text-gray-500 cursor-pointer'} onClick={togglePasswordVisibility}>
                {showPassword ? 'Hide' : 'View' }
            </span>
          </div>
          <TWInlineError errorMessage={errorMessage || extError} />
        </div>
      )
    );
  },
);
TWFormElementPassword.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  extError: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  showForgotPassword: PropTypes.bool,
  emptyErrorMessage: PropTypes.string,
  invalidErrorMessage: PropTypes.string,
};

// eslint-disable-next-line react/display-name
export const TWFormElementAccessCode = forwardRef(({ id, show, disabled, accessCodeExpirationCounter, extError }, ref) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = useState('');
  const inpRef = useRef();

  useImperativeHandle(ref, () => ({
    isValid() {
      return validate(value);
    },
    getValue() {
      return value;
    },
  }));

  const validate = useCallback(
    (accessCode) => {
      // const { password } = state;
      // log('validateUserAccessCode', accessCode);
      let error = '';
      if (!accessCode) {
        error = 'Please enter your access code.';
      } else if (!validAccessCode(accessCode)) {
        error = 'Please enter a valid access code.';
      }
      setErrorMessage(error);
      if (error !== '') {
        warn('validateUserAccessCode', { error, accessCode });
      }
      return error === '';
    },
    [setErrorMessage],
  );

  const onBlur = useCallback(
    ({ target: { value } }) => {
      // log('onBlur', name, ':', value);
      validate(value);
    },
    [validate],
  );

  const onChange = useCallback(({ target: { value } }) => {
    // log('onChange', name, ':', value);
    setValue(value);
    // validate(value);
  }, []);

  return (
    show && (
      <div className={errorMessage || extError ? 'has-error' : ''}>
        <div className="flex items-center justify-between">
          <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
            Access Code
          </label>
          <div className="text-sm">
            <CountDownTimer counter={accessCodeExpirationCounter}></CountDownTimer>
          </div>
        </div>
        <div className="mt-2 relative flex grow items-stretch bgh">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-5 h-5">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
              />
            </svg>
          </div>
          <input
            id={id}
            ref={inpRef}
            className="login-2fa__input"
            autoFocus={true}
            required
            name="accessCode"
            type="text"
            placeholder={'Your access code'}
            max={999999}
            maxLength={6}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        </div>
        <TWInlineError errorMessage={errorMessage || extError} />
      </div>
    )
  );
});
TWFormElementAccessCode.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  accessCodeExpirationCounter: PropTypes.number,
  extError: PropTypes.string,
};

// eslint-disable-next-line react/display-name
export const TWFormElementAuthenticatorCode = forwardRef(({ id, show, disabled, extError }, ref) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = useState('');
  const inpRef = useRef();

  useImperativeHandle(ref, () => ({
    isValid() {
      return validate(value);
    },
    getValue() {
      return value;
    },
  }));

  const validate = useCallback(
    (authenticatorCode) => {
      // const { password } = state;
      log('validateUserAuthenticatorCode', authenticatorCode);
      let error = '';
      if (!authenticatorCode) {
        error = 'Please enter your authenticator code.';
      } else if (!validAuthenticatorCode(authenticatorCode)) {
        error = 'Please enter a valid authenticator code.';
      }
      setErrorMessage(error);
      if (error !== '') {
        warn('authenticatorCode-error', { error, authenticatorCode });
      }
      return error === '';
    },
    [setErrorMessage],
  );

  const onBlur = useCallback(
    ({ target: { value } }) => {
      // log('onBlur', name, ':', value);
      validate(value);
    },
    [validate],
  );

  const onChange = useCallback(({ target: { value } }) => {
    // log('onChange', name, ':', value);
    setValue(value);
    // validate(value);
  }, []);

  return (
    show && (
      <div className={errorMessage || extError ? 'has-error' : ''}>
        <p className={'mb-4 text-sm'}>Use the Google Authenticator App to find out the current access code.</p>
        <div className="mt-2 relative flex grow items-stretch bgh">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-5 h-5">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
              />
            </svg>
          </div>
          <input
            id={id}
            ref={inpRef}
            className="login-2fa__input"
            required
            name="authenticatorCode"
            type="text"
            placeholder={'Google Authenticator Code'}
            max={999999}
            maxLength={6}
            autoComplete="off"
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        </div>
        <TWInlineError errorMessage={errorMessage || extError} />
      </div>
    )
  );
});
TWFormElementAuthenticatorCode.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  extError: PropTypes.string,
};

export const CountDownTimer = ({ counter }) => {
  const countToTime = (count) => {
    if (isNaN(count) || count <= 0) return 'Expired';
    const min = Math.floor(count / 60);
    const sec = count % 60;
    return `${min > 9 ? min : '0' + min} : ${sec > 9 ? sec : '0' + sec}`;
  };

  return <span className={'text-red-600 w-full text-right'}>{countToTime(counter)}</span>;
};
CountDownTimer.propTypes = {
  counter: PropTypes.number,
};

// eslint-disable-next-line react/display-name
export const ReCaptcha = forwardRef(({ enabled }, ref) => {
  const [loaded, setLoaded] = useState(false);
  const recaptchaRef = useRef();

  useImperativeHandle(ref, () => ({
    isReady() {
      return loaded;
    },
    callback(func) {
      recaptchaRef.current.reset();
      recaptchaRef.current.executeAsync().then((captchaValue) => {
        log('executeAsync promise - Captcha value:', captchaValue);
        func(captchaValue);
      });
    },
  }));
  const asyncScriptOnLoad = () => {
    setLoaded(true);
    log('scriptLoad - reCaptcha Ref-', recaptchaRef);
  };

  return (
    enabled && (
      <ReCAPTCHA
        ref={recaptchaRef}
        className={''}
        style={{ display: 'inline-block' }}
        sitekey={RECAPTCHA_KEY}
        asyncScriptOnLoad={asyncScriptOnLoad}
        size={'invisible'}
        badge={'bottomleft'}
        theme={'light'}
        onExpired={(e) => {
          log('ReCaptcha expired', e);
        }}
        onErrored={(e) => {
          log('ReCaptcha error', e);
        }}
      />
    )
  );
});
ReCaptcha.propTypes = {
  enabled: PropTypes.bool,
};

export const PasswordHint = ({ show }) => {
  return (
    show && (
      <p className={'mb-4 text-sm'}>
        Hint: Password must contain at least a capital letter, a digit and a special character and be at least 8 characters long...
      </p>
    )
  );
};
PasswordHint.propTypes = {
  show: PropTypes.bool,
};

export const AccessCodeHint = ({ show, authType}) => {
  const authentication = { type: authType === AUTH_TYPE.SMS ? 'SMS' : 'Email' };
  return (
    show && (
      <p className={'mb-4 text-sm'}>
        {'Hint: You can request an access code via ' + authentication.type + ', which you can use to change your password.'}
      </p>
    )
  );
};
AccessCodeHint.propTypes = {
  show: PropTypes.bool,
  authType: PropTypes.any
};
