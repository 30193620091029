import React from 'react'

import CreateEntity from './CreateEntity'
import CaseManageButtons from './CaseManageButtons'
import SaveEntity from './SaveEntity'
import GoBackButton from './GoBackButton'

const EntityManageButtons = () => (
    <span className="action-buttons ">
        <GoBackButton/>
        <CreateEntity/>
        <CaseManageButtons/>
        <SaveEntity/>
    </span>
);

export default EntityManageButtons
