import Box from 'components/Box';
import BoxBody from 'components/BoxBody';
import BoxHeader from 'components/BoxHeader';
import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const textColorMap = {
  warning: 'orange',
  primary: 'blue',
  danger: 'red',
  success: 'green',
};

const CollapseBox = ({
  title,
  titleIcon,
  collapsedDefault,
  additionalHeaderItems,
  variant,
  children,
  style,
  className,
  bodyStyles,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsedDefault);

  return (
    <Box className={`box-${variant} ${className}`} style={style}>
      <BoxHeader>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a id="_attachments_">
          <h3 className={`box-title text-${textColorMap[variant]}`}>
            {titleIcon && <i className={`fa fa-${titleIcon} margin-right-5`} />}
            {title}
          </h3>
        </a>
        <div className="box-tools pull-right toggle-box">
          <button
            type="button"
            className="btn btn-box-tool toggle-btn"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {additionalHeaderItems}
            <i
              className={
                'fa toggle-fa-icon-reviewer-info ' +
                (isCollapsed ? 'fa-plus' : 'fa-minus')
              }
            />
          </button>
        </div>
      </BoxHeader>
      <BoxBody classes={classnames({ hidden: isCollapsed })} style={bodyStyles}>
        {children}
      </BoxBody>
    </Box>
  );
};

CollapseBox.propTypes = {
  collapsedDefault: PropTypes.bool,
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  children: PropTypes.node,
  additionalHeaderItems: PropTypes.node,
  bodyStyles: PropTypes.object,
  variant: PropTypes.oneOf([
    'primary',
    'success',
    'danger',
    'default',
    'warning',
  ]),
};

CollapseBox.defaultValue = {
  collapsedDefault: true,
  children: null,
  additionalHeaderItems: null,
  title: '',
  titleIcon: '',
  variant: 'primary',
  bodyStyles: {},
};

export default CollapseBox;
