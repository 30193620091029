import React, {Component} from "react";
import {connect} from 'react-redux'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../components/LoadingIndicator";
import {editUser, getAllUsers} from '../../redux/action-creators/users'
import {isUser} from "../../utils/helpers";
import {withRouter} from "react-router-dom";

class ClientSubmitters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            size: 50,
            page: 1
        };
        this.applyParams = this.applyParams.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onActionClick = this.onActionClick.bind(this);
        this.roleFormatter = this.roleFormatter.bind(this);
    }

    componentDidMount() {
        this.props.getAllUsers();
    }

    fetchData(data) {
        this.setState(data);
    }

    applyParams(data) {
        data.splice(this.state.page * this.state.size)
            .splice(0, this.state.size);

        return data;
    }

    onRowClick(item) {
        this.props.history.push(`/submitters/profile/${item}`)
    }

    onActionClick(field) {
        console.log(field);
        this.setState({field});
        this.props.handleOpenDeleteModal()
    }


    roleFormatter(cell, row) {
        let roleName = '';
        if (row) {
            this.props.roles.forEach((item) => {
                if (item.code === cell) {
                    roleName = item.name;
                }
            });
        }
        return roleName;
    }

    enumFormatter = (cell, row, enumObject) => {
        return enumObject[cell];
    };

    render() {
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'title': 'Code',
                'sort': false,
                'hidden': true
            },
            {
                'name': 'firstName',
                'title': 'First Name',
                'width': '200',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
            },
            //  {
            //     'name': 'middleName',
            //     'title': 'Middle Name',
            //     'width': '200',
            //     'columnTitle': true,
            //     'sort': true,
            //     'filter': {type: 'TextFilter', delay: 500},
            // },
            {
                'name': 'lastName',
                'title': 'Last Name',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '100'
            },
            {
                'name': 'email',
                'title': 'Email',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '200'
            },
            {
                'name': 'roleCode',
                'title': 'Role',
                'dataFormat': this.roleFormatter,
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '200'
            },
            {
                'name': 'phoneNumber',
                'title': 'Phone Number',
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500},
                'width': '200'
            },
            {
                'name': 'active',
                'title': 'Status',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': 'Inactive',
                    'true': 'Active'
                },
                'width': '200',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': 'Inactive',
                        'true': 'Active'
                    }
                }
            }
        ];
        const {pagination, sorting} = this.props.gridConfiguration;

        return (
            <div className="col-md-12">
                <RemoteGrid
                    color="green"
                    entity="client_submitters"
                    title={`Client - ${this.props.profile.fullName}`}
                    data={this.props.data}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    total={this.props.totalElements}
                    columns={columns}
                    pagination={true}
                    search={true}
                    onRowClick={this.onRowClick}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
            </div>
        )


    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.app.users.users.filter((user) => user.companyCode === ownProps.match.params.id),
        roles: state.app.users.roles.filter((item) => isUser(item.code)),
        totalElements: state.app.users.users.filter((user) => user.companyCode === ownProps.match.params.id).length,
        profile: state.app.companies.editedCompany,
        gridConfiguration: state.app.grid.clientSubmitters,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveProfile: data => dispatch(editUser(data)),
        getAllUsers: () => {
            dispatch(getAllUsers())
        },
    }
};

ClientSubmitters = withRouter(ClientSubmitters);
export default connect(mapStateToProps, mapDispatchToProps)(ClientSubmitters);
