import React from 'react';
import { inProcess } from 'redux/pages/patient-case/patientCaseColumns';
import inProcessSlice from 'redux/pages/patient-case/slices/inprocessSlice';
import PatientCaseGrid from '../Components/PatientCaseGrid';

const columns = inProcess;

const InProcess = (props) => (
  <PatientCaseGrid columns={columns} slice={inProcessSlice} {...props} />
);

export default InProcess;
