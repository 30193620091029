import React from 'react';
import {connect} from 'react-redux';
import Modal from 'react-modal';
import "../components/ModalWin.css"
import {openCreateModal, closeCreateModal} from '../redux/action-creators/users'
import StaffCreateForm from './Staff/StaffCreateForm'
import ReviewerCreateForm from './Admin/ReviewerCreateForm'
import ClientCreateForm from './Company/ClientCreateForm'
import UserCreateForm from './ClientUsers/UserCreateForm'
import CaseTypeCreateForm from './Entities/CaseTypes/CaseTypeCreateForm'
import PatientCreateForm from './Case/PatientCreateForm'
import TemplateCreateForm from './Template/TemplateCreateForm'
import NotificationTemplateCreateForm from './NotificationTemplate/NotificationTemplateCreateForm'

import PropTypes from "prop-types";

class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.getCreationForm = this.getCreationForm.bind(this);
        this.getButtonColor = this.getButtonColor.bind(this);
    }

    getCreationForm() {
        switch (this.props['entityType']) {
            case 'staff':
                return <StaffCreateForm/>;
            case 'reviewer':
                return <ReviewerCreateForm/>;
            case 'client':
                return <ClientCreateForm/>;
            case 'user':
                return <UserCreateForm defaultCompany={this.props.company} showCompanies={this.props['showCompanies']}/>;
            case 'case type':
                return <CaseTypeCreateForm/>;
            case 'patient':
                return <PatientCreateForm/>;
            case 'template':
                return <TemplateCreateForm/>;
            case 'notification-template':
                return <NotificationTemplateCreateForm/>;
            default:
        }
    }

    getButtonColor() {
        switch (this.props.color) {
            case 'green':
                return 'btn-success';
            case 'yellow':
                return 'btn-warning';
            default:
                return 'btn-primary';
        }
    }

    render() {
        return (
            <div>
                <button className={`btn ${this.getButtonColor()}`} onClick={this.props.modalOpen}><i
                    className={`fa ${this.props.icon}`}/> {this.context.translate('common.users.add_' + this.props['entityType'])}
                </button>
                <Modal
                    isOpen={this.props.isOpen}
                    contentLabel="onRequestClose Example"
                    className="custom-modal modal-dialog"
                    overlayClassName="modal-overlay"
                >
                    <h3 className="modal-title text-center"> {this.context.translate('common.users.add_' + this.props['entityType'])}
                        <i className='fa fa-times close' onClick={this.props.modalClose}/>
                    </h3>
                    {this.getCreationForm()}
                </Modal>
            </div>
        );
    }
}

CreateModal.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    isOpen: state.app.users.createModalOpen
});

const mapDispatchToProps = dispatch => ({
    modalOpen: () => dispatch(openCreateModal()),
    modalClose: () => dispatch(closeCreateModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal)
