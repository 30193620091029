import React from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {Panel} from 'react-bootstrap';
import FormField from '../../../components/FormField'
import {editFieldInstance} from '../../../redux/actions/templateActions'

class EditScriptPanel extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.props.editField([this.props.name], e)
    }

    render() {
        const {md, opened, label, script, readOnly} = this.props;
        return (
            <div className={`col-md-${md}`}>
                <Panel id="collapsible-panel-example-1" expanded={opened} style={{border: 'none', marginTop: '15px'}}>
                    <Panel.Collapse>
                        <Panel.Body style={{padding: 0}}>
                            <FormField
                                label={label}
                                type="CODE"
                                value={script}
                                onChange={this.onChange}
                                readOnly={readOnly}
                            />
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </div>
        );
    }
}

EditScriptPanel.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    script: state.app.templates.editedField[ownProps.name] || ''
});

const mapDispatchToProps = dispatch => ({
    editField: (field, value) => {
        dispatch(editFieldInstance(field, value))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditScriptPanel)
