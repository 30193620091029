import { createApiParams } from 'components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import { fetchEntityList$ } from '../../epicUtils/commonEpics';
import clientEditFacilitySlice from './clientEditFacilitySlice';
import { transformClientEditFacilities } from './utils';

const clientEditFacilityEpic = fetchEntityList$({
    ...clientEditFacilitySlice.actions,
    resolveUrl: ({ code }) => `facility_minimal/page?code=${code}`,
    resolveParams: (params) => [{ params: createApiParams(params) }],
    dataTransformer: transformClientEditFacilities,
});

export default combineEpics(clientEditFacilityEpic);
