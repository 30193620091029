import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

const SubmitButton = (props) => {
    const {color, disabled, isLoading, onSubmit, title} = props;

    const buttonTitle = title ? title : 'Save';
    const classes = {
        blue: 'primary',
        green: 'success',
        grey: 'default',
        red: 'danger',
        yellow: 'warning',
    };
    const bsStyle = classes[color];

    return <Button
                block={false}
                bsStyle={bsStyle}
                disabled={isLoading || disabled}
                onClick={onSubmit}
                style={{fontWeight: 'normal', margin: '0 0 0 5px', minWidth: 'auto'}}
            >
                {buttonTitle}
            </Button>
};

SubmitButton.defaultProps = {
    color: 'blue',
    disabled: false,
    isLoading: false,
    onSubmit: () => {},
    title: 'Save',
};

SubmitButton.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
};

export default SubmitButton;
