import { startCase } from 'lodash';
import React from 'react';
import { Row } from 'react-bootstrap';
import { FINANCE_FIELDS } from 'redux/pages/case-finances/financeSlice';
import AmountField from './AmountField';

const ReviewerHourlyFields = () => {
  return (
    <div className="financeSection__reviewerContainer">
      <Row className="mg-top-10">
        <AmountField
          name={FINANCE_FIELDS.maxBillableAmountInReviewerCurrency}
          overrideLabel={startCase(FINANCE_FIELDS.maxBillableAmount)}
        />
      </Row>
      <Row className="mg-top-10">
        <AmountField
          name={FINANCE_FIELDS.reviewerAmountInReviewerCurrency}
          overrideLabel={startCase(FINANCE_FIELDS.reviewerAmount)}
        />
      </Row>
    </div>
  );
};

export default ReviewerHourlyFields;
