import React from 'react';
import {connect} from 'react-redux';
import FormField from '../../../components/FormField/index'
import {createNewSpecialty} from '../../../redux/actions/specialtyActions'
import PropTypes from "prop-types";
import FormValidateWrapper from '../../../containers/FormValidateWrapper';

class SpecialtyCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primarySpeciality: false,
            active: true,
            name: ''
        };

        this.Submit = this.Submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);

    }

    componentDidMount() {
        this.props.dispatchValidState()
    }

    onChangeCheckBox(e) {
        // const newValue = e.target.value === 'true' ? false : true
        this.setState({[e.target.name]: e.target.checked});
    }

    Submit(e) {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            const data = {
                ...this.state,
                code: this.state.code,
                name: this.state.name,
                bodyPartCodes: [],
                reviewersCount: 0,
                names: {
                    'EN': this.state.name
                }
            }
            console.log(data);
            this.props.signUp(data);
        } else {
            this.props.dispatchValidState()
            this.props.showMessages()

        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onChangeCode(e) {
      console.log(e.target.name)
      console.log(e.target.value)
      this.setState({[e.target.name]: e.target.value});
    }

    render() {
        // let showError = this.props.error ? 'block' : 'none';

        return (
            <form noValidate autoComplete="off"
                  className='modal-content custom-modal-content'
                  onSubmit={this.Submit}>

                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="12"
                            title={this.context.translate('common.form.fieldKey')}
                            label={this.context.translate('common.form.fieldKey')}
                            id={this.context.translate('common.form.fieldKey')}
                            name='code'
                            required
                            valids="required|unique"
                            validator={this.props.validator}
                            value={this.state.code}
                            onChange={this.onChangeCode}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>    

                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="12"
                            title={this.context.translate('common.form.name')}
                            label={this.context.translate('common.form.name')}
                            id={this.context.translate('common.form.name')}
                            name='name'
                            required
                            valids="required|unique"
                            validator={this.props.validator}
                            value={this.state.name}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="12"
                            type="checkbox"
                            title=""
                            label={this.context.translate('common.form.primary_specialty')}
                            name='primarySpeciality'
                            value={this.state.primarySpeciality}
                            checked={this.state.primarySpeciality}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="12"
                            type="checkbox"
                            title={this.context.translate('common.form.status')}
                            label={this.context.translate('common.form.active')}
                            name='active'
                            value={this.state.active}
                            checked={this.state.active}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right">
                            <button
                                className='btn btn-block btn-flat btn-success'
                                disabled={this.props.isLoading}
                            >
                                {this.context.translate('common.form.add_specialty')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

SpecialtyCreateForm.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => {
    const specialties_names = state.app.specialties.data
        .map((item) => {
            return item.name;
        }).join(',')

    return {
        isLoading: state.app.specialties.isLoading,
        error: state.app.specialties.error,


        //VALIDATION PROPS
        top: 0,
        customRules: {
            unique: {
                message: 'This value already exists in the system, the new record won\'t be created.',
                rule: function (val) {
                    return !specialties_names.split(',').includes(val);
                }
            }
        }
    }
}

const mapDispatchToProps = dispatch => ({
    signUp: (procedure) => {
        dispatch(createNewSpecialty(procedure))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(SpecialtyCreateForm))
