import React from 'react';
import {connect} from 'react-redux';
import SubmitButton from '../../components/SubmitButton'
import PropTypes from "prop-types";
import {editUser, showValidation} from '../../redux/action-creators/users'
import {getMultiSelectValue, getSelectFieldValue} from '../../utils/helpers'


class SaveStaffProfile extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        if (this.props.isProfileValid) {
            e.preventDefault();
            this.props.saveProfile({
                ...this.props.profile,
                active: this.props.profile.active,
                code: this.props.profile.code,
                email: this.props.profile.email.trim(),
                notificationEmail: this.props.profile.notificationEmail.trim(),
                roleCode: getSelectFieldValue(this.props.profile.roleCode),
                countryCode: getSelectFieldValue(this.props.profile.countryCode),
                stateCode: getSelectFieldValue(this.props.profile.stateCode),
                staffIpWhitelist: getMultiSelectValue(this.props.profile.staffIpWhitelist),
                defaultLanguageCode: getSelectFieldValue(this.props.profile.defaultLanguageCode),
                defaultLocaleCode: getSelectFieldValue(this.props.profile.defaultLocaleCode),
                timeZoneCode: getSelectFieldValue(this.props.profile.timeZoneCode),
            }, closeAfterSave)
        } else {
            this.props.showValidation()
        }
    }

    render() {
        return (
            <React.Fragment>
                <SubmitButton color='blue' disabled={!this.props.isProfileChanged} onSubmit={this.onSave} showTooltip={!this.props.isProfileValid}></SubmitButton>
                <SubmitButton color='blue'
                              disabled={!this.props.isProfileChanged}
                              onSubmit={(e) => this.onSave(e, true)}
                              title='Save And Close'
                              showTooltip={!this.props.isProfileValid}></SubmitButton>
            </React.Fragment>
        )
    }
}

SaveStaffProfile.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => ({
    profile: state.app.users.editedUser,
    isProfileValid: state.app.users.userValid,
    isProfileChanged: state.app.users.isProfileChanged
})

const mapDispatchToProps = dispatch => ({
    saveProfile: (data, closeAfterSave) => dispatch(editUser(data, closeAfterSave)),
    showValidation: () => dispatch(showValidation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SaveStaffProfile)
