import React from 'react';
import { Button } from 'react-bootstrap';
import { clientGetReport } from '../CaseColumns';

export const reportFormatter = (_, row) => {
  const { code, stage } = row;
  const visibility =
    stage && stage === 'Closed' ? 'visible' : 'hidden';
  const onClick = () => clientGetReport(code);

  return (
    <Button
      bsSize="xsmall"
      title="Download Report"
      onClick={onClick}
      style={{ visibility }}
    >
      <span
        className="fa fa-file"
        style={{ fontSize: '1.3em', pointerEvents: 'none' }}
      />
    </Button>
  );
};
