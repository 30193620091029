import Box from 'components/Box';
import BoxBody from 'components/BoxBody';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BoxFooter from 'components/BoxFooter';
import LoadingIndicator from 'components/LoadingIndicator';

const ColoredBox = ({
  children,
  variant,
  title,
  border,
  headerContent,
  footerContent,
  className,
  bodyClasses,
  icon,
  loader,
}) => {
  return (
    <Box
      color={`colored-box box-${variant}`}
      classes={classnames([{ bordered: border }, className])}
      style={{ padding: 0 }}
    >
      <div className="box-header with-border">
        {React.isValidElement(title) ? (
          title
        ) : (
          <h3 className={`box-title text-${variant}`}>
            {icon && (
              <i className={`fa fa-${icon} text-${variant}  margin-right-10`} />
            )}
            {title}
            {headerContent}
            {loader && <LoadingIndicator type="form" />}
          </h3>
        )}
      </div>
      <BoxBody classes={bodyClasses}>{children}</BoxBody>
      <BoxFooter>{footerContent}</BoxFooter>
    </Box>
  );
};

ColoredBox.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'info',
    'danger',
    'warning',
    'success',
    'default',
  ]),
  border: PropTypes.bool,
  headerContent: PropTypes.node,
  footerContent: PropTypes.node,
  className: PropTypes.string,
  bodyClasses: PropTypes.string,
  icon: PropTypes.string, // fa icon list
  loader: PropTypes.bool, // fa icon list
};

ColoredBox.defaultProps = {
  border: true,
  variant: 'default',
  title: null,
  headerContent: null,
  footerContent: null,
  className: '',
  bodyClasses: '',
  icon: null,
  loader: false,
};

export default ColoredBox;
