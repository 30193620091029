import React from 'react';
import {connect} from 'react-redux';
import SubmitButton from '../../components/SubmitButton'
import PropTypes from "prop-types";
import {editUser, showValidation} from '../../redux/action-creators/users'
import {saveSignature} from "../../redux/actions/profileActions";
import {getSelectFieldValue, getMultiSelectValue} from '../../utils/helpers'

class SaveReviewerProfile extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        e.preventDefault();
        if (this.props.isProfileValid) {
            const data = {
                ...this.props.profile,
                email: this.props.profile.email.trim(),
                notificationEmail: this.props.profile.notificationEmail.trim(),
                countryCode: getSelectFieldValue(this.props.profile.countryCode),
                stateCode: getSelectFieldValue(this.props.profile.stateCode),
                defaultLocaleCode: getSelectFieldValue(this.props.profile.defaultLocaleCode),
                specialtyCodes: getMultiSelectValue(this.props.profile.specialtyCodes),
                companyExcludedCodes: getMultiSelectValue(this.props.profile.companyExcludedCodes),
                companyIncludedCodes: getMultiSelectValue(this.props.profile.companyIncludedCodes),
                caseTypeCodesExcluded: getMultiSelectValue(this.props.profile.caseTypeCodesExcluded),
                languageCodes: getMultiSelectValue(this.props.profile.languageCodes),
                reviewerTypeCodes: getMultiSelectValue(this.props.profile.reviewerTypeCodes),
                reviewerCurrencyCode: getSelectFieldValue(this.props.profile.reviewerCurrencyCode),
                defaultLanguageCode: getSelectFieldValue(this.props.profile.defaultLanguageCode),
                timeZoneCode: getSelectFieldValue(this.props.profile.timeZoneCode),
                trainDate: this.props.profile.trainDate ? this.props.profile.trainDate.getTime() : null
            };

            if (this.props.profile.savedSignature && this.props.profile.savedSignature.length) {
                this.props.saveSignature({
                    code: this.props.profile.code,
                    file: this.props.profile.savedSignature[0]
                });
            }

            this.props.saveProfile(data, closeAfterSave)
        } else {
            this.props.showValidation()
        }
    }

    render() {
        return (
            <React.Fragment>
                <SubmitButton disabled={!this.props.isProfileChanged} color='blue' onSubmit={this.onSave}
                              showTooltip={!this.props.isProfileValid}/>
                <SubmitButton
                    color='blue'
                    disabled={!this.props.isProfileChanged}
                    title='Save And Close'
                    showTooltip={!this.props.isProfileValid}
                    onSubmit={(e) => this.onSave(e, true)}
                />
            </React.Fragment>
        )
    }

}

SaveReviewerProfile.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    profile: state.app.users.editedUser,
    isProfileValid: state.app.users.userValid,
    isProfileChanged: state.app.users.isProfileChanged
});

const mapDispatchToProps = dispatch => ({
    saveProfile: (data, closeAfterSave) => dispatch(editUser(data, closeAfterSave, 'auth/user/update')),
    saveSignature: data => dispatch(saveSignature(data)),
    showValidation: () => dispatch(showValidation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveReviewerProfile)
