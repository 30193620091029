import React from 'react';
import {connect} from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'
import PropTypes from "prop-types";
import {editCompany, saveLogo, emitValidationError} from '../../../redux/actions/companiesActions'

class SaveEntity extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        if (this.props.isCompanyValid) {
            this.props.saveCompanyProfile(this.props.editedCompany, closeAfterSave);

            const {savedLogo} = this.props.editedCompany;

            if (savedLogo && savedLogo.length) {
                this.props.saveLogo({
                    code: this.props.editedCompany.code,
                    file: this.props.editedCompany.savedLogo
                })
            }
        } else {
            this.props.emitValidationError()
        }
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={this.onSave}
                />
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={(e) => this.onSave(e, true)}
                    title={'Save And Close'}
                />
            </React.Fragment>
        )
    }
}

SaveEntity.contextTypes = {
    translate: PropTypes.func
};

const allowToEdit = (role) => (role === "ROLE_CLIENT_ADMIN" || role === 'ROLE_ADMIN');

const mapStateToProps = (state) => ({
    isProfileChanged: state.app.companies.isProfileChanged,
    isCompanyValid: state.app.companies.allValid,
    editedCompany: state.app.companies.editedCompany,
    isEditable: allowToEdit(state.app.auth.user.roleCode) || state.app.users.my_permissions.includes('MANAGE_CLIENT'),
});

const mapDispatchToProps = dispatch => ({
    saveCompanyProfile: (data, closeAfterSave) => dispatch(editCompany(data, closeAfterSave)),
    saveLogo: data => dispatch(saveLogo(data)),
    emitValidationError: () => dispatch(emitValidationError())
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveEntity)
