import {
    convertStyledText,
    fromBigToSmallRoleMatrix,
    fromSmallToBigRoleMatrix,
    getDateTimeFormat4Field,
    getMultiSelectValue,
    getSelectFieldValue,
    isJson,
    makeSelectOptions
} from '../utils/helpers'
import {reducedRoleStageMatrix, roleStageMatrix, serviceSections} from '../utils/Constants'

export const isFieldTypeSelect = (field) => {
    return /REF/g.test(field ? getSelectFieldValue(field) : null)
};

export const convertToJSON = (obj) => {
    let copy;

    if (obj && typeof obj === 'object') {
        copy = {...obj};
        copy = JSON.stringify(copy);
        return copy
    } else {
        return obj
    }
};

export const convertKeysToJSON = (obj) => {
    for (let key in obj) {
        if (!obj.hasOwnProperty(key)) continue;
        if (obj[key] && typeof obj[key] === 'object') obj[key] = JSON.stringify(obj[key])
    }
    return obj
};

export const isDefaultValueValid = (val, field) => {
    let {listOptions, includeOther, allowMultiSelect} = field;
    const validateCondition = !!listOptions && !!listOptions.length && !includeOther && !!val;
    if (validateCondition) {
        listOptions = getMultiSelectValue(listOptions);
        // let valids = listOptions.includes(val);
        // if (getSelectFieldValue(fieldType) === 'DATE' || getSelectFieldValue(fieldType) === 'DATE_TIME') {
        //   val = new Date(val).setMinutes(0,0,0,0)
        //
        //   valids = !!listOptions.find((item) => {
        //     const formatted = item
        //                       .replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")
        //                       .replace( /(\d{2})\-(\d{2})\-(\d{4})/, "$2/$1/$3")
        //     return new Date(formatted).setHours(0,0,0,0) == val
        //   })
        // }
        //
        // if (isFieldTypeSelect(fieldType) && this.props.field.listOptions && this.props.field.listOptions.length) {
        //   if (allowMultiSelect) {
        //     const valCodes = getMultiSelectValue(val);
        //     valids = valCodes.every((code) => listOptions.includes(code))
        //   } else {
        //     const code = getSelectFieldValue(val)
        //     valids = listOptions.includes(code)
        //   }
        // }
        let isValid;
        if (allowMultiSelect) {
            const valCodes = getMultiSelectValue(val);
            isValid = valCodes.every((code) => listOptions.includes(code))
        } else {
            const code = getSelectFieldValue(val);
            isValid = listOptions.includes(code)
        }

        return isValid;
    } else {
        return true
    }
};

export const convertDefaultValue = (data) => {
    const {fieldType, defaultValue, allowMultiSelect, listOptions} = data;
    let result = defaultValue;

    if (fieldType && getSelectFieldValue(fieldType) + '' === 'LABEL' && typeof defaultValue === 'object') {
        result = JSON.stringify(defaultValue);
    }

    if (fieldType && fieldType.value === 'BOOLEAN') result = !!defaultValue;

    if (isFieldTypeSelect(fieldType)
        || (listOptions && listOptions.length)) {
        result = allowMultiSelect ? JSON.stringify(getMultiSelectValue(defaultValue)) : getSelectFieldValue(defaultValue);
        //console.log(result);
    }
    return result;
};

export const serviceSectionFilter = (section) => {
    const name = section.code.split('_')[1];
    return !serviceSections.includes(name)
};

const convertFieldData = (field) => {
    return {
        ...field,
        name: field.skippDisplayName ? null : convertToJSON(field.name),
        names: field.skippDisplayName ? null : {...field.names, 'EN': convertToJSON(field.name)},
        helpTextStaff: convertKeysToJSON(field.helpTextStaff),
        helpTextReviewer: convertKeysToJSON(field.helpTextReviewer),
        helpTextClient: convertKeysToJSON(field.helpTextClient),
        fieldType: getSelectFieldValue(field.fieldType),
        customClasses: getMultiSelectValue(field.customClasses),
        defaultValue: convertDefaultValue(field),
        listOptions: JSON.stringify(field.listOptions),
        // listOptions: getMultiSelectValue(field.listOptions).toString(),
        requiredMatrix: fromSmallToBigRoleMatrix(field.requiredMatrix, roleStageMatrix),
        visibilityMatrix: fromSmallToBigRoleMatrix(field.visibilityMatrix, roleStageMatrix),
        readOnlyMatrix: fromSmallToBigRoleMatrix(field.readOnlyMatrix, roleStageMatrix),
        sectionCode: getSelectFieldValue(field.sectionCode),
        editableAfterClose: getSelectFieldValue(field.editableAfterClose),
        clientEditableAfterClose: getSelectFieldValue(field.clientEditableAfterClose),
        // caseLevel:getSelectFieldValue(props.field.caseLevel),
    }
};

const convertListOptions = (listOptions) => {
    if (!listOptions) return null;

    if (isJson(listOptions)) {
        return JSON.parse(listOptions)
    } else {
        return typeof listOptions === 'string' ? makeSelectOptions(listOptions.split(',')) : null
    }
};

export const convertReceivedField = (data) => {
    let defaultValue;

    if (!data || !data.fieldType) return data;

    data = {
        ...data,
        requiredMatrix: fromBigToSmallRoleMatrix(data.requiredMatrix, reducedRoleStageMatrix),
        visibilityMatrix: fromBigToSmallRoleMatrix(data.visibilityMatrix, reducedRoleStageMatrix),
        readOnlyMatrix: fromBigToSmallRoleMatrix(data.readOnlyMatrix, reducedRoleStageMatrix),
        listOptions: convertListOptions(data.listOptions),
        skippDisplayName: !data.name
    };

    if (data.fieldType === 'BOOLEAN') return {
        ...data,
        defaultValue: data.defaultValue === 'false' ? false : !!data.defaultValue
    };

    if (isFieldTypeSelect(data.fieldType) || (data.listOptions && data.listOptions.length)) {
        if (data.allowMultiSelect) {
            try {
                defaultValue = JSON.parse(data['defaultValue'])
            } catch (e) {
                console.group('default value converter error');
                console.log('value:', data['defaultValue']);
                console.log(e);
                console.groupEnd();
            }
        } else {
            defaultValue = getSelectFieldValue(data.defaultValue)
        }
        return {...data, defaultValue}
    }

    return data

};

export const renderDefaultValue = (item) => {
    if (!item.defaultValue) return '';
    let defaultValue;

    switch (item.fieldType) {
        case 'LABEL':
            defaultValue = convertStyledText(item.defaultValue);
            break;
        case 'DATE':
        case 'DATE_TIME':
            defaultValue = getDateTimeFormat4Field(item.fieldType, item.defaultValue);
            break;
        //     defaultValue = new Date(getTimestampAccordingOffset(item.defaultValue)).toLocaleDateString("en-GB");
        //     break;
        // case 'DATE_TIME':
        //     defaultValue = new Date(getTimestampAccordingOffset(item.defaultValue)).toLocaleString();
        //     break;
        case 'TIME':
            defaultValue = getDateTimeFormat4Field('TIME_READONLY', item.defaultValue);
            //defaultValue = new Date((item.defaultValue)).toLocaleTimeString();
            break;
        default:
            defaultValue = item['defaultValueName']
    }

    // TODO: WTF???
    if ((isFieldTypeSelect(item.fieldType) && (item['defaultValueName'] || item['defaultValue']) )
        || (item.listOptions && item.listOptions.length && item.allowMultiSelect)) {
        // console.log('...', isFieldTypeSelect(item.fieldType), item.listOptions, item['defaultValueName'], item['defaultValue']);
        try {
            if (item) {
                if (item[ 'defaultValueName' ]
                    && (item[ 'defaultValueName' ].substring(0, 1) === '[' || item[ 'defaultValueName' ].substring(0, 1) === '{')
                ) {
                    defaultValue = JSON.parse(item[ 'defaultValueName' ]).join(', ');
                } else if (item[ 'defaultValue' ]
                    && (item['defaultValue'].substring(0, 1) === '[' || item['defaultValue'].substring(0, 1) === '{')
                ) {
                    defaultValue = JSON.parse(item[ 'defaultValue' ]).join(', ');
                }
            }
        } catch (e) {
            // TODO: check it in console
            console.error('PROBLEM WITH renderDefaultValue/defaultValue',
                item['defaultValueName'],
                item.defaultValue,
                defaultValue);
        }
    }

    return defaultValue;
};

export default convertFieldData
