import {connect} from 'react-redux'
import ManageFieldForm from './ManageFieldForm'
import {
    dispatchFIValidStatus,
    editFieldInstance,
    getFieldInstance,
    isFieldValid,
    setCurrentTab,
    updateFieldInstance
} from '../../../redux/actions/templateActions'
import {getLanguages} from '../../../redux/actions/languageActions'
import {getAllStages} from '../../../redux/actions/stages'
import {getAllRoles} from '../../../redux/action-creators/users'
import {convertReceivedField, serviceSectionFilter} from '../../../utils/fields'

const mapStateToProps = (state) => ({
    // field: {},
    mode: 'edit',
    label: 'Edit Field Instance',
    loading: state.app.templates.loading,
    field: state.app.templates.editedField,
    isFieldChanged: state.app.templates.isFieldChanged,
    templateCode: state.app.templates.code,
    baseField: convertReceivedField(state.app.fields.data.find((item) => item.code === state.app.templates.editedField.originalFieldCode)) || {},
    sections: state.app.templates.sectionDto
        .filter(serviceSectionFilter)
        .sort((prev, next) => prev.position - next.position)
        .map(item => ({value: item.code, label: item.displayName['EN']})),
    fieldsCount: state.app.templates.fieldInstancesDto.length,
    languageCodes: state.app.languages.data,
    editedSuccess: state.app.templates.fieldEditSuccess,
    error: state.app.templates.error,
    isFormValid: state.app.templates.isFIFormValid,
    fieldValid: state.app.templates.fieldValid,

});

const mapDispatchToProps = (dispatch, ownProps) => ({
    getInitData: () => {
        dispatch(getFieldInstance(ownProps.match.params.id));
        dispatch(getLanguages());
        dispatch(getAllStages());
        dispatch(getAllRoles());
        dispatch(dispatchFIValidStatus(true));
    },
    editField: (field, value) => dispatch(editFieldInstance(field, value)),
    updateFieldInstance: data => dispatch(updateFieldInstance(data)),
    isAllValid: (valid) => dispatch(isFieldValid(valid)),
    setCurrentTab: () => dispatch(setCurrentTab('field_instances')),
    dispatchFIValidStatus: data => dispatch(dispatchFIValidStatus(data)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageFieldForm)
