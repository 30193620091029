import React from 'react';
import PropTypes from 'prop-types';

const LoginButton = ({ disabled }) => {
  return (
    <div className="row no-padding">
      <div className="col-xs-4 pull-right">
        <button
          disabled={disabled}
          className="btn btn-primary btn-block btn-flat"
        >
          Sign in
        </button>
      </div>
    </div>
  );
};

LoginButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default LoginButton;
