import {isClient, isReviewer} from './helpers'
import {getLocalStorageKey} from './case-helpers'

const scriptsNames = [
    {'v': 'customClassesScript', 'a': '', 'pre': ''},
    {'v': 'defaultValueScript', 'a': 'value', 'pre': ''},
    {'v': 'nameScript', 'a': 'displayName', 'pre': ''},
    {'v': 'sectionCodeScript', 'a': '', 'pre': ''},
    {'v': 'helpTextClientScript', 'a': 'title', 'pre': ''},
    {'v': 'helpTextReviewerScript', 'a': 'title', 'pre': ''},
    {'v': 'helpTextStaffScript', 'a': 'title', 'pre': ''},
    //{'v': 'listOptionsScript',      'a': '',       'pre': ''},
    {'v': 'readOnlyMatrixScript', 'a': '', 'pre': ''},
    //{'v': 'requiredMatrixScript',   'a': 'required',    'pre': ''},
    {'v': 'visibilityMatrixScript', 'a': '', 'pre': 'vis-'}
];

function parseUserAttributes(scriptArr2_part) {
    let editedUser = "JSON.parse(localStorage.getItem('current_user'))";
    let userField = scriptArr2_part.substr(6);
    return editedUser + "['" + userField + "']";
}

function parseCaseTypeAttributes(caseCode, templateCode, scriptArr2_part) {
    let key = getLocalStorageKey(caseCode);
    let fieldKey = 'caseType_' + templateCode;
    let caseField = scriptArr2_part.substr(10);
    let caseData = "JSON.parse(localStorage.getItem('" + key + "'))['case']['" + fieldKey + "']";
    return "(JSON.parse(localStorage.getItem('" + key + "'))['case']['" + fieldKey + "'])?"
        + caseData + "['" + caseField + "']:'';";
}

function parseCaseAttributes(caseCode, scriptArr2_part) {
    let key = getLocalStorageKey(caseCode);
    let editedCase = "JSON.parse(localStorage.getItem('" + key + "'))['edited_case']";
    let caseField = scriptArr2_part.substr(6);
    let caseValue = '';
    let caseFieldFirst = caseField.substr(0, caseField.indexOf('.'));
    switch (caseField) {
        case 'questionCountTotal':
            caseValue = "JSON.parse(localStorage.getItem('" + key + "'))['questionTotal']";
            break;
        case 'stage':
            caseValue = editedCase + "['currentStage']['code'].substr(" + editedCase + "['currentStage']['code'].indexOf('_') + 1)";
            break;
        case 'caseType':
            caseValue = editedCase + "['patientCaseType']";
            break;
        case 'clientDueDate':
        case 'reviewerDueDate':
        case 'creationDate':
        case 'dispatchDate':
        case 'submitDate':
            caseValue = "(" + editedCase + "['" + caseField + "'] != null ? new Date(" + editedCase + "['" + caseField + "']) : ''";
            break;
        case 'clientDueDate.full':
        case 'reviewerDueDate.full':
        case 'creationDate.full':
        case 'dispatchDate.full':
        case 'submitDate.full':
            caseValue = "(" + editedCase + "['" + caseField + "'] != null ? new Date(" + editedCase + "['" + caseField + "']) " +
                "new Date(" + editedCase + "['" + caseFieldFirst + "']) : ''";
            break;
        case 'clientDueDate.time':
        case 'reviewerDueDate.time':
        case 'creationDate.time':
        case 'dispatchDate.time':
        case 'submitDate.time':
            caseValue = "(" + editedCase + "['" + caseField + "'] != null ? new Date(" + editedCase + "['" + caseFieldFirst + "']) : ''";
            break;
        case 'clientDueDate.year':
        case 'reviewerDueDate.year':
        case 'creationDate.year':
        case 'dispatchDate.year':
        case 'submitDate.year':
            caseValue = "(" + editedCase + "['" + caseField + "'] != null ? new Date(" + editedCase + "['" + caseFieldFirst + "']).getFullYear() : ''";
            break;
        case 'clientDueDate.month':
        case 'reviewerDueDate.month':
        case 'creationDate.month':
        case 'dispatchDate.month':
        case 'submitDate.month':
            caseValue = "(" + editedCase + "['" + caseField + "'] != null ? new Date(" + editedCase + "['" + caseFieldFirst + "']).getMonth() : ''";
            break;
        case 'clientDueDate.date':
        case 'reviewerDueDate.date':
        case 'creationDate.date':
        case 'dispatchDate.date':
        case 'submitDate.date':
            caseValue = "(" + editedCase + "['" + caseField + "'] != null ? new Date(" + editedCase + "['" + caseFieldFirst + "']).getDate() : ''";
            break;
        default:
            caseValue = editedCase + "['" + caseField + "']";
    }
    return caseValue;
}

function parseSectionAttributes(scriptArr2_part, action) {
    let sectionName = scriptArr2_part.substr(9);
    if (action.indexOf('.show') === 0) {
        return 'if(document.getElementById("' + sectionName + 'LINK") != null){'
            + 'document.getElementById("' + sectionName + '").style.display="block";'
            + 'document.getElementById("' + sectionName + 'LINK").style.display="block";}'
            + action.replace(".show", "");
    }
    if (action.indexOf('.hide') === 0) {
        return 'if(document.getElementById("' + sectionName + 'LINK") != null){'
            + 'document.getElementById("' + sectionName + '").style.display="none";'
            + 'document.getElementById("' + sectionName + 'LINK").style.display="none";}'
            + action.replace(".hide", "");
    }
    if (action.indexOf('.isVisible') === 0) {
        action = action.replace("isVisible", "style.display=='block'");
        return 'document.getElementById("' + sectionName + '")' + action;
    }
}

function parseRefAttributes(caseCode, scriptArr2_part, is_need_ref, need_ref, templateCode, field, hotAttribute) {
    let key = getLocalStorageKey(caseCode);
    let refString = scriptArr2_part.substr(5).split('.');
    let othersParts = refString.length > 2 ? scriptArr2_part.substr(6 + refString[0].length + refString[1].length) : '';

    let condition = 'JSON.parse(localStorage.getItem("' + key + '"))["case"]["' + refString[0] + '_' + templateCode + '"] ? (';

    let result = 'JSON.parse(localStorage.getItem("' + key + '"))["case"]["' +
        refString[0] + '_' + templateCode + '"]["' +
        refString[1] + '"]';

    if (othersParts.indexOf('getFullYear') !== -1) {
        result = '(' + result + ' != null ? (new Date(' + result + ')).getFullYear() : "")';
    }
    if (othersParts.indexOf('getAge') !== -1) {
        result = '(' + result + ' != null ? (new Date()).getFullYear() - (new Date(' + result + ')).getFullYear() : "")';
    }

    // VALUE and LABEL magic
    if (is_need_ref && need_ref) {
        result = 'JSON.parse(localStorage.getItem("' + need_ref + '"))[' + result + ']';
    }

    // HOT FIX FOR COUNTRY AND STATE
    if (refString[0] === 'country') {
        result = 'JSON.parse(localStorage.getItem("list_country"))[' + result + '] || ' + result;
    }
    if (refString[0] === 'state') {
        result = 'JSON.parse(localStorage.getItem("list_state"))[' + result + '] || ' + result;
    }

    // DATES fixes values
    if (hotAttribute.indexOf('value') === 0) {
        if (field.fieldType === 'DATE') {
            result = '(' + result + ' != null ? (new Date(' + result + ') : "")';
        } else if (field.fieldType === 'TIME') {
            result = '(' + result + ' != null ? (new Date(' + result + ') : "")';
        } else if (field.fieldType === 'DATETIME') {
            result = '(' + result + ' != null ? (new Date(' + result + ') + " " + new Date(' + result + ') : "")';
        }
    }

    return condition + result + ') : ""';
}

function replaceReturn(resultScript, field, i, templateCode) {
    resultScript = resultScript.replace("return",
        '"{$' + field.code.replace('_' + templateCode, '') + '}"'
        + (scriptsNames[i]['a'] ? ('.' + scriptsNames[i]['a']) : '.value') + '=');

    return resultScript;
}

function getKeys(obj) {
    let keys = [];
    for (let key in obj) {
        if (!obj.hasOwnProperty(key)) continue;
        keys.push(key);
    }
    return keys;
}

function changeScriptsData(caseCode, field, templateCode, roleCode) {

    //console.clear();
    let key = getLocalStorageKey(caseCode);
    let refFields = JSON.parse(localStorage.getItem(key))['ref_fields'] || {};
    let refKeys = getKeys(refFields);
    let j_need_ref = 0;
    let need_ref = '';
    //let caseCode = JSON.parse(localStorage.getItem('edited_case'))['code'];

    for (let i = 0; i < scriptsNames.length; i++) {
        if (field[scriptsNames[i]['v']] && field[scriptsNames[i]['v']].indexOf('setDefaultTime(') !== -1) field[scriptsNames[i]['v']] = '';
        if (field[scriptsNames[i]['v']] && field[scriptsNames[i]['v']].indexOf('this.onchange') !== -1) {
            field.onchange.push(field[scriptsNames[i]['v']]);
            field[scriptsNames[i]['v']] = '';
        } else if (field[scriptsNames[i]['v']]) {
            if (isClient(roleCode) && ['helpTextReviewerScript', 'helpTextStaffScript'].indexOf(scriptsNames[i]['v']) !== -1) continue;
            if (isReviewer(roleCode) && ['helpTextClientScript', 'helpTextStaffScript'].indexOf(scriptsNames[i]['v']) !== -1) continue;
            if (!isClient(roleCode) && !isReviewer(roleCode)
                && ['helpTextClientScript', 'helpTextReviewerScript'].indexOf(scriptsNames[i]['v']) !== -1) continue;
            let script = field[scriptsNames[i]['v']];
            // search for all variables of objects
            script = replaceReturn(script, field, i, templateCode);
            let hotAttribute = scriptsNames[i]['a'] ? scriptsNames[i]['a'] : 'value';
            let scriptArr1 = script.split('"{');

            let resultScript = '';
            let tempScript = '';
            if (scriptArr1.length > 0) {
                let scriptArr2 = [];
                resultScript = scriptArr1[0];

                scriptArr1.map((item, index) => {
                    if (index > 0) {
                        scriptArr2.push(item.split('}"'));
                    }
                    return item;
                });
                //console.log(scriptArr2);
                for (let j = 0; j < scriptArr2.length; j++) {
                    if (scriptArr2[j][0][0] === '$') {
                        if (scriptArr2[j][1].substr(0, 1) !== '.') {
                            scriptArr2[j][1] = (scriptsNames[i]['a'] ? ('.' + scriptsNames[i]['a']) : '.value') + scriptArr2[j][1];
                            hotAttribute = scriptsNames[i]['a'] ? scriptsNames[i]['a'] : 'value';
                        } else {
                            hotAttribute = scriptArr2[j][1].substr(1, scriptArr2[j][1].indexOf(' '));
                        }

                        if (scriptArr2[j][1].indexOf('.show') === 0){
                             // for visibility we change parentNode
                            resultScript += 'if(document.getElementById("vis-' + scriptArr2[j][0].substr(1) + '_' + templateCode + '") != null)'
                            + 'document.getElementById("vis-' + scriptArr2[j][0].substr(1) + '_' + templateCode + '")';
                        } else if( scriptArr2[j][1].indexOf('.displayName') === 0){
                            resultScript +=
                            'document.getElementById("displayName-' + scriptArr2[j][0].substr(1) + '_' + templateCode + '").innerText'

                        } else if (scriptArr2[j][1].indexOf('.hide') === 0 ){
                            resultScript += 'let fieldValue = JSON.parse(localStorage.getItem("' + key + '"))["values"]["' +
                                scriptArr2[j][0].substr(1) + '_' + templateCode + '"];' +
                                'if(document.getElementById("vis-' + scriptArr2[j][0].substr(1) + '_' + templateCode + '") != null  )' +
                                'document.getElementById("vis-' + scriptArr2[j][0].substr(1) + '_' + templateCode + '")';
                        }
                        else if (scriptArr2[j][1].indexOf('.isVisible') === 0) {
                            resultScript += '(document.getElementById("vis-' + scriptArr2[j][0].substr(1) + '_' + templateCode + '") != null) && '
                                + 'document.getElementById("vis-' + scriptArr2[j][0].substr(1) + '_' + templateCode + '")';
                        } else if (scriptArr2[j][1].indexOf('.value') === 0) {

                            resultScript += '{let vvv = JSON.parse(localStorage.getItem("' + key + '"));vvv["values"]["' +
                                scriptArr2[j][0].substr(1) + '_' + templateCode + '"]=##VALUE##;}' ;
                                //'localStorage.setItem("' + key + '", JSON.stringify(vvv))}';
                        } else if (scriptArr2[j][1].indexOf('.getValue') === 0) {
                            resultScript += 'JSON.parse(localStorage.getItem("' + key + '"))["values"]["' +
                                scriptArr2[j][0].substr(1) + '_' + templateCode + '"]';
                        } else {
                            // for all other cases
                            resultScript += 'document.getElementsByName("' + scriptArr2[j][0].substr(1) + '_' + templateCode + '")[0]';
                        }

                        j_need_ref = 0;
                        need_ref = '';
                        if (scriptArr2[j][1].indexOf('.show') === 0 ||
                            scriptArr2[j][1].indexOf('.hide') === 0 ||
                            scriptArr2[j][1].indexOf('.isVisible') === 0 ||
                            scriptArr2[j][1].indexOf('.displayName') === 0) {
                            tempScript = scriptArr2[j][1].replace("show", "style.display='block'")
                                .replace("hide", "style.display='none'")
                                .replace("isVisible", "style.display=='block'")
                                .replace(".displayName", "");
                            resultScript += tempScript;
                        } else if (scriptArr2[j][1].indexOf('.value') === 0 &&
                            !(refKeys.indexOf(scriptArr2[j][0].substr(1) + '_' + templateCode) !== -1)) {
                            let temp_scriptPart2 = scriptArr2[j][1].substr(6)
                                .replace(" ; =", ";=")
                                .replace("; = ", ";=")
                                .replace(" ;=", ";=")
                                .replace(";= ", ";=")
                                .substr(2);
                            while (temp_scriptPart2[temp_scriptPart2.length - 1] === ';') {
                                temp_scriptPart2 = temp_scriptPart2.slice(0, -1);
                            }
                            let values = temp_scriptPart2.split(';');
                            //console.log(temp_scriptPart2)
                            if (temp_scriptPart2) resultScript = resultScript.replace('##VALUE##', values[0]) + ' '
                                                                + 'localStorage.setItem("' + key + '", JSON.stringify(vvv))}' + values[1];
                            // +
                            // "window.store.dispatch(window.updateCaseValues({'active':'true','code':'" + caseCode
                            //     + "','fieldData':JSON.parse(localStorage.getItem('fields'))}));";
                        } else if (scriptArr2[j][1].indexOf('.getValue') === 0) {
                            resultScript += scriptArr2[j][1].substr(9);
                            if (refKeys.indexOf(scriptArr2[j][0].substr(1) + '_' + templateCode) !== -1) {
                                // resultScript = resultScript
                                //     + scriptArr2[j][1].replace("getValue", "parentNode.getElementsByClassName('Select-value-label')[0].innerText");
                                //resultScript += 'document.getElementById("' + scriptArr2[j][0].substr(1) + '_' + templateCode + '").change();';
                                j_need_ref = j + 1;
                                need_ref = refFields[scriptArr2[j][0].substr(1) + '_' + templateCode];
                            }
                        } else {
                            resultScript += scriptArr2[j][1].replace('.value=', '');
                        }

                        // if (scriptArr2[j][1].indexOf('.value') === 0) {
                        //     //resultScript += 'window.store.dispatch(window.prepareFields({}));';
                        // }

                    } else if (scriptArr2[j][0][0] === '#') {
                        if (scriptArr2[j][0].indexOf('#CASE.') === 0) {
                            // PARSE CASE ATTRIBUTES
                            // resultScript += parseCaseAttributes(caseCode, scriptArr2[j][0]) + scriptArr2[j][1];
                            let caseData = parseCaseAttributes(caseCode, scriptArr2[j][0]);
                            if (resultScript.indexOf('##VALUE##') > 0) {
                                resultScript = resultScript.replace('##VALUE##', caseData) + '; ' + scriptArr2[j][1];
                            } else {
                                resultScript += caseData + scriptArr2[j][1];
                            }
                        } else if (scriptArr2[j][0].indexOf('#SECTION.') === 0) {
                            // PARSE SECTION ATTRIBUTES
                            resultScript += parseSectionAttributes(scriptArr2[j][0], scriptArr2[j][1]);
                        } else if (scriptArr2[j][0].indexOf('#USER.') === 0) {
                            // PARSE USER ATTRIBUTES
                            resultScript += parseUserAttributes(scriptArr2[j][0]) + scriptArr2[j][1];
                        } else if (scriptArr2[j][0].indexOf('#CASETYPE.') === 0) {
                            // PARSE CASE TYPE ATTRIBUTES
                            let caseTypeData = parseCaseTypeAttributes(caseCode, templateCode, scriptArr2[j][0]);
                            if (resultScript.indexOf('##VALUE##') > 0) {
                                resultScript = resultScript.replace('##VALUE##', caseTypeData) + '; ' + scriptArr2[j][1];
                            } else {
                                resultScript += caseTypeData + scriptArr2[j][1];
                            }
                        } else if (scriptArr2[j][0].indexOf('#REF.') === 0) {
                            // REFERRAL FIELDS
                            let refData = parseRefAttributes(caseCode, scriptArr2[j][0], j === j_need_ref, need_ref,
                                templateCode, field, hotAttribute);
                            if (resultScript.indexOf('##VALUE##') > 0) {
                                resultScript = resultScript.replace('##VALUE##', refData) + '; ' + scriptArr2[j][1];
                            } else {
                                resultScript += refData + scriptArr2[j][1];
                            }
                        }
                        //console.log('script', resultScript)
                    }
                }
            }
            field[scriptsNames[i]['v']] = resultScript;
        }
    }

    return field;
}

function onChangeBuild(onchange, templateCode) {
    let result = [];
    let actions = [];
    for (let i = 0; i < onchange.length; i++) {
        let item = onchange[i];
        item = item.replace('this.onchange', "").replace(/TEMPLATENAME/g, templateCode);
        item = item.substring(item.indexOf('{') + 1, item.lastIndexOf('}') - 1);
        actions = item.split('action(');
        for (let k = 0; k < actions.length; k++) {
            let action = actions[k].substring(0, actions[k].lastIndexOf(')'));
            if (action.replace(/^\s*(.*)\s*$/, '$1') === '') continue;
            action = action.replace(/^\s*(.*)\s*$/, '$1')
                .replace(/^'*(.*)''*$/, '$1')
                .replace(/^"*(.*)"*$/, '$1');
            result.push(action);
        }
    }
    return result;
}

export function onRequiredBuild(rules, templateCode, fields) {
    let result = [];
    let operations = [];
    let prepend = '';
    let firstRule = rules.indexOf('rule(');
    if(firstRule > 0) {
        prepend = rules.substr(0, firstRule);
    }
    let actions = rules.split('rule(');

    for (let k = 0; k < actions.length; k++) {
        let act = actions[k].indexOf(')');
        let action = actions[k].substring(0, act);
        let operation = actions[k].substring(act + 1);
        if (action.replace(/^\s*(.*)\s*$/, '$1') === '') continue;

        action = action.replace(/^\s*(.*)\s*$/, '$1')
            .replace(/^'*(.*)''*$/, '$1')
            .replace(/^"*(.*)""*$/, '$1');
        if (action.indexOf('field[') !== -1) {
            let fs = action.split('field[');
            action = '';
            for (let fi = 0; fi < fs.length; fi++) {
                if (fs[fi].indexOf(']') === -1) {
                    action += fs[fi];
                    continue;
                }
                let fieldName = fs[fi].substring(0, fs[fi].lastIndexOf(']'));
                fieldName = fieldName.replace(/^\s*(.*)\s*$/, '$1')
                    .replace(/^'*(.*)''*$/, '$1')
                    .replace(/^"*(.*)""*$/, '$1');
                fieldName = fieldName + '_' + templateCode;
                fieldName = (fields[fieldName]) ? fields[fieldName] : fieldName;
                if(fieldName) {
                    action += "'" + fieldName + "'" + fs[ fi ].substring(fs[ fi ].lastIndexOf(']') + 1);
                    result.push(action);
                    operations.push(operation);
                }
            }
        }

    }
    let resultRule = prepend;
    for (let i = 0; i < result.length; i++) {
        resultRule += '(' + result[i] + ')';
        resultRule += operations[i];
    }
    return resultRule;

}

export function onListOptionsBuild(rule, templateCode, values, fields) {
    let fieldType, fieldCode, fieldList;
    let fs = rule.split('field[');
    for (let fi = 0; fi < fs.length; fi++) {
        if (fs[fi].indexOf(']') === -1) continue;
        let fieldName = fs[fi].substring(0, fs[fi].lastIndexOf(']'));
        fieldName = fieldName.replace(/^\s*(.*)\s*$/, '$1')
            .replace(/^'*(.*)''*$/, '$1')
            .replace(/^"*(.*)""*$/, '$1');
        fieldName = fieldName + '_' + templateCode;
        let field = fields.find((item) => item.code === fieldName);
        fieldType = field ? field.fieldType : '';
        fieldName = (values && values[fieldName]) ? values[fieldName] : '';
        fieldCode = fieldName;
        fieldList = fs[fi].substring(fs[fi].lastIndexOf('].') + 2);
        fieldList = fieldList.replace(/^\s*(.*)\s*$/, '$1')
            .replace(/^;*(.*);;*$/, '$1');
    }
    return {'type': fieldType, 'code': fieldCode, 'list': fieldList};
}

export function scriptParse(caseCode, field, templateCode, roleCode) {
    field = changeScriptsData(caseCode, field, templateCode, roleCode);
    field.script = (field['displayNameScript'] ? field['displayNameScript'] + '; ' : '') +
        //(field.listOptionsScript ? field.listOptionsScript + '; ' : '') +
        (field.defaultValueScript ? field.defaultValueScript + '; ' : '') +
        (field.customClassesScript ? field.customClassesScript + '; ' : '') +
        //(field.requiredMatrixScript ? field.requiredMatrixScript + '; ' : '') +
        (field.helpTextStaffScript ? field.helpTextStaffScript + '; ' : '') +
        (field.helpTextReviewerScript ? field.helpTextReviewerScript + '; ' : '') +
        (field.helpTextClientScript ? field.helpTextClientScript + '; ' : '');
    //console.log(field.code, field.defaultValueScript)
    field.onchange = onChangeBuild(field.onchange, templateCode);
    return field;
}

export function runScripts() {
    // console.warn('runScripts');
    if (window.scripts && JSON.stringify(window.scripts) !== '{}') {
        for (let fCode in window.scripts) {
            if (!window.scripts.hasOwnProperty(fCode)) continue;
            window.scripts[fCode]();
        }
    }
}
