import {connect} from 'react-redux'
import TierManagePage from './TierManagePage'
import {createEntity, entityEditField, isEntityValid} from '../../../redux/actions/BasicEntityActions'
import {getFullBillingGroup, setCurrentTier} from '../../../redux/actions/billingActions'
import shortId from 'shortid'
import {matchPath} from 'react-router-dom'
import {getAllTypes} from "../../../redux/actions/reviewerTypesAction";

const mapStateToProps = (state) => {
    const {billing_tier, billing_group, reviewerTypes} = state.app;
    const {billingTiers} = billing_group.editedBillingGroup;

    const tierNumber = billingTiers.length + 1;

    const neededBGCode = billing_tier.editedBillingTier.billingGroup;
    const editedBGCode = billing_group.editedBillingGroup.code;

    return {
        mode: 'create',
        title: 'Tier ' + tierNumber,
        loading: billing_tier.loading,
        error: billing_tier.error,
        tier: billing_tier.editedBillingTier,
        tierNumber: tierNumber,
        tiers: billingTiers,
        showValidMessages: billing_tier.showValidMessages,
        isValid: billing_tier.isValid,
        isProfileChanged: billing_tier.isProfileChanged,
        editSuccess: billing_tier.editSuccess,
        reviewerTypes: reviewerTypes.data.map(item => ({value: item.code, label: item.name})),
        neededBGCode: neededBGCode,
        needToLoadBG: neededBGCode !== editedBGCode,

        top: '5em',
        style: {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '98%',
        }
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const match = matchPath(ownProps.history.location.pathname, {
            path: '/billing_tier/create/tier/:id',
            exact: true,
            strict: false
        }) ||
        matchPath(ownProps.history.location.pathname, {path: '/billing_groups/update/:id', exact: true, strict: false});
    const billingGroup = match ? match.params.id : 0;
    const code = shortId.generate();

    return {
        getFullBillingGroup: (code) => dispatch(getFullBillingGroup(code)),
        getInitData: () => {
            dispatch(setCurrentTier({code: code, billingGroup: billingGroup}));
            dispatch(getAllTypes());
        },
        editTier: (field, value) => dispatch(entityEditField(field, value, 'billing_tier')),
        isAllValid: (valid) => dispatch(isEntityValid(valid, 'billing_tier')),
        onSave: (data) => dispatch(createEntity('billing_tier', data)),
        cancelAndDiscard: () => dispatch({type: 'TIER_DISCARD_CHANGES'})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TierManagePage)
