import { createApiParams } from 'components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import { fetchEntityList$ } from '../../../epicUtils/commonEpics';
import clientEditMedicalServicesServicesSlice from "./clientEditMedicalServicesServicesSlice";
import { transformClientEditServicesServices } from "./utils";
import { getValidMedicalCodingSystemCodesArray } from "../utils";

const clientEditMedicalServicesServicesEpic = fetchEntityList$({
    ...clientEditMedicalServicesServicesSlice.actions,
    resolveUrl: ({ code, medicalCodingSystemCodes }) => {
        const arr = getValidMedicalCodingSystemCodesArray(medicalCodingSystemCodes);
        if (arr.length > 0) {
            return `client_medical_service/service/page?code=${code}&medicalCodingSystemCodes=${arr}`
        } else {
            return `client_medical_service/service/page?code=${code}`
        }
    },
    resolveParams: (params) => [{ params: createApiParams(params) }],
    dataTransformer: transformClientEditServicesServices,
    // dataTransformer: () => mockServices,
});

export default combineEpics(clientEditMedicalServicesServicesEpic);
