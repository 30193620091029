import {connect} from 'react-redux'
import CreateFieldForm from './CreateFieldForm'
import {
    createFieldAndInstance,
    dispatchFIValidStatus,
    editFieldInstance,
    isFieldValid,
    setCurrentField,
    setCurrentTab
} from '../../../redux/actions/templateActions'
import {getLanguages} from '../../../redux/actions/languageActions'
import {getAllRoles, openEditModal} from '../../../redux/action-creators/users'
import {getAllStages} from '../../../redux/actions/stages'
import {serviceSectionFilter} from '../../../utils/fields'

const mapStateToProps = (state) => ({
    // field: {},
    mode: 'create',
    label: 'Create Field Instance',
    loading: state.app.templates.loading,
    error: state.app.templates.error,
    field: state.app.templates.editedField,
    isFormValid: state.app.templates.isFIFormValid,
    fieldValid: state.app.templates.fieldValid,
    isFieldChanged: state.app.templates.isFieldChanged,
    sections: state.app.templates.sectionDto
        .filter(serviceSectionFilter)
        .sort((prev, next) => prev.position - next.position)
        .map(item => ({value: item.code, label: item.displayName['EN']})),
    fieldsCount: state.app.templates.fieldInstancesDto.length,
    languageCodes: state.app.languages.data,
});

const mapDispatchToProps = (dispatch) => ({
    getInitData: () => {
        dispatch(getLanguages());
        dispatch(setCurrentField());
        dispatch(dispatchFIValidStatus(true));
        dispatch(getAllStages());
        dispatch(getAllRoles())
    },
    editField: (field, value) => dispatch(editFieldInstance(field, value)),
    dispatchFIValidStatus: (value) => dispatch(dispatchFIValidStatus(value)),
    isAllValid: (valid) => dispatch(isFieldValid(valid)),
    handleOpenModal: () => {
        dispatch(openEditModal())
    },
    setCurrentTab: () => dispatch(setCurrentTab('field_instances')),
    createFieldAndInstance: (data) => dispatch(createFieldAndInstance(data)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateFieldForm)
