import { createApiParams } from 'components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import { fetchEntityList$ } from '../../epicUtils/commonEpics';
import clientEditPhysicianSlice from './clientEditPhysicianSlice';
import { transformClientEditPhysicians } from './utils';

const clientEditPhysicianEpic = fetchEntityList$({
    ...clientEditPhysicianSlice.actions,
    resolveUrl: ({ code }) => `physician/page?code=${code}`,
    resolveParams: (params) => [{ params: createApiParams(params) }],
    dataTransformer: transformClientEditPhysicians,
});

export default combineEpics(clientEditPhysicianEpic);
