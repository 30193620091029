import React from 'react'
import { Link } from 'react-router-dom'

const CollapsedLogo = () => (
    <Link className="logo navbar-fixed-top logo-collapsed" to='/'>
        <span>
            <img src={require('../../styles/img-custom/logo-small.png')} alt="MedRev" />
        </span>
    </Link>
);

export default CollapsedLogo;
