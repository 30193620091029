import api from "../../utils/api";
import { Statuses } from "./statuses";
import { isEntityLoaded } from "../../utils/helpers";

const isAvailableInCache = (reviewerTypes) =>
    isEntityLoaded(reviewerTypes) && reviewerTypes.data && reviewerTypes.data.length > 0;

export const getAllTypes = (reviewerTypes) => (dispatch) => {
    if (isAvailableInCache(reviewerTypes)) {
        return;
    }
    dispatch({
        type: 'FETCH_REVIEWER_TYPES_LOADING',
    })
    return api.get('auth/reviewer/type/all')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_REVIEWER_TYPES_SUCCESS',
                payload: data
            });

            return;
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_REVIEWER_TYPES_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_REVIEWER_TYPES_ERROR',
                    payload: error.message
                });
            }

            return Promise.reject(error);
        })

}
