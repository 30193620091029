import React, {Component} from "react";
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {verifyRecoveryLink, recoveryPasswordNext} from '../../redux/actions/authActions'
import ReCAPTCHA from 'react-google-recaptcha'
import {RECAPTCHA_KEY,} from '../../config';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.props.verifyCode(props.match.params.key);
        this.state = {
            error: '',
            newPassword: '',
            passwordRecoveryKey: props.match.params.key,
            repeatNewPassword: '',
            isLoading: false,
            serverError: false,
            needReset: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*+-|]).{8,}$/;
    }

    isValid = function () {
        return this.state.error === '';
    };

    onSubmit(e) {
        e.preventDefault();
        if (this.isValid()) {
            this.setState({errors: {}, isLoading: true});
            this.props.recoveryPasswordNext(this.state).then(
                () => this.setState({toLogin: true}),
                (err) => this.setState({errors: err, isLoading: false, serverError: true, needReset: true})
            );
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
        if(document.getElementById('password').value.match(this.regex) === null) {
            this.setState({error: 'The password provided does not meet the requirements. Please, try another, maybe another special character.'});
            this.setState({serverError: false});
        } else if (document.getElementById('password').value !== document.getElementById('repeatNewPassword').value) {
            this.setState({error: 'Passwords must match'});
            this.setState({serverError: false});
        } else {
            this.setState({error: ''});
        }
    }

    onChangeRe(response) {
        this.setState({
            'g-recaptcha-response': response
        });
    }

    render() {
        if (this.state.toLogin === true) {
            return <Redirect to='/login'/>
        }
        const {isLoading, serverError, needReset} = this.state;
        const stateError = this.state.error;
        const active = this.props.active;
        if (active === false) {
            return <Redirect to='/login'/>
        }
        if (needReset) {
            if (window.grecaptcha) {
                window.grecaptcha.reset();
            }
            this.setState({needReset: false});
        }
        return (
            <div>
                <p className="login-box-msg">
                    {stateError !== '' ?
                        <span className="text-danger">{stateError} </span>
                        :
                        (
                            serverError && this.props.error ?
                                <span className="text-danger">{this.props.error} </span>
                                :
                                "Restore your password"
                        )
                    }
                </p>
                <p className={'text-justify'}>
                    Password must contain at least a capital letter, a digit and a special character and be at least 8&nbsp;characters long.
                </p>
                <form onSubmit={this.onSubmit} className='wide-form'>
                    <div className="form-group has-feedback">
                        <input
                            id="password"
                            type="password"
                            className="form-control"
                            name="newPassword"
                            required="required"
                            placeholder="Password"
                            autoComplete="off"
                            onChange={this.onChange}
                        />
                        <span className="glyphicon glyphicon-lock form-control-feedback"/>
                    </div>
                    <div className="form-group has-feedback">
                        <input
                            id="repeatNewPassword"
                            type="password"
                            className="form-control"
                            name="repeatNewPassword"
                            required="required"
                            placeholder="Repeat Password"
                            autoComplete="off"
                            onChange={this.onChange}
                        />
                        <span className="glyphicon glyphicon-lock form-control-feedback"/>
                    </div>
                    <div className="form-group has-feedback">
                        <input
                            id="google_authenticator"
                            className="form-control"
                            type="input"
                            name="Google_authenticator"
                            required="required"
                            placeholder="Google Authenticator Code"
                            autoComplete="off"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="form-group has-feedback">
                        <ReCAPTCHA
                            ref="recaptcha"
                            sitekey={RECAPTCHA_KEY}
                            onChange={this.onChangeRe.bind(this)}
                        />
                    </div>
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right">
                            <button
                                disabled={isLoading}
                                className="btn btn-primary btn-block btn-flat">Next
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapState = (state) => {
    return {
        res: state.app.auth.res,
        active: state.app.auth.active,
        error: state.app.auth.error
    }
};

const mapActions = dispatch => {
    return {
        verifyCode: is_correct_code => dispatch(verifyRecoveryLink(is_correct_code)),
        recoveryPasswordNext: ChangePassword => dispatch(recoveryPasswordNext(ChangePassword))
    }
};

export default connect(mapState, mapActions)(ChangePassword);
