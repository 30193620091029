import React from 'react'
import LoadingIndicator from "../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import {getAll} from '../../redux/actions/BasicEntityActions'
import {getAllSpecialties} from '../../redux/actions/specialtyActions'
import PhysiciansList from "./PhysiciansList";

class PhysiciansManage extends React.Component {

    componentDidMount() {
        this.props.getAllBodyParts();
        this.props.getAllSpecialties();
        this.props.getAllLanguages();
        this.props.getAllTypes();
    }

    render() {
        return (
            <div className='container-fluid'>
                <div className="table-responsive" style={{marginTop: '15px', "padding": '10px'}}>
                    <PhysiciansList/>
                    {this.props.loading ? <LoadingIndicator height={50}/> : null}
                </div>
            </div>
        )
    }
}

const mapState = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {
        getAllBodyParts: () => dispatch(getAll('body_part')),
        getAllSpecialties: () => dispatch(getAllSpecialties()),
        getAllLanguages: () => dispatch(getAll('language')),
        getAllTypes: () => dispatch(getAll('facility_type'))
    }
};

export default connect(mapState, mapDispatchToProps)(PhysiciansManage);
