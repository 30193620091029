import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';
import { patientCaseStages } from '../patientCaseStages';

const acceptedSlice = createDatatableTemplateSlice({
    name: patientCaseStages.ACCEPTED,
    initialSettings: {},
});

export const acceptedReducer = acceptedSlice.reducer;

export const { loadData, dataLoaded, dataLoadError } = acceptedSlice.actions;

export default acceptedSlice;
