import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Panel } from 'react-bootstrap';
import ResponsiveTable from '../../../components/ResponsiveTable'

class ReviewerAdditionalInfo extends React.Component {

    render() {
        const { data, opened } = this.props;
        let tableStyle = {
            width: '55%'
        };
        return (
            <div>
                <Panel id="collapsible-panel-example-1" expanded={opened} onToggle={() => null}
                       style={{ border: 'none', margin: '0', paddingLeft: '15px', paddingRight: '15px' }}>
                    <Panel.Collapse>
                        <Panel.Body style={{ padding: 0 }} className='reviewer_add_info'>
                            <div className='row' style={{ width: '100%' }}>
                                <div className='col-lg-4 col-md-4'>
                                    <ResponsiveTable rowClassName='grid_row' fields={[
                                        {
                                            id: 1,
                                            title: 'Quality score',
                                            value: data.qualityScore,
                                        },
                                        {
                                            id: 2,
                                            title: 'Hourly Rate',
                                            value: data.hourlyRate + ' ' + data.hourlyRateCurrencyShortName,
                                        },
                                        {
                                            id: 3,
                                            title: 'CaseTypes excluded',
                                            value: data.excludedCaseTypeCodes === ' ' ? '' : data.excludedCaseTypeCodes,
                                        },
                                        {
                                            id: 4,
                                            title: 'Backup',
                                            value: data.backUp ? "Yes" : 'No'
                                        },

                                    ]}/>
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    <ResponsiveTable rowClassName='grid_row' fields={[
                                        {
                                            id: 1,
                                            title: 'Margin',
                                            value: data.flatRateMargin + '%',
                                        },
                                        {
                                            id: 2,
                                            title: 'On Time',
                                            value: data.onTime ? data.onTime + '%' : '',
                                        },
                                        {
                                            id: 3,
                                            title: 'Client excluded',
                                            value: data.excludedClientCodes === ' ' ? '' : data.excludedClientCodes,
                                        },
                                        {
                                            id: 4,
                                            title: 'Rev Types',
                                            value: data.reviewerTypeCodes === ' ' ? '' : data.reviewerTypeCodes,
                                        },

                                    ]}/>
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    <ResponsiveTable headerStyle={{ width: '30%' }} rowClassName='grid_row' fields={[
                                        {
                                            id: 1,
                                            title: 'Email',
                                            value: data.email,
                                        },
                                        {
                                            id: 2,
                                            title: 'Phone Num',
                                            value: data.phoneNumber,
                                        },
                                        {
                                            id: 3,
                                            title: 'Country',
                                            value: data.country,
                                        },
                                        {
                                            id: 4,
                                            title: 'Languages',
                                            value: data.languages,
                                        },

                                    ]}/>
                                </div>
                            </div>

                            <div style={{ background: '#fff', minHeight: '10px' }}/>

                            <div className="table-responsive">
                                <table className="table" style={{ background: 'inherit' }}>
                                    <tbody>
                                    <tr key={'key_1'} className='grid_row'
                                        style={{ background: '#ecf0f5', fontSize: '14px' }}>
                                        <th />
                                        <th>Current Month</th>
                                        <th>Minimum</th>
                                        <th>Maximum</th>
                                        <th style={tableStyle} />
                                    </tr>
                                    <tr key={'key_2'} className='grid_row'>
                                        <th style={{ fontSize: '14px' }}>Accepted</th>
                                        <td>{data.acceptedCurrentMonth}</td>
                                        <td>{data.acceptedMinimum}</td>
                                        <td>{data.acceptedMaximum}</td>
                                        <td style={tableStyle} />
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </div>
        );
    }

    static propTypes = {
        data: PropTypes.object,
        companies: PropTypes.array,
        languages: PropTypes.array,
        countries: PropTypes.array,
        opened: PropTypes.bool,
    }
}


ReviewerAdditionalInfo.contextTypes = {
    translate: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        companies: state.app.companies.data,
        countries: state.app.countries.all,
    }
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewerAdditionalInfo);
