import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './ControlDropZone.css';
import PropTypes from 'prop-types';
import { ControlContext } from '../Form';
import { getBase64 } from './dropzoneUtils';

export const ControlDropZone = ({ fileName, onChange, accept }) => {
  const { validationState } = useContext(ControlContext);
  const isInvalid = validationState === 'error';

  const [value, setValue] = useState(fileName);

  useEffect(() => {
    setValue(fileName);
  }, [fileName]);

  const handleChange = useCallback(
    async (acceptedFile) => {
      const [file] = acceptedFile;

      if (!file) {
        return;
      }
      setValue(file.name);

      const base64File = await getBase64(file);

      onChange({
        target: { value: { file: base64File, name: file.name, jsFile: file } },
      });
    },
    [onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: handleChange,
  });

  return (
    <div className="dropzone-container">
      <div
        {...getRootProps()}
        className={`dropzone ${isInvalid ? 'dropzone-error' : ''}`}
      >
        <input {...getInputProps()} />
        <span className="dropzone-drag-text text-center">
          {value ? value : 'Drag & Drop the file here ...'}
        </span>
      </div>
      <span className="mg-top-20 text-center">
        Click inside the grey rectangle or drag & drop a file
      </span>
    </div>
  );
};

ControlDropZone.propTypes = {
  fileName: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  accept: PropTypes.arrayOf(PropTypes.string),
};

export default ControlDropZone;
