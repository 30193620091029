import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {resetPasswordLink} from '../../redux/actions/authActions'

// TODO: @Deprecated
class ResetPassword extends Component {
    constructor(props){
        super(props);
        this.state={
            mail: '',
            codeSent: false,
            isLoading: false,
            isError: false,
            firstLoad: true
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.resetPasswordLink();
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ errors: {}, isLoading: true });
        this.props.resetPasswordLink(this.state.mail).then(
            (res) => this.setState({ codeSent: true, isLoading: false, firstLoad: false }),
            (err) => this.setState({ errors: err, isLoading: false, isError: true, firstLoad: false })
        );
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { isLoading, firstLoad } = this.state;

        return (
            <div>
                <p className="login-box-msg">
                    { (this.props.error && !firstLoad) ?
                        <span className="text-danger">{this.props.error} </span>
                        :
                        (
                            this.props.status ?
                                <span className="text-success" style={{wordBreak: 'normal'}}>{this.props.status} </span>
                                :
                                "Request password reset link"
                        )
                    }
                </p>
                <form onSubmit={this.onSubmit} className='wide-form'>
                    <div className="form-group has-feedback">
                        <input
                            id="mail"
                            autoFocus
                            className="form-control"
                            required="required"
                            placeholder="Email"
                            name="mail"
                            onChange={this.onChange}
                             />
                        <span style={{top: '0'}} className="glyphicon glyphicon-user form-control-feedback" />
                    </div>
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right">
                            <button
                                disabled={isLoading}
                                className="btn btn-primary btn-block btn-flat">
                                Send
                            </button>
                        </div>
                    </div>
                </form>
                <div className="login-box-msg" style={{float:'left', 'marginTop': '-25px'}}>
                    <Link to="/login">Back to login</Link>
                </div>
            </div>
        );
    }
}

const mapState = (state, history) => {
    return {
        status: state.app.auth.status,
        error: state.app.auth.error
    }
}

const mapActions = dispatch => {
    return {
        resetPasswordLink: mail => dispatch(resetPasswordLink(mail))
    }
}

export default connect(mapState, mapActions)(ResetPassword);
