import React from 'react';
import { connect } from 'react-redux';
import { exportExcel } from '../../../redux/actions/financialReportActions.js';
import PropTypes from 'prop-types';
import { isReviewer } from '../../../utils/helpers';
import Spinner from '../../Develop/Spinner';
import { withRouter } from 'react-router-dom';

class ExcelExportButton extends React.Component {
    constructor(props) {
        super(props);
        this.exportFile = this.exportFile.bind(this)
        this.state = {
            activeLink: 1,
        };
    }

    getCorrectedTimeZoneSpecificMilliSec(date) {
        let timeZoneOffSetInMilliSec = date.getTimezoneOffset() * 60 * 1000;
        return date.getTime() - timeZoneOffSetInMilliSec;
    }

    getCorrectedStartDateInMilliSec(startDateInMilliSec) {
        let startDate = new Date(startDateInMilliSec);
        startDate.setHours(0, 0, 0, 0);
        return this.getCorrectedTimeZoneSpecificMilliSec(startDate);
    }

    getCorrectedEndDateInMilliSec(endDateInMilliSec) {
        let endDate = new Date(endDateInMilliSec);
        endDate.setHours(23, 59, 59, 0);
        return this.getCorrectedTimeZoneSpecificMilliSec(endDate);
    }

    exportFile(e) {
        let url;
        const { location } = this.props;
        if (location.pathname === '/financial_reports') {
          url = 'finance/downloadReport'
        } else if (location.pathname === '/case_reports') {
          url = 'case/downloadReport'
        }
        e.preventDefault();
        const { data } = this.props;
        const userRole = this.props.userRole || 'UNKNOWN';
        const canFilter = !(userRole === 'ROLE_REVIEWER');
        const reviewers = data.reviewersOption === 'All' ? [] : data.reviewers.map((reviewer) => reviewer.value);
        const reviewerNames = data.reviewersOption === 'All' ? [] : data.reviewers.map((reviewer) => reviewer.label);
        const companies = data.companiesOption === 'All' ? this.props.allCompanies : data.companies.map((company) => company.value);
        const companyNames = data.companiesOption === 'All' ? this.props.allCompanies : data.companies.map((company) => company.label);
        const dataForSend = {
            userRole: null, // TODO ? : userRole
            fromDate: data.startDate ? this.getCorrectedStartDateInMilliSec(data.startDate) : 0,
            toDate: data.endDate ? this.getCorrectedEndDateInMilliSec(data.endDate) : 0,
            reviewers: canFilter ? reviewers : null,
            reviewerNames: canFilter ? reviewerNames : null,
            companies: canFilter ? companies : null,
            companyNames: canFilter ? companyNames : null,
        };
        this.props.exportByExcel(dataForSend, { ...this.props.data }, url);
    }

    render() {
        const { data } = this.props;
        const startDate = new Date(data.startDate).getTime();
        const endDate = new Date(data.endDate).getTime();
        const reviewers = data.reviewersOption === 'All' ? [] : data.reviewers;
        const companies = data.companiesOption === 'All' ? this.props.allCompanies : data.companies;
        return (
            <span className={'margin-left-5'}>
                <button
                    className='btn btn-flat btn-primary'
                    style={{ width: '100px', display: 'inline-flex', justifyContent: 'center' }}
                    onClick={this.exportFile}
                    disabled={
                        data.isReportLoading ||
                        (data.reviewersOption !== 'All' && reviewers.length === 0) ||
                        (data.companiesOption !== 'All' && companies.length === 0) ||
                        startDate > endDate || !startDate || !endDate
                    }
                >
                    <span>{'Generate'}</span>&nbsp;{data.isReportLoading && <Spinner/>}
                </button>
            </span>
        );
    }

    static propTypes = {
        userRole: PropTypes.string,
        userCode: PropTypes.string,
        userCompanyCode: PropTypes.string,
        exportByExcel: PropTypes.func,
        data: PropTypes.object,
        users: PropTypes.array,
        companies: PropTypes.array,
    }

    static defaultProps = {
        userRole: null,
        data: {},
        users: [],
        companies: [],
    }
}

const mapStateToProps = (state) => {
    return {
        userRole: state.app.auth.user.roleCode,
        userCode: state.app.auth.user.code,
        userCompanyCode: state.app.auth.user.companyCode,
        data: state.app.financialReport,
        users: state.app.users.users
            .filter((item) => isReviewer(item.roleCode))
            // .map(type => ({value: type.code, label: type.lastName + " " + type.lastName})),
            .map(user => user.code),
        companies: state.app.companies.data
            // .map(type => ({value: type.code, label: type.fullName})),
            .map(company => company.code),
        allCompanies: state.app.financialReport.companiesMinimalData
          .map(company => company.id)    
    };
}

const mapDispatchToProps = dispatch => {
    return {
        exportByExcel: (data, state, url) => dispatch(exportExcel(data, state, url))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExcelExportButton))
