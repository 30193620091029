import React from 'react';
import PermissionManageTable from './PermissionManageTable';
import Box from '../../components/Box';
import BoxHeader from '../../components/BoxHeader';
import BoxFooter from '../../components/BoxFooter';

const PrivilegesManagePage = () => (
    <div className='container-fluid'
         style={{marginTop: '15px', marginBottom: '10px', padding: '10px', paddingBottom: '20px'}}>
        <Box color="blue" classes="box-profile-first-row-height-show">
            <BoxHeader title="Permissions" color="blue"/>
            <PermissionManageTable/>
            <BoxFooter/>
        </Box>
    </div>
);

export default PrivilegesManagePage;
