import React from 'react'

class BoxBody extends React.Component {
    render() {
        return (
            <div className={"box-body " + this.props.classes} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}

export default BoxBody
