import React from "react";
import { connect } from 'react-redux';
import SubmitButton from '../../../components/SubmitButton'
import PropTypes from "prop-types";
import { editFacility, facilityEditField } from '../../../redux/actions/facilitiesActions'
import { onSort } from "../../../redux/actions/gridActions";

class SaveEntity extends React.Component {

    onLink = () => {
        const { editField, physicianCodes, selectedPhysicianCodes } = this.props;
        let physiciansSet = new Set([...physicianCodes, ...selectedPhysicianCodes]);
        editField('physicianCodes', Array.from(physiciansSet));
        this.props.sort('linked', 'desc');
    }

    onUnlink = () => {
        const { editField, physicianCodes, selectedPhysicianCodes } = this.props;
        const physicians = physicianCodes.filter(item => selectedPhysicianCodes.indexOf(item) === -1)
        editField('physicianCodes', physicians);
        this.props.sort('linked', 'asc');
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <span>
                <SubmitButton color='grey' onSubmit={this.onLink} showTooltip={false} title='Link'/>
                <SubmitButton color='grey' onSubmit={this.onUnlink} showTooltip={false} title='Unlink'/>
            </span>
        )
    }
}

SaveEntity.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => ({
    physicianCodes: state.app.facilities.editedFacility.physicianCodes || [],
    selectedPhysicianCodes: state.app.facilities.editedFacility.selectedPhysicianCodes,
    isEditable: state.app.users.my_permissions.includes('MODIFY_FACILITIES'),
})

const mapDispatchToProps = dispatch => ({
    saveFacilityProfile: data => dispatch(editFacility(data)),
    editField: (field, value) => dispatch(facilityEditField(field, value)),
    sort: (value, dir) => dispatch(onSort(value, dir))
})

export default connect(mapStateToProps, mapDispatchToProps)(SaveEntity)
