import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { toolTipId } from '../../utils/helpers';
import DataTableContext from './DataTableContext';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DataTableControlButtons = ({ onResetSort, onToggleFilter }) => {
  const { variant } = useContext(DataTableContext);

  return (
    <div className="datatable__control-buttons">
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id={toolTipId('dt-toggle-filters')}>Toggle filters</Tooltip>}
      >
        <button
          className={
            `btn btn-sm resizable-button tooltip-button btn-` + variant
          }
          onClick={onToggleFilter}
        >
          <i className="fa fa-filter" />
        </button>
      </OverlayTrigger>

      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id={toolTipId('dt-reset-sorting')}>Reset sorting</Tooltip>}
      >
        <button
          className={
            `btn btn-sm resizable-button tooltip-button btn-` + variant
          }
          onClick={onResetSort}
        >
          <i className="fa fa-sort" />
        </button>
      </OverlayTrigger>
    </div>
  );
};

DataTableControlButtons.propTypes = {
  onResetSort: PropTypes.func.isRequired,
  onToggleFilter: PropTypes.func.isRequired,
};

export default DataTableControlButtons;
