import { createSlice } from '@reduxjs/toolkit';

const caseCommentThreadsSlice = createSlice({
    name: 'caseCommentsThreads',
    initialState: {
        list: [],
        comment: '',
        expanded: false,
        fetching: false,
        fetched: true,
        saving: false,
        dirty: false,
        adding: false,
    },
    reducers: {
        resetComment: (state) => {
            state.list = [];
            state.comment = '';
        },
        toggleExpanded: (state) => {
            state.expanded = !state.expanded;
        },
        fetchingCommentThreads: (state) => {
            state.comment = '';
            if (!state.adding) {
                state.expanded = false;
            }
            state.adding = false;
            state.fetching = true;
            state.fetched = false;
            state.saving = false;
            state.dirty = false;
        },
        fetchThreadListSuccess: (state, { payload }) => {
            state.list = payload.data || [];
            state.list.sort((a, b) => {
                return new Date(b.updated).getTime() - new Date(a.updated).getTime();
            });
            state.fetching = false;
            state.fetched = true;
            state.saving = false;
            state.dirty = false;
            state.expanded = state.list.length > 0;
        },
        fetchThreadListError: (state, { payload }) => {
            state.fetching = false;
            state.fetched = true;
            state.saving = false;
            state.error = payload;
        },
        editComment: (state, { payload }) => {
            state.comment = payload;
            state.dirty = false;
        },
        addingComment: (state) => {
            state.saving = !!state.comment;
            state.dirty = true;
            state.adding = true;
        },
        addedComment: (state) => {
            state.comment = '';
            state.saving = false;
            state.dirty = false;
        },
    },
});

export const caseCommentThreadsReducer = caseCommentThreadsSlice.reducer;

export const {
    resetComment,
    toggleExpanded,
    fetchingCommentThreads,
    fetchThreadListSuccess,
    fetchThreadListError,
    editComment,
    addingComment,
    addedComment,
} = caseCommentThreadsSlice.actions;

export default caseCommentThreadsSlice;
