
const translates = [
    {
        "id": 1,
        "group": "common",
        "key": "core.login",
        "en": "Login",
        "sv": "Logga in"
    },{
        "id": 2,
        "group": "common",
        "key": "core.logout",
        "en": "Logout",
        "sv": "Logga ut"
    },{
        "id": 3,
        "group": "common",
        "key": "menu.home",
        "en": "Home",
        "sv": "Hem"
    },{
        "id": 4,
        "group": "common",
        "key": "menu.flagged",
        "en": "Flagged",
        "sv": "Flaggad"
    },{
        "id": 5,
        "group": "common",
        "key": "menu.draft",
        "en": "Draft",
        "sv": "Utkast"
    },{
        "id": 6,
        "group": "common",
        "key": "menu.dispatch",
        "en": "Dispatch",
        "sv": "För utskick"
    },{
        "id": 7,
        "group": "common",
        "key": "menu.review",
        "en": "Review",
        "sv": "Granskning"
    },{
        "id": 8,
        "group": "common",
        "key": "menu.qa",
        "en": "QA",
        "sv": "KG"
    },{
        "id": 9,
        "group": "common",
        "key": "menu.in_progress",
        "en": "In Progress",
        "sv": "Under behandling"
    },{
        "id": 10,
        "group": "common",
        "key": "menu.closed",
        "en": "Closed",
        "sv": "Avslutat"
    },{
        "id": 11,
        "group": "common",
        "key": "menu.search",
        "en": "Search",
        "sv": "Sök"
    },{
        "id": 12,
        "group": "common",
        "key": "menu.help_desk",
        "en": "Help Desk",
        "sv": "Help Desk"
    },{
        "id": 13,
        "group": "common",
        "key": "menu.submitters",
        "en": "Submitters",
        "sv": ""
    },{
        "id": 14,
        "group": "common",
        "key": "menu.assignments",
        "en": "Assignments",
        "sv": "Uppdrag"
    },{
        "id": 15,
        "group": "common",
        "key": "menu.physicians",
        "en": "Physicians",
        "sv": "Läkare"
    },{
        "id": 16,
        "group": "common",
        "key": "menu.facilities",
        "en": "Facilities",
        "sv": "Vårdinrättning"
    },{
        "id": 17,
        "group": "common",
        "key": "menu.specialties",
        "en": "Specialties",
        "sv": "Specialiteter"
    },{
        "id": 18,
        "group": "common",
        "key": "menu.body_parts",
        "en": "Body Parts",
        "sv": "Kroppsdelar"
    },{
        "id": 19,
        "group": "common",
        "key": "users.first_name",
        "en": "First Name",
        "sv": "Förnamn"
    },{
        "id": 20,
        "group": "common",
        "key": "users.middle_name",
        "en": "Middle Name",
        "sv": "Mellannamn"
    },{
        "id": 21,
        "group": "common",
        "key": "users.last_name",
        "en": "Last Name",
        "sv": "Efternamn"
    },{
        "id": 22,
        "group": "common",
        "key": "form.email",
        "en": "Email",
        "sv": "Email"
    },{
        "id": 23,
        "group": "common",
        "key": "form.phone_number",
        "en": "Phone Number",
        "sv": "Telefonnummer"
    },{
        "id": 24,
        "group": "common",
        "key": "users.role",
        "en": "Role",
        "sv": "Roll"
    },{
        "id": 25,
        "group": "common",
        "key": "form.status",
        "en": "Status",
        "sv": "Status"
    },{
        "id": 26,
        "group": "common",
        "key": "form.active",
        "en": "Active",
        "sv": "Aktiv"
    },{
        "id": 27,
        "group": "common",
        "key": "form.inactive",
        "en": "Inactive",
        "sv": "Inaktiv"
    },{
        "id": 28,
        "group": "common",
        "key": "form.search",
        "en": "Search",
        "sv": "Sök"
    },{
        "id": 29,
        "group": "common",
        "key": "form.select",
        "en": "Select",
        "sv": "Välj"
    },{
        "id": 30,
        "group": "common",
        "key": "form.enter",
        "en": "Enter",
        "sv": "Ange"
    },{
        "id": 31,
        "group": "common",
        "key": "users.company",
        "en": "Company",
        "sv": "Företag"
    },{
        "id": 32,
        "group": "common",
        "key": "users.add_user",
        "en": "Add Submitter",
        "sv": "Lägg till användare"
    },{
        "id": 33,
        "group": "common",
        "key": "users.edit_user",
        "en": "Edit User",
        "sv": "Redigera användare"
    },{
        "id": 34,
        "group": "common",
        "key": "form.notification_email",
        "en": "Notification Email",
        "sv": "Bekräftelsemail"
    },{
        "id": 35,
        "group": "common",
        "key": "users.alternative_phone_number",
        "en": "Alternative Phone Number",
        "sv": "Alternativ Telefonnummer"
    },{
        "id": 36,
        "group": "common",
        "key": "users.address1",
        "en": "Address 1",
        "sv": ""
    },{
        "id": 37,
        "group": "common",
        "key": "users.address2",
        "en": "Address 2",
        "sv": ""
    },{
        "id": 38,
        "group": "common",
        "key": "form.country",
        "en": "Country",
        "sv": "Land"
    },{
        "id": 39,
        "group": "common",
        "key": "form.state",
        "en": "State",
        "sv": "Kommun"
    },{
        "id": 40,
        "group": "common",
        "key": "form.city",
        "en": "City",
        "sv": "Stad"
    },{
        "id": 41,
        "group": "common",
        "key": "form.postal_code",
        "en": "Postal Code",
        "sv": "Postnummer"
    },{
        "id": 41,
        "group": "common",
        "key": "form.default_locale",
        "en": "Default Locale",
        "sv": ""
    },{
        "id": 42,
        "group": "common",
        "key": "form.notes",
        "en": "Notes",
        "sv": "Notering"
    },{
        "id": 43,
        "group": "common",
        "key": "menu.company",
        "en": "Company",
        "sv": "Företag"
    },{
        "id": 44,
        "group": "common",
        "key": "menu.medical_services",
        "en": "Medical Services",
        "sv": ""
    },{
        "id": 45,
        "group": "common",
        "key": "form.procedure",
        "en": "Procedure",
        "sv": ""
    },{
        "id": 46,
        "group": "common",
        "key": "form.diagnoses",
        "en": "Diagnoses",
        "sv": "Diagnos"
    },{
        "id": 47,
        "group": "common",
        "key": "form.locale",
        "en": "Locale",
        "sv": "Lokal"
    },{
        "id": 48,
        "group": "common",
        "key": "form.time_zone",
        "en": "Time Zone",
        "sv": "Tidszon"
    },{
        "id": 49,
        "group": "common",
        "key": "users.add_staff",
        "en": "Add Staff",
        "sv": ""
    },{
        "id": 50,
        "group": "common",
        "key": "users.add_reviewer",
        "en": "Add Reviewer",
        "sv": ""
    },{
        "id": 51,
        "group": "common",
        "key": "client.add_client",
        "en": "Add Client",
        "sv": "Lägg till Klient"
    },{
        "id": 52,
        "group": "common",
        "key": "cases.add_case_type",
        "en": "Add Case Type",
        "sv": "Lägg till Ärendetyp"
    },{
        "id": 53,
        "group": "common",
        "key": "cases.add_patient",
        "en": "Add Patient",
        "sv": ""
    },{
        "id": 54,
        "group": "common",
        "key": "client.add_medical_system_code",
        "en": "Add Medical System Code",
        "sv": ""
    },{
        "id": 55,
        "group": "common",
        "key": "client.add_service",
        "en": "Add Service",
        "sv": ""
    },{
        "id": 56,
        "group": "common",
        "key": "client.add_diagnose",
        "en": "Add Diagnose",
        "sv": ""
    },{
        "id": 57,
        "group": "common",
        "key": "cases.add_section",
        "en": "Add Section",
        "sv": ""
    },{
        "id": 58,
        "group": "common",
        "key": "cases.add_field",
        "en": "Add Field",
        "sv": ""
    },{
        "id": 59,
        "group": "common",
        "key": "form.edit_medical_system_code",
        "en": "Edit Medical System Code",
        "sv": ""
    },{
        "id": 60,
        "group": "common",
        "key": "form.edit_service",
        "en": "Edit Service",
        "sv": ""
    },{
        "id": 61,
        "group": "common",
        "key": "form.edit_diagnose",
        "en": "Edit Diagnose",
        "sv": ""
    },{
        "id": 62,
        "group": "common",
        "key": "form.name",
        "en": "Name",
        "sv": ""
    },{
        "id": 63,
        "group": "common",
        "key": "form.full_name",
        "en": "Full Name",
        "sv": ""
    },{
        "id": 64,
        "group": "common",
        "key": "form.countries",
        "en": "Countries",
        "sv": ""
    },{
        "id": 65,
        "group": "common",
        "key": "form.country_code",
        "en": "Country Code",
        "sv": ""
    },{
        "id": 66,
        "group": "common",
        "key": "form.specialties",
        "en": "Specialties",
        "sv": "Specialiteter"
    },{
        "id": 67,
        "group": "common",
        "key": "form.add_country",
        "en": "Add Country",
        "sv": ""
    },{
        "id": 68,
        "group": "common",
        "key": "form.add_specialty",
        "en": "Add Specialty",
        "sv": ""
    },{
        "id": 69,
        "group": "common",
        "key": "form.body_part_codes",
        "en": "Body Parts",
        "sv": ""
    },{
        "id": 70,
        "group": "common",
        "key": "form.primary_specialty",
        "en": "Primary Specialty",
        "sv": ""
    },{
        "id": 71,
        "group": "common",
        "key": "form.body_parts_required",
        "en": "Body parts required",
        "sv": ""
    },{
        "id": 72,
        "group": "common",
        "key": "form.specialty",
        "en": "Specialty",
        "sv": ""
    },{
        "id": 73,
        "group": "common",
        "key": "message.specialty_success",
        "en": "Specialty successfully updated",
        "sv": ""
    },{
        "id": 74,
        "group": "common",
        "key": "form.country",
        "en": "Country",
        "sv": ""
    },{
        "id": 75,
        "group": "common",
        "key": "message.country_success",
        "en": "Country successfully updated",
        "sv": ""
    },{
        "id": 76,
        "group": "common",
        "key": "message.facility_type_success",
        "en": "Facility Type successfully updated",
        "sv": ""
    },{
        "id": 77,
        "group": "common",
        "key": "message.facility_success",
        "en": "Facility successfully updated",
        "sv": ""
    },{
        "id": 78,
        "group": "common",
        "key": "message.physician_success",
        "en": "Physician successfully updated",
        "sv": ""
    },{
        "id": 79,
        "group": "common",
        "key": "form.coding_system",
        "en": "Coding System",
        "sv": ""
    },{
        "id": 80,
        "group": "common",
        "key": "form.medical_coding_systems",
        "en": "Medical Coding Systems",
        "sv": ""
    },{
        "id": 81,
        "group": "common",
        "key": "form.services",
        "en": "Services",
        "sv": ""
    },{
        "id": 82,
        "group": "common",
        "key": "form.new_service",
        "en": "New Services",
        "sv": ""
    },{
        "id": 83,
        "group": "common",
        "key": "form.new_diagnose",
        "en": "New Diagnose",
        "sv": ""
    },{
        "id": 84,
        "group": "common",
        "key": "message.service_success",
        "en": "Service successfully updated",
        "sv": ""
    },{
        "id": 85,
        "group": "common",
        "key": "message.new_service_success",
        "en": "Service successfully created",
        "sv": ""
    },{
        "id": 86,
        "group": "common",
        "key": "message.diagnose_success",
        "en": "Diagnose successfully updated",
        "sv": ""
    },{
        "id": 87,
        "group": "common",
        "key": "message.new_diagnose_success",
        "en": "Diagnose successfully created",
        "sv": ""
    },{
        "id": 88,
        "group": "common",
        "key": "message.coding_system_success",
        "en": "Coding system successfully updated",
        "sv": ""
    },{
        "id": 89,
        "group": "common",
        "key": "message.new_coding_system_success",
        "en": "Coding system successfully created",
        "sv": ""
    },{
        "id": 90,
        "group": "common",
        "key": "message.code_success",
        "en": "Code successfully updated",
        "sv": ""
    },{
        "id": 91,
        "group": "common",
        "key": "message.only_for_services",
        "en": "This code can be created only for services",
        "sv": ""
    },{
        "id": 92,
        "group": "common",
        "key": "message.only_for_diagnoses",
        "en": "This code can be created only for diagnoses",
        "sv": ""
    },{
        "id": 93,
        "group": "common",
        "key": "case.draft",
        "en": "Draft",
        "sv": "Utkast"
    },{
        "id": 94,
        "group": "common",
        "key": "case.dispatch",
        "en": "Dispatch",
        "sv": "För utskick"
    },{
        "id": 95,
        "group": "common",
        "key": "case.review",
        "en": "Review",
        "sv": "Granskning"
    },{
        "id": 96,
        "group": "common",
        "key": "case.qa",
        "en": "QA",
        "sv": "KG"
    },{
        "id": 97,
        "group": "common",
        "key": "case.closed",
        "en": "Closed",
        "sv": "Avslutat"
    },{
        "id": 98,
        "group": "common",
        "key": "case.post_reviewer_qa",
        "en": "Post Review QA",
        "sv": ""
    },{
        "id": 98,
        "group": "common",
        "key": "case.closed_this_month",
        "en": "Closed This Month",
        "sv": ""
    },{
        "id": 98,
        "group": "common",
        "key": "case.closed_last_month",
        "en": "Closed Last Month",
        "sv": ""
    },{
        "id": 99,
        "group": "common",
        "key": "case.add_case_file_template",
        "en": "Add New Case File Template",
        "sv": "Some swedish expression(???)"
    },{
        "id": 100,
        "group": "common",
        "key": "form.fieldKey",
        "en": "Field key",
        "sv": ""
      }


]


const getTranslatesItems = group => translates.filter( item => item.group === group)
export default getTranslatesItems
