import React from 'react';
import All from './Stages/All';
import Draft from './Stages/Draft';
import Dispatch from './Stages/Dispatch';
import Review from './Stages/Review';
import Qa from './Stages/Qa';
import Closed from './Stages/Closed';
import Available from './Stages/Available';
import Accepted from './Stages/Accepted';
import InProcess from './Stages/InProcess';
import ClosedThisMonth from './Stages/ClosedThisMonth';
import ClosedLastMonth from './Stages/ClosedLastMonth';
import Complete from './Stages/Complete';
import Flagged from './Stages/Flagged';
import { PatientCaseContext } from './PatientCaseContext';
import PageContainer from 'components/PageContainer/PageContainer';
import useSelectedCases from './hooks/useSelectedCases';

const grids = {
  all_cases: All,
  draft: Draft,
  dispatch: Dispatch,
  review: Review,
  qa: Qa,
  flagged: Flagged,
  closed: Closed,
  available: Available,
  accepted: Accepted,
  in_progress: InProcess,
  complete: Complete,
  closed_cases: Closed,
  closed_cases_this_month: ClosedThisMonth,
  closed_cases_last_month: ClosedLastMonth,
};

// eslint-disable-next-line react/prop-types
const List = ({ location: { pathname }, history, ...props }) => {
  const Grid = grids[pathname.replace('/', '')];

  const selectedCases = useSelectedCases();

  return (
    <PageContainer>
      <PatientCaseContext.Provider
        value={{
          ...selectedCases,
          history,
        }}
      >
        <Grid {...props} forceShow />
      </PatientCaseContext.Provider>
    </PageContainer>
  );
};

export default List;
