import React, {Component} from "react";
import {connect} from 'react-redux'
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import FormField from '../../components/FormField'
import {userEditField} from '../../redux/action-creators/users'
import PropTypes from "prop-types";
import ContactInfoSection from "../../components/Sections/ContactInfoSection";
import AddressInfoSection from "../../components/Sections/AddressInfoSection";
import {getSelectFieldValue} from "../../utils/helpers";


class ReviewerEditProfile extends Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({showSuccess: false});
        if(e.target.name.toLowerCase().includes('email')){
            this.props.userEditField(e.target.name, e.target.value.replace(/\s+/g, ''));
        }
        else{
            this.props.userEditField(e.target.name, e.target.value);
        }
            
    }

    onChangeState = (stateCode) => {
        this.props.userEditField('stateCode', stateCode)
    };

    onChangeCountry = (countryCode) => {
        this.props.userEditField('countryCode', countryCode);
        if (!countryCode || getSelectFieldValue(countryCode) + '' !== 'US') {
            this.props.userEditField('stateCode', null);
            this.props.userEditField('routingNum', '');
            this.props.userEditField('accountNum', '');
        }
    };

    render() {
        return (
            <Box color="blue" classes="box-profile-first-row-height-show">
                <BoxHeader title={`Reviewer - ${this.props.profile.firstName} ${this.props.profile.lastName}`}
                           color="blue" icon="fa-user"/>
                <BoxBody>
                    <form>
                        <div className="bordered form-group">
                            <div className="form-group">
                                <div className="row">
                                    <FormField
                                        md="12"
                                        type='label_field'
                                        label="Personal Information"
                                        className='margin-bottom-15 fontWeight800'
                                        style={{fontSize: '1.1em'}}
                                    />
                                    <FormField
                                        md="4"
                                        label={this.context.translate('common.users.first_name')}
                                        id={this.context.translate('common.users.first_name')}
                                        className="form-control"
                                        required
                                        valids="required"
                                        validator={this.props.validator}
                                        name="firstName"
                                        value={this.props.profile.firstName}
                                        onChange={this.onChange}
                                        onBlur={this.props.dispatchValidStatus}
                                    />

                                    <FormField
                                        md="4"
                                        label={this.context.translate('common.users.middle_name')}
                                        id={this.context.translate('common.users.middle_name')}
                                        className="form-control"
                                        name="middleName"
                                        value={this.props.profile.middleName}
                                        onChange={this.onChange}
                                        valids="alpha"
                                        validator={this.props.validator}
                                        onBlur={this.props.dispatchValidStatus}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">

                                    <FormField
                                        md="4"
                                        label={this.context.translate('common.users.last_name')}
                                        id={this.context.translate('common.users.last_name')}
                                        className="form-control"
                                        required
                                        valids="required"
                                        validator={this.props.validator}
                                        name="lastName"
                                        value={this.props.profile.lastName}
                                        onChange={this.onChange}
                                        onBlur={this.props.dispatchValidStatus}
                                    />

                                    <FormField
                                        md="4"
                                        label={this.context.translate('common.users.title')}
                                        className="form-control"
                                        name="title"
                                        value={this.props.profile.title}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <ContactInfoSection
                            validator={this.props.validator}
                            dispatchValidState={this.props.dispatchValidStatus}
                            onChange={this.onChange}
                            email={this.props.profile.email}
                            notificationEmail={this.props.profile.notificationEmail}
                            phoneNumber={this.props.profile.phoneNumber}
                            alternativePhoneNumber={this.props.profile.alternativePhoneNumber}
                        />

                        <AddressInfoSection
                            validator={this.props.validator}
                            dispatchValidStatus={this.props.dispatchValidStatus}
                            onChange={this.onChange}
                            onChangeCountry={this.onChangeCountry}
                            onChangeState={this.onChangeState}
                            address1={this.props.profile.address1}
                            address2={this.props.profile.address2}
                            city={this.props.profile.city}
                            countryCode={this.props.profile.countryCode}
                            stateCode={this.props.profile.stateCode}
                            postalCode={this.props.profile.postalCode}
                            noBorder
                        />

                    </form>
                </BoxBody>
                <BoxFooter>

                </BoxFooter>
            </Box>
        );
    }
}

ReviewerEditProfile.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        profile: state.app.users.editedUser,
    }
};


const mapDispatchToProps = dispatch => {
    return {
        userEditField: (field, value) => {
            dispatch(userEditField(field, value))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewerEditProfile);
