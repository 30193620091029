export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            reject('Error: ', error);
        };
    });
};

export const dataUrlToFile = (dataUrl, fileName, type) =>
    new Promise((resolve, reject) => {
        fetch(dataUrl)
            .then((res) => {
                res.blob().then((blob) => resolve(new File([blob], fileName, { type })));
            })
            .catch(() => {
                reject();
            });
    });

export const getFileContentAsText = (file) => {
    if (!file) return '{}';
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            reject('Error: ', error);
        };
        reader.readAsText(file);
    });
}
