import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SystemMessage from '../../components/SystemMessage';
import LoadingIndicator from '../../components/LoadingIndicator';
import ColoredBox from '../../components/ColoredBox/ColoredBox';
import { Col, Row } from 'react-bootstrap';
import NotificationTemplateForm from './NotificationTemplateForm';
import { FORM_TYPES } from './NotificationTemplateForm';
import PreventTransitionPromptNew from 'components/PreventTransitionPromptNew';
import UpdateButtons from '../../components/UpdateButtons';
import { transformNotificationEntityToSubmit } from 'redux/pages/notification-template/utils';
import './styles.css';
import {
  fetchEntityLoading as getNotificationTemplate,
  updateEntityLoading as updateNotificationTemplate,
} from 'redux/pages/notification-template/notificationTemplateSlice';

const NotificationTemplateEditPage = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();

  const { error, isFormChanged, entityOriginal, isLoading, isUpdateSuccess, isFormValid } = useSelector((state) => state.app.notificationTemplates);

  const { name: originalName } = entityOriginal;

  const formRef = useRef();

  useEffect(() => {
    dispatch(getNotificationTemplate({ id }));
  }, [dispatch, id]);

  const saveChanges = (entity, _event, { closeAfterSave }) => {
    dispatch(
      updateNotificationTemplate({
        ...transformNotificationEntityToSubmit(entity),
        closeAfterSave,
      }),
    );
  };

  const submitForm = (closeAfterSave = true) => {
    formRef.current.submit({ closeAfterSave });
  };

  return (
    <section className="content" id="notificationEditPage">
      <PreventTransitionPromptNew when={isFormChanged} saveChanges={submitForm} isFormValid={isFormValid} />
      <SystemMessage type="error" message={error} shown={!isLoading && error} />

      <SystemMessage
        shown={isUpdateSuccess && isFormValid && !isLoading}
        type="success"
        message="Notification Template has been updated successfully."
      />
      <UpdateButtons isFormChanged={isFormChanged} isFormValid={isFormValid} onSave={submitForm} />
      <Row>
        <Col lg={12}>
          <ColoredBox
            variant="success"
            title={`Notification Template - ${originalName}`}
            bodyClasses="pd-20"
            headerContent={isLoading && <LoadingIndicator type="form" />}
          >
            <NotificationTemplateForm
              type={FORM_TYPES.edit}
              onSubmit={saveChanges}
              ref={formRef}
            />
          </ColoredBox>
        </Col>
      </Row>
    </section>
  );
};

NotificationTemplateEditPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

NotificationTemplateEditPage.contextTypes = { color: PropTypes.string };

export default NotificationTemplateEditPage;
