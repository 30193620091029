import SubmitButton from 'components/Form/SubmitButton';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetEntity } from '../../redux/pages/notification-template/notificationTemplateSlice';
import NotificationTemplateForm, {
  FORM_TYPES,
} from './NotificationTemplateForm';
import './styles.css';
import { createEntityLoading as createNotificationTemplate } from 'redux/pages/notification-template/notificationTemplateSlice';

const NotificationTemplateCreateForm = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetEntity());
  }, [dispatch]);

  const onSubmit = (entity) => {
    dispatch(
      createNotificationTemplate({
        ...entity,
        active: true,
      }),
    );
  };

  return (
    <NotificationTemplateForm
      className="modal-content custom-modal-content notification-modal"
      onSubmit={onSubmit}
      type={FORM_TYPES.new}
    >
      <SubmitButton title="Add Notification Template" />
    </NotificationTemplateForm>
  );
};

export default NotificationTemplateCreateForm;
