import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import SimpleReactValidator from '../../utils/simple-react-validator';
import LoadingIndicator from '../../components/LoadingIndicator';
import EditBillingGroup from './EditBillingGroup';
import ClientList from './ClientList';
import { isEntityValid, setCurrentTab } from '../../redux/actions/BasicEntityActions';
import { getFullBillingGroup, updateBillingGroup } from '../../redux/actions/billingActions';
import { closeCreateModal } from '../../redux/action-creators/users';
import SystemMessage from '../../components/SystemMessage';
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';
import { getObjKey } from '../../utils/helpers'

class ManageBillingGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This Billing Group already exists in the system.',
                rule: function (val) {
                    return !props.BGNames.includes(val);
                }
            }
        });
        this.validator.showMessages();
    }

    componentDidMount() {
        this.props.closeCreateModal();
        this.props.getFullBillingGroup(this.props.match.params.id)
            .then(this.dispatchValidState);
        this.props.setCurrentTab('billing_group');
    }

    componentWillUnmount() {
        this.props.setCurrentTab('billing_group')
    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.BGNames) !== JSON.stringify(nextProps.BGNames)) {
            this.validator = new SimpleReactValidator({
                unique: {
                    message: 'This Billing Group already exists in the system.',
                    rule: function (val) {
                        return !nextProps.BGNames.includes(val);
                    }
                }
            });
            this.validator.showMessages();
        }
    }

    handleSelect = (key) => {
        this.props.setCurrentTab(key)
    };

    dispatchValidState = () => {
        this.props.isEntityValid(this.validator.allValid());
        this.forceUpdate();
    };

    onSave = () => {
        const { group } = this.props;
        const data = {
            ...group,
            billingTiers: group.billingTiers.map(getObjKey('code')),
            companies: group.companies.map(getObjKey('code')),
        };

        return this.props.updateGroup(data);
    };

    render() {
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[ field ],
            tab: 'template'
        }));
        return (
            <section className="content user-profile-content">
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <div className='col-md-12'>
                    <Tabs activeKey={this.props.currentTab}
                          defaultActiveKey={this.props.currentTab ? this.props.currentTab : 'field_instances'}
                          animation={true} id="noanim-tab-example" onSelect={this.handleSelect}>

                        <SystemMessage shown={this.props.editSuccess && this.props.isValid} type='success'
                                       message='Billing Group successfully updated' top='7em'/>
                        <SystemMessage shown={this.props.showValidMessages} type='error' message={this.props.error}
                                       validationMessages={errorMessages} goToTab={this.handleSelect} top='7em'/>
                        <PreventTransitionPrompt
                            when={this.props.isProfileChanged}
                            message='MyCustomDialogComponent'
                            entity='billing_group'
                            isFormValid={this.props.isValid}
                            saveChanges={this.onSave}
                            cancelAndDiscard={() => {
                                this.props.getFullBillingGroup(this.props.match.params.id)
                            }}
                        />

                        <Tab eventKey='billing_group' title="Billing Group">
                            <div className='row'>
                                <EditBillingGroup history={this.props.history} validator={this.validator}
                                                  dispatchValidState={this.dispatchValidState}/>
                            </div>
                        </Tab>

                        <Tab eventKey='clients' title="Clients">
                            <div className='row'>
                                <ClientList history={this.props.history}/>
                            </div>
                        </Tab>

                    </Tabs>
                </div>
            </section>
        )
    }

    static propTypes = {
        loading: PropTypes.bool,
        isProfileChanged: PropTypes.bool,
        editSuccess: PropTypes.bool,
        isValid: PropTypes.bool,
        showValidMessages: PropTypes.bool,
        currentTab: PropTypes.string,
        error: PropTypes.string,
        BGNames: PropTypes.array,
        history: PropTypes.object,
        match: PropTypes.object,
        group: PropTypes.object,
        getFullBillingGroup: PropTypes.func,
        setCurrentTab: PropTypes.func,
        closeCreateModal: PropTypes.func,
        isEntityValid: PropTypes.func,
        updateGroup: PropTypes.func,
    }
}

const mapStateToProps = (state) => {
    let BGNames = state.app.billing_group.data
        .filter((item) => item.code !== state.app.billing_group.originalBillingGroup.code)
        .map((item) => item.name);
    return {
        loading: state.app.billing_group.loading,
        error: state.app.billing_group.error,
        group: state.app.billing_group.editedBillingGroup,
        BGNames: BGNames,
        currentTab: state.app.billing_group.currentTab,
        isProfileChanged: state.app.billing_group.isProfileChanged,
        editSuccess: state.app.billing_group.editSuccess,
        isValid: state.app.billing_group.isValid,
        showValidMessages: state.app.billing_group.showValidMessages,
        currentPage: state.router.location.pathname
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFullBillingGroup: (code) => dispatch(getFullBillingGroup(code)),
        setCurrentTab: (tab) => dispatch(setCurrentTab(tab, 'billing_group')),
        closeCreateModal: () => dispatch(closeCreateModal()),
        isEntityValid: (valid) => dispatch(isEntityValid(valid, 'billing_group')),
        updateGroup: (data, closeAfterSave) => dispatch(updateBillingGroup(data, closeAfterSave))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageBillingGroup);
