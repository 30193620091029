export const getValidMedicalCodingSystemCodesArray = (codesArray) => {
    const arr = [];
    codesArray && codesArray.forEach((item) => {
        let val;
        if (typeof item === 'string') {
            val = item;
        } else if (typeof item === 'object' && item.value) {
            val = item.value;
        }
        arr.push(val);
    });
    return arr;
}
