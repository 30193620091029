import React from 'react';
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {deleteStageOwner} from "../../../redux/actions/cases/caseActions";
import {editCase} from "../../../redux/actions/cases/caseListActions";

class EditOwnerBtn extends React.Component {

    deleteOwner = (e) => {
        e.preventDefault();
        const {stage} = this.props;

        this.props.deleteStageOwner(stage)
    };

    render() {
        return (
            <button className="btn btn-default margin-left-5" onClick={this.deleteOwner}><i className="fa fa-times"> </i>
            </button>
        )
    }
}

const mapState = () => {
    return {}
};

const mapActions = dispatch => {
    return {
        updateCase: (data) => dispatch(editCase(data)),
        deleteStageOwner: (data) => dispatch(deleteStageOwner(data)),
    }
};

export default connect(mapState, mapActions)(withRouter(EditOwnerBtn));
