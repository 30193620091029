import { isNull, negate } from "lodash";
import { transformResponseToTableData } from "../../../../components/Datatable/dataTableUtils";

const formatList = (list) => {
    return list.filter(negate(isNull)).join(', ');
};

export const transformClientEditServicesDiagnosis = transformResponseToTableData((data) => {
    return {
        data: data.map((
            {
                code, name, specialityNames, bodyPartNames, isLinked
            }) => ({
            code: code,
            name: name,
            specialityNames: formatList(specialityNames),
            bodyPartNames: formatList(bodyPartNames),
            linked: isLinked ? 'Linked' : 'Unlinked',
        })),
    };
});
