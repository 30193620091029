import React from 'react';
import { closedCasesLastMonth } from 'redux/pages/patient-case/patientCaseColumns';
import closedLastMonthSlice from 'redux/pages/patient-case/slices/closedLastMonthSlice';
import PatientCaseGrid from '../Components/PatientCaseGrid';
import { useSelector } from 'react-redux';
import { isReviewer } from '../../../utils/helpers';

const columns = closedCasesLastMonth;

const ClosedLastMonth = (props) => {
  const { roleCode } = useSelector((state) => {
    return state.app.auth.user;
  });

  return <PatientCaseGrid columns={columns} slice={closedLastMonthSlice} clickable={!isReviewer(roleCode)} {...props} />;
};

export default ClosedLastMonth;
