import { createDatatableTemplateSlice } from '../../../components/Datatable/dataTableUtils';

const filteredReviewersSlice = createDatatableTemplateSlice({
    name: 'filteredReviewers',
    initialSettings: {
        sortBy: 'default_name',
    },
});

export const { loadData, dataLoaded, dataLoadError, changeSettings } = filteredReviewersSlice.actions;

export default filteredReviewersSlice;
