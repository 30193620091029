import apiRequest from './api-request'

export default {
	loadSelect(entityPath) {
		return (dispatch) => {
			apiRequest(dispatch, entityPath, "SELECT_INPUT");
		}
	}
	,
	updateValue(value) {
		return (dispatch) => {
			dispatch ({
				type: 'SELECT_INPUT_VALUE_UPDATE',
				value: value
			})
			// apiRequest(dispatch, value, "SELECT_INPUT_VALUE_UPDATE");
		}
	}

}