import React from 'react';
import {connect} from 'react-redux';
import FormField from '../../../components/FormField/index'
import {editSpecialty, getSpecialty, isAllValid, specialtyEditField} from '../../../redux/actions/specialtyActions'
import PropTypes from "prop-types";
import {getAll} from "../../../redux/actions/BasicEntityActions";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Box from "../../../components/Box";
import BoxHeader from "../../../components/BoxHeader";
import BoxBody from "../../../components/BoxBody";
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import SystemMessage from '../../../components/SystemMessage';
import FormValidateWrapper from '../../../containers/FormValidateWrapper';


class SpecialtyEditPage extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onChangeCode = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.onChangeBodyPartCodes = this.onChangeBodyPartCodes.bind(this);
    }

    componentDidMount() {
        this.props.getSpecialty(this.props.match.params.id)
        this.props.getAllBodyParts();
        this.props.dispatchValidState()
    }

    onChangeCheckBox(e) {
        this.props.specialtyEditField([e.target.name], e.target.checked)
    }

    onChange(e) {
        this.props.specialtyEditField(e.target.name, e.target.value)
    }

    onChangeCode(e) {
      this.props.specialtyEditField(e.target.name, e.target.value)
    }

    onChangeBodyPartCodes(bodyPartCodes) {
        this.props.specialtyEditField('bodyPartCodes', bodyPartCodes)
    }

    onSave = () => {
        let bodyPartCodes = this.props.specialty.bodyPartCodes.map((item) => item.value ? item.value : item);

        return this.props.saveSpecialty({
            ...this.props.specialty,
            active: this.props.specialty.active,
            code: this.props.specialty.code,
            bodyPartCodes: bodyPartCodes,
            names: this.props.specialty.names ? this.props.specialty.names : {'EN': ''}
        })
    }

    render() {
        const {isProfileChanged, editSpecialtySuccess, isEditable} = this.props;

        return (
            <div className="content" style={{marginTop: '10px'}}>
                <PreventTransitionPrompt
                    when={isProfileChanged}
                    message='MyCustomDialogComponent'
                    isFormValid={this.props.isValid}
                    entity='specialty'
                    saveChanges={this.onSave}
                    cancelAndDiscard={() => this.props.getSpecialty(this.props.match.params.id)}
                />

                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <SystemMessage shown={!isProfileChanged && editSpecialtySuccess} type='success' message='Specialty successfully updated'/>

                <Box color="green" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`${this.context.translate('common.form.specialty')} - ${this.props.specialty.name}`} color="green"
                               icon="fa-list-alt" classes="box-profile-first-row-height-show" />
                    <BoxBody>
                        <form style={{overflow: 'visible'}}
                              className='modal-content custom-modal-content'
                              onSubmit={this.Submit}>
                              
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                      md="12"
                                      label={this.context.translate('common.form.fieldKey')}
                                      id={this.context.translate('common.form.fieldKey')}
                                      required
                                      valids="required|unique"
                                      validator={this.props.validator}
                                      value={this.props.specialty.code}
                                      onChange={this.onChangeCode}
                                      name='code'
                                      onBlur={this.props.dispatchValidState}
                                      disabled={!isEditable}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        label={this.context.translate('common.form.name')}
                                        id={this.context.translate('common.form.name')}
                                        required
                                        valids="required|unique"
                                        validator={this.props.validator}
                                        value={this.props.specialty.name}
                                        onChange={this.onChange}
                                        name='name'
                                        onBlur={this.props.dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        xs="12"
                                        type="select"
                                        label="Body Parts"
                                        className="text-bg "
                                        searchable={true}
                                        multi={true}
                                        options={this.props.bodyParts}
                                        id="edit_body_parts"
                                        name="bodyPartCodes"
                                        value={this.props.specialty.bodyPartCodes}
                                        onChange={this.onChangeBodyPartCodes}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        type="checkbox"
                                        title=""
                                        label={this.context.translate('common.form.primary_specialty')}
                                        name='primarySpeciality'
                                        value={this.props.specialty.primarySpeciality}
                                        checked={this.props.specialty.primarySpeciality}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className='row'>
                                    <FormField
                                        md="12"
                                        type="checkbox"
                                        title={this.context.translate('common.form.status')}
                                        label={this.context.translate('common.form.active')}
                                        name='active'
                                        value={this.props.specialty.active}
                                        checked={this.props.specialty.active}
                                        onChange={this.onChangeCheckBox}
                                        className="checkbox"
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </form>
                    </BoxBody>
                </Box>
            </div>
        );
    }
}

SpecialtyEditPage.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    isLoading: state.app.specialties.loading,
    isEditable: state.app.users.my_permissions.includes('MODIFY_SPECIALTIES'),
    error: state.app.specialties.error,
    editSpecialtySuccess: state.app.specialties.editSpecialtySuccess,
    isProfileChanged: state.app.specialties.isProfileChanged,
    bodyParts: state.app.bodyParts.data
        .filter((item) => item.active)
        .map(item => ({
            value: item.code,
            label: item.name
        })),
    specialty: state.app.specialties.editedSpecialty,
    isValid: state.app.specialties.specialtyValid,
    specialties: state.app.specialties.data,

    //VALIDATION PROPS
    showValidMessages: state.app.specialties.showValidMessages,
    customRules: {
        unique: {
            message: 'This name already exists in the system.',
            rule: function (val) {
                let specialties_names = [];
                state.app.specialties.data.forEach((item) => {
                    if (item.code !== ownProps.match.params.id) {
                        specialties_names.push(item.name);
                    }
                });
                return !specialties_names.includes(val);
            }
        }
    },
    style: {marginLeft: '15px', marginRight: '15px'}
})

const mapDispatchToProps = dispatch => ({
    getSpecialty: code => dispatch(getSpecialty(code)),
    getAllBodyParts: () => dispatch(getAll('body_part')),
    specialtyEditField: (field, value) => dispatch(specialtyEditField(field, value)),
    saveSpecialty: (specialty) => dispatch(editSpecialty(specialty)),
    isAllValid: (valid) => dispatch(isAllValid(valid))
})

export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(SpecialtyEditPage))
