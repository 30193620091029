const defaultState = {
    loading: false,
    loadedCount: 0,
    data: [],
    error: '',
    lastLoadedTime: 0,
}

const countries = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_CURRENCY_LOADING':
            return { ...state, loading: true }
        case 'FETCH_CURRENCIES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            })

        case 'FETCH_CURRENCY_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            }
        case 'FILL_CURRENCY_LOCAL_STORAGE': {
            let items = {};
            for (let i = 0; i < state.data.length; i++) {
                items[ state.data[ i ].code ] = state.data[ i ].fullName;
            }
            localStorage.setItem('list_currency', JSON.stringify(items));
            return state;
        }
        case 'FETCH_CURRENCY_LOADED_COUNT':
            return { ...state, loadedCount: action.payload }

        case 'FETCH_CURRENCY_ERROR':
            return { ...state, error: action.payload, loading: false }
        default:
    }

    return state
}

export default countries
