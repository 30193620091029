import React from 'react';
import { connect } from 'react-redux';
import Box from '../../components/Box';
import BoxHeader from '../../components/BoxHeader';
import BoxBody from '../../components/BoxBody';
import FormField from '../../components/FormField';
import { DateTimePicker } from 'react-widgets';
import { getAllCompaniesMinimal, getAllReviewersMinimal, saveFilter, validationChange } from '../../redux/actions/financialReportActions';
import BoxFooter from '../../components/BoxFooter';
import SystemMessage from '../../components/SystemMessage';
import PropTypes from 'prop-types';
import Select from "react-select";
import classnames from "classnames";
import { withRouter } from 'react-router-dom';

class FinancialReportForm extends React.Component {

    selectPeriodOptions = [
        { value: 'Period', label: 'Period' },
        { value: 'Today', label: 'Today' },
        { value: 'Yesterday', label: 'Yesterday' },
        { value: 'This week', label: 'This week' },
        { value: 'Last week', label: 'Last week' },
        { value: 'This month', label: 'This month' },
        { value: 'Last month', label: 'Last month' },
        { value: '6 months', label: '6 months' }
    ]

    showReviewers = true;
    showCompanies = true;
    title;

    constructor(props) {
        super(props);
        const userRole = this.props.userRole;
        this.showCompanies = this.showReviewers = !(userRole === 'ROLE_REVIEWER' || userRole.includes('ROLE_CLIENT'));
        this.state = {
            reviewers: [],
            reviewersOption: 'All',
            companies: [],
            companiesOption: 'All',
            startDate: undefined,
            endDate: undefined,
            period: { value: 'Period', label: 'Period' },
        };
    }

    componentDidMount() {
        this.props.getAllCompaniesMinimal();
        this.props.saveFilter({ ...this.state, validationMessages: [] });
        const { location } = this.props;
        if (location.pathname === '/financial_reports') {
          this.title = 'Financial report'
        } else if (location.pathname === '/case_reports') {
          this.title = 'Case report'
        }
    }

    onSelectPeriodChange(e) {
        this.setState({ period: this.selectPeriodOptions.find((item) => item === e) });
        let startDate = new Date();
        let endDate = new Date();
        switch (e && e.value) {
            case 'Today':
                break;
            case 'Yesterday':
                startDate.setDate(startDate.getDate() - 1);
                endDate.setDate(endDate.getDate() - 1);
                break;
            case 'This week':
                startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
                endDate.setDate(startDate.getDate() + 6);
                break;
            case 'Last week':
                // eslint-disable-next-line no-case-declarations
                const thisWeekMonday = new Date(startDate.setDate(startDate.getDate() - startDate.getDay() + 1));
                startDate.setDate(thisWeekMonday.getDate() - 7);
                endDate.setDate(thisWeekMonday.getDate() - 1);
                break;
            case 'This month':
                startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
                break;
            case 'Last month':
                startDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
                endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0)
                break;
            case '6 months':
                startDate = new Date(startDate.getFullYear(), startDate.getMonth() - 5, 1);
                endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
                break;
            default:
                startDate = undefined;
                endDate = undefined;
                break;
        }
        this.setState({ startDate: startDate, endDate: endDate });
        this.removeValidationMessageByFieldName('Start Date');
        this.removeValidationMessageByFieldName('End Date');
        this.props.saveFilter({ ...this.state, startDate, endDate });
    }

    onChangeStartDate = (e) => {
        this.setState({ startDate: e, period: { value: 'Period', label: 'Period' } });
        const data = { ...this.state, startDate: e };
        this.props.saveFilter(data);
    }

    onBlurStartDate = () => {
        if (!this.state.startDate) {
            this.addValidationMessageByFieldName('Start Date', 'The Start Date field is required');
        } else {
            if (this.state.startDate > this.state.endDate) {
                this.addValidationMessageByFieldName('Start Date', 'The Start Date must be earlier than the End Date');
            } else {
                this.removeValidationMessageByFieldName('Start Date');
                if (this.state.endDate) {
                    this.removeValidationMessageByFieldName('End Date');
                }
            }
        }
    }

    onBlurEndDate = () => {
        if (!this.state.endDate) {
            this.addValidationMessageByFieldName('End Date', 'The End Date field is required');
        } else {
            if (this.state.startDate > this.state.endDate) {
                this.addValidationMessageByFieldName('End Date', 'The End Date must be later than the Start Date');
            } else {
                if (this.state.startDate) {
                    this.removeValidationMessageByFieldName('Start Date');
                }
                this.removeValidationMessageByFieldName('End Date');
            }
        }
    }

    onChangeEndDate = (e) => {
        this.setState({ endDate: e, period: { value: 'Period', label: 'Period' } });
        const data = { ...this.state, endDate: e };
        this.props.saveFilter(data);
    }

    onRadioChangeReviewers = (e) => {
        this.setState({ reviewersOption: e.target.value });
        if (e.target.value === 'All') {
            this.setState({ reviewers: [] });
            this.removeValidationMessageByFieldName('Reviewers');
        } else { // 'Selected'
            this.props.getAllReviewersMinimal();
        }
        const data = { ...this.state, reviewersOption: e.target.value };
        this.props.saveFilter(data);
    };

    onChangeReviewers(selectedReviewers) {
        const reviewersAfterCheck = selectedReviewers ? selectedReviewers : [];
        this.setState({ reviewers: reviewersAfterCheck });
        const data = { ...this.state, reviewers: reviewersAfterCheck };
        this.props.saveFilter(data);
    }

    onBlurReviewers = () => {
        if (this.state.reviewers.length === 0) {
            this.addValidationMessageByFieldName('Reviewers', 'The Reviewers field is required');
        } else {
            this.removeValidationMessageByFieldName('Reviewers');
        }
    }

    onRadioChangeCompanies = (e) => {
        this.setState({ companiesOption: e.target.value });
        if (e.target.value === 'All') {
            this.setState({ companies: [] });
            this.removeValidationMessageByFieldName('Clients');
        }
        const data = { ...this.state, companiesOption: e.target.value };
        this.props.saveFilter(data);
    };

    onChangeCompanies(selectedCompanies) {
        const companiesAfterCheck = selectedCompanies ? selectedCompanies : [];
        this.setState({ companies: companiesAfterCheck });
        const data = { ...this.state, companies: companiesAfterCheck, validationMessages: [...this.props.validationMessages] };
        this.props.saveFilter(data);
    }

    onBlurCompanies = () => {
        if (this.state.companies.length === 0) {
            this.addValidationMessageByFieldName('Clients', 'The Clients field is required');
        } else {
            this.removeValidationMessageByFieldName('Clients');
        }
    }

    addValidationMessageByFieldName = (field, message) => {
        const arr = this.props.validationMessages;
        const idx = arr.indexOf(arr.find(validation => validation.field === field));
        if (idx !== -1) { // only one message per field at same time
            arr.splice(idx, 1);
        }
        arr.push({ id: field, field, message });
        this.props.validationChange({ validationMessages: arr });
    }

    removeValidationMessageByFieldName = (field) => {
        const arr = this.props.validationMessages;
        const idx = arr.indexOf(arr.find(validation => validation.field === field));
        if (idx !== -1) {
            arr.splice(idx, 1);
            this.props.validationChange({ validationMessages: arr });
        }
    }

    getValidationMessageByFieldName = (field) => {
        return this.props.validationMessages.find(validation => validation.field === field);
    }

    render() {
        const { validationMessages } = this.props;
        return (
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <Box color="blue" classes="box-profile-first-row-height-show">
                            <BoxHeader title={this.title} color="blue">
                            </BoxHeader>
                            <BoxBody>
                                <SystemMessage
                                    shown={validationMessages.length !== 0}
                                    type="error"
                                    message={''}
                                    validationMessages={validationMessages}
                                    top="7em"
                                />
                                <form>
                                    <FormField
                                        md="12"
                                        type='label_field'
                                        label="Date Range"
                                        className='margin-bottom-15 fontWeight800'
                                        style={{ fontSize: '1.1em' }}
                                    />
                                    <div className="col-md-12">
                                        <div className="form-group col-md-3" style={{ paddingLeft: '10px', marginRight: '-8px' }}>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="required">Period</label>
                                                </div>
                                            </div>
                                            <Select
                                                className="legacy-select"
                                                classNamePrefix="legacy-select"
                                                options={this.selectPeriodOptions}
                                                value={this.state.period}
                                                isClearable={true}
                                                onChange={(e) => this.onSelectPeriodChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group bordered">
                                        <div className="row">
                                            <div className='col-md-3'>
                                                <div>
                                                    <label className="required">Start Date</label>
                                                    <DateTimePicker
                                                        className={this.getValidationMessageByFieldName('Start Date') ? 'invalid-form-field' : ''}
                                                        time={false}
                                                        culture='en'
                                                        placeholder='Type date in format dd/mm/yyyy or select from calendar'
                                                        value={this.state.startDate}
                                                        onChange={(e) => this.onChangeStartDate(e)}
                                                        onBlur={() => this.onBlurStartDate()}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div>
                                                    <label className="required">End Date</label>
                                                    <DateTimePicker
                                                        className={this.getValidationMessageByFieldName('End Date') ? 'invalid-form-field' : ''}
                                                        time={false}
                                                        culture='en'
                                                        placeholder='Type date in format dd/mm/yyyy or select from calendar'
                                                        value={this.state.endDate}
                                                        onChange={(e) => this.onChangeEndDate(e)}
                                                        onBlur={() => this.onBlurEndDate()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 form-group' style={{ marginRight: '8px' }}>
                                        {
                                            this.showReviewers && this.title != 'Case report' &&
                                            <div style={{ marginRight: '-8px' }}>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <label className="required">Reviewers</label>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label>
                                                                <input type="radio" className="mr-3" name='Reviewers' value={'All'}
                                                                       checked={this.state.reviewersOption === 'All'}
                                                                       onChange={(e) => this.onRadioChangeReviewers(e)}/>
                                                                All
                                                            </label>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label>
                                                                <input type="radio" className="mr-3" name='Reviewers' value={'Selected'}
                                                                       checked={this.state.reviewersOption === 'Selected'}
                                                                       onChange={(e) => this.onRadioChangeReviewers(e)}/>
                                                                Selected
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <Select
                                                        className={classnames([
                                                            'legacy-select',
                                                            { 'invalid-form-field': this.getValidationMessageByFieldName('Reviewers') }
                                                        ])}
                                                        classNamePrefix="legacy-select"
                                                        placeholder={this.state.reviewersOption === 'All' ? 'All Selected' : 'Select...'}
                                                        options={this.props.reviewersMinimalData || []}
                                                        value={this.state.reviewers}
                                                        isLoading={this.props.isReviewersMinimalLoading}
                                                        isClearable={true}
                                                        isMulti={true}
                                                        isDisabled={this.state.reviewersOption === 'All'}
                                                        onChange={(e) => this.onChangeReviewers(e)}
                                                        onBlur={this.onBlurReviewers}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.showCompanies &&
                                            <div style={{ marginRight: '-8px' }}>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="required">Clients</label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>
                                                            <input type="radio" className="mr-3" name='Clients' value={'All'}
                                                                   checked={this.state.companiesOption === 'All'}
                                                                   onChange={(e) => this.onRadioChangeCompanies(e)}/>
                                                            All
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>
                                                            <input type="radio" className="mr-3" name='Clients' value={'Selected'}
                                                                   checked={this.state.companiesOption === 'Selected'}
                                                                   onChange={(e) => this.onRadioChangeCompanies(e)}/>
                                                            Selected
                                                        </label>
                                                    </div>
                                                </div>
                                                <Select
                                                    className={classnames([
                                                        'legacy-select',
                                                        { 'invalid-form-field': this.getValidationMessageByFieldName('Clients') }
                                                    ])}
                                                    classNamePrefix="legacy-select"
                                                    placeholder={this.state.companiesOption === 'All' ? 'All Selected' : 'Select...'}
                                                    options={this.props.companiesMinimalData || []}
                                                    value={this.state.companies}
                                                    isLoading={this.props.isCompaniesMinimalLoading}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    isDisabled={this.state.companiesOption === 'All'}
                                                    onChange={(e) => this.onChangeCompanies(e)}
                                                    onBlur={this.onBlurCompanies}
                                                />
                                            </div>
                                        }
                                    </div>
                                </form>
                            </BoxBody>
                            <BoxFooter/>
                        </Box>
                    </div>
                </div>
            </section>
        )
    }

    static propTypes = {
        isReviewersMinimalLoading: PropTypes.bool,
        isCompaniesMinimalLoading: PropTypes.bool,
        userRole: PropTypes.string,
        companies: PropTypes.array,
        validationMessages: PropTypes.array,
        reviewersMinimalData: PropTypes.array,
        companiesMinimalData: PropTypes.array,
        saveFilter: PropTypes.func,
        validationChange: PropTypes.func,
        getAllReviewersMinimal: PropTypes.func,
        getAllCompaniesMinimal: PropTypes.func,
    }
}

const mapStateToProps = (state) => {
    return {
        userRole: state.app.auth.user.roleCode,
        validationMessages: state.app.financialReport.validationMessages,
        reviewersMinimalData: state.app.financialReport.reviewersMinimalData
            .map(({ fullName, id }) => ({
                label: fullName,
                value: id})),
        companiesMinimalData: state.app.financialReport.companiesMinimalData
            .map(({ fullName, id }) => ({
                label: fullName,
                value: id})),
        isReviewersMinimalLoading: state.app.financialReport.isReviewersMinimalLoading,
        isCompaniesMinimalLoading: state.app.financialReport.isCompaniesMinimalLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveFilter: (data) => dispatch(saveFilter(data)),
        validationChange: (validationMessages) => dispatch(validationChange(validationMessages)),
        getAllReviewersMinimal: () => dispatch(getAllReviewersMinimal()),
        getAllCompaniesMinimal: () => dispatch(getAllCompaniesMinimal()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FinancialReportForm));
