import React from 'react';
import {connect} from 'react-redux'
import ReviewerEditProfile from './ReviewerEditProfile'
import {Tab, Tabs} from 'react-bootstrap';
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';
import ReviewerEditSignature from './ReviewerEditSignature'
import ReviewerEditProfessionalInfo from './ReviewerEditProfessionalInfo'
import ReviewerFinanceInfo from './ReviewerFinanceInfo'
import ReviewerStatisticsInfo from './ReviewerStatisticsInfo'
import {getActiveCountries} from '../../redux/actions/countryActions'
import {getAllSpecialties} from '../../redux/actions/specialtyActions'
import {getAllTypes} from '../../redux/actions/reviewerTypesAction'
import {getAllCompanies} from '../../redux/actions/companiesActions'
import {getAllCaseTypes} from '../../redux/actions/cases/caseTypesActions'
import {getAllCurrencies} from '../../redux/actions/currencyActions'
import {getLanguages} from '../../redux/actions/languageActions'
import {getAllLocales} from '../../redux/actions/localeActions'
import { editUser, getUserProfile, getUserStatistics, isAllValid } from '../../redux/action-creators/users'
import {saveSignature} from "../../redux/actions/profileActions";
import SimpleReactValidator from "utils/simple-react-validator";
import {getMultiSelectValue, getSelectFieldValue} from '../../utils/helpers'
import SystemMessage from '../../components/SystemMessage';
import UserSettings from "../UserSettings";
import LoadingIndicator from "../../components/LoadingIndicator";
import PropTypes from "prop-types";

const tabToField = {
    'First Name': 'general',
    'Middle Name': 'general',
    'Last Name': 'general',
    'Notification Email': 'general',
    'Phone Number': 'general',
    'Country': 'general',
    'Reviewer Type': 'professional',
    'Specialties': 'professional',
    'Case Types Excluded': 'professional',
    'Reviewer Currency': 'finance',
    'Hourly Rate': 'finance',
    'Reviewer Flat Margin': 'finance',
    'Accepted Per Month Min': 'statistics',
    'Accepted Per Month Max': 'statistics'
};

class ReviewerEditPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            test: ''
        };

        this.validator = new SimpleReactValidator({
            custom_email:
                {
                    message: 'The Notification email must be a valid email address',
                    rule: (val) => val === '' ||
                        // eslint-disable-next-line no-control-regex,max-len
                        (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/igm).test(val)
                },
        });
        this.validator.showMessages()
    }

    componentDidMount() {
        this.props.getActiveCountries();
        this.props.getAllSpecialties();
        this.props.getAllTypes();
        this.props.getAllCompanies();
        this.props.getAllCaseTypes();
        this.props.getAllCurrencies();
        this.props.getLanguages();
        this.props.getUserProfile(this.props.match.params.id)
            .then(() => {
                this.props.isAllValid(this.validator.allValid())
                this.props.getUserStatistics(this.props.profile.code);
            })

    }

    dispatchValidStatus = () => {
        this.props.isAllValid(this.validator.allValid());
        this.forceUpdate();
    };

    handleSelect = (key) => {
        this.setState({currentTab: key})
    };

    onSubmit = () => {
        // this.validator.showMessages();
        const data = {
            ...this.props.profile,
            countryCode: getSelectFieldValue(this.props.profile.countryCode),
            stateCode: getSelectFieldValue(this.props.profile.stateCode),
            defaultLocaleCode: getSelectFieldValue(this.props.profile.defaultLocaleCode),
            specialtyCodes: getMultiSelectValue(this.props.profile.specialtyCodes),
            companyExcludedCodes: getMultiSelectValue(this.props.profile.companyExcludedCodes),
            caseTypeCodesExcluded: getSelectFieldValue(this.props.profile.caseTypeCodesExcluded),
            languageCodes: getMultiSelectValue(this.props.profile.languageCodes),
            defaultLanguageCode: getSelectFieldValue(this.props.profile.defaultLanguageCode),
            reviewerTypeCode: getSelectFieldValue(this.props.profile.reviewerTypeCode),
            reviewerCurrencyCode: getSelectFieldValue(this.props.profile.reviewerCurrencyCode),
            trainDate: this.props.profile.trainDate ? this.props.profile.trainDate.getTime() : null
        };

        if (this.props.profile.savedSignature && this.props.profile.savedSignature.length) {
            this.props.saveSignature({
                code: this.props.profile.code,
                file: this.props.profile.savedSignature[0]
            })
        }

        return this.props.saveProfile(data)
    };

    render() {
        const {match, isProfileChanged, isProfileValid, editUserSuccess, showValidMessages, error, isLoading} = this.props;
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
            tab: tabToField[field]
        }));

        return (
            <div>
                <section className="content user-profile-content">
                    <PreventTransitionPrompt
                        when={isProfileChanged}
                        message='MyCustomDialogComponent'
                        isFormValid={isProfileValid}
                        saveChanges={this.onSubmit}
                        entity='user'
                        cancelAndDiscard={() =>
                            this.props.getUserProfile(this.props.match.params.id)
                        }
                    />
                    <div className='col-md-12'>
                        {isLoading && <LoadingIndicator type="tabs"/>}
                        <Tabs activeKey={this.state.currentTab} defaultActiveKey={'general'} animation={true}
                              id="noanim-tab-example" onSelect={this.handleSelect}>
                            <SystemMessage
                                shown={editUserSuccess && !isProfileChanged}
                                type='success'
                                message='Profile successfully updated'
                                top='7em'
                            />
                            <SystemMessage
                                shown={showValidMessages}
                                type='error' message={error}
                                validationMessages={errorMessages}
                                goToTab={this.handleSelect}
                                top='7em'
                            />
                            <Tab eventKey={'general'} title="Profile" tabClassName=''>
                                <div className="row">
                                    <div className='col-md-10'>
                                        {isLoading && <LoadingIndicator type="form"/>}
                                        {/*{isLoading && <LoadingIndicator height={50}/>}*/}
                                        <ReviewerEditProfile match={match} validator={this.validator}
                                                             dispatchValidStatus={this.dispatchValidStatus}/>
                                    </div>
                                    <div className='col-md-2'>
                                        <ReviewerEditSignature match={match}/>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey={'professional'} title="Professional">
                                <ReviewerEditProfessionalInfo match={match} validator={this.validator}
                                                              dispatchValidStatus={this.dispatchValidStatus}/>
                            </Tab>
                            <Tab eventKey={'finance'} title="Finance">
                                <ReviewerFinanceInfo match={match} validator={this.validator}
                                                     dispatchValidStatus={this.dispatchValidStatus}/>
                            </Tab>
                            {       /*       <Tab eventKey={4} title="Notes">
                <div style={{marginTop:'60px'}}>
                  <ReviewerNotes match={match}/>
                </div>
              </Tab> */}
                            <Tab eventKey={'statistics'} title="Statistics">
                                <ReviewerStatisticsInfo validator={this.validator}
                                                        dispatchValidStatus={this.dispatchValidStatus}
                                                        showValidMessages={showValidMessages}/>
                            </Tab>

                            <Tab eventKey={'settings'} title="Settings">
                                <div className="row">
                                    <UserSettings
                                        color='blue'
                                        title={`Reviewer - ${this.props.profile.firstName} ${this.props.profile.lastName}`}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    {/*
          <div className="col-md-2">
                  <SubmitButton
                     color='blue'
                     onSubmit={ this.onSubmit }
                     isLoading={ this.props.loading }
                     />
          </div>
        */}
                </section>
            </div>)
    }

    static propTypes = {
        isLoading: PropTypes.bool,
    }
    static defaultProps = {
        isLoading: false
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.app.users.profileLoading,
    editUserSuccess: state.app.users.editUserSuccess,
    error: state.app.users.editUserError,
    profile: state.app.users.editedUser,
    isProfileChanged: state.app.users.isProfileChanged,
    isProfileValid: state.app.users.userValid,
    showValidMessages: state.app.users.showValidMessages
});

const mapDispatchToProps = dispatch => {
    return {
        getActiveCountries: () => dispatch(getActiveCountries()),
        getAllSpecialties: () => dispatch(getAllSpecialties()),
        getAllTypes: () => dispatch(getAllTypes()),
        getAllCompanies: () => dispatch(getAllCompanies()),
        getAllCaseTypes: () => dispatch(getAllCaseTypes()),
        getAllCurrencies: () => dispatch(getAllCurrencies()),
        getLanguages: () => dispatch(getLanguages()),
        getAllLocales: () => dispatch(getAllLocales()),
        getUserProfile: code => dispatch(getUserProfile(code, 'auth/user/get?code=')),
        saveProfile: data => dispatch(editUser(data, false, 'auth/user/update')),
        saveSignature: data => dispatch(saveSignature(data)),
        isAllValid: (valid) => dispatch(isAllValid(valid)),
        getUserStatistics: (code) => dispatch(getUserStatistics(code)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewerEditPage);
