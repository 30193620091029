import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SaveCompanyProfile from '../../pages/Company/Buttons/SaveCompanyProfile';
import { closeCreateModal, openCreateModal } from '../../redux/action-creators/users';
import LinkPhysicianFacility from '../../pages/Facility/Buttons/LinkPhysicianFacility';
import LinkClientFacility from '../../pages/Facility/Buttons/LinkClientFacility';
import SaveReviewerProfile from '../../pages/Admin/SaveReviewerProfile';
import SaveStaffProfile from '../../pages/Staff/SaveStaffProfile';
import SaveUserProfile from '../../pages/ClientUsers/SaveUserProfile';
import SaveProfile from '../../pages/Profile/SaveProfileButton';
import SavePasswordButton from '../../pages/Profile/SavePasswordButton';
import SavePatientProfile from '../../pages/Patients/SavePatientProfile';
import SaveMCSystem from '../../pages/MedicalServices/CodingSystems/SaveMCSystem';
import SaveCode from '../../pages/MedicalServices/Codes/SaveCode';
import SaveProcedure from '../../pages/MedicalServices/Procedures/SaveProcedure';
import SaveDiagnose from '../../pages/MedicalServices/Diagnoses/SaveDiagnose';
import SaveSpecialty from '../../pages/BasicEntities/Specialties/SaveSpecialty';
import SaveCountry from '../../pages/BasicEntities/Countries/SaveCountry';
import FacilitySave from '../../pages/Facility/Buttons/FacilitySave';
import FacilityTypeSave from '../../pages/Facility/FacilityTypeSave';
import PhysicianSave from '../../pages/Physician/Buttons/PhysicianSave';
import LinkFacilityPhysician from '../../pages/Physician/Buttons/LinkFacilityPhysician';
import SaveCaseType from '../../pages/Entities/CaseTypes/SaveCaseType';

import CreateFieldButton from '../../pages/Entities/Fields/CreateFieldButton';
import UpdateFieldButton from '../../pages/Entities/Fields/UpdateFieldButton';
import CreateFieldInstanceButton from '../../pages/Template/Fields/Buttons/CreateFieldInstanceButton';
import UpdateFieldInstanceButton from '../../pages/Template/Fields/Buttons/UpdateFieldInstanceButton';
import RemoveFieldInstanceButton from '../../pages/Template/Fields/Buttons/RemoveFieldInstanceButton';
import GoToFieldInstanceListButton from '../../pages/Template/Fields/Buttons/GoToFieldInstanceListButton';

import SaveBillingGroupButton from '../../pages/BillingGroups/Buttons/SaveBillingGroupButton';
import CreateTierButton from '../../pages/BillingGroups/Buttons/CreateTierButton';
import SaveTierButton from '../../pages/BillingGroups/Buttons/SaveTierButton';

import CaseAssignButton from '../../pages/Case/Assignments/Buttons/CreateTiersButton';
import ApplyFilterButton from '../../pages/Case/Assignments/Buttons/ApplyFilterButton';

import Modal from 'react-modal';
import UpdateTemplateButton from '../../pages/Template/UpdateTemplateButton';
import CreateSection from '../../pages/Template/Sections/CreateSection';
import AddFieldButton from '../../pages/Template/Fields/AddFieldButton';
import ResetFilterToDefaultButton from "../../pages/Case/Assignments/Buttons/ResetFilterToDefaultButton";
// import ButtonLoader from "../../pages/Develop/ButtonLoader";
import ExportTemplateButton from "../../pages/Template/ExportTemplateButton";
//import CreateNotificationTemplateButton from "../../pages/NotificationTemplate/CreateNotificationTemplateButton";

const SaveEntity = ({ currentPage, currentTab, currentTabFacility, currentTabPhysician, templateCurrentTab, modalOpen, isOpen, templateCode }) => {
    // console.warn('CURRENT PAGE:', currentPage);
    // console.warn('templateCode:', templateCode);
    let addButton;
    switch (true) {
        case /clients\/profile/.test(currentPage):
            switch (currentTab) {
                case 'physicians':
                    return (
                        <span>
                            <SaveCompanyProfile />
                            {/*<LinkPhysician />*/}
                        </span>
                    );
                case 'facilities':
                    return (
                        <span>
                            <SaveCompanyProfile />
                            {/*<LinkFacility />*/}
                        </span>
                    );
                case 'mcSystem':
                    return (
                        <span>
                            <SaveCompanyProfile />
                            {/*<LinkMcSystem />*/}
                        </span>
                    );
                default:
                    return <SaveCompanyProfile />;
            }
        case /reviewers\/profile/.test(currentPage):
            return <SaveReviewerProfile />;

        case /patients\/profile/.test(currentPage):
            return <SavePatientProfile />;

        case /fields\/create/.test(currentPage):
            return <CreateFieldButton />;

        case /fields\/update/.test(currentPage):
            return <UpdateFieldButton />;

        case /field_instances\/create/.test(currentPage):
        case /field_instances\/clone/.test(currentPage):
            return <CreateFieldInstanceButton />;

        case /field_instances\/update/.test(currentPage):
            return (
                <span>
                    <UpdateFieldInstanceButton />
                    <RemoveFieldInstanceButton />
                    <GoToFieldInstanceListButton />
                </span>
            );

        case /staff\/profile/.test(currentPage):
            return <SaveStaffProfile />;
        case /submitters\/profile/.test(currentPage):
            return <SaveUserProfile />;
        case /profile/.test(currentPage):
            return (
                <span>
                    <SaveProfile />
                    <SavePasswordButton />
                </span>
            );
        case /coding_system\//.test(currentPage):
            return <SaveMCSystem />;
        case /coding_system_code\//.test(currentPage):
            return <SaveCode />;
        case /procedure\//.test(currentPage):
            return <SaveProcedure />;
        case /diagnose\//.test(currentPage):
            return <SaveDiagnose />;
        case /specialties\//.test(currentPage):
            return <SaveSpecialty />;
        case /countries\//.test(currentPage):
            return <SaveCountry />;

        case /facility\//.test(currentPage):
            switch (currentTabFacility) {
                case 'physicians':
                    return (
                        <span>
                            <FacilitySave />
                            <LinkPhysicianFacility />
                        </span>
                    );
                case 'clients':
                    return (
                        <span>
                            <FacilitySave />
                            <LinkClientFacility />
                        </span>
                    );
                default:
                    return <FacilitySave />;
            }

        case /facility_type\//.test(currentPage):
            return <FacilityTypeSave />;
        case /physicians\//.test(currentPage):
            if (currentTabPhysician === 'facilities') {
                return (
                    <span>
                        <PhysicianSave />
                        <LinkFacilityPhysician />
                    </span>
                );
            } else return <PhysicianSave />;
        case /billing_groups\//.test(currentPage):
            return <SaveBillingGroupButton />;
        case /billing_tier\/create\/tier\//.test(currentPage):
            return <CreateTierButton />;
        case /billing_tier\/update\/tier\//.test(currentPage):
            return <SaveTierButton />;
        case /case_types\//.test(currentPage):
            return <SaveCaseType />;
        case /assign\//.test(currentPage):
            return (
                <span>
                    <CaseAssignButton />
                    <ApplyFilterButton />
                    <ResetFilterToDefaultButton />
                </span>
            );

        case /^\/templates\/create/.test(currentPage):
        case /^\/templates\/update/.test(currentPage):
            switch (templateCurrentTab) {
                case 'sections':
                    addButton = (
                        <span>
                            <button className={`btn resizable-button tooltip-button btn-primary margin-left-5`} onClick={modalOpen}>
                                {'Add Section'}
                            </button>
                            <Modal
                                isOpen={isOpen}
                                contentLabel="onRequestClose Example"
                                className="custom-modal modal-dialog"
                                overlayClassName="modal-overlay"
                            >
                                <CreateSection />
                            </Modal>
                        </span>
                    );
                    break;

                case 'field_instances':
                    addButton = <>
                        <AddFieldButton />
                        <ExportTemplateButton templateCode={templateCode} />
                    </>;
                    break;
                case 'template':
                    addButton = (
                        <ExportTemplateButton templateCode={templateCode} />
                    );
                    break;
                default:
                    addButton = <span />;
            }
            return (
                <span>
                    <UpdateTemplateButton />
                    {addButton}
                </span>
            );
        default:
            return <span id="SaveButtonContainer"></span>;
    }
};

SaveEntity.contextTypes = {
    translate: PropTypes.func,
};

const mapStateToProps = (state) => {
    // console.log(state.app.templates);
    return ({
    currentPage: state.router.location.pathname,
    currentTab: state.app.companies.currentTab,
    currentTabFacility: state.app.facilities.currentTab,
    templateCurrentTab: state.app.templates.currentTab,
    isOpen: state.app.users.createModalOpen,
    currentTabPhysician: state.app.physician.currentTab,
    templateCode: state.app.templates.code,
})};

const mapDispatchToProps = (dispatch) => ({
    modalOpen: () => dispatch(openCreateModal()),
    modalClose: () => dispatch(closeCreateModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveEntity);
