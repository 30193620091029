import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';
import { patientCaseStages } from '../patientCaseStages';

const availableSlice = createDatatableTemplateSlice({
    name: patientCaseStages.AVAILABLE,
    initialSettings: {},
});

export const availableReducer = availableSlice.reducer;

export const { loadData, dataLoaded, dataLoadError } = availableSlice.actions;

export default availableSlice;
