/* eslint-enable react-hooks/exhaustive-deps */
/* eslint "react-hooks/exhaustive-deps": "error" */

import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';

const shouldFiltered = (fieldValue) => (currentFilter) => {
    if (!currentFilter) {
        return true;
    }

    if (!Array.isArray(currentFilter)) {
        if (typeof currentFilter === 'string') {
            return fieldValue.includes(currentFilter);
        }

        return fieldValue.toLowerCase() === currentFilter.toLowerCase();
    }

    return currentFilter.some(shouldFiltered(fieldValue));
};

const filterRow = (filters) => (row) =>
    Object.keys(filters).every((column) => {
        const currentFilter = filters[column];
        const fieldValue = row[column];

        return shouldFiltered(fieldValue)(currentFilter);
    });

const useClientFilterPagination = (settings, dataParam, multiColumns) => {
    const { filters, pageSize, page, sortBy: sortByColumnName, sortDirection } = settings;

    const data = dataParam ? dataParam : [];

    const filteredData = useMemo(() => {
        return data.filter(filterRow(filters));
    }, [data, filters]);

    const orderedData = useMemo(() => {
        if (sortByColumnName) {
            return orderBy(filteredData, sortByColumnName, sortDirection);
        }

        return filteredData;
    }, [filteredData, sortByColumnName, sortDirection]);

    const paginatedData = useMemo(() => {
        if (orderedData.length < (page - 1) * pageSize) {
            return orderedData;
        }

        return orderedData.slice((page - 1) * pageSize, page * pageSize);
    }, [orderedData, page, pageSize]);

    const filterOptions = Object.values(data).reduce((result, current) => {
        multiColumns.forEach((key) => {
            if (result[key] !== undefined) {
                if (!result[key].map(({ value }) => value).includes(current[key])) {
                    result[key].push({ value: current[key], label: current[key] });
                }
            } else {
                result[key] = [{ value: current[key], label: current[key] }];
            }
        });
        return result;
    }, {});

    return { totalRecords: data.length, totalRecordsFiltered: filteredData.length, sortedData: paginatedData, filterOptions };
};

export default useClientFilterPagination;
