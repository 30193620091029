import React from 'react';
import api from 'utils/api';
import fileDownload from 'react-file-download';
import { MIME_TYPES } from 'constants/mimeTypes';

import officeCrypto from 'officecrypto-tool';
import { PDFDocument } from 'pdf-lib/dist/pdf-lib';

import PDFJSWorker from 'pdfjs-dist/build/pdf.worker.entry'
const pdfjsLib = require('pdfjs-dist/build/pdf');

pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;


const linkFormatter = (name, { code }) => (
    <a
        href="/"
        title={name}
        onClick={(event) => {
            event.preventDefault();
            api.request({
                url: '/reports/download',
                params: {
                    code: code,
                    latestReport: false,
                    isReport: false
                },
                responseType: 'arraybuffer',
                headers: {
                    Accept: [MIME_TYPES.octetStream, MIME_TYPES.docx],
                },
            })
                .then((response) => {
                    let responseContentTypeString = response.headers["content-type"];
                    let ext= responseContentTypeString.slice(responseContentTypeString.indexOf('/')+1, responseContentTypeString.length);
                    fileDownload(response.data, `${name}.` + (ext === 'pdf' ? ext : 'docx'));
                })
                .catch((error) => {
                    console.error(error);
                });
        }}
    >
        {name}
    </a>
);

const acceptedExtensions = ['doc', 'docx', 'ppt', 'pptx', 'pdf'];
let buffer;
let extension;

async function isFilePasswordCorrect(password) {
    try {
        if (extension === 'pdf') {
            return new Promise((resolve) => {
                pdfjsLib.getDocument({data: buffer, password: password}).promise
                .then(res => resolve(true))
                .catch(e => {
                    resolve(false);
                });
            })
        } else {
            await officeCrypto.decrypt(buffer, { password });
            return true;
        }
    } catch (e) {
        return false;
    }
    
}

async function checkFileEncryption(file) {
    extension = file.name.toLowerCase().split('.').pop();
    if (acceptedExtensions.includes(extension)) {
        return await readFile(file, extension);
    }
    
}

async function readFile(file, extension) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
            buffer = event.target.result;
            let isEncrypted;
            if (extension === 'pdf') {
                const pdfDoc = await PDFDocument.load(buffer, { ignoreEncryption: true });
                isEncrypted = pdfDoc.isEncrypted;
            } else {
                isEncrypted = officeCrypto.isEncrypted(buffer);
            }

            resolve(isEncrypted);
        };
        reader.readAsArrayBuffer(file);
    });
}

export { linkFormatter, checkFileEncryption, isFilePasswordCorrect };