import { createDatatableTemplateSlice } from '../../../components/Datatable/dataTableUtils';
import createEntityMutationTemplateSlice from '../../utils/createEntityMutationTemplateSlice';

const caseFileTemplateSlice = createDatatableTemplateSlice(
    createEntityMutationTemplateSlice({
        name: 'caseFileTemplate',
        initialSettings: {
            sortBy: 'name',
        },
        initialState: {
            entity: {
                code: '00000000-0000-0000-0000-000000000000',
                templateName: '',
                template: null,
                name: '',
                active: true,
                clients: [],
            },
        },
        reducers: {
            downloadCaseFileTemplate: (state) => {
                state.fileLoading = true;
            },
            downloadCaseFileTemplateSuccess: (state) => {
                state.fileLoading = false;
            },
            downloadCaseFileTemplateError: (state, { payload }) => {
                state.fileLoading = false;
                state.error = payload;
            },
        },
    }),
);

export const caseFileTemplateReducer = caseFileTemplateSlice.reducer;

export const {
    // uploadCaseFileTemplateError,
    downloadCaseFileTemplate,
    downloadCaseFileTemplateError,
    downloadCaseFileTemplateSuccess,
    deleteError,
    deleteEntity,
    deleteSuccess,
    loadData,
    dataLoaded,
    dataLoadError,
    changeSettings,
    editField,
    setValidityStatus,
    updateEntityError,
    updateEntityLoading,
    updateEntitySuccess,
    fetchEntityLoading,
    fetchEntitySuccess,
    fetchEntityError,
    createEntityError,
    createEntityLoading,
    createEntitySuccess,
    resetEntity,
    setServerErrors,
} = caseFileTemplateSlice.actions;

export default caseFileTemplateSlice;
