import React, {Component} from "react";

import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import FormField from '../../components/FormField'
import ProfileFormWrapper from '../../containers/ProfileFormWrapper'
import {getFullNameFromCode} from "../../utils/helpers";
import ContactInfoSection from "../../components/Sections/ContactInfoSection";
import AddressInfoSection from "../../components/Sections/AddressInfoSection";


class ProfileUserDataClients extends Component {
  componentDidMount() {
      console.log(this.props);
      console.log(1);
  }

  state = {}

    render() {
        // const {isLoading, showSuccess, error} = this.props;
        let companyName = getFullNameFromCode(this.props.profile.companyCode, this.props.companies);

        return (
            <Box color = "blue" classes="box-profile-first-row-height-show">
                <BoxHeader title="Profile" color="blue" icon="fa-user"/>
                <BoxBody>
                    <form onSubmit={this.onSubmit} noValidate>
                        <div className="form-group bordered">
                        <div className="form-group">
                            <div className="row">
                              <FormField
                              md="12"
                              type='label_field'
                              label="Personal Information"
                              className='margin-bottom-15'
                              style={{fontSize: '1.1em'}}
                              />
                                <FormField
                                    md="4"
                                    label="First Name"
                                    id="First Name"
                                    className="form-control"
                                    required
                                    name="firstName"
                                    valids="required|alpha"
                                    validator={this.props.validator}
                                    value={this.props.profile.firstName}
                                    onChange={this.props.onChange}
                                    onBlur={this.props.dispatchValidState}
                                />
                                <FormField
                                    md="4"
                                    label="Middle Name"
                                    id="Middle Name"
                                    className="form-control"
                                    name="middleName"
                                    valids="alpha"
                                    validator={this.props.validator}
                                    value={this.props.profile.middleName}
                                    onChange={this.props.onChange}
                                    onBlur={this.props.dispatchValidState}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <FormField
                                    md="4"
                                    label="Last Name"
                                    id="Last Name"
                                    className="form-control"
                                    required
                                    name="lastName"
                                    valids="required|alpha"
                                    validator={this.props.validator}
                                    value={this.props.profile.lastName}
                                    onChange={this.props.onChange}
                                    onBlur={this.props.dispatchValidState}
                                />
                            </div>
                        </div>
                      </div>
                        <div className="form-group bordered">
                            <div className="row">
                              <FormField
                                md="12"
                                type='label_field'
                                label="Security Information"
                                className='margin-bottom-15'
                                style={{fontSize: '1.1em'}}
                                />

                              <FormField
                                  md="4"
                                  label="Company"
                                  id="Company"
                                  disabled="true"
                                  className="form-control"
                                  name="companyName"
                                  value={companyName}
                              />
                            </div>
                        </div>

                        <ContactInfoSection
                            validator={this.props.validator}
                            dispatchValidState={this.props.dispatchValidState}
                            onChange={this.props.onChange}
                            email={this.props.profile.email}
                            notificationEmail={this.props.profile.notificationEmail}
                            phoneNumber={this.props.profile.phoneNumber}
                            alternativePhoneNumber={this.props.profile.alternativePhoneNumber}
                            />
                        <AddressInfoSection
                            onChange={this.props.onChange}
                            onChangeCountry={this.props.onChangeCountry}
                            onChangeState={this.props.onChangeState}
                            address1={this.props.profile.address1}
                            address2={this.props.profile.address2}
                            city={this.props.profile.city}
                            countryCode={this.props.profile.countryCode}
                            stateCode={this.props.profile.stateCode}
                            postalCode={this.props.profile.postalCode}
                            />

                       <div className="form-group">
                            <div className="row">
                               <FormField
                                    md="4"
                                    xs="12"
                                    type="select"
                                    id="Locale"
                                    label="Locale"
                                    className="text-bg "
                                    searchable
                                    multi={false}
                                    disabled={false}
                                    options={this.props.locales}
                                    name="defaultLocaleCode"
                                    value={this.props.profile.defaultLocaleCode}
                                    onChange={this.props.onChangeDefaultLocale}
                                />
                                <FormField
                                    md="4"
                                    xs="12"
                                    type="select"
                                    id="Time Zone"
                                    label="Time Zone"
                                    className="text-bg "
                                    searchable={true}
                                    multi={false}
                                    disabled={false}
                                    options={this.props.timezones}
                                    name="timeZoneCode"
                                    value={this.props.profile.timeZoneCode}
                                    onChange={this.props.onChangeTimeZones}
                                />
                            </div>
                        </div>
                    </form>
                </BoxBody>
                <BoxFooter>

                </BoxFooter>
            </Box>
        );
    }
}

export default ProfileFormWrapper(ProfileUserDataClients);
