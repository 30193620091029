import { difference } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCases } from 'redux/actions/cases/caseListActions';

const useSelectedCases = () => {
    const dispatch = useDispatch();

    const selectedCaseCodes = useSelector((state) => state.app.case_list.selectedCaseCodes);

    useEffect(() => {
        dispatch(setSelectedCases([]));
    }, [dispatch]);

    const onRowSelect = useCallback(
        (id) => {
            dispatch(setSelectedCases([...selectedCaseCodes, id]));
        },
        [dispatch, selectedCaseCodes],
    );

    const onRowSelectAll = useCallback(
        (ids) => {
            dispatch(setSelectedCases([...selectedCaseCodes, ...ids]));
        },
        [dispatch, selectedCaseCodes],
    );

    const onRowDeselect = useCallback(
        (id) => {
            dispatch(setSelectedCases(difference(selectedCaseCodes, [id])));
        },
        [dispatch, selectedCaseCodes],
    );

    const onRowDeselectAll = useCallback(
        (ids) => {
            dispatch(setSelectedCases(difference(selectedCaseCodes, ids)));
        },
        [dispatch, selectedCaseCodes],
    );

    return { selectedCaseCodes, onRowSelect, onRowDeselect, onRowDeselectAll, onRowSelectAll };
};

export default useSelectedCases;
