export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const VERIFY_FIRST_LOGIN = 'VERIFY_FIRST_LOGIN';
export const CHANGE_FIRST_LOGIN = 'CHANGE_FIRST_LOGIN';
export const RESET_PASSWORD_STATUS = 'RESET_PASSWORD_STATUS'; //TODO: @Deprecated
export const VERIFY_RECOVERY = 'VERIFY_RECOVERY';
export const CHANGE_RECOVERY_PASSWORD = 'CHANGE_RECOVERY_PASSWORD';
export const RESPONSE_ERROR = 'RESPONSE_ERROR';

export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_PROFILE = 'GET_PROFILE';
export const SAVE_PROFILE = 'SAVE_PROFILE';
