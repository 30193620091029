export default {

    toggleCounterMenu() {
        return (dispatch) => {
            dispatch({
                type: 'TOGGLE_COUNTER_MENU'
            })
        }
    },

    closeCounterMenu() {
        return (dispatch) => {
            dispatch({
                type: 'CLOSE_COUNTER_MENU',
                counterOpen: false
            })
        }
    },

    toggleSubmitToMenu() {
        return (dispatch) => {
            dispatch({
                type: 'TOGGLE_SUBMIT_TO_MENU'
            })
        }
    },

    toggleCaseReportMenu() {
        return (dispatch) => {
            dispatch({
                type: 'TOGGLE_CASE_REPORT_MENU'
            })
        }
    },

    closeCaseReportMenu() {
        return (dispatch) => {
            dispatch({
                type: 'CLOSE_CASE_REPORT_MENU'
            })
        }
    },

    closeSubmitToMenu() {
        return (dispatch) => {
            dispatch({
                type: 'CLOSE_SUBMIT_TO_MENU',
                submitToOpen: false
            })
        }
    },

    toggleAdminMenu() {
        return (dispatch) => {
            dispatch({
                type: 'TOGGLE_ADMIN_MENU'
            })
        }
    },

    closeAdminMenu() {
        return (dispatch) => {
            dispatch({
                type: 'CLOSE_ADMIN_MENU',
                adminOpen: false
            })
        }
    },

    load: () => {
        return dispatch => {
            dispatch({
                type: "LOAD_MENU_START",
            })
            let randErr = Math.floor(Math.random() * 10);
            if (randErr !== 0) {
                setTimeout(() => {
                    dispatch({
                        type: "LOAD_MENU_SUCCESS",
                        items: [
                            // {id: "6", name: "User 1", mail: "u1@gm.com", role: "admin", saving: true},
                            // {id: "7", name: "User 2", mail: "u2@gm.com", role: "staff", deleting: true},
                            {id: "8", name: "Polko Tester", mail: "uto@gm.com", role: "staff"},
                            {id: "9", name: "Zeta Adste", mail: "abc@gm.com", role: "admin"},
                            {id: "10", name: "Ago Daster", mail: "dadab@gm.com", role: "staff"},
                            {id: "3", name: "Ergo Exter", mail: "fud@gm.com", role: "admin"},
                            {id: "1", name: "Cember Factsi", mail: "xaazaz@gm.com", role: "staff"},
                            {id: "15", name: "Octi Nisto", mail: "rdsssds@gm.com", role: "admin"},
                            {id: "108", name: "Pefo Namo", mail: "sasssf@gm.com", role: "staff"},
                            {id: "4", name: "Alto Mempo", mail: "turetg@gm.com", role: "admin"},
                        ],
                    })
                }, 200)
            } else {
                setTimeout(() => {
                    dispatch({
                        type: "LOAD_MENU_ERROR",
                        error: "Connection timeout",
                    })
                }, 1000)
            }
        }
    }
}
