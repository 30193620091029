import React from 'react';
import PropTypes from 'prop-types';

const CaseCommentsAvatar = ({ user }) => {
  return (
    <div className="case-comment-avatar">
      {user && user.firstName && user.firstName.substr(0, 1)}
      {user && user.lastName && user.lastName.substr(0, 1)}
    </div>
  );
};

CaseCommentsAvatar.propTypes = {
  user: PropTypes.object,
};

CaseCommentsAvatar.defaultProps = {};

export default CaseCommentsAvatar;
