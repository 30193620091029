import React from 'react'
import InputGroupWrapper from "./InputGroupWrapper";
import HelperText from './HelperText';

const valueConverter = (value) => value && (typeof value === 'string' || typeof value === 'number') ? value : '';
const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const InputField = (props) => {
    let i = <input
        type={props.type}
        className={props.className + ' form-control'}
        name={props.name}
        id={props.id}
        value={valueConverter(props.value)}
        disabled={props.disabled}
        required={!props.disabled && props.required}
        checked={props.checked}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onClick={props.type === 'checkbox' && typeof props.onBlur !== "undefined"
            ? () => {
                setTimeout(() => {
                    props.onBlur()
                }, 200)
            } : props.onClick}
        title={props.title}
        step={props.step}
        autoComplete={'whatever' + +new Date()}
        //defaultChecked={true}
    />;
    let classes = props['classBox'] ? props['classBox'] : "";
    classes += ` col-md-${props['labelLeft'] ? props.md - 2 : props.md}`;
    classes += " col-sm-" + (props.sm || "12");
    classes += " col-xs-" + (props.xs || "12");

    i = props['actioned'] ? <InputGroupWrapper name={props.name} onActionClick={props.onActionClick}
                                            icon={props.icon}>{i}</InputGroupWrapper> : i;
    const validMessage = (
        <span style={{display: 'none'}}>
      {
          props.validator && props['valids'] ?
              props.validator.message(
                  props['validLabel'] || props.label,
                  props.value && typeof props.value === 'string' ? props.value.trim() : props.value, props['valids'],
                  'text-danger') : ''
      }
    </span>);

    switch (props.type) {
        case "checkbox":
            i = <React.Fragment>
                {props['labelLeft'] && <div className='col-md-2'> </div>}

                <div className={`${classes} text-${props['aligment']}`}>
                    <div className="checkbox" style={props.style}>
                        <label title={props.title}>
                            {validMessage}
                            {i}
                            {props.label}
                            {props.tickMark}
                        </label>
                    </div>
                </div>
            </React.Fragment>;
            break;
        case "BOOLEAN":
            i =
                <React.Fragment>
                    {props['labelLeft'] &&
                    <div className='col-md-2'>
                        <label htmlFor={props.id} className="required">
                            {props.label}
                            {props.tickMark}
                        </label>
                    </div>}
                    <div className={classes} style={props.style}>
                        <label htmlFor={props.id} className="required" id={'displayName-' + props.name}>
                            {props.label}
                            {props.tickMark}
                        </label>
                        <div className="checkbox">
                            <label>
                                <input
                                    type='checkbox'
                                    className={props.className + ' form-control'}
                                    name={props.name}
                                    id={props.id}
                                    value={valueConverter(props.value)}
                                    required={props.required}
                                    disabled={props.disabled}
                                    checked={props.checked}
                                    placeholder={props.placeholder}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    title={props.title}
                                />
                                { /*props.label */}
                            </label>
                        </div>
                    </div>
                </React.Fragment>;

            break;
        case "password":
            i = <div className={classes} style={props.style}>
                <label htmlFor={props.id} className="required" id={'displayName-' + props.name}>
                    {props.label}
                    {props.tickMark} 
                </label>
                {validMessage}
                <div className="input-group">
                    <div className="input-group-addon">
                        <i className={"fa fa-lock "}> </i>
                    </div>
                    {i}
                </div>
            </div>;
            break;
        case "email":
            i = <div className={classes} style={props.style}>
                <label htmlFor={props.id} className="required" id={'displayName-' + props.name}>
                    {props.label}
                    {props.tickMark} 
                </label>
                {validMessage}
                <div className="input-group">
                    <div className="input-group-addon">
                        <i className={"fa fa-envelope-o "}> </i>
                    </div>
                    {i}
                </div>
            </div>;
            break;
        case "input-action":
            i = <div className={classes} style={props.style}>
                <label htmlFor={props.id} className="required" id={'displayName-' + props.name}>
                    {props.label}
                    {props.tickMark} 
                </label>
                {validMessage}
                <div className="input-group ">
                    {i}
                    <div className="input-group-addon cursor-pointer" onClick={props.onActionClick}>
                        <i className={"fa fa-" + props.icon}> </i>
                    </div>
                </div>
            </div>;
            break;
        case "radio": {
            if (props.disabled) {
                if (props.checked) {
                    i = <div className="radio-inline">
                        <label className="input-container input-opacity-0" id={'displayName-' + props.name}>
                            {props.label}
                            {i}
                            <span className="input-checkmark"> </span>
                            {props.tickMark} 
                        </label>
                    </div>;
                } else {
                    i = <div className="radio-inline" style={{cursor: 'not-allowed'}}>
                        <label className={"input-container"} style={{cursor: 'not-allowed'}} id={'displayName-' + props.name}>
                            {props.label}
                            <input
                                type="radio"
                                name={props.name}
                                disabled
                            />
                            <span className="input-checkmark" style={{backgroundColor: '#ddd', borderColor: '#999'}}> </span>
                            {props.tickMark} 
                        </label>
                    </div>
                }
            } else {
                i = <div className="radio-inline">
                    <label className="input-container">
                        {props.label}
                        {i}
                        <span className="input-checkmark"> </span>
                        {props.tickMark} 
                    </label>
                </div>;
            }
            break;
        }
        case "input-icon":
            i = <div className={classes} style={props.style}>
                <label htmlFor={props.id} className="required" id={'displayName-' + props.name}>
                    {props.label}
                    {props.tickMark} 
                </label>
                {validMessage}
                <div className="input-group">
                    <div className="input-group-addon">
                        <i className={"fa fa-" + props.icon}>
                            <b>{!props.icon && props.text}</b>
                        </i>
                    </div>
                    {i}
                </div>
            </div>;
            break;
        case "PHONE_NUMBER":
            i =
                <React.Fragment>
                    {props['labelLeft'] && 
                        <div className='col-md-2'>
                            <label>
                                {props.label}
                                {props.tickMark} 
                            </label>
                        </div>}
                    <div className={classes} style={props.style}>
                        {!props['labelLeft'] && 
                            <label htmlFor={props.id} className="required" id={'displayName-' + props.name}>
                                {props.label}
                                {props.tickMark} 
                            </label>}
                        {props.title && <HelperText helperText={props.title}/>}
                        {validMessage}
                        <div className="input-group" style={{width: '100%'}}>
                            <div className="input-group-addon">
                                <i className={"fa fa-phone"}> </i>
                            </div>
                            {i}
                        </div>
                    </div>
                </React.Fragment>;
            break;
        case "SCORING":
        case "NUMBER":
        case "INTEGER":
        case "YEAR": {
            let nValue = typeof props.value === "string" ? props.value.replace(/[^-0-9]/gim, '') : props.value;
            i = <React.Fragment>
                {props['labelLeft'] && 
                    <div className='col-md-2'>
                        <label>
                            {props.label}
                            {props.tickMark} 
                        </label>
                    </div>}
                <div className={classes} style={props.style}>
                    {!props['labelLeft'] && 
                        <label htmlFor={props.id} className="required" id={'displayName-' + props.name}>
                            {props.label}
                            {props.tickMark} 
                        </label>}
                    {props.title && <HelperText helperText={props.title}/>}
                    {validMessage}
                    {props['actioned'] ?
                        <InputGroupWrapper name={props.name} onActionClick={props.onActionClick} icon={props.icon} text={props.text}>
                            <input
                                type={isSafari() ? 'text' : 'number'}
                                className={' form-control'}
                                name={props.name}
                                id={props.id}
                                value={valueConverter(nValue)}
                                disabled={props.disabled}
                                required={!props.disabled && props.required}
                                checked={props.checked}
                                placeholder={props.placeholder}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                title={props.title}
                                min={props.min}
                                autoComplete="off"
                            />
                        </InputGroupWrapper> :
                        <input
                            type={isSafari() ? 'text' : 'number'}
                            className={' form-control'}
                            name={props.name}
                            id={props.id}
                            value={valueConverter(nValue)}
                            disabled={props.disabled}
                            required={!props.disabled && props.required}
                            checked={props.checked}
                            placeholder={props.placeholder}
                            onChange={props.onChange}
                            onKeyDown={props.onKeyDown}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            title={props.title}
                            min={props.min}
                            max={props.max}
                            autoComplete="off"
                        />}
                </div>
            </React.Fragment>;
            break;
        }
        case "CASE_QUESTION":
        case "textarea":
        case "TEXT":
            i =
                <React.Fragment>
                    {props['labelLeft'] && 
                        <div className='col-md-2'>
                            <label>
                                {props.label}
                                {props.tickMark} 
                            </label>
                        </div>}
                    <div className={classes} style={props.style}>
                        {!props['labelLeft'] && 
                            <label htmlFor={props.id} className="required" id={'displayName-' + props.name}>
                                {props.label}
                                {props.tickMark} 
                            </label>}
                        {props.title && <HelperText helperText={props.title}/>}
                        {validMessage}
                        {props['actioned'] ?
                            <InputGroupWrapper name={props.name} onActionClick={props.onActionClick} icon={props.icon}>
                    <textarea
                        name={props.name}
                        id={props.id}
                        value={valueConverter(props.value)}
                        className={' form-control'}
                        required={props.required}
                        disabled={props.disabled}
                        rows={props.rows}
                        placeholder={props.placeholder}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        title={props.title}
                        style={{resize: 'vertical'}}
                    />
                            </InputGroupWrapper> :
                            <textarea
                                name={props.name}
                                id={props.id}
                                value={valueConverter(props.value)}
                                className={' form-control'}
                                required={props.required}
                                disabled={props.disabled}
                                rows={props.rows}
                                placeholder={props.placeholder}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                title={props.title}
                                style={{resize: 'vertical'}}
                            />
                        }
                    </div>
                </React.Fragment>;
            break;

        case "CASE_QUESTION_CASE": {
            let inner = [];
            if (!props['answers'] || props['answers'] === 'hide') {
                for (let k = 0; k < props['questionsCount']; k += 1) {
                    inner.push(
                        <div key={props.id + '_' + k}>
                        <textarea
                            name={props.name + '_' + k}
                            id={'CQC' + k}
                            value={typeof props.value === "object" ? props.value[k] : (k === 0 ? props.value : '')}
                            className={' form-control'}
                            required={props.required}
                            disabled={props.disabled}
                            rows={props.rows || 5}
                            placeholder={props.placeholder}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            title={props.title}
                        />
                        </div>
                    )
                }
            } else {
                for (let k = 0; k < props['questionsCount']; k += 1) {
                    inner.push(
                        <span> <br/>{typeof props.value === "object" ? props.value[k] : (k === 0 ? props.value : '')}
                            {(props['answers'] && props['answers'] === 'edit') ?
                                <textarea
                                    name={props.name + '_a_' + k}
                                    id={'ACQCa_' + k}
                                    value={props.value['a_' + k]}
                                    className={' form-control'}
                                    required={props.required}
                                    disabled={props.disabled}
                                    rows={props.rows || 5}
                                    placeholder={props.placeholder}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    title={props.title}
                                />
                                :
                                <span><br/><b>Answer:</b> {props.value['a_' + k]}</span>
                            }
                        </span>
                    );
                }
            }
            i = <div className={classes} style={props.style}>
                <label htmlFor={props.id} className="required" id={'displayName-' + props.name}>
                    {props.label}
                    {props.tickMark} 
                </label>
                {validMessage}
                {inner}
            </div>;
            break;
        }
        case "label_field": {
            let labValue = props.value;
            if (Object.prototype.toString.call(labValue) === '[object Object]') {
                let valResult = [];
                for (let labKey in labValue) {
                    if (!labValue.hasOwnProperty(labKey)) continue;
                    valResult.push(labValue[labKey]);
                }
                labValue = valResult.join(', ');
            }
            i = <div className={`${classes} text-${props['aligment']} ${props.className}`} style={props.style}>
                <label htmlFor={props.id} className="required" id={'displayName-' + props.name}>
                    {props.label}
                    {props.tickMark} 
                </label><br/>
                {<span> {labValue}</span>}
            </div>;
            break;
        }
        default:
            i =
                <React.Fragment>
                    {props['labelLeft'] && 
                        <div className='col-md-2'>
                            <label>
                                {props.label}
                                {props.tickMark} 
                            </label>
                        </div>}
                    <div className={classes} style={props.style}>
                        {!props['labelLeft'] &&
                        <label htmlFor={props.id} className="required invalid_form_field" id={'displayName-' + props.name}>
                            {props.label}
                            {props.tickMark} 
                        </label>}
                        {props.title && <HelperText helperText={props.title}/>}
                        {validMessage}
                        {i}
                    </div>
                </React.Fragment>;
            break;
    }

    return i
};

export default InputField;
