import api from "../../utils/api";
import {Statuses} from "./statuses";
import { isEntityLoaded, makeUrlFromType } from '../../utils/helpers'
import history from '../history'

const getUrl = (entity) => {
    let url;
    switch (entity) {
        case 'mc_system':
            url = 'mc_system';
            break;
        case 'case_determination':
            url = 'case_determination';
            break;
        case 'sc_system':
            url = 'sc_system';
            break;
        case 'medical/diagnosis':
        case 'medical_diagnosis':
        case 'med_diagnosis':
        case 'diagnosis':
            url = 'med_diagnosis';
            entity = 'diagnosis';
            break;
        case 'medical_service':
        case 'medical_services':
        case 'med_service':
        case 'procedure':
            url = 'med_service';
            entity = 'procedure';
            break;
        case 'specialty':
            url = 'speciality';
            break;
        case 'time_zone':
            url = 'time_zone';
            break;
        case 'case_level':
            url = 'case_level';
            break;
        default:
            url = makeUrlFromType(entity);
    }
    return {url: url, entity: entity};
};

const isAvailableInCache = (basicEntity) =>
    isEntityLoaded(basicEntity) && basicEntity.data && basicEntity.data.length > 0;

export const getAll = (entity1, basicEntity) => (dispatch) => {
    if (isAvailableInCache(basicEntity)) {
        return;
    }
    const {url, entity} = getUrl(entity1);
    dispatch({
        type: `FETCH_${entity.toUpperCase()}_LOADING`,
    });
    return api.get(`entity/${url}/all`)
        .then(({data}) => {
            dispatch({
                type: `FETCH_${entity.toUpperCase()}_SUCCESS`,
                payload: data
            });
            dispatch({
                type: `FILL_${entity.toUpperCase()}_LOCAL_STORAGE`
            });

            return;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                dispatch({
                    type: `FETCH_${entity.toUpperCase()}_ERROR`,
                    payload: Statuses[error.response.status]
                })
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: `FETCH_${entity.toUpperCase()}_ERROR`,
                    payload: error.message
                });
            }

            return Promise.reject(error);
        });

};

export const getAllIfEmpty = (entity) => (dispatch, getState) => {
    let path;
    let arr = 'data';

    path = entity === 'case_determination' ? 'case_determination' : entity + 's';

    let data = getState().app[path][arr];
    if (data.length === 0) {
        return dispatch(getAll(entity));
    }
};

export const getOne = (code, entity1) => (dispatch) => {
    if (!code) return;
    const {url, entity} = getUrl(entity1);

    dispatch({
        type: `FETCH_${entity.toUpperCase()}_LOADING`,
    });

    return api.get(`${url}/get?code=${code}`)
        .then(({data}) => {
            dispatch({
                type: `FETCH_${entity.toUpperCase()}_PROFILE_SUCCESS`,
                payload: data
            });

            return data
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: `FETCH_${entity.toUpperCase()}_PROFILE_ERROR`,
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: `FETCH_${entity.toUpperCase()}_PROFILE_ERROR`,
                    payload: error.message
                });
            }
        })
};

export const setLoadedCount = (count, entity) => (dispatch) => {
    dispatch({
        type: `FETCH_${entity.toUpperCase()}_LOADED_COUNT`,
        payload: count
    })
};

export const getOne4Select = (code, entity1) => (dispatch) => {
    const {url, entity} = getUrl(entity1);
    if (!code) return;

    dispatch({
        type: `FETCH_${entity.toUpperCase()}_LOADING`,
    });

    let backName = entity === 'user' ? 'auth' : 'entity';

    return api.get(`${backName}/${url}/get?code=${code}`)
        .then(({data}) => {
            dispatch({
                type: `FETCH_${entity.toUpperCase()}_4SELECT_SUCCESS`,
                payload: data
            });
            dispatch({
                type: `FILL_${entity.toUpperCase()}_LOCAL_STORAGE`
            });
            return data;
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: `FETCH_${entity.toUpperCase()}_PROFILE_ERROR`,
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: `FETCH_${entity.toUpperCase()}_PROFILE_ERROR`,
                    payload: error.message
                });
            }
        })
};

export const createEntity = (entity1, data) => (dispatch) => {
    const {url, entity} = getUrl(entity1);

    dispatch({
        type: `FETCH_${entity.toUpperCase()}_LOADING`,
    });

    return api.post(`${url}/create`, data)
        .then(({config}) => {
            dispatch({
                type: `FETCH_CREATE_${entity.toUpperCase()}_SUCCESS`,
                payload: JSON.parse(config.data)
            });
            dispatch({
                type: `CLOSE_CREATE_MODAL`,
            });
            return true
        })
        .catch((err) => {
            dispatch({
                type: `FETCH_${entity.toUpperCase()}_ERROR`,
                payload: Statuses[err.response ? err.response.status : err.request.status]
            })
        })

};

export const updateEntity = (entity1, data, closeAfterSave) => (dispatch) => {
    const {url, entity} = getUrl(entity1);

    dispatch({
        type: `FETCH_${entity.toUpperCase()}_LOADING`,
    });

    return api.post(`${url}/update`, data)
        .then(({config}) => {
            dispatch({
                type: `FETCH_UPDATE_${entity.toUpperCase()}_SUCCESS`,
                payload: JSON.parse(config.data)
            });
            closeAfterSave && history.goBack()
        })
        .catch((err) => {
            dispatch({
                type: `FETCH_UPDATE_${entity.toUpperCase()}_ERROR`,
                payload: Statuses[err.response ? err.response.status : err.request.status]
            })
        })

};

export const getEntityProfile = (entity1, code) => (dispatch) => {
    const {url, entity} = getUrl(entity1);

    dispatch({
        type: `FETCH_${entity.toUpperCase()}_PROFILE_LOADING`,
    });

    return api.get(`${url}/get?code=${code}`)
        .then(({data}) => {
            dispatch({
                type: `FETCH_${entity.toUpperCase()}_PROFILE_SUCCESS`,
                payload: data
            })
        })
        .catch((err) => {
            dispatch({
                type: `FETCH_${entity.toUpperCase()}_PROFILE_ERROR`,
                payload: Statuses[err.response ? err.response.status : err.request.status]
            })
        })

};

export const getEntitiesFromCodes = (entity, codes) => {
    const url = getUrl(entity);

    return api.post(`${url}/determined`, codes)
};

export const entityEditField = (field, value, entity) => ({
    type: `${entity.toUpperCase()}_EDIT_FIELD`,
    payload: {
        [field]: value
    }
});

export const isEntityValid = (valid, entity) => {
    return {
        type: `VALIDATE_${entity.toUpperCase()}_FIELD`,
        payload: valid
    }
};

export const setCurrentTab = (tab, entity) => {
    return {
        type: `SET_${entity.toUpperCase()}_CURRENT_TAB`,
        payload: tab
    }
};

export const showValidation = (entity) => {
    return {
        type: `SHOW_${entity.toUpperCase()}_VALIDATION`,
    }
};
