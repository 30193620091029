import api from "../../utils/api";
import {Statuses} from "./statuses";
import history from '../history'

export const createNewDiagnose = (diagnose) => (dispatch) => {
    dispatch({
        type: 'FETCH_DIAGNOSE_LOADING',
    })

    api.post('med_diagnosis/create', diagnose)
        .then((res) => {
            dispatch({
                type: 'FETCH_CREATE_DIAGNOSE_SUCCESS',
                payload: diagnose
            })
            dispatch({
                type:'CLOSE_CREATE_MODAL'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_DIAGNOSE_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_DIAGNOSE_ERROR',
                    payload:Statuses[error.request.status]
                });
            }
        })

}

export const editDiagnose = (data, closeAfterSave) => dispatch => {
    dispatch({
        type: 'FETCH_EDIT_DIAGNOSE_LOADING',
    })

    return api.post('med_diagnosis/update', data)
        .then(({config}) => {
            dispatch({
                type: 'FETCH_EDIT_DIAGNOSE_SUCCESS',
                payload: JSON.parse(config.data)
            })
            closeAfterSave && history.goBack()
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_EDIT_DIAGNOSE_ERROR',
                payload: Statuses[err.response] || Statuses[0]
            })
        })
}

export const getDiagnose = (code) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_DIAGNOSE_LOADING',
    })

    return api.get(`med_diagnosis/get?code=${code}`)
    .then(({data}) => {
      const mcSystems = getState().app.mcSystem.data

      const systemToCode = data.medicalCodingSystemCodes ? data.medicalCodingSystemCodes.reduce((systemToCode, medicalCode) => {
        const system = mcSystems.find((mcSystem) => mcSystem.medicalCodeCodes.indexOf(medicalCode) !== -1)
        const systemCode = system ? system.code : null

        systemToCode[systemCode] = systemToCode[systemCode] ? [...systemToCode[systemCode], medicalCode] : [medicalCode]
        return systemToCode
      }, {}) : {}

      dispatch({
          type:'FETCH_GET_DIAGNOSE_SUCCESS',
          payload: code ? {
             ...data,
             systemToCode,
             names: data.names ? data.names : {'EN':data.name}
           } : null
      })
    })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_DIAGNOSE_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_DIAGNOSE_ERROR',
                    payload: error.message
                });
            }
        })
}

export const editField = (field, value) => ({
    type: 'DIAGNOSE_EDIT_FIELD',
    payload:{
        [field]: value
    }
})

export const isAllValid = valid => {
    return {
        type:'VALIDATE_DIAGNOSE_FIELD',
        payload: valid
    }
}
