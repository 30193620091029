import React, { useCallback, useEffect, useState } from 'react';
import './CaseComments.css';
import { Button, Col, Label, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addComment, getCaseComments } from '../../../../redux/actions/caseCommentsActions';
import PropTypes from 'prop-types';
import CaseCommentsThreadList from './CaseCommentsThreadList';
import Select from 'react-select';

import { editComment, resetComment, toggleExpanded } from '../../../../redux/pages/case-comments/caseCommentThreadsSlice';
import Spinner from '../../../Develop/Spinner';
import SystemMessage from '../../../../components/SystemMessage';
import CaseCommentsAvatar from './CaseCommentsAvatar';
import { commentRoles } from '../../../../utils/Constants';
import { isClient, isReviewer } from 'utils/helpers';

const CaseCommentsForm = ({ caseEntityCode }) => {
  const dispatch = useDispatch();

  const { comment, fetching, saving, dirty, expanded } = useSelector(
    (state) => {
      return state.app.caseCommentThreads;
    },
  );

  const { user } = useSelector((state) => {
    return state.app.auth;
  });

  const setUserRole = () => {
    if(isReviewer(user.roleCode)) return commentRoles[0];
    else if(isClient(user.roleCode)) return commentRoles[3];
    return commentRoles[1];
  }

  const [assignedUserRole, setAssignedUserRole] = useState(setUserRole);

  const handleSelectChange = (value) => {
    setAssignedUserRole(value);
  };

  useEffect(() => {
    dispatch(getCaseComments(caseEntityCode));
    return () => {
      dispatch(resetComment());
    };
  }, [dispatch, caseEntityCode]);

  const handleEditComment = useCallback(
    ({ target: { value: nextValue } }) => {
      dispatch(editComment(nextValue));
    },
    [dispatch],
  );

  const handleClickToggle = () => {
    dispatch(toggleExpanded());
  };

  const handleClickAdd = () => {
    if (!saving && !fetching) {
      dispatch(addComment(comment, caseEntityCode, assignedUserRole.value));
    }
  };

  return (
    <div
      className="box box-primary case-comments-section"
      id={'blockedContainerInternal'}
    >
      <div className="box-header with-border">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a id="_attachments_">
          <h3 className="box-title text-blue">
            <i className="fa fa-comments margin-right-5"> </i>
            Comments
          </h3>
        </a>
        <div className="box-tools pull-right toggle-box">
          <button
            type="button"
            className="btn btn-box-tool toggle-btn remove-reviewer-data"
            onClick={handleClickToggle}
          >
            <i
              className={
                'fa toggle-fa-icon-reviewer-info ' +
                (expanded ? 'fa-minus' : 'fa-plus')
              }
            >
              {' '}
            </i>
          </button>
        </div>
      </div>
      <div
        className="box-body"
        style={{ display: expanded ? 'block' : 'none' }}
      >
        <SystemMessage
          type="error"
          validationMessages={[
            {
              id: 'Comment',
              field: 'Comment',
              message: 'The Comment field is required',
            },
          ]}
          shown={dirty && !comment}
          top="7em"
        />
        <Row>
          <Col md={10}>
            <div style={{ display: 'flex' }}>
              <div className="mg-right-10">
                <CaseCommentsAvatar user={user}/>
              </div>
              <div style={{ width: '100%' }}>
                <label
                  style={{ color: dirty && !comment ? ' #dd4b39' : 'black' }}
                >
                  Write a comment
                </label>
                <textarea
                  rows={8}
                  value={comment}
                  onChange={handleEditComment}
                  style={{
                    border:
                      dirty && !comment
                        ? ' 1px #dd4b39 solid'
                        : '1px black solid',
                  }}
                />
          { isClient(user.roleCode) ? '' : !isReviewer(user.roleCode) && 
          <>
            <Label>Assignee</Label>
              <Select
                options={commentRoles}
                value={assignedUserRole}
                onChange={handleSelectChange}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isOptionDisabled={(option) => option.disabled}
              />
            </>
          }
              </div>
            </div>
          </Col>
          <Col md={10}>
            <Button
              type="submit"
              bsStyle="primary"
              style={{
                width: '60px',
                display: 'inline-flex',
                justifyContent: 'center',
              }}
              className="btn btn-flat pull-right mg-top-10"
              onClick={handleClickAdd}
              disabled={saving || fetching}
            >
              <span>{'Add'}</span>&nbsp;{saving && <Spinner/>}
            </Button>
          </Col>
        </Row>
        <CaseCommentsThreadList/>
      </div>
    </div>
  );
};

CaseCommentsForm.propTypes = {
  caseEntityCode: PropTypes.string,
  section: PropTypes.object,
};

CaseCommentsForm.defaultProps = {};

export default CaseCommentsForm;
