import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './ControlDropZone.css';
import PropTypes from 'prop-types';
import { ControlContext } from '../Form';

export const MedicalDropZone = ({ fileName, onChange, accept, children = null }) => {
  const { validationState } = useContext(ControlContext);
  const isInvalid = validationState === 'error';

  const [value, setValue] = useState(fileName);

  useEffect(() => {
    setValue(fileName);
  }, [fileName]);

  const handleChange = useCallback(
    async (acceptedFile) => {
      const files = acceptedFile;

      if (!files) {
        return;
      }

      onChange({ target: { files } });
    },
    [onChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop: handleChange,
    multiple: true,
    noClick: true
  });

  return (
    <div className="medical-dropzone-container">
      <div
        {...getRootProps({
          onClick: null
        })}
        className={`medical-dropzone ${isInvalid ? 'dropzone-error' : ''}`}
      >
        {isDragActive && 
        <div className="medical-target">
          <span className="dropzone-drag-text text-center">
            {value ? value : 'Drag & Drop the file here ...'}
          </span>
        </div>}
        {children}
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

MedicalDropZone.propTypes = {
  fileName: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  accept: PropTypes.arrayOf(PropTypes.string),
};

export default MedicalDropZone;
