/* eslint-disable object-shorthand */
/* eslint-disable comma-dangle */
import { createApiParams } from 'components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import api from '../../../utils/api';
import queuedMessageSlice from './queuedMessageSlice';
import { transformResponseToTableData } from '../../../components/Datatable/dataTableUtils';
import moment from "moment";

const { loadData, dataLoaded, dataLoadError } = queuedMessageSlice.actions;

const transformQueuedMessages = transformResponseToTableData((data) => {
    return {
        data: data.map(
            ({ id, notificationTemplateName, patientCaseId, queuedFor, sentAt, status }) => ({
                id,
                notificationTemplateName,
                patientCaseId,
                queuedFor: moment(queuedFor).utc(false).format('MMMM DD, YYYY HH:mm:ss'),
                sentAt: sentAt ? moment(sentAt).utc(false).format('MMMM DD, YYYY HH:mm:ss') : '',
                status,
            }),
        ),
    };
});

const loadQueuedMessageEpic = (action$) =>
    action$.pipe(
        filter(loadData.match),
        switchMap(({ payload: params }) => {
            return from(
                api.get('/notifications/page', {
                    params: createApiParams(params),
                }),
            ).pipe(map((response) => {
                // console.log('response', response);
                return response.data;
            }));
        }),
        map(transformQueuedMessages),
        map(dataLoaded),
        catchError((error) => {
            console.error(error);
            return of(dataLoadError(JSON.stringify(error)));
        }),
    );

export default combineEpics(loadQueuedMessageEpic);
