import React from 'react';
import {connect} from 'react-redux'
import {Redirect} from "react-router-dom";
import {isUser} from '../../utils/helpers'
import ClientsManagePage from './ClientsManagePage'

class CompanyEditPage extends React.Component {
    render() {
        return isUser(this.props.currentUser.roleCode) ?
            <Redirect to={'clients/profile/' + this.props.currentUser.companyCode}/> :
            <ClientsManagePage/>
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.app.auth.user,
});

const mapDispatchToProps = () => {
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditPage);
