import createLinkingConnectedDataTableSlice from "../../../utils/createLinkingConnectedDataTableSlice";

const clientEditMedicalServicesDiagnosesSlice = createLinkingConnectedDataTableSlice(
    'clientEditMedicalServicesDiagnoses',
    'name'
);

export const {
    loadData,
    linkingEntities, unlinkingEntities, linkingEntitiesSuccess, linkingEntitiesError,
} = clientEditMedicalServicesDiagnosesSlice.actions;

export const clientEditMedicalServicesDiagnosesSliceReducer = clientEditMedicalServicesDiagnosesSlice.reducer;

export default clientEditMedicalServicesDiagnosesSlice;
