import React from 'react';
import PropTypes from 'prop-types';
import useResizeListener from './hooks/useResizeListener';

const DataTableContainer = ({ children, setContainerWidth, className }) => {
  const containerRef = useResizeListener(setContainerWidth);

  return (
    <div className={'datatable ' + className} ref={containerRef}>
      {children}
    </div>
  );
};

DataTableContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  setContainerWidth: PropTypes.func.isRequired,
};

export default DataTableContainer;
