import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ClientAddMCSystemPanel from './ClientAddMCSystemPanel';
import LinkingConnectedDataTable from './common/LinkingConnectedDataTable';
import clientEditMedicalServicesServicesSlice from '../../redux/pages/client-edit-medical-services/services/clientEditMedicalServicesServicesSlice';
import { openEditModal } from '../../redux/action-creators/users';
import ClientEditProcedureForm from './ClientEditProcedureForm';
import ClientEditDiagnoseForm from './ClientEditDiagnoseForm';
import EditModal from '../EditModal';
import clientEditMedicalServicesDiagnosesSlice
  from '../../redux/pages/client-edit-medical-services/diagnoses/clientEditMedicalServicesDiagnosesSlice';
import {
  linkClientEditServices,
  unlinkClientEditServices,
} from '../../redux/pages/client-edit-medical-services/services/clientEditMedicalServicesServicesActions';
import {
  linkClientEditDiagnoses,
  unlinkClientEditDiagnoses,
} from '../../redux/pages/client-edit-medical-services/diagnoses/clientEditMedicalServicesDiagnosesActions';
import ColoredConnectedDataTable from './common/ColoredConnectedDataTable';

const columnsServices = {
  name: { label: 'Service', colStyle: { width: '15%' } },
  specialityNames: { label: 'Specialty', colStyle: { width: 'auto' } },
  bodyPartNames: { label: 'Body Part', colStyle: { width: '15%' } },
  linked: {
    label: 'Linking',
    sortable: false,
    colStyle: { width: '12%' },
    filterOptions: [
      { value: true, label: 'Linked' },
      { value: false, label: 'Unlinked' },
    ],
  },
};

const columnsDiagnoses = {
  name: { label: 'Diagnoses', colStyle: { width: '15%' } },
  specialityNames: { label: 'Specialty', colStyle: { width: 'auto' } },
  bodyPartNames: { label: 'Body Part', colStyle: { width: '15%' } },
  linked: {
    label: 'Linking',
    sortable: false,
    colStyle: { width: '12%' },
    filterOptions: [
      { value: true, label: 'Linked' },
      { value: false, label: 'Unlinked' },
    ],
  },
};

const ClientEditMedicalServices = (
  {
    validator,
    dispatchValidStatus,
    isEditable,
  },
) => {

  const dispatch = useDispatch();

  const { code, medicalCodingSystemCodes } = useSelector((state) => {
    return state.app.companies.editedCompany;
  });

  const { selectedEntities: selectedServices, settings: servicesDatatableSettings } = useSelector((state) => {
    // console.log('STATE APP', state.app.clientEditMedicalServicesServices);
    return state.app.clientEditMedicalServicesServices;
  });

  const { selectedEntities: selectedDiagnoses, settings: diagnosesDatatableSettings } = useSelector((state) => {
    // console.log('STATE APP', state.app.clientEditMedicalServicesServices);
    return state.app.clientEditMedicalServicesDiagnoses;
  });

  const { search } = useSelector((state) => {
    // console.log('APP:', state.app);
    return state.app.globalSearch;
  });

  const [clickedCode, setClickedCode] = useState(null);
  const [editedEntity, setEditedEntity] = useState('');

  const handleServicesRowClick = ({ code }) => {
    // console.log('handleServicesRowClick', code);
    setClickedCode(code);
    setEditedEntity('services');
    dispatch(openEditModal());
  };

  const handleDiagnosesRowClick = ({ code }) => {
    // console.log('handleDiagnosesRowClick', code);
    setClickedCode(code);
    setEditedEntity('diagnoses');
    dispatch(openEditModal());
  };

  const handleLinkServicesSubmit = () => {
    // console.log('handleLinkServicesClick');
    const selectedServicesCodes = selectedServices.map(({ code }) => code);
    dispatch(linkClientEditServices(selectedServicesCodes, servicesDatatableSettings, search, code, medicalCodingSystemCodes));
  };

  const handleUnlinkServicesSubmit = () => {
    // console.log('handleUnlinkServicesClick');
    const selectedServicesCodes = selectedServices.map(({ code }) => code);
    dispatch(unlinkClientEditServices(selectedServicesCodes, servicesDatatableSettings, search, code, medicalCodingSystemCodes));
  };

  const handleLinkDiagnosesSubmit = () => {
    // console.log('handleLinkServicesClick');
    const selectedDiagnosesCodes = selectedDiagnoses.map(({ code }) => code);
    dispatch(linkClientEditDiagnoses(selectedDiagnosesCodes, diagnosesDatatableSettings, search, code, medicalCodingSystemCodes));
  };

  const handleUnlinkDiagnosesSubmit = () => {
    // console.log('handleUnlinkServicesClick');
    const selectedDiagnosesCodes = selectedDiagnoses.map(({ code }) => code);
    dispatch(unlinkClientEditDiagnoses(selectedDiagnosesCodes, diagnosesDatatableSettings, search, code, medicalCodingSystemCodes));
  };

  return (
    <div>
      <ClientAddMCSystemPanel
        validator={validator}
        dispatchValidStatus={dispatchValidStatus}
        isEditable={isEditable}
      />
      {
        isEditable ?
          <>
            <LinkingConnectedDataTable
              title="Services"
              columns={columnsServices}
              stateKey="clientEditMedicalServicesServices"
              actions={clientEditMedicalServicesServicesSlice.actions}
              onRowClick={handleServicesRowClick}
              onLinkClick={handleLinkServicesSubmit}
              onUnlinkClick={handleUnlinkServicesSubmit}
              medicalCodingSystemCodes={medicalCodingSystemCodes}
            />
            <LinkingConnectedDataTable
              title="Diagnoses"
              columns={columnsDiagnoses}
              stateKey="clientEditMedicalServicesDiagnoses"
              actions={clientEditMedicalServicesDiagnosesSlice.actions}
              onRowClick={handleDiagnosesRowClick}
              onLinkClick={handleLinkDiagnosesSubmit}
              onUnlinkClick={handleUnlinkDiagnosesSubmit}
              medicalCodingSystemCodes={medicalCodingSystemCodes}
            />
          </>
          :
          <>
            <ColoredConnectedDataTable
              title="Services"
              columns={columnsServices}
              stateKey="clientEditMedicalServicesServices"
              actions={clientEditMedicalServicesServicesSlice.actions}
              onRowClick={handleServicesRowClick}
              medicalCodingSystemCodes={medicalCodingSystemCodes}
            />
            <ColoredConnectedDataTable
              title="Diagnoses"
              columns={columnsDiagnoses}
              stateKey="clientEditMedicalServicesDiagnoses"
              actions={clientEditMedicalServicesDiagnosesSlice.actions}
              onRowClick={handleDiagnosesRowClick}
              medicalCodingSystemCodes={medicalCodingSystemCodes}
            />
          </>
      }
      <EditModal
        H3Name='common.client.edit_procedure'
      >
        {editedEntity === 'services' &&
        <ClientEditProcedureForm procedureCode={clickedCode} />}
        {editedEntity === 'diagnoses' &&
        <ClientEditDiagnoseForm diagnoseCode={clickedCode} />}
      </EditModal>
    </div>
  );
};

ClientEditMedicalServices.propTypes = {
  validator: PropTypes.object,
  dispatchValidStatus: PropTypes.func,
  isEditable: PropTypes.bool,
  history: PropTypes.object,
};

export default ClientEditMedicalServices;
