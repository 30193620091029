import DataTable from 'components/Datatable/DataTable';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { defaultSettingsGlobal } from './dataTableUtils';
import useClientFilterPagination from './hooks/useClientFilterPagination';
import useLocalStorage from 'utils/hooks/useLocalStorage';

const propsAreEqual = (prev, next) => {
  const { length: prevLength } = prev.data || { length: null };
  const { length: nextLength = null } = next.data || { length: null };
  if ([prevLength, nextLength].includes(null)) {
    return false;
  }

  return prevLength === nextLength && prev.loading && next.loading;
};

const ClientSideDataTable = ({
  data,
  columns,
  initialSettings,
  stateKey,
  ...props
}) => {
  const defaultSettings = {
    ...defaultSettingsGlobal,
    ...initialSettings,
  };

  const useStateHook = stateKey ? useLocalStorage : useState;
  const settingsInitialParams = stateKey
    ? [stateKey, defaultSettings]
    : [defaultSettings];

  const [storedSettings, changeSettings] = useStateHook(
    ...settingsInitialParams,
  );

  const multiColumns = Object.keys(columns).filter(
    (key) => columns[key].multiSelect === true,
  );

  const handleChangeSettings = useCallback(
    (settingsToChange) => {
      changeSettings({ ...storedSettings, ...settingsToChange });
    },
    [changeSettings, storedSettings],
  );

  const {
    totalRecords,
    totalRecordsFiltered,
    sortedData,
    filterOptions,
  } = useClientFilterPagination(storedSettings, data, multiColumns);

  return (
    <DataTable
      {...props}
      columns={columns}
      totalRecords={totalRecords}
      totalRecordsFiltered={totalRecordsFiltered}
      data={sortedData}
      onSettingsChange={handleChangeSettings}
      defaultSettings={storedSettings}
      filterOptions={filterOptions}
      initialSettings={initialSettings}
      loadingPlaceholderCount={initialSettings.pageSize}
    />
  );
};

ClientSideDataTable.propTypes = {
  stateKey: PropTypes.string,
  actions: PropTypes.object,
  data: PropTypes.array.isRequired,
  columns: PropTypes.object.isRequired,
  initialSettings: PropTypes.object,
};

ClientSideDataTable.defaultProps = {
  data: [],
  initialSettings: {},
};

export default React.memo(ClientSideDataTable, propsAreEqual);
