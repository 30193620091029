import React from 'react';
import { review } from 'redux/pages/patient-case/patientCaseColumns';
import reviewSlice from 'redux/pages/patient-case/slices/reviewSlice';
import PatientCaseGrid from '../Components/PatientCaseGrid';

const columns = review;

const Review = (props) => (
  <PatientCaseGrid
    variant="warning"
    columns={columns}
    slice={reviewSlice}
    {...props}
  />
);

export default Review;
