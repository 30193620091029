import api from '../../utils/api';
import { fieldsErrors } from './statuses';
import {
    addedComment,
    addingComment,
    fetchingCommentThreads,
    fetchThreadListError,
    fetchThreadListSuccess,
} from '../pages/case-comments/caseCommentThreadsSlice';
import shortId from 'shortid';

export const getCaseComments = (code) => (dispatch) => {
    dispatch(fetchingCommentThreads());
    return api
        .get('case/thread/all?caseCode=' + code)
        .then((res) => {
            dispatch(fetchThreadListSuccess(res));
        })
        .catch((error) => {
            if (error.response) {
                dispatch(fetchThreadListError(fieldsErrors[error.response.status]));
            } else if (error.request) {
                console.error(error.request);
                dispatch(fetchThreadListError(error));
            } else {
                console.error('Error ', error.message);
            }
        });
};

export const addComment = (comment, patientCaseCode, assignedUserRole) => (dispatch) => {
    dispatch(addingComment());
    if (!comment) {
        // required
        return;
    }
    const entity = {
        patientCaseCode: patientCaseCode,
        message: comment,
        assigneeType: assignedUserRole,
        //backend need a HUGE refactor to avoid this!!!
        code: shortId.generate(),
        active: true,
    };
    return api
        .post('case/thread/create', entity)
        .then((res) => {
            // console.log('res', res);
            dispatch(addedComment());
            dispatch(getCaseComments(patientCaseCode));
        })
        .catch((error) => {
            // console.log('catch', error.response);
            if (error.response) {
                dispatch(fetchThreadListError(fieldsErrors[error.response.status]));
            } else if (error.request) {
                console.error(error.request);
                dispatch(fetchThreadListError(error));
            } else {
                console.error('Error ', error.message);
            }
        });
};
