import React from 'react'
import {connect} from 'react-redux'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../components/LoadingIndicator";
import {getTemplates} from "../../redux/actions/templateActions";
import {getNameFromCode, getNamesFromCodes} from '../../utils/helpers'
import PropTypes from "prop-types";

class TemplatesList extends React.Component {

    constructor(props) {
        super(props);
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
        this.props.getTemplates();
    }

    onRowClick(item) {
        this.props.history.push(`templates/update/${item}`)
    }

    render() {
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true,
                'sort': 'asc'
            }, {
                'name': 'name',
                'title': 'Template Name',
                'sort': 'asc',
                'width': '200',
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            }, {
                'name': 'sectionCodes',
                'title': 'Sections Count',
                'sort': 'asc',
                'width': '200',
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            }, {
                'name': 'fieldInstanceCodes',
                'title': 'Fields Count',
                'sort': 'asc',
                'width': '200',
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            }, {
                'name': 'active',
                'title': 'Status',
                'dataFormat': (cell, row, enumObject) => {
                    return enumObject[cell]
                },
                'sort': 'asc',
                'formatExtraData': {
                    'false': 'Inactive',
                    'true': 'Active'
                },
                'width': '100',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': 'Inactive',
                        'true': 'Active'
                    }
                }
            }
        ];
        const {isLoading} = this.props;
        const {sorting, pagination} = this.props.gridConfiguration;
        return (
            <div className='container-fluid'>
                <div className="table-responsive" style={{marginTop: '15px', "padding": '10px'}}>
                    {isLoading && <LoadingIndicator type="lister"/>}
                    <RemoteGrid
                        color="blue"
                        title="Templates"
                        entity="template"
                        data={this.props.data}
                        total={this.props.totalElements}
                        columns={columns}
                        pagination={true}
                        page={pagination.page}
                        sizePerPage={pagination.sizePerPage}
                        search={true}
                        onRowClick={this.onRowClick}
                        defaultSort
                        sortName={sorting.sortName}
                        sortOrder={sorting.sortOrder}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        gridConfiguration: PropTypes.object,
    }
    static defaultProps = {
        isLoading: false
    };
}

const mapState = (state) => {
    return {
        data: state.app.templates.allTemplates
            // .filter((item) => item.active)
            .map((item) => ({
                ...item,
                workflowCode: getNameFromCode(item.workflowCode, []),
                name: item.name.replace(/^\s+/g, ''),
                fieldInstanceCodes: item.fieldInstanceCodes ? item.fieldInstanceCodes.length : 0,
                sectionCodes: item.sectionCodes ? item.sectionCodes.length : 0,
                templateQuestionCodes: getNamesFromCodes(item.templateQuestionCodes, [])
            })),
        totalElements: state.app.templates.allTemplates.length,
        isLoading: state.app.templates.loading,
        gridConfiguration: state.app.grid.template,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTemplates: () => dispatch(getTemplates()),
    }
};

export default connect(mapState, mapDispatchToProps)(TemplatesList);
