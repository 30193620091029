/* eslint-enable react-hooks/exhaustive-deps */
/* eslint "react-hooks/exhaustive-deps": "error" */

import { difference, intersection } from 'lodash';

const { useState, useEffect } = require('react');
const { useCallback } = require('react');

const useRowSelect = ({
    onRowSelect,
    onSelectedRowsChange,
    onRowSelectAll,
    onRowDeselect,
    onRowDeselectAll,
    data,
    keyExtractor,
    singleSelect,
    selectedKeys,
}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllValue, setSelectAllValue] = useState(false);

    useEffect(() => {
        if (selectedKeys !== null) {
            setSelectedRows(selectedKeys);
        }
    }, [selectedKeys]);

    const handleRowSelect = useCallback(
        (id, rowData) => {
            if (selectedRows.includes(id)) {
                onRowDeselect(id, rowData);
            } else {
                onRowSelect(id, rowData);
            }

            let nextRows = selectedRows.includes(id) ? [] : [id];
            if (!singleSelect) {
                nextRows = selectedRows.includes(id) ? selectedRows.filter((selectedId) => selectedId !== id) : [...selectedRows, id];
            }

            setSelectedRows(nextRows);
            onSelectedRowsChange(nextRows);
        },
        [onRowSelect, onSelectedRowsChange, selectedRows, onRowDeselect, singleSelect],
    );

    const handleRowSelectAll = useCallback(() => {
        if (singleSelect) return;

        const selectedKeys = data.map(keyExtractor);

        const rowsToDeselect = intersection(selectedRows, selectedKeys);
        const rowsToSelect = difference(selectedKeys, rowsToDeselect);

        if (selectAllValue) {
            setSelectedRows([]);
            onRowDeselectAll(
                rowsToDeselect,
                data.filter((item) => rowsToDeselect.includes(keyExtractor(item))),
            );
            onSelectedRowsChange([]);
        } else {
            setSelectedRows(selectedKeys);
            onRowSelectAll(
                rowsToSelect,
                data.filter((item) => rowsToSelect.includes(keyExtractor(item))),
            );
            onSelectedRowsChange(selectedKeys);
        }
    }, [keyExtractor, onRowSelectAll, onSelectedRowsChange, selectAllValue, data, onRowDeselectAll, selectedRows, singleSelect]);

    const resetSelectedRows = () => {
        setSelectedRows([]);
        setSelectAllValue(false);
    };

    return { handleRowSelect, handleRowSelectAll, setSelectAllValue, selectAllValue, selectedRows, resetSelectedRows };
};

export default useRowSelect;
