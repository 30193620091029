
const defaultState = {
    loading: false,
    loadedCount: 0,
    data: [],
    error: ''
}

const locales = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_LOCALE_LOADING':
            return { ...state, loading: true }

        case 'FETCH_LOCALES_SUCCESS':
        case 'FETCH_LOCALE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload,
                error: null
            })

        case 'FETCH_LOCALE_4SELECT_SUCCESS':
            return { ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload ]
            }

        case 'FETCH_LOCALE_LOADED_COUNT':
            return {...state, loadedCount: action.payload}

        case 'FETCH_LOCALE_ERROR':
            return {...state, error: action.payload, loading: false}
        default:
    }

    return state
}

export default locales
