import React from 'react';
import { connect } from 'react-redux';
import FormField from '../../components/FormField'
import { editField, isAllValid } from '../../redux/actions/companiesActions'
import SimpleReactValidator from "../../utils/simple-react-validator";
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import clientEditMedicalServicesServicesSlice from "../../redux/pages/client-edit-medical-services/services/clientEditMedicalServicesServicesSlice";
import clientEditMedicalServicesDiagnosesSlice from "../../redux/pages/client-edit-medical-services/diagnoses/clientEditMedicalServicesDiagnosesSlice";

class ClientAddMCSystemPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            medicalCodingSystemCodes: [],
            firstLoad: true
        };

        this.validator = new SimpleReactValidator();

        this.onChangeMedicalCodingSystemCodes = this.onChangeMedicalCodingSystemCodes.bind(this)
    }

    onChangeMedicalCodingSystemCodes(medicalCodingSystemCodes) {
        this.props.editField('medicalCodingSystemCodes', medicalCodingSystemCodes);
        this.props.changeServicesDataTableSettings();
        this.props.changeDiagnosesDataTableSettings();
    }

    render() {
        const { isEditable } = this.props;

        return (
            <Box color="green" classes="box-profile-first-row-height-show">
                <BoxHeader title={`Client - ${this.props.company.fullName}`} color="green" />
                <BoxBody>
                    <form>
                        <p className="login-box-msg">
                            {this.props.error ?
                                <span className="text-danger">{this.props.error} </span> : null
                            }
                        </p>
                        <div className="form-group">
                            <div className='row'>
                                <FormField
                                    md="8"
                                    type="REF_MEDICAL_CODING_SYSTEM"
                                    label="Coding Systems"
                                    id="Coding Systems"
                                    value={this.props.company.medicalCodingSystemCodes}
                                    onChange={this.onChangeMedicalCodingSystemCodes}
                                    validator={this.props.validator}
                                    valids="required"
                                    onBlur={this.props.dispatchValidStatus}
                                    multi
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                    </form>
                </BoxBody>
                <BoxFooter>
                </BoxFooter>
            </Box>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log('mapStateToProps', state.app);
    return {
        company: state.app.companies.editedCompany,
        isLoading: state.app.companies.loading,
        error: state.app.companies.editFacilitiesError,
        servicesDataTableSettings: state.app.clientEditMedicalServicesServices.settings,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        editField: (field, value) => dispatch(editField(field, value)),
        isAllValid: (valid) => dispatch(isAllValid(valid)),
        changeServicesDataTableSettings: () => dispatch(clientEditMedicalServicesServicesSlice.actions.changeSettings({page: 1})),
        changeDiagnosesDataTableSettings: () => dispatch(clientEditMedicalServicesDiagnosesSlice.actions.changeSettings({page: 1})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientAddMCSystemPanel)
