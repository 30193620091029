import React from 'react';
import FormField from '../FormField';
import PropTypes from "prop-types";


export default class PortalSettingsSection extends React.Component {

    onChangeTimeZones = (timeZoneCode) => {
        this.props.onChange('timeZoneCode', timeZoneCode);
    };
    onChangeLanguage = (defaultLocaleCode) => {
        this.props.onChange('defaultLocaleCode', defaultLocaleCode);
    };

    render() {
        const { defaultLocaleCode, timeZoneCode, isEditable } = this.props;

        return (
            <div className="form-group  bordered">
                <div className="row">
                    <FormField
                        md="12"
                        type='label_field'
                        label="Portal"
                        className='margin-bottom-15 fontWeight800'
                        style={{ fontSize: '1.1em' }}
                    />
                    <FormField
                        md="4"
                        type="REF_LOCALE"
                        label="Portal Language"
                        className="text-bg "
                        searchable
                        value={defaultLocaleCode}
                        onChange={this.onChangeLanguage}
                        disabled={!isEditable}
                    />
                    <FormField
                        md="4"
                        type="timezone"
                        label="Time Zone"
                        className="text-bg "
                        searchable={true}
                        value={timeZoneCode}
                        onChange={this.onChangeTimeZones}
                        disabled={!isEditable}
                    />
                </div>
            </div>
        )
    }

    static propTypes = {
        isEditable: PropTypes.bool,
    }
    static defaultProps = {
        isEditable: true,
    }
}
