import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';

const DataTableLoadingPlaceholder = ({ rows }) => {
  return (
    <tbody>
      {range(1, rows + 1).map((key) => (
        <tr key={key}>
          <td colSpan="100%" className="datatable__loader">
            <div className="bar"></div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

DataTableLoadingPlaceholder.propTypes = {
  rows: PropTypes.number.isRequired,
};

export default DataTableLoadingPlaceholder;
