import React from 'react';
import ColoredBox from 'components/ColoredBox/ColoredBox';
import PageContainer from 'components/PageContainer/PageContainer';
import ConnectedDataTable from '../../components/Datatable/ConnectedDataTable';
import physicianSlice from '../../redux/pages/physician/physicianSlice';
import PropTypes from 'prop-types';

const columns = {
  firstName: { label: 'First Name', colStyle: { width: '12%' } },
  lastName: { label: 'Last Name', colStyle: { width: '12%' } },
  physicianCode: { label: 'Code', colStyle: { width: '12%' } },
  specialtyNames: { label: 'Specialties', colStyle: { width: '12%' } },
  bodyPartNames: { label: 'Body Parts', colStyle: { width: '12%' } },
  privateFacility: {
    label: 'Private Facility',
    colStyle: { width: '12%' },
    filterOptions: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  languageNames: { label: 'Languages', colStyle: { width: '12%' } },
  status: {
    label: 'Status',
    colStyle: { width: '12%' },
    filterOptions: [
      { value: true, label: 'ACTIVE' },
      { value: false, label: 'INACTIVE' },
    ],
  },
};

const PhysicianPage = ({ history }) => {
  const handleRowClick = ({ code }) => {
    // console.log('handleRowClick', history, code);
    history.push(`/physicians/${code}`);
  };

  return (
    <PageContainer>
      <ColoredBox variant="success" title="Physicians">
        <ConnectedDataTable
          columns={columns}
          onRowClick={handleRowClick}
          stateKey="physicianReducer"
          actions={physicianSlice.actions}
          variant="success"
        />
      </ColoredBox>
    </PageContainer>
  );
};

PhysicianPage.propTypes = {
  history: PropTypes.object,
};


export default PhysicianPage;
