import React from 'react';
import FormField from '../FormField'
import PropTypes from "prop-types";

export default class OtherSection extends React.Component {

    onChangeCheckBox = (e) => {
        this.props.onChange([e.target.name], e.target.checked);
    };

    render() {
        const { active, isEditable } = this.props;

        return (
            <>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type='label_field'
                            label="Other"
                            className='margin-bottom-15 fontWeight800'
                            style={{ fontSize: '1.1em' }}
                        />
                        <FormField
                            md="12"
                            type="checkbox"
                            title="Status"
                            label="Active"
                            name='active'
                            value={active}
                            checked={active}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                            disabled={!isEditable}
                        />
                        </div>
                </div>
            </>
        )
    }

    static propTypes = {
        active: PropTypes.bool,
        isEditable: PropTypes.bool,
        onChange: PropTypes.func,
    }
    static defaultProps = {
        isEditable: true,
    }
}
