import React, { useCallback, useContext } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { ControlContext } from '../Form';
import './ControlSelector.css';

export const ControlSelector = ({
  onChange,
  options,
  defaultValue,
  ...props
}) => {
  const { validationState } = useContext(ControlContext);
  const isInvalid = validationState === 'error';

  const handleChange = useCallback(
    (value) => {
      onChange({ target: { value } });
    },
    [onChange],
  );

  const invalidStyles = {
    control: (base) => ({
      ...base,
      borderColor: isInvalid ? '#c32323 !important' : base.borderColor,
    }),
  };

  return (
    <Select
      {...props}
      className="control-select legacy-select"
      classNamePrefix="legacy-select"
      styles={invalidStyles}
      defaultValue={defaultValue}
      options={options}
      onChange={handleChange}
    />
  );
};

ControlSelector.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  defaultValue: PropTypes.array,
};

ControlSelector.defaultProps = {
  onChange: () => {},
  options: [],
  defaultValue: [],
};

export default ControlSelector;
