import React, { useEffect } from 'react';
import PageContainer from 'components/PageContainer/PageContainer';
import Dispatch from './Stages/Dispatch';
import Draft from './Stages/Draft';
import Qa from './Stages/Qa';
import Review from './Stages/Review';
import All from './Stages/All';
import { PatientCaseContext } from './PatientCaseContext';
import { useDispatch, useSelector } from 'react-redux';
import { setCaseMessage, setSubmitAfterLoad, setSubmitAfterValidation } from 'redux/actions/cases/caseActions';
import Closed from './Stages/Closed';
import InProcess from './Stages/InProcess';
import Accepted from './Stages/Accepted';
import ClosedLastMonth from './Stages/ClosedLastMonth';
import ClosedThisMonth from './Stages/ClosedThisMonth';
import Available from './Stages/Available';
import useSelectedCases from './hooks/useSelectedCases';
import { setSelectedCases } from 'redux/actions/cases/caseListActions';
import SystemMessage from 'components/SystemMessage';
import { removeCaseListError } from '../../redux/actions/cases/caseListActions';

// eslint-disable-next-line react/prop-types
const Home = ({ history }) => {
  const dispatch = useDispatch();

  const selectedCases = useSelectedCases();

  const { error, caseMessage, caseMessageType = 'success', caseMessageTime } = useSelector((state) => {
    return {
      error: state.app.case_list.error,
      caseMessage: state.app.cases.caseMessage,
      caseMessageTime: state.app.cases.caseMessageTime
    };
  });

  useEffect(() => {
    dispatch(setSelectedCases([]));

    // TODO remove unnecessary actions probably all
    // dispatch(getMyPermissions());
    // dispatch(getAllCompanies());
    // dispatch(getAllCaseTypes());
    dispatch(setCaseMessage({}));
    dispatch(setSubmitAfterLoad());
    dispatch(setSubmitAfterValidation());
    return () => {
      dispatch(removeCaseListError());
    };
  }, [dispatch]);

  return (
    <PatientCaseContext.Provider
      value={{
        ...selectedCases,
        history,
      }}
    >
      <PageContainer>
        <SystemMessage shown={caseMessage && !error} type={caseMessageType} message={caseMessage} time={caseMessageTime} top="65px" />
        <SystemMessage shown={error} type="error" top="65px" message={error} />

        <Available />
        <Accepted />
        <InProcess />
        <Draft />
        <Dispatch />
        <Review />
        <Qa />
        <Closed />
        <ClosedThisMonth />
        <ClosedLastMonth />
        <All />

        {/* <HomeOld {...props} /> */}
      </PageContainer>
    </PatientCaseContext.Provider>
  );
};

export default Home;
