import React, { Component } from "react";
import { connect } from 'react-redux'
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import FormField from '../../components/FormField'
import { editCompany, editField } from '../../redux/actions/companiesActions'
import { isClient } from '../../utils/helpers'

class ClientEditAdminInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            firstLoad: true,
            rolesAllowedToReopenCodes: [],
            allowReopens: false,
            invalidIp: null,
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.onChangeCaseLevels = this.onChangeCaseLevels.bind(this);
        this.onChangeIpWhiteList = this.onChangeIpWhiteList.bind(this);
        this.onChangeAllowReopens = this.onChangeAllowReopens.bind(this);
        this.onChangeRolesAllowedToReopenCodes = this.onChangeRolesAllowedToReopenCodes.bind(this);
    }

    onChange(e) {
        this.props.editField(e.target.name, e.target.value)
    }

    onChangeRadio = (e) => {
        const { value, name } = e.target;
        this.props.editField(name, value === 'business')
    };

    onChangeRolesAllowedToReopenCodes(rolesAllowedToReopenCodes) {
        this.setState({ showSuccess: false });
        this.props.editField('rolesAllowedToReopenCodes', rolesAllowedToReopenCodes)
    }

    onChangeIpWhiteList(ipWhiteList) {
        this.props.editField('ipWhiteList', ipWhiteList)
    }

    onChangeCaseLevels(caseLevels) {
        let found = false;
        caseLevels.forEach((item) => {
            if (item.value === 'INITIAL') found = true;
        });
        if (!found) {
            caseLevels.push({
                value: 'INITIAL',
                label: 'Initial'
            })
        }
        this.props.editField('caseLevels', caseLevels)
    }

    onChangeCheckBox(e) {
        const newValue = e.target.value !== 'true';
        this.setState({ showSuccess: false });
        this.props.editField([e.target.name], newValue);
    }

    onChangeCaseFile = (e) => {
        if (e.target.value === 'pdfAvailable') {
            this.props.editField('pdfAvailable', true);
            this.props.editField('wordAvailable', false);
        } else if (e.target.value === 'wordAvailable') {
            this.props.editField('pdfAvailable', false);
            this.props.editField('wordAvailable', true);
        }
    }

    onChangeAllowReopens(e) {
        const { name, checked } = e.target;

        if (checked) {
            this.props.editField([name], true);
            this.props.editField('rolesAllowedToReopenCodes', ["ROLE_CLIENT_ADMIN", "ROLE_CLIENT_SUPERVISOR", "ROLE_CLIENT_USER"])
        } else {
            this.props.editField([name], false);
            this.props.editField('copyReopen', false);
            this.props.editField('reopenDays', '');
            this.props.editField('rolesAllowedToReopenCodes', []);
        }
    }

    render() {
        const { showSuccess } = this.state;
        const {
            code, businessCalendarDays, ipWhiteList, casesTotal, allowReopens,
            rolesAllowedToReopenCodes, reopenDays, fullName, wordAvailable, pdfAvailable
        } = this.props.profile;
        const { isEditable } = this.props;

        return (
            <Box color="green" classes="box-profile-first-row-height-show">
                <BoxHeader title={`Client - ${fullName}`} color="green" icon="fa-file"/>
                <BoxBody>
                    <p className="login-box-msg">
                        {this.props.error ?
                            <span className="text-danger">{this.props.error} </span>
                            :
                            (
                                showSuccess ?
                                    <span className="text-success">Profile successfully updated </span>
                                    : ""
                            )
                        }
                    </p>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group bordered">
                            <div className='row'>
                                <FormField
                                    md="12"
                                    type='label_field'
                                    label="Security"
                                    className='margin-bottom-15 fontWeight800'
                                    style={{ fontSize: '1.1em' }}
                                />
                                <FormField
                                    md="4"
                                    type='label_field'
                                    label="ID"
                                    divClasses="hide"
                                    value={code}
                                />
                                <FormField
                                    md="6"
                                    type="creatable"
                                    creatable={true}
                                    label="IP White List"
                                    id="IP White List"
                                    multi={true}
                                    placeholder='Input ip address or range of ip addresses, for example 192.168.0.1 or 192.168.0.2 - 192.168.0.100'
                                    validator={this.props.validator}
                                    valids='ip_list'
                                    value={ipWhiteList}
                                    onChange={this.onChangeIpWhiteList}
                                    onBlur={this.props.dispatchValidStatus}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <div className="form-group bordered">
                            <div className='row'>
                                <FormField
                                    md="12"
                                    type='label_field'
                                    label="Case Information"
                                    className='margin-bottom-15 fontWeight800'
                                    style={{ fontSize: '1.1em' }}
                                />
                                <FormField
                                    md="4"
                                    type='label_field'
                                    label="Case Total:"
                                    value={casesTotal ? casesTotal : 0}
                                />
                            </div>
                        </div>
                        <div className="form-group bordered">
                            <div className='row'>
                                <FormField
                                    md="12"
                                    type='label_field'
                                    label="Case Reopen"
                                    className='margin-bottom-15 fontWeight800'
                                    style={{ fontSize: '1.1em' }}
                                />
                                <FormField
                                    md="12"
                                    type="checkbox"
                                    label="Allow Reopens"
                                    name='allowReopens'
                                    value={allowReopens}
                                    checked={!!allowReopens}
                                    onChange={this.onChangeAllowReopens}
                                    onBlur={this.props.dispatchValidStatus}
                                    className="checkbox"
                                    style={{ marginBottom: '10px' }}
                                    disabled={!isEditable}
                                />
                                <FormField
                                    md="4"
                                    xs="6"
                                    type='number'
                                    id="Reopen Days"
                                    disabled={!allowReopens || !isEditable}
                                    label="Reopen Days"
                                    className="required-field text-bg"
                                    divClasses="show"
                                    name="reopenDays"
                                    value={reopenDays}
                                    valids="reopen_days"
                                    required={allowReopens}
                                    validator={this.props.validator}
                                    onChange={this.onChange}
                                    onBlur={this.props.dispatchValidStatus}
                                />
                                <FormField
                                    md="6"
                                    type="select"
                                    label="Roles Allowed To Reopen"
                                    searchable
                                    multi={true}
                                    disabled={!allowReopens || !isEditable}
                                    options={this.props.roles}
                                    value={allowReopens ? rolesAllowedToReopenCodes : []}
                                    onChange={this.onChangeRolesAllowedToReopenCodes}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                          <span style={{ display: 'none' }}>
                           {
                               this.props.validator.message(
                                   'Case File',
                                   null, 'case_file',
                                   'text-danger')
                           }
                         </span>
                            <div className='row'>
                                <div className='col-lg-2 col-md-3'>
                                    <div style={{ marginBottom: '10px' }} id='Case File'><label className="required">Case
                                        File</label></div>
                                    {/*<div className="radio-inline" style={{cursor: 'not-allowed'}}>*/}
                                    {/*    <label className={"input-container"} style={{cursor: 'not-allowed'}}>*/}
                                    {/*        PDF*/}
                                    {/*        <input*/}
                                    {/*            type="radio"*/}
                                    {/*            name="caseFile"*/}
                                    {/*            disabled*/}
                                    {/*        />*/}
                                    {/*        <span className="input-checkmark" style={{backgroundColor: '#ddd', borderColor: '#999'}}> </span>*/}
                                    {/*    </label>*/}
                                    {/*</div>*/}
                                    <FormField
                                        type="radio"
                                        label="PDF"
                                        name="caseFile"
                                        onClick={this.onChangeCaseFile}
                                        checked={pdfAvailable}
                                        value='pdfAvailable'
                                        disabled={!isEditable}
                                    />

                                    <FormField
                                        type="radio"
                                        label="Word"
                                        name="caseFile"
                                        onClick={this.onChangeCaseFile}
                                        checked={wordAvailable}
                                        value='wordAvailable'
                                        disabled={!isEditable}
                                    />
                                    {/*TODO: sample! if the PDF will work*/}
                                    {/*<div className="radio-inline">*/}
                                    {/*    <label className='label-radio'>*/}
                                    {/*        <input type="checkbox" style={{marginLeft: 0}} name="pdfAvailable"*/}
                                    {/*               onChange={this.onChangeCheckBox}*/}
                                    {/*               onBlur={this.props.dispatchValidStatus} checked={!!pdfAvailable}*/}
                                    {/*               value={pdfAvailable}/>PDF*/}
                                    {/*    </label>*/}
                                    {/*</div>*/}
                                    {/*<div className="radio-inline">*/}
                                    {/*    <label className='label-radio'><input type="checkbox" name="wordAvailable"*/}
                                    {/*                                          onChange={this.onChangeCheckBox}*/}
                                    {/*                                          onBlur={this.props.dispatchValidStatus}*/}
                                    {/*                                          checked={!!wordAvailable}*/}
                                    {/*                                          value={wordAvailable}/>Word</label>*/}
                                    {/*</div>*/}
                                </div>
                                <div className='col-md-4'>
                                    <div style={{ marginBottom: '10px' }}><label className="required">Business vs Calendar
                                        Days</label></div>
                                    <FormField
                                        type='radio'
                                        label='Business'
                                        name="businessCalendarDays"
                                        onClick={this.onChangeRadio}
                                        checked={businessCalendarDays === "business" || businessCalendarDays === true}
                                        value='business'
                                        disabled={!isEditable}
                                    />
                                    <FormField
                                        type='radio'
                                        label='Calendar'
                                        name="businessCalendarDays"
                                        onClick={this.onChangeRadio}
                                        checked={businessCalendarDays === "calendar" || businessCalendarDays === false}
                                        value='calendar'
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </BoxBody>
                <BoxFooter>
                </BoxFooter>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.app.companies.editedCompany,
        error: state.app.companies.editAdminError,
        isLoading: state.app.companies.loading,
        roles: state.app.users.roles
            .filter((item) => isClient(item.code) && item.active)
            .map(role => ({
                value: role.code,
                label: role.name.toLowerCase()
            })),

    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveProfile: data => dispatch(editCompany(data, 'admin')),
        editField: (field, value) => dispatch(editField(field, value)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientEditAdminInfo);
