import api from '../../utils/api';
import { fieldsErrors } from './statuses';
import queuedMessageSlice, { createEntityError, createEntityLoading, } from '../pages/queued-message/queuedMessageSlice';

const { loadData } = queuedMessageSlice.actions;

export const sendMessage = (queuedMessage, storedSettings) => (dispatch) => {
    dispatch(createEntityLoading());
    // console.log('sendMessage', queuedMessage);
    return api
        // .post('notifications/send/' + queuedMessage.id) // url
        .post('notifications/send', queuedMessage.id) // body
        // .post('notifications/send', {}, { headers: { 'Notification-Id': queuedMessage.id } }) // http header
        .then(() => {
            // console.log('then');
            dispatch(loadData(storedSettings));
        })
        .catch((error) => {
            // console.log('catch', error.response);
            if (error.response) {
                dispatch(createEntityError(fieldsErrors[ error.response ? error.response.status : 400 ]));
            } else if (error.request) {
                dispatch(createEntityError(fieldsErrors[ error.response ? error.response.status : 400 ]));
            } else {
                console.error('Error ', error.message);
            }
        });
};
