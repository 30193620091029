import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputGroupWrapper from './InputGroupWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { fillSelectedPatientData, getAllPatientMinimal } from '../../redux/actions/patientActions';
import Select from 'react-select';
import { getOne4Select } from '../../redux/actions/BasicEntityActions';

const RefPatient = ({
                      options,
                      creatableOptions,
                      tickMark,
                      name,
                      value,
                      labelLeft,
                      md, xs,
                      multi,
                      validLabel, label, labelAddon,
                      title, id,
                      valids, validator,
                      onChange,
                      onBlur,
                      actioned, onActionClick,
                      ...rest
                    }) => {

  let opt = (creatableOptions && creatableOptions.length > 0) ? options.concat(creatableOptions) : options;

  const dispatch = useDispatch();

  const [patientCode, setPatientCode] = useState(() => value)

  const {
    data, loading
  } = useSelector((state) => {
    return state.app.patients;
  });

  const {
    code: caseId
  } = useSelector((state) => {
    // console.log('useSelector App:::', state.app);
    return state.app.cases.editedCase;
  });

  opt = data
    .map(({ firstName, lastName, code }) => ({
      label: firstName + ' ' + lastName,
      value: code,
    })) || opt;

  useEffect(() => {
    // console.log('useEffect', patientCode);
    if (patientCode) {
      dispatch(getOne4Select(patientCode, 'patient'))
        .then((data) => {
          dispatch(fillSelectedPatientData(data));
        })
    }
  }, [dispatch, patientCode]);

  const handleOpen = useCallback(
    () => {
      dispatch(getAllPatientMinimal(caseId));
    },
    [dispatch, caseId],
  );

  const handleChange = useCallback(
    (newPatient) => {
      let newPatientCode = newPatient ? newPatient.value : null; // null is important because of TemplatesInner !!!
      setPatientCode(newPatientCode);
      onChange(newPatientCode);
    },
    [onChange],
  );

  const emulatedLegacySelect = (
    <Select
      {...rest}
      className="legacy-select patient-select"
      classNamePrefix="legacy-select"
      options={opt}
      value={opt.filter(option => option.value === patientCode)}
      isClearable={true}
      closeMenuOnSelect={true}
      isLoading={loading}
      placeholder={"Select..."}
      onMenuOpen={handleOpen}
      onChange={handleChange}
    />);

  let classes = "";
  classes += `col-md-${labelLeft ? md - 2 : md}`;
  classes += " col-xs-" + (xs || "12");

  return (
    <>
      {labelLeft && 
      <div className='col-md-2'>
        <label>
          {label}
          {tickMark}
        </label>
      </div>}
      <div className={classes}
           style={{height: '71px'}} // need input same height in same row
           title={title || ''}
      >
        {!labelLeft && 
        <label htmlFor={id} className="required">
          {label} {labelAddon}
          {tickMark}
        </label>}
        <span
          style={{ display: 'none' }}>{validator && valids ? validator.message(validLabel || label, patientCode, valids, 'text-danger') : ''}</span>
        {actioned ?
          <InputGroupWrapper name={name} icon='code' onActionClick={onActionClick}>{emulatedLegacySelect}</InputGroupWrapper> : emulatedLegacySelect}
      </div>
    </>
  )
};

RefPatient.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
}

RefPatient.defaultProps = {
  className: '',
  onChange: () => {
  },
};

export default RefPatient;
