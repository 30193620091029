import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';

const physicianSlice = createDatatableTemplateSlice({
    name: 'physicians',
    initialSettings: {
        sortBy: 'firstName',
    },
});

export const { loadData, dataLoaded, dataLoadError, changeSettings } = physicianSlice.actions;

export default physicianSlice;
