import { combineEpics, ofType } from 'redux-observable';
import { getCaseAssignment } from 'redux/actions/cases/caseAssignActions';
import { catchError$ } from 'redux/epicUtils/commonEpics';
import { FETCH_UPDATE_CASE_VALUES_SUCCESS_COMMITTED, UPDATE_ASSIGNMENT_SUCCESS } from 'redux/reducers/cases/case';
import { from, EMPTY, iif, of, race, defer, interval } from 'rxjs';
import { filter, switchMap, catchError, map, mergeMap, take, ignoreElements, tap, takeUntil } from 'rxjs/operators';
import api from 'utils/api';
import { isAdmin, isStaff } from 'utils/helpers';
import { calculate, calculateSuccess, calculateError, reset, override, resetSuccess, calculateSkip } from './financeSlice';
import { patientCaseStages } from "../patient-case/patientCaseStages";

const resetTypes = {
    NOT_REVIEWER: 'NOT_REVIEWER',
    NOT_BILLING_TIER: 'NOT_BILLING_TIER',
    NOT_BILLING_TIER_AND_MAX_AMOUNTS: 'NOT_BILLING_TIER_AND_MAX_AMOUNTS',
};


const calculateEpic = (action$, state$) =>
  action$.pipe(
    filter(calculate.match),
    switchMap(async ({ payload: params }) => {

      /** 
       * Calculate finance should be waiting for assignment details loading API call process to finish. To avoid deadlock, ensure proper synchronization mechanisms are in place.
       */
      const timeout = 10000;
      const startTime = Date.now();

      while (state$.value.app.case_assign.assignmentDetailsLoading || state$.value.app.cases.isCaseValuesLoading) {
        if (Date.now() - startTime > timeout) {
          console.warn('Timeout exceeded, breaking the loop.');
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 100)); 
      }

      const response = await api.post('/finance/calculate', params);
      return response.data;
    }),
    map(calculateSuccess),
    catchError(catchError$(calculateError)));

const overrideEpic = (action$) =>
    action$.pipe(
        filter(override.match),
        switchMap(({ payload: params }) =>
            from(api.post('/finance/override', params)).pipe(
                map(() => ({
                    code: params.patientCaseCode,
                })),
            ),
        ),
        map(calculate),
        catchError(catchError$(calculateError)),
    );
    
    /**
     * Reset should be waiting for get_assignment_details API after reset should call, and finnaly calculate.
     * @param {*} action$ 
     * @param {*} state$ 
     * @returns 
     */
    const resetEpic = (action$, state$) => {
      return action$.pipe(
        filter(reset.match),
        switchMap(({ payload: { code, type = resetTypes.NOT_REVIEWER } }) =>
          defer(() => {
            return from(
              new Promise(resolve => {
                const intervalId = setInterval(() => {
                  if (!state$.value.app.case_assign.assignmentDetailsLoading) {
                    clearInterval(intervalId);
                    resolve();
                  }
                }, 100);
              })
            );
          }).pipe(
            switchMap(() => {
              const params = { code, type };
    
              return state$.value.app.cases.editedCase.currentStage.code === 'CW_DRAFT'
                ? of(resetSuccess(), calculateSkip())
                : from(api.post('/finance/reset', params)).pipe(
                    mergeMap(() => {
                      if (state$.value.app.cases.editedCase.currentStage.code === 'CW_DRAFT') {
                        return of(calculateSkip());
                      } else {
                        return of(resetSuccess(), calculate(params));
                      }
                    }),
                    catchError(error => {
                      console.error('API call error:', error);
                      return of(calculateError(error));
                    })
                  );
            }),
            takeUntil(action$.pipe(filter(reset.match)))
          )
        ),
        catchError(catchError$(calculateError)),
      );
    };

const RECALCULATE_TRIGGER_FIELDS = ['callCount_', 'turnAroundTime_', 'questionCount_', 'pageCount_', 'caseLevel_', 'caseType_', 'questionCountTotal_'];

/*
 * This epic's purpose is to recalculate finance section values if TAT, Question count or number of calls is changed.
 */
const updateFieldResetEpic = (action$) =>
    action$.pipe(
        ofType(FETCH_UPDATE_CASE_VALUES_SUCCESS_COMMITTED),
        switchMap(({ payload: { code, fieldData } }) =>
            iif(
                () =>
                    Object.keys(fieldData).some((fieldName) => RECALCULATE_TRIGGER_FIELDS.some((triggerField) => fieldName.startsWith(triggerField))),
                of(reset({ code })),
                EMPTY,
            ),
        ),
        catchError(catchError$(calculateError)),
    );

// eslint-disable-next-line no-unused-vars
const resetOnAction$ = (action) => (actionStream$, state$) =>
    actionStream$.pipe(
        ofType(action),
        map(() => state$.value.app.cases.editedCase.code),
        tap((code) => console.log(`Finance reset is triggered on ${action} case code: ${code}`)),
        map((code) => reset({ code: code })),
        catchError(catchError$(calculateError)),
    );

const resetOnAttachFileEpic = (action$, state$) =>
    action$.pipe(
        ofType('FETCH_CASE_FILE_SUCCESS', 'FETCH_UPDATE_CASE_FILE_SUCCESS'),
        switchMap(() => {
            const { code } = state$.value.app.cases.editedCase;
            const { roleCode } = state$.value.app.auth.user;
            return iif(() => isAdmin(roleCode) || isStaff(roleCode), of(reset({ code })), EMPTY);
        }),
        catchError(catchError$(calculateError)),
    );

const resetOnDetachFileEpic = resetOnAction$('FETCH_DETACH_FILE_SUCCESS');

// const resetOnAssignmentFilterEpic = (action$) =>
//     action$.pipe(
//         ofType('UPDATE_ASSIGNMENT_FILTER_SUCCESS'),
//         map(({ payload: { code } }) => reset({ code, type: resetTypes.NOT_BILLING_TIER })),
//         catchError(catchError$(calculateError)),
//     );

const resetOnAssignmentUpdateEpic = (action$, state$) =>
    action$.pipe(
        ofType(UPDATE_ASSIGNMENT_SUCCESS),
        map((currentAction) => {
            // console.log('currentAction', currentAction, currentAction.payload.action);
            const resetType = patientCaseStages.ACCEPTED === currentAction.payload.action
                ? resetTypes.NOT_BILLING_TIER_AND_MAX_AMOUNTS
                : resetTypes.NOT_BILLING_TIER;
            return reset({
                code: state$.value.app.cases.editedCase.code,
                type: resetType
            });
        }),
        catchError(catchError$(calculateError))
    );

const fethAssigmentsOnCalculateEpic = (action$) =>
    action$.pipe(
        filter(override.match),
        switchMap(({ payload: { patientCaseCode } }) =>
            race(
                action$.pipe(
                    filter(calculateSuccess.match),
                    take(1),
                    map(() => getCaseAssignment(patientCaseCode)),
                ),
                action$.pipe(
                    filter(calculateError.match),
                    tap(() => console.log('fethAssigmentsOnCalculateEpic error')),
                    ignoreElements(),
                ),
            ),
        ),
    );

export default combineEpics(
    calculateEpic,
    overrideEpic,
    resetEpic,
    updateFieldResetEpic,
    resetOnAttachFileEpic,
    resetOnDetachFileEpic,
    // resetOnAssignmentFilterEpic,
    resetOnAssignmentUpdateEpic,
    fethAssigmentsOnCalculateEpic,
);
