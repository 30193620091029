import React from 'react'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import Box from "../../components/Box";
import { capitalizeAllFirstLetters, capitalizeFirstLetter, getNamesFromCodes, getSelectFieldValue } from '../../utils/helpers'
import FormField from "../../components/FormField";
import BoxHeader from "../../components/BoxHeader";
import BoxBody from "../../components/BoxBody";
import { editField, isAllValid } from "../../redux/actions/companiesActions";
import { getCompanyBillingTiers } from "../../redux/actions/billingActions";

class ClientPricingTiers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 10,
            page: 1
        };

        this.applyParams = this.applyParams.bind(this);
        this.onChangeBillingGroupCodes = this.onChangeBillingGroupCodes.bind(this);
    }

    applyParams(data) {
        data.splice(this.state.page * this.state.size)
            .splice(0, this.state.size);
        return data;
    }

    onChangeBillingGroupCodes(billingGroup) {
        this.setState({ showSuccess: false });
        this.props.editField('billingGroup', billingGroup);
        this.props.getCompanyBillingTiers();
    }

    numbersFormatter = (colName) => (col, row) => {
        const minVal = row[ colName + 'Min' ];
        const maxVal = row[ colName + 'Max' ];

        if (maxVal === 0) return 0;

        if (!minVal && !maxVal) return 'Any';

        if (minVal && !maxVal) return '>=' + minVal;

        if (!minVal && maxVal) return '<=' + maxVal;

        if (minVal && maxVal) return minVal + '-' + maxVal
    };

    refTierFormatter = (col) => {
        const { billingTiers } = this.props.profile;
        const codeToName = billingTiers.reduce((result, tier) => ({
            ...result,
            [ tier.code ]: 'Tier ' + tier.tierNumber
        }), {});
        return codeToName[ col ] || ''
    };

    caseTypesFormatter = (col) => <span>{col}</span>;

    caseLevelFormatter = (col) => capitalizeFirstLetter(col) || 'Any';

    reviewerTypesFormatter = (col) => <span>{col}</span>;

    rateTypeFormatter = (col) => capitalizeFirstLetter(col);

    enumFormatter = (cell, row, enumObject) => {
        return (<span>{enumObject[ cell ]}</span>)
    };

    render() {
        const { billingGroupCodes, isEditable } = this.props;
        const { billingGroup, fullName } = this.props.profile;
        const { pagination, sorting } = this.props.gridConfiguration;
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true
            }, {
                'name': 'tierNumber',
                'title': 'Name',
                'columnTitle': true,
                'dataFormat': (col) => {
                    return 'Tier ' + col
                },
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'caseTypes',
                'title': 'Case Types',
                'sort': true,
                'dataAlign': 'left',
                'dataFormat': this.caseTypesFormatter,
                'columnTitle': (title) => {
                    return title;
                },
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'caseLevel',
                'title': 'Case Level',
                'sort': true,
                'dataAlign': 'left',
                'dataFormat': this.caseLevelFormatter,
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'reviewerTypes',
                'title': 'Reviewer Type',
                'sort': true,
                'dataAlign': 'left',
                'dataFormat': this.reviewerTypesFormatter,
                'columnTitle': (title) => {
                    return title
                },
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'callsMin',
                'title': 'Calls',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'dataFormat': this.numbersFormatter('calls'),
                'filterFormatted': true,
            }, {
                'name': 'tatMin',
                'title': 'TAT',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'dataFormat': this.numbersFormatter('tat'),
                'filterFormatted': true,
            }, {
                'name': 'pagesMin',
                'title': 'Pages',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'dataFormat': this.numbersFormatter('pages'),
                'filterFormatted': true,
            }, {
                'name': 'questionsMin',
                'title': 'Question',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'dataFormat': this.numbersFormatter('questions'),
                'filterFormatted': true,
                'tdStyle': { borderRightColor: '#000' },
                'thStyle': { borderRightColor: '#000' }
            }, {
                'name': 'rateType',
                'title': 'F/H/A',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'dataFormat': this.rateTypeFormatter,
                'filterFormatted': true,
            }, {
                'name': 'currency',
                'title': 'Currency',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },

            }, {
                'name': 'rate',
                'title': 'Rate',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'filterFormatted': true
            }, {
                'name': 'adjust',
                'title': 'Adjust',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'filterFormatted': true,
            }, {
                'name': 'refTier',
                'title': 'Ref Tier',
                'sort': true,
                'dataAlign': 'left',
                'dataFormat': this.refTierFormatter,
                'filter': { type: 'TextFilter', delay: 500 },
                'filterFormatted': true,
            }, {
                'name': 'timeMin',
                'title': 'Min Time',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'filterFormatted': true,
            }, {
                'name': 'chargeMin',
                'title': 'Min Charge',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },
                'filterFormatted': true,
                // }, {
                //     'name': 'active',
                //     'title': 'Status',
                //     'dataFormat': this.enumFormatter,
                //     'sort': true,
                //     'formatExtraData': {
                //         'false': 'Inactive',
                //         'true': 'Active'
                //     },
                //     'filter': {
                //         type: 'SelectFilter', defaultValue: 'true', options: {
                //             'false': 'Inactive',
                //             'true': 'Active'
                //         }
                //     }
            }
        ];

        return (
            <div>
                <Box color="green" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`Client - ${fullName}`} color="green"/>
                    <BoxBody>
                        <div className="form-group">
                            <div className='row'>
                                <FormField
                                    md="8"
                                    type="select"
                                    label="Billing Group"
                                    id="Billing Group"
                                    valids="required"
                                    required
                                    validator={this.props.validator}
                                    options={billingGroupCodes}
                                    value={billingGroup}
                                    onChange={this.onChangeBillingGroupCodes}
                                    onBlur={this.props.dispatchValidStatus}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                    </BoxBody>
                </Box>
                <RemoteGrid
                    color="green"
                    entity="client_pricing_tiers"
                    title="Pricing Tiers"
                    trClassName={"withoutPointer"}
                    data={this.props.data}
                    total={this.props.data ? this.props.data.length : 0}
                    columns={columns}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
            </div>
        )
    }
}

const getSeparatedWords = (wordOne, wordTwo, separator) => {
    if (wordOne && wordTwo) return wordOne + separator + wordTwo;

    return 'Any'
};


const filteringCodes = (codes, profile, currency) => {
    return codes
        .filter((item) => (item.active === true || item.code === profile.billingGroup)
            && (profile.currencyCode !== null && item.currency === currency))
        .map((billing) => ({
            value: billing.code,
            label: billing.name,
            currency: billing.currency
        }));
}

const mapStateToProps = (state) => {
    const profile = state.app.companies.editedCompany;
    const reviewerTypes = state.app.reviewerTypes.data;
    const caseTypes = state.app.case_types.types;
    const currencyCode = getSelectFieldValue(profile.currencyCode);
    const billingGroupCodes = filteringCodes(state.app.billing.codes, profile, currencyCode);
    let data = profile.billingTiers;

    if (data && profile.billingGroup != null) {
        data =
            data
                .filter((item) => item.active)
                .map((item) => {
                    let rate = item.rateType === 'HOURLY' ? item.hourlyRate : item.flatRate;
                    if (!rate) rate = 0;
                    return {
                        ...item,
                        rate: rate,
                        rateType: capitalizeAllFirstLetters(item.rateType),
                        tat: getSeparatedWords(item[ 'tatSign' ], item[ 'tatValue' ], ' '),
                        pages: getSeparatedWords(item[ 'pagesFrom' ], item[ 'pagesTo' ], ' - '),
                        questions: getSeparatedWords(item[ 'questionsSign' ], item[ 'questionsValue' ], ' '),
                        reviewerTypes: getNamesFromCodes(item.reviewerTypes, reviewerTypes) || 'Any',
                        caseTypes: getNamesFromCodes(item.caseTypes, caseTypes) || 'Any',
                        adjust: item.rateType === 'HOURLY' ? item.adjust : '',
                        refTier: item.rateType === 'HOURLY' ? item.refTier : '',
                        timeMin: item.rateType === 'HOURLY' ? item.timeMin : '',
                        chargeMin: item.rateType === 'HOURLY' ? item.chargeMin : ''
                    }
                });
    } else {
        data = [];
    }

    return {
        profile,
        billingGroupCodes,
        gridConfiguration: state.app.grid.clientPricingTiers,
        caseTypes,
        reviewerTypes,
        caseLevels: state.app.case_types.levels,
        data
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCompanyBillingTiers: () => dispatch(getCompanyBillingTiers()),
        editField: (field, value) => dispatch(editField(field, value)),
        isAllValid: (valid) => dispatch(isAllValid(valid))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientPricingTiers);
