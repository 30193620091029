import api from "../../utils/api";
import {Statuses} from "./statuses";
import history from '../history'

export const createNewSCSystem = (scSystem) => (dispatch) => {
    dispatch({
        type: 'FETCH_SC_SYSTEM_LOADING',
    })

    api.post('sc_system/create', scSystem)
        .then((res) => {
            dispatch({
                type: 'FETCH_CREATE_SC_SYSTEM_SUCCESS',
                payload: scSystem
            })
            dispatch({
                type:'CLOSE_CREATE_MODAL'
            })
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_SC_SYSTEM_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_SC_SYSTEM_ERROR',
                    payload:Statuses[error.request.status]
                });
            }
        })
}

export const editSCSystem = (data, closeAfterSave) => dispatch => {
    dispatch({
        type: 'FETCH_SC_SYSTEM_LOADING',
    })

    return api.post('sc_system/update', data)
        .then(({config}) => {
            dispatch({
                type: 'FETCH_EDIT_SC_SYSTEM_SUCCESS',
                payload: JSON.parse(config.data)
            })
            closeAfterSave && history.goBack()
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_EDIT_SC_SYSTEM_ERROR',
                payload: Statuses[err.response] || Statuses[0]
            })
        })
}

export const getSCSystem = (code) => dispatch => {
    dispatch({
        type: 'FETCH_SC_SYSTEM_LOADING',
    })

    return api.get(`sc_system/get?code=${code}`)
        .then(({data}) => dispatch({
            type:'FETCH_GET_SC_SYSTEM_SUCCESS',
            payload: data
        }))
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_SC_SYSTEM_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_SC_SYSTEM_ERROR',
                    payload: error.message
                });
            }
        })
}

export const editField = (field, value) => ({
    type: 'SC_SYSTEM_EDIT_FIELD',
    payload:{
        [field]: value
    }
})

export const isAllValid = valid => {
    return {
        type:'VALIDATE_SC_SYSTEM_FIELD',
        payload: valid
    }
}
