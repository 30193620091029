import React from 'react';
import {connect} from 'react-redux';
import {closeEditModal} from '../../../redux/action-creators/users'
import {getSection, removeSection, updateSection} from "../../../redux/actions/templateActions";
import SimpleReactValidator from "utils/simple-react-validator";
import FormField from '../../../components/FormField/index'
import PropTypes from "prop-types";
import SystemMessage from '../../../components/SystemMessage';

class SectionManageForm extends React.Component {
    constructor(props) {
        super(props);
        this.defaultState = {
            language: {value: 'EN', label: 'English'},
            displayName: {'EN': '', 'SV': ''},
            helpText: {'EN': '', 'SV': ''},
            position: '',
            active: false,
        };

        this.state = this.defaultState;
        this.validator = new SimpleReactValidator({
            positive_num: {
                message: 'The :attribute must be greater than 0.',
                rule: function (val) {
                    return parseFloat(val) > 0 || val === ''
                }
            }
        });

        this.validator.showMessages();

        this.Submit = this.Submit.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    }

    componentDidMount() {
        this.props.getSection(this.props.sectionId)
            .then(() => {
                this.setState({...this.state, ...this.props.section});
            });
    }

    onChangeCheckBox(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: Object.assign({}, this.state.displayName, {
                [this.state.language.value]: e.target.value
            })
        });
    };

    onChangeLanguage = (language) => {
        this.setState({language})
    };

    Submit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            return this.props.onSubmit({
                ...this.state,
                position: Number.parseInt(this.state.position, 10)
            })
        } else {
            this.setState({messagesShown: true})
        }
    }

    onChangeOrder = (e) => {
        e.preventDefault();
        this.setState({[e.target.name]: e.target.value});
    };

    removeSection = e => {
        e.preventDefault();
        this.props.removeSection(this.state.code);
    };

    dispatchValidState = () => {
        this.forceUpdate()
    };

    render() {
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
        }));

        return (
            <form
                className='modal-content custom-modal-content'
                noValidate
            >

                <div className="row no-padding margin-bottom-15">
                    <div className="col-xs-4 col-md-6 pull-left">
                        <h3>{this.props.label}</h3>
                    </div>
                    <div className="col-xs-4 col-md-6 pull-right text-right"
                         style={{paddingTop: '10px', paddingRight: '25px'}}>
                        <button
                            className="btn btn-flat btn-primary"
                            disabled={this.props.isLoading}
                            onClick={this.Submit}
                        >
                            Save
                        </button>
                        <button
                            className="btn btn-flat btn-primary margin-left-5"
                            disabled={this.props.isLoading}
                            onClick={(e) => {
                                this.Submit(e)
                                    .then(() => {
                                        this.props.closeCreateModal()
                                    })
                            }}
                        >
                            Save And Close
                        </button>
                        <button
                            className="btn btn-flat btn-danger margin-left-5"
                            disabled={this.props.isLoading || !this.state.deletable}
                            onClick={this.removeSection}
                        >
                            Remove
                        </button>
                        <i className='fa fa-times close' style={{top: '-12px'}}
                           onClick={this.props.closeCreateModal}/>
                    </div>
                </div>
                <SystemMessage shown={this.state.messagesShown} type='error' message={this.props.error}
                               validationMessages={errorMessages}/>
                <SystemMessage shown={this.props.editedSuccess} type='success' message='Section successfully updated'/>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="10"
                            label="Display Name"
                            id="Display Name"
                            className="form-control"
                            required
                            name="displayName"
                            value={this.state.displayName[this.state.language.value]}
                            onChange={this.onChange}
                            validator={this.validator}
                            valids='required'
                            onBlur={this.dispatchValidState}
                        />
                        {/*  <FormField
                      md="4"
                      type="select"
                      label="Language"
                      clearable={false}
                      className="text-bg "
                      options={this.props.languageCodes}
                      name="languageCode"
                      value={this.state.language}
                      onChange={this.onChangeLanguage}
                      /> */}
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            required
                            md="10"
                            type='number'
                            label='Section Order'
                            id='Section Order'
                            value={this.state.position}
                            onChange={this.onChangeOrder}
                            validator={this.validator}
                            name='position'
                            valids='required|positive_num'
                            onBlur={this.dispatchValidState}
                        />
                    </div>
                </div>
                {/*      <div className="form-group">
                  <div className='row'>
                    <FormField
                        md="12"
                        type="checkbox"
                        title="Status"
                        label="Active"
                        name='active'
                        alignment='left'
                        value={this.state.active}
                        checked={this.state.active}
                        onChange={this.onChangeCheckBox}
                        className="checkbox"
                    />
                  </div>
              </div> */}
            </form>
        );
    }
}

SectionManageForm.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    languageCodes: state.app.languages.data.filter((item) => item.active).map((item) => ({
        value: item.code,
        label: item.name
    })),
    isLoading: state.app.templates.loading,
    label: 'Edit Section',
    error: state.app.templates.sectionError,
    section: state.app.templates.editedSection,
    editedSuccess: state.app.templates.sectionEditSuccess
});

const mapDispatchToProps = dispatch => ({
    closeCreateModal: () => {
        dispatch(closeEditModal())
    },
    onSubmit: (section) => dispatch(updateSection(section)),
    getSection: (id) => dispatch(getSection(id)),
    removeSection: (id) => {
        dispatch(removeSection(id))
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(SectionManageForm)
