import React from 'react';
import {connect} from 'react-redux';
import SimpleReactValidator from "../../utils/simple-react-validator";
import FormField from '../../components/FormField/index'
import {getDiagnoseNotes} from '../../redux/actions/mcsystemsActions'
import {editMdNotes} from '../../redux/actions/companiesActions'
import PropTypes from "prop-types";
import {getNamesFromCodes} from '../../utils/helpers'
import {closeEditModal} from '../../redux/action-creators/users'
import LoadingIndicator from "../../components/LoadingIndicator";

class ClientEditDiagnoseForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.diagnose || {};
        this.state = {...this.state, notesLanguage: {value: 'EN', label: 'English'}};
        this.validator = new SimpleReactValidator();

        this.Submit = this.Submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    }

    componentDidMount() {
        this.props.getDiagnose(this.props.diagnoseCode);
    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.diagnose) !== JSON.stringify(this.props.diagnose)) {
            this.setState({...nextProps.diagnose, notesLanguage: {value: 'EN', label: 'English'}})
        }
    }

    onChangeCheckBox(e) {
        const noteName = e.target.name.replace('UseDefault', '');

        this.setState({
            [e.target.name]: e.target.checked,
            [noteName]: this.props.defaultDiagnose[noteName],
        });
    }

    Submit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            const data = {
                ...this.state,
            };
            console.log(data);
            this.props.editDiagnose(data);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onChangeNotes = (e) => {
        const {value, name} = e.target;
        let names = this.state[name] || {};

        names = Object.assign({}, names, {
            [this.state.notesLanguage.value]: value
        });
        console.log(name);
        console.log(names);
        this.setState({[name]: names})
    };

    setNoteValue = (noteName) => {
        const note = this.state[noteName];
        const {notesLanguage} = this.state;
        return note && note[notesLanguage.value] ? note[notesLanguage.value] : ''
    };

    render() {
        return (
            <form
                className='modal-content custom-modal-content'
                onSubmit={this.Submit}>
                <div className="row no-padding">
                    <div className="col-xs-4 col-md-6 pull-left">
                        <b style={{
                            color: '#558d29',
                            fontSize: '1.5em'
                        }}>{this.props.diagnose.name}</b>
                    </div>
                    <div className="col-xs-4 col-md-6 pull-right text-right">
                        <button
                            className="btn btn-flat btn-success"
                            disabled={this.props.isLoading}
                        >
                            Save
                        </button>
                        <button
                            className="btn btn-flat btn-default margin-left-5"
                            disabled={this.props.isLoading}
                            onClick={this.props.closeEditModal}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                <hr style={{height: '2px'}}/>
                <div className="form-group">
                    <div className='row'>
                        <div className='col-md-12'>
                            <label>Service: </label>
                            <a className='margin-left-5' target='_blank' rel={'noopener noreferrer'}
                               href={`#/diagnose/${this.props.diagnose.code}`}>{this.props.diagnose.name}</a>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <div className='col-md-12'>
                            <label>Specialties: </label>
                            <span className='margin-left-5'>{this.props.diagnose.specialtyCodes}</span>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <div className='col-md-12'>
                            <label>Body Parts: </label>
                            <span className='margin-left-5'>{this.props.diagnose.bodyPartCodes}</span>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <div className='col-md-12'>
                            <label>Medical Codes: </label>
                            <span className='margin-left-5'>{this.props.diagnose.medicalCodingSystemCodes}</span>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="6"
                            type='label_field'
                            label="Notes"
                            className='margin-bottom-15'
                            style={{fontSize: '1.2em'}}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="8"
                            type='textarea'
                            label='Medical Nessesity'
                            title={this.context.translate('common.form.name')}
                            valids="max:200"
                            validator={this.validator}
                            value={this.setNoteValue('medicalNecessity')}
                            onChange={this.onChangeNotes}
                            name='medicalNecessity'
                            disabled={this.state['medicalNecessityUseDefault']}
                        />
                        <FormField
                            md="2"
                            style={{marginTop: '18px'}}
                            type='checkbox'
                            label='Link to Default'
                            onChange={this.onChangeCheckBox}
                            name='medicalNecessityUseDefault'
                            value={this.state['medicalNecessityUseDefault']}
                            checked={this.state['medicalNecessityUseDefault']}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="8"
                            type='textarea'
                            label='Medical Nessesity Alternative'
                            title={this.context.translate('common.form.name')}
                            valids="max:200"
                            validator={this.validator}
                            value={this.setNoteValue('medicalNecessityAlternative')}
                            onChange={this.onChangeNotes}
                            name='medicalNecessityAlternative'
                            disabled={this.state['medicalNecessityAlternativeUseDefault']}
                        />
                        <FormField
                            md="2"
                            style={{marginTop: '18px'}}
                            type='checkbox'
                            label='Link to Default'
                            onChange={this.onChangeCheckBox}
                            name='medicalNecessityAlternativeUseDefault'
                            value={this.state['medicalNecessityAlternativeUseDefault']}
                            checked={this.state['medicalNecessityAlternativeUseDefault']}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="8"
                            type='textarea'
                            label='References'
                            title={this.context.translate('common.form.name')}
                            valids="max:200"
                            validator={this.validator}
                            value={this.setNoteValue('noteReferences')}
                            onChange={this.onChangeNotes}
                            name='noteReferences'
                            disabled={this.state['noteReferencesUseDefault']}
                        />
                        <FormField
                            md="2"
                            style={{marginTop: '18px'}}
                            type='checkbox'
                            label='Link to Default'
                            onChange={this.onChangeCheckBox}
                            name='noteReferencesUseDefault'
                            value={this.state['noteReferencesUseDefault']}
                            checked={this.state['noteReferencesUseDefault']}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className='row'>
                        <FormField
                            md="8"
                            type='textarea'
                            label='Other'
                            title={this.context.translate('common.form.name')}
                            valids="max:200"
                            validator={this.validator}
                            value={this.setNoteValue('notes')}
                            onChange={this.onChangeNotes}
                            name='notes'
                            disabled={this.state['notesUseDefault']}
                        />
                        <FormField
                            md="2"
                            style={{marginTop: '18px'}}
                            type='checkbox'
                            label='Link to Default'
                            onChange={this.onChangeCheckBox}
                            name='notesUseDefault'
                            value={this.state['notesUseDefault']}
                            checked={this.state['notesUseDefault']}
                        />
                    </div>
                </div>
                {this.props.isLoading ? <LoadingIndicator height={50}/> : null}
            </form>
        );
    }
}

ClientEditDiagnoseForm.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    isLoading: state.app.diagnoses.loading,
    error: state.app.diagnoses.error,
    diagnose: {
        ...state.app.diagnoses.editedDiagnose,
        ...{
            specialtyCodes: getNamesFromCodes(state.app.diagnoses.editedDiagnose.specialtyCodes, state.app.specialties.data),
            // medicalCodingSystemCodes: getNamesFromCodes(state.app.diagnoses.editedDiagnose.medicalCodingSystemCodes, state.app.mcSystem.data),
            medicalCodingSystemCodes: getNamesFromCodes(state.app.diagnoses.editedDiagnose.medicalCodingSystemCodes, state.app.scSystem.data),
            bodyPartCodes: getNamesFromCodes(state.app.diagnoses.editedDiagnose.bodyPartCodes, state.app.bodyParts.data),
        }
    },
    defaultDiagnose: state.app.diagnoses.data.find((item) => item.code === ownProps.diagnoseCode)
});

const mapDispatchToProps = dispatch => ({
    editDiagnose: (procedure) => {
        dispatch(editMdNotes(procedure))
    },
    closeEditModal: (procedure) => {
        dispatch(closeEditModal(procedure))
    },
    getDiagnose: (code) => {
        dispatch(getDiagnoseNotes(code))
    },
    // getDiagnose: (code, entity='diagnosis') => {
    //     dispatch(getOne(code, entity))
    // },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientEditDiagnoseForm)
