import { onRequiredBuild } from './scripts-helpers';

export function getFieldValidators(fieldLabel, fieldCode, fieldValidationDtos, templateCode, fields){
    const specValidationMessages = {
        hardValidation: [],
        softValidation: []
    };
    
    for(let i = 0; i < fieldValidationDtos.length; i++){
        if (fieldValidationDtos && fieldValidationDtos[i] && fieldValidationDtos[i].logic && fieldValidationDtos[i].logic !== ''){
            let fieldValidator;
            let fieldValidatorBuild = onRequiredBuild(fieldValidationDtos[i].logic, templateCode, fields);
            try {
                // eslint-disable-next-line no-eval
                fieldValidator = eval(fieldValidatorBuild);          
            }
            catch(e) {
                console.error(e);
            }
            if(typeof fieldValidator === 'boolean'){
                if(!fieldValidator){
                    if(fieldValidationDtos[i].hardValidation){
                        specValidationMessages.hardValidation.push({
                            id: fieldCode,
                            field: fieldLabel,
                            message: fieldValidationDtos[i].text['EN'],
                        });
                    } else {
                        specValidationMessages.softValidation.push({
                            id: fieldCode,
                            field: fieldLabel,
                            message: fieldValidationDtos[i].text['EN'],
                        });
                    }
                    
                }
            }
        }
    }

    return specValidationMessages;
}