import React, { useRef } from 'react';
import api from '../../utils/api';
import BoxHeader from '../../components/BoxHeader';
import BoxBody from '../../components/BoxBody';
import BoxFooter from '../../components/BoxFooter';
import Box from '../../components/Box';
import ButtonLoader from './ButtonLoader';
import Snackbar from './Snackbar';
import { Col, Row } from 'react-bootstrap';

const DevelopRandomPopulatePage = () => {
  const snackbarRef = useRef();

  const patients = [
    { num: 1, ref: React.createRef() },
    { num: 10, ref: React.createRef() },
    { num: 100, ref: React.createRef() },
    { num: 1000, ref: React.createRef() },
  ];
  const deleteButtonPatientRef = useRef();

  const reviewers = [
    { num: 1, ref: React.createRef() },
    { num: 10, ref: React.createRef() },
    { num: 100, ref: React.createRef() },
  ];
  const deleteButtonReviewerRef = useRef();

  const submitters = [
    { num: 1, ref: React.createRef() },
    { num: 10, ref: React.createRef() },
    { num: 100, ref: React.createRef() },
  ];
  const deleteButtonSubmitterRef = useRef();

  const physicians = [
    { num: 1, ref: React.createRef() },
    { num: 10, ref: React.createRef() },
    { num: 100, ref: React.createRef() },
    { num: 1000, ref: React.createRef() },
  ];
  const deleteButtonPhysicianRef = useRef();

  const cases = [
    { num: 1, ref: React.createRef() },
    { num: 10, ref: React.createRef() },
    { num: 100, ref: React.createRef() },
    { num: 1000, ref: React.createRef() },
  ];
  const deleteButtonCaseRef = useRef();

  const buttonRefs = { patients, reviewers, submitters, physicians, cases };

  const getEndpoint = (func, entity, num) => {
    let service;
    if (func === 'add') {
      if (entity === 'reviewer' || entity === 'submitter') {
        service = 'auth';
      } else {
        service = 'entity'; // patient, physician
      }
      return `dev/${service}/${func}/${num}/random-${entity}`;
    } else if (func === 'remove') {
      if (entity === 'reviewer' || entity === 'submitter') {
        service = 'auth';
      } else {
        service = 'entity'; // patient, physician
      }
      return `dev/${service}/${func}/random-${entity}`;
    }
  };

  const addEntity = (e, ref, entity, num) => {
    e.preventDefault();
    if (ref) {
      ref.current.setLoading(true);
    }
    setTimeout(() => {
      api
        .post(getEndpoint('add', entity, num), null, { timeout: 10 * 60 * 1000 })
        .then((result) => {
          const { time } = result.data;
          snackbarRef.current.open(
            `${num} random ${entity} created! [It took ${time} ms]`,
            'alert alert-success',
          );
          if (ref) {
            ref.current.setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          snackbarRef.current.open(
            `Error! Cannot create ${num} random ${entity}!`,
            'alert alert-danger',
          );
          if (ref) {
            ref.current.setLoading(false);
          }
        });
    }, 200);
  };

  const deleteEntity = (e, ref, entity) => {
    e.preventDefault();
    if (ref) {
      ref.current.setLoading(true);
    }
    setTimeout(() => {
      api
        .delete(getEndpoint('remove', entity))
        .then((result) => {
          const { time } = result.data;
          snackbarRef.current.open(
            `Random ${entity}s deleted! [It took ${time} ms]`,
            'alert alert-success',
          );
          if (ref) {
            ref.current.setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          snackbarRef.current.open(
            `Error! Cannot delete random ${entity}!`,
            'alert alert-danger',
          );
          if (ref) {
            ref.current.setLoading(false);
          }
        });
    }, 200);
  };

  return (
    <div className="content">
      <Box color="blue" classes="box-profile-first-row-height-show">
        <BoxHeader
          title={`Developer Tools - Populate Random Entities`}
          color="blue"
          icon="fa-wrench"
          classes="box-profile-first-row-height-show"
        />
        <BoxBody>
          {/*Random Patients*/}
          <Row>
            <Col md={12}>
              <h3>
                <i className={'fa fa-user'} /> Patients
              </h3>
              {buttonRefs.patients.map((btn, i) => (
                <ButtonLoader
                  ref={btn.ref}
                  key={i}
                  text={`Add ${btn.num} patient${btn.num !== 1 ? 's' : ''}`}
                  onClick={(e) => addEntity(e, btn.ref, 'patient', btn.num)}
                />
              ))}
              <ButtonLoader
                ref={deleteButtonPatientRef}
                text={'Delete Patients'}
                className={'btn btn-danger'}
                onClick={(e) =>
                  deleteEntity(e, deleteButtonPatientRef, 'patient')
                }
              />
            </Col>
          </Row>
          {/*Random Reviewers*/}
          <Row>
            <Col md={12}>
              <h3>
                <i className={'fa fa-user-md'} /> Reviewers
              </h3>
              {buttonRefs.reviewers.map((btn, i) => (
                <ButtonLoader
                  ref={btn.ref}
                  key={i}
                  text={`Add ${btn.num} reviewer${btn.num !== 1 ? 's' : ''}`}
                  onClick={(e) => addEntity(e, btn.ref, 'reviewer', btn.num)}
                />
              ))}
              <ButtonLoader
                ref={deleteButtonReviewerRef}
                text={'Delete 150 Reviewers'}
                className={'btn btn-danger'}
                onClick={(e) =>
                  deleteEntity(e, deleteButtonReviewerRef, 'reviewer')
                }
              />
            </Col>
          </Row>
          {/*Random Submitters*/}
          <Row>
            <Col md={12}>
              <h3>
                <i className={'fa fa-users'} /> Submitters
              </h3>
              {buttonRefs.submitters.map((btn, i) => (
                <ButtonLoader
                  ref={btn.ref}
                  key={i}
                  text={`Add ${btn.num} submitter${btn.num !== 1 ? 's' : ''}`}
                  onClick={(e) => addEntity(e, btn.ref, 'submitter', btn.num)}
                />
              ))}
              <ButtonLoader
                ref={deleteButtonSubmitterRef}
                text={'Delete Submitters'}
                className={'btn btn-danger'}
                onClick={(e) =>
                  deleteEntity(e, deleteButtonSubmitterRef, 'submitter')
                }
              />
            </Col>
          </Row>
          {/*Random Physicians*/}
          <Row>
            <Col md={12}>
              <h3>
                <i className={'fa fa-user-md'} /> Physicians
              </h3>
              {buttonRefs.physicians.map((btn, i) => (
                <ButtonLoader
                  ref={btn.ref}
                  key={i}
                  text={`Add ${btn.num} physician${btn.num !== 1 ? 's' : ''}`}
                  onClick={(e) => addEntity(e, btn.ref, 'physician', btn.num)}
                />
              ))}
              <ButtonLoader
                ref={deleteButtonPhysicianRef}
                text={'Delete Physicians'}
                className={'btn btn-danger'}
                onClick={(e) =>
                  deleteEntity(e, deleteButtonPhysicianRef, 'physician')
                }
              />
            </Col>
          </Row>

          {/*Random Cases*/}
          <Row>
            <Col md={12}>
              <h3>
                <i className="fa fa-file-text-o" /> Cases
              </h3>
              {buttonRefs.cases.map((btn, i) => (
                <ButtonLoader
                  ref={btn.ref}
                  key={i}
                  text={`Add ${btn.num} case${btn.num !== 1 ? 's' : ''}`}
                  onClick={(e) => addEntity(e, btn.ref, 'case', btn.num)}
                />
              ))}
              <ButtonLoader
                ref={deleteButtonCaseRef}
                text={'Delete Cases'}
                className={'btn btn-danger'}
                onClick={(e) =>
                  deleteEntity(e, deleteButtonCaseRef, 'case')
                }
              />
            </Col>
          </Row>
        </BoxBody>
        <BoxFooter>
          <i className="fa fa-exclamation-circle" /> Use these features carefully... ;)
        </BoxFooter>
      </Box>
      <Snackbar ref={snackbarRef} />
    </div>
  );
};

export default DevelopRandomPopulatePage;
