import React, { Component } from 'react';
import { connect } from 'react-redux';
import Box from '../components/Box';
import BoxHeader from '../components/BoxHeader';
import BoxBody from '../components/BoxBody';
import BoxFooter from '../components/BoxFooter';
import PortalSettingsSection from '../components/Sections/PortalSettingsSection';
import OtherSection from '../components/Sections/OtherSection';
import { userEditField, } from '../redux/action-creators/users';
import { isClient } from '../utils/helpers';
import PropTypes from "prop-types";

class UserSettings extends Component {

    render() {
        const { color, title, my_role, isEditable, editField,  } = this.props;
        const { defaultLocaleCode, timeZoneCode, active } = this.props.profile;

        return (
            <div className="content">
                <Box color={color} classes="box-profile-first-row-height-show">
                    <BoxHeader title={title} color={color} icon="fa-user"/>
                    <BoxBody>
                        <form>
                            <PortalSettingsSection
                                defaultLocaleCode={defaultLocaleCode}
                                timeZoneCode={timeZoneCode}
                                onChange={editField}
                                isEditable={isEditable}
                            />
                            <OtherSection
                                active={active}
                                onChange={editField}
                                // readonly={isClient(this.props.my_role)}
                                isEditable={!isClient(my_role) || isEditable}
                            />
                        </form>
                    </BoxBody>
                    <BoxFooter>
                    </BoxFooter>
                </Box>
            </div>
        );
    }

    static propTypes = {
        color: PropTypes.string,
        title: PropTypes.string,
        my_role: PropTypes.string,
        isEditable: PropTypes.bool,
        profile: PropTypes.object,
        editField: PropTypes.func,
    }
    static defaultProps = {
        isEditable: true,
    }
}

const mapStateToProps = (state) => {
    return {
        // profile: state.app.companies.data.filter(usr => usr.code == ownProps.match.params.id)[0],
        profile: state.app.users.editedUser,
        error: state.app.companies.editTemplateError,
        isLoading: state.app.companies.loading,
        my_role: state.app.auth.user.roleCode
    }
};

const mapDispatchToProps = dispatch => {
    return {
        editField: (field, value) => dispatch(userEditField(field, value))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
