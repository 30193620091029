import React from 'react';
import {connect} from 'react-redux';
import {editDiagnose, getDiagnose, isAllValid} from '../../../redux/actions/diagnosesActions'
import PropTypes from "prop-types";
import SimpleReactValidator from "utils/simple-react-validator";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Box from "../../../components/Box";
import BoxHeader from "../../../components/BoxHeader";
import BoxBody from "../../../components/BoxBody";
import DiagnoseForm from "./DiagnoseForm";
import {getAll} from "../../../redux/actions/BasicEntityActions";
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import {getMultiSelectValue} from '../../../utils/helpers'
import SystemMessage from '../../../components/SystemMessage';

class DiagnoseEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.validator = new SimpleReactValidator({
            unique: {
                message: 'This Diagnosis already exists in the system.',
                rule: (val) => !this.props.diagnosisNames.includes(val)
            }
        });

        this.validator.showMessages();
    }

    componentDidMount() {
        this.props.getDiagnose(this.props.match.params.id);
        this.props.getAllMc();
        this.props.getAllSc();
    }

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onSave = () => {
        let {systemToCode, names, name} = this.props.item
        const medicalCodeCodes = Object.keys(systemToCode).reduce((p, c) => {
            return [...p, ...systemToCode[c]]
        }, [])

        if (names) {
            names['EN'] = names['EN'] ? names['EN'] : name
            name = names['EN'] ? names['EN'] : name
        } else names = {'EN': name}

        return this.props.saveData({
            ...this.props.item,
            names: names,
            name: name,
            active: this.props.item.active,
            code: this.props.item.code,
            medicalCodingSystemCodes: medicalCodeCodes,
            specialtyCodes: getMultiSelectValue(this.props.item.specialtyCodes),
            bodyPartCodes: getMultiSelectValue(this.props.item.bodyPartCodes),
        })
    }

    render() {
        const {match, showValidMessages, editDiagnoseSuccess, isValid} = this.props;
        const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
            id: field,
            field,
            message: this.validator.errorMessages[field],
        }))
        console.log(showValidMessages);
        return (
            <div className="content" style={{marginTop: '10px'}}>

                <PreventTransitionPrompt
                    when={this.props.isProfileChanged}
                    message='MyCustomDialogComponent'
                    entity='diagnose'
                    isFormValid={this.props.isValid}
                    saveChanges={this.onSave}
                    cancelAndDiscard={() => this.props.getDiagnose(this.props.item.code)}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                <SystemMessage shown={editDiagnoseSuccess && isValid} type='success' message={this.context.translate('common.message.diagnose_success')}/>

                <SystemMessage shown={showValidMessages} type='error' message={this.props.error} validationMessages={errorMessages}/>

                <Box color="green" classes="box-profile-first-row-height-show">
                    <BoxHeader title={`${this.context.translate('common.form.diagnosis')} - ${this.props.item.name}`} color="green"
                               icon="fa-list-alt" classes="box-profile-first-row-height-show"/>
                    <BoxBody>
                        <DiagnoseForm params_id={match.params.id} validator={this.validator} dispatchValidState={this.dispatchValidState}/>
                    </BoxBody>
                </Box>
            </div>
        );
    }
}

DiagnoseEditPage.contextTypes = {
    translate: PropTypes.func
}

const mapStateToProps = (state) => ({
    diagnoses: state.app.diagnoses.data,
    item: state.app.diagnoses.editedDiagnose,
    loading: state.app.diagnoses.loading,
    showValidMessages: state.app.diagnoses.showValidMessages,
    isProfileChanged: state.app.diagnoses.isProfileChanged,
    error: state.app.diagnoses.error,
    editDiagnoseSuccess: state.app.diagnoses.editDiagnoseSuccess,
    isValid: state.app.diagnoses.diagnoseValid,
    diagnosisNames: state.app.diagnoses.data
        .filter((item) => item.code !== state.app.diagnoses.editedDiagnose.code)
        .map((item) => item.name),

})

const mapDispatchToProps = dispatch => ({
    getAllMc: () => dispatch(getAll('mc_system')),
    getAllSc: () => dispatch(getAll('sc_system')),
    getDiagnose: code => dispatch(getDiagnose(code)),
    saveData: data => dispatch(editDiagnose(data)),
    isAllValid: (valid) => dispatch(isAllValid(valid)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DiagnoseEditPage)
