import { isObjectsEqual } from '../../../utils/helpers'

const baseProcedure = {
    medicalNecessity: null,
    medicalNecessityAlternative: null,
    noteReferences: null,
    notes: null,
    name: '',
    names: { 'EN': '' },
    code: null,
    active: true,
    medicalCodingSystemCodes: [],
    systemToCode: {}
};
const defaultState = {
    loading: false,
    loadedCount: 0,
    editProcedureSuccess: false,
    data: [],
    error: '',
    editedProcedure: baseProcedure,
    originalProcedure: baseProcedure,
    procedureLoaded: false,
    isProfileChanged: false,
    procedureValid: undefined,
    showValidMessages: false,
    lastLoadedTime: 0,
};

const procedures = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_PROCEDURE_LOADING':
        case 'FETCH_MED_SERVICE_LOADING':
            return {
                ...state,
                loading: true,
                procedureLoaded: false,
                isProfileChanged: false,
                editProcedureSuccess: false,
                showValidMessages: false
            };
        case 'FETCH_PROCEDURE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                procedureLoaded: false,
                data: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            });
        case 'FETCH_MED_SERVICE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                procedureLoaded: false,
                data: action.payload,
                error: null
            });
        case 'FETCH_CREATE_PROCEDURE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editProcedureSuccess: true,
                data: [...state.data, action.payload]
            });

        case 'FETCH_GET_PROCEDURE_SUCCESS': {
            const procedure = action.payload ? action.payload : defaultState.editedProcedure;
            return {
                ...state,
                error: null,
                loading: false,
                procedureValid: true,
                procedureLoaded: true,
                editedProcedure: { ...defaultState.editedProcedure, ...procedure },
                originalProcedure: { ...defaultState.editedProcedure, ...procedure },
            };
        }
        case 'FETCH_EDIT_PROCEDURE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editProcedureSuccess: true,
                data: state.data.map((item) => {
                    if (item.code === action.payload.code) return action.payload;
                    return item
                })
            });
        case 'PROCEDURE_EDIT_FIELD':
            return Object.assign({}, state, {
                editedProcedure: Object.assign({}, state.editedProcedure, action.payload),
                isProfileChanged: !isObjectsEqual(Object.assign({}, state.editedProcedure, action.payload), state.originalProcedure)
            });
        case 'VALIDATE_PROCEDURE_FIELD':
            return Object.assign({}, state, {
                procedureValid: action.payload
            });
        case 'FETCH_PROCEDURE_ERROR':
            return { ...state, error: action.payload, loading: false, editedProcedure: defaultState.editedProcedure };

        case 'SHOW_PROCEDURE_VALIDATION':
            return Object.assign({}, state, {
                showValidMessages: true
            });

        case 'FETCH_PROCEDURE_4SELECT_SUCCESS':
            return {
                ...state,
                error: null,
                loading: false,
                data: [...state.data, action.payload]
            };
        case 'FILL_PROCEDURE_LOCAL_STORAGE': {
            let items = {};
            for (let i = 0; i < state.data.length; i++) {
                items[ state.data[ i ].code ] = state.data[ i ].name;
            }
            localStorage.setItem('list_medical_service', JSON.stringify(items));
            return state;
        }
        case 'FETCH_PROCEDURE_LOADED_COUNT':
            return { ...state, loadedCount: action.payload };

        default:
    }
    return state
};

export default procedures
