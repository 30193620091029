import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import MenuItem from './MenuItem'
import MenuItemGroup from './MenuItemGroup'
import {isStaff} from '../utils/helpers'

class Menu extends React.Component {
    static propTypes = {
        error: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            layoutItems: {}
        };
    }

    getMenuItems = (menuItems) => {
        return menuItems
            .filter((item) => {
                if (item.code === 'staff') {
                    return !isStaff(this.props.role)
                } else return true
            })
            .sort((prevItem, nextItem) => prevItem.position - nextItem.position)
            .map(item => {
                if (item.children && item.children.length) {
                    return <MenuItemGroup key={item.code} menuItem={item} role={this.props.role}/>
                } else {
                    return (
                        <MenuItem
                            key={item.code}
                            counterEndPoint={item.counter_endpoint}
                            name={item.name['EN']}
                            itemName={item.code}
                            url={item.link}
                            icon={item.icon}
                            tooltip={item.tooltip}
                        >
                            {item.children}
                        </MenuItem>
                    )
                }

            })
    };

    render() {
        return (
            <ul className="sidebar-menu">
                {this.getMenuItems(this.props.layoutItems)}
            </ul>
        )
    }
}

Menu.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    layoutItems: state.app.context.layout.items,
    error: state.app.context.layout.error,
    role: state.app.auth.user.roleCode
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
