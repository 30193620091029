import React from 'react';
import {connect} from 'react-redux'
import ClientControlTable from './ClientControlTable';
import {getAllCompanies} from '../../redux/actions/companiesActions'

class ClientsManagePage extends React.Component {

    componentDidMount() {
        this.props.getAllCompanies();
    }

    render() {
        return (
            <div className='container-fluid'>
                {       /* <div className='container-fluid'>
          <div className='row text-right' style={{ marginTop:'15px', marginBottom:'10px', padding:'10px', paddingBottom:'20px'}}>
              <CreateModal color='green' entityType='client' icon='fa-building' />
          </div>
        </div> */}
                <ClientControlTable/>
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getAllCompanies: () => dispatch(getAllCompanies()),
    }
};

export default connect(null, mapDispatchToProps)(ClientsManagePage);
