import React from 'react';
import {connect} from 'react-redux';
import Table from 'rc-table'
import {getAllStages} from '../../redux/actions/stages'
import {getAllRoles} from '../../redux/action-creators/users'
import LoadingIndicator from '../../components/LoadingIndicator';
import {reducedRoles} from '../../utils/Constants';
import {CustomOption} from '../../components/FormField/WysiwygEditor'

import 'rc-table/assets/index.css';

class UsageMatrix extends React.Component {
    constructor(props) {
        super(props);
        this.showNecessity = this.showNecessity.bind(this);
        this.changeStageVisibility = this.changeStageVisibility.bind(this);
        this.getTablesCols = this.getTablesCols.bind(this);
        this.getTablesData = this.getTablesData.bind(this);
    }

    componentDidMount() {
        // this.props.getAllStages();
        // this.props.getAllRoles();
    }

    showNecessity(stageCode, roleCode) {
        return this.props.matrix[`${stageCode}#${roleCode}`] ? <i className="fa fa-check"/> : null;
    }

    changeStageVisibility(stageCode, roleCode) {
        // console.log(stageCode, roleCode);
        // !this.props.disabled &&
        this.props.onChange(this.props.name, stageCode + '#' + roleCode)
    }

    payloadMatrix(updatedMatrix) {
      this.props.payloadMatrix(this.props.name, updatedMatrix)
    }

    onSelectCol = (roleName, isSet) => {
        const role = this.props.roles.find(role => role.name === roleName);
        const newVisibilityMatrix = Object.entries(this.props.matrix).reduce((acc, [key, value]) => {
          key.includes(role?.name) ? acc[key] = isSet : acc[key] = value;
          return acc;
        }, {});
        this.payloadMatrix(newVisibilityMatrix);
    }


    onSelectRow = (stageName, isSet) => {
      const stage = this.props.stages.find(role => role.name === stageName);
      const newVisibilityMatrix = Object.entries(this.props.matrix).reduce((acc, [key, value]) => {
        key.includes(stage?.code) ? acc[key] = isSet : acc[key] = value;
        return acc;
      }, {});
      this.payloadMatrix(newVisibilityMatrix);
    }

    isHeaderColCheckboxChecked = (role) => {
        return this.props.stages.every((stage) => this.props.matrix[`${stage.code}#${role.code}`]);
    }

    isHeaderRowCheckboxChecked = (stage) => {
        return this.props.roles.every((role) => this.props.matrix[`${stage.code}#${role.code}`]);
    }

    makePrettyRole = (role) => role.replace('ROLE', '').replace(/_/g, ' ')

    getTablesCols = () => ([
        {
            title: 'STAGE/ROLE',
            dataIndex: 'stageName',
            key: 'stageName',
            // className: this.props.disabled ? 'background-grey' : '',
            width: 20,
            // fixed: 'left'
        },
        ...this.props.roles
            .map((role) => ({
                title: (
                        <>
                        {this.makePrettyRole(role.name)}
                        <br/>
                        {!this.props.disabled && (
                        <input type="checkbox"
                               checked={this.isHeaderColCheckboxChecked(role)}
                               onClick={(e) => !this.props.disabled && this.onSelectCol(role.name, e.target.checked)}
                        />)}
                        </>),
                dataIndex: role.code,
                key: role.code,
                width: 200,
                align: 'center',
                // className: this.props.disabled ? 'background-grey' : '',
                // colSpan: 1,

            }))
    ])

    getTablesData = () => (
        this.props.stages.map(stage => ({
            stageName: (
                    <>
                        {!this.props.disabled && (
                                <input type="checkbox"
                                       checked={this.isHeaderRowCheckboxChecked(stage)}
                                       onClick={(e) => !this.props.disabled && this.onSelectRow(stage.name, e.target.checked)}/>
                        )}
                        &nbsp;&nbsp;
                        {stage.name}
                    </>
            ),
            ...this.props.roles.reduce((acc, role) => {
                acc[role.code] = (
                    <div
                        className={this.props.disabled ? 'cursor-disabled' : 'cursor-pointer'}
                        style={{'width': '60%', 'height': '18px', 'margin': 'auto'}}
                        onClick={() => !this.props.disabled && this.changeStageVisibility(stage.code, role.code)}
                    >
                        {this.showNecessity(stage.code, role.code)}
                    </div>)
                return acc
            }, {})
        }))
    )

    render() {
        return (
            <div className='col-md-8 '>
                <label>{this.props.label}</label>
                <Table
                    scroll={{x: 400}}
                    className='table table-bordered'
                    style={{marginBottom: '0px'}}
                    data={this.getTablesData()}
                    columns={this.getTablesCols()}
                    useFixedHeader={false}
                />
                <CustomOption
                    style={{background: '#fff', padding: '16px', top: '25px'}}
                    onActionClick={this.props.onActionClick}
                    name={this.props.name}
                />
                {this.props.loading ?
                    <LoadingIndicator height={50}/>
                    : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    stages: state.app.stages.data.filter(stage => stage.workflow === 'WF_COMMON'),
    roles: reducedRoles,
    // roles: state.app.users.roles.filter(role => role.code !== 'ROLE_ADMIN'),
})

const mapDispatchToProps = dispatch => ({
    getAllStages: () => {
        dispatch(getAllStages())
    },
    getAllRoles: () => {
        dispatch(getAllRoles())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(UsageMatrix);
