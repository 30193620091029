import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';
import { patientCaseStages } from '../patientCaseStages';

const closedThisMonthSlice = createDatatableTemplateSlice({
    name: patientCaseStages.CLOSED_THIS_MONTH,
    initialSettings: {},
});

export const closedThisMonthReducer = closedThisMonthSlice.reducer;

export const { loadData, dataLoaded, dataLoadError } = closedThisMonthSlice.actions;

export default closedThisMonthSlice;
