import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';
import createEntityMutationTemplateSlice from 'redux/utils/createEntityMutationTemplateSlice';
import { selectedAllTemplates } from './utils';

// it is necessary for the form
const initialEntity = {
    subject: { EN: '' },
    body: { EN: '' },
    name: '',
    code: '',
    toAssignee: [],
    ccAssignee: [],
    caseTemplate: selectedAllTemplates,
    triggerType: null,
};

const notificationTemplateSlice = createDatatableTemplateSlice(
    createEntityMutationTemplateSlice({
        name: 'notificationTemplate',
        initialSettings: {
            sortBy: 'templateName',
        },
        initialState: {
            entity: initialEntity,
        },
        reducers: {
            getAvailableTriggersSuccess: (state, { payload }) => {
                state.availableTriggers = payload.data;
            },
        }
    }),
);

export const notificationTemplateReducer = notificationTemplateSlice.reducer;

export const {
    loadData,
    dataLoaded,
    dataLoadError,
    changeSettings,
    editField,
    setValidityStatus,
    updateEntityError,
    updateEntityLoading,
    updateEntitySuccess,
    fetchEntityLoading,
    fetchEntitySuccess,
    fetchEntityError,
    createEntityError,
    createEntityLoading,
    createEntitySuccess,
    resetEntity,
    setServerErrors,
    getAvailableTriggersSuccess,
} = notificationTemplateSlice.actions;

export default notificationTemplateSlice;
