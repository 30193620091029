import React from 'react'
import MySelect from './MySelect'
import InputGroupWrapper from "./InputGroupWrapper";
import HelperText from './HelperText';

class SelectField extends React.Component {

    selectAll = ({target}) => {
        if (!target.checked) return;
        this.props.onChange(this.props.options);
    };

    render() {
        let i;
        let value = this.props.value != null && this.props.value !== 'null' ? this.props.value : '';

        i = <MySelect
            {...this.props}
            value={!Array.isArray(value) && value !== '' ? [value] : value}
            closeOnSelect={!this.props.multi}
            options={this.props.options || []}
            allowSelectAll={this.props.multi}
            clearable={this.props.clearable || true}
        />;

        let classes = "";
        // classes += " col-md-" + (this.props.md || "6")

        classes += `col-md-${this.props['labelLeft'] ? this.props.md - 2 : this.props.md}`;
        classes += " col-xs-" + (this.props.xs || "12");
        
        if (this.props.name === "reviewerSelect") {
            return i
        } else {
            return (
                <React.Fragment>
                    {this.props['labelLeft'] && 
                    <div className='col-md-2'>
                        <label>
                            {this.props.label}
                            {this.props.tickMark}
                        </label>
                    </div>}
                    <div className={classes}>
                        {!this.props['labelLeft'] &&
                        <label htmlFor={this.props.id} className="required"
                               style={{maxWidth: '100%'}}
                               id={'displayName-'+this.props.name}>
                            {this.props.label}
                            {this.props['labelAddon']}
                            {this.props.tickMark}
                        </label>
                        }
                        {this.props.title && <HelperText helperText={this.props.title}/>}
                        <span style={{display: 'none'}}>
                            {this.props.validator && this.props['valids']
                            ? this.props.validator.message(this.props['validLabel'] || this.props.label,
                            value, this.props['valids'], 'text-danger') : ''}
                        </span>
                        {this.props['actioned'] ? <InputGroupWrapper name={this.props.name} icon='code'
                                                                     onActionClick={this.props.onActionClick}>{i}</InputGroupWrapper> : i}
                    </div>
                </React.Fragment>

            )
        }
    }
}

export default SelectField
