import React, { useCallback, useContext } from 'react';
import { notificationAssigneeTypes } from '../../utils/Constants';
import Select from 'react-select/creatable';
import PropTypes from 'prop-types';
import { ControlContext } from '../../components/Form';
import { TO_ASSIGNEE_TYPES } from 'redux/pages/notification-template/utils';
import { toolTipId } from 'utils/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// eslint-disable-next-line no-control-regex
const RFC_5322_EMAIL_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const FIELD_REFERENCE_REGEXP = /^%[a-zA-Z]+(_*[a-zA-Z]+)*%$/;

const groupedOptions = notificationAssigneeTypes.map((t) => ({
  ...t,
  type: TO_ASSIGNEE_TYPES.assigneeTypes,
}));

export const NotificationAssigneTypeSelector = ({
  onChange,
  name,
  hasDefaultOptions,
  types,
  value: selectedValues,
  // eslint-disable-next-line no-unused-vars
  className,
  ...props
}) => {
  const { validationState } = useContext(ControlContext);
  const isInvalid = validationState === 'error';

  const handleChange = useCallback(
    (value) => {
      onChange({ target: { value } });
    },
    [onChange],
  );

  const handleCreate = useCallback(
    (inputValue) => {
      const values = selectedValues || [];
      if (FIELD_REFERENCE_REGEXP.test(inputValue)) {
        const newItem = {
          label: inputValue,
          value: inputValue,
          type: types.assigneeFieldReferences,
        };
        if (!(values || []).includes(newItem)) {
          handleChange([...values, newItem]);
        }
      } else if (RFC_5322_EMAIL_REGEXP.test(inputValue)) {
        const newItem = {
          label: inputValue,
          value: inputValue,
          type: types.assigneeEmails,
        };
        if (!values.includes(newItem)) {
          handleChange([...values, newItem]);
        }
      }
    },
    [handleChange, selectedValues, types],
  );

  const isValidNewOption = () => true;

  const invalidStyles = {
    control: (base) => ({
      ...base,
      borderColor: isInvalid ? '#c32323 !important' : base.borderColor,
    }),
  };

  const tooltip = (text) => (
    <Tooltip id={toolTipId('format-tooltip')}>
        {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="bottom" overlay={tooltip("Accepted formats: %FIELD_NAME% | email format")}>
      <div>
        <Select
        {...props}
        className="legacy-select"
        classNamePrefix="legacy-select"
        styles={invalidStyles}
        name={name}
        options={hasDefaultOptions ? [] : groupedOptions}
        onChange={handleChange}
        onCreateOption={handleCreate}
        isValidNewOption={isValidNewOption}
        value={selectedValues}
        isMulti
        />
      </div>
    </OverlayTrigger>
  );
};

NotificationAssigneTypeSelector.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  hasDefaultOptions: PropTypes.bool,
  types: PropTypes.object.isRequired,
  className: PropTypes.string,
  value: PropTypes.array,
};

NotificationAssigneTypeSelector.defaultProps = {
  onChange: () => {},
  hasDefaultOptions: false,
  required: false,
  className: '',
  value: [],
};

export default NotificationAssigneTypeSelector;
