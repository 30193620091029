import React from 'react';
import UserControlTable from './UserControlTable';
import connect from "react-redux/es/connect/connect";
import {isUser} from '../../utils/helpers'

class UserManagePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentCompany: {},
            currentUser: {}
        };
        this.setCurrentCompany = this.setCurrentCompany.bind(this);

    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        this.setState({
            currentUser: nextProps.currentUser
        });
        if (isUser(nextProps.currentUser.roleCode)) {
            let company = nextProps.companies.find((item) => {
                return (item.value === nextProps.currentUser.companyCode) ? item : null;
            });
            this.setState({
                currentCompany: company
            })
        }
    }

    setCurrentCompany(company) {
        this.setState({
            currentCompany: company
        })
    }

    render() {
        return (
            <div className='container-fluid'>
                <UserControlTable companyCode={this.state.currentCompany ? this.state.currentCompany.value : null}/>
            </div>
        )
    }
}

const mapState = (state) => {
    return {
        companies: state.app.companies.data.map(cp => ({
            label: cp.fullName,
            value: cp.code
        })),
        currentUser: state.app.auth.user
    }
};

export default connect(mapState, null)(UserManagePage);
