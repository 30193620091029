import connect from "react-redux/es/connect/connect";
import CaseButton from './CaseButton'
import { getMergedDocForReviewer } from "../../../redux/actions/cases/caseReportsActions";

const mapState = (state) => {
    const mergedPagesCount = state.app.cases.editedCase.mergedPagesCount;
    const isVisible = mergedPagesCount !== 'undefined' && mergedPagesCount > 0;
    const className = 'btn-default margin-left-5';
    const label = 'View Case Documents';
    return {
        icon: 'fa-file',
        title: label,
        content: label,
        disabled: state.app.cases.loading,
        isVisible,
        className
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onClick: () => dispatch(getMergedDocForReviewer())
    }
};

export default connect(mapState, mapDispatchToProps)(CaseButton);
