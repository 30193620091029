import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import FilteredReviewers from './FilteredReviewers';
import {
    getExtendedAssignments,
    deleteExtendedAssignmentsNoneTierOne
} from '../../../redux/actions/cases/caseAssignActions';
import { getCase } from '../../../redux/actions/cases/caseActions';
import PropTypes from 'prop-types';
import TierGridNew from "./TierGridNew";
import {closeCreateModal, openCreateModal} from '../../../redux/action-creators/users';
import SubmitButton from '../../../components/SubmitButton';
import case_assign from 'redux/reducers/cases/case_assign';

class ReviewerTiering extends Component {

    static propTypes = {
        caseCode: PropTypes.string,
        editedCaseCode: PropTypes.string,
        case_assign: PropTypes.array,
        reviewersCount: PropTypes.number,
        languages: PropTypes.array,
        getCase: PropTypes.func,
        getExtendedAssignments: PropTypes.func,
        deleteExtendedAssignmentsNoneTierOne: PropTypes.func
    }

    componentDidMount() {
        this.props.getExtendedAssignments(this.props.caseCode);
        if (this.props.editedCaseCode !== this.props.caseCode) {
            this.props.getCase(this.props.caseCode);
        }
    }

    deleteAllNonTierOne = (e) => {
        this.props.deleteExtendedAssignmentsNoneTierOne(this.props.caseCode, 'ALL_NO_TIER');
        this.props.modalClose();
    }

    render() {
        let reviewersCount = this.props.reviewersCount;
        let caseCode = this.props.caseCode;
        const style = {
            marginTop: '30px'
        };

        return (
            <form className="container-fluid" onSubmit={(e) => e.preventDefault()}>
                <div className='row margin-top-15' style={{ padding: 0 }}>
                    <h2 className='col-md-6'>Filtered Reviewers</h2>
                    <h2 className='col-md-6 text-right'>
                        <small style={{ color: 'inherit' }}>found {reviewersCount} reviewers</small>
                    </h2>
                </div>
                <div style={style}>
                    <FilteredReviewers caseCode={caseCode} title='No Tier' tier='NO_TIER'> </FilteredReviewers>
                </div>

                <div className='row margin-top-15' style={{ padding: 0 }}>
                    <h2 className='col-md-6'>Reviewer Assignment</h2>
                    <h2 className='col-md-6 text-right'>
                       <SubmitButton disabled={this.props.case_assign && this.props.case_assign.length <= 1}
                        onSubmit={(e) => this.props.modalOpen()} title='De-populate Tiers II and III'/>
                    </h2>
                </div>

                <div style={style}>
                    <TierGridNew caseCode={caseCode} title='Tier I' tier='TIER_1' languages={this.props.languages}> </TierGridNew>
                </div>
                <div style={style}>
                    <TierGridNew caseCode={caseCode} title='Tier II' tier='TIER_2' languages={this.props.languages}> </TierGridNew>
                </div>
                <div style={style}>
                    <TierGridNew caseCode={caseCode} title='Tier III' tier='TIER_3' languages={this.props.languages}> </TierGridNew>
                </div>
                <Modal
                        isOpen={this.props.isOpen}
                        className="custom-modal modal-dialog"
                        overlayClassName="modal-overlay"
                >
                    <div className='modal-content custom-modal-content text-center'>
                        <h3 className="text-center">Do you really want to de-populate Tiers II and III?</h3>

                        <div>
                            <button
                                    className='btn btn-grey margin-left-5 margin-right-5 margin-top-5'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.deleteAllNonTierOne();
                                    }}>
                                Yes
                            </button>
                            <button
                                    className='btn btn-grey margin-left-5 margin-top-5'
                                    onClick={this.props.modalClose}>
                                No
                            </button>
                        </div>
                    </div>
                </Modal>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        case_assign: state.app.case_assign.extendedAssignments,
        editedCaseCode: state.app.cases.editedCase.code,
        reviewersCount: state.app.filteredReviewersReducer.totalRecordsFiltered,
        languages: state.app.languages.data,
        isOpen: state.app.users.createModalOpen,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCase: (code) => dispatch(getCase(code)),
        getExtendedAssignments: (caseCode) => dispatch(getExtendedAssignments(caseCode)),
        deleteExtendedAssignmentsNoneTierOne: (code, data) => dispatch(deleteExtendedAssignmentsNoneTierOne(code, data)),
        modalOpen: () => dispatch(openCreateModal()),
        modalClose: () => dispatch(closeCreateModal())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewerTiering);
