import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {itemToStageCode} from '../utils/Constants'
import {Badge} from 'react-bootstrap'
import { isReviewer, toolTipId } from "../utils/helpers";
import {resetSearch} from "../redux/global-search/globalSearchSlice";


const tooltip = (text, visible) => (
    <Tooltip id={toolTipId('menu-item')} style={{visibility: visible ? 'visible' : 'hidden'}}>
        {text}
    </Tooltip>
);

const ColorRectangle = ({value, color, className}) => {
    return (
        <Badge
            pullRight
            style={{
                background: color,
                borderRadius: '5px',
                // display,
                // transition: 'visibility .3s, opacity .5s'
            }}
            className={className}
        >{value}
        </Badge>)
};

const getCaseCount = (itemCode, caseCount) => {
    if (!itemCode) {
        return null;
    }
    const stage = itemToStageCode[itemCode.split('_').slice(1, itemCode.length).join('_')];
    return caseCount[stage] !== undefined ? {
        count: caseCount[stage],
        color: '#08437a'
    } : null
};

class MenuItem extends Component {
    static propTypes = {
        icon: PropTypes.string,
        name: PropTypes.string,
        cssClasses: PropTypes.string
    };

    isActive = () => {
        const {url, location, isGroupItem} = this.props;
        return isGroupItem ? false : url === location || location.match(url + '/')
    };

    componentDidMount() {
        window.onscroll = () => {
            return document.querySelectorAll("#tooltip.fade.in.tooltip.right").forEach((element) => element.style.display = 'none');
        }
    }

    handleClick = () => {
        this.props.resetGlobalSearch();
    }

    render() {
        let counter = '';
        const data = getCaseCount(this.props.itemName, this.props.caseCount);
        if (data !== null && !isReviewer(this.props.currentUserRole)) {
            counter = <ColorRectangle
                color={data.color}
                value={data.count}
                display={this.props.sidebarCollapsed ? 'none' : 'inline-block'}
                className={`my_transition ${this.props.sidebarCollapsed ? '' : 'my_transition_visible'}`}
            />
        }
        let activeClassName = this.isActive() ? 'active' : '';

        return (
            <li style={{overflow: 'hidden'}} className={activeClassName}>
                <OverlayTrigger placement="right"
                                overlay={tooltip(`${this.props.name} ${data && !isReviewer(this.props.currentUserRole) ? data.count : ''}`, this.props.sidebarCollapsed)}>
                    <Link to={this.props.url && `${this.props.url}`} onClick={this.props.onClick ? this.props.onClick : this.handleClick}>
                        <i className={`fa ${this.props.icon} ${this.props.cssClasses} custom-fa-middle`}/>
                        <span>{this.props.name}</span>
                        {counter}
                    </Link>
                </OverlayTrigger>
            </li>
        )
    }
}

const mapStateToProps = (state) => ({
    currentUserRole: state.app.auth.user.roleCode,
    location: state.router.location.pathname,
    caseCount: state.app.context.caseCount,
    sidebarCollapsed: state.app.menu.sidebarCollapsed
});

const mapDispatchToProps = (dispatch) => {
    return {
        resetGlobalSearch: () => dispatch(resetSearch())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
