import React from 'react'
import {Link} from 'react-router-dom'

const MainLogo = () => (
    <Link className="logo navbar-fixed-top scale-logo" to='/'>
        <img src={require('../../styles/img-custom/medrev-logo-medium.png')} alt="MedRev"/>
    </Link>
);

export default MainLogo
