import React from 'react';
import InputGroupWrapper from './InputGroupWrapper';
import { DateTimePicker } from 'react-widgets';
import moment from 'moment';
import PropTypes from "prop-types";
import { LOCAL_TIMEZONE_OFFSET } from "../../utils/Constants";
import { connect } from "react-redux";
import HelperText from './HelperText';

class DateTimeField extends React.Component {

    constructor(props) {
        super(props);
        this.state = { needClear: false };
        this.isClearable = true;
        this.dateTimePickerRef = React.createRef();
        this.minDate = typeof props.name !== 'undefined' && props.name.toLowerCase().includes('duedate') ? props.min : new Date(1900,0,1)
    }

    convertReceivedValue = (value) => {
        let result;
        if (!value || new Date(value).toString() === 'Invalid Date') {
            result = null;
        } else if (value instanceof Date) {
            result = value;
        } else if (!this.props.allowTime) { // only datepicker e.g. 06/02/2021
            result = new Date(value);
         } else if (typeof value === 'string'){
            result = new Date(Date.parse(new Date(value)) - LOCAL_TIMEZONE_OFFSET);
        } else { // datetimepicker
            result = new Date(value + LOCAL_TIMEZONE_OFFSET);
        }
        return result;
    }

    getDisplayConfig = () => {
        const { allowTime, allowDate } = this.props;
        if (allowDate && allowTime) return {
            format: 'dddd, MMMM DD YYYY, hh:mm A',
            editFormat: 'DD/MM/YYYY hh:mm A',
            placeholder: 'Type date and time in format dd/mm/yyyy hh:mm AM/PM or select from calendar'
        }
        if (allowDate) return {
            format: 'dddd, MMMM DD YYYY',
            editFormat: 'DD/MM/YYYY',
            placeholder: 'Type date in format dd/mm/yyyy or select from calendar'
        }
        if (allowTime) return {
            format: 'hh:mm A',
            editFormat: 'hh:mm A',
            placeholder: 'Type time in format hh:mm AM/PM or select from time widget'
        }
    }

    onClearClick = () => {
        this.onChange(null, '');
    }

    onChange = (value, dateString) => {
        if(value < this.minDate){
            const minDate = this.minDate;
            this.props.onChange(minDate, minDate.toDateString());
        } else {
            this.props.onChange(value, dateString);
        }
        if (typeof this.props.onChange === 'function') {
        }
        this.props.onBlur();
        setTimeout(() => document.activeElement.blur());
    }

    handleMouseDown = ({ target }) => {
        const timeText = (target.childNodes[0] && target.childNodes[0].data);
        if (target.tagName !== 'LI' || !timeText) {
            return;
        }
        const newDate = new Date(this.props.value + LOCAL_TIMEZONE_OFFSET);
        const yearMonthDayStr = newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate();
        this.onChange(moment(yearMonthDayStr + ', ' + timeText, 'YYYY-MM-DD hh:mm A').toDate());
        if (this.dateTimePickerRef && this.dateTimePickerRef.current) {
            this.dateTimePickerRef.current.close();
        }
    }

    render() {
        const { format, editFormat, placeholder } = this.getDisplayConfig();

        let classes = '';
        classes += `col-md-${this.props.labelLeft ? this.props.md - 2 : this.props.md}`
        classes += " col-xs-" + (this.props.xs || "12")
        classes += " col-xs-" + (this.props.xs || "12") + " col-xs-offset-" + (this.props.offset || 0)

        let value = this.convertReceivedValue(this.props.value);
        const dateTimePicker = <DateTimePicker
            ref={this.dateTimePickerRef}
            id={this.props.id}
            name={this.props.name}
            title={this.props.title}
            containerClassName={this.props.className}
            disabled={this.props.disabled || this.props.loading}
            required={this.props.required}
            date={this.props.allowDate}
            time={this.props.allowTime}
            // value={this.convertReceivedValue(this.props.value)}
            value={value}
            // value={this.props.allowTime && this.props.loading ? null : value}
            // onChange={this.props.onChange}
            onChange={this.onChange}
            onBlur={this.props.onBlur}
            onMouseDown={this.props.allowTime ? this.handleMouseDown : undefined}
            editFormat={editFormat}
            format={format}
            placeholder={placeholder}
            min={ this.minDate }
            max={this.props.max !== null ? this.props.max : new Date('2100-01-01')}
            culture='en'
        />
        return (
            <React.Fragment>
                {this.props.labelLeft && 
                <div className='col-md-2'>
                    <label>
                        {this.props.label}
                        {this.props.tickMark} 
                    </label>
                </div>}
                <div className={classes}>
                    {!this.props.labelLeft && 
                    <label id={'displayName-'+this.props.name} htmlFor={this.props.id} className="required">
                        {this.props.label}
                        {this.props.tickMark}
                    </label>}
                    {this.props.title && <HelperText helperText={this.props.title}/>}
                    <span style={{ display: 'none' }}>{this.props.validator && this.props.valids
                        ? this.props.validator.message(this.props.validLabel || this.props.label,
                            this.props.value, this.props.valids, 'text-danger') : ''}</span>
                    {this.props.actioned ?
                        <InputGroupWrapper name={this.props.name} icon='code' onActionClick={this.props.onActionClick}>{dateTimePicker}</InputGroupWrapper>
                    : this.isClearable ?
                            <div style={{ position: 'relative' }}>
                                {dateTimePicker}
                                <span style={{ position: 'absolute', top: '2px', right: this.props.allowTime ? '60px' : '34px',
                                          cursor: 'pointer', fontSize: '20px', fontWeight: '700' }}
                                      onClick={this.onClearClick}
                                >
                                    ×
                                </span>
                            </div>
                    : dateTimePicker}
                </div>
            </React.Fragment>
        )
    }

    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
        label: PropTypes.any,
        validLabel: PropTypes.string,
        valids: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        value: PropTypes.any, // TODO
        allowTime: PropTypes.bool,
        allowDate: PropTypes.bool,
        labelLeft: PropTypes.bool,
        actioned: PropTypes.bool,
        xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        min: PropTypes.any,
        max: PropTypes.any,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onActionClick: PropTypes.func,
        validator: PropTypes.object,
    }
    static defaultProps = {
        allowTime: false,
        allowDate: true
    };

}

const mapStateToProps = (state) => {
    return {
        loading: state.app.cases.loading,
    }
}

export default connect(mapStateToProps)(DateTimeField);
