import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { login } from '../../redux/actions/authActions';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_KEY } from '../../config';
import ResetPasswordLink from './Login/ResetPasswordLink';
import LoginFields from './Login/LoginFields';
import LoginButton from './Login/LoginButton';
import LoginMessage from './Login/LoginMessage';

//TODO: @Deprecated
const Login = () => {
  const dispatch = useDispatch();
  const [state, setStateOriginal] = useState({
    errors: {},
    username: '',
    password: '',
    isLoading: false,
    isError: false,
    firstLoad: true,
  });
  const { isLoading, isError, firstLoad } = state;

  const { automaticLogoutPreviousPath, lastVisitedPath, currentUser, error } = useSelector((state) => {
    const { user } = state.app.auth;

    return {
      error: state.app.auth.error,
      automaticLogoutPreviousPath: state.app.loginRedirect.redirectTo,
      currentUser: user,
      lastVisitedPath: state.app.auth.logoutPath[user ? user.code : null],
    };
  }, shallowEqual);

  const { code } = currentUser;

  const setState = useCallback(
    (newState) => {
      setStateOriginal({ ...state, ...newState });
    },
    [setStateOriginal, state],
  );

  const onSubmit = (event) => {
    event.preventDefault();
    setState({ errors: {}, isLoading: true });
    dispatch(login(state)).then(
      () =>
        setState({
          isLoading: false,
          firstLoad: false,
        }),
      () =>
        setState({
          isLoading: false,
          isError: true,
          firstLoad: false,
        }),
    );
  };

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      setState({ [name]: value });
    },
    [setState],
  );

  const onChangeRe = useCallback(
    (response) => {
      setState({
        'g-recaptcha-response': response,
      });
    },
    [setState],
  );

  const pathToRedirectAfterLogin = useMemo(() => {
    if (code) {
      return !lastVisitedPath || lastVisitedPath === '/login' ? automaticLogoutPreviousPath : lastVisitedPath;
    }

    return '/';
  }, [automaticLogoutPreviousPath, code, lastVisitedPath]);

  if (isError) {
    if (document.getElementById('google_authenticator')) {
      document.getElementById('google_authenticator').value = '';
    }
    if (window.grecaptcha) {
      window.grecaptcha.reset();
    }
    setState({ isError: false });
  }

  if (currentUser && currentUser.code) {
    return <Redirect to={pathToRedirectAfterLogin + ''} />;
  }

  return (
    <div>
      <LoginMessage showMessage={!!error && !firstLoad} message={error} />
      <form onSubmit={onSubmit} id="loginForm" className="wide-form">
        <LoginFields onChange={onChange} />
        <div className="form-group has-feedback">
          <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={onChangeRe} />
        </div>
        <LoginButton disabled={isLoading} />
      </form>
      <ResetPasswordLink />
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func,
  currentUser: PropTypes.object,
  lastVisitedPath: PropTypes.string,
  automaticLogoutPreviousPath: PropTypes.string,
  pathToRedirectAfterLogin: PropTypes.string,
  error: PropTypes.string,
};

export default Login;
