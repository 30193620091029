import React from 'react';
import {connect} from 'react-redux';
import LoadingIndicator from '../../../components/LoadingIndicator';
import RemoteGrid from "../../../components/Grid/RemoteGrid";
import {getAllCompanies} from '../../../redux/actions/companiesActions'
import { API_HOST } from '../../../config';

class ControlTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showActivateModal: false,
            showEditModal: false,
            clientId: ''
        };

        this.enumFormatter = this.enumFormatter.bind(this);
        this.logoFormatter = this.logoFormatter.bind(this);
    }

    componentDidMount() {
        this.props.getAllCompanies();
    }

    enumFormatter = (cell, row, enumObject) => {
        return enumObject[cell];
    };

    logoFormatter = (cell) => {
        if (cell !== '')
            return (<img style={{width: 100}} src={cell} alt=""/>);
        else return '';
    };

    handleOpenEdit = (clientId) => {
        this.props.history.push(`/clients/profile/${clientId}`)
    };

    render() {
        const {companies} = this.props;

        let currencies = {};
        for (let i = 0; i < this.props.currencies.length; i++) {
            currencies[this.props.currencies[i]] = this.props.currencies[i];
        }

        let companiesData = [];
        for (let i = 0; i < companies.length; i++) {
            let company = companies[i];
            company.address = '';
            if (company.city) company.address = company.address + company.city;
            if (company.street1) company.address = company.address + ' ' + company.street1;
            if (company.logo != null) {
                company.logoFile = API_HOST + '/company/logo?code=' + company.code;
            } else {
                company.logoFile = '';
            }
            companiesData.push(company);
        }

        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'hidden': true
            }, {
                'name': 'fullName',
                'title': 'Full Name',
                'sort': true,
                'width': '200',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'shortName',
                'title': 'Short Name',
                'sort': true,
                'width': '100',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'address',
                'title': 'Address',
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'vatId',
                'title': 'VAT ID',
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'currencyCode',
                'title': 'Currency',
                'sort': true,
                'width': '150',
                'dataAlign': 'left',
                'filter': {type: 'SelectFilter', options: currencies}
            }, {
                'name': 'active',
                'title': 'Status',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': 'Inactive',
                    'true': 'Active'
                },
                'width': '100',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': 'Inactive',
                        'true': 'Active'
                    }
                }
            }
        ];
        const {sorting, pagination} = this.props.gridConfiguration;

        return (
            <div className="container-fluid table-responsive">
                <RemoteGrid
                    color="blue"
                    entity="template_client"
                    title={`Template - ${this.props.templateName}`}
                    data={companiesData}
                    total={companiesData.length}
                    columns={columns}
                    pagination={true}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    search={true}
                    onRowClick={this.handleOpenEdit}
                    defaultSort
                    sortName={sorting.sortName}
                    sortOrder={sorting.sortOrder}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    companies: state.app.companies.data.filter((item) => item.defaultTemplateCode === state.app.templates.code),
    currencies: state.app.currencies.data
        .map(currency => (
            [currency.code]
        )),
    loading: state.app.users.loading,
    templateName: state.app.templates.name,
    gridConfiguration: state.app.grid.templateClient
});

const mapDispatchToProps = dispatch => ({
    editField: () => console.log('edit'),
    getAllCompanies: () => dispatch(getAllCompanies())
});

// ControlTable = withRouter(ControlTable)
export default connect(mapStateToProps, mapDispatchToProps)(ControlTable)
