import React from 'react';
import GlobalSearch from './GlobalSearch';
import UserMenu from './UserMenu';
import Logout from './Logout';

const NavBarNav = () => {
  return (
    <ul className="nav navbar-nav">
      <GlobalSearch />
      <UserMenu />
      <Logout />
    </ul>
  );
};

export default NavBarNav;
