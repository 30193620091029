import React from 'react'
import connect from "react-redux/es/connect/connect";
import LoadingIndicator from '../LoadingIndicator';

class NotFound extends React.Component {
    render() {
        return (
            <div style={{paddingLeft: '30px'}}>
                {
                    this.props.loading ? 
                    <LoadingIndicator />
                    :
                    <h1>Page Not Found</h1>
                }
            </div>
        )
    }
}

const mapState = (state) => {
    return {
        loading: state.app.context.loading
    }
}

export default connect(mapState)(NotFound);