import React, { forwardRef, useEffect } from 'react';
import notificationTemplateSlice from '../../redux/pages/notification-template/notificationTemplateSlice';
import { CC_ASSIGNEE_TYPES, selectedAllTemplates, TO_ASSIGNEE_TYPES } from '../../redux/pages/notification-template/utils';
import NotificationAssigneeTypeSelector from './NotificationAssigneTypeSelector';
import { Row } from 'react-bootstrap';
import { Control, Form, FormSectionLabel, useForm } from '../../components/Form';
import SystemMessage from 'components/SystemMessage';
import PropTypes from 'prop-types';
import { transformNotificationEntityToSubmit } from 'redux/pages/notification-template/utils';
import ControlSelector from '../../components/Controls/ControlSelector';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplates } from '../../redux/actions/templateActions';
import { getNotificationsAvailableTriggers } from '../../redux/actions/notificationTemplateActions';

export const FORM_KEY = 'notificationTemplates';

export const FORM_TYPES = {
  new: 'new',
  edit: 'edit',
};

const NotificationTemplateForm = forwardRef(({ children, className, onSubmit, type }, ref) => {
  const formProps = useForm(notificationTemplateSlice.actions, FORM_KEY);
  
  const dispatch = useDispatch();
  
  const { mappedErrorMessages, isLoading, serverErrors, entity } = formProps;
  
  const { allTemplates } = useSelector((state) => state.app.templates);
  
  const { availableTriggers } = useSelector((state) => state.app.notificationTemplates);
  
  useEffect(() => {
    dispatch(getTemplates());
    dispatch(getNotificationsAvailableTriggers());
  }, [dispatch]);
  
  const templates = [selectedAllTemplates].concat(
    allTemplates
    .filter((item) => item.active)
    .map(({ name, id }) => ({
      label: name,
      value: id,
    })),
  );
  
  const triggers =
    availableTriggers &&
    Object.keys(availableTriggers).map((key) => {
      return {
        label: availableTriggers[ key ],
        value: key,
      };
    });
  
  return (
    <Form
      ref={ref}
      className={className}
      onSubmit={(...params) => {
        onSubmit(transformNotificationEntityToSubmit(entity), ...params);
      }}
      formKey={FORM_KEY}
      {...formProps}
    >
      <SystemMessage type="error" validationMessages={[...mappedErrorMessages, ...serverErrors]} shown={!isLoading} preventAddClasses />
      <FormSectionLabel label="General" />
      <Row>
        <Control md={6} label="Template Code" name="code" required disabled={type === FORM_TYPES.edit} />
        <Control md={6} label="Template Name" name="name" required disabled={isLoading} />
      </Row>
      <Row>
        <Control
          className="caseFileTemplateForm__control-selector"
          md={6}
          component={ControlSelector}
          label="Case Template"
          name="caseTemplate"
          options={templates}
          disabled={isLoading}
        />
        <Control
          className="caseFileTemplateForm__control-selector"
          md={6}
          component={ControlSelector}
          label="Trigger Types"
          name="triggerType"
          options={triggers}
          disabled={isLoading}
          required
        />
      </Row>
      <FormSectionLabel label="Email Assignees" />
      <Row>
        <Control md={6} component={NotificationAssigneeTypeSelector} label="To" name="toAssignee" disabled={isLoading} types={TO_ASSIGNEE_TYPES} />
        <Control md={6} component={NotificationAssigneeTypeSelector} label="Cc" name="ccAssignee" disabled={isLoading} types={CC_ASSIGNEE_TYPES} />
      </Row>
      <FormSectionLabel label="Template" />
      <Row>
        <Control md={12} label="Subject" name="subject" disabled={isLoading} required translated />
      </Row>
      <Row>
        <Control componentClass="textarea" rows={8} md={12} label="Body" name="body" disabled={isLoading} translated required />
      </Row>
      {children}
    </Form>
  );
});

NotificationTemplateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.any,
  type: PropTypes.oneOf(['new', 'edit']).isRequired,
  className: PropTypes.string,
};

NotificationTemplateForm.defaultProps = {
  className: '',
};

export default NotificationTemplateForm;
