import React from 'react';
import { Link } from 'react-router-dom';

// TODO: @Deprecated
const ResetPasswordLink = () => {
  return (
    <div
      className="login-box-msg"
      style={{ float: 'left', marginTop: '-25px' }}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link to="/login/reset-password">Reset password</Link>
    </div>
  );
};

export default ResetPasswordLink;
