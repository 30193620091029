import React from 'react';
import {connect} from 'react-redux'
import {getAll} from '../../redux/actions/BasicEntityActions'
import {
    dispatchTemplateValidStatus,
    editTemplate,
    isTemplateValid,
    setCurrentTemplate,
    updateTemplate
} from "../../redux/actions/templateActions";
import FormField from '../../components/FormField'
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';

class TemplateManageForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            templateChanged: false
        };

        props.validator.showMessages();

    }

    componentDidMount() {
        this.props.getInitData()
    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        if (!nextProps.isFormValid && nextProps.isFormValid !== this.props.isFormValid) {
            this.props.validator.showMessages();
        }

    }

    onChangeDisplayName = (e) => {
        const {name, value} = e.target;

        this.props.templateEditField(name, value);
    };

    onChangeWorkflow = (workFlowCode) => {
        this.props.templateEditField('workflowCode', workFlowCode)
    };

    onChangeLanguage = (language) => this.setState({language});

    onChangeCheckBox = (e) => {
        this.props.templateEditField([e.target.name], e.target.checked)
    };

    saveTemplate = () => {
        const {template} = this.props;
        const fieldInstanceCodes = template.fieldInstancesDto.map((item) => item.code);
        const names = {'EN': template.name};

        return this.props.updateTemplate({...template, names, fieldInstanceCodes})
    };

    render() {
        return (<section className="content user-profile-content">
            <div className="row">
                <div className="col-md-12">
                    <PreventTransitionPrompt
                        when={this.props.isTemplateChanged}
                        message='MyCustomDialogComponent'
                        entity='template'
                        isFormValid={this.props.isFormValid}
                        saveChanges={this.saveTemplate}
                        cancelAndDiscard={() => {
                            this.props.setCurrentTemplate(this.props.originalTemplate);
                            this.props.templateEditField('isTemplateChanged', false)
                        }}
                    />

                    <Box color="blue" classes="box-profile-first-row-height-show">
                        <BoxHeader title={this.props.label} color="blue"/>

                        <BoxBody>
                            <form className='' onSubmit={this.onSubmit}>

                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="12"
                                            label="Template Name"
                                            id="Template Name"
                                            className="form-control"
                                            name="name"
                                            required
                                            value={this.props.template.name}
                                            onChange={this.onChangeDisplayName}
                                            valids="required|unique_template"
                                            validator={this.props.validator}
                                            onBlur={this.props.dispatchValidState}
                                        />
                                        {/*  <FormField
                     md="2"
                     type="REF_LANGUAGE"
                     label="Language"
                     className="text-bg "
                     name="language"
                     value={this.state.language}
                     onChange={this.onChangeLanguage}
                     clearable={false}
                     /> */}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="12"
                                            type="select"
                                            label="Work Flow"
                                            id="Work Flow"
                                            className="text-bg required-field"
                                            options={this.props.workFlows}
                                            name="workFlowCode"
                                            value={this.props.template.workflowCode}
                                            onChange={this.onChangeWorkflow}
                                            valids="required"
                                            validator={this.props.validator}
                                            onBlur={this.props.dispatchValidState}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="row">
                                        <FormField
                                            md="12"
                                            type="checkbox"
                                            title="Status"
                                            label="Active"
                                            name='active'
                                            value={this.props.template.active}
                                            checked={this.props.template.active}
                                            onChange={this.onChangeCheckBox}
                                            className="checkbox"/>
                                    </div>
                                </div>

                            </form>
                        </BoxBody>
                        <BoxFooter/>
                    </Box>
                </div>
            </div>
        </section>)
    }
}

const mapStateToProps = (state) => ({
    template: state.app.templates,
    originalTemplate: state.app.templates.originalTemplate,
    isTemplateChanged: state.app.templates.isTemplateChanged,
    workFlows: state.app.workflows.data.map((item) => ({label: item.name, value: item.code})),
    error: state.app.templates.error,
    loading: state.app.templates.loading,
    templateEditSuccess: state.app.templates.templateEditSuccess,
    label: `Template - ${state.app.templates.name}`,
    isFormValid: state.app.templates.isTemplateValid,
});

const mapDispatchToProps = (dispatch) => {
    return {
        templateEditField: (field, value) => (dispatch(editTemplate(field, value))),
        getInitData: () => {
            dispatch(setCurrentTemplate());
            dispatch(dispatchTemplateValidStatus(true));
            dispatch(isTemplateValid(true));
            return dispatch(getAll('workflow'))
        },
        isAllValid: (valid) => dispatch(isTemplateValid(valid)),
        updateTemplate: data => dispatch(updateTemplate(data)),
        setCurrentTemplate: data => dispatch(setCurrentTemplate(data)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateManageForm)
