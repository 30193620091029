import React from 'react';
import FormField from '../FormField'
import PropTypes from "prop-types";

export default class ContactInfoSection extends React.Component {

    render() {
        const { onChange, validator, dispatchValidState, email, notificationEmail, phoneNumber, alternativePhoneNumber, isEditable} = this.props;

        return (
            <div className="form-group bordered">
                <div className="form-group bordered">
                    <div className="row">
                        <FormField
                            md="12"
                            type='label_field'
                            label="Contact Information"
                            className='margin-bottom-15 fontWeight800'
                            style={{fontSize: '1.1em'}}
                        />
                        <FormField
                            md="4"
                            icon="envelope-o"
                            type="input-icon"
                            label={this.context.translate('common.form.email')}
                            id={this.context.translate('common.form.email')}
                            className="form-control"
                            disabled
                            name="email"
                            value={email}
                            onChange={onChange}
                        />
                        <FormField
                            md="4"
                            icon="envelope-o"
                            type="input-icon"
                            required
                            label={this.context.translate('common.form.notification_email')}
                            id={this.context.translate('common.form.notification_email')}
                            className="form-control"
                            name="notificationEmail"
                            valids="required|email"
                            validator={this.props.validator}
                            value={notificationEmail}
                            onChange={onChange}
                            onBlur={dispatchValidState}
                            disabled={!isEditable}
                        />
                    </div>
                </div>
                <div className="form-group ">
                    <div className="row">
                        <FormField
                            md="4"
                            type="input-icon"
                            icon="phone"
                            required
                            label={this.context.translate('common.form.phone_number')}
                            id={this.context.translate('common.form.phone_number')}
                            className="form-control"
                            name="phoneNumber"
                            valids="required"
                            validator={validator}
                            value={phoneNumber}
                            onChange={onChange}
                            onBlur={dispatchValidState}
                            disabled={!isEditable}
                        />
                        <FormField
                            md="4"
                            type="input-icon"
                            icon="phone"
                            label={this.context.translate('common.users.alternative_phone_number')}
                            id={this.context.translate('common.users.alternative_phone_number')}
                            className="form-control"
                            name="alternativePhoneNumber"
                            value={alternativePhoneNumber}
                            onChange={onChange}
                            disabled={!isEditable}
                        />
                    </div>
                </div>
            </div>
        )
    }

    static propTypes = {
        isEditable: PropTypes.bool,
    }
    static defaultProps = {
        isEditable: true,
    }
}

ContactInfoSection.contextTypes = {
    translate: PropTypes.func
};
