// import React from 'react';
import connect from "react-redux/es/connect/connect";
import CaseButton from './CaseButton'
import {getLastReportForClient} from '../../../redux/actions/cases/caseReportsActions'

const mapState = (state) => {
    const {my_permissions} = state.app.users;
    const {reports} = state.app.cases;
    const isVisible = my_permissions.includes('MODIFY_CASES') && reports.length > 0;
    const className = state.app.cases.editedCase.currentStage.code === "CW_CLOSED" ? 'btn-success margin-left-5' : 'btn-default margin-left-5';
    return {
        icon: 'fa-file',
        title: 'Download Report',
        disabled: state.app.cases.loading,
        isVisible,
        className
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onClick: () => dispatch(getLastReportForClient())
    }
};

export default connect(mapState, mapDispatchToProps)(CaseButton);
