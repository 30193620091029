import React from 'react';
import { available } from 'redux/pages/patient-case/patientCaseColumns';
import availableSlice from 'redux/pages/patient-case/slices/availableSlice';
import PatientCaseGrid from '../Components/PatientCaseGrid';

const columns = available;

const Available = (props) => (
  <PatientCaseGrid columns={columns} slice={availableSlice} {...props} />
);

export default Available;
