import { createSlice } from '@reduxjs/toolkit';
import { camelCase, groupBy, mapValues, find, chain, keys } from 'lodash';
import createEntityMutationTemplateSlice from 'redux/utils/createEntityMutationTemplateSlice';

export const FINANCE_RATE_TYPES = {
    flat: 'FLAT',
    hourly: 'HOURLY',
};

const FLAT_FIELDS = {
    caseMargin: 'caseMargin',
    clientAmount: 'clientAmount',
    maxBillableAmount: 'maxBillableAmount',
    maxBillableAmountInReviewerCurrency: 'maxBillableAmountInReviewerCurrency',
    reviewerAmount: 'reviewerAmount',
    reviewerAmountInReviewerCurrency: 'reviewerAmountInReviewerCurrency',
};

const HOURLY_FIELDS = {
    maxBillableTime: 'maxBillableTime',
    maxBillableAmount: 'maxBillableAmount',
    reviewerTime: 'reviewerTime',
    clientTime: 'clientTime',
    maxBillableAmountHourlyRate: 'maxBillableAmountHourlyRate',
    reviewerAmountHourlyRate: 'reviewerAmountHourlyRate',
    billingTierHourlyRate: 'billingTierHourlyRate',
};

export const FINANCE_FIELDS = {
    billingTier: 'billingTier',
    ...FLAT_FIELDS,
    ...HOURLY_FIELDS,
};

// it is neccessary for the form
const initialEntity = {
    billingTier: '',
    ...mapValues(FLAT_FIELDS, () => 0),
    ...mapValues(HOURLY_FIELDS, () => 0),
};

const initialFieldSettings = mapValues(FINANCE_FIELDS, (name) => ({ name, unit: '' }));
const initialFieldVisibilities = mapValues(FINANCE_FIELDS, () => false);

const initialState = {
    entity: initialEntity,
    fieldSettings: initialFieldSettings,
    billingTiers: [],
    fieldVisibilites: initialFieldVisibilities,
    error: null,
    initialized: false,
    showResetButton: false,
    calculateUpdateSuccess: null,
    rateType: '',
    isLoading: false,
};

const financeSlice = createSlice(
    createEntityMutationTemplateSlice({
        name: 'finances',
        initialState: initialState,
        reducers: {
            billingTierChange() {},
            resetInitialization(state) {
                state = initialState;
            },
            resetVisibilities(state) {
                state.initialized = false;
                state.fieldVisibilites = initialFieldVisibilities;
            },
            calculate(state) {
                state.calculateUpdateSuccess = null;
                state.error = null;
                state.isLoading = true;
            },
            override(state) {
                state.calculateUpdateSuccess = null;
                state.error = null;
                state.isLoading = true;
            },
            reset(state) {
                state.error = null;
                state.isLoading = true;
                state.isResetting = false;
            },
            resetSuccess(state) {
                state.isResetting = false;
            },
            calculateSkip(state) {
              state.isLoading = false;
              state.initialized = true;
            },
            calculateSuccess(state, { payload: { billingTierSelectOptions, financeFields, showResetButton, rateType } }) {
                const mapBillingTiers = ({ code, name }) => ({ label: name, value: code });
                const selectedBillingTier = find(billingTierSelectOptions, 'selected') || { name: '', code: '' };
                const fields = groupBy(financeFields, ({ name }) => camelCase(name));
                const fieldSettings = { ...mapValues(fields, ([field]) => field), billingTier: { name: 'Billing Tier', isEditable: true } };
                const selectValues = {
                    billingTier: mapBillingTiers(selectedBillingTier),
                };

                const scalarValues = mapValues(fields, ([{ value }]) => value);
                const fieldValues = { ...selectValues, ...scalarValues };
                const billingTiers = billingTierSelectOptions.map(mapBillingTiers);

                const fieldVisibilites = chain(FINANCE_FIELDS)
                    .mapValues((_, key) => keys(fieldSettings).includes(key))
                    .value();

                state.showResetButton = showResetButton;
                state.rateType = rateType;
                state.fieldVisibilites = fieldVisibilites;
                state.billingTiers = billingTiers;
                state.entity = fieldValues;
                state.fieldSettings = fieldSettings;
                state.calculateUpdateSuccess = true;
                state.isLoading = false;
                state.initialized = true;
            },
            calculateError(state, { payload }) {
                state.calculateUpdateSuccess = false;
                state.isLoading = false;
                state.initialized = true;
                state.error = payload;
            },
        },
    }),
);

export const financeReducer = financeSlice.reducer;

export const {
    resetVisibilities,
    resetEntity,
    calculate,
    calculateError,
    calculateSuccess,
    calculateSkip,
    override,
    reset,
    resetInitialization,
    resetSuccess,
    billingTierChange,
} = financeSlice.actions;

export default financeSlice;
