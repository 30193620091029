import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-empty-pattern
const Snackbar = forwardRef(({}, ref) => {

  const [animation, setAnimation] = useState('closed');
  const [message, setMessage] = useState('');
  const [className, setClassName] = useState('');

  useImperativeHandle(ref, () => {
    return {
      open: (message, className) => {
        setMessage(message);
        setAnimation('opening');
        setClassName(className);
      },
    };
  }, []);

  const onAnimationEnd = () => {
    if (animation === 'opening') {
      setAnimation('opened');
      setAnimation('closing');
    } else { // 'closing'
      setAnimation('closed');
    }
  };

  return (
    <div
      className={'snackbar ' + animation + ' ' + className}
      onAnimationEnd={onAnimationEnd}
    >
      <span>{message}</span>
    </div>
  );
});

Snackbar.propTypes = {
  open: PropTypes.func,
  message: PropTypes.string,
  className: PropTypes.string,
};

export default Snackbar;
