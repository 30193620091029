import React from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import SubmitButton from '../../../../components/SubmitButton'
import {dispatchFIValidStatus, updateFieldInstance} from '../../../../redux/actions/templateActions'
import convertFieldData from '../../../../utils/fields'

class CreateFieldButton extends React.Component {

    onSave = (e, closeAfterSave) => {
        e.preventDefault();
        const {field, dispatchFIValidStatus, fieldValid, updateFieldInstance} = this.props;

        if (fieldValid) {
            const data = convertFieldData(field);

            dispatchFIValidStatus(true);
            updateFieldInstance(data, closeAfterSave)

        } else dispatchFIValidStatus(false)
    };

    render() {
        return (
            <React.Fragment>
                <SubmitButton color='blue' disabled={!this.props.isFieldChanged} onSubmit={this.onSave}
                              showTooltip={!this.props.fieldValid}/>
                <SubmitButton color='blue' disabled={!this.props.isFieldChanged} onSubmit={(e) => this.onSave(e, true)}
                              title='Save And Close' showTooltip={!this.props.fieldValid}/>
            </React.Fragment>
        )
    }
}

CreateFieldButton.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    field: state.app.templates.editedField,
    isFieldChanged: state.app.templates.isFieldChanged,
    fieldValid: state.app.templates.fieldValid,
});

const mapDispatchToProps = dispatch => ({
    updateFieldInstance: (data, closeAfterSave) => dispatch(updateFieldInstance(data, closeAfterSave)),
    dispatchFIValidStatus: data => dispatch(dispatchFIValidStatus(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFieldButton)
