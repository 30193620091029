import React from 'react';
import PropTypes from 'prop-types';

const FormSectionLabel = ({ label }) => {
  return (
    <div
      className=" col-md-12 col-sm-12 col-xs-12 margin-bottom-15 margin-top-15 fontWeight800"
      style={{ fontSize: '1.1em' }}
    >
      <label>{label}</label>
    </div>
  );
};

FormSectionLabel.propTypes = {
  label: PropTypes.string,
};

FormSectionLabel.defaultProps = {
  label: 'Label',
};

export default FormSectionLabel;
