import api from "../../../../utils/api";
import clientEditMedicalServicesServicesSlice, {
    linkingEntities,
    linkingEntitiesError,
    linkingEntitiesSuccess,
    unlinkingEntities
} from "./clientEditMedicalServicesServicesSlice";

export const linkClientEditServices = (selectedServiceCodes, storedSettings, search, getPageCode, medicalCodingSystemCodes) => (dispatch) => {
    if (selectedServiceCodes && selectedServiceCodes.length === 0) {
        return;
    }
    dispatch(linkingEntities());
    const entity = {
        serviceCodes: selectedServiceCodes,
    };

    return api
        .post('client_medical_service/service/link?code=' + getPageCode, entity)
        .then((res) => {
            // console.log('res', res);
            dispatch(linkingEntitiesSuccess());
            dispatch(clientEditMedicalServicesServicesSlice.actions.loadData(
                { ...storedSettings, globalSearch: search, code: getPageCode, medicalCodingSystemCodes: medicalCodingSystemCodes }
            ));
        })
        .catch((error) => {
            // console.log('catch', error.response);
            dispatch(linkingEntitiesError());
            if (error.response) {
                // dispatch(fetchThreadListError(fieldsErrors[ error.response.status ]));
            } else if (error.request) {
                console.error(error.request);
                // dispatch(fetchThreadListError(error));
            } else {
                console.error('Error ', error.message);
            }
        });
}

export const unlinkClientEditServices = (selectedServiceCodes, storedSettings, search, getPageCode, medicalCodingSystemCodes) => (dispatch) => {
    if (selectedServiceCodes && selectedServiceCodes.length === 0) {
        return;
    }
    dispatch(unlinkingEntities());
    const entity = {
        serviceCodes: selectedServiceCodes,
    };
    return api
        .post('client_medical_service/service/unlink?code=' + getPageCode, entity)
        .then((res) => {
            // console.log('res', res);
            dispatch(linkingEntitiesSuccess());
            dispatch(clientEditMedicalServicesServicesSlice.actions.loadData(
                { ...storedSettings, globalSearch: search, code: getPageCode, medicalCodingSystemCodes: medicalCodingSystemCodes }
            ));
        })
        .catch((error) => {
            // console.log('catch', error.response);
            dispatch(linkingEntitiesError());
            if (error.response) {
                // dispatch(fetchThreadListError(fieldsErrors[ error.response.status ]));
            } else if (error.request) {
                console.error(error.request);
                // dispatch(fetchThreadListError(error));
            } else {
                console.error('Error ', error.message);
            }
        });
}

export const changeMedicalCodingSystemCodes = (selectedMedicalCodingSystemCodes, getPageCode) => (dispatch) => {
    dispatch(linkingEntities());
    dispatch(unlinkingEntities());
    const entity = {
        medicalCodingSystemCodes: selectedMedicalCodingSystemCodes,
    };

    return api
        .post('client_medical_service/change_medical_code?code=' + getPageCode, entity)
        .then((res) => {
            // console.log('res', res);
            dispatch(linkingEntitiesSuccess());
            dispatch(clientEditMedicalServicesServicesSlice.actions.changeSettings(
                { page: 1 }
            ));
        })
        .catch((error) => {
            // console.log('catch', error.response);
            dispatch(linkingEntitiesError());
            if (error.response) {
                // dispatch(fetchThreadListError(fieldsErrors[ error.response.status ]));
            } else if (error.request) {
                console.error(error.request);
                // dispatch(fetchThreadListError(error));
            } else {
                console.error('Error ', error.message);
            }
        });
}
