import React, {Component} from 'react';
import {connect} from 'react-redux';
import SimpleReactValidator from "utils/simple-react-validator";
import {editField, getMyProfile, isAllValid, isPasswordValid} from "../redux/actions/profileActions";
import {getActiveCountries, getActiveStates} from '../redux/actions/countryActions'
import {getAllSpecialties} from '../redux/actions/specialtyActions'
import {getLanguages} from '../redux/actions/languageActions'
import {getAllLocales} from '../redux/actions/localeActions'
import {getAllCompanies} from '../redux/actions/companiesActions'
import {getAll} from "../redux/actions/BasicEntityActions";
import SystemMessage from '../components/SystemMessage';
import ProfileChangePassword from '../pages/Profile/ChangePassword'

// export default function getDefaultValues(initialState, requiredFields) {
const passwordFilter = need => field => {
    return need ? field.match('Password') : !field.match('Password')
}

export default function Wrapper(WrappedComponent) {

    class WrappedForm extends Component {
        constructor(props) {
            super(props)
            this.validator = new SimpleReactValidator({
                password: {
                    message: 'The password must contain at least one uppercase character, at least one digit and at least one special character.',
                    rule: function (val) {
                        return this._testRegex(val,
                            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{8,}$/)
                    }
                },
                equal: {
                    message: 'New Passwords must be equal',
                    rule: () => this.props.profile.newPassword === this.props.profile.repeatNewPassword
                }
            });
            this.validator.showMessages()

            this.onDrop = this.onDrop.bind(this);
            this.onChange = this.onChange.bind(this);
            this.delImage = this.delImage.bind(this);
            this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
            this.onChangeTimeZones = this.onChangeTimeZones.bind(this);
            this.onChangeCountry = this.onChangeCountry.bind(this);
            this.onChangeState = this.onChangeState.bind(this);
            this.onChangeSpecialties = this.onChangeSpecialties.bind(this);
            this.onChangeDefaultLocale = this.onChangeDefaultLocale.bind(this);
            this.onChangeLanguageCodes = this.onChangeLanguageCodes.bind(this);

            this.needPassword = passwordFilter(true)
            this.noNeedPassword = passwordFilter(false)
        }

        componentDidMount() {
            this.props.getActiveCountries();
            this.props.getActiveStates();
            this.props.getAllSpecialties();
            this.props.getLanguages();
            this.props.getAllLocales();
            this.props.getAllCompanies();
            this.props.getAllTimeZones();

            this.props.getProfile().then(() => {
                // this.props.isAllValid(this.validator.allValid())
                this.dispatchValidState()
                this.dispatchPasswordValidState()
            })

            this.validator.showMessages();
        }

        dispatchValidState = () => {
            const {fields} = this.validator
            this.props.isAllValid(Object.keys(fields).filter(this.noNeedPassword).reduce((p, c) => p && fields[c], true))
            this.forceUpdate();
        }

        dispatchPasswordValidState = () => {
            const {fields} = this.validator
            this.props.isPasswordValid(Object.keys(fields).filter(this.needPassword).reduce((p, c) => p && fields[c], true))
            this.validator.showMessages();
        }

        onChange(e) {
            this.props.editField(e.target.name, e.target.value)
        }

        onChangeCheckBox(e) {
            const newValue = e.target.value === 'true'
                ? false
                : true
            this.props.editField([e.target.name], newValue)
        }

        onChangeTimeZones(timeZones) {
            this.props.editField('timeZoneCode', timeZones);
        }

        onChangeDefaultLocale(defaultLocaleCode) {
            this.props.editField('defaultLocaleCode', defaultLocaleCode)
        }

        onChangeSpecialties(specialtyCodes) {
            this.props.editField("specialtyCodes", specialtyCodes)
        }

        onChangeCountry(countryCode) {
            this.props.editField("countryCode", countryCode)
            if (countryCode && countryCode.value !== 'US') {
                this.props.editField("stateCode", null);
                this.props.editField("routingNum", '')
                this.props.editField("accountNum", '')
            } else {
                this.props.editField("stateCode", null)
                this.props.editField("routingNum", '')
                this.props.editField("accountNum", '')
            }
        }

        onChangeState(stateCode) {
            this.props.editField("stateCode", stateCode)
        }

        onChangeLanguageCodes(languageCodes) {
            this.props.editField("languageCodes", languageCodes)
        }


        onDrop(savedSignature) {
            this.props.editField('savedSignature', savedSignature)
        }

        delImage() {
            this.props.editField('signature', null);
            this.props.editField('savedSignature', [])
        }

        render() {
            const {showSuccess, showValidMessages, showPasswordValidMessages, isProfileChanged, passwordStatus, error} = this.props;
            const errorMessages = Object.keys(this.validator.errorMessages).map((field) => ({
                id: field,
                field,
                message: this.validator.errorMessages[field],
            }))
            const passwordErrorMessages = errorMessages.filter(({field}) => this.needPassword(field))

            return (
                <React.Fragment>
                    <SystemMessage
                        shown={showSuccess && !isProfileChanged}
                        type='success'
                        message='Profile successfully updated'
                    />
                    <SystemMessage
                        shown={passwordStatus}
                        type='success'
                        message='Password successfully updated'
                    />

                    <SystemMessage
                        shown={showValidMessages}
                        type='error' message={error}
                        validationMessages={errorMessages.filter(({field}) => this.noNeedPassword(field))}
                    />
                    <SystemMessage
                        shown={showPasswordValidMessages}
                        type='error'
                        validationMessages={passwordErrorMessages}
                    />

                    <div className="row">
                        <div className="col-md-7">
                            <WrappedComponent
                                {...this.props}
                                validator={this.validator}
                                onChange={this.onChange}
                                onChangeCheckBox={this.onChangeCheckBox}
                                onChangeTimeZones={this.onChangeTimeZones}
                                onChangeDefaultLocale={this.onChangeDefaultLocale}
                                onChangeCountry={this.onChangeCountry}
                                onChangeState={this.onChangeState}
                                onChangeSpecialties={this.onChangeSpecialties}
                                onChangeLanguageCodes={this.onChangeLanguageCodes}
                                onDrop={this.onDrop}
                                delImage={this.delImage}
                                isAllValid={this.props.isAllValid}
                                dispatchValidState={this.dispatchValidState}
                            />
                        </div>
                        <div className="col-md-5">
                            <ProfileChangePassword
                                onChange={this.onChange}
                                validator={this.validator}
                                profile={this.props.profile}
                                dispatchValidState={this.dispatchPasswordValidState}/>
                        </div>
                    </div>
                </React.Fragment>
            );

        }
    }

    const mapState = (state) => {
        return {
            user: state.app.auth.user,
            profile: state.app.profile.data,
            error: state.app.profile.error,
            showSuccess: state.app.profile.showSuccess,
            isProfileChanged: state.app.profile.isProfileChanged,
            showValidMessages: state.app.profile.showValidMessages,
            showPasswordValidMessages: state.app.profile.showPasswordValidMessages,
            passwordStatus: state.app.profile.passwordStatus,
            companies: state.app.companies.data,
            timezones: state.app.timezone.data.filter((item) => item.active).map(tz => ({value: tz.code, label: tz.name})),
            countries: state.app.countries.data.filter((item) => item.active).map(country => ({value: country.code, label: country.name})),
            states: state.app.countries.states.filter((item) => item.active).map(state => ({value: state.code, label: state.name})),
            specialties: state.app.specialties.data.filter((item) => item.active).map(spec => ({value: spec.code, label: spec.name})),
            languages: state.app.languages.data.filter((item) => item.active).map(lang => ({value: lang.code, label: lang.name})),
            locales: state.app.locales.data.filter((item) => item.active).map(locale => ({value: locale.code, label: locale.displayName})),

        }
    }

    const mapDispatchToProps = dispatch => {
        return {
            getActiveCountries: () => dispatch(getActiveCountries()),
            getActiveStates: () => dispatch(getActiveStates()),
            getAllSpecialties: () => dispatch(getAllSpecialties()),
            getProfile: () => dispatch(getMyProfile()),
            editField: (field, value) => dispatch(editField(field, value)),
            isAllValid: (valid) => dispatch(isAllValid(valid)),
            getLanguages: () => dispatch(getLanguages()),
            getAllLocales: () => dispatch(getAllLocales()),
            getAllCompanies: () => dispatch(getAllCompanies()),
            getAllTimeZones: () => dispatch(getAll('time_zone')),
            isPasswordValid: (valid) => dispatch(isPasswordValid(valid)),
        }
    }

    return connect(mapState, mapDispatchToProps)(WrappedForm)
}

// }
