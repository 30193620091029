import React from 'react';
import { draft } from 'redux/pages/patient-case/patientCaseColumns';
import draftSlice from 'redux/pages/patient-case/slices/draftSlice';
import PatientCaseGrid from '../Components/PatientCaseGrid';

const columns = draft;

const Draft = (props) => (
  <PatientCaseGrid columns={columns} slice={draftSlice} {...props} />
);

export default Draft;
