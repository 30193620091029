import api from '../../utils/api';
import { Statuses } from "./statuses";

export const validationChange = (data) => (dispatch) => {
    dispatch({
        type: 'FINANCIAL_REPORT_VALIDATION_CHANGE',
        payload: data
    });
}

export const getAllReviewersMinimal = () => (dispatch) => {
    dispatch({
        type: 'FETCH_REVIEWERS_MINIMAL_LOADING',
    });

    api.get('reviewer/reviewer-minimal-views')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_REVIEWERS_MINIMAL_SUCCESS',
                payload: data
            });
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_REVIEWERS_MINIMAL_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.error(error.request);
            } else {
                console.error('Error ', error.message);
                dispatch({
                    type: 'FETCH_REVIEWERS_MINIMAL_ERROR',
                    payload: error.message
                });
            }
        })
}

export const getAllCompaniesMinimal = () => (dispatch) => {
    dispatch({
        type: 'FETCH_COMPANIES_MINIMAL_LOADING',
    });

    api.get('company/simple-company-views')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_COMPANIES_MINIMAL_SUCCESS',
                payload: data
            });
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_COMPANIES_MINIMAL_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.error(error.request);
            } else {
                console.error('Error ', error.message);
                dispatch({
                    type: 'FETCH_COMPANIES_MINIMAL_ERROR',
                    payload: error.message
                });
            }
        })
}

export const saveFilter = (data) => (dispatch) => {
    // console.log('1SAVEFILTER', data, dispatch);
    dispatch({
        type: 'SAVE_FILTER_FINANCIAL_REPORT',
        payload: data
    });
}

export const exportExcel = (data, state, apiRequestPath) => (dispatch) => {
    dispatch({
        type: 'EXPORT_FINANCIAL_REPORT',
    });
    console.warn('exportExcel', data);
    return api.request({
        responseType: 'arraybuffer',
        url: apiRequestPath,
        method: 'post',
        data: data,
        timeout: 1200000,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.ms-excel'
        }
    }).then((res) => {
        if (res.status === 200) {
            const name = res.headers['content-disposition'];
            let fileName = 'file.xlsx';
            if (name) {
                fileName = name.substr(name.indexOf('filename=') + 9);
            }
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            dispatch({
                type: 'REPORT_FINANCIAL_REPORT_SUCCESS',
            });
        } else {
            console.warn('EMPTY FILE');
            dispatch({
                type: 'REPORT_FINANCIAL_REPORT_FAILED',
            });
        }
    }).catch((res) => {
        const decoder = new TextDecoder("utf-8");
        let text = '';
        let json = {};
        if (res && res.response && res.response.data instanceof ArrayBuffer) {
            text = decoder.decode(res.response.data);
            json = JSON.parse(text);
        }
        dispatch({
            type: 'REPORT_FINANCIAL_REPORT_FAILED',
            payload: json ? json.error : null,
        });
        console.error(res);
    });
};
