import React from 'react';
import {connect} from 'react-redux';
import Table from 'rc-table'
import {editRole, getAllPermissions, getAllRoles} from '../../redux/action-creators/users'
import {isStaff} from '../../utils/helpers'
import LoadingIndicator from '../../components/LoadingIndicator';
import 'rc-table/assets/index.css';
import PropTypes from "prop-types";

class PermissionManageTable extends React.Component {
    constructor(props) {
        super(props);
        this.showPermission = this.showPermission.bind(this);
        this.changeRolePermission = this.changeRolePermission.bind(this);
        this.getTablesCols = this.getTablesCols.bind(this);
        this.getTablesData = this.getTablesData.bind(this);
    }

    componentDidMount() {
        this.props.getAllRoles();
        this.props.getAllPermissions();
    }

    showPermission = (role, perm) => {
        return role.permissionCodes.indexOf(perm.code) === -1 ? null : <i className="fa fa-check"/>
    };

    changeRolePermission(role, permCode) {
        let newPermCodes;

        if (role.permissionCodes.indexOf(permCode) === -1) {
            newPermCodes = [...role.permissionCodes, permCode]
        } else newPermCodes = role.permissionCodes.filter((code) => code !== permCode);

        this.props.submitRoleUpdate({
            code: role.code,
            name: role.name,
            active: true,
            permissionCodes: newPermCodes
        })
    }

    makePrettyRole = (role) => role.replace(/^ROLE/, '').replace(/_/g, ' ');
    makePrettyPerm = (perm) => perm.replace(/^ROLE/, '').replace(/_/g, ' ');

    getTablesCols = () => ([
        {
            title: 'PERMISSION/ROLE',
            dataIndex: 'PermName',
            key: 'perm',
            width: 170,
            fixed: 'left'
        },
        ...this.props.roles
            .filter((item) => isStaff(item.code))
            .map((role) => ({
                title: this.makePrettyRole(role.name),
                dataIndex: role.code,
                key: role.code,
                width: 170,
                align: 'center',
                colSpan: 1
            }))
    ]);

    getTablesData = () => (
        this.props.permissions.map((perm) => ({
            PermName: this.makePrettyPerm(perm.code),
            ...this.props.roles.reduce((acc, role) => {
                if (isStaff(role.code)) {
                    acc[role.code] = (
                        <div className='cursor-pointer' style={{'width': '60%', 'height': '18px', 'margin': 'auto'}}
                             onClick={() => this.changeRolePermission(role, perm.code)}>
                            {this.showPermission(role, perm)}
                        </div>)
                }
                return acc
            }, {})
        }))
    );

    render() {
        const {isLoading} = this.props;
        return (
            <div>
                {isLoading && <LoadingIndicator type="form"/>}
                <Table
                    scroll={{y: 670, x: 1000}}
                    data={this.getTablesData()}
                    columns={this.getTablesCols()}
                    useFixedHeader={true}
                    emptyText={isLoading ? 'Loading... Please wait' : 'No Data'}
                />
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
    }
    static defaultProps = {
        isLoading: false
    };
}

const mapStateToProps = (state) => ({
    roles: state.app.users.roles,
    permissions: state.app.users.permissions,
    isLoading: state.app.users.loadingRoles || state.app.users.loadingPermissions,
    error: state.app.users.loading
});

const mapDispatchToProps = dispatch => ({
    submitRoleUpdate: (data) => {
        dispatch(editRole(data))
    },
    getAllRoles: () => {
        dispatch(getAllRoles())
    },
    getAllPermissions: () => {
        dispatch(getAllPermissions())
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionManageTable);
