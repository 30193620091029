import React from 'react'
import FormField from '../../../components/FormField'
import Box from '../../../components/Box'
import BoxHeader from '../../../components/BoxHeader'
import BoxBody from '../../../components/BoxBody'
import LoadingIndicator from '../../../components/LoadingIndicator';
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import SystemMessage from '../../../components/SystemMessage';
import FormValidateWrapper from '../../../containers/FormValidateWrapper';
import RadioAddon from '../../../components/FormField/RadioAddon'
import { rateTypes } from '../../../utils/Constants'
import { getMultiSelectValue, getSelectFieldValue, makeSelectOptions } from '../../../utils/helpers'
import PropTypes from "prop-types";
import { connect } from "react-redux";

const rateOptions = makeSelectOptions(rateTypes);

class TierManagePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isRateEditing: false,
            isMinChargeEditing: false,
            isAdjustEditing: false
        };
        this.props.dispatchValidState();
    }

    componentDidMount() {
        this.props.getInitData();
        setTimeout(() => {
            if (this.props.needToLoadBG) {
                this.props.getFullBillingGroup(this.props.neededBGCode);
            }
        }, 1000);
    }

    onChange = (e) => {
        this.props.editTier(e.target.name, e.target.value);
        setTimeout(() => {
            this.props.dispatchValidState();
        }, 600);
    };

    onChangeCheckBox = (e) => this.props.editTier(e.target.name, e.target.checked);

    onChangeCaseType = (caseTypes) => this.props.editTier('caseTypes', caseTypes);

    onChangeCaseLevel = (caseLevel) => this.props.editTier('caseLevel', caseLevel);

    onChangeReviewerType = (reviewerTypes) => this.props.editTier('reviewerTypes', reviewerTypes);

    onChangeRateType = (rateType) => this.props.editTier('rateType', rateType);

    onChangeRefTier = (refTier) => this.props.editTier('refTier', refTier);

    onSave = () => {
        let { tier, tierNumber, title } = this.props;
        tierNumber = tier.tierNumber || tierNumber;

        return this.props.onSave({
            ...tier,
            tierNumber: tierNumber,
            name: title,
            caseTypes: getMultiSelectValue(tier.caseTypes),
            caseLevel: getSelectFieldValue(tier.caseLevel),
            reviewerTypes: getMultiSelectValue(tier.reviewerTypes),
            rateType: getSelectFieldValue(tier.rateType),
            refTier: getSelectFieldValue(tier.refTier),
        })
    };

    toCurrency = (number, isEmpty) => {
        if (typeof isEmpty !== 'undefined' && isEmpty === true && !number) return '';
        const formatter = new Intl.NumberFormat("en-US", {
            style: "decimal",
            currency: "USD"
        });

        return formatter.format(number);
    };

    toggleRateEditing = () => {
        this.props.dispatchValidState();
        this.setState({ isRateEditing: !this.state.isRateEditing });
    };

    toggleMinChargeEditing = () => {
        this.props.dispatchValidState();
        this.setState({ isMinChargeEditing: !this.state.isMinChargeEditing });
    };

    toggleAdjustEditing = () => {
        this.props.dispatchValidState();
        this.setState({ isAdjustEditing: !this.state.isAdjustEditing });
    };

    naturalSorter = (a, b) => {
        let a_lab = parseInt(a.label.replace('Tier ', ''), 10);
        let b_lab = parseInt(b.label.replace('Tier ', ''), 10);
        if (a_lab < b_lab) return -1;
        if (a_lab > b_lab) return 1;
        return 0;
    };

    render() {
        const { isValid, isProfileChanged, editSuccess, title, tier, validator, dispatchValidState, isEditable } = this.props;

        if (tier[ 'callsMin' ] && tier[ 'callsMax' ]) {
            this.props.validator.message('Calls From', tier[ 'callsMin' ], 'gte:0|integer', 'text-danger');
            this.props.validator.message('Calls To', tier[ 'callsMax' ], 'gte:' + tier[ 'callsMin' ] + '|integer', 'text-danger');
        } else {
            if (!tier[ 'callsMin' ]) this.props.validator.message('Calls From', tier[ 'callsMin' ], 'none', '');
            else this.props.validator.message('Calls From', tier[ 'callsMin' ], 'gte:0|integer', 'text-danger');
            if (!tier[ 'callsMax' ]) this.props.validator.message('Calls To', tier[ 'callsMax' ], 'none', '');
            else this.props.validator.message('Calls To', tier[ 'callsMax' ], 'gte:0|integer', 'text-danger');
        }

        if (tier[ 'tatMin' ] && tier[ 'tatMax' ]) {
            this.props.validator.message('TAT From', tier[ 'tatMin' ], 'gte:0|integer', 'text-danger');
            this.props.validator.message('TAT To', tier[ 'tatMax' ], 'gte:' + tier[ 'tatMin' ] + '|integer', 'text-danger');
        } else {
            if (!tier[ 'tatMin' ]) this.props.validator.message('TAT From', tier[ 'tatMin' ], 'none', '');
            else this.props.validator.message('TAT From', tier[ 'tatMin' ], 'gte:0|integer', 'text-danger');
            if (!tier[ 'tatMax' ]) this.props.validator.message('TAT To', tier[ 'tatMax' ], 'none', '');
            else this.props.validator.message('TAT To', tier[ 'tatMax' ], 'gte:0|integer', 'text-danger');
        }

        if (tier[ 'pagesMin' ] && tier[ 'pagesMax' ]) {
            this.props.validator.message('Pages From', tier[ 'pagesMin' ], 'gte:0|integer', 'text-danger');
            this.props.validator.message('Pages To', tier[ 'pagesMax' ], 'gte:' + tier[ 'pagesMin' ] + '|integer', 'text-danger');
        } else {
            if (!tier[ 'pagesMin' ]) this.props.validator.message('Pages From', tier[ 'pagesMin' ], 'none', '');
            else this.props.validator.message('Pages From', tier[ 'pagesMin' ], 'gte:0|integer', 'text-danger');
            if (!tier[ 'pagesMax' ]) this.props.validator.message('Pages To', tier[ 'pagesMax' ], 'none', '');
            else this.props.validator.message('Pages To', tier[ 'pagesMax' ], 'gte:0|integer', 'text-danger');
        }

        if (tier[ 'questionsMin' ] && tier[ 'questionsMax' ]) {
            this.props.validator.message('Questions From', tier[ 'questionsMin' ], 'gte:0|integer', 'text-danger');
            this.props.validator.message('Questions To', tier[ 'questionsMax' ], 'gte:' + tier[ 'questionsMin' ] + '|integer', 'text-danger');
        } else {
            if (!tier[ 'questionsMin' ]) this.props.validator.message('Questions From', tier[ 'questionsMin' ], 'none', '');
            else this.props.validator.message('Questions From', tier[ 'questionsMin' ], 'gte:0|integer', 'text-danger');
            if (!tier[ 'questionsMax' ]) this.props.validator.message('Questions To', tier[ 'questionsMax' ], 'none', '');
            else this.props.validator.message('Questions To', tier[ 'questionsMax' ], 'gte:0|integer', 'text-danger');
        }

        const rateType = getSelectFieldValue(tier.rateType);
        //const rate = rateType ? rateType.toLowerCase() + 'Rate' : null
        const tierOptions = this.props.tiers
            .filter((item) => item.rateType === 'FLAT' && tier.code !== item.code && item.active)
            .map((item) => ({ label: `Tier ${item.tierNumber}`, value: item.code }));
        tierOptions.sort(this.naturalSorter);

        const caseTypesAddon = <RadioAddon
            data={[{ label: 'Contains', radio: 'CONTAINS' }, { label: 'Equals To', radio: 'EQUALS' }]}
            name='caseTypeEquality'
            onChange={this.onChange}
            value={tier.caseTypeEquality}
            disabled={!isEditable}
        />;

        const reviewerTypesAddon = <RadioAddon
            data={[{ label: 'Contains', radio: 'CONTAINS' }, { label: 'Equals To', radio: 'EQUALS' }]}
            name='reviewerTypeEquality'
            onChange={this.onChange}
            value={tier.reviewerTypeEquality}
            disabled={!isEditable}
        />;

        return (
            <div className="content" style={{ marginTop: '10px' }}>
                <PreventTransitionPrompt
                    when={isProfileChanged}
                    isFormValid={isValid}
                    entity='billing_tier'
                    saveChanges={this.onSave}
                    cancelAndDiscard={this.props.cancelAndDiscard}
                />

                {this.props.loading ? <LoadingIndicator height={50}/> : null}

                <SystemMessage shown={!isProfileChanged && editSuccess} type='success'
                               message='Tier successfully updated'/>

                <Box color="blue" classes="box-profile-first-row-height-show">
                    <BoxHeader title={title} color="blue" classes="box-profile-first-row-height-show"/>
                    <BoxBody>
                        <form style={{ overflow: 'visible' }}>
                            <div className='form-group'>
                                <div className='row'>
                                    <FormField
                                        type="REF_CASE_LEVEL"
                                        md="4"
                                        label="Case Level"
                                        value={tier.caseLevel}
                                        onChange={this.onChangeCaseLevel}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className='form-group bordered'>
                                <div className='row'>
                                    <FormField
                                        type="REF_CASE_TYPE"
                                        md="4"
                                        multi
                                        label="Case Types"
                                        value={tier.caseTypes}
                                        onChange={this.onChangeCaseType}
                                        labelAddon={caseTypesAddon}
                                        disabled={!isEditable}
                                    />
                                    <FormField
                                        type="select"
                                        md="4"
                                        multi
                                        label="Reviewer Type"
                                        value={tier.reviewerTypes}
                                        options={this.props.reviewerTypes}
                                        onChange={this.onChangeReviewerType}
                                        labelAddon={reviewerTypesAddon}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className='form-group border-bold'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className='row margin-bottom-15'>
                                            <div className='col-md-2' style={{ marginTop: '25px' }}><label>Calls</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>From</label>
                                                <input type="number" className='form-control' name='callsMin' id='Calls From'
                                                       value={tier.callsMin} onChange={this.onChange} disabled={!isEditable}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <label>To</label>
                                                <input type="number" className='form-control' name='callsMax' id='Calls To'
                                                       value={tier.callsMax} onChange={this.onChange} disabled={!isEditable}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-2'><label>TAT</label></div>
                                            <div className='col-md-4'>
                                                <input type="number" className='form-control' name='tatMin' id='TAT From'
                                                       value={tier.tatMin} onChange={this.onChange} disabled={!isEditable}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <input type="number" className='form-control' name='tatMax' id='TAT To'
                                                       value={tier.tatMax} onChange={this.onChange} disabled={!isEditable}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='row margin-bottom-15'>
                                            <div className='col-md-2 col-md-offset-2' style={{ marginTop: '25px' }}>
                                                <label>Pages</label></div>
                                            <div className='col-md-4'>
                                                <label>From</label>
                                                <input type="number" className='form-control' name='pagesMin' id='Pages From'
                                                       value={tier.pagesMin} onChange={this.onChange} disabled={!isEditable}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <label>To</label>
                                                <input type="number" className='form-control' name='pagesMax' id='Pages To'
                                                       value={tier.pagesMax} onChange={this.onChange} disabled={!isEditable}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-2 col-md-offset-2'><label>Questions</label></div>
                                            <div className='col-md-4'>
                                                <input type="number" className='form-control' name='questionsMin' id='Questions From'
                                                       value={tier.questionsMin} onChange={this.onChange} disabled={!isEditable}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <input type="number" className='form-control' name='questionsMax' id='Questions To'
                                                       value={tier.questionsMax} onChange={this.onChange} disabled={!isEditable}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`form-group ${rateType + '' !== 'HOURLY' && 'bordered'}`}>
                                <div className='row'>
                                    <FormField
                                        type="select"
                                        md="4"
                                        mulit
                                        label="F/H/A"
                                        id="F/H/A"
                                        value={tier.rateType}
                                        options={rateOptions}
                                        onChange={this.onChangeRateType}
                                        validator={validator}
                                        valids='required'
                                        onBlur={dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                    {this.state.isRateEditing ? (
                                        <FormField
                                            style={{ display: rateType + '' === 'FLAT' ? 'block' : 'none' }}
                                            type="number"
                                            md="2"
                                            label='Flat Rate'
                                            id='Flat Rate'
                                            name='flatRate'
                                            value={tier.flatRate}
                                            onChange={this.onChange}
                                            required
                                            validator={validator}
                                            valids={rateType + '' === 'FLAT' ? 'required|gte:0' : 'none'}
                                            onBlur={this.toggleRateEditing}
                                            disabled={!isEditable}
                                        />
                                    ) : (
                                        <FormField
                                            style={{ display: rateType + '' === 'FLAT' ? 'block' : 'none' }}
                                            type="text"
                                            md="2"
                                            label='Flat Rate'
                                            id='Flat Rate'
                                            name='flatRate'
                                            value={this.toCurrency(tier.flatRate)}
                                            onFocus={this.toggleRateEditing}
                                            onChange={this.onChange}
                                            readOnly
                                            required
                                            validator={validator}
                                            valids={rateType + '' === 'FLAT' ? 'required|gte:0' : 'none'}
                                            disabled={!isEditable}
                                        />
                                    )}

                                    {this.state.isRateEditing ? (
                                        <FormField
                                            style={{ display: rateType + '' === 'HOURLY' ? 'block' : 'none' }}
                                            type="number"
                                            md="2"
                                            label='Hourly Rate'
                                            id='Hourly Rate'
                                            name='hourlyRate'
                                            value={tier.hourlyRate}
                                            onChange={this.onChange}
                                            required
                                            validator={validator}
                                            valids={rateType + '' === 'HOURLY' ? 'required|gte:0' : 'none'}
                                            onBlur={this.toggleRateEditing}
                                            disabled={!isEditable}
                                        />
                                    ) : (
                                        <FormField
                                            style={{ display: rateType + '' === 'HOURLY' ? 'block' : 'none' }}
                                            type="text"
                                            md="2"
                                            label='Hourly Rate'
                                            id='Hourly Rate'
                                            name='hourlyRate'
                                            value={this.toCurrency(tier.hourlyRate)}
                                            onFocus={this.toggleRateEditing}
                                            onChange={this.onChange}
                                            readOnly
                                            required
                                            validator={validator}
                                            valids={rateType + '' === 'HOURLY' ? 'required|gte:0' : 'none'}
                                            disabled={!isEditable}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className='form-group bordered'
                                 style={{ display: rateType + '' === 'HOURLY' ? 'block' : 'none' }}>
                                <div className='row'>
                                    <FormField
                                        type="select"
                                        md="2"
                                        label="Ref Tier"
                                        value={tier.refTier}
                                        valuesSorted
                                        sorted
                                        options={tierOptions}
                                        onChange={this.onChangeRefTier}
                                        disabled={!isEditable}
                                    />
                                    {this.state.isAdjustEditing ? (
                                        <FormField
                                            type="number"
                                            md="2"
                                            label='Adjust %'
                                            id='Adjust %'
                                            name='adjust'
                                            value={tier.adjust}
                                            onChange={this.onChange}
                                            validator={validator}
                                            valids={rateType + '' === 'HOURLY'
                                            && tier.adjust !== '' && tier.adjust !== null
                                                ? 'gte:0' : 'none'}
                                            onBlur={this.toggleAdjustEditing}
                                            disabled={!isEditable}
                                        />
                                    ) : (
                                        <FormField
                                            type="text"
                                            md="2"
                                            label='Adjust %'
                                            id='Adjust %'
                                            name='adjust'
                                            value={this.toCurrency(tier.adjust, true)}
                                            onChange={this.onChange}
                                            validator={validator}
                                            valids={rateType + '' === 'HOURLY'
                                            && tier.adjust !== '' && tier.adjust !== null
                                                ? 'gte:0' : 'none'}
                                            onFocus={this.toggleAdjustEditing}
                                            disabled={!isEditable}
                                        />
                                    )}
                                    <FormField
                                        type="number"
                                        md="2"
                                        label='Min Time'
                                        id='Min Time'
                                        name='timeMin'
                                        value={tier.timeMin}
                                        onChange={this.onChange}
                                        validator={validator}
                                        valids={rateType + '' === 'HOURLY'
                                        && tier.timeMin !== '' && tier.timeMin !== null
                                            ? 'gte:0|integer' : 'none'}
                                        onBlur={dispatchValidState}
                                        disabled={!isEditable}
                                    />
                                    {this.state.isMinChargeEditing ? (
                                        <FormField
                                            type="number"
                                            md="2"
                                            label='Min Charge'
                                            id='Min Charge'
                                            name='chargeMin'
                                            value={tier.chargeMin}
                                            onChange={this.onChange}
                                            validator={validator}
                                            valids={rateType + '' === 'HOURLY'
                                            && tier.chargeMin !== '' && tier.chargeMin !== null
                                                ? 'gte:0' : 'none'}
                                            onBlur={this.toggleMinChargeEditing}
                                            disabled={!isEditable}
                                        />
                                    ) : (
                                        <FormField
                                            type="text"
                                            md="2"
                                            label='Min Charge'
                                            id='Min Charge'
                                            name='chargeMin'
                                            value={this.toCurrency(tier.chargeMin, true)}
                                            onFocus={this.toggleMinChargeEditing}
                                            onChange={this.onChange}
                                            readOnly
                                            validator={validator}
                                            valids={rateType + '' === 'HOURLY'
                                            && tier.chargeMin !== '' && tier.chargeMin !== null
                                                ? 'gte:0' : 'none'}
                                            disabled={!isEditable}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='row'>
                                    <FormField
                                        type="checkbox"
                                        md="4"
                                        label="Active"
                                        name="active"
                                        value={tier.active}
                                        checked={tier.active}
                                        onChange={this.onChangeCheckBox}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </form>
                    </BoxBody>
                </Box>
            </div>
        )
    }

    static propTypes = {
        isValid: PropTypes.bool,
        isProfileChanged: PropTypes.bool,
        isEditable: PropTypes.bool,
        editSuccess: PropTypes.bool,
        dispatchValidState: PropTypes.func,
        onSave: PropTypes.func,
        getInitData: PropTypes.func,
        needToLoadBG: PropTypes.bool,
        getFullBillingGroup: PropTypes.func,
        neededBGCode: PropTypes.string,
        editTier: PropTypes.func,
        tier: PropTypes.object,
        tierNumber: PropTypes.number,
        title: PropTypes.string,
        validator: PropTypes.object,
        tiers: PropTypes.array,
        cancelAndDiscard: PropTypes.func,
        loading: PropTypes.bool,
        reviewerTypes: PropTypes.array
    };
}

const mapStateToProps = (state) => ({
    isEditable: state.app.users.my_permissions.includes('MODIFY_BILLING_TIERS'),
});

export default connect(mapStateToProps)(FormValidateWrapper(TierManagePage));
