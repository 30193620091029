import { RESPONSE_ERROR } from "../actions/types";
import { Statuses } from "../actions/statuses";

const defaultProfileState = {
    loading: false,
    data: {
      signature: null,
      savedSignature: [],
      oldPassword:'',
      newPassword:'',
      repeatNewPassword:'',
    },
    originalUser: {
      signature: null,
      savedSignature: [],
      oldPassword:'',
      newPassword:'',
      repeatNewPassword:'',
    },
    signature: '',
    error: '',
    passwordStatus: '',
    profileValid: true,
    isPasswordValid: true,
    isProfileChanged: false,
    showSuccess: false,
    showValidMessages: false,
    showPasswordValidMessages: false,


}
let isProfileObjectsEqual = (obj1, obj2) => {
    let equal = true;
    for (let key in obj1) {
        if (!obj1.hasOwnProperty(key)) continue;
        if (key === 'oldPassword' || key === 'newPassword' || key === 'repeatNewPassword' || key === 'savedSignature') continue;
        if (obj1[key] === obj2[key]) continue;
        if (typeof obj2[key] === "undefined") equal = false;
        else if (obj1[key] !== obj2[key]) {
            equal = false;
        }
    }
    return equal;
}

const profile = (state = defaultProfileState, action) => {
    switch (action.type) {
        case RESPONSE_ERROR:
            return Object.assign(defaultProfileState, {...state, error: Statuses[action.status], loading: false});
        case 'FETCH_PROFILE_SIGNATURE_LOADING':
        case 'FETCH_PASSWORD_CHANGE_LOADING':
            return { ...state, loading: true, passwordStatus: '' }
        case 'FETCH_PROFILE_LOADING':
            return { ...state, loading: true, passwordStatus: '', editedUser: defaultProfileState.data, originalUser: defaultProfileState.originalUser, showValidMessages: false, showPasswordValidMessages:false }
        case 'FETCH_PASSWORD_CHANGE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                passwordStatus: action.payload,
                error: ''
            })
        case 'FETCH_PROFILE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: {
                  ...action.payload,
                  savedSignature: [],
                  oldPassword:'',
                  newPassword:'',
                  repeatNewPassword:'',
                },
                originalUser: {
                  ...action.payload,
                  savedSignature: [],
                  oldPassword:'',
                  newPassword:'',
                  repeatNewPassword:'',
                },
                error: null,
                isProfileChanged: false
            })
        case 'PROFILE_SUCCESS_MESSAGE':
            return Object.assign({}, state, {
                showSuccess: action.payload
            })
        case 'FETCH_EDIT_PROFILE_SUCCESS':
            return Object.assign({}, state, {
                loading:false,
                showSuccess:true,
                error: null,
                data: {
                  ...action.payload,
                  savedSignature: [],
                  oldPassword:'',
                  newPassword:'',
                  repeatNewPassword:'',
                },
                originalUser: {
                  ...action.payload,
                  savedSignature: [],
                  oldPassword:'',
                  newPassword:'',
                  repeatNewPassword:'',
                },
                isProfileChanged:false,
            })
        case 'FETCH_PROFILE_SIGNATURE_SUCCESS':
            return Object.assign({}, state, {
                loading:false,
                error: null,
                signature: action.payload
            })
        case 'FETCH_PROFILE_SIGNATURE_ERROR':
        case 'FETCH_PASSWORD_CHANGE_ERROR':
        case 'FETCH_PROFILE_ERROR':
            return {...state, error: action.payload, loading: false}
        case 'PROFILE_EDIT_FIELD':
          return Object.assign({}, state, {
            data: Object.assign({}, state.data, action.payload),
            isProfileChanged: !isProfileObjectsEqual(Object.assign({}, state.data, action.payload), state.originalUser)
          })
        case 'SHOW_PROFILE_VALIDATION':
            return Object.assign({}, state, {
                showValidMessages: true
            })
        case 'SHOW_PASSWORD_VALIDATION':
            return Object.assign({}, state, {
                showPasswordValidMessages: true
            })
        case 'VALIDATE_PROFILE_FIELD':
          return Object.assign({}, state, {
            profileValid: action.payload
          })
        case 'VALIDATE_PASSWORD_FIELD':
          return Object.assign({}, state, {
            isPasswordValid: action.payload
          })
        default: return state
    }
}

export default profile
