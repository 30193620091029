import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

const GoBackButton = ({ history, location }) => {
  const { disabledReviewerButtons } = useSelector((state) => {
    return {
      disabledReviewerButtons: state.app.cases.disabledReviewerButtons,
    };
  });

  const canGoBack = location.pathname !== '/';

  const goBack = () => {
    if (canGoBack) {
      history.goBack();
    }
  };

  return (
    <span title={'Back'}>
      <button
        disabled={disabledReviewerButtons || !canGoBack}
        className={'btn btn-default margin-left-5'}
        onClick={goBack}
        title={'Back'}
      >
        <i className={'fa fa-arrow-left'} />
      </button>
    </span>
  );
};

GoBackButton.propTypes = {
  disabledReviewerButtons: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(GoBackButton);
