import React from 'react'
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import {isArrayConverge} from 'utils/helpers'

const isDescendant = (el, target) => {
    if (target !== null) {
        return el === target || isDescendant(el, target.parentNode);
    }
    return false;
};

// https://github.com/mui-org/material-ui/blob/386edcf7c0437b120d259794df29b40ac5e14996/src/utils/ClickAwayListener.js
const preventedClasses = ['rw-list', 'rw-list-option', 'btn']

class ClickOutsideListener extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        onClickOutside: PropTypes.any,
    };

    constructor(props) {
        super(props)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
        document.addEventListener('touchstart', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.removeEventListener('touchstart', this.handleClickOutside)
    }

    isPrevented = (classNames) => {
      if (!classNames || typeof classnames !== 'string') return

      return isArrayConverge(preventedClasses, classNames.split(" "))
    }

    handleClickOutside(event) {
        // Ignore events that have been `event.preventDefault()` marked. And event which targets class contains one of preventedClasses
        if (event.defaultPrevented || this.isPrevented(event.target.className)) {
          return;
        }

        const el = findDOMNode(this);

        if (
            event.target instanceof HTMLElement &&
            document.documentElement &&
            document.documentElement.contains(event.target) &&
            !isDescendant(el, event.target)
        ) {
            this.props.onClickOutside(event);
        }
    }
    render() {
        return this.props.children
    }
}

export default ClickOutsideListener
