import React from 'react';
import { capitalizeAllFirstLetters } from '../../utils/helpers';

class GroupCheckBox extends React.Component {
    constructor(props) {
        super(props);
        this.selectedCheckboxes = new Set(props[ 'initValue' ]);
    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps[ 'initValue' ]) !== JSON.stringify(this.props[ 'initValue' ])) {
            this.selectedCheckboxes = new Set(nextProps[ 'initValue' ]);
        }
    }

    toggleCheckbox = ({ target }) => {
        const { value } = target;
        if (this.selectedCheckboxes.has(value)) {
            this.selectedCheckboxes.delete(value);
        } else {
            this.selectedCheckboxes.add(value);
        }

        this.props.onChange([...this.selectedCheckboxes])
    };

    createCheckbox = (label, value) => (
        <div className="checkbox" style={{ margin: '0' }} key={value}>
            <label style={{ maxWidth: '100%' }}>
                <input
                    type='checkbox'
                    label={label}
                    value={value}
                    checked={this.selectedCheckboxes.has(value)}
                    className='form-control'
                    onChange={this.toggleCheckbox}
                />
                {'  ' + capitalizeAllFirstLetters(label)}
            </label>
        </div>
    );

    createCheckboxes = () => (
        this.props.items.map(item => {
            return this.createCheckbox(item.label, item.value)
        })
    );

    render() {
        return (
            <div className={`col-lg-6 col-md-12 col-sm-12`}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '36px'}}>
                    <label style={{ maxWidth: '100%' }}>{this.props.label}:</label>
                    {this.createCheckboxes()}
                </div>
            </div>
        );
    }
}

export default GroupCheckBox;
