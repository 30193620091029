import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

const useResizeListener = (setContainerWidth) => {
    const containerRef = useRef();

    const getContainerWidth = useCallback(() => (containerRef.current ? containerRef.current.clientWidth : 'auto'), []);
    const containerRefClientWidth = containerRef.current && containerRef.current.clientWidth;

    const updateContainer = useCallback(
        debounce(
            () => {
                setContainerWidth(getContainerWidth());
            },
            400,
            { leading: true, trailing: true },
        ),
        [getContainerWidth, setContainerWidth, containerRefClientWidth],
    );

    const sidebarCollapsed = useSelector((state) => state.app.menu.sidebarCollapsed);

    useEffect(() => {
        // LOGIC: changed from useLayoutEffect because of BUG on first load !
        if (getContainerWidth() > 0) {
            return;
        }
        const intervalForFirstLoadHack = setInterval(() => {
            updateContainer();
            if (getContainerWidth() > 0) {
                clearInterval(intervalForFirstLoadHack);
            } else {
                // console.warn('INTERVAL', getContainerWidth(), ':::', containerRefClientWidth); // LOGIC: [MED-529] BUG on first load !
            }
        }, 500);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        window.addEventListener('resize', updateContainer);
        setTimeout(() => updateContainer(), sidebarCollapsed ? 300 : 300);
    }, [getContainerWidth, setContainerWidth, sidebarCollapsed, updateContainer, containerRefClientWidth]);

    return containerRef;
};

export default useResizeListener;
