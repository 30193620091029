import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../../../components/SubmitButton';
import PropTypes from 'prop-types';
import { createNewPhysician, editPhysician } from '../../../redux/actions/physicianActions';
import shortId from 'shortid';
import { getMultiSelectValue, getSelectFieldValue } from '../../../utils/helpers';
import { showValidation } from '../../../redux/actions/BasicEntityActions';

class PhysicianSave extends React.Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this)
    }

    onSave(e, closeAfterSave) {
        e.preventDefault();
        if (this.props.isValid) {
            if (this.props.physician.code) {
                this.props.savePhysician({
                    ...this.props.physician,
                    active: this.props.physician.active,
                    code: this.props.physician.code ? this.props.physician.code : shortId.generate(),
                    countryCode: getSelectFieldValue(this.props.physician.countryCode),
                    stateCode: getSelectFieldValue(this.props.physician.stateCode),
                    specialtyCodes: getMultiSelectValue(this.props.physician.specialtyCodes),
                    bodyPartCodes: getMultiSelectValue(this.props.physician.bodyPartCodes),
                    languageCodes: getMultiSelectValue(this.props.physician.languageCodes)
                }, closeAfterSave)
            } else {
                this.props.newPhysician({
                    ...this.props.physician,
                    code: shortId.generate(),
                    countryCode: getSelectFieldValue(this.props.physician.countryCode),
                    stateCode: getSelectFieldValue(this.props.physician.stateCode),
                    specialtyCodes: getMultiSelectValue(this.props.physician.specialtyCodes),
                    bodyPartCodes: getMultiSelectValue(this.props.physician.bodyPartCodes),
                    languageCodes: getMultiSelectValue(this.props.physician.languageCodes)
                });
            }
        } else {
            this.props.showValidation();
        }
    }

    render() {
        if (!this.props.isEditable) return (<></>);
        return (
            <React.Fragment>
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={this.onSave}
                />
                <SubmitButton
                    color={'green'}
                    disabled={!this.props.isProfileChanged}
                    onSubmit={(e) => this.onSave(e, true)}
                    title={'Save And Close'}
                />
            </React.Fragment>
        )
    }

    static propTypes = {
        isEditable: PropTypes.bool,
        isProfileChanged: PropTypes.bool,
        isValid: PropTypes.bool,
        physician: PropTypes.object,
        savePhysician: PropTypes.func,
        newPhysician: PropTypes.func,
        showValidation: PropTypes.func,
    }
}

PhysicianSave.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        physician: state.app.physician.editedPhysician,
        isValid: state.app.physician.physicianValid,
        isProfileChanged: state.app.physician.isProfileChanged,
        isEditable: state.app.users.my_permissions.includes('MODIFY_PHYSICIANS'),
    }
};

const mapDispatchToProps = dispatch => ({
    savePhysician: (data, closeAfterSave) => dispatch(editPhysician(data, closeAfterSave)),
    newPhysician: data => dispatch(createNewPhysician(data)),
    showValidation: () => dispatch(showValidation('physician'))
});

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianSave)
