import React from "react"
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

class CaseDecideLater extends React.Component {

    render() {
        return (
            <span>
                <button
                    disabled={this.props.disabledReviewerButtons}
                    className="btn resizable-button tooltip-button margin-left-5"
                    onClick={() => this.props.history.goBack()}>
                    {this.context.translate('common.cases.decide_later')}
                </button>
            </span>
        )
    }
}

CaseDecideLater.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state) => {
    return {
        disabledReviewerButtons: state.app.cases.disabledReviewerButtons
    }
};

export default connect(mapState)(withRouter(CaseDecideLater));
