import React from 'react';
import { connect } from 'react-redux';
import shortId from 'shortid';
import '../../components/ModalWin.css';
import FormField from '../../components/FormField';
import { createNewUser } from '../../redux/action-creators/users';
import { isUser } from '../../utils/helpers';
import PropTypes from 'prop-types';
import FormValidateWrapper from '../../containers/FormValidateWrapper';
import { getAll } from 'redux/actions/BasicEntityActions';

class UserCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstLoad: true,
            active: true,
            roleCode: '',
            companyCode: '',
            currentUser: '',
            currentCompany: '',
            timezone: '',
        };

        this.createUser = this.createUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCompanyCode = this.onChangeCompanyCode.bind(this);
        this.onChangeRole = this.onChangeRole.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.onChangeTimeZones = this.onChangeTimeZones.bind(this);
    }

    componentDidMount() {
        this.props.dispatchValidState();
        this.props.resetError();
        this.props.getAllTimeZones(this.props.timezones);
    }

    onChangeCheckBox(e) {
        this.setState({ [e.target.name]: e.target.checked });
    }

    createUser(e) {
        e.preventDefault();
        console.log(this.state);
        if (this.props.validator.allValid()) {
            let companyCode = this.state.companyCode.value;
            let tz = this.state.timezone.value;
            if (this.props['showCompanies'] === 'none') {
                companyCode = this.props['defaultCompany'] ? this.props['defaultCompany'].value : '';
            }
            const data = {
                ...this.state,
                code: shortId.generate(),
                roleCode: this.state.roleCode.value,
                companyCode: companyCode,
                timeZoneCode: tz,
                email: this.state.email.trim(),
            };
            this.props.signUp(data);
        } else {
            this.props.showMessages();
        }
    }

    onChange(e) {
        if(e.target.name.toLowerCase().includes('email'))
            this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, '') });
        else
            this.setState({ [e.target.name]: e.target.value });
    }

    onChangeCompanyCode(companyCode) {
        this.setState({ companyCode });
        if (companyCode.tz) {
            this.setState({ timezone: this.props.timezones.find((zone) => zone.value === companyCode.tz) });
        }
    }

    onChangeRole(roleCode) {
        this.setState({ roleCode });
    }

    onChangeTimeZones(timeZone) {
        this.setState({ timezone: timeZone });
    }

    render() {
        return (
            <form className="modal-content custom-modal-content" noValidate onSubmit={this.createUser}>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            label={this.context.translate('common.users.first_name')}
                            id={this.context.translate('common.users.first_name')}
                            className="form-control"
                            required
                            name="firstName"
                            valids="required|alpha_dash_space"
                            validator={this.props.validator}
                            value={this.state.firstName}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                {/*
                <label>
                    {this.context.translate('common.users.middle_name')}
                </label>
                {this.props.validator.message('Middle name', this.state.middleName, 'alpha', 'text-danger')}
                <input
                    className='form-control margin-bottom-15 modal-input'
                    name="middleName"
                    type='text'
                    value={this.state.middleName}
                    onChange={this.onChange}>
                </input>
                */}
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            label={this.context.translate('common.users.last_name')}
                            id={this.context.translate('common.users.last_name')}
                            className="form-control"
                            required
                            name="lastName"
                            valids="required|alpha_dash_space"
                            validator={this.props.validator}
                            value={this.state.lastName}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        {this.props['showCompanies'] !== 'none' ? (
                            <FormField
                                md="12"
                                type="select"
                                label={this.context.translate('common.users.company')}
                                id={this.context.translate('common.users.company')}
                                className="text-bg required-field"
                                searchable
                                disabled={false}
                                valids="required"
                                validator={this.props.validator}
                                options={this.props.companies}
                                value={this.state.companyCode}
                                onChange={this.onChangeCompanyCode}
                                onBlur={this.props.dispatchValidState}
                            />
                        ) : (
                            <FormField
                                md="12"
                                label={this.context.translate('common.users.company')}
                                disabled="disabled"
                                className="form-control"
                                id="user_edit_state"
                                value={this.props['defaultCompany'].label}
                            />
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type="select"
                            label={this.context.translate('common.users.role')}
                            id={this.context.translate('common.users.role')}
                            className="text-bg required-field"
                            searchable
                            disabled={false}
                            valids="required"
                            validator={this.props.validator}
                            options={this.props.roles}
                            value={this.state.roleCode}
                            onChange={this.onChangeRole}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            icon="envelope-o"
                            type="input-icon"
                            required
                            label={this.context.translate('common.form.email')}
                            id={this.context.translate('common.form.email')}
                            className="form-control"
                            name="email"
                            valids="required|email"
                            validator={this.props.validator}
                            value={this.state.email}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type="input-icon"
                            icon="phone"
                            label={this.context.translate('common.form.phone_number')}
                            id={this.context.translate('common.form.phone_number')}
                            className="form-control"
                            required
                            name="phoneNumber"
                            valids="required"
                            validator={this.props.validator}
                            value={this.state.phoneNumber}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            id="Time Zone"
                            type="select"
                            label="Time Zone"
                            className="text-bg required-field"
                            required
                            valids="required"
                            validator={this.props.validator}
                            searchable={true}
                            multi={false}
                            disabled={false}
                            options={this.props.timezones}
                            name="timeZoneCode"
                            value={this.state.timezone}
                            onChange={this.onChangeTimeZones}
                        />
                    </div>
                </div>
                <FormField
                    md="12"
                    type="checkbox"
                    title={this.context.translate('common.form.status')}
                    label={this.context.translate('common.form.active')}
                    name="active"
                    value={this.state.active}
                    checked={this.state.active}
                    onChange={this.onChangeCheckBox}
                    className="checkbox"
                />
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right text-right">
                            <button className="btn btn-flat btn-warning" disabled={this.props.isLoading}>
                                {this.context.translate('common.users.add_user')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

UserCreateForm.contextTypes = {
    translate: PropTypes.func,
};

const mapStateToProps = (state) => ({
    isLoading: state.app.users.loading,
    error: state.app.users.error,
    companies: state.app.companies.data
        .filter((item) => item.active)
        .map((cp) => ({
            label: cp.fullName,
            value: cp.code,
            tz: cp.timeZoneCode,
        })),
    currentUser: state.app.auth.user,
    roles: state.app.users.roles
        .filter((item) => isUser(item.code) && item.active)
        .map((role) => ({
            value: role.code,
            label: role.name.toLowerCase(),
        })),
    timezones: state.app.timezone.data
        .filter((item) => item.active)
        .map((zone) => ({
            value: zone.code,
            label: zone.name,
        })),
});

const mapDispatchToProps = (dispatch) => ({
    signUp: (user, userType = 'submitters') => {
        dispatch(createNewUser(user, userType));
    },
    resetError: () => {
        dispatch({
            type: 'FETCH_USER_ERROR',
            payload: null,
        });
    },
    getAllTimeZones: (timezone) => dispatch(getAll('time_zone', timezone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(UserCreateForm));
