/* eslint-disable react/prop-types */
import React from 'react';

const AuthLayout = ({ children }) => (
  <div className="login-box">
    <div className="login-box-body">
      <div className="login-logo">
        <a className="login-logo-size" href="/">
          <img
            src="/static/media/medrev-logo-medium.42f1aa6e.png"
            alt="MedRev"
          />
        </a>
      </div>
      {children}
    </div>
  </div>
);

export default AuthLayout;
