import { createApiParams } from '../../../components/Datatable/dataTableUtils';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import FileDownload from 'react-file-download';
import { defer, from, iif, merge, of } from 'rxjs';
import api from '../../../utils/api';
import { combineEpics } from 'redux-observable';
import caseFileTemplateSlice, {
    createEntityError,
    createEntityLoading,
    createEntitySuccess,
    deleteSuccess,
    downloadCaseFileTemplate,
    downloadCaseFileTemplateError,
    downloadCaseFileTemplateSuccess,
    fetchEntityError,
    loadData,
    setServerErrors,
    updateEntityError,
    updateEntityLoading,
    updateEntitySuccess
} from './caseFileTemplateSlice';
import { MIME_TYPES } from 'constants/mimeTypes';
import { dataUrlToFile } from 'components/Controls/dropzoneUtils';
import history from '../../history';
import { catchError$, createRedirectEpic$, deleteEntity$, fetchEntity$, fetchEntityList$ } from 'redux/epicUtils/commonEpics';
import { isFunction, omit } from 'lodash';
import { transformCaseFileTemplates } from './utils';
import { fieldsErrors } from "../../actions/statuses";
import { mapUnprocessableEntityErrorMessages } from "../../epicUtils/epicErrorHandling";

const loadCaseFileTemplatesEpic = fetchEntityList$({
    ...caseFileTemplateSlice.actions,
    resolveUrl: () => 'case_file_template/page',
    resolveParams: (params) => [{ params: createApiParams(params) }],
    dataTransformer: transformCaseFileTemplates,
});

const loadCaseFileTempateEpic = fetchEntity$({
    ...caseFileTemplateSlice.actions,
    resolveUrl: ({ code }) => 'case_file_template/get?code=' + code,
});

const deleteCaseFileTemplatesEpic = deleteEntity$({
    ...caseFileTemplateSlice.actions,
    resolveUrl: () => 'case_file_template/delete',
    resolveParams: (code) => [{ code }],
    deleteSuccess: (code, { sortBy = 'name', ...settings }) => [loadData({ ...settings, sortBy }), deleteSuccess(code)],
    getState: (state$) => state$.value.app.caseFileTemplates.settings,
});

const decodeFile$ = () =>
    switchMap(({ payload: { template: { file, name } = { file: null }, ...params } }) =>
        iif(
            () => file,
            from(dataUrlToFile(file, name, MIME_TYPES.docx)).pipe(
                map((template) => ({
                    ...params,
                    template,
                    templateName: name,
                })),
            ),
            of(omit(params, 'template')),
        ),
    );

const uploadFile$ = () =>
    switchMap(({ template, ...params }) =>
        iif(
            () => template,
            defer(() => {
                const data = new FormData();
                data.append('template', template);
                return from(api.post('case_file_template/upload?code=' + params.code, data)).pipe(map(() => params));
            }),
            of(params),
        ),
    );

const updateCaseFileTemplateEpic = (action$) => {
    return action$.pipe(
        filter(updateEntityLoading.match),
        decodeFile$(),
        uploadFile$(),
        switchMap((params) =>
            from(api.post('case_file_template/update', omit(params, 'closeAfterSave'))).pipe(
                map((response) => ({ closeAfterSave: params.closeAfterSave, ...response.data })),
            ),
        ),
        mergeMap((data) => [updateEntitySuccess(data)]),
        // catchError(catchError$(updateEntityError)),
        catchError(catchErrorCaseFileTemplate$(updateEntityError, setServerErrors)),
    );
};

const createCaseFileTemplateEpic = (action$) => {
    return action$.pipe(
        filter(createEntityLoading.match),
        decodeFile$(),
        switchMap(({ template, ...params }) => {
                const data = new FormData();
                data.append('template', template);
                return from(api.post('case_file_template/validate', data)).pipe(map(() => {
                    return ({ template, params });
                }));
            }
        ),
        switchMap(({ template, params }) => {
                return from(api.post('case_file_template/create', omit(params, 'closeAfterSave'))).pipe(map((response) => {
                    return ({ template, ...response.data });
                }))
            },
        ),
        uploadFile$(),
        mergeMap((data) => [
            createEntitySuccess(data),
            {
                type: 'CLOSE_CREATE_MODAL',
            },
        ]),
        catchError(catchErrorCaseFileTemplate$(createEntityError, setServerErrors)),
    );
};

const catchErrorCaseFileTemplate$ = (errorAction, setServerErrors) => (error, caught) => {
    console.error('catchErrorCaseFileTemplate$:', error, ':::', caught);
    const { response: { status } = { status: null } } = error;

    const message = status ? fieldsErrors[ status ] : error.message;

    let actionStream = [of(isFunction(errorAction) ? errorAction(message) : errorAction)];

    if (status === 406) {
        let { response: { data: { errors: errorMessages, error: errorMessage } } = { data: { errors: [], error: 'Validation error' } } } = error;
        console.log('406:', errorMessages, errorMessage);
        actionStream.push(of(setServerErrors(mapUnprocessableEntityErrorMessages(
            [{ fieldName: 'Upload Document', errorMessage: 'Document not in desired format' }])))
        );
    }

    console.error('Catch Error status:', status, ':::', message);
    actionStream.push(caught);

    return merge(...actionStream);
}

const redirectAfterUpdate = createRedirectEpic$(updateEntitySuccess, ({ closeAfterSave }) => {
    if (closeAfterSave) {
        history.goBack();
    }
});

const downloadCaseFileTemplateEpic = (action$) => {
    return action$.pipe(
        filter(downloadCaseFileTemplate.match),
        switchMap(({ payload: { templateName, code } }) =>
            from(
                api.request({
                    url: 'case_file_template/download',
                    params: { code },
                    responseType: 'arraybuffer',
                    headers: {
                        Accept: [MIME_TYPES.octetStream, MIME_TYPES.docx],
                    },
                }),
            ).pipe(map((response) => ({ dataStream: response.data, templateName }))),
        ),
        map(({ dataStream, templateName }) => {
            FileDownload(dataStream, templateName);
        }),
        map(downloadCaseFileTemplateSuccess),
        catchError(catchError$(downloadCaseFileTemplateError)),
    );
};

const redirectIfFetchErrorEpic = createRedirectEpic$(fetchEntityError, () => {
    history.push(`/case_file_templates`);
});

const redirectAfterCreateEpic = createRedirectEpic$(createEntitySuccess, ({ code }) => {
    history.push(`/case_file_templates/update/${code}`);
});

export default combineEpics(
    loadCaseFileTemplatesEpic,
    deleteCaseFileTemplatesEpic,
    createCaseFileTemplateEpic,
    updateCaseFileTemplateEpic,
    loadCaseFileTempateEpic,
    redirectAfterCreateEpic,
    redirectAfterUpdate,
    redirectIfFetchErrorEpic,
    downloadCaseFileTemplateEpic,
);
