import React from 'react'
import Modal from 'react-modal';
import RemoteGrid from "../../../components/Grid/RemoteGrid";
import connect from "react-redux/es/connect/connect";
import {getAllCaseTypes} from '../../../redux/actions/cases/caseTypesActions'
import LoadingIndicator from "../../../components/LoadingIndicator";
import PropTypes from "prop-types";

class CaseTypesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            caseType: '',
        };
        this.onRowClick = this.onRowClick.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
    }

    componentDidMount() {
        Modal.setAppElement('body');
        this.props.getAllCaseTypes();
    }

    onRowClick(item) {
        this.setState({item})
        this.props.history.push(`case_types/update/${item}`)
    }


    enumFormatter(cell, row, enumObject) {
        return enumObject[cell];
    }

    render() {
        let columns = [
            {
                'isKey': true,
                'name': 'code',
                'title': 'Code',
                'sort': false,
                'hidden': true
            },
            {
                'name': 'name',
                'title': 'Full Name',
                'sort': true,
                'width': '200',
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            },
            {
                'name': 'shortName',
                'title': 'Short Name',
                'sort': true,
                'width': '200',
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            },
            {
                'name': 'tat',
                'title': 'TAT',
                'sort': true,
                'width': '200',
                'filter': {type: 'TextFilter', delay: 500},
                'headerAlign': 'left'
            },
            {
                'name': 'active',
                'title': 'Status',
                'headerAlign': 'left',
                'contentAlign': 'center',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': 'Inactive',
                    'true': 'Active'
                },
                'width': '100',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': 'Inactive',
                        'true': 'Active'
                    }
                }
            }
        ];
        const {isLoading} = this.props;
        const {sorting, pagination} = this.props.gridConfiguration
        return (
            <div className='container-fluid'>
                <div className="table-responsive" style={{marginTop: '15px', "padding": '10px'}}>
                    {isLoading && <LoadingIndicator type="lister"/>}
                    <RemoteGrid
                        color="blue"
                        entity="case_type"
                        title="Case Types"
                        data={this.props.data}
                        total={this.props.totalElements}
                        columns={columns}
                        pagination={true}
                        page={pagination.page}
                        sizePerPage={pagination.sizePerPage}
                        search={true}
                        onRowClick={this.onRowClick}
                        defaultSort
                        sortName={sorting.sortName}
                        sortOrder={sorting.sortOrder}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        gridConfiguration: PropTypes.object,
    }
    static defaultProps = {
        isLoading: false
    };
}

const mapState = (state) => {
    return {
        data: state.app.case_types.types,
        isLoading: state.app.case_types.loading,
        size: state.app.case_types.types.length,
        totalElements: state.app.case_types.types.length,
        gridConfiguration: state.app.grid.caseType,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllCaseTypes: () => dispatch(getAllCaseTypes()),
    }
}

export default connect(mapState, mapDispatchToProps)(CaseTypesList);
