import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import { useForm } from 'components/Form';
import financeSlice, {
  FINANCE_RATE_TYPES,
} from '../../../../redux/pages/case-finances/financeSlice';
import './FinanceSection.css';
import { useSelector } from 'react-redux';
import { isReviewer } from 'utils/helpers';
import ReviewerHourlyFields from './ReviewerHourlyFields';
import ReviewerFlatFields from './ReviewerFlatFields';
import StaffFields from './StaffFields';
import FinanceContext from './FinanceContext';

const { actions, name: formKey } = financeSlice;

const FinanceFlatForm = ({
  overrideCalculation,
  onBillingTierChange,
  userRole,
  legacyValidator,
  templateCode,
}) => {
  const formProps = useForm(actions, formKey);
  const isReviewerRole = isReviewer(userRole);

  const { isLoading, fieldSettings, calculateUpdateSuccess, fieldVisibilites, rateType } = useSelector(
    (state) => state.app.finances,
  );

  const handleChange = useCallback(
    (...args) => {
      overrideCalculation(...args);
    },
    [overrideCalculation],
  );

  const contextValues = {
    fieldSettings,
    fieldVisibilites,
    calculateUpdateSuccess,
    handleChange,
    isLoading,
    legacyValidator,
    templateCode,
  };

  const isHourlyReviewer =
    isReviewerRole && rateType === FINANCE_RATE_TYPES.hourly;
  const isFlatReviewer = isReviewerRole && rateType === FINANCE_RATE_TYPES.flat;

  return (
    <FinanceContext.Provider value={contextValues}>
      <Form onSubmit={() => {}} formKey={formKey} {...formProps}>
        {(() => {
          if (isHourlyReviewer) {
            return <ReviewerHourlyFields />;
          }
          if (isFlatReviewer) {
            return <ReviewerFlatFields />;
          }

          return <StaffFields onBillingTierChange={onBillingTierChange} />;
        })()}
      </Form>
    </FinanceContext.Provider>
  );
};

FinanceFlatForm.propTypes = {
  userRole: PropTypes.string.isRequired,
  overrideCalculation: PropTypes.func.isRequired,
  onBillingTierChange: PropTypes.func.isRequired,
};

export default FinanceFlatForm;
