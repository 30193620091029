import { combineEpics } from 'redux-observable';

import notificationEpics from './pages/notification-template/notificationTemplateEpics';
import patientEpics from './pages/patient/patientEpics';
import queuedMessageEpics from './pages/queued-message/queuedMessageEpics';
import caseFileTemplateEpics from './pages/case-file-template/caseFileTemplateEpics';
import financeEpics from './pages/case-finances/financeEpics';
import patientCaseEpics from './pages/patient-case/patientCaseEpics';
import reviewerEpics from "./pages/reviewer/reviewerEpics";
import filteredReviewersEpics from "./pages/filtered-reviewers/filteredReviewersEpics";
import facilityEpics from "./pages/facility/facilityEpics";
import physicianEpics from "./pages/physician/physicianEpics";
import clientEditPhysicianEpics from "./pages/client-edit-physician/clientEditPhysicianEpics";
import clientEditFacilityEpics from "./pages/client-edit-facility/clientEditFacilityEpics";
import clientEditMedicalServicesServicesEpics from "./pages/client-edit-medical-services/services/clientEditMedicalServicesServicesEpics";
import clientEditMedicalServicesDiagnosesEpics from "./pages/client-edit-medical-services/diagnoses/clientEditMedicalServicesDiagnosesEpics";

const epics = [
    notificationEpics, patientEpics, reviewerEpics, facilityEpics, queuedMessageEpics, caseFileTemplateEpics, physicianEpics,
    financeEpics, patientCaseEpics,
    clientEditPhysicianEpics, clientEditFacilityEpics, clientEditMedicalServicesServicesEpics, clientEditMedicalServicesDiagnosesEpics,
    filteredReviewersEpics
];

export default combineEpics(...epics);
