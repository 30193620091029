import React, { Component } from "react";
import {connect} from 'react-redux'
import {getAll} from '../../redux/actions/BasicEntityActions'
import {
    dispatchTemplateValidStatus,
    editedFieldForReport,
    editedOutCome,
    isTemplateValid,
    setCurrentTemplate,
    updateFieldForReport
} from "../../redux/actions/templateActions";
import FormField from '../../components/FormField'
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';

class ReportForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templateChanged: false
        };
        this.onChangeDymicOutComeHandler = this.onChangeDymicOutComeHandler.bind(this);
        props.validator.showMessages();

    }

    componentDidMount() {
        this.props.getInitData()
    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        if (!nextProps.isFormValid && nextProps.isFormValid !== this.props.isFormValid) {
            this.props.validator.showMessages();
        }

    }

    onChangeDymicOutComeHandler = (event, value) => {
      
      this.props.editOutCome({value, event})
    };
  

    onChangeLanguage = (language) => this.setState({language});

    saveTemplate = () => {
        const {template} = this.props;
        const fieldInstanceCodes = template.fieldInstancesDto.map((item) => item.code);
        const names = {'EN': template.name};

        return this.props.updateFieldForReport({...template, names, fieldInstanceCodes})
    };

    render() {
      const listOptions = this.props.outComeFieldsByCaseType[0]?.listOptions;
      let valueLabelMap = {};
      
      if (listOptions) {
          var optionsArray = JSON.parse(listOptions);
          for (var i = 0; i < optionsArray?.length; i++) {
              var option = optionsArray[i];
              valueLabelMap[option.value] = option.label;
          }
      }

      if(!Object.keys(valueLabelMap).length) {
        valueLabelMap = this.props.allOutComeField;
      }
      
        return (<section className="content user-profile-content">
            <div className="row">
                <div className="col-md-12">
                    <PreventTransitionPrompt
                        when={this.props.isTemplateChanged}
                        message='MyCustomDialogComponent'
                        entity='template'
                        isFormValid={this.props.isFormValid}
                        saveChanges={this.saveTemplate}
                        cancelAndDiscard={() => {
                            this.props.setCurrentTemplate(this.props.originalTemplate);
                            this.props.templateEditField('isTemplateChanged', false)
                        }}
                    />

                    <Box color="blue" classes="box-profile-first-row-height-show">
                        <BoxHeader title={this.props.label} color="blue"/>

                        <BoxBody>
                            <form className='' onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    {this.props.fieldsForReportAssignment.map((value) => (
                                    <div className="row" key={value}>
                                        <FormField
                                            md="12"
                                            type="select"
                                            label={toCamelCase(value)}
                                            id={value}
                                            className="text-bg required-field"
                                            options={this.props.workFlows}
                                            name={value}
                                            placeholder='Do not report'
                                            value={this.props.template.fieldForReportDto?.fieldReferences?.[value]}
                                            onChange={(event) => this.onChangeDymicOutComeHandler(event, value)}
                                            validator={this.props.validator}
                                            onBlur={this.props.dispatchValidState}
                                        />
                                    </div>
                                    ))}
                                    <div style={{ marginTop: '30px', marginBottom: '10px' }}>
                                      <strong>Outcome fields by case type</strong>
                                    </div>
                                    {Object.keys(valueLabelMap).map((value) => (
                                    <div className="row" key={value}>
                                        <FormField
                                            md="12"
                                            type="select"
                                            label={valueLabelMap[value]}
                                            id={value}
                                            className="text-bg required-field"
                                            options={this.props.workFlows}
                                            name={value}
                                            placeholder='Do not report'
                                            value={this.props.template.fieldForReportDto?.fieldReferences?.[value]}
                                            onChange={(event) => this.onChangeDymicOutComeHandler(event, value)}
                                            validator={this.props.validator}
                                            onBlur={this.props.dispatchValidState}
                                        />
                                    </div>
                                    ))}
                                </div>
                            </form>
                        </BoxBody>
                        <BoxFooter/>
                    </Box>
                </div>
            </div>
        </section>)
    }
}

const mapStateToProps = (state) => (
  {
    template: state.app.templates,
    originalTemplate: state.app.templates.originalTemplate,
    isTemplateChanged: state.app.templates.isTemplateChanged,
    workFlows: state.app.templates.fieldInstancesDto.map((item) => ({label: item.displayName, value: item.originalFieldCode})),
    primitiveFields: state.app.fields.data.map((item) => ({label: item.displayName, value: item.code})),
    error: state.app.templates.error,
    loading: state.app.templates.loading,
    templateEditSuccess: state.app.templates.templateEditSuccess,
    outComeFieldsByCaseType: state.app.templates.fieldInstancesDto.filter((field) => field.originalFieldCode === 'caseType'),
    allOutComeField: state.app.case_types.types.reduce((acc, item) => {
      acc[item.code] = item.name;
      return acc;
    }, {}),
    fieldsForReportAssignment: ['caseType', 'gender', 'clientCaseNo', 'bodyPart', 'dateOfBirth', 'memberAge', 'requestedSpecialty', 'reviewStage', 'reviewTiming', 'turnAroundDays', 'treatingDoctorName', 'facility', 'sufficientClinicalInformation'],
    outComeByCaseTypeValues: state.app.templates.fieldForReportDto?.fieldReferences,
    label: `Template - ${state.app.templates.name}`,
    isFormValid: state.app.templates.isTemplateValid,
});

const mapDispatchToProps = (dispatch) => {
    return {
        templateEditField: (field, value) => (dispatch(editedFieldForReport(field, value))),
        editOutCome: (field, value) => (dispatch(editedOutCome(field, value))),
        getInitData: () => {
            dispatch(setCurrentTemplate());
            dispatch(dispatchTemplateValidStatus(true));
            dispatch(isTemplateValid(true));
            return dispatch(getAll('workflow'))
        },
        isAllValid: (valid) => dispatch(isTemplateValid(valid)),
        updateFieldForReport: data => dispatch(updateFieldForReport(data)),
        setCurrentTemplate: data => dispatch(setCurrentTemplate(data)),

    }
};

function toCamelCase(name) {
  return name
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function(str) { return str.toUpperCase(); })
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportForm)
