import React, { Component } from 'react';
import RemoteGrid from '../../components/Grid/RemoteGrid';
import { userEditField } from '../../redux/action-creators/users';
import { connect } from 'react-redux';
import { onSort } from '../../redux/actions/gridActions';

class ReviewerStatisticsInfo extends Component {
    constructor(props) {
        super(props);
        this.props.sort('status', 'asc');
    }

    onEditCell = (row, cellName, cellValue) => {
        this.props.userEditField(
            row.key + cellName,
            cellValue
        );
        setTimeout(this.props.dispatchValidStatus, 500)
    };

    onBeforeSaveCell = () => {
        // You can do any validation on here for editing value,
        // return false for reject the editing
        return true;
    };

    render() {
        const { validator, profile, showValidMessages } = this.props;
        validator.message('Accepted Per Month Min', profile.acceptedPerMonthMin, 'required', 'text-danger');
        validator.message('Accepted Per Month Max', profile.acceptedPerMonthMax, 'required', 'text-danger');
        validator.message('Assigned Per Day Max', profile.assignedPerDayMax, 'none', 'text-danger');

        const columnsFirst = [
            {
                'isKey': true,
                'title': 'Key',
                'name': 'key',
                'sort': false,
                'hidden': true
            }, {
                'name': 'status',
                'title': 'Status',
                'width': '100',
                'columnTitle': true,
                'sort': true,
            }, {
                'name': 'lastMonth',
                'title': 'Last Month',
                'width': '150',
                'columnTitle': true,
                'sort': true,
            }, {
                'name': 'currentMonth',
                'title': 'Current Month',
                'columnTitle': true,
                'width': '100',
                'sort': true,
            }, {
                'name': 'total',
                'title': 'Total',
                'columnTitle': true,
                'width': '100',
                'sort': true,
            }, {
                'name': 'first',
                'title': 'First',
                'columnTitle': true,
                'width': '150',
                'sort': true,
            }, {
                'name': 'last',
                'title': 'Last',
                'columnTitle': true,
                'width': '150',
                'sort': true,
            }
        ];

        const columnsSecond = [
            {
                'isKey': true,
                'name': 'key',
                'title': 'Key',
                'sort': false,
                'hidden': true
            }, {
                'name': 'status',
                'title': 'Status',
                'width': '100',
                'editable': false,
                'columnTitle': true,
                'sort': true,
            }, {
                'name': 'Min',
                'title': 'Min',
                'width': '70',
                'columnTitle': true,
                'sort': true,
                'editable': (cellValue, row) => row.key !== 'assignedPerDay',
                'columnClassName': (fieldValue) => {
                    if (showValidMessages && !fieldValue) return 'td_invalid_data'
                }
            }, {
                'name': 'Max',
                'title': 'Max',
                'columnTitle': true,
                'width': '70',
                'sort': true,
                'tdStyle': { bordered: '1px solid red' },
                'columnClassName': (fieldValue) => {
                    if (showValidMessages && !fieldValue) return 'td_invalid_data'
                }
            }
        ];
        return (
            <div className="row">
                <div className='col-md-12'>
                    <RemoteGrid
                        title={`Reviewer - ${this.props.profile.firstName} ${this.props.profile.lastName}`}
                        search={true}
                        data={this.props.statisticsTableData}
                        total={this.props.statisticsTableData.length}
                        columns={columnsFirst}
                        onRowClick={() => console.log()}
                        noFilter={true}
                    >
                        <div className="row">
                            <div className='col-md-1' style={{ marginLeft: '10px' }}>
                                <strong>Quality:</strong> {this.props.profile.qualityScore}
                            </div>
                            <div className='col-md-2' style={{ textAlign: 'center' }}>
                                <strong>On Time:</strong> {this.props.profile.onTime}%
                            </div>
                        </div>
                    </RemoteGrid>
                </div>
                <div className='col-md-12'>
                    <RemoteGrid
                        title={`Reviewer - ${this.props.profile.firstName} ${this.props.profile.lastName}`}
                        search={true}
                        data={this.props.minMaxTableData}
                        total={this.props.minMaxTableData.length}
                        columns={columnsSecond}
                        onRowClick={() => console.log()}
                        cellEdit={{
                            mode: 'click',
                            blurToSave: true,
                            afterSaveCell: this.onEditCell
                        }}
                        noFilter={true}
                    />
                </div>
            </div>
        );
    }
}

const dateFormat = (dateISO) => {
    return dateISO ? new Date(dateISO).toDateString() : '-';
}

const mapStateToProps = (state) => {
    const stat = state.app.users.statistics;
    return {
        profile: state.app.users.editedUser,
        statisticsTableData: [
            {
                key: 1,
                status: 'Assigned',
                lastMonth: stat.lastMonthAssigned,
                currentMonth: stat.currentMonthAssigned,
                total: stat.totalAssigned,
                first: dateFormat(stat.assignedFirstDate),
                last: dateFormat(stat.assignedLastDate),
            }, {
                key: 2,
                status: 'Accepted',
                lastMonth: stat.lastMonthAccepted,
                currentMonth: stat.currentMonthAccepted,
                total: stat.totalAccepted,
                first: dateFormat(stat.acceptedFirstDate),
                last: dateFormat(stat.acceptedLastDate),
            }, {
                key: 3,
                status: 'Scooped',
                lastMonth: stat.lastMonthScooped,
                currentMonth: stat.currentMonthScooped,
                total: stat.totalScooped,
                first: dateFormat(stat.scoopedFirstDate),
                last: dateFormat(stat.scoopedLastDate),
            }, {
                key: 4,
                status: 'Rejected',
                lastMonth: stat.lastMonthRejected,
                currentMonth: stat.currentMonthRejected,
                total: stat.totalRejected,
                first: dateFormat(stat.rejectedFirstDate),
                last: dateFormat(stat.rejectedLastDate),
            }
        ],
        minMaxTableData: [
            {
                key: 'assignedPerDay',
                status: 'Assigned Per Day',
                Min: '-',
                Max: state.app.users.editedUser.assignedPerDayMax,
            }, {
                key: 'acceptedPerMonth',
                status: 'Accepted Per Month',
                Min: state.app.users.editedUser.acceptedPerMonthMin,
                Max: state.app.users.editedUser.acceptedPerMonthMax,
            },
        ],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        userEditField: (field, value) => dispatch(userEditField(field, value)),
        sort: (value, dir) => dispatch(onSort(value, dir)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewerStatisticsInfo);
