import React from 'react'

const titleClass = {
    'orange': 'text-orange',
    'green': 'text-green',
    'blue': 'text-blue'
};

const BoxHeader = ({icon, color, children, title, onClick}) => (
    <div className="box-header with-border" onClick={onClick}>
        <h3 className={"box-title " + titleClass[color]}>
            <i className={`fa ${icon} ${titleClass[color]} margin-right-5`}/>
            {title}
        </h3>
        {children}
    </div>
);

export default BoxHeader
