import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { DateTimePicker } from 'react-widgets';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { capitalizeFirstLetter, dropDownFixPosition, getCorrectDateFormat, getTimestampAccordingOffset } from '../../../utils/helpers';
import { LOCAL_TIMEZONE_OFFSET, tiers } from '../../../utils/Constants';
import { throwCaseError } from '../../../redux/actions/cases/caseActions';
import {
    saveDropDownData,
    updateExtendedAssignments,
    updateExtendedAssignmentsDetails,
    updateExtendedAssignmentsTier,
    updateTiersDates
} from '../../../redux/actions/cases/caseAssignActions';
import ReviewerAdditionalInfoNew from "./ReviewerAdditionalInfoNew";
import { ColorCircle } from "./ColorCircle";

const assignStatuses = [
    {
        label: 'Assigned',
        value: 'ASSIGNED'
    }, {
        label: 'Pre-assigned',
        value: 'PREASSIGNED'
    }, {
        label: 'Accepted',
        value: 'ACCEPTED'
    }, {
        label: 'Rejected',
        value: 'REJECTED'
    }, {
        label: 'Scooped',
        value: 'SKIPPED'
    }
];

class TierGridNew extends React.Component {

    static propTypes = {
        caseCode: PropTypes.string,
        editedCompany: PropTypes.object,
        defaultActivationDates: PropTypes.object,
        tiersActivationDates: PropTypes.object,
        originalTiersActivationDates: PropTypes.object,
        tier: PropTypes.string,
        creationDate: PropTypes.number,
        title: PropTypes.string,
        languages: PropTypes.array,
        saveDropDownData: PropTypes.func,
        updateTiersDates: PropTypes.func,
        throwCaseError: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.state = {
            opened: {}
        };
        this.tiersToMove = tiers.filter((item) => item !== props.tier)
    }

    dropDownFixPositionSave = (e) => {
        if (typeof e.target !== "undefined") {
            this.props.saveDropDownData(e.target, e.target.nextSibling);
            dropDownFixPosition(e.target, e.target.nextSibling);
        }
    };

    getDelayData = () => {
        const { tiersActivationDates, defaultActivationDates } = this.props;
        let dt0 = new Date().getTime();
        let dt1 = tiersActivationDates[ 'TIER_1' ];
        let dt2 = tiersActivationDates[ 'TIER_2' ];
        let dt3 = tiersActivationDates[ 'TIER_3' ];
        return {
            dt0: dt0,
            dt1: dt1,
            dt2: dt2,
            dt3: dt3,
            d1: dt1 ? dt1 - dt0 : 0,
            d2: dt1 && dt2 ? dt2 - dt1 : (!dt1 ? dt2 - dt0 : 0),
            d3: dt2 && dt3 ? dt3 - dt2 : (!dt2 && dt1 ? dt3 - dt1 : (!dt1 ? dt3 - dt0 : 0)),
            df1: defaultActivationDates[ 'TIER_1' ] - dt0,
            df2: defaultActivationDates[ 'TIER_2' ] - defaultActivationDates[ 'TIER_1' ],
            df3: defaultActivationDates[ 'TIER_3' ] - defaultActivationDates[ 'TIER_2' ],
            offset: LOCAL_TIMEZONE_OFFSET
        }
    };

    saveDetailsNewTiers = (newActivationDates) => {
        this.props.updateTiersDates(newActivationDates);
        const details = this.props.extendedAssignments.map((item) => {
            return {
                ...item,
                activationTime: newActivationDates[ item.tier ],
                tierActivationDate: newActivationDates[ item.tier ]
            };
        });
        this.props.updateExtendedAssignmentsDetails(this.props.caseCode, details);
    };

    onChangeAssignDate = (value) => {
        const { tier, tiersActivationDates } = this.props;
        if (!value || !(value instanceof Date) || String(new Date(value)) === 'Invalid Date') {
            value = new Date(tiersActivationDates[tier]);
        }
        let { dt0, dt1, dt2, dt3, d1, d2, d3, offset } = this.getDelayData();
        if (tier === 'TIER_1') {
            // d1 = value.getTime() - dt0;
            dt1 = value.getTime() - offset;
            dt2 = dt1 + d2;
            dt3 = dt2 + d3;
        } else if (tier === 'TIER_2') {
            // d2 = value.getTime() - dt2;
            dt1 = dt0 + d1;
            dt2 = value.getTime() - offset;
            dt3 = dt2 + d3;
        } else if (tier === 'TIER_3') {
            // d3 = value.getTime() - dt2;
            dt1 = dt0 + d1;
            dt2 = dt1 + d2;
            dt3 = value.getTime() - offset;
        }

        let newActivationDates = {
            'TIER_1': dt1,
            'TIER_2': dt2,
            'TIER_3': dt3
        };
        this.saveDetailsNewTiers(newActivationDates);
    };

    resetDelay = (e) => {
        e.preventDefault();
        const { tiersActivationDates, tier } = this.props;
        if (!tiersActivationDates[ tier ]) return '';

        let { dt0, dt1, dt2, dt3, d1, d2, d3, df1, df2, df3 } = this.getDelayData();
        if (tier === 'TIER_1') {
            d1 = df1;// - offset;
        }
        if (tier === 'TIER_2') {
            d2 = df2;
        }
        if (tier === 'TIER_3') {
            d3 = df3;
        }
        if (dt1) dt1 = dt0 + d1;
        if (dt2) dt2 = dt1 ? dt1 + d2 : dt0 + d2;
        if (dt3) dt3 = dt2 ? dt2 + d3 : (dt1 ? dt1 + d3 : dt0 + d3);

        let newActivationDates = {
            'TIER_1': dt1,
            'TIER_2': dt2,
            'TIER_3': dt3
        };
        this.saveDetailsNewTiers(newActivationDates);
    };

    getResetDisabled = () => {
        const { originalTiersActivationDates, tier } = this.props;
        if (!originalTiersActivationDates[ tier ]) return true;
        const { d1, d2, d3, df1, df2, df3 } = this.getDelayData();

        let disabled = true;
        if (tier === 'TIER_1' && Math.round(d1 / 3600000) !== Math.round(df1 / 3600000)) {
            disabled = false;
        }
        if (tier === 'TIER_2' && Math.round(d2 / 3600000) !== Math.round(df2 / 3600000)) {
            disabled = false;
        }
        if (tier === 'TIER_3' && Math.round(d3 / 3600000) !== Math.round(df3 / 3600000)) {
            disabled = false;
        }
        return disabled;
    };

    moveReviewer = (key, row) => {
        this.props.updateExtendedAssignmentsTier(row.code, key);
    };

    updateAssignment = (row, action) => {
        const details = this.props.extendedAssignments.map((item) => {
            const reviewer = { ...item };

            if (reviewer.reviewerCode === row.reviewerCode) {
                reviewer.action = action;
            } else if (action === 'ACCEPTED' && reviewer.action === 'ASSIGNED') reviewer.action = 'SKIPPED';

            return reviewer;
        });

        const acceptedCount = details.filter((item) => item.action === 'ACCEPTED').length;

        if ((action === 'ACCEPTED' && acceptedCount > 1)
            || (action === 'ASSIGNED' && acceptedCount === 1)) {
            return this.props.throwCaseError('Case is already accepted.')
        }

        this.props.throwCaseError(null);
        this.props.updateExtendedAssignments(row.code, action);
    };

    reviewerFormatter = (cell, row) => {
        return (
            <React.Fragment>
                <ColorCircle color={row.acceptedCaseCountStatus} />
                <a target='_blank' rel={'noopener noreferrer'}
                   href={`#/reviewers/profile/${row.reviewerCode}`}>{' ' + row.name}</a>
            </React.Fragment>
        )
    };

    actionFormatter = (cell, row) => {
        let tiersToMove = this.tiersToMove;
        const uid = `dropdown-basic-${Math.round(Math.random() * 1000000)}`;
        return (
            <DropdownButton
                onToggle={
                    (isOpen, event) => {
                        // eslint-disable-next-line no-empty-pattern
                        const {} = event; // TODO
                    }
                }
                onClick={this.dropDownFixPositionSave}
                bsStyle='primary'
                title='Move To'
                key={1}
                id={uid}
                disabled={this.props.loading}
            >
                {tiersToMove.map((item) => (<MenuItem key={item} eventKey={item}
                                                      onSelect={(key) => this.moveReviewer(key, row)}>{capitalizeFirstLetter(item)}</MenuItem>))}
            </DropdownButton>
        )
    };

    moreFormatter = (cell, row) => {
        return (
            <span
                style={{ fontSize: '1.1em' }}
                onClick={() => {
                    this.toggleOpenReviewerInfo(row.reviewerCode)
                }} className='cursor-pointer text_bold_muted'>
        <i className={`fa ${this.state.opened[ row.reviewerCode ] ? 'fa-angle-up' : 'fa-angle-down'}`}
           style={{ fontSize: '1.1em', marginLeft: '12px' }}> </i> More
      </span>
        )
    };

    statusFormatter = (cell, row) => {
        let statusName;
        switch (cell) {
            case 'ASSIGNED':
                statusName = 'Assigned';
                break;
            case 'PREASSIGNED':
                statusName = 'Pre-assigned';
                break;
            case 'ACCEPTED':
                statusName = 'Accepted';
                break;
            case 'REJECTED':
                statusName = 'Rejected';
                break;
            case 'SKIPPED':
                statusName = 'Scooped';
                break;
            default:
                statusName = 'Pre-assigned'
        }
        const uid = `dropdown-basic-${Math.round(Math.random() * 1000000)}`;
        return (<DropdownButton
            bsStyle='default'
            onClick={this.dropDownFixPositionSave}
            title={statusName}
            style={{ minWidth: '120px' }}
            key={1}
            id={uid}
            disabled={this.props.loading}
        >
            {
                assignStatuses
                    .map((item) => <MenuItem key={item.value} eventKey={item.value}
                                             onSelect={(key) => this.updateAssignment(row, key)}>{item.label}</MenuItem>)
            }
        </DropdownButton>)
    };

    convertDateValue = (value) => {
        if (!value || String(new Date(value)) === 'Invalid Date') return null;
        if (value instanceof Date) return value;

        return new Date(getTimestampAccordingOffset(value))
    };

    getTierDelay = () => {
        const { tiersActivationDates, tier, defaultActivationDates } = this.props;

        let prevTier = `TIER_${tier.slice(tier.length - 1, tier.length) - 1}`;
        if (!tiersActivationDates[ prevTier ] && tier === 'TIER_3') prevTier = 'TIER_1';
        const prevDate = tiersActivationDates[ prevTier ] ? tiersActivationDates[ prevTier ] : defaultActivationDates[ 'TIER_1' ];

        const delay = Math.round(
            (new Date(tiersActivationDates[ tier ]) - prevDate) / 3600000
        );

        if (delay <= 0 || !delay) return 'No Delay';

        return delay + ' Hours';
    };

    mbaFormatter = (cell, row) => {
        return <div><span>{cell}</span> <span>{row.maxBillableAmountCurrencyCode}</span></div>;
    };

    toggleOpenReviewerInfo = (code) => {
        this.setState({
            opened: {
                ...this.state.opened,
                [ code ]: !this.state.opened[ code ]
            }
        })
    };

    expandComponent = (row) => {
        return (<div>
            <ReviewerAdditionalInfoNew opened={this.state.opened[ row.reviewerCode ]} data={row.additionalInfo}
                                       languages={this.props.languages}> </ReviewerAdditionalInfoNew>
        </div>)
    };

    render() {
        const {
            tier,
            tiersActivationDates,
            creationDate,
            title,
        } = this.props;
        const tierActivationDate = tiersActivationDates[ tier ];
// TODO
console.log('tierActivationDate', tier, tierActivationDate, ':::', getCorrectDateFormat(tierActivationDate));
        const options = {
            expanding: this.props.extendedAssignments && this.props.extendedAssignments.map((item) => item.reviewerCode),
            expandBy: 'column',
            expandBodyClass: 'border_bottom',
        };
        const minDate = new Date(getTimestampAccordingOffset(creationDate));
        minDate.setMinutes(0);

        const getTierDelay = this.getTierDelay;
        const data = this.props.extendedAssignments && this.props.extendedAssignments.filter((item) => item.tier === tier).map((item) => {
            return ({
                ...item,
                key: item.reviewerCode
            });
        });

        return (
            <div className='tier'>
                <div className='row tier_header'>
                    <div className='col-md-2' style={{ paddingLeft: '0', paddingRight: '0' }}>
                        <span style={{ fontSize: '1.5em', fontWeight: 700, paddingLeft: '15px' }}>
                          {title}
                        </span>
                    </div>
                    <div className='col-md-6 form-horizontal'>
                        <label className="col-sm-2 control-label">Assign</label>
                        <div className="col-sm-10">
                            <DateTimePicker
                                min={minDate}
                                value={this.convertDateValue(tierActivationDate)}
                                onChange={this.onChangeAssignDate}
                                format='dddd, MMMM DD YYYY, hh:mm A'
                                editFormat='DD/MM/YYYY hh:mm A'
                                placeholder='Type date and time in format dd/mm/yyyy hh:mm AM/PM or select from calendar'
                                culture='en'
                            />
                        </div>
                    </div>
                    <div className='col-md-1'>
                        <button
                            className='btn btn-default'
                            disabled={this.getResetDisabled()}
                            onClick={this.resetDelay}>
                            Reset Delay
                        </button>
                    </div>
                    <div className='col-md-2 margin-left-5' style={{ textAlign: 'right', marginLeft: '15px' }}>
                        <span>{data.length ? getTierDelay() : <i>No Data</i>}</span>
                    </div>
                </div>
                <BootstrapTable
                    data={data}
                    options={options}
                    bodyContainerClass='tier_grid_overflow_visible'
                    headerContainerClass='tier_table_header with_padding'
                    expandComponent={this.expandComponent}
                    condensed
                    bordered={false}
                    trClassName={
                        () => {
                            return 'with_top_border'
                        }
                    }
                    expandableRow={() => true}>
                    <TableHeaderColumn
                        dataField='key'
                        isKey={true}
                        hidden>
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='reviewer'
                        dataFormat={this.reviewerFormatter}
                        tdStyle={{ paddingTop: '20px', paddingLeft: '15px' }}
                        expandable={false}
                        headerStyle={{ paddingLeft: '15px' }}>
                        Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='specialityNames'
                        tdStyle={{ paddingTop: '20px' }}
                        columnTitle={(row) => {
                            return row
                        }}
                        expandable={false}>
                        Specialties
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='maxBillableAmount'
                        tdStyle={{ paddingTop: '20px' }}
                        dataFormat={this.mbaFormatter}
                        expandable={false}>
                        MBA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='action'
                        columnClassName='overflow-visible'
                        dataFormat={this.statusFormatter}
                        tdStyle={{ paddingTop: '20px' }}
                        expandable={false}>Status</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='more'
                        dataFormat={this.moreFormatter}
                        tdStyle={{ paddingTop: '20px' }}
                        dataAlign='center'
                        expandable={false}/>
                    <TableHeaderColumn
                        dataField='action'
                        dataFormat={this.actionFormatter}
                        tdStyle={{ paddingTop: '20px' }}
                        columnClassName='overflow-visible'
                        dataAlign='center'
                        expandable={false}/>
                </BootstrapTable>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const case_assign = state.app.case_assign;
    return {
        extendedAssignments: case_assign.extendedAssignments,
        tiersActivationDates: case_assign.tiersActivationDates || {},
        originalTiersActivationDates: case_assign.originalTiersActivationDates || {},
        defaultActivationDates: case_assign.defaultActivationDates || {},
        loading: case_assign.loading,
        creationDate: state.app.cases.editedCase.creationDate,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveDropDownData: (button, next) => dispatch(saveDropDownData(button, next)),
        updateExtendedAssignmentsDetails: (code, data) => dispatch(updateExtendedAssignmentsDetails(code, data)),
        updateExtendedAssignmentsTier: (code, data) => dispatch(updateExtendedAssignmentsTier(code, data)),
        updateExtendedAssignments: (detailCode, action) => dispatch(updateExtendedAssignments(detailCode, action)),
        updateTiersDates: (tierDates) => dispatch(updateTiersDates(tierDates)),
        throwCaseError: (msg) => dispatch(throwCaseError(msg)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TierGridNew);
