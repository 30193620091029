import { createDatatableTemplateSlice } from 'components/Datatable/dataTableUtils';

const reviewerSlice = createDatatableTemplateSlice({
    name: 'reviewer',
    initialSettings: {
        sortBy: 'firstName',
    },
});

export const { loadData, dataLoaded, dataLoadError, changeSettings } = reviewerSlice.actions;

export default reviewerSlice;
