import React from 'react'
import { connect } from 'react-redux';
import { getSelectFieldValue } from '../../utils/helpers'
import { entityEditField, getOne, isEntityValid, setCurrentTab, updateEntity } from '../../redux/actions/BasicEntityActions'
import PatientGeneralInfo from "./PatientGeneralInfo";
import LoadingIndicator from '../../components/LoadingIndicator';
import PreventTransitionPrompt from '../../components/PreventTransitionPrompt';
import SimpleReactValidator from "utils/simple-react-validator";
import SystemMessage from '../../components/SystemMessage';

const tabToField = {
    'First Name': 'general',
    'Last Name': 'general',
    'Notification Email': 'general',
    'Phone Number': 'general',
    'Role': 'settings',
    'IP White List': 'settings',
};

class PatientEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            custom_email:
                {
                    message: 'The Notification email must be a valid email address',
                    rule: (val) => val === '' ||
                        // eslint-disable-next-line no-control-regex,max-len
                        (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/igm).test(val)
                },
            custom_date:
                {
                    message: "That doesn't look right. Please re-enter date of birth.",
                    rule: (val) => val <= new Date().getTime() && val >= new Date('12/31/1899').getTime()
                },
        });

        this.validator.showMessages();

        this.state = {
            errorMessages: Object.keys(this.validator.errorMessages).map((field) => ({
                id: field,
                field,
                message: this.validator.errorMessages[ field ],
                tab: tabToField[ field ]
            }))
        }

    }

    componentDidMount() {
        this.props.getUserProfile(this.props.match.params.id)
            .then(() => this.dispatchValidState())

    }

    handleSelect = (key) => {
        this.props.setCurrentTab(key)
    };

    dispatchValidState = () => {
        this.props.isAllValid(this.validator.allValid());
        this.setState({
            errorMessages: Object.keys(this.validator.errorMessages)
                .map((field) => ({
                    id: field,
                    field,
                    message: this.validator.errorMessages[ field ],
                    tab: tabToField[ field ]
                }))
        })
    };

    onSave = () => {
        return this.props.saveProfile({
            ...this.props.patient,
            gender: getSelectFieldValue(this.props.patient.gender),
            company: getSelectFieldValue(this.props.patient.company),
            state: getSelectFieldValue(this.props.patient.state),
            country: getSelectFieldValue(this.props.patient.country),
            birthDate: this.props.patient.birthDate,
        })
    };

    render() {
        const { isProfileChanged, editUserSuccess, isProfileValid, error, showValidMessages, isLoading, isEditable } = this.props;
        const { errorMessages } = this.state;

        return (
            <section className="content user-profile-content row">
                <div className='col-md-12'>
                    {isLoading && <LoadingIndicator type="form" top={'22px'} right={'30px'}/>}
                    <SystemMessage shown={editUserSuccess} type='success' message='Profile successfully updated'
                                   top='65px' style={{ marginLeft: '15px', marginRight: '15px' }}/>

                    <SystemMessage
                        style={{ marginLeft: '15px', marginRight: '15px' }}
                        shown={showValidMessages}
                        type='error' message={error}
                        validationMessages={errorMessages}
                        goToTab={this.handleSelect}
                        top='65px'
                    />

                    {/*{this.props.loading ? <LoadingIndicator height={50}/> : null}*/}
                    {/*{<LoadingIndicator height={50}/>}*/}
                    <PreventTransitionPrompt
                        when={isProfileChanged}
                        message='MyCustomDialogComponent'
                        isFormValid={isProfileValid}
                        entity='user'
                        saveChanges={this.onSave}
                        cancelAndDiscard={() => this.props.getUserProfile(this.props.match.params.id)}
                    />
                    <PatientGeneralInfo validator={this.validator} dispatchValidState={this.dispatchValidState} isEditable={isEditable}/>
                </div>
            </section>
        );
    }
}

const allowToEdit = (role) => (role === 'ROLE_ADMIN' || role === 'ROLE_CLIENT_ADMIN'
    || role === 'ROLE_CLIENT_USER' || role === 'ROLE_CLIENT_SUPERVISOR');

const mapStateToProps = (state) => {
    return {
        isEditable: allowToEdit(state.app.auth.user.roleCode) || state.app.users.my_permissions.includes('MODIFY_PATIENTS'),
        patient: state.app.patients.editedPatient,
        currentTab: state.app.patients.currentTab,
        isLoading: state.app.patients.loading,
        isProfileValid: state.app.patients.patientValid,
        showValidMessages: state.app.patients.showValidMessages,
        editUserSuccess: state.app.patients.editedPatientSuccess,
        isProfileChanged: state.app.patients.isProfileChanged,
        error: state.app.patients.editUserError,
    };
};

const mapDispatchToProps = dispatch => ({
    setCurrentTab: (tab) => dispatch(setCurrentTab(tab, 'patient')),
    getUserProfile: code => dispatch(getOne(code, 'patient')),
    userEditField: (field, value) => dispatch(entityEditField(field, value, 'patient')),
    isAllValid: (valid) => dispatch(isEntityValid(valid, 'patient')),
    saveProfile: data => dispatch(updateEntity('patient', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientEditPage)
