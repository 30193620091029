const defaultState = {
    loading: false,
    codes: [],
    error: '',
    lastLoadedTime: 0,
};

const billing = (state = defaultState, action) => {
    switch (action.type) {
        case 'FETCH_BILLING_LOADING':
            return {...state, loading: true};
        case 'FETCH_BILLING_CODES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                codes: action.payload,
                error: null,
                lastLoadedTime: Date.now(),
            });
        case 'FETCH_BILLING_ERROR':
            return {...state, error: action.payload, loading: false};
        default:
            return state
    }
};

export default billing
