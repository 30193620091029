import { createSlice } from '@reduxjs/toolkit';

const loginRedirectSlice = createSlice({
    name: 'loginRedirectSlice',
    initialState: {
        redirectTo: '/',
    },
    reducers: {
        setRedirectPathAfterLogin: (state, { payload }) => {
            state.redirectTo = payload;
        },
    },
});

export const { setRedirectPathAfterLogin } = loginRedirectSlice.actions;

export default loginRedirectSlice.reducer;
