import React from 'react'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import { getAll } from '../../redux/actions/BasicEntityActions'
import { getAllSpecialties } from '../../redux/actions/specialtyActions'
import { editField, getAllMdNotes, getAllMsNotes } from '../../redux/actions/companiesActions'
import ClientAddMCSystemPanel from './ClientAddMCSystemPanel';
import EditModal from '../EditModal';
import { closeEditModal, openEditModal } from '../../redux/action-creators/users'
import { getMultiSelectValue, getNamesFromCodes, isArrayConverge } from '../../utils/helpers'
import ClientEditProcedureForm from "./ClientEditProcedureForm";
import ClientEditDiagnoseForm from "./ClientEditDiagnoseForm";
import { onChangeSortConfiguration, resetSortConfiguration } from '../../redux/actions/gridActions'

class ClientMedicalCodingSystem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 10,
            page: 1
        };
        this.applyParams = this.applyParams.bind(this);
    }

    componentDidMount() {
        this.props.getAllProcedures(this.props.procedures);
        this.props.getAllDiagnoses(this.props.diagnoses);
        this.props.getAllScSystem(this.props.scSystem);
        this.props.getBodyParts(this.props.bodyParts);
        this.props.getAllSpecialties(this.props.specialties);
        this.props.getAllMsNotes(this.props.company.code);
        this.props.getAllMdNotes(this.props.company.code);
        this.props.closeEditModal();
    }

    applyParams(data) {
        data.splice(this.state.page * this.state.size)
            .splice(0, this.state.size);

        return data;
    }

    onProcedureClick = (item) => {
        console.log('onProcedureClick', item);
        this.setState({
            editedEntity: 'procedure',
            procedureCode: item
        });

        this.props.openEditModal();
    };

    onDiagnoseClick = (item) => {
        this.setState({
            editedEntity: 'diagnose',
            diagnoseCode: item
        });

        this.props.openEditModal()
    };

    onDiagnoseSelect = (row, isSelected) => {
        const { code } = row;
        const { selectedDiagnosesCodes } = this.props.company;
        const { editField } = this.props;

        if (!Array.isArray(selectedDiagnosesCodes)) {
            return editField('selectedDiagnosesCodes', [code])
        }

        if (isSelected) {
            editField('selectedDiagnosesCodes', [...selectedDiagnosesCodes, code])
        } else {
            editField('selectedDiagnosesCodes', selectedDiagnosesCodes.filter((item) => item !== code))
        }
    };

    onDiagnoseSelectAll = (isSelected) => {
        const { editField } = this.props;

        if (isSelected) {
            const data = this.props.diagnosesData.map((item) => item.code);
            editField('selectedDiagnosesCodes', data)
        } else editField('selectedDiagnosesCodes', [])
    };


    onProcedureSelect = (row, isSelected) => {
        const { code } = row;
        const { selectedProceduresCodes } = this.props.company;
        const { editField } = this.props;

        if (!Array.isArray(selectedProceduresCodes)) {
            return editField('selectedProceduresCodes', [code])
        }

        if (isSelected) {
            editField('selectedProceduresCodes', [...selectedProceduresCodes, code])
        } else {
            editField('selectedProceduresCodes', selectedProceduresCodes.filter((item) => item !== code))
        }
    };

    onProcedureSelectAll = (isSelected) => {
        const { editField } = this.props;

        if (isSelected) {
            const data = this.props.proceduresData.map((item) => item.code);
            editField('selectedProceduresCodes', data)
        } else editField('selectedProceduresCodes', [])
    };

    render() {
        let procedureCols = [
            {
                'isKey': true,
                'name': 'code',
                'title': 'Code',
                'sort': false,
                'hidden': true
            },
            {
                'name': 'name',
                'title': 'Service',
                'columnTitle': true,
                'width': '400',
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 }
            },
            {
                'name': 'specialtyCodes',
                'title': ' Specialty',
                'columnTitle': true,
                'width': '300',
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 }
            },
            {
                'name': 'bodyPartCodes',
                'title': 'Body Part',
                'columnTitle': true,
                'width': '300',
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 }
            },
            // {
            //     'name': 'medicalNecessity',
            //     'title': ' Medical Necessity',
            //     'columnTitle': true,
            //     'width': '200',
            //     'sort': true,
            //     'filter': {type: 'TextFilter', delay: 500}
            // },
            // {
            //     'name': 'medicalNecessityAlternative',
            //     'title': ' Medical Necessity Alternative',
            //     'columnTitle': true,
            //     'width': '250',
            //     'sort': true,
            //     'filter': {type: 'TextFilter', delay: 500}
            // },
            // {
            //     'name': 'noteReferences',
            //     'title': ' Note References',
            //     'columnTitle': true,
            //     'width': '200',
            //     'sort': true,
            //     'filter': {type: 'TextFilter', delay: 500}
            // },
            //  {
            //     'name': 'notes',
            //     'title': 'Notes',
            //     'columnTitle': true,
            //     'width': '200',
            //     'sort': true,
            //     'filter': {type: 'TextFilter', delay: 500}
            // },
            // {
            //     'name': 'active',
            //     'title': 'Status',
            //     'dataFormat': this.enumFormatter,
            //     'sort': true,
            //     'formatExtraData': {
            //         'false': 'Inactive',
            //         'true': 'Active'
            //     },
            //     'width': '200',
            //     'filter': { type: 'SelectFilter', options: {
            //             'false': 'Inactive',
            //             'true': 'Active'
            //         }}
            // },
            {
                'name': 'linked',
                'title': 'Linking',
                'dataFormat': (cell, row, enumObject) => {
                    return enumObject[ cell ]
                },
                'sort': true,
                'formatExtraData': {
                    'false': 'Unlinked',
                    'true': 'Linked'
                },
                'width': '200',
                'filter': {
                    type: 'SelectFilter',
                    options: {
                        'false': 'Unlinked',
                        'true': 'Linked',
                    },
                    defaultValue: 'true'
                }
            },
        ];

        let diagnosesCols = [
            {
                'isKey': true,
                'name': 'code',
                'title': 'Code',
                'sort': false,
                'hidden': true
            },
            {
                'name': 'name',
                'title': 'Diagnosis',
                'columnTitle': true,
                'width': '400',
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 }
            },
            {
                'name': 'specialtyCodes',
                'title': ' Specialty',
                'columnTitle': true,
                'width': '300',
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 }
            },
            {
                'name': 'bodyPartCodes',
                'title': 'Body Part',
                'columnTitle': true,
                'width': '300',
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 }
            },
            // {
            //     'name': 'medicalNecessity',
            //     'title': ' Medical Necessity',
            //     'columnTitle': true,
            //     'width': '200',
            //     'sort': true,
            //     'filter': {type: 'TextFilter', delay: 500}
            // },
            // {
            //     'name': 'medicalNecessityAlternative',
            //     'title': ' Medical Necessity Alternative',
            //     'columnTitle': true,
            //     'width': '250',
            //     'sort': true,
            //     'filter': {type: 'TextFilter', delay: 500}
            // },
            // {
            //     'name': 'noteReferences',
            //     'title': ' Note References',
            //     'columnTitle': true,
            //     'width': '200',
            //     'sort': true,
            //     'filter': {type: 'TextFilter', delay: 500}
            // },
            //  {
            //     'name': 'notes',
            //     'title': 'Notes',
            //     'columnTitle': true,
            //     'width': '200',
            //     'sort': true,
            //     'filter': {type: 'TextFilter', delay: 500}
            // },
            // {
            //     'name': 'active',
            //     'title': 'Status',
            //     'dataFormat': this.enumFormatter,
            //     'sort': true,
            //     'formatExtraData': {
            //         'false': 'Inactive',
            //         'true': 'Active'
            //     },
            //     'width': '200',
            //     'filter': { type: 'SelectFilter', options: {
            //             'false': 'Inactive',
            //             'true': 'Active'
            //         }}
            // },
            {
                'name': 'linked',
                'title': 'Linking',
                'dataFormat': (cell, row, enumObject) => {
                    return enumObject[ cell ]
                },
                'sort': true,
                'formatExtraData': {
                    'false': 'Unlinked',
                    'true': 'Linked',
                },
                'width': '200',
                'filter': {
                    type: 'SelectFilter',
                    options: {
                        'false': 'Unlinked',
                        'true': 'Linked',
                    },
                    defaultValue: 'true'
                }
            },
        ];

        const selectDiagnoseRow = {
            mode: 'checkbox',
            onSelect: this.onDiagnoseSelect,
            onSelectAll: this.onDiagnoseSelectAll,
            // selected: this.props.company.selectedPhysicianCodes
            // selected: ['test1']
        };

        const selectProcedureRow = {
            mode: 'checkbox',
            onSelect: this.onProcedureSelect,
            onSelectAll: this.onProcedureSelectAll,
            // selected: this.props.company.selectedPhysicianCodes
            // selected: ['test1']
        };

        const { clientService, clientDiagnosis, isEditable } = this.props;

        return (
            <div>
                <ClientAddMCSystemPanel
                    validator={this.props.validator}
                    dispatchValidStatus={this.props.dispatchValidStatus}
                    isEditable={isEditable}
                />
                <RemoteGrid
                    color="green"
                    entity="client_service"
                    title="Services"
                    data={this.applyParams(this.props.proceduresData)}
                    total={this.props.proceduresData.length}
                    columns={procedureCols}
                    pagination={true}
                    page={clientService.pagination.page}
                    sizePerPage={clientService.pagination.sizePerPage}
                    search={true}
                    onRowClick={this.onProcedureClick}
                    selectRow={isEditable && selectProcedureRow}
                    defaultSort
                    sortName={clientService.sorting.sortName}
                    sortOrder={clientService.sorting.sortOrder}
                />
                <RemoteGrid
                    color="green"
                    entity="client_diagnosis"
                    title="Diagnoses"
                    data={this.applyParams(this.props.diagnosesData)}
                    total={this.props.diagnosesData.length}
                    columns={diagnosesCols}
                    pagination={true}
                    page={clientDiagnosis.pagination.page}
                    sizePerPage={clientDiagnosis.pagination.sizePerPage}
                    search={true}
                    onRowClick={this.onDiagnoseClick}
                    selectRow={isEditable && selectDiagnoseRow}
                    defaultSort
                    sortName={clientDiagnosis.sorting.sortName}
                    sortOrder={clientDiagnosis.sorting.sortOrder}
                />
                {this.props.loading ? <LoadingIndicator height={50}/> : null}
                {/* TODO: egyik Modal beleír a másikba, hiába külön fülön vannak !!! */}
                <EditModal
                    H3Name='common.client.edit_procedure'
                >
                    {this.state.editedEntity === 'procedure' &&
                    <ClientEditProcedureForm procedureCode={this.state.procedureCode}/>}
                    {this.state.editedEntity === 'diagnose' &&
                    <ClientEditDiagnoseForm diagnoseCode={this.state.diagnoseCode}/>}
                </EditModal>
            </div>
        )
    }
}

const mapState = (state) => {
    const medicalCodingSystemCodes = getMultiSelectValue(state.app.companies.editedCompany.medicalCodingSystemCodes);

    //medical system codes that supports services
    const CodingSystemsWithServices = state.app.mcSystem.data
        .filter((item) => {
            return medicalCodingSystemCodes.indexOf(item.code) !== -1 &&
                item.service
        }).map((item) => item.code);

    //medical system codes that supports diagnosis
    const CodingSystemsWithDiagnosis = state.app.mcSystem.data
        .filter((item) => {
            return medicalCodingSystemCodes.indexOf(item.code) !== -1 &&
                item.diagnosis
        }).map((item) => item.code);

    return {
        proceduresData: state.app.procedures.data
            .filter((item) => item.active)
            .filter((item) => {

                const medicalCodeCodes = state.app.scSystem.data
                    .filter((item) => (
                        item.active &&
                        item.service &&
                        CodingSystemsWithServices.indexOf(item.medicalCodingSystemCode) !== -1)
                    )
                    .map((item) => item.code);

                return isArrayConverge(item.medicalCodingSystemCodes, medicalCodeCodes)
            })
            .map(item => {
                const { medicalProcedureCodes, msNotes } = state.app.companies.editedCompany;
                const notedProcedure = msNotes.find((notedItem) => notedItem.code === item.code);

                let linked = medicalProcedureCodes ? medicalProcedureCodes.indexOf(item.code) !== -1 : false;
                item = notedProcedure ? notedProcedure : item;

                return {
                    ...item,
                    specialtyCodes: getNamesFromCodes(item.specialtyCodes, state.app.specialties.data),
                    bodyPartCodes: getNamesFromCodes(item.bodyPartCodes, state.app.bodyParts.data),
                    linked
                }
            }),
        diagnosesData: state.app.diagnoses.data
            .filter((item) => item.active)
            .filter((item) => {
                const medicalCodeCodes = state.app.scSystem.data
                    .filter((item) => (
                        item.active &&
                        item.diagnosis &&
                        CodingSystemsWithDiagnosis.indexOf(item.medicalCodingSystemCode) !== -1)
                    )
                    .map((item) => item.code);

                return isArrayConverge(item.medicalCodingSystemCodes, medicalCodeCodes)
            })
            .map(item => {
                const { medicalDiagnosesCodes, mdNotes } = state.app.companies.editedCompany;
                const notedDiagnose = mdNotes.find((notedDiagnose) => notedDiagnose.code === item.code);

                let linked = medicalDiagnosesCodes ? medicalDiagnosesCodes.indexOf(item.code) !== -1 : false;
                item = notedDiagnose ? notedDiagnose : item;
                return {
                    ...item,
                    specialtyCodes: getNamesFromCodes(item.specialtyCodes, state.app.specialties.data),
                    bodyPartCodes: getNamesFromCodes(item.bodyPartCodes, state.app.bodyParts.data),
                    linked
                }
            }),
        company: state.app.companies.editedCompany,
        servicesGridConfiguration: state.app.companies.servicesGridConfiguration,
        diagnosisGridConfiguration: state.app.companies.diagnosisGridConfiguration,
        clientService: state.app.grid.clientService,
        clientDiagnosis: state.app.grid.clientDiagnosis,
        procedures: state.app.procedures,
        diagnoses: state.app.diagnoses,
        scSystem: state.app.scSystem,
        bodyParts: state.app.bodyParts,
        specialties: state.app.specialties,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllProcedures: (procedures) => dispatch(getAll('procedure', procedures)),
        getAllDiagnoses: (diagnoses) => dispatch(getAll('diagnosis', diagnoses)),
        getAllScSystem: (scSystem) => dispatch(getAll('sc_system', scSystem)),
        getAllMsNotes: (companyCode) => dispatch(getAllMsNotes(companyCode)),
        getAllMdNotes: (companyCode) => dispatch(getAllMdNotes(companyCode)),
        getBodyParts: (bodyParts) => dispatch(getAll('body_part', bodyParts)),
        getAllSpecialties: (specialties) => dispatch(getAllSpecialties(specialties)),
        editField: (field, value) => dispatch(editField(field, value)),
        openEditModal: () => dispatch(openEditModal()),
        closeEditModal: () => dispatch(closeEditModal()),
        onSortChange: (name, order, entity = 'COMPANY_SERVICES') => dispatch(onChangeSortConfiguration(name, order, entity)),
        resetSorting: (entity = 'COMPANY_SERVICES') => dispatch(resetSortConfiguration(entity))
    }
};

export default connect(mapState, mapDispatchToProps)(ClientMedicalCodingSystem);
