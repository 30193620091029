import React from 'react'
import { Link } from 'react-router-dom'

const FooterLeft = () => (
	<p>
		<strong>Copyright©<Link to='/'>MedRev</Link>.</strong> All rights reserved.
	</p>
)

export default FooterLeft
