import React from 'react'
import { connect } from 'react-redux'
import RemoteGrid from "../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../components/LoadingIndicator";
import { getAll } from "../../redux/actions/BasicEntityActions";
import PropTypes from "prop-types";

class BillingGroupList extends React.Component {

    componentDidMount() {
        this.props.getBillingGroups();
    }

    onRowClick = (item) => {
        this.props.history.push(`billing_groups/update/${item}`)
    };

    enumFormatter = (cell, row, enumObject) => {
        return enumObject[ cell ];
    };

    render() {
        let columns = [
            {
                'isKey': true,
                'name': 'name',
                'hidden': true,
            }, {
                'name': 'name',
                'title': 'Name',
                'sort': true,
                'width': '200',
                'filter': { type: 'TextFilter', delay: 500 },
                'headerAlign': 'left'
            },
            {
                'name': 'currency',
                'title': 'Currency',
                'sort': true,
                'dataAlign': 'left',
                'filter': { type: 'TextFilter', delay: 500 },

            }, {
                'name': 'tierCount',
                'title': 'Tiers',
                'sort': true,
                'filter': { type: 'TextFilter', delay: 500 },
                'headerAlign': 'left'
            }, {
                'name': 'active',
                'title': 'Status',
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': 'Inactive',
                    'true': 'Active'
                },
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': 'Inactive',
                        'true': 'Active'
                    }
                }
            }
        ];
        const { isLoading } = this.props;
        const { sorting, pagination } = this.props.gridConfiguration;
        return (
            <div className='container-fluid billing-group-list'>
                <div className="table-responsive" style={{ marginTop: '15px', "padding": '10px' }}>
                    {isLoading && <LoadingIndicator type="tabs"/>}
                    <RemoteGrid
                        color="blue"
                        title="Billing Groups"
                        entity="billing_group"
                        data={this.props.data}
                        total={this.props.totalElements}
                        columns={columns}
                        pagination={true}
                        page={pagination.page}
                        sizePerPage={pagination.sizePerPage}
                        search={true}
                        onRowClick={this.onRowClick}
                        defaultSort
                        sortName={sorting.sortName}
                        sortOrder={sorting.sortOrder}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        gridConfiguration: PropTypes.object,
    }
    static defaultProps = {
        isLoading: false
    };
}

const mapState = (state) => {
    const { billing_group, grid } = state.app;

    return {
        data: billing_group.data,
        totalElements: billing_group.data.length,
        isLoading: billing_group.loading,
        gridConfiguration: grid.billingGroup,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getBillingGroups: () => dispatch(getAll('billing_group')),
    }
};

export default connect(mapState, mapDispatchToProps)(BillingGroupList);
