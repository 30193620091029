import React, { Fragment, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import useForm from '../../components/Form/useForm';
import Control from '../../components/Form/Control';
import Form from '../../components/Form/Form';
import SystemMessage from 'components/SystemMessage';
import PropTypes from 'prop-types';
import caseFileTemplateSlice from '../../redux/pages/case-file-template/caseFileTemplateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompaniesIfEmpty } from '../../redux/actions/companiesActions';
import ControlSelector from '../../components/Controls/ControlSelector';
import ControlCheckbox from '../../components/Controls/ControlCheckbox';
import ControlDropZone from '../../components/Controls/ControlDropZone';
import { ACCEPTED_MIME_TYPES } from 'constants/mimeTypes';

export const FORM_KEY = 'caseFileTemplates';

export const FORM_TYPES = {
  new: 'new',
  edit: 'edit',
};

const CaseFileTemplateForm = React.forwardRef(
  ({ children, className, onSubmit, type, leftColumnWrapper: LeftColumnWrapper, rightColumnWrapper: RightColumnWrapper }, ref) => {
    const dispatch = useDispatch();
    const formProps = useForm(caseFileTemplateSlice.actions, FORM_KEY);
    const { mappedErrorMessages, isLoading, serverErrors } = formProps;

    const { data: companies, loading: clientsLoading } = useSelector((state) => state.app.companies);
    const { active, templateName } = useSelector((state) => state.app.caseFileTemplates.entity);

    useEffect(() => {
      dispatch(getAllCompaniesIfEmpty());
    }, [dispatch]);

    const clients = companies
      .filter((item) => item.active)
      .map(({ fullName, code }) => ({
        label: fullName,
        value: code,
      }));

    return (
      <Form
        ref={ref}
        className={className}
        onSubmit={(...params) => {
          onSubmit(formProps.entity, ...params);
        }}
        formKey={FORM_KEY}
        {...formProps}
      >
        <div style={{ position: 'relative' }}>
          <SystemMessage
            type="error"
            validationMessages={[...mappedErrorMessages, ...serverErrors]}
            shown={!isLoading}
            preventAddClasses
            style={type === FORM_TYPES.edit && { marginLeft: '15px', marginRight: '15px' }}
          />

          <Col md={7}>
            <LeftColumnWrapper>
              <Row>
                <Control md={12} label="Name" name="name" disabled={isLoading} required container={Fragment} />
              </Row>
              <Row>
                {type === FORM_TYPES.new && (
                  <Control
                    className="caseFileTemplateForm__control-selector"
                    md={12}
                    component={ControlSelector}
                    label="Client"
                    name="clients"
                    disabled={isLoading}
                    isLoading={clientsLoading}
                    options={clients}
                    isMulti
                  />
                )}
                {type === FORM_TYPES.edit && (
                  <Control md={1} component={ControlCheckbox} label="Active" name="active" disabled={isLoading} checked={active} container={Fragment} />
                )}
              </Row>
            </LeftColumnWrapper>
          </Col>
          <Col md={5}>
            <RightColumnWrapper>
              <Control
                md={12}
                label="Upload Document"
                name="template"
                accept={ACCEPTED_MIME_TYPES.documents}
                component={ControlDropZone}
                fileName={templateName}
                hideLabel={type === FORM_TYPES.edit}
                required={type === FORM_TYPES.new}
                container={Fragment}
              />
              {type === FORM_TYPES.edit && (
                <Col md={12}>
                  <div className="alert alert-warning">
                    <i className="fa fa-warning" /> Uploading a new Case File Template docx file <strong> will overwrite</strong> the previously
                    stored file.
                  </div>
                </Col>
              )}
            </RightColumnWrapper>
          </Col>
          {children}
        </div>
      </Form>
    );
  },
);

const DefaultColumnWrapper = ({ children }) => {
  return <>{children}</>;
};

DefaultColumnWrapper.propTypes = {
  children: PropTypes.any,
};

CaseFileTemplateForm.displayName = 'CaseFileTemplateForm';
CaseFileTemplateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.any,
  type: PropTypes.oneOf(['new', 'edit']).isRequired,
  className: PropTypes.string,
  leftColumnWrapper: PropTypes.elementType,
  rightColumnWrapper: PropTypes.elementType,
};

CaseFileTemplateForm.defaultProps = {
  className: '',
  leftColumnWrapper: DefaultColumnWrapper,
  rightColumnWrapper: DefaultColumnWrapper,
};

export default CaseFileTemplateForm;
