import grid from './grid'

const defaultGridListState = {
	grids: [] //array of grid objects
}

const page = (state = defaultGridListState, action) => {

	switch (action.type) {
		case 'LOAD_PAGE_LOADING':
			return Object.assign({}, state, {
				loading: true
			})
		case 'LOAD_PAGE_SUCCESS':
			return Object.assign({}, state, {
				grids: action.grids.map(gr => grid(undefined, {
					type: action.type,
					...gr,
				})), //grids array
				loading: false,
				error: null
			})
		case 'LOAD_PAGE_ERROR':
			return Object.assign({}, state, {
				loading: false,
				error: action.error
			})
		default:
			if (action.index !== undefined) {
				var gridIndex = 0
				const prevGrid = state.grids.find((g, i) => {
					if (g.index === action.index) {
						gridIndex = i
						return true
					}
					return false
				})
				return Object.assign({}, state, {
					grids: [
						...state.grids.slice(0, gridIndex), //index idva ot loadedGrid(grid, index) v action-creators/page
						grid(prevGrid, action),
						...state.grids.slice(gridIndex + 1)
					]
				})
			}
	}
	return state
}

export default page
