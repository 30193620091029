import React from 'react'
import RemoteGrid from "../../../components/Grid/RemoteGrid";
import LoadingIndicator from "../../../components/LoadingIndicator";
import connect from "react-redux/es/connect/connect";
import {getAll} from '../../../redux/actions/BasicEntityActions'
import Modal from "react-modal";
import PropTypes from "prop-types";

class CountriesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mcSystem: '',
        };
        this.onRowClick = this.onRowClick.bind(this);
        this.enumFormatter = this.enumFormatter.bind(this);
    }

    componentDidMount() {
        Modal.setAppElement('body');
        this.props.getAllCountries();
    }

    onRowClick(item) {
        this.setState({item});
        this.props.history.push(`/countries/${item}`)
    }

    enumFormatter = (cell, row, enumObject) => {
        return enumObject[cell];
    };

    render() {
        let cols = [
            {
                'name': 'code',
                'hidden': true
            }, {
                'name': 'name',
                'title': this.context.translate('common.form.name'),
                'columnTitle': true,
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'isKey': true,
                'name': 'code',
                'title': this.context.translate('common.form.country_code'),
                'sort': true,
                'filter': {type: 'TextFilter', delay: 500}
            }, {
                'name': 'active',
                'title': this.context.translate('common.form.status'),
                'dataFormat': this.enumFormatter,
                'sort': true,
                'formatExtraData': {
                    'false': this.context.translate('common.form.inactive'),
                    'true': this.context.translate('common.form.active'),
                },
                'width': '200',
                'filter': {
                    type: 'SelectFilter', defaultValue: 'true', options: {
                        'false': this.context.translate('common.form.inactive'),
                        'true': this.context.translate('common.form.active'),
                    }
                }
            }
        ];
        const {isLoading, countries} = this.props;
        const {sorting, pagination} = this.props.gridConfiguration;
        return (
            <div className='container-fluid'>
                <div className="table-responsive" style={{marginTop: '15px', "padding": '10px'}}>
                    {isLoading && <LoadingIndicator type="lister"/>}
                    <RemoteGrid
                        color="green"
                        entity="country"
                        title={this.context.translate('common.form.countries')}
                        data={countries}
                        total={countries.length}
                        columns={cols}
                        pagination={true}
                        page={pagination.page}
                        sizePerPage={pagination.sizePerPage}
                        search={true}
                        onRowClick={this.onRowClick}
                        defaultSort
                        sortName={sorting.sortName}
                        sortOrder={sorting.sortOrder}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        )
    }

    static propTypes = {
        isLoading: PropTypes.bool,
        gridConfiguration: PropTypes.object,
        countries: PropTypes.array,
    }
    static defaultProps = {
        isLoading: false
    };
}

CountriesList.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state) => {
    return {
        countries: state.app.countries.all,
        gridConfiguration: state.app.grid.country,
        isLoading: state.app.countries.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllCountries: () => dispatch(getAll('country')),
    }
};

export default connect(mapState, mapDispatchToProps)(CountriesList);
