import { createApiParams } from 'components/Datatable/dataTableUtils';
import { combineEpics } from 'redux-observable';
import { fetchEntityList$ } from '../../../epicUtils/commonEpics';
import clientEditMedicalServicesDiagnosesSlice from "./clientEditMedicalServicesDiagnosesSlice";
import { transformClientEditServicesDiagnosis } from "./utils";
import { getValidMedicalCodingSystemCodesArray } from "../utils";

const clientEditMedicalServicesDiagnosesEpic = fetchEntityList$({
    ...clientEditMedicalServicesDiagnosesSlice.actions,
    resolveUrl: ({ code, medicalCodingSystemCodes }) => {
        const arr = getValidMedicalCodingSystemCodesArray(medicalCodingSystemCodes);
        if (arr.length > 0) {
            return `client_medical_service/diagnosis/page?code=${code}&medicalCodingSystemCodes=${arr}`
        } else {
            return `client_medical_service/diagnosis/page?code=${code}`
        }
    },
    resolveParams: (params) => [{ params: createApiParams(params) }],
    dataTransformer: transformClientEditServicesDiagnosis,
    //dataTransformer: () => mockDiagnoses,
});

export default combineEpics(clientEditMedicalServicesDiagnosesEpic);
