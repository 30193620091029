import React from 'react';
import { connect } from 'react-redux';
import shortId from 'shortid';
import '../../components/ModalWin.css';
import { createNewUser } from '../../redux/action-creators/users';
import { isStaff } from '../../utils/helpers';
import FormField from '../../components/FormField';
import FormValidateWrapper from '../../containers/FormValidateWrapper';
import { getAll } from 'redux/actions/BasicEntityActions';

class StaffCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            active: true,
            code: null,
            email: '',
            firstName: '',
            lastName: '',
            middleName: '',
            phoneNumber: '',
            profileCode: '',
            roleCode: null,
            timezone: '',
        };

        this.state = this.initialState;

        this.createUser = this.createUser.bind(this);

        this.onChange = this.onChange.bind(this);
        this.changeRole = this.changeRole.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.onChangeTimeZones = this.onChangeTimeZones.bind(this);
    }

    componentDidMount() {
        this.props.dispatchValidState();
        this.props.getAllTimeZones(this.props.timezones);
    }

    changeRole(roleCode) {
        this.setState({ roleCode: roleCode });
    }

    onChangeCheckBox(e) {
        // const newValue = e.target.value === 'true' ? false : true
        this.setState({ [e.target.name]: e.target.checked });
    }

    createUser(e) {
        e.preventDefault();
        if (this.props.validator.allValid()) {
            this.props.signUp({
                ...this.state,
                code: shortId.generate(),
                roleCode: this.state.roleCode.value,
                timeZoneCode: this.state.timezone.value,
                email: this.state.email.trim(),
            });
        } else {
            this.props.showMessages();
        }
    }

    onChange(e) {
        if(e.target.name.toLowerCase().includes('email'))
            this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, '') });
        else
            this.setState({ [e.target.name]: e.target.value });
    }

    onChangeTimeZones(timeZone) {
        this.setState({ timezone: timeZone });
    }

    render() {
        console.log(this.props.validator)
        return (
            <form className="modal-content custom-modal-content" noValidate autoComplete="off" ref="form">
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            label="First Name"
                            id="First Name"
                            className="form-control"
                            required
                            name="firstName"
                            valids="required|alpha_dash_space"
                            validator={this.props.validator}
                            value={this.state.firstName}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            label="Last Name"
                            id="Last Name"
                            className="form-control"
                            required
                            name="lastName"
                            valids="required|alpha_dash_space"
                            validator={this.props.validator}
                            value={this.state.lastName}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type="select"
                            label="Role"
                            id="Role"
                            className="text-bg required-field"
                            required
                            searchable
                            disabled={false}
                            multi={false}
                            options={this.props.roles}
                            name="roleCode"
                            valids="required"
                            validator={this.props.validator}
                            value={this.state.roleCode}
                            onChange={this.changeRole}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            icon="envelope-o"
                            type="input-icon"
                            required
                            label="Email"
                            id="Email"
                            className="form-control"
                            name="email"
                            valids="required|email|unique"
                            validator={this.props.validator}
                            value={this.state.email}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type="input-icon"
                            icon="phone"
                            label="Phone Number"
                            className="form-control"
                            required
                            id="Phone Number"
                            name="phoneNumber"
                            valids="required"
                            validator={this.props.validator}
                            value={this.state.phoneNumber}
                            onChange={this.onChange}
                            onBlur={this.props.dispatchValidState}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            id="Time Zone"
                            type="select"
                            label="Time Zone"
                            className="text-bg required-field"
                            required
                            valids="required"
                            validator={this.props.validator}
                            searchable={true}
                            multi={false}
                            disabled={false}
                            options={this.props.timezones}
                            name="timeZoneCode"
                            value={this.state.timezone}
                            onChange={this.onChangeTimeZones}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <FormField
                            md="12"
                            type="checkbox"
                            title="Status"
                            label="Active"
                            name="active"
                            value={this.state.active}
                            checked={this.state.active}
                            onChange={this.onChangeCheckBox}
                            className="checkbox"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right text-right">
                            <button className="btn btn-flat btn-primary" disabled={this.props.isLoading} onClick={this.createUser}>
                                Add Staff
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    const users_emails = state.app.users.users.map((item) => {
        return item.email;
    });

    return {
        isLoading: state.app.users.loading,
        error: state.app.users.error,
        roles: state.app.users.roles
            .filter((item) => isStaff(item.code) && item.active)
            .map((role) => ({
                value: role.code,
                label: role.name.toLowerCase(),
            })),
        customRules: {
            unique: {
                message: 'User with this email address is existing.',
                rule: function (val) {
                    return !users_emails.includes(val);
                },
            },
        },
        top: '0em',
        timezones: state.app.timezone.data
            .filter((item) => item.active)
            .map((zone) => ({
                value: zone.code,
                label: zone.name,
            })),
    };
};
//
const mapDispatchToProps = (dispatch) => ({
    getAllTimeZones: (timezone) => dispatch(getAll('time_zone', timezone)),
    signUp: (user, userType = 'staff') => {
        dispatch(createNewUser(user, userType));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormValidateWrapper(StaffCreateForm));
