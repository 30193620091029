import React from 'react';
import FormField from '../../components/FormField';
import Modal from 'react-modal';

export default class ValidationBlock extends React.Component {
    constructor(props) {
        super();
        this.initialState = {...props.validRule}
        this.state = this.initialState;
    }

    // TODO: deprecated react method
    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.validRule) !== JSON.stringify(nextProps.validRule)) {
            if (!nextProps.validRule.code) {
                this.setState(this.initialState)
            } else {
                const {active, code, logic, text, hardValidation} = nextProps.validRule;
                // console.group();
                // console.log(code);
                // console.log(hardValidation);
                // console.groupEnd();
                this.setState({active, code, logic, text, hardValidation})
            }
        }
    }

    onChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value})
    }

    onChangeText = (e) => {
        const {value} = e.target;
        this.setState({text: {'EN': value}})
    }

    onChangeCheckBox = (e) => {
        this.setState({
            hardValidation: e.target.name === 'hard' + this.state.code
        }, () => {
            this.onBlur()
        })
    }

    onBlur = (e) => {
        const {active, code, logic, text, hardValidation} = this.state;
        this.props.onChange({
            active,
            code,
            logic,
            text,
            hardValidation,
        })
    }

    deleteRule = () => {
        const {logic, text} = this.state;

        if (!logic && !text['EN']) {
            this.props.onDelete(this.props.validRule.code)
        } else {
            this.handleOpenModal()
        }
    }

    handleOpenModal = () => this.setState({isOpen: true})
    handleCloseModal = () => this.setState({isOpen: false})

    render() {
        const {logic, text, hardValidation, code} = this.state;
        const {disabled, onDelete, isLast} = this.props;

        return (
            <React.Fragment>
                <div className={`form-group ${!isLast && 'bordered'}`}>
                    <div className="form-group">
                        <div className="row">
                            <div className='col-md-10' style={{position: 'relative'}} title="Remove validation rule">
                                <i className='fa fa-times pull-right close'
                                   style={{right: '-20px', top: '-10px', cursor: disabled ? 'not-allowed' : 'pointer'}}
                                   onClick={() => !disabled && this.deleteRule()}></i>
                            </div>
                        </div>
                        <div className="row">
                            <FormField
                                md="10"
                                style={{marginBottom: '15px'}}
                                labelLeft
                                label="Validation Logic"
                                className="form-control margin-bottom-15"
                                name="logic"
                                disabled={disabled}
                                value={logic}
                                onChange={this.onChange}
                                onBlur={this.onBlur}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <FormField
                                md="10"
                                style={{marginBottom: '15px'}}
                                labelLeft
                                label="Validation Text"
                                className="form-control margin-bottom-15"
                                name="text"
                                disabled={disabled}
                                value={text ? text['EN'] : ''}
                                onChange={this.onChangeText}
                                onBlur={this.onBlur}
                            />
                        </div>
                    </div>
                    <div className="form-group col-md-10 text-right">
                        <div className="radio-inline">
                            <label style={{fontWeight: 500}}><input
                                type="radio"
                                name={"hard" + code}
                                disabled={disabled}
                                onChange={this.onChangeCheckBox}
                                checked={!!hardValidation}
                            />Hard</label>
                        </div>
                        <div className="radio-inline">
                            <label style={{fontWeight: 500}}>
                                <input
                                    type="radio"
                                    name={'soft' + code}
                                    disabled={disabled}
                                    onChange={this.onChangeCheckBox}
                                    checked={!hardValidation}
                                />Soft
                            </label>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.isOpen}
                    className="custom-modal modal-dialog"
                    overlayClassName="modal-overlay"
                >
                    <h3 className="modal-title text-center">Do you realy want to delete validation rule?</h3>
                    <div className='modal-content custom-modal-content text-center'>
                        <div>
                            <button
                                className='btn btn-grey margin-right-5 margin-top-5'
                                onClick={() => onDelete(code)}
                            >
                                Yes
                            </button>
                            <button
                                className='btn btn-grey margin-left-5 margin-top-5'
                                onClick={this.handleCloseModal}>
                                No
                            </button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
