import React from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import CaseCommentsAvatar from './CaseCommentsAvatar';
import PropTypes from 'prop-types';
import history from '../../../../redux/history';


const CaseCommentsThread = ({ ...props }) => {
  return (
    <Row key={props.code + '_row'}>
      <Col md={10}>
        <div className="case-comment-item">
          <div>
            <CaseCommentsAvatar user={props}/>
          </div>
          <div>
            <span className="case-comment-name">
              {props.firstName + ' ' + props.lastName}
            </span>
            <span className="case-comment-date">
              {moment(props.updated).utc(false).format('MMMM DD, YYYY HH:mm:ss')}
            </span>
            {props.commentThreadType === 'LINK' ? (
              <p className="case-comment-p">
                {props.message}
                <a onClick={(e) => handleLinkClick(props.urlForThread, e)}>{props.clickablePart}</a>
              </p>
            ) : (
              <p className="case-comment-p">
                {props.message}
              </p>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

const handleLinkClick = (url, e) => {
  history.push(url)
  window.location.reload();
};

CaseCommentsThread.propTypes = {
  code: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  updated: PropTypes.number,
  message: PropTypes.string,
};

CaseCommentsThread.defaultProps = {};

export default CaseCommentsThread;
