import React from 'react';
import {connect} from 'react-redux';
import CreateModal from '../CreateModal';
import FormField from '../../components/FormField'
import {editField} from '../../redux/actions/cases/caseTypesActions'

const AddPatient = ({patient, setPatient}) => (
    <div className='container-fluid'>
        <div className='row' style={{
            marginTop: '15px',
            marginBottom: '30px',
            background: '#fff',
            padding: '10px',
            paddingBottom: '20px'
        }}>
            <FormField
                md="6"
                type="REF_PATIENT"
                label="Patients"
                className="text-bg "
                value={patient}
                onChange={(patient) => setPatient(undefined, patient)}
            />
            <div className='col-md-6 col-xs-12 text-right'>
                <CreateModal entityType='patient' color='blue' icon='fa-user'> </CreateModal>
            </div>
        </div>
    </div>
);


const mapStateToProps = (state) => ({
    patient: state.app.case_types.currentCase.patient,
});

const mapDispatchToProps = dispatch => ({
    setPatient: (field = 'patient', value) => dispatch(editField(field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPatient)
