import React, { Component } from 'react';
import { connect } from 'react-redux';
import Box from '../../components/Box';
import BoxHeader from '../../components/BoxHeader';
import BoxBody from '../../components/BoxBody';
import BoxFooter from '../../components/BoxFooter';
import FormField from '../../components/FormField';
import { editUser, userEditField, isAllValid } from '../../redux/action-creators/users';
import { currencyToIcon } from '../../utils/Constants';
import { getSelectFieldValue } from '../../utils/helpers';

class ReviewerEditProfessionalInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstLoad: true,
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeHourly = this.onChangeHourly.bind(this);
        this.getCurrencyIcon = this.getCurrencyIcon.bind(this);
        this.onChangeReviewerCurrencyCode = this.onChangeReviewerCurrencyCode.bind(this);
    }

    onChange(e) {
        this.props.userEditField(e.target.name, e.target.value);
    }

    onChangeHourly(e) {
        this.props.userEditField(e.target.name, e.target.value);
    }

    onChangeReviewerCurrencyCode(reviewerCurrencyCode) {
        this.props.userEditField('reviewerCurrencyCode', reviewerCurrencyCode);
    }

    getCurrencyIcon() {
        const { reviewerCurrencyCode } = this.props.profile;
        if (!reviewerCurrencyCode) return currencyToIcon['ENY'];
        if (typeof reviewerCurrencyCode === 'string') return currencyToIcon[reviewerCurrencyCode];
        if (typeof reviewerCurrencyCode === 'object') return currencyToIcon[reviewerCurrencyCode.value];
    }

    render() {
        return (
            <Box color="blue" classes="box-profile-first-row-height-show">
                <BoxHeader title={`Reviewer - ${this.props.profile.firstName} ${this.props.profile.lastName} `} color="blue" icon="fa-credit-card" />
                <BoxBody>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group bordered">
                            <div className="row">
                                <FormField
                                    md="2"
                                    type="select"
                                    label="Reviewer Currency"
                                    id="Reviewer Currency"
                                    className="text-bg required-field"
                                    searchable
                                    disabled={false}
                                    options={this.props.currencies}
                                    name="reviewerCurrencyCode"
                                    valids="required"
                                    validator={this.props.validator}
                                    value={this.props.profile.reviewerCurrencyCode}
                                    onChange={this.onChangeReviewerCurrencyCode}
                                    multi={false}
                                    onBlur={this.props.dispatchValidStatus}
                                />
                                <FormField
                                    md="2"
                                    type="input-icon"
                                    icon={this.getCurrencyIcon()}
                                    text="Kr"
                                    label="Hourly Rate"
                                    id="Hourly Rate"
                                    className="form-control"
                                    required
                                    divClasses="show"
                                    name="hrlyRate"
                                    valids="required|decimal"
                                    validator={this.props.validator}
                                    value={this.props.profile.hrlyRate}
                                    onChange={this.onChangeHourly}
                                    onBlur={() => {
                                        this.props.isAllValid(this.props.validator.allValid());
                                    }}
                                />
                                <FormField
                                    md="2"
                                    label="Reviewer Flat Margin"
                                    id="Reviewer Flat Margin"
                                    type="input-icon"
                                    icon="percent"
                                    className="form-control"
                                    required
                                    divClasses="show "
                                    name="reviewerFlatMargin"
                                    valids="required|integer"
                                    validator={this.props.validator}
                                    value={this.props.profile.reviewerFlatMargin}
                                    onChange={this.onChange}
                                    onBlur={this.props.dispatchValidStatus}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <FormField
                                    md="4"
                                    xs="6"
                                    label="IBAN"
                                    className="form-control"
                                    id="user_edit_reviewer_iban"
                                    divClasses="show"
                                    name="iban"
                                    value={this.props.profile.iban}
                                    onChange={this.onChange}
                                />
                                <FormField
                                    md="4"
                                    xs="6"
                                    label="BIC Number"
                                    className="form-control"
                                    id="user_edit_reviewer_bicNumber"
                                    divClasses="show"
                                    name="bicnum"
                                    value={this.props.profile.bicnum}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <FormField
                                    md="4"
                                    xs="6"
                                    label="Routing Number"
                                    className="form-control"
                                    text="Only for US Reviewers"
                                    id="user_edit_reviewer_routingNumber"
                                    divClasses="hide"
                                    name="routingNum"
                                    disabled={'' + getSelectFieldValue(this.props.profile.countryCode) !== 'US'}
                                    title="This field is to be filled to USA users only"
                                    value={this.props.profile.routingNum}
                                    onChange={this.onChange}
                                />
                                <FormField
                                    md="4"
                                    xs="6"
                                    label="Account Number"
                                    text="Only for US Reviewers"
                                    className="form-control h"
                                    divClasses="hide"
                                    disabled={'' + getSelectFieldValue(this.props.profile.countryCode) !== 'US'}
                                    title="This field is to be filled to USA users only"
                                    name="accountNum"
                                    value={this.props.profile.accountNum}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </form>
                </BoxBody>
                <BoxFooter></BoxFooter>
            </Box>
        );
    }
}

// currencyToIcon[this.props.profile.reviewerCurrencyCode ? this.props.profile.reviewerCurrencyCode.value : this.props.profile.reviewerCurrencyCode ]
const mapStateToProps = (state) => {
    return {
        // profile: state.app.users.users.filter(usr => usr.code == getParamFromUrl(state.router.location.pathname))[0],
        profile: state.app.users.editedUser,
        isLoading: state.app.users.loading,
        error: state.app.users.editUserError,
        currencies: state.app.currencies.data
            .filter((item) => item.active)
            .map((currency) => ({
                value: currency.code,
                label: currency.fullName,
            })),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveProfile: (data) => dispatch(editUser(data, 'profile')),
        userEditField: (field, value) => dispatch(userEditField(field, value)),
        isAllValid: (valid) => dispatch(isAllValid(valid)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewerEditProfessionalInfo);
