import React, {Component} from "react";
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {firstLogin, firstLoginNext} from '../../redux/actions/authActions'

class FirstLogin extends Component {
    constructor(props) {
        super(props);
        this.props.verifyCode(props.match.params.key);
        this.state = {
            error: '',
            toLogin: false,
            firstLoginKey: props.match.params.key,
            //oldPassword: '',
            newPassword: '',
            repeatNewPassword: '',
            isLoading: false,
            isError: false
        };
        // if (1) {
        //     this.setState({ redirect: true });
        // }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    isValid = function () {
        return this.state.error === '';
    };

    onSubmit(e) {
        e.preventDefault();
        if (this.isValid()) {
            this.setState({errors: {}, isLoading: true});
            this.setState({mfaQrCodeLink: this.props.mfaQrCodeLink, mfaSecretKey: this.props.mfaSecretKey});
            this.props.firstLoginNext({
                firstLoginKey: this.state.firstLoginKey,
                newPassword: this.state.newPassword,
                repeatNewPassword: this.state.repeatNewPassword
            }).then(
                () => {
                    this.setState({toLogin: true});
                    this.props.history.push(`/login`)
                },
                (err) => {
                    //this.props.verifyCode(this.state.firstLoginKey);
                    this.setState({errors: err, isLoading: false, isError: true})
                }
            );
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
        if (document.getElementById('password').value !== document.getElementById('repeatNewPassword').value) {
            this.setState({error: 'new passwords must be equal'});
        } else {
            this.setState({error: ''});
        }
    }

    render() {
        if (this.state.toLogin === true) {
            return <Redirect to='/login'/>
        }
        const {isLoading} = this.state;
        const stateError = this.state.error;
        const active = this.props.active;
        const mfaQrCodeLink = this.props.mfaQrCodeLink ? this.props.mfaQrCodeLink : this.state.mfaQrCodeLink;
        const mfaSecretKey = this.props.mfaSecretKey ? this.props.mfaSecretKey : this.state.mfaSecretKey;

        if (active === false) {
            return <Redirect to='/login'/>
        }
        return (
            <div>
                <p className="login-box-msg" style={{paddingLeft: 0, paddingRight: 0}}>
                    {this.props.error ?
                        <span className="text-danger">{this.props.error} </span>
                        :
                        (
                            stateError !== '' ??
                                <span className="text-danger">{stateError} </span>
                        )
                    }
                </p>

                <p style={{display: 'flex'}}>
                    <span className="stepIndicator">
                        1
                    </span>
                    <span className="stepText">
                        Set up 2-factor authentication
                    </span>
                </p>

                <form onSubmit={this.onSubmit} className='wide-form'>
                    <div className="form-group has-feedback">
                        <p style={{color: 'red', wordBreak: 'normal'}}>You must install Google Authenticator on your
                            smartphone before you continue, otherwise you won't be able to login later.</p>
                        <p>For installing use this QR-code</p>
                        {
                            mfaQrCodeLink ?
                                <img src={mfaQrCodeLink} alt={mfaQrCodeLink}/>
                                : ''
                        }
                        <p>Or you can use the next code (if installing manually):</p>
                        <p><strong>{mfaSecretKey}</strong></p>
                        <p style={{color: 'red'}}>Keep this code in a safe place,
                            it can be useful if you lose your Google Authenticator application</p>
                    </div>

                    <p style={{display: 'flex'}}>
                        <span className="stepIndicator">
                            2
                        </span> 
                        <span className="stepText">
                            Create a secure password
                        </span>
                    </p>

                    <p className={'text-justify'} style={{fontWeight: "bold"}}>
                        Now that 2-factor authentication is set up, please create a password.
                    </p>

                    <p className={'text-justify'}>
                        Password must contain at least a capital letter, a digit and a special character and be at least 8&nbsp;characters long.
                    </p>
                    <div className="form-group has-feedback">
                        <input
                            id="password"
                            type="password"
                            className="form-control"
                            name="newPassword"
                            required="required"
                            placeholder="New Password"
                            autoComplete="off"
                            onChange={this.onChange}
                        />
                        <span className="glyphicon glyphicon-lock form-control-feedback" style={{top: '0'}}/>
                    </div>
                    <div className="form-group has-feedback">
                        <input
                            id="repeatNewPassword"
                            type="password"
                            className="form-control"
                            name="repeatNewPassword"
                            required="required"
                            placeholder="Confirm New Password"
                            autoComplete="off"
                            onChange={this.onChange}
                        />
                        <span className="glyphicon glyphicon-lock form-control-feedback" style={{top: '0'}}/>
                    </div>

                    <p style={{display: 'flex'}}>
                        <span className="stepIndicator">
                            3
                        </span> 
                        <span className="stepText">
                            Verify 2-factor authentication
                        </span>
                    </p>

                    <p style={{color: 'red', wordBreak: 'normal'}}>
                        Before proceeding, make sure you can see 6-digit codes appearing in Google Authenticator for your MedRev account as this step will not be repeatable once you have set a password for your account.
                    </p>

                    <div className="row no-padding">
                        <div className="col-xs-4 pull-right">
                            <button
                                disabled={isLoading}
                                className="btn btn-primary btn-block btn-flat">Next
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapState = (state) => {
    return {
        res: state.app.auth.res,
        active: state.app.auth.active,
        mfaQrCodeLink: state.app.auth.mfaQrCodeLink,
        mfaSecretKey: state.app.auth.mfaSecretKey,
        error: state.app.auth.error
    }
};

const mapActions = dispatch => {
    return {
        verifyCode: is_correct_code => dispatch(firstLogin(is_correct_code)),
        firstLoginNext: data => dispatch(firstLoginNext(data))
    }
};

export default connect(mapState, mapActions)(FirstLogin);


/*width: 30px;
height: 30px;
border-radius: 50%;
font-size: 21px;
color: #fff;
text-align: center;
background: #000;
display: block;*/
