import api from "../../../utils/api";
import clientEditFacilitySlice, { linkingEntities, linkingEntitiesError, linkingEntitiesSuccess, unlinkingEntities } from "./clientEditFacilitySlice";

export const linkClientEditFacility = (selectedFacilityCodes, storedSettings, search, getPageCode) => (dispatch) => {
    if (selectedFacilityCodes && selectedFacilityCodes.length === 0) {
        return;
    }
    dispatch(linkingEntities());
    const entity = {
        facilityCodes: selectedFacilityCodes,
    };
    return api
        .post('facility_minimal/client_edit_facility/link?code=' + getPageCode, entity)
        .then((res) => {
            // console.log('res', res);
            dispatch(linkingEntitiesSuccess());
            dispatch(clientEditFacilitySlice.actions.loadData({ ...storedSettings, globalSearch: search, code: getPageCode }));
        })
        .catch((error) => {
            // console.log('catch', error.response);
            dispatch(linkingEntitiesError());
            if (error.response) {
                // dispatch(fetchThreadListError(fieldsErrors[ error.response.status ]));
            } else if (error.request) {
                console.error(error.request);
                // dispatch(fetchThreadListError(error));
            } else {
                console.error('Error ', error.message);
            }
        });
}

export const unlinkClientEditFacility = (selectedFacilityCodes, storedSettings, search, getPageCode) => (dispatch) => {
    if (selectedFacilityCodes && selectedFacilityCodes.length === 0) {
        return;
    }
    dispatch(unlinkingEntities());
    const entity = {
        facilityCodes: selectedFacilityCodes,
    };
    return api
        .post('facility_minimal/client_edit_facility/unlink?code=' + getPageCode, entity)
        .then((res) => {
            // console.log('res', res);
            dispatch(linkingEntitiesSuccess());
            dispatch(clientEditFacilitySlice.actions.loadData({ ...storedSettings, globalSearch: search, code: getPageCode }));
        })
        .catch((error) => {
            // console.log('catch', error.response);
            dispatch(linkingEntitiesError());
            if (error.response) {
                // dispatch(fetchThreadListError(fieldsErrors[ error.response.status ]));
            } else if (error.request) {
                console.error(error.request);
                // dispatch(fetchThreadListError(error));
            } else {
                console.error('Error ', error.message);
            }
        });
}
