import { createDatatableTemplateSlice, sortDirections } from 'components/Datatable/dataTableUtils';
import createEntityMutationTemplateSlice from 'redux/utils/createEntityMutationTemplateSlice';

// it is necessary for the form
const initialEntity = {};

const queuedMessageSlice = createDatatableTemplateSlice(
    createEntityMutationTemplateSlice({
        name: 'queuedMessage',
        initialSettings: {
            sortBy: 'queuedFor',
            sortDirection: sortDirections.DESC,
        },
        initialState: {
            entity: initialEntity,
        },
    }),
);

export const queuedMessageReducer = queuedMessageSlice.reducer;

export const {
    loadData,
    dataLoaded,
    dataLoadError,
    changeSettings,
    editField,
    setValidityStatus,
    updateEntityError,
    updateEntityLoading,
    updateEntitySuccess,
    fetchEntityLoading,
    fetchEntitySuccess,
    fetchEntityError,
    createEntityError,
    createEntityLoading,
    createEntitySuccess,
    resetEntity,
} = queuedMessageSlice.actions;

export default queuedMessageSlice;
