import React from 'react';
import PropTypes from 'prop-types';

const LoginMessage = ({ showMessage = false, message }) => {
  return (
    <p className="login-box-msg">
      {showMessage ? (
        <span className="text-danger">{message} </span>
      ) : (
        'Sign in to start your session'
      )}
    </p>
  );
};

LoginMessage.propTypes = {
  showMessage: PropTypes.bool,
  message: PropTypes.string,
};

export default LoginMessage;
