import { EditorState, convertToRaw } from 'draft-js';

export const initialWysiwygValue = convertToRaw(EditorState.createEmpty().getCurrentContent());

// export const ItemTypes = {
//     FIELD: 'field'
// };

export const currencyToIcon = {
    USD: 'dollar',
    NZD: 'dollar',
    EUR: 'euro',
    RUB: 'ruble',
    GBP: 'gbp',
    JPY: 'yen',
    ENY: 'dollar',
    undefined: '',
};

export const reducedRoles = [
    {
        code: 'ROLE_STAFF',
        name: 'ROLE_STAFF',
    },
    {
        code: 'ROLE_CLIENT',
        name: 'ROLE_CLIENT',
    },
    {
        code: 'ROLE_REVIEWER',
        name: 'ROLE_REVIEWER',
    },
];

export const commentRoles = [
    {
        value: 'REVIEWER',
        label: 'Reviewer',
    },
    {
        value: 'QAER',
        label: 'Staff QA',
    },
    {
        value: 'DISPATCHER',
        label: 'Dispatcher',
    },
    {
        value: 'SUBMITTER',
        label: 'Client',
    },
];

export const roleStageMatrix = {
    'CW_DRAFT#ROLE_STAFF_ACCOUNTANT': false,
    'CW_DRAFT#ROLE_CLIENT_SUPERVISOR': false,
    'CW_DISPATCH#ROLE_CLIENT_USER': false,
    'CW_CLOSED#ROLE_CLIENT_USER': false,
    'CW_DISPATCH#ROLE_STAFF_QA': false,
    'CW_REVIEW#ROLE_ADMIN': false,
    'CW_QA#ROLE_CLIENT_USER': false,
    'CW_REVIEW#ROLE_STAFF_MANAGER': false,
    'CW_REVIEW#ROLE_STAFF_ACCOUNTANT': false,
    'CW_DISPATCH#ROLE_REVIEWER': false,
    'CW_REVIEW#ROLE_CLIENT_SUPERVISOR': false,
    'CW_DRAFT#ROLE_CLIENT_ADMIN': false,
    'CW_REVIEW#ROLE_STAFF_BASIC': false,
    'CW_QA#ROLE_ADMIN': false,
    'CW_QA#ROLE_CLIENT_ADMIN': false,
    'CW_CLOSED#ROLE_STAFF_BASIC': false,
    'CW_DRAFT#ROLE_STAFF_BASIC': false,
    'CW_DRAFT#ROLE_STAFF_MANAGER': false,
    'CW_DRAFT#ROLE_ADMIN': false,
    'CW_QA#ROLE_STAFF_MANAGER': false,
    'CW_CLOSED#ROLE_REVIEWER': false,
    'CW_DISPATCH#ROLE_CLIENT_SUPERVISOR': false,
    'CW_CLOSED#ROLE_STAFF_QA': false,
    'CW_DISPATCH#ROLE_CLIENT_ADMIN': false,
    'CW_CLOSED#ROLE_STAFF_ACCOUNTANT': false,
    'CW_REVIEW#ROLE_REVIEWER': false,
    'CW_DRAFT#ROLE_STAFF_QA': false,
    'CW_REVIEW#ROLE_CLIENT_USER': false,
    'CW_QA#ROLE_STAFF_QA': false,
    'CW_CLOSED#ROLE_STAFF_MANAGER': false,
    'CW_QA#ROLE_CLIENT_SUPERVISOR': false,
    'CW_DISPATCH#ROLE_ADMIN': false,
    'CW_DISPATCH#ROLE_STAFF_MANAGER': false,
    'CW_REVIEW#ROLE_CLIENT_ADMIN': false,
    'CW_DRAFT#ROLE_REVIEWER': false,
    'CW_CLOSED#ROLE_CLIENT_SUPERVISOR': false,
    'CW_QA#ROLE_STAFF_BASIC': false,
    'CW_DISPATCH#ROLE_STAFF_ACCOUNTANT': false,
    'CW_REVIEW#ROLE_STAFF_QA': false,
    'CW_CLOSED#ROLE_ADMIN': false,
    'CW_DISPATCH#ROLE_STAFF_BASIC': false,
    'CW_CLOSED#ROLE_CLIENT_ADMIN': false,
    'CW_DRAFT#ROLE_CLIENT_USER': false,
    'CW_QA#ROLE_REVIEWER': false,
    'CW_QA#ROLE_STAFF_ACCOUNTANT': false,
};

export const reducedRoleStageMatrix = {
    'CW_DISPATCH#ROLE_STAFF': false,
    'CW_DISPATCH#ROLE_CLIENT': false,
    'CW_DISPATCH#ROLE_REVIEWER': false,
    'CW_REVIEW#ROLE_STAFF': false,
    'CW_REVIEW#ROLE_CLIENT': false,
    'CW_REVIEW#ROLE_REVIEWER': false,
    'CW_QA#ROLE_STAFF': false,
    'CW_QA#ROLE_CLIENT': false,
    'CW_QA#ROLE_REVIEWER': false,
    'CW_DRAFT#ROLE_STAFF': false,
    'CW_DRAFT#ROLE_CLIENT': false,
    'CW_DRAFT#ROLE_REVIEWER': false,
    'CW_CLOSED#ROLE_STAFF': false,
    'CW_CLOSED#ROLE_CLIENT': false,
    'CW_CLOSED#ROLE_REVIEWER': false,
};

export const caseQuestionCount = [
    {
        label: 'One',
        value: 1,
    },
    {
        label: 'Two',
        value: 2,
    },
    {
        label: 'Three',
        value: 3,
    },
];

export const serviceSections = ['assignment', 'attachments', 'owners'];

export const tiers = ['TIER_1', 'TIER_2', 'TIER_3', 'NO_TIER'];

export const itemToStageCode = {
    draft: 'CW_DRAFT',
    dispatch: 'CW_DISPATCH',
    review: 'CW_REVIEW',
    qa: 'CW_QA',
    complete: 'CW_CLOSED',
    closed_cases: 'CW_CLOSED',
    flagged: '',
    assignments: '',
    all_cases: 'CW_ALL',
    in_progress: 'CW_PROGRESS',
};

export const colorToStage = {
    CW_CLOSED: '#61a12f',
    CW_REVIEW: '#FF851B',
    CW_DISPATCH: '#FF851B',
    CW_QA: '#FF851B',
    CW_DRAFT: '#08437a',
    CW_ALL: '#08437a',
};

// export const reportTypes = [
//     'CASE_REPORT_PDF',
//     'CASE_REPORT_DOCX',
// ];

export const reportTypeForStaff = 'CASE_REPORT_DOCX';

export const rateTypes = ['HOURLY', 'FLAT'];

const casePaginationBasic = {
    page: 1,
    sizePerPage: 50,
};

const casePaginationHome = {
    page: 1,
    sizePerPage: 5,
};

const caseReviewerDueDateBasic = {
    sorting: {
        sortName: 'reviewerDueDate',
        sortOrder: 'asc',
    },
    pagination: casePaginationBasic,
    total: 0,
};

const caseReviewerDueDateHome = {
    sorting: {
        sortName: 'reviewerDueDate',
        sortOrder: 'asc',
    },
    pagination: casePaginationHome,
    total: 0,
};

const caseDueDateBasic = {
    sorting: {
        sortName: 'clientDueDate',
        sortOrder: 'asc',
    },
    pagination: casePaginationBasic,
    total: 0,
};

const caseDueDateHome = {
    sorting: {
        sortName: 'clientDueDate',
        sortOrder: 'asc',
    },
    pagination: casePaginationHome,
    total: 0,
};

const caseCompletionDateBasic = {
    sorting: {
        sortName: 'completionDate',
        sortOrder: 'asc',
    },
    pagination: casePaginationBasic,
    total: 0,
};

const caseCompletionDateHome = {
    sorting: {
        sortName: 'completionDate',
        sortOrder: 'asc',
    },
    pagination: casePaginationHome,
    total: 0,
};

export const gridDefaultSettings = {
    //TEMPLATE
    fieldInstance: {
        sorting: {
            sortName: ['position', 'sectionOrder'],
            sortOrder: ['asc', 'asc'],
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    template: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    section: {
        sorting: {
            sortName: 'position',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    templateClient: {
        sorting: {
            sortName: 'fullName',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    field: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },
    //

    billingGroupTiers: {
        sorting: {
            sortName: 'tierNumber',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },
    //

    //USERS
    patient: {
        sorting: {
            sortName: ['firstName', 'lastName'],
            sortOrder: ['asc', 'asc'],
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    staff: {
        sorting: {
            sortName: ['firstName', 'lastName'],
            sortOrder: ['asc', 'asc'],
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    user: {
        sorting: {
            sortName: ['firstName', 'lastName'],
            sortOrder: ['asc', 'asc'],
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    reviewer: {
        sorting: {
            sortName: 'lastName',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },
    //

    //CLIENT
    client: {
        sorting: {
            sortName: 'fullName',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    clientSubmitters: {
        sorting: {
            sortName: 'firstName',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    clientPricingTiers: {
        sorting: {
            sortName: 'tierNumber',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    clientService: {
        sorting: {
            sortName: 'linked',
            sortOrder: 'desc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    clientDiagnosis: {
        sorting: {
            sortName: 'linked',
            sortOrder: 'desc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    clientFacility: {
        sorting: {
            sortName: 'linked',
            sortOrder: 'desc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    clientPhysician: {
        sorting: {
            sortName: 'linked',
            sortOrder: 'desc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    //

    //BILLING GROUP

    billingGroup: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    //

    //PHYSICIAN

    physician: {
        sorting: {
            sortName: ['firstName', 'lastName'],
            sortOrder: ['asc', 'asc'],
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    physicianFacilities: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    //

    //FACILITY

    facility: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    facilityType: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    facilityClient: {
        sorting: {
            sortName: 'fullName',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    facilityPhysician: {
        sorting: {
            sortName: 'firstName',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    //

    specialties: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    //

    //

    caseType: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    //

    //

    country: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    //

    //MEDICAL SYSTEM

    mcSystem: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    scSystem: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    procedure: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    diagnosis: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    //

    //CASES

    caseDraft: caseDueDateBasic,
    caseDispatch: caseDueDateBasic,
    caseReview: caseDueDateBasic,
    caseQa: caseDueDateBasic,
    caseClosed: caseCompletionDateBasic,
    caseAll: caseDueDateBasic,
    caseInProcess: caseDueDateBasic,
    caseAvailable: caseReviewerDueDateBasic,
    caseAccepted: caseReviewerDueDateBasic,
    caseClosedLastMonth: caseCompletionDateBasic,
    caseClosedThisMonth: caseCompletionDateBasic,

    caseDraftHome: caseDueDateHome,
    caseDispatchHome: caseDueDateHome,
    caseReviewHome: caseDueDateHome,
    caseQaHome: caseDueDateHome,
    caseClosedHome: caseCompletionDateHome,
    caseAllHome: caseDueDateHome,
    caseInProcessHome: caseDueDateHome,
    caseAvailableHome: caseReviewerDueDateHome,
    caseAcceptedHome: caseReviewerDueDateHome,
    caseClosedLastMonthHome: caseCompletionDateHome,
    caseClosedThisMonthHome: caseCompletionDateHome,

    caseMedicalRecords: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    caseReports: {
        sorting: {
            sortName: 'date_original',
            sortOrder: 'desc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    caseInternalCaseFiles: {
        sorting: {
            sortName: 'date_original',
            sortOrder: 'desc',
        },
        pagination: {
            page: 1,
            sizePerPage: 50,
        },
    },

    // NOTIFICATION TEMPLATE
    notificationTemplate: {
        sorting: {
            sortName: 'name',
            sortOrder: 'asc',
        },
        pagination: {
            page: 1,
            sizePerPage: 10,
        },
    },
};

export const notificationAssigneeTypes = [
    {
        label: 'SUBMITTER',
        value: 'SUBMITTER',
    },
    {
        label: 'DISPATCHER',
        value: 'DISPATCHER',
    },
    {
        label: 'ASSIGNED_REVIEWER',
        value: 'ASSIGNED_REVIEWER',
    },
    {
        label: 'TIER_1_ASSIGNED_REVIEWER',
        value: 'TIER_1_ASSIGNED_REVIEWER',
    },
    {
        label: 'TIER_2_ASSIGNED_REVIEWER',
        value: 'TIER_2_ASSIGNED_REVIEWER',
    },
    {
        label: 'TIER_3_ASSIGNED_REVIEWER',
        value: 'TIER_3_ASSIGNED_REVIEWER',
    },
    {
        label: 'ACCEPTED_REVIEWER',
        value: 'ACCEPTED_REVIEWER',
    },
    {
        label: 'QAER',
        value: 'QAER',
    },
    {
        label: 'CLIENT_SUPERVISOR',
        value: 'CLIENT_SUPERVISOR',
    },
    {
        label: 'STAFF_BASIC',
        value: 'STAFF_BASIC',
    },
    {
        label: 'STAFF_MANAGER',
        value: 'STAFF_MANAGER',
    },
    {
        label: 'STAFF_QA',
        value: 'STAFF_QA',
    },
    {
        label: 'STAFF_ACCOUNTANT',
        value: 'STAFF_ACCOUNTANT',
    },
];

export const GLOBAL_ASYNC_CACHE_TIME = 30 * 60 * 1000; // 30 minutes

export const TAT_MIN = 1; // days
export const TAT_MAX = 365; // days

export const LOCAL_TIMEZONE_OFFSET = new Date().getTimezoneOffset() * 60 * 1000;
