import { combineReducers } from 'redux';
import { patientCaseStages } from './patientCaseStages';
import { acceptedReducer } from './slices/acceptedSlice';
import { allReducer } from './slices/allSlice';
import { availableReducer } from './slices/availableSlice';
import { closedLastMonthReducer } from './slices/closedLastMonthSlice';
import { closedReducer } from './slices/closedSlice';
import { closedThisMonthReducer } from './slices/closedThisMonthSlice';
import { completeReducer } from './slices/completeSlice';
import { dispatchReducer } from './slices/dispatchSlice';
import { draftReducer } from './slices/draftSlice';
import { flaggedReducer } from './slices/flaggedSlice';
import { inProcessReducer } from './slices/inprocessSlice';
import { qaReducer } from './slices/qaSlice';
import { reviewReducer } from './slices/reviewSlice';

export default combineReducers({
    [patientCaseStages.ACCEPTED]: acceptedReducer,
    [patientCaseStages.AVAILABLE]: availableReducer,
    [patientCaseStages.CLOSED_LAST_MONTH]: closedLastMonthReducer,
    [patientCaseStages.CLOSED]: closedReducer,
    [patientCaseStages.CLOSED_THIS_MONTH]: closedThisMonthReducer,
    [patientCaseStages.DISPATCH]: dispatchReducer,
    [patientCaseStages.DRAFT]: draftReducer,
    [patientCaseStages.IN_PROCESS]: inProcessReducer,
    [patientCaseStages.QA]: qaReducer,
    [patientCaseStages.REVIEW]: reviewReducer,
    [patientCaseStages.ALL]: allReducer,
    [patientCaseStages.FLAGGED]: flaggedReducer,
    [patientCaseStages.COMPLETE]: completeReducer,
});
