import PropTypes from 'prop-types';
import React from 'react';

// acceptedCaseCountStatus -> RED, GREEN, BLUE
export const ColorCircle = ({ color }) => {
  return (
    <i className="fa fa-circle" style={{ color: color ? color.toLowerCase() : 'red' }}>
      &nbsp;
    </i>
  );
};
ColorCircle.propTypes = {
  color: PropTypes.string,
};
