import React from 'react';
import shortId from 'shortid'
import LoadingIndicator from '../../../components/LoadingIndicator';
import FormField from '../../../components/FormField'
import {editableByRoles, fieldClasses, fieldsTypes} from '../../../utils/field-constants'
import UsageMatrix from '../../FieldComponents/UsageMatrix'
import ValidationBlock from '../../FieldComponents/ValidationBlock'
import EditScriptPanel from './EditScriptPanel'
import Box from '../../../components/Box'
import BoxHeader from '../../../components/BoxHeader'
import BoxBody from '../../../components/BoxBody'
import {
    getFirstValue,
    getSelectFieldValue,
    isFieldTypeSelect,
    makeSelectOptions,
    toArray,
    validateListOptions
} from '../../../utils/helpers'
import SimpleReactValidator from "utils/simple-react-validator";
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import convertFieldData, {isDefaultValueValid} from '../../../utils/fields'
import SystemMessage from '../../../components/SystemMessage'

export default class ManageFieldForm extends React.Component {

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            alpha_num_dash_space: {
                message: 'The :attribute may only contain letters, numbers, dashes, and spaces.',
                rule: (val) => val.toString().match(/^[A-Z0-9 _-]*$/i) !== null
            },
            custom_value: {
                message: 'The Value should be in the List Options.',
                rule: this.isDefaultValueValid
            },
            positive_num: {
                message: 'The :attribute must be greater than 0.',
                rule: function (val) {
                    return parseFloat(val) > 0 || val === ''
                }
            },
            list_options: {
                message: 'List options must be the same type as field type',
                rule: this.isListOptionsValid
            }
        });

        this.state = {
            displayNameLanguage: {value: 'EN', label: 'English'},
            helpTextLanguage: {value: 'EN', label: 'English'},
            errorMessages: Object.keys(this.validator.errorMessages).map((field) => ({
                id: field,
                field: field,
                message: this.validator.errorMessages[field],
            }))
        };

        this.validator.showMessages()

    }

    isDefaultValueValid = (val) => {
        isDefaultValueValid(val, this.props.field)
    };

    isListOptionsValid = () => {
        const {listOptions, fieldType} = this.props.field;
        if (fieldType) {
            return validateListOptions(listOptions, fieldType.value ? fieldType.value : fieldType)
        }
        return true
    };

    componentDidMount() {
        this.props.getInitData();
        setTimeout(() => {
                this.dispatchValidState()
            }
            , 1500)
    }

    dispatchValidState = () => {
        const {fields, errorMessages} = this.validator;
        let valid = true;
        let messages = [];

        for (let key in fields) {
            if (!fields.hasOwnProperty(key)) continue;
            if (getSelectFieldValue(this.props.field.fieldType) + '' === 'LABEL' && key === 'Display Name') {
                messages.push({
                    id: key,
                    field: key,
                    message: null
                });
                continue
            }

            valid = valid && fields[key];
            messages.push({
                id: key,
                field: key,
                message: errorMessages[key]
            })
        }
        this.props.isAllValid(valid);

        this.setState({
            errorMessages: messages
        })
    };

    openEditScriptForm = (e) => {
        console.log('openEditScriptForm', e.target.dataset.name + 'Script');
        let script = e.target.dataset.name + 'Script';
        this.setState({
            [script]: !this.state[script]
        });
        console.log(this.state[script]);
    };

    openEditListScriptForm = () => {
        this.setState({
            listOptionsScript: !this.state.listOptionsScript
        })
    };

    onChange = (e) => this.props.editField([e.target.name], e.target.value);

    onChangeCheckBox = (e) => this.props.editField([e.target.name], e.target.checked);

    onChangeAllowMultiSelect = (e) => {
        const {name, checked} = e.target;
        const {defaultValue, fieldType, listOptions} = this.props.field;
        let defaultVal;

        if (isFieldTypeSelect(getSelectFieldValue(fieldType)) || (listOptions && listOptions.length)) {
            defaultVal = checked ? toArray(defaultValue) : getFirstValue(defaultValue)
        }

        this.props.editField([name], checked);
        this.props.editField('defaultValue', defaultVal || defaultValue)
    };

    onChangeDisplayNameLanguage = (displayNameLanguage) => this.setState({displayNameLanguage});

    onChangeHelpTextLanguage = (helpTextLanguage) => this.setState({helpTextLanguage});

    onChangeDataType = (fieldType) => {
        this.props.editField('fieldType', fieldType);
        this.props.editField('allowMultiSelect', false);
        this.props.editField('listOptions', []);

        if (fieldType && fieldType.value !== 'DATE_TIME' && fieldType.value !== 'DATE' && fieldType.value !== 'TIME' && fieldType.value !== 'YEAR') this.props.editField('defaultValue', '');
        else this.props.editField('defaultValue', null)
    };

    onChangeDefaultValue = (defaultValue) => {
        if (defaultValue && defaultValue.target) {
            const type = this.props.field.fieldType.value || this.props.field.fieldType;
            if (type === 'BOOLEAN') {
                return this.props.editField('defaultValue', defaultValue.target.checked);
            } else {
                this.onChange(defaultValue)
            }
        } else {
            this.props.editField('defaultValue', defaultValue)
        }
    };

    onChangeScript = (script) => this.setState({script});

    onChangeListOptionsScript = (listOptionsScript) => this.props.editField('listOptionsScript', listOptionsScript);

    onChangeReadOnlyMatrixScript = (readOnlyMatrixScrip) => this.props.editField('readOnlyMatrixScript', readOnlyMatrixScrip);

    onChangeRequiredMatrixScript = (requiredMatrixScript) => this.props.editField('requiredMatrixScript', requiredMatrixScript);

    onChangeVisibilityMatrixScript = (visibilityMatrixScript) => this.props.editField('visibilityMatrixScript', visibilityMatrixScript);

    onChangeCustomClasses = (customClasses) => this.props.editField('customClasses', customClasses);

    onChangeSection = (sectionCode) => this.props.editField('sectionCode', sectionCode);

    onChangeCaseLevel = (caseLevel) => this.props.editField('caseLevel', caseLevel);

    onChangeDisplayName = (value) => {
        this.props.editField('name', value);
    };

    onChangeListOptions = (listOptions) => {
        const {fieldType, defaultValue, allowMultiSelect} = this.props.field;

        if (isFieldTypeSelect(fieldType) && !!defaultValue && !allowMultiSelect) {
            const isContains = !!listOptions.find((item) => item.value === getSelectFieldValue(defaultValue));
            !isContains && this.props.editField('defaultValue', null)
        }

        if (!listOptions || !listOptions.length) this.props.editField('defaultValue', null);

        this.props.editField('listOptions', listOptions)
    };

    onChangeHelpTextStaff = (value) => {
        const helpTextStaff = Object.assign({}, this.props.field.helpText, {
            [this.state.helpTextLanguage.value]: value
        });

        this.props.editField('helpTextStaff', helpTextStaff);
    };

    onChangeHelpTextReviewer = (value) => {
        const helpTextReviewer = Object.assign({}, this.props.field.helpTextReviewer, {
            [this.state.helpTextLanguage.value]: value
        });

        this.props.editField('helpTextReviewer', helpTextReviewer);
    };

    onChangeHelpTextClient = (value) => {
        const helpTextClient = Object.assign({}, this.props.field.helpTextClient, {
            [this.state.helpTextLanguage.value]: value
        });

        this.props.editField('helpTextClient', helpTextClient);
    };

    onChangeVisibilityMatrix = (stageRole) => {
        this.props.editField('visibilityMatrix', {
            ...this.props.field.visibilityMatrix,
            [stageRole]: !this.props.field.visibilityMatrix[stageRole]
        });
    };

    onChangeRequiredMatrix = (stageRole) => {
        this.props.editField('requiredMatrix', {
            ...this.props.field.requiredMatrix,
            [stageRole]: !this.props.field.requiredMatrix[stageRole]
        });
    };

    onChangeReadOnlyMatrix = (stageRole) => {
        this.props.editField('readOnlyMatrix', {
            ...this.props.field.readOnlyMatrix,
            [stageRole]: !this.props.field.readOnlyMatrix[stageRole]
        });
    };

    onChangeEditableAfterClose = (val) => this.props.editField('editableAfterClose', val);

    onChangeClientEditableAfterClose = (val) => this.props.editField('clientEditableAfterClose', val);

    isFieldTypeSelect = (field) => {
        return /REF/g.test(field ? getSelectFieldValue(field) : null)
    };

    onChangeValidationRule = (value) => {
        const {fieldValidationDtos} = this.props.field;
        this.props.editField('fieldValidationDtos', fieldValidationDtos.map((item) => {
            if (item.code === value.code) return value;
            return item;
        }))
    };

    removeFieldValidRule = (code) => {
        const {fieldValidationDtos} = this.props.field;

        this.props.editField('fieldValidationDtos', fieldValidationDtos.filter((item) => item.code !== code));
    };

    addFieldValidRule = (e) => {
        e.preventDefault();
        const {fieldValidationDtos} = this.props.field;
        const emptyRule = {
            active: true,
            code: shortId.generate(),
            hardValidation: true,
            logic: '',
            text: {'EN': ''}
        };

        this.props.editField('fieldValidationDtos', [...fieldValidationDtos, emptyRule])
    };

    onSave = () => {
        const data = convertFieldData(this.props.field);
        return this.props.createFieldAndInstance(data)
    };

    render() {
        let showSuccess = this.props.editedSuccess ? 'block' : 'none';
        const fieldType = getSelectFieldValue(this.props.field.fieldType);
        const {fieldValidationDtos} = this.props.field;
        const {errorMessages} = this.state;

        let defaultValueType;

        if (this.props.field.listOptions && this.props.field.listOptions.length) {
            defaultValueType = 'select'
        } else {
            defaultValueType = fieldType + '' === 'LABEL' ? 'RICH_TEXT' : fieldType;
        }

        const validationSection = fieldValidationDtos.map((validRule, i) => (
            <ValidationBlock
                key={validRule.code}
                validRule={validRule}
                onChange={this.onChangeValidationRule}
                onDelete={this.removeFieldValidRule}
                isLast={fieldValidationDtos.length === i + 1}
            />
        ));

        const isListOptionsDisabled = fieldType + '' === 'LABEL' ||
            fieldType + '' === 'BOOLEAN' ||
            fieldType + '' === 'DATE' ||
            fieldType + '' === 'DATE_TIME' ||
            fieldType + '' === 'TIME' ||
            fieldType + '' === 'TEXT';

        return (
            <div className="container-fluid">
                <SystemMessage shown={this.props.editedSuccess && this.props.isFormValid} type='success'
                               message='Profile successfully updated' top='65px'/>
                <SystemMessage shown={!this.props.isFormValid} type='error' message={this.props.error}
                               validationMessages={errorMessages} top='65px'/>
                <section className="content user-profile-content">
                    {this.props.loading ?
                        <LoadingIndicator height={50}/>
                        : null}


                    <div className="row">
                        <PreventTransitionPrompt
                            when={!!this.props.isFieldChanged}
                            isFormValid={this.props.fieldValid}
                            entity='field_instance'
                            saveChanges={this.onSave}
                            onValiudationFail={() => this.props.dispatchFIValidStatus(false)}
                            cancelAndDiscard={() => {
                                this.props.getInitData()
                            }}
                        />
                        {/*<PageHeader style={{color:'#08437a'}}>{this.props.label}</PageHeader>*/}
                        <Box
                            color="blue"
                            classes="box-profile-first-row-height-show">
                            <BoxHeader
                                title={this.props.label}
                                color="blue"
                                classes="box-profile-first-row-height-show"/>
                            <BoxBody>
                                <div
                                    className="alert alert-success"
                                    style={{display: showSuccess}}>
                                    Field successfully updated
                                </div>
                                <form onKeyDown={(e) => {
                                    if (e.target['tagName'] === 'INPUT' && e.keyCode === 13) e.preventDefault()
                                }}>
                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                label="Field Key"
                                                id="Field Key"
                                                labelLeft
                                                className="form-control"
                                                required={this.props.mode === 'create'}
                                                name="code"
                                                disabled={this.props.mode !== 'create'}
                                                value={this.props.field.code}
                                                onChange={this.onChange}
                                                valids="required|alpha_num_dash_space"
                                                validator={this.validator}
                                                onBlur={this.dispatchValidState}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group bordered">
                                        <div className="row">
                                            {getSelectFieldValue(this.props.field.fieldType) + '' === 'LABEL' ?
                                                <FormField
                                                    md="10"
                                                    type='RICH_TEXT'
                                                    labelLeft
                                                    actioned
                                                    icon='code'
                                                    onActionClick={this.openEditScriptForm}
                                                    label="Display Name"
                                                    id="Display Name"
                                                    className="form-control required-field"
                                                    readOnly={true}
                                                    name="name"
                                                    value={null}
                                                    onChange={this.onChangeDisplayName}
                                                />
                                                :
                                                <FormField
                                                    md="10"
                                                    type='RICH_TEXT'
                                                    labelLeft
                                                    actioned
                                                    icon='code'
                                                    onActionClick={this.openEditScriptForm}
                                                    label="Display Name"
                                                    id="Display Name"
                                                    className="form-control required-field"
                                                    name="name"
                                                    value={this.props.field.name}
                                                    onChange={this.onChangeDisplayName}
                                                    valids='required'
                                                    validator={this.validator}
                                                    onBlur={this.dispatchValidState}
                                                />
                                            }
                                            <EditScriptPanel
                                                opened={this.state.nameScript}
                                                md='10'
                                                name='nameScript'
                                                label='Display Name Script'
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="select"
                                                label="Field Type"
                                                id="Field Type"
                                                labelLeft
                                                className={this.props.mode !== 'create' ? 'background-grey text-bg' : "required-field text-bg"}
                                                disabled={this.props.mode !== 'create'}
                                                options={makeSelectOptions(fieldsTypes)}
                                                name="fieldType"
                                                value={this.props.field.fieldType}
                                                onChange={this.onChangeDataType}
                                                valids="required"
                                                validator={this.validator}
                                                onBlur={this.dispatchValidState}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group border-bold">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="select"
                                                label="Style"
                                                labelLeft
                                                className="text-bg "
                                                searchable={true}
                                                multi={true}
                                                options={fieldClasses}
                                                name="customClasses"
                                                actioned
                                                icon='code'
                                                onActionClick={this.openEditScriptForm}
                                                value={this.props.field.customClasses}
                                                onChange={this.onChangeCustomClasses}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.customClassesScript}
                                                md='10'
                                                name='customClassesScript'
                                                label='Style Script'
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group border-bold">
                                        <div className="form-group ">
                                            <div className="row">
                                                <FormField
                                                    md="12"
                                                    type='label_field'
                                                    label="Placement"
                                                    className='margin-bottom-15'
                                                    style={{fontSize: '1.2em', marginBottom: '20px'}}
                                                />

                                                <FormField
                                                    md="10"
                                                    label="Section"
                                                    id="Section"
                                                    labelLeft
                                                    className="text-bg required-field"
                                                    type="select"
                                                    name="sectionCode"
                                                    searchable
                                                    sorted
                                                    options={this.props.sections}
                                                    value={this.props.field.sectionCode}
                                                    onChange={this.onChangeSection}
                                                    valids='required'
                                                    validator={this.validator}
                                                    onBlur={this.dispatchValidState}
                                                    actioned
                                                    icon='code'
                                                    onActionClick={this.openEditScriptForm}
                                                />

                                                <EditScriptPanel
                                                    opened={this.state.sectionCodeScript}
                                                    md='10'
                                                    name='sectionCodeScript'
                                                    label='Section Script'
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <div className="row">
                                                <FormField
                                                    md="10"
                                                    type="number"
                                                    label="Order"
                                                    id="Order"
                                                    labelLeft
                                                    className="text-bg "
                                                    name="position"
                                                    required
                                                    value={this.props.field.position ? this.props.field.position : ''}
                                                    onChange={this.onChange}
                                                    valids='required|positive_num'
                                                    validator={this.validator}
                                                    onBlur={this.dispatchValidState}
                                                    actioned
                                                    icon='code'
                                                    onActionClick={this.openEditScriptForm}
                                                />
                                                <EditScriptPanel
                                                    opened={this.state.positionScript}
                                                    md='10'
                                                    name='positionScript'
                                                    label='Order Script'
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group bordered margin-top-15">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type={isFieldTypeSelect(fieldType) ? fieldType : 'creatable'}
                                                multi
                                                actioned
                                                icon='code'
                                                onActionClick={this.openEditScriptForm}
                                                creatable={true}
                                                label="List Options"
                                                id="List Options"
                                                labelLeft
                                                valuesSorted
                                                name="listOptions"
                                                validator={this.validator}
                                                valids='list_options'
                                                value={this.props.field.listOptions}
                                                onChange={this.onChangeListOptions}
                                                disabled={isListOptionsDisabled}
                                                onBlur={this.dispatchValidState}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.listOptionsScript}
                                                md='10'
                                                name='listOptionsScript'
                                                label='List Options Script'
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="checkbox"
                                                label="Include Other Option"
                                                labelLeft
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                // title="Only for reference fields"
                                                name='includeOther'
                                                value={this.props.field['includeOther']}
                                                checked={!!this.props.field['includeOther']}
                                                onChange={this.onChangeCheckBox}
                                                className="checkbox"
                                                onBlur={this.dispatchValidState}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="checkbox"
                                                label="Allow Multi-select"
                                                labelLeft
                                                aligment='left'
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                title="Only for reference fields"
                                                name='allowMultiSelect'
                                                value={this.props.field.allowMultiSelect}
                                                checked={!!this.props.field.allowMultiSelect}
                                                onChange={this.onChangeAllowMultiSelect}
                                                className="checkbox"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group border-bold">
                                        <div className="row">
                                            <FormField
                                                md='10'
                                                type={defaultValueType}
                                                label='Value'
                                                id='Value'
                                                labelLeft
                                                name='defaultValue'
                                                actioned
                                                icon='code'
                                                valids="custom_value"
                                                validator={this.validator}
                                                onActionClick={this.openEditScriptForm}
                                                value={this.props.field.defaultValue}
                                                checked={!!this.props.field.defaultValue}
                                                onChange={this.onChangeDefaultValue}
                                                options={this.props.field.listOptions}
                                                sorted={this.props.field.listOptions && this.props.field.listOptions.length}
                                                multi={this.props.field.allowMultiSelect}
                                                disabled={typeof this.props.field.fieldType === "undefined"}
                                                onBlur={this.dispatchValidState}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.defaultValueScript}
                                                md='10'
                                                name='defaultValueScript'
                                                label='Value Script'
                                            />
                                        </div>
                                    </div>


                                    <div className="form-group  border-bold">
                                        <div className="row">
                                            <FormField
                                                md="2"
                                                type='label_field'
                                                label="Visibility"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em'}}
                                            />
                                            <UsageMatrix
                                                matrix={this.props.field.visibilityMatrix}
                                                label=''
                                                languageCode={'EN'}
                                                onChange={this.onChangeVisibilityMatrix}
                                                onActionClick={this.openEditScriptForm}
                                                name='visibilityMatrix'
                                            />
                                            <EditScriptPanel
                                                opened={this.state.visibilityMatrixScript}
                                                md='10'
                                                name='visibilityMatrixScript'
                                                label='Visibility Script'
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group  border-bold">
                                        <div className="row">
                                            <FormField
                                                md="2"
                                                type='label_field'
                                                label="Read Only"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em'}}
                                            />
                                            <UsageMatrix
                                                label=""
                                                matrix={this.props.field.readOnlyMatrix}
                                                languageCode={'EN'}
                                                onChange={this.onChangeReadOnlyMatrix}
                                                onActionClick={this.openEditScriptForm}
                                                name='readOnlyMatrix'
                                            />
                                            <EditScriptPanel
                                                opened={this.state.readOnlyMatrixScript}
                                                md='10'
                                                name='readOnlyMatrixScript'
                                                label='Read Only Script'
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group  border-bold">
                                        <div className="row">
                                            <FormField
                                                md="2"
                                                type='label_field'
                                                label="Required"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em'}}
                                            />
                                            <UsageMatrix
                                                label=""
                                                matrix={this.props.field.requiredMatrix}
                                                languageCode={'EN'}
                                                onChange={this.onChangeRequiredMatrix}
                                                onActionClick={this.openEditScriptForm}
                                                name='requiredMatrix'
                                            />
                                            <EditScriptPanel
                                                opened={this.state.requiredMatrixScript}
                                                md='10'
                                                name='requiredMatrixScript'
                                                label='Required Script'
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group  border-bold">

                                        <div className="row">
                                            <FormField
                                                md="12"
                                                type='label_field'
                                                label="Help Text"
                                                style={{fontSize: '1.2em', marginBottom: '20px'}}
                                            />

                                            <FormField
                                                md="10"
                                                type='RICH_TEXT'
                                                actioned
                                                icon='code'
                                                onActionClick={this.openEditScriptForm}
                                                label="Staff"
                                                labelLeft
                                                className="form-control"
                                                name="helpTextStaff"
                                                value={this.props.field.helpTextStaff
                                                       && this.props.field.helpTextStaff[this.state.helpTextLanguage.value]}
                                                onChange={this.onChangeHelpTextStaff}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.helpTextStaffScript}
                                                md='10'
                                                name='helpTextStaffScript'
                                                label='Staff Help Text Script'
                                            />
                                            <div className='col-md-12'>
                                            </div>
                                            <FormField
                                                md="10"
                                                type='RICH_TEXT'
                                                labelLeft
                                                actioned
                                                icon='code'
                                                onActionClick={this.openEditScriptForm}
                                                label="Reviewer"
                                                className="form-control"
                                                name="helpTextReviewer"
                                                value={this.props.field.helpTextReviewer
                                                       && this.props.field.helpTextReviewer[this.state.helpTextLanguage.value]}
                                                onChange={this.onChangeHelpTextReviewer}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.helpTextReviewerScript}
                                                md='10'
                                                name='helpTextReviewerScript'
                                                label='Reviewer Text Script'
                                            />
                                            <div className='col-md-12'>
                                            </div>

                                            <FormField
                                                md="10"
                                                type='RICH_TEXT'
                                                labelLeft
                                                actioned
                                                icon='code'
                                                onActionClick={this.openEditScriptForm}
                                                label="Client"
                                                className="form-control"
                                                name="helpTextClient"
                                                value={this.props.field.helpTextClient
                                                       && this.props.field.helpTextClient[this.state.helpTextLanguage.value]}
                                                onChange={this.onChangeHelpTextClient}
                                            />
                                            <EditScriptPanel
                                                opened={this.state.helpTextClientScript}
                                                md='10'
                                                name='helpTextClientScript'
                                                label='Client Help Text Script'
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group border-bold">
                                        <div className="row">
                                            <FormField
                                                md="6"
                                                type='label_field'
                                                label="Validation"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em', marginBottom: '20px'}}
                                            />
                                        </div>
                                        {validationSection}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <button
                                                    className='btn-link cursor-pointer'
                                                    onClick={this.addFieldValidRule}>
                                                    +Add new Validation Rule
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <FormField
                                                md="12"
                                                type='label_field'
                                                label="Additional Attributes"
                                                className='margin-bottom-15'
                                                style={{fontSize: '1.2em', marginBottom: '20px'}}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="select"
                                                label="Editable After Close"
                                                labelLeft
                                                className="text-bg "
                                                options={editableByRoles}
                                                name="editableAfterClose"
                                                value={this.props.field.editableAfterClose}
                                                onChange={this.onChangeEditableAfterClose}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                type="select"
                                                label="Client Editable After Close"
                                                labelLeft
                                                className="text-bg "
                                                options={editableByRoles}
                                                name="clientEditableAfterClose"
                                                value={this.props.field.clientEditableAfterClose}
                                                onChange={this.onChangeClientEditableAfterClose}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                type='checkbox'
                                                className='form-control'
                                                name='copyOption'
                                                labelLeft
                                                label='Copy Option'
                                                value={this.props.field['copyOption']}
                                                checked={!!this.props.field['copyOption']}
                                                onChange={this.onChangeCheckBox}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group bordered">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                type='checkbox'
                                                className='form-control'
                                                name='phi'
                                                labelLeft
                                                label='PHI Field'
                                                value={this.props.field.phi}
                                                checked={!!this.props.field.phi}
                                                onChange={this.onChangeCheckBox}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group  border-bold">
                                        <div className="row">
                                            <FormField
                                                md="10"
                                                style={{marginTop: '-10px', marginBottom: '-10px'}}
                                                type='checkbox'
                                                className='form-control'
                                                name='question'
                                                labelLeft
                                                label='Question'
                                                value={this.props.field.question}
                                                checked={!!this.props.field.question}
                                                onChange={this.onChangeCheckBox}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <FormField
                                                md='10'
                                                type="checkbox"
                                                labelLeft
                                                title="Status"
                                                label="Active"
                                                className='form-control'
                                                name='active'
                                                value={this.props.field.active}
                                                checked={!!this.props.field.active}
                                                onChange={this.onChangeCheckBox}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </BoxBody>
                        </Box>
                    </div>
                    {this.props.loading ?
                        <LoadingIndicator height={50}/>
                        : null}
                </section>
            </div>
        )
    }
}
