import React, { Component } from "react";
import { connect } from 'react-redux'
import Box from '../../components/Box'
import BoxHeader from '../../components/BoxHeader'
import BoxBody from '../../components/BoxBody'
import BoxFooter from '../../components/BoxFooter'
import FormField from '../../components/FormField'
import { editCompany, editField, isAllValid } from '../../redux/actions/companiesActions'

class ClientEditTemplateInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showSuccess: false
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeDefaultTemplateCode = this.onChangeDefaultTemplateCode.bind(this);
        this.onChangeFileCaseTemplateCode = this.onChangeFileCaseTemplateCode.bind(this);
    }

    onChange(e) {
        this.setState({ showSuccess: false });
        this.props.editField(e.target.name, e.target.value)
    }

    onChangeDefaultTemplateCode(defaultTemplateCode) {
        this.setState({ showSuccess: false });
        this.props.editField('defaultTemplateCode', defaultTemplateCode)
    }

    onChangeFileCaseTemplateCode(caseFileTemplateCode) {
        this.setState({ showSuccess: false });
        this.props.editField('caseFileTemplateCode', caseFileTemplateCode)
    }

    render() {
        const { showSuccess } = this.state;
        const { isEditable } = this.props;

        return (
            <Box color="green" classes="box-profile-first-row-height-show">
                <BoxHeader title={`Client - ${this.props.profile.fullName}`} color="green" icon="fa-list-alt"/>
                <BoxBody>
                    <p className="login-box-msg">
                        {this.props.error ?
                            <span className="text-danger">{this.props.error} </span>
                            :
                            (
                                showSuccess ?
                                    <span className="text-success">Profile successfully updated </span>
                                    : ""
                            )
                        }
                    </p>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <div className="row">
                                <FormField
                                    md="4"
                                    type="select"
                                    label="Default Template"
                                    id="Default Template"
                                    valids="required"
                                    validator={this.props.validator}
                                    options={this.props.templates}
                                    name="defaultTemplateCode"
                                    value={this.props.profile.defaultTemplateCode}
                                    onBlur={this.props.dispatchValidStatus}
                                    onChange={this.onChangeDefaultTemplateCode}
                                    disabled={!isEditable}
                                />
                                <FormField
                                    md="4"
                                    type="select"
                                    label="Case File Template"
                                    id="Case File Template"
                                    valids="required"
                                    validator={this.props.validator}
                                    options={this.props.case_file_templates}
                                    name="caseFileTemplateCode"
                                    value={this.props.profile.caseFileTemplateCode}
                                    onBlur={this.props.dispatchValidStatus}
                                    onChange={this.onChangeFileCaseTemplateCode}
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        {     /*       <div className="form-group">
                          <div className="row">
                            <FormField
                                md="12"
                                label="Template Modifications"
                                className="form-control"
                                name="templateModifications"
                                value={this.props.profile.templateModifications}
                                onChange={this.onChange}
                            />
                          </div>
                      </div>*/}
                    </form>
                </BoxBody>
                <BoxFooter>
                </BoxFooter>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    let editedCompany = state.app.companies.editedCompany;
    return {
        // profile: state.app.companies.data.filter(usr => usr.code == ownProps.match.params.id)[0],
        profile: editedCompany,
        error: state.app.companies.editTemplateError,
        isLoading: state.app.companies.loading,
        templates: state.app.templates.allTemplates
            .filter((item) => item.active || item.code === editedCompany.defaultTemplateCode)
            .map(template => ({
                value: template.code,
                label: template.name
            })),
        case_file_templates: state.app.case_file_templates.data
            .filter((item) => item.active || item.code === editedCompany.caseFileTemplateCode)
            .map(template => ({
                value: template.code,
                label: template.name
            }))
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveProfile: data => dispatch(editCompany(data, 'template')),
        editField: (field, value) => dispatch(editField(field, value)),
        isAllValid: (valid) => dispatch(isAllValid(valid)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientEditTemplateInfo);
