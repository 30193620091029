const CHANGE_GRID_PAGINATION = 'CHANGE_GRID_PAGINATION';
const CHANGE_GRID_SORT = 'CHANGE_GRID_SORT';
const CHANGE_GRID_SORTING = 'CHANGE_GRID_SORTING';
const CHANGE_SEARCH_VALUE = 'CHANGE_SEARCH_VALUE';
const HIDE_SEARCH_PANEL = 'HIDE_SEARCH_PANEL';
const SAVE_GRID_TOTAL = 'SAVE_GRID_TOTAL';
const SHOW_SEARCH_PANEL = 'SHOW_SEARCH_PANEL';

const hideSearchPanel = () => (dispatch) => {
    dispatch({
        type: HIDE_SEARCH_PANEL,
    });
};

const showSearchPanel = () => (dispatch) => {
    dispatch({
        type: SHOW_SEARCH_PANEL,
    });
};

const onSearch = (value) => (dispatch) => {
    dispatch({
        payload: value,
        type: CHANGE_SEARCH_VALUE,
    });
};

const onSort = (value, direction) => (dispatch) => {
    dispatch({
        payload: { direction: direction, value: value },
        type: CHANGE_GRID_SORT,
    });
};

const onChangeSortConfiguration = (sortName, sortOrder, entity) => (dispatch) =>
    dispatch({
        entity,
        payload: {
            sortName,
            sortOrder,
        },
        type: CHANGE_GRID_SORTING,
    });

const resetSortConfiguration = (entity) => (dispatch) =>
    dispatch({
        entity,
        payload: null,
        type: CHANGE_GRID_SORTING,
    });

const onChangePage = (page, sizePerPage, entity) => (dispatch) =>
    dispatch({
        entity,
        payload: { page, sizePerPage },
        type: CHANGE_GRID_PAGINATION,
    });

const resetPagination = (entity) => (dispatch) =>
    dispatch({
        type: `RESET_${entity.toUpperCase()}_PAGINATION`,
    });

const saveTotal = (total, entity) => (dispatch) =>
    dispatch({
        entity,
        payload: total,
        type: SAVE_GRID_TOTAL,
    });

export {
    CHANGE_GRID_PAGINATION,
    CHANGE_GRID_SORT,
    CHANGE_GRID_SORTING,
    CHANGE_SEARCH_VALUE,
    HIDE_SEARCH_PANEL,
    SAVE_GRID_TOTAL,
    SHOW_SEARCH_PANEL,
    hideSearchPanel,
    onChangePage,
    onChangeSortConfiguration,
    onSearch,
    onSort,
    resetPagination,
    resetSortConfiguration,
    saveTotal,
    showSearchPanel,
};
