import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clientEditFacilitySlice from '../../redux/pages/client-edit-facility/clientEditFacilitySlice';
import { linkClientEditFacility, unlinkClientEditFacility } from '../../redux/pages/client-edit-facility/clientEditFacilityActions';
import LinkingConnectedDataTable from './common/LinkingConnectedDataTable';
import ColoredConnectedDataTable from './common/ColoredConnectedDataTable';

const columns = {
  name: { label: 'Name', colStyle: { width: 'auto' } },
  facilityTypeCode: { label: 'Facility Type', colStyle: { width: '20%' } },
  facilityCode: { label: 'Code', colStyle: { width: '20%' } },
  country: { label: 'Country', colStyle: { width: '20%' } },
  linked: {
    label: 'Linking',
    colStyle: { width: '10%' },
    sortable: false,
    filterOptions: [
      { value: true, label: 'Linked' },
      { value: false, label: 'Unlinked' },
    ],
  },
};

const ClientEditFacilitiesNew = ({ isEditable }) => {
  const { code, fullName } = useSelector((state) => {
    return state.app.companies.editedCompany;
  });

  const { selectedEntities: selectedFacilities, settings: datatableSettings } = useSelector((state) => {
    // console.log('STATE APP', state.app);
    return state.app.clientEditFacility;
  });

  const { search } = useSelector((state) => {
    // console.log('APP:', state.app);
    return state.app.globalSearch;
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const handleRowClick = ({ code }) => {
    // console.log('handleRowClick', history, code);
    history.push(`/facility/${code}`);
  };

  const handleLinkSubmit = () => {
    // console.log('handleLinkSubmit');
    const selectedFacilityCodes = selectedFacilities.map(({ code }) => code);
    dispatch(linkClientEditFacility(selectedFacilityCodes, datatableSettings, search, code));
  };

  const handleUnlinkSubmit = () => {
    // console.log('handleUnlinkSubmit');
    const selectedFacilityCodes = selectedFacilities.map(({ code }) => code);
    dispatch(unlinkClientEditFacility(selectedFacilityCodes, datatableSettings, search, code));
  };

  return (
    isEditable ?
      <LinkingConnectedDataTable
        title={'Client - ' + fullName}
        columns={columns}
        stateKey="clientEditFacility"
        actions={clientEditFacilitySlice.actions}
        onRowClick={handleRowClick}
        onLinkClick={handleLinkSubmit}
        onUnlinkClick={handleUnlinkSubmit}
      />
      :
      <ColoredConnectedDataTable
        title={'Client - ' + fullName}
        columns={columns}
        stateKey="clientEditFacility"
        actions={clientEditFacilitySlice.actions}
        onRowClick={handleRowClick}
      />
  );
};

ClientEditFacilitiesNew.propTypes = {
  isEditable: PropTypes.bool,
  history: PropTypes.object,
};

export default ClientEditFacilitiesNew;
